import { Column } from '@stenajs-webui/core';
import { FC } from 'react';
import { useLocalDateFormat } from '../../../common/hooks/useLocalDateFormat';
import { getDateDisplayName } from '../../../common/utils/dates/dateUtils';
import { BookingResultNotification as BookingResultNotificationType } from '../../../services/notifications/notificationsReducer';
import { NotificationsGroupedByDate } from '../utils/getSortedNotifications';
import { BookingResultNotification } from './BookingResultNotification';
import { EmptyNotificationsContent, NotificationGroup } from './EmptyNotificationsContent';

export interface BookingResultNotificationsProps {
  notificationGroups: NotificationsGroupedByDate<BookingResultNotificationType>[];
  onClose: () => void;
}

export const BookingResultNotifications: FC<BookingResultNotificationsProps> = ({
  notificationGroups,
  onClose,
}) => {
  const dateFormat = useLocalDateFormat();

  return (
    <Column>
      {notificationGroups.map(group => (
        <NotificationGroup key={group.date} heading={getDateDisplayName(group.date, dateFormat)}>
          {group.notifications.map(notification => (
            <BookingResultNotification
              key={notification.id}
              bookingResultNotification={notification}
              onClose={onClose}
            />
          ))}
        </NotificationGroup>
      ))}
      {notificationGroups.length === 0 && <EmptyNotificationsContent label={'No notifications'} />}
    </Column>
  );
};
