import {
  differenceInCalendarDays,
  format,
  isAfter,
  isEqual,
  isValid,
  isWithinInterval,
  parseISO,
  setHours,
  setMinutes,
} from 'date-fns';
import { DropdownOption } from '../../../use-cases/manage/grid/utils/optionTransformers';
import { dateRegionFormats, formatsToUse } from './dateRegionFormats';
import { DateRange } from '@stenajs-webui/calendar';

export const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
export const PRINT_DATE_FORMAT = 'yyyy-MM-dd HH:mm z';

export const formatServerDate = (date: string | Date): string =>
  format(typeof date === 'string' ? parseISO(date) : date, SERVER_DATE_FORMAT);

export const isValidServerDate = (dateString: string): boolean => isValid(parseISO(dateString));

export const formatDate = (date: string | Date, dateFormat: string): string =>
  format(typeof date === 'string' ? parseISO(date) : date, dateFormat);

export const formatTime = (date: string | Date): string =>
  format(typeof date === 'string' ? parseISO(date) : date, 'HH:mm');

export const getDateDisplayName = (selectedDate: string | Date, dateFormat: string): string => {
  const diff = differenceInCalendarDays(
    typeof selectedDate === 'string' ? parseISO(selectedDate) : selectedDate,
    new Date(),
  );
  if (diff > 1 || diff < -1) {
    return formatDate(selectedDate, dateFormat);
  }
  if (diff === 1) {
    return 'Tomorrow';
  }
  if (diff === -1) {
    return 'Yesterday';
  }
  return 'Today';
};

export const formatDateTime = (date: string | Date, dateFormat: string): string =>
  format(typeof date === 'string' ? parseISO(date) : date, `${dateFormat} HH:mm`);

const getLanguage = (): string =>
  navigator.languages?.[0] ?? navigator.language ?? (navigator as any).userLanguage;

export const resolveDateFormat = (): string => {
  const userLanguage = getLanguage().toLowerCase();

  const userLanguageFormat = dateRegionFormats[userLanguage];
  return userLanguageFormat && formatsToUse.indexOf(userLanguageFormat) > -1
    ? userLanguageFormat
    : SERVER_DATE_FORMAT;
};

export const transformRegionFormatOptions = (
  value: string,
  today: Date,
): DropdownOption<string> => ({
  data: value,
  label: `${formatDate(today, value)} (${value.toUpperCase()})`,
  value,
});

export const createRegionFormatsOptions = (today: Date = new Date()): DropdownOption<string>[] =>
  formatsToUse.map(value => transformRegionFormatOptions(value, today));

export const getDateFromDateAndTime = (
  dateString: string,
  timeString: string | undefined,
): Date => {
  const date = parseISO(dateString);

  if (!timeString) {
    return date;
  }

  const [h, m] = timeString.split(':');
  return setHours(setMinutes(date, Number(m)), Number(h));
};

export const getMaybeDateFromDateAndTime = (
  dateString: string | undefined,
  timeString: string | undefined,
): Date | undefined => {
  if (!dateString) {
    return undefined;
  }
  return getDateFromDateAndTime(dateString, timeString);
};

interface ItemWithValidFromAndTo {
  validFrom: string;
  validTo?: string | null;
}

export function isItemValidWithinDate<TItem extends ItemWithValidFromAndTo>(
  departureDate: Date,
): (item: TItem) => boolean {
  return item => {
    if (item.validTo) {
      return isWithinInterval(departureDate, {
        start: parseISO(item.validFrom),
        end: parseISO(item.validTo),
      });
    }
    return (
      isEqual(departureDate, parseISO(item.validFrom)) ||
      isAfter(departureDate, parseISO(item.validFrom))
    );
  };
}

export const formatDateDifference = (diff: [number | null, number | null] | null): string => {
  if (!diff) {
    return 'no date range is set';
  }
  return diff
    .map(d => {
      if (d == null) {
        return '-';
      }
      return (d > 0 ? '+' + d : d) + ' days';
    })
    .join(', ');
};

export const getCalenderDateDifference = (
  date: DateRange,
  today: Date,
): [number | null, number | null] | null => {
  const startDiff = date.startDate ? differenceInCalendarDays(date.startDate, today) : null;
  const endDiff = date.endDate ? differenceInCalendarDays(date.endDate, today) : null;
  if (startDiff != null || endDiff != null) {
    return [startDiff, endDiff];
  }
  return null;
};
