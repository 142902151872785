import { Box } from '@stenajs-webui/core';
import { Banner, FlatButton } from '@stenajs-webui/elements';
import { useSelector } from 'react-redux';
import { getFrontendIsOutOfDate } from '../../services/frontend-out-of-date/frontendOutOfDateReducer';
import { trackEvent } from '../tracking/trackerService';

export const FrontendOutOfDateBanner = () => {
  const show = useSelector(getFrontendIsOutOfDate);

  if (!show) {
    return null;
  }

  const onClick = () => {
    trackEvent({
      category: 'General',
      action: 'Reload (out of date banner)',
    });
    window.location.reload();
  };

  return (
    <Box style={{ marginLeft: 'var(--nav-menu-width)' }}>
      <Banner
        variant={'info'}
        text={
          'A new version of Freight Portal is available. Click Reload to get the latest version.'
        }
        contentRight={<FlatButton label={'Reload'} onClick={onClick} />}
      />
    </Box>
  );
};
