import { createAction } from '@reduxjs/toolkit';

export type CheckUrgentMessagesAction = ReturnType<typeof checkUrgentMessages>;

export const checkUrgentMessages = createAction(
  'URGENT_MESSAGE:CHECK_MESSAGES',
  (dateFromString: string, dateToString: string, routeCode: string) => ({
    payload: {
      dateFromString,
      dateToString,
      routeCode,
    },
  }),
);
