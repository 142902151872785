import { graphql } from '../../../gql';

export const allIntermodalRoutesQuery = graphql(/* GraphQL */ `
  query AllIntermodalRoutesQuery {
    viewer {
      intermodalRoutes {
        allRoutes {
          id
          longDescription
        }
      }
    }
  }
`);
