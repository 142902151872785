import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { getInformationMessageInfo } from '../../../common-ui/information-message/informationMessageUtils';
import { ImportExportStatusCode } from '../../../gql/graphql';
import { resolveInformationMessageType } from './InformationMessageFactory';
import {
  stenaCheck,
  stenaExclamationTriangle,
  stenaRefresh,
  stenaTimes,
} from '@stenajs-webui/elements';

const resolveButtonClassNameFromImportExportStatusCode = (
  code: ImportExportStatusCode,
): string | undefined => {
  switch (code) {
    case ImportExportStatusCode.Error:
      return 't_import_export_status_icon_error';
    case ImportExportStatusCode.Success:
      return 't_import_export_status_icon_success';
    case ImportExportStatusCode.Warning:
      return 't_import_export_status_icon_warning';
    case ImportExportStatusCode.Refresh:
      return 't_import_export_status_icon_refresh';
    default:
      return undefined;
  }
};

const resolveButtonIconFromImportExportStatusCode = (
  code: ImportExportStatusCode,
): IconDefinition | undefined => {
  switch (code) {
    case ImportExportStatusCode.Error:
      return stenaTimes;
    case ImportExportStatusCode.Success:
      return stenaCheck;
    case ImportExportStatusCode.Warning:
      return stenaExclamationTriangle;
    case ImportExportStatusCode.Refresh:
      return stenaRefresh;
    default:
      return undefined;
  }
};

export const resolveIconProps = (code: ImportExportStatusCode) => {
  const infoMessageIconProps = getInformationMessageInfo(resolveInformationMessageType(code));

  const icon = resolveButtonIconFromImportExportStatusCode(code);
  const className = resolveButtonClassNameFromImportExportStatusCode(code);

  return { ...infoMessageIconProps, icon, className };
};
