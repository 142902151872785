import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Row, Txt } from '@stenajs-webui/core';
import { Icon } from '@stenajs-webui/elements';
import { FC } from 'react';

interface SailingInfoItemProps {
  iconColor?: string;
  iconName: IconDefinition;
  label: string;
  textColor?: string;
}

export const SailingInfoItem: FC<SailingInfoItemProps> = ({
  iconColor,
  iconName,
  label,
  textColor,
}) => {
  return (
    <Row alignItems={'center'} gap={2}>
      <Icon icon={iconName} color={iconColor} size={20} />
      <Txt color={textColor}>{label}</Txt>
    </Row>
  );
};
