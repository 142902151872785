import { ColumnId } from '../../gql/graphql';
import { StoreState } from '../../store/storeState';
import { RehydratePageSettingsAction } from '../page-settings/pageSettingsActions';
import { ColumnFilterActions } from './columnFilterActions';

export interface ColumnsFilterReducerState {
  enabled: Partial<Record<ColumnId, boolean>>;
  width: Partial<Record<ColumnId, number>>;
  order: ColumnId[];
  selectorViewOpen: boolean;
}

export const INITIAL_VALUE: ColumnsFilterReducerState = {
  enabled: {},
  width: {},
  order: [],
  selectorViewOpen: false,
};

export const columnsFilterReducer = (
  state: ColumnsFilterReducerState = INITIAL_VALUE,
  action: ColumnFilterActions | RehydratePageSettingsAction,
): ColumnsFilterReducerState => {
  switch (action.type) {
    case 'PAGE_SETTINGS:REHYDRATE':
      return {
        ...state,
        enabled: action.payload.columnsEnabled,
        width: action.payload.columnsWidth,
        order: action.payload.columnsOrder,
      };
    case 'COLUMN_FILTER:ADD_COLUMN':
      return {
        ...state,
        enabled: {
          ...state.enabled,
          [action.payload.id]: true,
        },
      };
    case 'COLUMN_FILTER:REMOVE_COLUMN':
      return {
        ...state,
        enabled: {
          ...state.enabled,
          [action.payload.id]: false,
        },
      };
    case 'COLUMN_FILTER:SET_COLUMN_ORDER':
      return {
        ...state,
        order: action.payload.order,
      };
    case 'COLUMN_FILTER:CLEAR_COLUMNS':
      return {
        ...state,
        enabled: INITIAL_VALUE.enabled,
        order: INITIAL_VALUE.order,
        width: INITIAL_VALUE.width,
      };
    case 'COLUMN_FILTER:SHOW_COLUMNS_FILTER':
      return {
        ...state,
        selectorViewOpen: true,
      };
    case 'COLUMN_FILTER:HIDE_COLUMNS_FILTER':
      return {
        ...state,
        selectorViewOpen: false,
      };
    case 'COLUMN_FILTER:CHANGE_WIDTH':
      return {
        ...state,
        width: {
          ...state.width,
          [action.payload.id]: action.payload.width,
        },
      };
    default:
      return state;
  }
};

export const getColumnFilter = (state: StoreState): ColumnsFilterReducerState =>
  state.columnsFilter;
export const getColumnFilterEnabled = (state: StoreState) => getColumnFilter(state).enabled;
export const getColumnFilterOrder = (state: StoreState) => getColumnFilter(state).order;
export const getColumnFilterColumnWidth = (state: StoreState, column: ColumnId) =>
  getColumnFilter(state).width[column];
export const getColumnsFilterOpen = (state: StoreState): boolean =>
  getColumnFilter(state).selectorViewOpen;
