import { BasicBooking } from '../../common/graphql/fragments/gql/BasicBooking';
import { StoreState } from '../../store/storeState';
import { SwitchActions } from './switchActions';

export interface TempResult {
  confirmedBooking: BasicBooking;
  waitlistedBooking: BasicBooking;
}

export interface SwitchReducerState {
  tempResult: TempResult | null;
}

const INITIAL_VALUE: SwitchReducerState = {
  tempResult: null,
};

export const switchReducer = (
  state: SwitchReducerState = INITIAL_VALUE,
  action: SwitchActions,
): SwitchReducerState => {
  switch (action.type) {
    case 'SWITCH:CLEAR_TEMP_RESULT':
      return {
        ...state,
        tempResult: null,
      };
    case 'SWITCH:SET_TEMP_RESULT':
      return {
        ...state,
        tempResult: action.payload.tempResult,
      };
    default:
      return state;
  }
};
const getSwitch = (state: StoreState): SwitchReducerState => state.switch;
export const getTempResult = (state: StoreState): TempResult | null => getSwitch(state).tempResult;
