import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { getCookieConsentInitialState, updateConsent } from '../services/cookie-consent/slice';
import { startNewFeatureTour } from '../services/tour/tourActions';
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSagas';

function setupStore() {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: {
      cookieConsent: getCookieConsentInitialState(),
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(sagaMiddleware),
    devTools: { actionCreators: { startNewFeatureTour, updateConsent } },
  });

  const persistor = persistStore(store, { manualPersist: true });
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}

export const { store, persistor } = setupStore();
