import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchBooking, updateBooking, UpdateBookingResult } from '../../../common/api';
import { BasicBooking } from '../../../common/graphql/fragments/gql/BasicBooking';
import { trackEvent } from '../../../common/tracking/trackerService';
import { transformToUpdateBookingFormState } from '../../../common/update-information/utils/transformToUpdateBookingFormState';
import { transformToInput } from '../../../use-cases/manage/details/utils/transformFormData';
import { BookingValidationErrors, UpdateFormState } from '../updateBookingReducer';
import {
  finishSubmit,
  initializeUpdateForm,
  setRegistrationNumberAlert,
  startSubmit,
  UpdateFormRequestSubmitAction,
  UpdateFormUpdateFinishedAction,
} from '../updateFormActions';
import {
  formIdVariants,
  FormInstanceId,
  getFormIsEdited,
  getFormIsSubmitting,
  getFormIsUpdating,
  getUpdateFormState,
} from '../updateFormReducer';

const createInitializeAllFormIdsSideEffects = (booking: BasicBooking) => {
  const updatedFormState = transformToUpdateBookingFormState(booking);

  return formIdVariants.map(formId =>
    put(initializeUpdateForm({ bookingNo: booking.bookingNo, formId }, updatedFormState, booking)),
  );
};

function validate(state: UpdateFormState): BookingValidationErrors | null {
  const validationErrors: BookingValidationErrors = {};

  if (!state.editedFormState?.sailing) {
    validationErrors['sailing'] = 'Select a departure time';
  }

  if (!state.editedFormState?.departureDate) {
    validationErrors['departureDate'] = 'Select a departure date';
  }

  if (Object.keys(validationErrors).length === 0) {
    return null;
  }

  return validationErrors;
}

function* submitUpdateBooking({ bookingNo, formId }: FormInstanceId, state: UpdateFormState) {
  const registrationNumberAlert = state.registrationNumberAlert;
  yield put(startSubmit({ bookingNo, formId }));

  const validationErrors = validate(state);

  const trackAction = 'Save result';

  if (validationErrors != null) {
    trackEvent({
      category: formId,
      action: trackAction,
      label: 'Error - Client validation',
    });

    yield put(
      finishSubmit(
        { bookingNo, formId },
        {
          result: {
            booking: null,
            bookingOutOfDate: false,
            messages: [],
            errors: [],
            success: false,
            waitlistPossible: false,
            warnings: [],
          },
          genericError: 'There was a problem. Please review your changes and try again.',
        },
        validationErrors,
      ),
    );
  } else {
    try {
      const result: UpdateBookingResult = yield call(
        updateBooking,
        transformToInput(state.editedFormState!),
        formId === 'Details',
      );

      if (result.errors.length > 0) {
        trackEvent({
          category: formId,
          action: trackAction,
          label: 'Error - Server validation',
        });

        yield put(
          finishSubmit(
            { bookingNo, formId },
            {
              genericError: 'Something went wrong',
            },
            {},
          ),
        );
      } else {
        const updateBookingResult = result.result!;
        if (updateBookingResult.success) {
          yield all(createInitializeAllFormIdsSideEffects(updateBookingResult.booking!));
          if (registrationNumberAlert && !registrationNumberAlert.appliedArrivalNotepad) {
            yield put(setRegistrationNumberAlert({ bookingNo, formId }, registrationNumberAlert));
          }
        }

        if (updateBookingResult.bookingOutOfDate) {
          const fetchBookingResult: Awaited<ReturnType<typeof fetchBooking>> = yield call(
            fetchBooking,
            bookingNo,
          );

          const booking = fetchBookingResult.success ? fetchBookingResult.value : null;

          if (booking) {
            const updatedFormState = transformToUpdateBookingFormState(booking);

            trackEvent({
              category: formId,
              action: trackAction,
              label: 'Error - BookingOutOfDate',
            });

            yield put(
              finishSubmit(
                { bookingNo, formId },
                {
                  bookingOutOfDate: true,
                  result: updateBookingResult,
                },
                {},
                booking,
                updatedFormState,
              ),
            );
          }
        } else {
          trackEvent({
            category: formId,
            action: trackAction,
            label: updateBookingResult.success ? 'Success' : 'Error',
          });
          yield put(
            finishSubmit(
              { bookingNo, formId },
              {
                result: updateBookingResult,
              },
              {},
            ),
          );
        }
      }
    } catch (e: any) {
      trackEvent({
        category: formId,
        action: trackAction,
        label: 'Error',
      });
      yield put(
        finishSubmit(
          { bookingNo, formId },
          {
            genericError: e.message,
          },
          {},
        ),
      );
    }
  }
}

function* submitFormIfChanged({ bookingNo, formId }: FormInstanceId) {
  const edited: ReturnType<typeof getFormIsEdited> = yield select(getFormIsEdited, {
    bookingNo,
    formId,
  });

  if (edited) {
    trackEvent({
      category: formId,
      action: 'Save',
    });
    const state: ReturnType<typeof getUpdateFormState> = yield select(getUpdateFormState, {
      bookingNo,
      formId,
    });
    if (state) {
      yield submitUpdateBooking({ bookingNo, formId }, state);
    }
  }
}

export function* handleWaitForSubmit({
  payload: {
    id: { formId, bookingNo },
  },
}: UpdateFormRequestSubmitAction) {
  const updating: ReturnType<typeof getFormIsUpdating> = yield select(getFormIsUpdating, {
    formId,
    bookingNo,
  });
  const submitting: ReturnType<typeof getFormIsSubmitting> = yield select(getFormIsSubmitting, {
    formId,
    bookingNo,
  });
  if (!updating) {
    if (!submitting) {
      yield submitFormIfChanged({ formId, bookingNo });
    }
  } else {
    while (true) {
      const { payload }: UpdateFormUpdateFinishedAction = yield take('UPDATE_FORM:UPDATE_FINISHED');
      if (bookingNo === payload.id.bookingNo && formId === payload.id.formId) {
        const formIsUpdating: ReturnType<typeof getFormIsUpdating> = yield select(
          getFormIsUpdating,
          { formId, bookingNo },
        );
        if (!formIsUpdating) {
          yield submitFormIfChanged({ formId, bookingNo });
          return;
        }
      }
    }
  }
}
