import { put, select } from 'redux-saga/effects';
import { UpdatePageSettings2Input } from '../../../gql/graphql';
import { getBookingsPerLoad, getCompactMode } from '../../grid/gridReducer';
import { requestSavePageSettings } from '../pageSettingsActions';
import { transformToDisplayDensity } from './utils/transformDisplayDensity';

export function* updateCompactModePageSettings() {
  const compactMode: ReturnType<typeof getCompactMode> = yield select(getCompactMode);

  const input: UpdatePageSettings2Input = {
    grid: {
      displayDensity: transformToDisplayDensity(compactMode),
    },
  };

  yield put(requestSavePageSettings(input));
}

export function* updateBookingsPerLoadPageSettings() {
  const bookingsPerLoad: ReturnType<typeof getBookingsPerLoad> = yield select(getBookingsPerLoad);

  const input: UpdatePageSettings2Input = {
    grid: {
      bookingsPerLoad,
    },
  };

  yield put(requestSavePageSettings(input));
}
