import styled from '@emotion/styled';
import { Box, Column, Row } from '@stenajs-webui/core';
import { FC } from 'react';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { SearchResultColumn } from '../utils/searchResultColumns';
import { BookingSearchTableRowCell } from './BookingSearchTableRowCell';
import { useIsDesktop } from '../../../../common/hooks/useIsDesktop';
import { cssColor } from '@stenajs-webui/theme';
import { Label } from '@stenajs-webui/elements';
import { getSearchColumnLabel } from '../../../../common/utils/columns/gridColumns';
import { breakpoints } from '../../../../themes/defaultTheme';

export interface BookingSearchTableRowProps {
  onClickResult: () => void;
  item: BasicBooking;
  searchColumns: SearchResultColumn[];
  searchQuery: string;
}

const ResultBoxWrapper = styled(Box)`
  cursor: pointer;

  @media screen and (min-width: ${breakpoints.sm}) {
    border-radius: var(--swui-max-border-radius);
  }

  &:hover {
    background-color: ${cssColor('--silver-ui')};
  }
`;

export const BookingSearchTableRow: FC<BookingSearchTableRowProps> = ({
  onClickResult,
  item,
  searchColumns,
  searchQuery,
}) => {
  const isWide = useIsDesktop();

  return (
    <ResultBoxWrapper className={'t_search_row_' + item.bookingNo} onClick={onClickResult}>
      <Box indent={2} flexDirection={['column', 'row']} alignItems={[null, 'center']} spacing>
        {searchColumns.map(column => {
          return (
            <Column
              className={column.columnId}
              key={column.columnId}
              width={[null, column.remWidth + 'rem']}
            >
              <Row>
                {!isWide && <Label width={90} text={getSearchColumnLabel(column.columnId)} row />}
                <BookingSearchTableRowCell
                  emptyPlaceholder={isWide ? '' : '-'}
                  booking={item}
                  searchQuery={searchQuery}
                  column={column}
                />
              </Row>
            </Column>
          );
        })}
      </Box>
    </ResultBoxWrapper>
  );
};
