import { Heading } from '@stenajs-webui/core';
import { PrimaryButton, stenaPlusCircle } from '@stenajs-webui/elements';
import { DRAGGABLE_CANCEL_CLASSNAME } from '@stenajs-webui/modal';
import { cssColor } from '@stenajs-webui/theme';
import cx from 'classnames';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ModalFooterBase } from '../../common-ui/ModalFooterBase';
import { PRINT_HIDE_CLASSNAME } from '../../globalStyles';
import { Permission } from '../../services/authentication/utils/authUtils';
import { getCreateIntermodalBookingIsFetchingJourney } from '../../services/intermodal/createIntermodalReducer';
import { useIntermodalVehicleTypesBackgroundQuery } from '../../services/intermodal/hooks/useAllIntermodalVehicleTypesAsOptions';
import { useCreateIntermodalBooking } from '../../services/intermodal/hooks/useCreateIntermodalBooking';
import { useGoodsTypesBackgroundQuery } from '../../services/intermodal/hooks/useGoodsTypes';
import { initializeIntermodalCreateThunk } from '../../services/intermodal/thunks/initializeIntermodalCreateThunk';
import { useAppDispatch } from '../../store/appDispatch';
import { IntermodalBookingCreate } from '../../use-cases/intermodal/create/IntermodalBookingCreate';
import { commonProps } from '../../use-cases/manage/details/components/BookingDetailsDecider';
import { Modal } from '../../use-cases/manage/details/components/modal/Modal';
import { PermissionGateModal } from '../home/components/PermissionGateModal';
import { usePageTitle } from '../routes/PageTitle';
import { useRouteModal } from './useRouteModal';
import { v4 as uuid } from 'uuid';
import { trackEvent } from '../tracking/trackerService';

export const IntermodalBookingCreateModal = () => {
  const { onClose } = useRouteModal();
  usePageTitle('Create intermodal booking');
  const dispatch = useAppDispatch();
  const goodsTypesQueryRef = useGoodsTypesBackgroundQuery();
  const vehicleTypesQueryRef = useIntermodalVehicleTypesBackgroundQuery();

  useEffect(() => {
    dispatch(initializeIntermodalCreateThunk({ goodId: uuid() }));
  }, [dispatch]);

  return (
    <Modal
      {...commonProps}
      closeButtonClassName={cx('t_close_intermodal_create', PRINT_HIDE_CLASSNAME)}
      onRequestClose={onClose}
      header={
        <Heading
          variant={'h3'}
          color={cssColor('--lhds-color-ui-900')}
          whiteSpace={'nowrap'}
          className={DRAGGABLE_CANCEL_CLASSNAME}
        >
          Create intermodal booking
        </Heading>
      }
      footer={<IntermodalCreateModalFooter onClose={onClose} />}
    >
      <IntermodalBookingCreate
        goodsTypesQueryRef={goodsTypesQueryRef}
        vehicleTypesQueryRef={vehicleTypesQueryRef}
      />
    </Modal>
  );
};

function IntermodalCreateModalFooter(props: { onClose: () => void }) {
  const isFetchingJourney = useSelector(getCreateIntermodalBookingIsFetchingJourney);
  const { submit, loading } = useCreateIntermodalBooking(props.onClose);

  const onClick = () => {
    trackEvent({ action: 'Click create booking  ', category: 'IntermodalCreate' });
    submit();
  };

  return (
    <ModalFooterBase
      rightContent={
        <PrimaryButton
          disabled={isFetchingJourney}
          label={'Create booking'}
          className={'t_intermodal_create_booking'}
          leftIcon={stenaPlusCircle}
          onClick={onClick}
          loading={loading}
          loadingLabel={'Saving...'}
        />
      }
    />
  );
}

export const IntermodalBookingCreateContainer = () => {
  return (
    <PermissionGateModal permission={Permission.EditIntermodalBookings}>
      <IntermodalBookingCreateModal />
    </PermissionGateModal>
  );
};
