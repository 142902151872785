import { Heading, Row } from '@stenajs-webui/core';
import { Icon, stenaSwitch } from '@stenajs-webui/elements';
import { cssColor } from '@stenajs-webui/theme';
import { FC } from 'react';
import { DRAGGABLE_CANCEL_CLASSNAME } from '../../details/components/modal/BaseModal';
import { ModalHeader } from '../../details/components/modal/ModalHeader';

interface Props {
  errorTitle?: string;
  bookingNo: number;
  onRequestClose: () => void;
}

export const SwitchModalHeader: FC<Props> = ({ errorTitle, bookingNo, onRequestClose }) => (
  <ModalHeader
    header={
      <Row gap={2} indent>
        {errorTitle ? (
          <Heading
            variant={'h4'}
            as={'h1'}
            color={cssColor('--lhds-color-ui-900')}
            whiteSpace={'nowrap'}
            className={DRAGGABLE_CANCEL_CLASSNAME}
          >
            {errorTitle}
          </Heading>
        ) : (
          <>
            <Icon icon={stenaSwitch} size={20} />
            <Heading
              variant={'h4'}
              as={'h1'}
              whiteSpace={'nowrap'}
              className={DRAGGABLE_CANCEL_CLASSNAME}
            >
              Switch booking {bookingNo}
            </Heading>
          </>
        )}
      </Row>
    }
    onRequestClose={onRequestClose}
  />
);
