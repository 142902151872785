export type TourActions =
  | RequestInitializeTourAction
  | StartWelcomeTourAction
  | RequestStopWelcomeTourAction
  | StartNewFeatureTourAction
  | StopNewFeatureTourAction;

export interface RequestInitializeTourPayload {
  introductionTourSeen: boolean;
  seenFeatureIds: string[];
}

export interface RequestInitializeTourAction {
  payload: RequestInitializeTourPayload;
  type: 'TOUR:REQUEST_INITIALIZE';
}

export const requestInitializeTour = (
  payload: RequestInitializeTourPayload,
): RequestInitializeTourAction => ({
  payload,
  type: 'TOUR:REQUEST_INITIALIZE',
});

export interface StartWelcomeTourAction {
  payload: {};
  type: 'WELCOME_TOUR:START';
}

export const startWelcomeTour = (): StartWelcomeTourAction => ({
  payload: {},
  type: 'WELCOME_TOUR:START',
});

export interface RequestStopWelcomeTourAction {
  payload: {};
  type: 'WELCOME_TOUR:REQUEST_STOP';
}

export const requestStopWelcomeTour = (): RequestStopWelcomeTourAction => ({
  payload: {},
  type: 'WELCOME_TOUR:REQUEST_STOP',
});

export interface StartNewFeatureTourAction {
  payload: {
    ids: string[];
  };
  type: 'NEW_FEATURE_TOUR:START';
}

export const startNewFeatureTour = (ids: string[]): StartNewFeatureTourAction => ({
  payload: {
    ids,
  },
  type: 'NEW_FEATURE_TOUR:START',
});

export interface StopNewFeatureTourAction {
  payload: {
    seenIds: string[];
  };
  type: 'NEW_FEATURE_TOUR:STOP';
}

export const stopNewFeatureTour = (ids: string[]): StopNewFeatureTourAction => ({
  payload: {
    seenIds: ids,
  },
  type: 'NEW_FEATURE_TOUR:STOP',
});
