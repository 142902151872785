import { subDays } from 'date-fns';
import { SettingsFragment } from '../../../../common/graphql/fragments/gql/SettingsFragment';
import {
  FilterDateString,
  getAbsoluteFilterDate,
  getRelativeFilterDate,
  resolveFilterDateString,
} from '../../../../common/utils/dates/dateFilter';
import { selectablePlannedFilterStates } from '../../../../common/utils/statusFilter';
import { BookingFilterState, FilterVehicleType } from '../../../../gql/graphql';
import {
  FilterDate,
  FilterState,
  INITIAL_VALUE,
} from '../../../../services/booking-filter/filterReducer';
import {
  isAccountFilterEqual,
  isCargoFilterEqual,
  isDateFilterEqual,
  isFilterStateEqual,
  isRouteFilterEqual,
  isTimeFilterEqual,
  isVehicleFilterEqual,
  transformDefaultFiltersFromSettings,
} from '../../../../services/booking-filter/utils/filterUtils';

interface Filter {
  filterStates: BookingFilterState[];
  date?: FilterDate;
  vehicleIds?: FilterVehicleType[];
}

export type FilterStateFactory = (routeCodes?: string[]) => FilterState;

export const createFilterFactory = ({
  filterStates,
  date = {},
  vehicleIds = [],
}: Filter): FilterStateFactory => {
  return routeCodes => {
    const filterState: FilterState = {
      ...INITIAL_VALUE.filter,
      filterStates: filterStates,
      routeCodeIds: routeCodes ?? [],
      vehicleIds: vehicleIds,
      date,
    };

    return filterState;
  };
};

export const createDefaultFilter = (accountSettings: SettingsFragment): FilterState =>
  transformDefaultFiltersFromSettings(accountSettings);

export const createNotCheckedInFilterFactory = createFilterFactory({
  filterStates: [BookingFilterState.CloseToCheckin],
});

export const createPendingApprovalFilterFactory = createFilterFactory({
  filterStates: [BookingFilterState.PendingApproval],
});

export const createDayFilterFactory = (date: FilterDateString) =>
  createFilterFactory({
    filterStates: [
      ...selectablePlannedFilterStates,
      BookingFilterState.Shipped,
      BookingFilterState.NoShow,
    ],
    date: { from: getRelativeFilterDate(date), to: getRelativeFilterDate(date) },
  });

export const ON_QUAY_DAYS_BACK = 28;

export const createOnQuayFilterFactory = (date: FilterDateString, today = new Date()) =>
  createFilterFactory({
    filterStates: [BookingFilterState.OnQuay],
    vehicleIds: [FilterVehicleType.Unaccompanied],
    date: {
      from: getAbsoluteFilterDate(subDays(resolveFilterDateString(date, today), ON_QUAY_DAYS_BACK)),
      to: getRelativeFilterDate(date),
    },
  });

export const createIncomingFilterFactory = (date: FilterDateString, today = new Date()) =>
  createFilterFactory({
    filterStates: [BookingFilterState.Incoming],
    vehicleIds: [FilterVehicleType.Unaccompanied],
    date: {
      from: getAbsoluteFilterDate(subDays(resolveFilterDateString(date, today), ON_QUAY_DAYS_BACK)),
      to: getRelativeFilterDate(date),
    },
  });

export const createOnQuayAndIncomingFilterFactory = (date: FilterDateString, today = new Date()) =>
  createFilterFactory({
    filterStates: [BookingFilterState.OnQuay, BookingFilterState.Incoming],
    vehicleIds: [FilterVehicleType.Unaccompanied],
    date: {
      from: getAbsoluteFilterDate(subDays(resolveFilterDateString(date, today), ON_QUAY_DAYS_BACK)),
      to: getRelativeFilterDate(date),
    },
  });

export const isFilterStateActive = (
  currentFilter: FilterState,
  filterStateFactory: FilterStateFactory,
): boolean => {
  const nextFilter: FilterState = filterStateFactory();
  return (
    isFilterStateEqual(currentFilter, nextFilter) &&
    isDateFilterEqual(currentFilter, nextFilter) &&
    isTimeFilterEqual(currentFilter, nextFilter) &&
    isCargoFilterEqual(currentFilter, nextFilter) &&
    isAccountFilterEqual(currentFilter, nextFilter) &&
    isVehicleFilterEqual(currentFilter, nextFilter)
  );
};

export const isDayFilterActive = (
  currentFilter: FilterState,
  filterStateFactory: FilterStateFactory,
): boolean => {
  return isFilterStateActive(currentFilter, filterStateFactory);
};

export const isDefaultFilterActive = (currentFilter: FilterState, defaultFilter: FilterState) =>
  isFilterStateEqual(currentFilter, defaultFilter) &&
  isDateFilterEqual(currentFilter, defaultFilter) &&
  isTimeFilterEqual(currentFilter, defaultFilter) &&
  isRouteFilterEqual(currentFilter, defaultFilter) &&
  isCargoFilterEqual(currentFilter, defaultFilter) &&
  isAccountFilterEqual(currentFilter, defaultFilter) &&
  isVehicleFilterEqual(currentFilter, defaultFilter);
