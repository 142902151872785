export type SidebarActions =
  | OpenSidebarAction
  | CloseSidebarAction
  | ToggleSidebarAction
  | EnableRailSidebarAction
  | DisableRailSidebarAction;

interface OpenSidebarAction {
  type: 'SIDEBAR:OPEN';
}

interface CloseSidebarAction {
  type: 'SIDEBAR:CLOSE';
}

interface ToggleSidebarAction {
  type: 'SIDEBAR:TOGGLE';
}

interface EnableRailSidebarAction {
  type: 'SIDEBAR:ENABLE_RAIL';
}

interface DisableRailSidebarAction {
  type: 'SIDEBAR:DISABLE_RAIL';
}

export const openSidebar = (): OpenSidebarAction => ({ type: 'SIDEBAR:OPEN' });
export const closeSidebar = (): CloseSidebarAction => ({ type: 'SIDEBAR:CLOSE' });
export const toggleSidebar = (): ToggleSidebarAction => ({ type: 'SIDEBAR:TOGGLE' });
export const enableSidebarRail = (): EnableRailSidebarAction => ({ type: 'SIDEBAR:ENABLE_RAIL' });
export const disableSidebarRail = (): DisableRailSidebarAction => ({
  type: 'SIDEBAR:DISABLE_RAIL',
});
