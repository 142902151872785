import { HotkeyGroup, HotkeyInformation, Hotkeys } from './hotkeys';

const hotkeys: Record<Hotkeys, HotkeyInformation> = {
  [Hotkeys.HotkeysOverView]: {
    binding: Hotkeys.HotkeysOverView,
    action: 'Show this overview',
    group: HotkeyGroup.General,
  },
  [Hotkeys.CloseWindows]: {
    binding: Hotkeys.CloseWindows,
    action: 'Close windows',
    group: HotkeyGroup.General,
  },
  [Hotkeys.RefreshPage]: {
    binding: Hotkeys.RefreshPage,
    action: 'Refresh page',
    group: HotkeyGroup.General,
  },
  [Hotkeys.BookingSearch]: {
    binding: Hotkeys.BookingSearch,
    action: 'Open search',
    group: HotkeyGroup.FiltersCreate,
  },
  [Hotkeys.OpenFilterMenu]: {
    binding: Hotkeys.OpenFilterMenu,
    action: 'Open filter menu',
    group: HotkeyGroup.FiltersCreate,
  },
  [Hotkeys.AllBookingsToday]: {
    binding: Hotkeys.AllBookingsToday,
    action: 'Show all bookings today',
    group: HotkeyGroup.FiltersCreate,
  },
  [Hotkeys.ClearAllFilter]: {
    binding: Hotkeys.ClearAllFilter,
    action: 'Clear all filters',
    group: HotkeyGroup.FiltersCreate,
  },
  [Hotkeys.ApplyDefaultFilters]: {
    binding: Hotkeys.ApplyDefaultFilters,
    action: 'Match default filters',
    group: HotkeyGroup.FiltersCreate,
  },
  [Hotkeys.BookingCreate]: {
    binding: Hotkeys.BookingCreate,
    action: 'Open add booking',
    group: HotkeyGroup.FiltersCreate,
  },
  [Hotkeys.BookingDetails]: {
    binding: Hotkeys.BookingDetails,
    action: 'View booking details',
    group: HotkeyGroup.GridBooking,
  },
  [Hotkeys.BookingSaveChanges]: {
    binding: Hotkeys.BookingSaveChanges,
    action: 'Save changes',
    group: HotkeyGroup.GridBooking,
  },
  [Hotkeys.BookingUndoChanges]: {
    binding: Hotkeys.BookingUndoChanges,
    action: 'Undo changes',
    group: HotkeyGroup.GridBooking,
  },
  [Hotkeys.BookingCancel]: {
    binding: Hotkeys.BookingCancel,
    action: 'Cancel booking',
    group: HotkeyGroup.GridBooking,
  },
  [Hotkeys.BookingCopyClipboard]: {
    binding: Hotkeys.BookingCopyClipboard,
    action: 'Copy booking to clipboard',
    group: HotkeyGroup.GridBooking,
  },
};

export const getHotkeyByGroup = (byGroup?: HotkeyGroup): HotkeyInformation[] => {
  if (byGroup === undefined) {
    return Object.values(hotkeys);
  }

  return Object.values(hotkeys).filter(key => key.group === byGroup);
};
