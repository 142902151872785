export const enablePrintScrollOffsetFix = () => {
  let lastScroll = window.scrollX;
  const beforePrint = () => {
    lastScroll = window.scrollX;
    window.scrollTo({ left: 0 });
  };
  const afterPrint = () => {
    window.scrollTo({ left: lastScroll });
  };

  window.addEventListener('beforeprint', beforePrint);
  window.addEventListener('afterprint', afterPrint);

  return () => {
    window.removeEventListener('beforeprint', beforePrint);
    window.removeEventListener('afterprint', afterPrint);
  };
};
