import { ComponentType } from 'react';
import SvgArtic from '../../../../svgs/vehicle/AR.svg?react';
import SvgContainer20_29 from '../../../../svgs/vehicle/C2.svg?react';
import SvgContainer30_39 from '../../../../svgs/vehicle/C3.svg?react';
import SvgContainer40_49 from '../../../../svgs/vehicle/C4.svg?react';
import SvgCarCarrier from '../../../../svgs/vehicle/CC.svg?react';
import SvgFootPassenger from '../../../../svgs/vehicle/FP.svg?react';
import SvgFridgedTrailer from '../../../../svgs/vehicle/FT.svg?react';
import SvgOnDeckParcels from '../../../../svgs/vehicle/G1.svg?react';
import SvgOnDeckPallets from '../../../../svgs/vehicle/G2.svg?react';
import SvgHorseBox from '../../../../svgs/vehicle/HB.svg?react';
import SvgLorry from '../../../../svgs/vehicle/LO.svg?react';
import SvgRailWagon2Xl from '../../../../svgs/vehicle/R2.svg?react';
import SvgRailWagon4Xl from '../../../../svgs/vehicle/R4.svg?react';
import SvgReefer from '../../../../svgs/vehicle/RE.svg?react';
import SvgRoadTrain from '../../../../svgs/vehicle/RT.svg?react';
import SvgSwopBody7 from '../../../../svgs/vehicle/S1.svg?react';
import SvgSwopBody15 from '../../../../svgs/vehicle/S2.svg?react';
import SvgSteelTracked from '../../../../svgs/vehicle/SI.svg?react';
import SvgSoloTractorTradeChassis from '../../../../svgs/vehicle/SU.svg?react';
import SvgTradeCar from '../../../../svgs/vehicle/T1.svg?react';
import SvgTradeCaravan from '../../../../svgs/vehicle/T2.svg?react';
import SvgTradeMachine from '../../../../svgs/vehicle/T4.svg?react';
import SvgTradeMiniBus from '../../../../svgs/vehicle/T5.svg?react';
import SvgTrailer from '../../../../svgs/vehicle/TR.svg?react';
import SvgVan from '../../../../svgs/vehicle/VA.svg?react';

export enum VehicleTypeCode {
  Artic = 'AR',
  Container20_29 = 'C2',
  Container30_39 = 'C3',
  Container40_49 = 'C4',
  CarCarrier = 'CC',
  FridgedTrailer = 'FT',
  FootPassenger = 'FP',
  OnDeckParcels = 'G1',
  OnDeckPallets = 'G2',
  HorseCarridge = 'HB',
  Lorry = 'LO',
  RailWagon2Xl = 'R2',
  RailWagon4Xl = 'R4',
  Reefer = 'RE',
  RoadTrain = 'RT',
  SwopBody7 = 'S1',
  SwopBody15 = 'S2',
  SoloTractor = 'SU',
  SteelTracked = 'SI',
  TradeCar = 'T1',
  TradeCaravan = 'T2',
  TradeChassis = 'T3',
  TradeMachine = 'T4',
  TradeMiniBus = 'T5',
  Trailer = 'TR',
  UsedTradeCar = 'U1',
  UsedTradeChassis = 'U3',
  UsedTradeMachine = 'U4',
  UsedTradeMiniBus = 'U5',
  Van = 'VA',
}

export const resolveVehicleSVG = (vehicleType: string | null): ComponentType<any> | undefined => {
  switch (vehicleType) {
    case VehicleTypeCode.Artic:
      return SvgArtic;
    case VehicleTypeCode.Container20_29:
      return SvgContainer20_29;
    case VehicleTypeCode.Container30_39:
      return SvgContainer30_39;
    case VehicleTypeCode.Container40_49:
      return SvgContainer40_49;
    case VehicleTypeCode.CarCarrier:
      return SvgCarCarrier;
    case VehicleTypeCode.FridgedTrailer:
      return SvgFridgedTrailer;
    case VehicleTypeCode.FootPassenger:
      return SvgFootPassenger;
    case VehicleTypeCode.OnDeckParcels:
      return SvgOnDeckParcels;
    case VehicleTypeCode.OnDeckPallets:
      return SvgOnDeckPallets;
    case VehicleTypeCode.HorseCarridge:
      return SvgHorseBox;
    case VehicleTypeCode.Lorry:
      return SvgLorry;
    case VehicleTypeCode.RailWagon2Xl:
      return SvgRailWagon2Xl;
    case VehicleTypeCode.RailWagon4Xl:
      return SvgRailWagon4Xl;
    case VehicleTypeCode.Reefer:
      return SvgReefer;
    case VehicleTypeCode.RoadTrain:
      return SvgRoadTrain;
    case VehicleTypeCode.SwopBody7:
      return SvgSwopBody7;
    case VehicleTypeCode.SwopBody15:
      return SvgSwopBody15;
    case VehicleTypeCode.SoloTractor:
      return SvgSoloTractorTradeChassis;
    case VehicleTypeCode.SteelTracked:
      return SvgSteelTracked;
    case VehicleTypeCode.TradeCar:
      return SvgTradeCar;
    case VehicleTypeCode.TradeCaravan:
      return SvgTradeCaravan;
    case VehicleTypeCode.TradeChassis:
      return SvgSoloTractorTradeChassis;
    case VehicleTypeCode.TradeMachine:
      return SvgTradeMachine;
    case VehicleTypeCode.TradeMiniBus:
      return SvgTradeMiniBus;
    case VehicleTypeCode.Trailer:
      return SvgTrailer;
    case VehicleTypeCode.UsedTradeCar:
      return SvgTradeCar;
    case VehicleTypeCode.UsedTradeChassis:
      return SvgSoloTractorTradeChassis;
    case VehicleTypeCode.UsedTradeMachine:
      return SvgTradeMachine;
    case VehicleTypeCode.UsedTradeMiniBus:
      return SvgTradeMiniBus;
    case VehicleTypeCode.Van:
      return SvgVan;
    default:
      return undefined;
  }
};
