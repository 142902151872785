import { TypePolicies } from '@apollo/client/cache/inmemory/policies';

export const typePolicies: TypePolicies = {
  AccountSearchType: { merge: true },
  AppVersionSearchType: { merge: true },
  ArrivalNotepadSearchType: { merge: true },
  BookingsSearchType: { merge: true },
  ConfigurationSearchType: { merge: true },
  ContactsSearchType: { merge: true },
  DashboardSearchType: { merge: true },
  PaymentSearchType: { merge: true },
  RoutesSearchType: { merge: true },
  SailingsSearchType: { merge: true },
  IntermodalBookingsSearchType: { merge: true },
  IntermodalRoutesSearchType: { merge: true },
  SearchSearchType: { merge: true },
  TravelledVehicleSearchType: { merge: true },
  UrgentMessagesSearchType: { merge: true },
  VehicleTypeSearchType: { merge: true },
  Viewer: { merge: true },
  IntermodalJourney: { merge: true },
};
