import { Animal } from '../../use-cases/manage/grid/utils/optionTransformers';
import { animalDropdownNoOption } from '../graphql/useAnimalTypesOptions';
import { Texts } from '../texts';

export const resolveAnimalsInformationText = (livestock: boolean | null): string | undefined =>
  livestock ? Texts.AnimalsInformation : undefined;

export const includeYesOption = (livestockType?: Animal | null, livestock?: boolean): boolean => {
  if (livestock) {
    return !livestockType ? true : livestockType.value === animalDropdownNoOption.value;
  } else {
    return false;
  }
};
