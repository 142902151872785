import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import {
  InformationMessage,
  InformationMessageType,
} from '../../../../common-ui/information-message/informationMessageUtils';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { switchBookingMutation } from '../../../../common/graphql/switchBookingQuery';
import { transformToUpdateBookingFormState } from '../../../../common/update-information/utils/transformToUpdateBookingFormState';
import { BookingFilterState } from '../../../../gql/graphql';
import { clearModals } from '../../../../services/modal/modalActions';
import {
  addBookingResultNotification,
  BookingResult,
  requestShowBookingResultToast,
} from '../../../../services/notifications/notificationsActions';
import { setTempSwitchResult } from '../../../../services/switch/switchActions';
import { initializeUpdateForm } from '../../../../services/update-form/updateFormActions';
import { formIdVariants } from '../../../../services/update-form/updateFormReducer';
import {
  getSwitchSubmitInformation,
  prepareSubmitParams,
  SWITCH_SUBMIT_SHOW,
  SwitchSubmitInformation,
} from '../utils/switchUtils';
import { trackEvent } from '../../../../common/tracking/trackerService';

const getSwitchBookingVariable = (booking1: BasicBooking, booking2: BasicBooking) =>
  booking1.status === BookingFilterState.Confirmed
    ? prepareSubmitParams(booking1, booking2)
    : prepareSubmitParams(booking2, booking1);

export const useSwitch = () => {
  const dispatch = useDispatch();

  const [statusInformationList, setStatusInformationList] = useState<InformationMessage[]>([]);

  const [submitSwitch, { loading }] = useMutation(switchBookingMutation);

  const switchBookings = useCallback(
    async (booking1: BasicBooking, booking2: BasicBooking | null) => {
      if (!booking2) {
        trackEvent({
          category: 'Switch',
          action: 'Error',
          label: 'Second booking not selected',
        });

        setStatusInformationList([
          {
            message: 'Select second booking',
            type: InformationMessageType.HardWarning,
          },
        ]);
        return;
      }

      trackEvent({
        category: 'Switch',
        action: 'Perform switch',
        label: `${booking1.status} --> ${booking2.status}`,
      });

      const variables = getSwitchBookingVariable(booking1, booking2);

      const { waitlistedBookingNo, confirmedBookingNo } = variables;

      try {
        const result = await submitSwitch({ variables });
        if (result?.data?.switchBookings) {
          setStatusInformationList([]);
          const switchSubmitInformation: SwitchSubmitInformation = getSwitchSubmitInformation(
            result.data.switchBookings,
          );

          if (switchSubmitInformation.status === SWITCH_SUBMIT_SHOW.SUCCESS) {
            const { confirmedBooking, waitlistedBooking } = result.data.switchBookings;

            batch(() => {
              formIdVariants.forEach(formId => {
                dispatch(
                  initializeUpdateForm(
                    { bookingNo: confirmedBooking!.bookingNo, formId },
                    transformToUpdateBookingFormState(confirmedBooking!),
                    confirmedBooking!,
                  ),
                );

                dispatch(
                  initializeUpdateForm(
                    { bookingNo: waitlistedBooking!.bookingNo, formId },
                    transformToUpdateBookingFormState(waitlistedBooking!),
                    waitlistedBooking!,
                  ),
                );
              });

              const bookingResult: BookingResult = {
                type: 'switch-success',
                confirmedBookingNo: confirmedBooking!.bookingNo,
                waitlistedBookingNo: waitlistedBooking!.bookingNo,
                sailing: confirmedBooking!.sailing,
                messages: switchSubmitInformation.messages,
                warnings: switchSubmitInformation.warnings,
              };
              dispatch(requestShowBookingResultToast(bookingResult));
              dispatch(addBookingResultNotification(bookingResult));

              dispatch(
                setTempSwitchResult({
                  confirmedBooking: confirmedBooking!,
                  waitlistedBooking: waitlistedBooking!,
                }),
              );
              dispatch(clearModals());
            });
          } else {
            const errors = switchSubmitInformation.errors;

            trackEvent({ category: 'Switch', action: 'Error' });

            if (errors && errors.length > 0) {
              const bookingResult: BookingResult = {
                type: 'switch-fail',
                confirmedBookingNo,
                waitlistedBookingNo,
                errors,
              };
              dispatch(requestShowBookingResultToast(bookingResult));
              dispatch(addBookingResultNotification(bookingResult));

              setStatusInformationList(
                errors.map(message => ({
                  type: InformationMessageType.HardWarning,
                  message,
                })),
              );
            } else {
              const bookingResult: BookingResult = {
                type: 'switch-fail',
                confirmedBookingNo,
                waitlistedBookingNo,
                errors: ['Something went wrong, please try again.'],
              };
              dispatch(requestShowBookingResultToast(bookingResult));
              dispatch(addBookingResultNotification(bookingResult));

              setStatusInformationList([
                {
                  message: 'Something went wrong, please try again.',
                  type: InformationMessageType.HardWarning,
                },
              ]);
            }
          }
          return result.data.switchBookings;
        }
      } catch (error) {
        trackEvent({ category: 'Switch', action: 'Error' });

        dispatch(
          requestShowBookingResultToast({
            type: 'switch-fail',
            confirmedBookingNo,
            waitlistedBookingNo,
            errors: ['Something went wrong, please try again.'],
          }),
        );

        setStatusInformationList([
          {
            message: 'Something went wrong, please try again.',
            type: InformationMessageType.HardWarning,
          },
        ]);
      }
    },
    [dispatch, submitSwitch],
  );

  return {
    loading,
    statusInformationList,
    switchBookings,
    setStatusInformationList,
  };
};
