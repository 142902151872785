import { FC, ReactNode } from 'react';
import { Column, Row, SeparatorLine, StandardText } from '@stenajs-webui/core';
import { Colors } from '../colors';
import { formatDateTime, resolveDateFormat } from '../utils/dates/dateUtils';

export interface ErrorCardProps {
  title: ReactNode;
  content?: ReactNode;
}

export const ErrorCard: FC<ErrorCardProps> = ({ content, title }) => (
  <Column background={Colors.UI8} shadow={'box'} width={960} maxWidth={960}>
    <Row spacing={2} indent={2} justifyContent={'space-between'}>
      <StandardText>{title}</StandardText>
      <StandardText>{formatDateTime(new Date(), resolveDateFormat())}</StandardText>
    </Row>
    <SeparatorLine />
    <Row spacing={2} indent={2}>
      {content}
    </Row>
  </Column>
);
