import { IntermodalVehicleType } from '../../common/graphql/fragments/gql/IntermodalBooking';
import { CommonIntermodalFormModel } from './common/types';

export function getVehicleDimensions(
  vehicleType: IntermodalVehicleType,
): Partial<CommonIntermodalFormModel> {
  return {
    length: String(vehicleType.defaultLength),
    emptyWeight: String(vehicleType.defaultWeight),
    height: String(vehicleType.defaultHeight),
    width: String(vehicleType.defaultWidth),
  };
}
