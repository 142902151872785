import { FC } from 'react';
import { PaymentStatus } from '../../../../gql/graphql';
import { BookingStatusTag } from '../../../../common-ui/BookingStatusTag';
import { PaymentStatusLabel } from '../../../../common-ui/payment/PaymentStatusLabel';
import {
  BookingModalTitleBase,
  BookingModalTitleBaseProps,
} from '../../../../common-ui/BookingModalTitleBase';
import { DetailedBooking } from '../../../../common/graphql/fragments/gql/DetailedBooking';

interface BookingDetailsHeaderProps
  extends Pick<BookingModalTitleBaseProps, 'className' | 'bookingActions'> {
  booking: DetailedBooking;
}

export const BookingDetailsHeader: FC<BookingDetailsHeaderProps> = ({ booking, ...props }) => {
  const { bookingStatus, bookingQuayStatus } = booking;
  const paymentStatus = booking.paymentStatus ?? PaymentStatus.NotApplicable;

  return (
    <BookingModalTitleBase
      {...props}
      bookingNo={booking.bookingNo}
      bookingStatus={<BookingStatusTag status={bookingStatus} quayStatus={bookingQuayStatus} />}
      paymentStatus={
        paymentStatus !== PaymentStatus.NotApplicable && (
          <PaymentStatusLabel status={paymentStatus} />
        )
      }
    />
  );
};
