import { MINIMUM_SEARCH_LENGTH } from '../../../../common/graphql/useSearchBookingsResult';
import { InvalidSearchQueryReason } from '../../../../common/utils/stringUtil';

export const getSearchQueryInformation = (invalidSearchQueryReason: InvalidSearchQueryReason) => {
  switch (invalidSearchQueryReason) {
    case 'length_too_short':
      return `Please enter at least ${MINIMUM_SEARCH_LENGTH} characters to search`;
    case 'invalid_letter':
      return 'Only a-z, A-Z, 0-9, !#$%&*()_=|:;+^- are allowed';
  }
};
