import { StoreState } from '../../store/storeState';
import { CreateResult } from '../create/createFormActions';
import { MultiCreateActions } from './multiCreateActions';

export interface TempResult {
  result: CreateResult | null;
}

export interface MultiCreateReducerState {
  showPanel: boolean;
  tempResult: TempResult | null;
}

const INITIAL_VALUE: MultiCreateReducerState = {
  showPanel: false,
  tempResult: null,
};

export const multiCreateReducer = (
  state: MultiCreateReducerState = INITIAL_VALUE,
  action: MultiCreateActions,
) => {
  switch (action.type) {
    case 'MULTIPLE_CREATE:SHOW':
      return {
        ...state,
        showPanel: true,
      };
    case 'MULTIPLE_CREATE:HIDE':
      return {
        ...state,
        showPanel: false,
      };
    case 'CREATE_FORM:REQUEST_CLEANUP_FORM':
    case 'MULTIPLE_CREATE:CLEAR_TEMP_RESULT':
      return {
        ...state,
        tempResult: null,
      };
    case 'CREATE_FORM:SET_CREATE_RESULT':
      return {
        ...state,
        tempResult: {
          result: action.payload.result,
        },
      };
    default:
      return state;
  }
};
const getMultiCreate = (state: StoreState): MultiCreateReducerState => state.multiCreate;
export const isShowing = (state: StoreState): boolean => getMultiCreate(state).showPanel;
export const getTempResult = (state: StoreState): TempResult | null =>
  getMultiCreate(state).tempResult;

export const getRecentCreatedBookingNos = (state: StoreState): number[] => {
  const tempResult = getTempResult(state);
  if (tempResult?.result?.result) {
    return tempResult.result.result
      .filter(result => !!result.booking)
      .map(result => result.booking!.bookingNo);
  }
  return [];
};
