import { graphql } from '../../../gql';

export const userSubscriptionsQuery = graphql(/*GraphQL*/ `
  query UserSubscriptionsQuery {
    viewer {
      account {
        byEmail {
          userSubscriptions {
            routes {
              id
            }
            ...UserSubscriptions
          }
        }
      }
    }
  }
`);

export const UserSubscriptionsFragment = graphql(/*GraphQL*/ `
  fragment UserSubscriptions on UserSubscriptions {
    bookingUpdatedInFreightPortal {
      ...UserSubscription
    }
    waitingChangedToConfirmed {
      ...UserSubscription
    }
    checkInDeviation {
      ...UserSubscription
    }
    sailingCancelled {
      ...UserSubscription
    }
    arrivalTimeChanged {
      ...UserSubscription
    }
    noShow {
      ...UserSubscription
    }
    lateCancellations {
      ...UserSubscription
    }
    unitsSummaryPerSailing {
      ...UserSubscription
    }
    dailySailingSummary {
      ...UserSubscription
    }
  }
`);

export const UserSubscriptionFragment = graphql(/*GraphQL*/ `
  fragment UserSubscription on UserSubscription {
    emailEnabled
    smsEnabled
    freightPortalEnabled
  }
`);
