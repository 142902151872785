export type SearchActions = ShowSearchOverlayAction | HideSearchOverlayAction;

export interface ShowSearchOverlayAction {
  payload: {};
  type: 'SEARCH_QUERY:SHOW_OVERLAY';
}

export const showSearchOverlay = (): ShowSearchOverlayAction => ({
  payload: {},
  type: 'SEARCH_QUERY:SHOW_OVERLAY',
});

export interface HideSearchOverlayAction {
  payload: {};
  type: 'SEARCH_QUERY:HIDE_OVERLAY';
}

export const hideSearchOverlay = (): HideSearchOverlayAction => ({
  payload: {},
  type: 'SEARCH_QUERY:HIDE_OVERLAY',
});
