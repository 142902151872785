import { FC } from 'react';
import { BookingDetailsHeader } from './BookingDetailsHeader';
import { DetailedBooking } from '../../../../common/graphql/fragments/gql/DetailedBooking';
import { BookingActionMenuButton } from './BookingActionMenuButton';

interface BookingModalTitleViewProps {
  booking: DetailedBooking;
}

export const BookingModalTitleView: FC<BookingModalTitleViewProps> = ({ booking }) => (
  <BookingDetailsHeader
    className={'t_booking_details_view_header'}
    booking={booking}
    bookingActions={<BookingActionMenuButton booking={booking} trackerCategory={'Details'} />}
  />
);
