import { css } from '@emotion/react';
import { ResponsiveValue, TLengthStyledSystem } from 'styled-system';
import { zIndices } from './common/zIndices';

export const globalCardWrapperIndent: ResponsiveValue<boolean | TLengthStyledSystem> = [0, 3];
export const globalContentWrapperIndent: ResponsiveValue<boolean | TLengthStyledSystem> = [2, 3];
export const PRINT_HIDE_CLASSNAME = 'print-hide';
export const PRINT_ROW_CLASSNAME = 'print-row';

export const globalStyles = css`
  body {
    --bg-color: var(--silver-lighter);
    background-color: var(--bg-color);
    margin: 0;
    padding: 0;
  }

  html {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  #root {
    isolation: isolate;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  @media print {
    body {
      --bg-color: white;
      font-size: 16px;
      display: block;
    }

    a {
      text-decoration: underline;
    }

    .ReactModalPortal .ReactModal__Content > div {
      transform: translate(0, 0) !important;
    }

    .${PRINT_HIDE_CLASSNAME} {
      display: none !important;
    }

    .${PRINT_ROW_CLASSNAME} {
      flex-direction: row !important;
    }
  }

  body.manage-page {
    display: table;

    @media print {
      display: block;
    }
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModalPortal {
    .ReactModal__Overlay {
      z-index: ${zIndices.modal};
    }
  }
`;
