import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FormInstanceId } from '../../services/update-form/updateFormReducer';
import { BookingDetailsDecider } from '../../use-cases/manage/details/components/BookingDetailsDecider';
import { useActiveRow } from '../../use-cases/manage/grid/hooks/useActiveRow';
import { BookingFormInstanceIdProvider } from '../update-information/BookingFormInstanceIdProvider';
import { useRouteModal } from './useRouteModal';
import { usePageTitle } from '../routes/PageTitle';

export const BookingDetailsModal: FC<{ isEditing?: boolean }> = ({ isEditing = false }) => {
  const { bookingNo: bookingNoString } = useParams<'bookingNo'>();

  const bookingNo = Number(bookingNoString);
  const { onClose } = useRouteModal();
  usePageTitle(`Booking ${bookingNo}`);

  useActiveRow({ bookingNo });

  const formInstanceId = useMemo<FormInstanceId>(
    () => ({
      bookingNo,
      formId: 'Details',
    }),
    [bookingNo],
  );

  return (
    <BookingFormInstanceIdProvider value={formInstanceId} key={bookingNo}>
      <BookingDetailsDecider bookingNo={bookingNo} isEditing={isEditing} onRequestClose={onClose} />
    </BookingFormInstanceIdProvider>
  );
};
