import { History } from 'history';
import { URLHashData } from '../../common/booking-filters/types';
import { isJson, jsonParseWithDates } from './jsonUtils';

export const urlDecodeObject = (str: string): URLHashData | null => {
  const rawUrlData = decodeURI(str);
  return isJson(rawUrlData) ? jsonParseWithDates(rawUrlData) : null;
};

export const getFilterFromLegacyUrlHash = (h: History): string => h.location.hash.substring(1);

export const urlGetSearchString = (h: History): string => h.location.search.substring(1);

export const urlReplaceSearchString = (h: History, query: string) => {
  h.replace({ ...h.location, search: query }, h.location.state);
};
