import { StoreState } from '../../store/storeState';
import { FrontendOutOfDateActions } from './frontendOutOfDateActions';

export interface FrontendOutOfDateReducerState {
  pollingEnabled: boolean;
  isOutOfDate: boolean;
}

const INITIAL_VALUE: FrontendOutOfDateReducerState = {
  pollingEnabled: false,
  isOutOfDate: false,
};

export const frontendOutOfDateReducer = (
  state: FrontendOutOfDateReducerState = INITIAL_VALUE,
  action: FrontendOutOfDateActions,
): FrontendOutOfDateReducerState => {
  switch (action.type) {
    case 'FRONTEND_OUT_OF_DATE:START_POLLING':
      return {
        ...state,
        pollingEnabled: true,
      };
    case 'FRONTEND_OUT_OF_DATE:STOP_POLLING':
      return {
        ...state,
        pollingEnabled: false,
      };
    case 'FRONTEND_OUT_OF_DATE:SET':
      return {
        ...state,
        isOutOfDate: action.payload.isOutOfDate,
      };
    default:
      return state;
  }
};

export const getFrontendOutOfDateState = (state: StoreState) => state.frontendOutOfDate;
export const getFrontendOutOfDatePollingEnabled = (state: StoreState) =>
  getFrontendOutOfDateState(state).pollingEnabled;
export const getFrontendIsOutOfDate = (state: StoreState) =>
  getFrontendOutOfDateState(state).isOutOfDate;
