import { Row, Space } from '@stenajs-webui/core';
import { FC, ReactElement, ReactNode } from 'react';
import { components, OptionProps } from 'react-select';
import { SailingCategoryDotWithText } from '../../../../common-ui/sailing-category/SailingCategoryDotWithText';
import { Sailing, Sailing_sailingType } from '../../../../common/graphql/fragments/gql/Sailing';
import { DropdownOption } from '../../../manage/grid/utils/optionTransformers';

export interface DepartureTimeWithSailingTypeProps {
  sailingType: Sailing_sailingType;
  children?: ReactNode;
}

const DepartureTimeWithSailingType: FC<DepartureTimeWithSailingTypeProps> = ({
  children,
  sailingType,
}) => {
  return (
    <Row alignItems="center">
      <Row width={'3em'}>{children}</Row>
      <Space />
      <SailingCategoryDotWithText type={sailingType} />
    </Row>
  );
};

export const DepartureTimeDropdownOption = ({
  children,
  ...optionProps
}: OptionProps<DropdownOption<Sailing>, false>): ReactElement => (
  <components.Option {...optionProps}>
    <DepartureTimeWithSailingType
      sailingType={optionProps.data.data.sailingType}
      children={children}
    />
  </components.Option>
);
