import { useLayoutEffect, useRef, useState } from 'react';

export const useOverflowingBox = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useLayoutEffect(() => {
    const divElem = ref.current;

    if (divElem) {
      const resizeObserver = new ResizeObserver(entries => {
        const onlyEntry = entries[0];

        if (onlyEntry) {
          const hasOverflow =
            Math.round(onlyEntry.contentRect.width) < onlyEntry.target.scrollWidth;
          setIsOverflowing(hasOverflow);
        }
      });

      resizeObserver.observe(divElem);

      return () => {
        resizeObserver.unobserve(divElem);
      };
    }

    return () => {};
  });

  return { ref, isOverflowing };
};
