import { QueryReference, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { GoodsTypesQueryQuery } from '../../../gql/graphql';
import { goodsTypesQuery } from '../graphql/GoodsTypesQuery';

export interface GoodsTypesQueryRefProps {
  goodsTypesQueryRef: QueryReference<GoodsTypesQueryQuery>;
}

export const useGoodsTypesBackgroundQuery = (): QueryReference<GoodsTypesQueryQuery> => {
  const [queryRef] = useBackgroundQuery(goodsTypesQuery, { fetchPolicy: 'cache-first' });

  return queryRef;
};

export const useGoodsTypes = (queryRef: QueryReference<GoodsTypesQueryQuery>) => {
  const { data, error } = useReadQuery(queryRef);

  return { goodsTypes: data.viewer.goodsTypes.all ?? [], error };
};
