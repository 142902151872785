import { FC } from 'react';
import { useVehicleTypesForCustomerAndRouteAsOptionsWithFavoriteOptions } from '../../../../common/graphql/useVehicleTypesForCustomerAndRouteAsOptionsWithFavoriteOptions';
import {
  VehicleTypeDropdownOption,
  VehicleTypeDropdownSingleValue,
} from '../../../../common/vehicle-types/vehicleTypeDropdownComponents';
import { transformVehicleType } from '../../grid/utils/optionTransformers';
import { DropdownField } from './fields/DropdownField';

export interface VehicleTypeFieldProps {
  customerNo: number | null;
  disabled?: boolean;
  routeCode: string | null;
}

export const VehicleTypeField: FC<VehicleTypeFieldProps> = ({
  customerNo,
  disabled = false,
  routeCode,
}) => {
  const options = useVehicleTypesForCustomerAndRouteAsOptionsWithFavoriteOptions(
    customerNo,
    routeCode,
  );

  return (
    <DropdownField
      trackerCategory={'Details'}
      name={'vehicleType'}
      disabled={disabled}
      transformOption={transformVehicleType}
      components={{
        Option: VehicleTypeDropdownOption,
        SingleValue: VehicleTypeDropdownSingleValue,
      }}
      options={options}
    />
  );
};
