import { cssColor } from '@stenajs-webui/theme';
import { FC } from 'react';
import { formatDateTime } from '../common/utils/dates/dateUtils';
import { SailingInfoItem } from './sailing-info/SailingInfoItem';
import {
  stenaCheckCircle,
  stenaClock,
  stenaSailingOnRoute,
  stenaStatisticsBar,
} from '@stenajs-webui/elements';
import { parseISO } from 'date-fns';
import { SailingType } from '../common/graphql/fragments/gql/SailingType';
import { formatShipName } from '../common/formatters/formatShipName';

interface TripDetailsProps {
  releasedAt?: string | null;
  arrivalInformation?: string | null;
  shipName?: string;
  dateFormat: string;
  sailingType?: SailingType;
}

export const TripDetails: FC<TripDetailsProps> = ({
  sailingType,
  arrivalInformation,
  releasedAt,
  shipName,
  dateFormat,
}) => (
  <>
    {shipName && (
      <SailingInfoItem iconName={stenaSailingOnRoute} label={formatShipName(shipName)} />
    )}
    {sailingType && <SailingInfoItem label={sailingType.label} iconName={stenaStatisticsBar} />}
    {arrivalInformation && <SailingInfoItem label={arrivalInformation} iconName={stenaClock} />}
    {releasedAt && (
      <SailingInfoItem
        label={`Released at ${formatDateTime(parseISO(releasedAt), dateFormat)}`}
        iconName={stenaCheckCircle}
        iconColor={cssColor('--lhds-color-green-500')}
      />
    )}
  </>
);
