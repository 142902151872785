import {
  RegistrationNumberAlertFormState,
  TravelledVehicleType,
} from '../../../services/update-form/registrationNumberAlert';
import { createMockCreator } from '../../_mocks/mockUtils';
import { vehicleTypeMockArtic } from '../../_mocks/vehicleTypeMock';
import { travelledVehicleMock } from './travelledVehicleMock';

export const registrationNumberAlertMock: RegistrationNumberAlertFormState = {
  appliedArrivalNotepad: false,
  appliedCargoWeight: false,
  appliedHazardousGoods: false,
  appliedHeight: false,
  appliedLength: false,
  appliedLoadingListMessage: false,
  result: travelledVehicleMock,
  travelledVehicleType: TravelledVehicleType.Vehicle,
  vehicleType: vehicleTypeMockArtic,
  appliedTravelledVehicle: false,
};

export const createRegistrationNumberAlertMock = createMockCreator(registrationNumberAlertMock);
