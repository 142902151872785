import { Row } from '@stenajs-webui/core';
import { TextInputButtonSize } from '@stenajs-webui/elements';
import { forwardRef } from 'react';
import {
  BasicBooking,
  BasicBooking_exportReference_status,
  BasicBooking_importReference_status,
} from '../../common/graphql/fragments/gql/BasicBooking';
import { TrackerCategory } from '../../common/tracking/trackerService';
import { ImportExportStatusCellIconButton } from '../../use-cases/import-export/components/ImportExportStatusCellIconButton';
import { EnsLink } from './EnsLink';

interface EnsContentProps {
  booking: BasicBooking;
  confirmed: boolean;
  importExportStatus:
    | BasicBooking_importReference_status
    | BasicBooking_exportReference_status
    | null;
  disabled: boolean;
  onRefreshIconClick?: () => Promise<void>;
  popoverVisible: boolean;
  onOpenPopover: () => void;
  onRequestClosePopover: () => void;
  size?: TextInputButtonSize;
  trackerCategory: TrackerCategory;
}

export const EnsContent = forwardRef<HTMLAnchorElement, EnsContentProps>(
  (
    {
      booking,
      confirmed,
      importExportStatus,
      disabled,
      onRefreshIconClick,
      popoverVisible,
      onOpenPopover,
      onRequestClosePopover,
      size,
      trackerCategory,
    },
    ref,
  ) => {
    return (
      <Row>
        <Row indent={0.5} alignItems={'center'}>
          <ImportExportStatusCellIconButton
            status={importExportStatus}
            bookingNo={booking.bookingNo}
            onRefreshIconClick={onRefreshIconClick}
            visible={popoverVisible}
            onOpen={onOpenPopover}
            size={size}
            onRequestClose={onRequestClosePopover}
          />
        </Row>
        <Row indent={0.5} alignItems={'center'}>
          <EnsLink
            ensUrl={booking.ensUrl}
            disabled={disabled}
            ref={ref}
            confirmed={confirmed}
            trackerCategory={trackerCategory}
            optional={false}
          />
        </Row>
      </Row>
    );
  },
);
