import { isApolloError } from '@apollo/client';
import { all, call, debounce, put, select, takeEvery, throttle } from 'redux-saga/effects';
import { fetchBookings } from '../../common/api';
import { getGenericErrorFromApolloError } from '../../common/error-handling/TransformGraphqlError';
import { transformToUpdateBookingFormState } from '../../common/update-information/utils/transformToUpdateBookingFormState';
import { createSortParams } from '../../use-cases/manage/list/utils/createSortParams';
import { transformFilterState } from '../../use-cases/manage/list/utils/transformers';
import { getFilter } from '../booking-filter/filterReducer';
import { getSorting } from '../sorting/sortingReducer';
import { multiInitializeUpdateForm } from '../update-form/updateFormActions';
import { BookingsFilterQuery_viewer_bookings_byFilter } from './graphql/gql/BookingsFilterQuery';
import {
  requestFetchGrid,
  RequestFetchGridAction,
  setBookingQueryResult,
  setIsRefetching,
  updatePagination,
} from './gridActions';
import { getBookingsFilterQueryResult, getBookingsPerLoad, getOffset } from './gridReducer';
import { mergeBookingNos } from './utils/mergeBookingNos';

export function* updateBookingsInGridSaga() {
  const offset: ReturnType<typeof getOffset> = yield select(getOffset);
  yield fetchGridSaga(requestFetchGrid({ first: offset, offset: 0 })); // When current bookings are updated, the offset value is number of bookings in the grid.
}

export function* fetchMoreSaga() {
  const bookingsPerLoad: ReturnType<typeof getBookingsPerLoad> = yield select(getBookingsPerLoad);
  const offset: ReturnType<typeof getOffset> = yield select(getOffset);
  yield fetchGridSaga(requestFetchGrid({ first: bookingsPerLoad, offset, fetchingMore: true }));
}

export function* fetchGridSaga(action: RequestFetchGridAction) {
  const { first, offset, fetchingMore } = action.payload;
  const filter: ReturnType<typeof getFilter> = yield select(getFilter);
  const sort: ReturnType<typeof getSorting> = yield select(getSorting);
  const bookingsPerLoad: ReturnType<typeof getBookingsPerLoad> = yield select(getBookingsPerLoad);
  const prevResult: ReturnType<typeof getBookingsFilterQueryResult> = yield select(
    getBookingsFilterQueryResult,
  );

  try {
    const result: BookingsFilterQuery_viewer_bookings_byFilter | null = yield call(fetchBookings, {
      filter: transformFilterState(filter),
      first,
      offset,
      sort: createSortParams(sort),
    });

    if (result) {
      yield put(
        multiInitializeUpdateForm(
          'Grid',
          result.bookings.map(basicBooking => ({
            booking: basicBooking,
            formState: transformToUpdateBookingFormState(basicBooking),
          })),
        ),
      );

      yield put(
        setBookingQueryResult({
          errors: [],
          result: {
            bookingNos: fetchingMore
              ? mergeBookingNos(prevResult, result)
              : result.bookings.map(b => b.bookingNo),
            count: result.count,
          },
        }),
      );

      yield put(updatePagination(bookingsPerLoad, offset + first));
    }
  } catch (error: any) {
    const message = isApolloError(error)
      ? getGenericErrorFromApolloError(error)
      : 'There seems to be a problem with the connection to the server. Please try again later.';

    yield put(
      setBookingQueryResult({
        errors: [message],
        result: null,
      }),
    );
  } finally {
    yield put(setIsRefetching(false));
  }
}

export function* addUpdatingIndicatorToGrid() {
  yield put(setIsRefetching(true));
}

export function* watchGrid() {
  yield all([
    throttle(2000, 'SORTING:TOGGLE_SORT_BOOKINGS', updateBookingsInGridSaga),
    debounce(2000, 'GRID:REQUEST_FETCH_GRID', fetchGridSaga),
    takeEvery('GRID:REQUEST_FETCH_MORE', fetchMoreSaga),
    takeEvery('GRID:REQUEST_UPDATE_BOOKINGS_IN_GRID', updateBookingsInGridSaga),
    takeEvery(
      [
        'GRID:REQUEST_FETCH_MORE',
        'GRID:REQUEST_FETCH_GRID',
        'GRID:REQUEST_UPDATE_BOOKINGS_IN_GRID',
        'SORTING:TOGGLE_SORT_BOOKINGS',
      ],
      addUpdatingIndicatorToGrid,
    ),
  ]);
}
