import { graphql } from '../../../gql';

export const allIntermodalVehicleTypesQuery = graphql(/*GraphQL*/ `
  query AllIntermodalVehicleTypesQuery {
    viewer {
      intermodalVehicleType {
        all {
          vehicleTypes {
            description
            id
            code
            defaultLength
            defaultWidth
            defaultHeight
            defaultWeight
          }
        }
      }
    }
  }
`);
