import { exhaustSwitchCase } from '@stenajs-webui/core';
import { FeatureFlag } from './enum';

export const getFeatureIsEnabled = (flag: FeatureFlag): boolean => {
  switch (flag) {
    case FeatureFlag.NoShowLateHandling:
    case FeatureFlag.Statistics:
    case FeatureFlag.Proforma:
    case FeatureFlag.FinancialDocuments:
    case FeatureFlag.CustomsLink:
    case FeatureFlag.GA4:
    case FeatureFlag.UserSubscriptions:
    case FeatureFlag.UserSubscriptionsFreightPortalColumn:
    case FeatureFlag.IntermodalBook:
      return window.FREIGHT_PORTAL_ENV[flag];
    default:
      return exhaustSwitchCase(flag, false);
  }
};
