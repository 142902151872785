import { Sailing } from '../graphql/fragments/gql/Sailing';
import { first } from 'lodash';

interface BookableSailing extends Pick<Sailing, 'bookable'> {}
interface SailingWithDepartureTime extends BookableSailing, Pick<Sailing, 'departureTime'> {}

export const getBookableSailings = <Sailing extends BookableSailing>(
  sailings: Sailing[],
): Sailing[] => sailings.filter(s => s.bookable);

const getBookableSailingWithSameDepartureTime = <Sailing extends SailingWithDepartureTime>(
  sailings: Sailing[],
  previousSailing: { departureTime: string } | null | undefined,
): Sailing | null =>
  sailings.find(s => s.bookable && s.departureTime === previousSailing?.departureTime) ?? null;

export const getSingleBookableSailing = <S extends BookableSailing>(sailings: S[]): S | null => {
  const bookableSailings = getBookableSailings(sailings);

  return bookableSailings.length === 1 ? first(bookableSailings) ?? null : null;
};

export const getNewBookableSailing = <Sailing extends SailingWithDepartureTime>(
  sailings: Sailing[],
  previousSailing: { departureTime: string } | null | undefined,
) =>
  getBookableSailingWithSameDepartureTime(sailings, previousSailing) ??
  getSingleBookableSailing(sailings);
