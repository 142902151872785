import { TrackerAction } from '../../use-cases/simple-create/hooks/UseTrackingTextInput';

interface FieldProps<TValue, TOnChange> {
  editedValue: TValue;
  onChange: TOnChange;
  editable?: boolean;
  disabled?: boolean;
  trackerAction: TrackerAction;
}

interface ComponentProps {
  editable?: boolean;
  disabled?: boolean;
}

interface CombinedProps<TValue, TOnChange> {
  value: TValue;
  onValueChange: TOnChange;
  editable?: boolean;
  disabled?: boolean;
  trackerAction: TrackerAction;
}

export function getFieldProps<TValue, TOnChange>(
  props: ComponentProps,
  fieldProps: FieldProps<TValue, TOnChange>,
): CombinedProps<TValue, TOnChange> {
  return {
    value: fieldProps.editedValue,
    onValueChange: fieldProps.onChange,
    editable: (props.editable ?? true) && (fieldProps.editable ?? true),
    disabled: props.disabled || fieldProps.disabled,
    trackerAction: fieldProps.trackerAction,
  };
}
