import { addYears } from 'date-fns';
import Cookies from 'js-cookie';

export type ConsentMode = 'granted' | 'denied';

const COOKIE_NAME: string = 'cookie-consent';

let scriptHasLoaded = false;

const getTrackingId = () => (window.FREIGHT_PORTAL_ENV || {}).GOOGLE_ANALYTICS_4_ID;

const loadScript = () => {
  if (scriptHasLoaded) {
    return;
  }

  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=' + getTrackingId();
  script.async = true;

  document.head.appendChild(script);
  scriptHasLoaded = true;
};

export const initializeGA4 = (userId: string | undefined) => {
  gtag('config', getTrackingId(), { send_page_view: false, anonymize_ip: true, user_id: userId });

  const defaultMode: ConsentMode = 'denied';

  gtag('consent', 'default', {
    ad_storage: defaultMode,
    analytics_storage: defaultMode,
  });

  const mode = getConsentMode();
  if (mode) {
    setConsentMode(mode);
  }
};

const removeCookies = () => {
  const trackingId = getTrackingId();
  const cookieName = trackingId.replace('G-', '_ga_');

  Cookies.remove(cookieName);
};

const setConsentMode = (value: ConsentMode) => {
  gtag('consent', 'update', {
    ad_storage: value,
    analytics_storage: value,
  });

  if (value === 'granted') {
    loadScript();
  } else {
    removeCookies();
  }
};

export const getConsentMode = (): ConsentMode | null => {
  switch (Cookies.get(COOKIE_NAME)) {
    case 'granted':
      return 'granted';
    case 'denied':
      return 'denied';
    default:
      return null;
  }
};

export const updateConsentMode = (value: ConsentMode) => {
  Cookies.set(COOKIE_NAME, value, { expires: addYears(new Date(), 1) });
  setConsentMode(value);
};
