export enum HotkeyGroup {
  General,
  FiltersCreate,
  GridBooking,
}

export enum Hotkeys {
  HotkeysOverView = 'Ctrl + Alt + I',
  CloseWindows = 'Esc',
  RefreshPage = 'F5',
  BookingSaveChanges = 'Ctrl + Alt + S',
  BookingUndoChanges = 'Ctrl + Alt + Z',
  BookingDetails = 'Ctrl + Alt + D',
  BookingCopyClipboard = 'Ctrl + Alt + C',
  BookingSearch = 'Ctrl + Alt + Space',
  BookingCancel = 'Ctrl + Alt + X',
  BookingCreate = 'Ctrl + Alt + B',
  OpenFilterMenu = 'Ctrl + Alt + M',
  AllBookingsToday = 'Ctrl + Alt + T',
  ClearAllFilter = 'Ctrl + Alt + 0',
  ApplyDefaultFilters = 'Ctrl + Alt + 1',
}

export interface HotkeyInformation {
  binding: Hotkeys;
  action: string;
  group: HotkeyGroup;
}
