import { createMockCreator } from '../../_mocks/mockUtils';
import { vehicleTypeMockArtic } from '../../_mocks/vehicleTypeMock';
import { TravelledVehicle } from '../../graphql/fragments/gql/TravelledVehicle';

export const travelledVehicleMock: TravelledVehicle = {
  custNo: 123,
  height: 2,
  length: 2,
  vehicleNationalityCode: 'SE',
  vehicleRegNo: 'ABC123',
  vehicleTypeCode: vehicleTypeMockArtic.code,
};

export const createTravelledVehicleMock = createMockCreator(travelledVehicleMock);
