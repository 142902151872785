import { graphql } from '../../../gql';

export const updatePrivacySettingsMutation = graphql(/* GraphQL */ `
  mutation UpdatePrivacySettings($settings: UpdatePrivacySettingsInput!) {
    updatePrivacySettings(settings: $settings) {
      errors {
        description
      }
      settings {
        id
        customsLink
      }
      success
    }
  }
`);
