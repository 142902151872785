import { uniq } from 'lodash';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { SwitchBookings_switchBookings } from '../../../../common/graphql/gql/SwitchBookings';
import { SwitchBookingsMutationVariables } from '../../../../gql/graphql';
import { isBookingEditable } from '../../../../services/update-form/utils/bookingUtils';

export enum SWITCH_SUBMIT_SHOW {
  FAIL,
  NONE,
  SUCCESS,
}

export interface SwitchSubmitInformation {
  errors: string[];
  status: SWITCH_SUBMIT_SHOW;
  messages: string[];
  warnings: string[];
}
export const getSwitchSubmitInformation = (
  switchResult: SwitchBookings_switchBookings | null,
): SwitchSubmitInformation => {
  if (switchResult === null) {
    return {
      errors: ['Unexpected error'],
      warnings: [],
      status: SWITCH_SUBMIT_SHOW.FAIL,
      messages: [],
    };
  }
  if (
    !switchResult.success ||
    switchResult.confirmedBooking === null ||
    switchResult.waitlistedBooking === null
  ) {
    return {
      errors: uniq(switchResult.errors.map(error => error.description)),
      status: SWITCH_SUBMIT_SHOW.FAIL,
      messages: [],
      warnings: [],
    };
  } else {
    return {
      status: SWITCH_SUBMIT_SHOW.SUCCESS,
      errors: uniq(switchResult.errors.map(error => error.description)),
      messages: uniq(switchResult.messages.map(warning => warning.description)),
      warnings: uniq(switchResult.warnings.map(warning => warning.description)),
    };
  }
};

export const prepareSubmitParams = (
  confirmed: BasicBooking,
  waitlisted: BasicBooking,
): SwitchBookingsMutationVariables => ({
  confirmedBookingNo: confirmed.bookingNo,
  confirmedTimeStamp: confirmed.timestamp,
  waitlistedBookingNo: waitlisted.bookingNo,
  waitlistedTimeStamp: waitlisted.timestamp,
});

export const isBookingSwitchable = (booking: BasicBooking): boolean =>
  isBookingEditable(booking) && !booking.customer.isCashCustomer;
