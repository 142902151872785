import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setFilter } from '../../services/booking-filter/filterActions';
import { TrackerCategory, trackEvent } from '../tracking/trackerService';
import { useRedirectToManagePage } from './useRedirectToManagePage';
import { createDepartureFilter } from './utils/createDepartureFilter';

export const useShowDeparture = (
  departureDate: string,
  departureTime: string,
  routeCode: string,
  trackerCategory: TrackerCategory,
  trackerAction: string,
) => {
  const dispatch = useDispatch();

  const redirectToManagePage = useRedirectToManagePage();

  return useCallback(() => {
    dispatch(setFilter(createDepartureFilter(departureDate, routeCode, departureTime)));

    trackEvent({
      category: trackerCategory,
      action: trackerAction,
    });

    redirectToManagePage();
  }, [
    departureDate,
    departureTime,
    dispatch,
    routeCode,
    trackerAction,
    trackerCategory,
    redirectToManagePage,
  ]);
};
