import { graphql } from '../../../gql';

export const intermodalBookingQuery = graphql(/* GraphQL */ `
  query IntermodalDetailsQuery($bookingNo: Int!) {
    viewer {
      intermodalBookings {
        booking: byBookingNo(bookingNo: $bookingNo) {
          ...DetailedIntermodalBooking
        }
      }
    }
  }
`);
