import cx from 'classnames';
import { FC } from 'react';
import Draggable from 'react-draggable';
import ReactModal from 'react-modal';
import styles from './Modal.module.css';

export interface BaseModalProps extends ReactModal.Props {
  width?: string;
  onRequestClose?: () => void;
  draggable?: boolean;
}

export const DRAGGABLE_HANDLE_CLASSNAME = 'draggable-modal-handle';
export const DRAGGABLE_CANCEL_CLASSNAME = 'draggable-modal-cancel';
export const REACT_MODAL_OVERLAY_SELECTOR = '.ReactModal__Overlay';

export const BaseModal: FC<BaseModalProps> = ({
  width,
  draggable = false,
  children,
  className,
  overlayClassName,
  ...reactModalProps
}) => {
  return (
    <ReactModal
      overlayClassName={cx(styles.overlay, overlayClassName)}
      className={cx(styles.modal, className)}
      {...reactModalProps}
    >
      <Draggable
        handle={`.${DRAGGABLE_HANDLE_CLASSNAME}`}
        cancel={`.${DRAGGABLE_CANCEL_CLASSNAME}, button, [role="tooltip"]`}
        bounds={REACT_MODAL_OVERLAY_SELECTOR}
        disabled={!draggable}
      >
        <div
          style={{ ['--swui-modal-width' as string]: width }}
          className={cx(styles.content, {
            [styles.isDraggable]: draggable,
          })}
        >
          {children}
        </div>
      </Draggable>
    </ReactModal>
  );
};
