import { useUpdateField } from '../../../../../common/update-information/hooks/useUpdateField';
import { StringFieldKeys } from '../../../../../services/update-form/updateFormReducer';
import {
  CommonTextInputField,
  CommonTextInputFieldProps,
} from '../../../../../common-ui/form-fields/CommonTextInputField';
import { getFieldProps } from '../../../../../common-ui/form-fields/getFieldProps';

export interface TextInputFieldProps<Key extends StringFieldKeys>
  extends Omit<
    CommonTextInputFieldProps,
    'onValueChange' | 'editable' | 'trackerAction' | 'value'
  > {
  name: Key;
}

export function TextInputField<Key extends StringFieldKeys>({
  name,
  ...props
}: TextInputFieldProps<Key>) {
  const fieldProps = useUpdateField<StringFieldKeys>(name);
  return (
    <CommonTextInputField
      {...props}
      {...getFieldProps(props, fieldProps)}
      changed={fieldProps.editedValue !== fieldProps.initialValue}
    />
  );
}
