import { useLocation, Location } from 'react-router-dom';

export const getBackgroundLocation = (location: any) => {
  const background = location.state?.background;
  return background ?? location;
};

export const useBackgroundLocation = (): Location => {
  const location = useLocation();
  return getBackgroundLocation(location);
};
