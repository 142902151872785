import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Column, Space, Txt } from '@stenajs-webui/core';
import { Icon, stenaBan, stenaExclamationTriangle } from '@stenajs-webui/elements';
import { cssColor } from '@stenajs-webui/theme';
import { GraphQLError } from 'graphql';
import { FC, ReactElement } from 'react';
import MissingBookingImage from '../../../../common-ui/images/missing_booking.svg?react';
import { HttpError } from '../../../../common/error-handling/HttpError';
import { transformGraphqlError } from '../../../../common/error-handling/TransformGraphqlError';
import { freightPortalBaseModalProps } from '../../../../common/modal/FreightPortalBaseModal';
import { Modal } from './modal/Modal';

export function renderBookingErrorModal(
  loading: boolean,
  error: GraphQLError | undefined,
  onRequestClose: () => void,
): ReactElement {
  if (loading) {
    return renderBookingLoadingModal(onRequestClose);
  }

  const { message, icon } = getErrorModalContentFromGQLError(error);

  return (
    <Modal
      {...freightPortalBaseModalProps}
      isOpen
      shouldCloseOnOverlayClick
      draggable
      loading={loading}
      onRequestClose={onRequestClose}
      headerText={message}
    >
      <BookingErrorModalContents icon={icon} />
    </Modal>
  );
}

export const BookingErrorModalContents: FC<{ icon?: IconDefinition }> = ({ icon }) => {
  return (
    <Column spacing={4} indent={4} alignItems={'center'} maxWidth={'380px'}>
      {icon ? (
        <Icon size={120} icon={icon} color={cssColor('--lhds-color-blue-500')} />
      ) : (
        <MissingBookingImage height={134} width={124} />
      )}
      <Space num={4} />
      <Txt>Please contact your local Stena Line office if you need booking assistance.</Txt>
    </Column>
  );
};

export function renderBookingLoadingModal(onRequestClose: () => void): ReactElement {
  return (
    <Modal
      {...freightPortalBaseModalProps}
      isOpen
      shouldCloseOnOverlayClick
      draggable
      loading={true}
      onRequestClose={onRequestClose}
    />
  );
}

export const BOOKING_ERROR_NOT_FOUND = { message: 'We cannot find this booking', icon: undefined };
export const BOOKING_ERROR_FORBIDDEN = {
  icon: stenaBan,
  message: 'You do not have access to this booking',
};
export const BOOKING_ERROR_UNKNOWN = {
  icon: stenaExclamationTriangle,
  message: 'Something went wrong',
};

export function getErrorModalContentFromGQLError(error: GraphQLError | undefined) {
  if (!error) {
    return BOOKING_ERROR_NOT_FOUND;
  }

  const errorDetails = transformGraphqlError(error);

  switch (errorDetails.statusCode) {
    case HttpError.FileNotFound:
      return BOOKING_ERROR_NOT_FOUND;
    case HttpError.Forbidden:
      return BOOKING_ERROR_FORBIDDEN;
    case HttpError.InternalServerError:
    default:
      return BOOKING_ERROR_UNKNOWN;
  }
}
