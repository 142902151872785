import { graphql } from '../../gql';

export const createClaimsPortalTokenMutation = graphql(/* GraphQL */ `
  mutation CreateClaimsPortalToken {
    createClaimsPortalToken {
      success
      token
      service
    }
  }
`);
