import { StoreState } from '../../store/storeState';
import { ErrorType } from '../../use-cases/auth/errors';
import { AuthResult } from '../../use-cases/auth/types';
import { AuthAction } from './authActions';
import { resolveUserDisplayName, resolveUserName } from './utils/authUtils';

export interface AuthError {
  type: ErrorType;
  description: string;
}

export interface AuthState {
  auth0SessionInformationFetched: boolean;
  authResult?: AuthResult;
  error: AuthError | null;
}

const INITIAL_VALUE: AuthState = {
  auth0SessionInformationFetched: false,
  authResult: undefined,
  error: null,
};

export enum AuthTokenScope {
  Openid = 'openid',
  Email = 'email',
  Phone = 'phone',
  ReadBooking = 'read:booking',
  WriteBooking = 'write:booking',
  ReadPricing = 'read:pricing',
  ReadStatistics = 'read:statistics',
  ReadInvoices = 'read:invoices',
  ReadIntermodal = 'read:intermodal',
  WriteIntermodal = 'write:intermodal',
}

export const authReducer = (state: AuthState = INITIAL_VALUE, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'AUTH:USER_LOGGED_IN':
      return {
        ...state,
        auth0SessionInformationFetched: true,
        authResult: action.payload.authResult,
      };
    case 'AUTH:NO_SESSION_FOUND':
      return {
        ...state,
        auth0SessionInformationFetched: true,
        authResult: undefined,
      };
    case 'AUTH:FATAL_ERROR':
      return {
        ...state,
        auth0SessionInformationFetched: true,
        authResult: undefined,
        error: { ...action.payload },
      };
    default:
      return state;
  }
};

const getAuth = (state: StoreState): AuthState => state.auth;

export const getAuthResult = (state: StoreState): AuthResult | undefined =>
  getAuth(state).authResult;

export const getUserNameWithPrefix = (state: StoreState): string => {
  const authResult = getAuthResult(state);
  return authResult ? resolveUserName(authResult) : '';
};

export const getUserDisplayName = (state: StoreState): string => {
  const authResult = getAuthResult(state);
  return authResult ? resolveUserDisplayName(authResult) : '';
};

export const getAuthTokenScope = (state: StoreState): string => {
  const authResult = getAuthResult(state);
  return authResult?.scope ?? '';
};

export const isAuth0SessionInformationFetched = (state: StoreState): boolean => {
  return getAuth(state).auth0SessionInformationFetched;
};

export const isAuthenticated = (state: StoreState): boolean => {
  const authResult = getAuthResult(state);
  return authResult && authResult.expiresAt ? new Date().getTime() < authResult.expiresAt : false;
};

export const getAuthError = (state: StoreState): AuthError | null => {
  return getAuth(state).error;
};
