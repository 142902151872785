import { Column, Row } from '@stenajs-webui/core';
import { FlatButton, stenaPlusCircle } from '@stenajs-webui/elements';
import { map } from 'lodash';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEditable } from '../../../../common/modal/EditableContext';
import { HazardousGoodsStatus } from '../../../../gql/graphql';
import { GoodsTypesQueryRefProps } from '../../../../services/intermodal/hooks/useGoodsTypes';
import { useUpdateIntermodalFieldValue } from '../../../../services/intermodal/hooks/UseUpdateIntermodalFieldValue';
import {
  addUpdateIntermodalBookingGood,
  clearUpdateBookingGoods,
  getUpdateIntermodalBookingIsSubmitting,
} from '../../../../services/intermodal/updateIntermodalReducer';
import { pluginsRange, yesNoOptions } from '../../../manage/details/components/sections/common';
import { transformYesNoOption } from '../../../manage/grid/utils/optionTransformers';
import { IntermodalDetailsFieldCell } from '../../common/IntermodalDetailsFieldCell';
import {
  getIntermodalHazardousGoodsApprovalInfo,
  transformNoOfPluginsOption,
} from '../../utils/detailsUtils';
import { UpdateIntermodalDropdownField } from '../fields/UpdateIntermodalDropdownField';
import { UpdateIntermodalTextInputField } from '../fields/UpdateIntermodalTextInputField';
import { UpdateBookingGoodsItem } from '../UpdateBookingGoodsItem';
import { v4 as uuid } from 'uuid';
import { RadioButtonWithLabel } from '@stenajs-webui/forms';

interface UpdateIntermodalCargoSectionProps extends GoodsTypesQueryRefProps {
  hazardousGoods: HazardousGoodsStatus;
}

export const UpdateIntermodalCargoSection: FC<UpdateIntermodalCargoSectionProps> = ({
  hazardousGoods,
  goodsTypesQueryRef,
}) => {
  const bookingGoods = useUpdateIntermodalFieldValue('bookingGoods');
  const dispatch = useDispatch();
  const editable = useEditable();
  const disabled = useSelector(getUpdateIntermodalBookingIsSubmitting) || !editable;
  const hazardousGoodsApprovalInfo = getIntermodalHazardousGoodsApprovalInfo(hazardousGoods);
  const loaded = Object.keys(bookingGoods).length > 0;

  const clearGoods = () => dispatch(clearUpdateBookingGoods());
  const addGood = () => dispatch(addUpdateIntermodalBookingGood({ goodId: uuid() }));

  return (
    <>
      <IntermodalDetailsFieldCell
        label={'Hazardous goods'}
        children={
          <UpdateIntermodalDropdownField
            trackerCategory={'IntermodalUpdate'}
            transformOption={transformYesNoOption}
            name={'hazardousGoods'}
            options={yesNoOptions}
          />
        }
        approved={hazardousGoodsApprovalInfo ?? undefined}
        flex={1}
      />
      <Row gap={2}>
        <IntermodalDetailsFieldCell
          label={'Plugin'}
          children={
            <UpdateIntermodalDropdownField
              trackerCategory={'IntermodalUpdate'}
              transformOption={transformNoOfPluginsOption}
              options={pluginsRange}
              name={'noOfPlugins'}
            />
          }
          flex={1}
        />
        <IntermodalDetailsFieldCell
          label={'Temp (°C)'}
          children={
            <UpdateIntermodalTextInputField
              trackerCategory={'IntermodalUpdate'}
              name={'temperature'}
            />
          }
          flex={1}
        />
      </Row>
      <Column gap>
        <RadioButtonWithLabel
          name={'loaded'}
          label={'Empty'}
          disabled={disabled}
          checked={!loaded}
          onClick={loaded ? clearGoods : undefined}
        />
        <RadioButtonWithLabel
          name={'loaded'}
          label={'Loaded'}
          disabled={disabled}
          onClick={!loaded ? addGood : undefined}
          checked={loaded}
        />
      </Column>
      {loaded && (
        <>
          <Column gap={3}>
            {map(bookingGoods, good => (
              <UpdateBookingGoodsItem
                key={good.id}
                value={good}
                editable={editable}
                goodsTypesQueryRef={goodsTypesQueryRef}
              />
            ))}
          </Column>
          <FlatButton
            disabled={disabled}
            leftIcon={stenaPlusCircle}
            label="Add goods"
            onClick={() => dispatch(addUpdateIntermodalBookingGood({ goodId: uuid() }))}
          />
        </>
      )}
    </>
  );
};
