import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { assert } from '../../common/asserts/assert';
import { trackException } from '../../common/tracking/appInsight';
import { AppDispatch } from '../../store/appDispatch';
import { fatalError } from '../authentication/authActions';
import { healthCheckFail } from './healthCheckActions';
import { typePolicies } from './typePolicies';

let apiToken: string | null = null;

export const setToken = (token: string | null) => {
  apiToken = token;
};

export const getToken = () => apiToken;

let errorCallback: AppDispatch = () => {
  assert(false, 'Error callback not set');
};

export const setErrorCallback = (f: AppDispatch) => {
  errorCallback = f;
};

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${getToken()}`,
      'x-client-code': 'FP_BOOK',
      'x-client-version': import.meta.env.REACT_APP_VERSION,
    },
  };
});

const httpLink = new HttpLink({
  credentials: 'include',
  uri: operation => '/graphql?operationName=' + operation.operationName,
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const isAuthorizationError = graphQLErrors.some(props =>
      props.message.includes('401 Unauthorized'),
    );

    if (isAuthorizationError) {
      errorCallback(fatalError('invalid_token', ''));
      return;
    }

    if (networkError) {
      trackException({
        exception: networkError,
        properties: {
          operationName: operation.operationName,
          graphQLErrors,
        },
      });
    }
  }

  if (networkError) {
    if (
      operation.operationName === 'HealthCheckQuery' ||
      operation.operationName === 'PingLastLogin'
    ) {
      errorCallback(healthCheckFail());
    }
  }
});

export const client = new ApolloClient({
  name: 'FreightPortal',
  version: import.meta.env.REACT_APP_VERSION,
  assumeImmutableResults: true,
  cache: new InMemoryCache({ typePolicies }),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    watchQuery: {
      errorPolicy: 'all',
    },
  },
  link: ApolloLink.from([authLink, errorLink, httpLink]),
});

export const refetchQueries = (queries: string[]) =>
  client.refetchQueries({
    include: queries,
  });
