import { useCallback, useMemo } from 'react';
import {
  BookingValidationErrors,
  UpdateResult,
} from '../../../services/update-form/updateBookingReducer';
import {
  BookingFormState,
  FormInstanceId,
  getEditedFormState,
  getInitialFormState,
  getUpdateFormState,
} from '../../../services/update-form/updateFormReducer';
import { isEqualFormStates } from '../../../services/update-form/utils/isEqualFormStates';
import { StoreState } from '../../../store/storeState';
import { BasicBooking } from '../../graphql/fragments/gql/BasicBooking';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import {
  getUpdateSubmitInformation,
  UpdateSubmitInformation,
} from '../utils/getUpdateSubmitInformation';
import { useUpdateSubmit } from './useUpdateSubmit';

export interface UseUpdateFormState {
  booking?: BasicBooking;
  edited: boolean;
  formState?: BookingFormState;
  submit: () => void;
  submitting: boolean;
  updateSubmitInformation: UpdateSubmitInformation;
}

export interface StateProps {
  booking?: BasicBooking;
  editedFormState?: BookingFormState;
  initialFormState?: BookingFormState;
  updateResult: UpdateResult | null;
  validationErrors: BookingValidationErrors | null;
}

export const useUpdateFormState = ({ bookingNo, formId }: FormInstanceId): UseUpdateFormState => {
  const mapState = useCallback(
    (state: StoreState): StateProps => {
      const updateFormState = getUpdateFormState(state, { bookingNo, formId });
      return {
        booking: updateFormState ? updateFormState.booking : undefined,
        editedFormState: getEditedFormState(state, { bookingNo, formId }),
        initialFormState: getInitialFormState(state, { bookingNo, formId }),
        updateResult: updateFormState ? updateFormState.meta.updateResult : null,
        validationErrors: updateFormState ? updateFormState.meta.validationErrors : null,
      };
    },
    [bookingNo, formId],
  );

  const { booking, editedFormState, initialFormState, updateResult } =
    useShallowEqualSelector(mapState);

  const { submitting, submit } = useUpdateSubmit({
    bookingNo: bookingNo,
    formId: formId,
  });

  const updateSubmitInformation = useMemo(
    () => getUpdateSubmitInformation(updateResult || {}),
    [updateResult],
  );

  return {
    booking,
    edited: !isEqualFormStates(initialFormState, editedFormState),
    formState: editedFormState,
    submit,
    submitting,
    updateSubmitInformation,
  };
};
