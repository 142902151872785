import { Column } from '@stenajs-webui/core';
import { FC, ReactNode } from 'react';
import { globalContentWrapperIndent } from '../../../../../globalStyles';

export const ModalFooter: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Column indent={globalContentWrapperIndent} spacing={2}>
      {children}
    </Column>
  );
};
