import { useQuery } from '@apollo/client';
import { bookingQuery } from '../../use-cases/manage/details/graphql/bookingQuery';

export const useBookingDetails = (bookingNo: number) => {
  const { data, loading, error } = useQuery(bookingQuery, {
    fetchPolicy: 'network-only',
    variables: { bookingNo },
  });

  return {
    booking: data?.viewer?.bookings?.booking ?? null,
    loading: loading || (data && !data.viewer) || false,
    error,
  };
};
