import { BookingQuayStatus, BookingStatus } from '../../../../gql/graphql';

export const evaluatePhoneNumberString = (value: string): boolean => {
  if (value === '') {
    return true;
  }

  // Regexp: Must start with number, there after numbers and dashes are valid
  return /^[\d]*\d[\d-]*$/.test(value);
};

export const cleanPhoneNumberString = (value: string): string => {
  if (value === '') {
    return '';
  }
  if (!evaluatePhoneNumberString(value)) {
    return '';
  }

  return value.replace(/-/g, '');
};

export const formatPhoneNumber = (countryCode: string | null, phoneNumber: string): string => {
  const nr = countryCode ? removeStartingZeroes(phoneNumber) : phoneNumber;
  return countryCode + nr;
};

export const removeStartingZeroes = (inputStr: string): string => inputStr.replace(/\b0+/g, '');

export function shouldShowShareBooking(
  vehicleRegNumber: string,
  status: BookingStatus | null,
  quayStatus: BookingQuayStatus | null | undefined,
) {
  return (
    Boolean(vehicleRegNumber) &&
    status !== null &&
    status !== BookingStatus.NoShow &&
    status !== BookingStatus.Cancelled &&
    quayStatus !== BookingQuayStatus.GateExit
  );
}
