import { Message } from '../../../common/update-information/utils/messagesResult';
import {
  getCreateIntermodalBookingIsFetchingJourney,
  getCreateIntermodalBookingJourneyResult,
  getCreateIntermodalBookingLegs,
} from '../../../services/intermodal/createIntermodalReducer';
import { StoreState } from '../../../store/storeState';
import { useShallowEqualSelector } from '../../../common/hooks/useShallowEqualSelector';

const emptyArray: Message[] = [];

const mapState = (state: StoreState) => {
  const journeyResult = getCreateIntermodalBookingJourneyResult(state);
  const resultErrors = journeyResult?.errors ?? emptyArray;
  const fetchingJourney = getCreateIntermodalBookingIsFetchingJourney(state);
  const legs = getCreateIntermodalBookingLegs(state);
  return { legs, errors: resultErrors, fetchingJourney };
};

export const useJourneyResult = () => useShallowEqualSelector(mapState);
