import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreState } from '../../store/storeState';
import { loadTokenForBookingNo } from './thunks/loadTokenForBookingNo';

type LoaderState =
  | {
      name: 'idle';
    }
  | {
      name: 'success';
    }
  | {
      name: 'loading';
    }
  | {
      name: 'error';
      error: string;
    };

export interface CustomsLinkLoaderReducerState {
  bookingNo: number | null;
  loaderState: LoaderState;
}

const INITIAL_STATE: CustomsLinkLoaderReducerState = {
  bookingNo: null,
  loaderState: { name: 'idle' },
};

const slice = createSlice({
  name: 'customs-link-loader',
  initialState: INITIAL_STATE,
  reducers: {
    initializeCustomsLinkLoader(state, action: PayloadAction<number>) {
      state.bookingNo = action.payload;
      state.loaderState = { name: 'idle' };
    },
  },
  extraReducers: builder =>
    builder
      .addCase(loadTokenForBookingNo.pending, (state, action) => {
        state.bookingNo = action.meta.arg;
        state.loaderState = { name: 'loading' };
      })
      .addCase(loadTokenForBookingNo.rejected, (state, action) => {
        state.loaderState = {
          name: 'error',
          error: action.error.message ?? 'Failed to contact CustomsLink, please try again.',
        };
      })
      .addCase(loadTokenForBookingNo.fulfilled, state => {
        state.loaderState = {
          name: 'success',
        };
      }),
});

export const {
  reducer: customsLinkLoaderReducer,
  actions: { initializeCustomsLinkLoader },
} = slice;

export const getCustomsLinkLoaderState = (state: StoreState) => state.customsLinkLoader;

export const getCustomsLinkLoaderStateForBookingNo = (state: StoreState, bookingNo: number) => {
  const s = getCustomsLinkLoaderState(state);
  return s.bookingNo === bookingNo ? s.loaderState : null;
};
