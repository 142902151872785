import { Action } from 'redux';
import { Task } from 'redux-saga';
import { TailParameters } from 'redux-saga-uniq';
import { ActionPattern, call, cancel, fork, take } from 'redux-saga/effects';

type Parameters<T extends (...args: any) => any> = T extends (...args: infer P) => any ? P : never;

export function takeLatestUniqBy<
  A extends Action,
  P extends ActionPattern,
  Fn extends (...args: any[]) => any,
>(keyExtractor: (action: A) => string, patternOrChannel: P, saga: Fn, ...args: TailParameters<Fn>) {
  return fork(function* () {
    let tasks: Record<string, Task> = {};
    while (true) {
      const action: A = yield take(patternOrChannel);
      const taskKey = keyExtractor(action);

      yield fork(function* () {
        if (tasks[taskKey]) {
          yield cancel(tasks[taskKey]);
        }
        tasks[taskKey] = yield fork(function* () {
          yield call<Fn>(saga, ...(args.concat(action) as Parameters<Fn>));
          delete tasks[taskKey];
        });
      });
    }
  });
}
