import { exhaustSwitchCaseElseThrow } from '../../../common/utils/ExhaustSwitchCase';
import { PaymentStatus, PaymentType } from '../../../gql/graphql';
import { GQLPrice } from '../hooks/usePaymentStatus';
import { SuccessPaymentStatus } from '../components/PaymentSuccessResultHeader';

export type PaymentState =
  | { type: 'error'; errors: string[] }
  | { type: 'processing' }
  | { type: 'processingError' }
  | { type: 'done' }
  | { type: 'initial' };

export type PaymentLocalStatus = PaymentState['type'];
export const showGoBackToManagePageButton = (paymentState: PaymentLocalStatus) => {
  switch (paymentState) {
    case 'done':
    case 'error':
    case 'processingError':
    case 'initial':
      return true;
    case 'processing':
      return false;
    default:
      return exhaustSwitchCaseElseThrow(paymentState);
  }
};

export const getPaymentTypeText = (paymentType: PaymentType): string => {
  switch (paymentType) {
    case PaymentType.Payment:
      return 'Payment';
    case PaymentType.Refund:
      return 'Refund';
    case PaymentType.Transfer:
      return 'Transfer to other booking';
    default:
      return 'Payment';
  }
};
const priceNumberFormat = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatGQLPrice = (price: GQLPrice): string => {
  return priceNumberFormat.format(price.amount) + ' ' + price.currencyCode;
};

const exchangeRateFormat = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 4,
  maximumFractionDigits: 4,
});

export const formatExchangeRate = (exchangeRate: number) => exchangeRateFormat.format(exchangeRate);

export const getPaymentSuccessHeaderProps = (
  paymentStatus: SuccessPaymentStatus,
): { variant: 'success' | 'partially_paid' | 'refunded'; headerText: string } => {
  switch (paymentStatus) {
    case PaymentStatus.Paid:
      return { variant: 'success', headerText: 'The booking has been paid successfully' };
    case PaymentStatus.PartiallyPaid:
      return { variant: 'partially_paid', headerText: 'The booking is partially paid' };
    case PaymentStatus.Refunded:
      return { variant: 'refunded', headerText: 'The payment has been refunded' };
  }
};
