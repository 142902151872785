import { BasicBooking } from '../../../common/graphql/fragments/gql/BasicBooking';
import { ImportExportStatusCode } from '../../../gql/graphql';
import { getFeatureIsEnabled } from '../../../services/flags/featureFlags';
import { FeatureFlag } from '../../../services/flags/enum';

const stringIsMUCR = (name: string | null | undefined): boolean =>
  (name?.includes('MUCR') || name?.includes('(M)UCR')) ?? false;

export const getShowCustomsLinkForEnvelopeId = (
  booking: BasicBooking,
  importExportSettingsName: string | null | undefined,
  importExportReference: string | null | undefined,
): boolean =>
  getFeatureIsEnabled(FeatureFlag.CustomsLink) &&
  booking.showCustomsLink &&
  !importExportReference &&
  !stringIsMUCR(importExportSettingsName);

export const getShowCustomsLinkForScac = (booking: BasicBooking): boolean =>
  getFeatureIsEnabled(FeatureFlag.CustomsLink) &&
  booking.showCustomsLink &&
  booking.exportReference.status?.statusCode !== ImportExportStatusCode.Success;
