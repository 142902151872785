import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../store/storeState';
import {
  getUpdateIntermodalBookingEditedFormState,
  UpdateIntermodalBookingFormModel,
} from '../updateIntermodalReducer';

export const useUpdateIntermodalFieldValue = <TKey extends keyof UpdateIntermodalBookingFormModel>(
  name: TKey,
): UpdateIntermodalBookingFormModel[TKey] => {
  return useSelector(
    useCallback(
      (state: StoreState) => getUpdateIntermodalBookingEditedFormState(state)[name],
      [name],
    ),
  );
};
