import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateUserSubscriptions } from '../../../common/api';
import {
  getUserSubscriptionsFormState,
  getUserSubscriptionsInitialFormState,
  SubscriptionKey,
  UserSubscription,
} from '../userSubscriptionsReducer';
import { UserSubscriptionInput, UserSubscriptionsInput } from '../../../gql/graphql';
import { StoreState } from '../../../store/storeState';
import { trackEvent } from '../../../common/tracking/trackerService';
import { trackChangedUserSubscriptions } from '../utils';
import { UserSubscriptionsMutation_updateSubscriptionSettings } from '../../../use-cases/user-subscriptions/graphql/gql/UserSubscriptionsMutation';
import { difference } from 'lodash';

const transformStateToInput = ({
  settings,
  routeCodes,
}: {
  settings: Record<SubscriptionKey, UserSubscription>;
  routeCodes: string[];
}): UserSubscriptionsInput => {
  return {
    arrivalTimeChanged: transformUserSubscriptionToInput(settings.arrivalTimeChanged),
    bookingUpdatedInFreightPortal: transformUserSubscriptionToInput(
      settings.bookingUpdatedInFreightPortal,
    ),
    checkInDeviation: transformUserSubscriptionToInput(settings.checkInDeviation),
    dailySailingSummary: transformUserSubscriptionToInput(settings.dailySailingSummary),
    lateCancellations: transformUserSubscriptionToInput(settings.lateCancellations),
    noShow: transformUserSubscriptionToInput(settings.noShow),
    routeCodes,
    sailingCancelled: transformUserSubscriptionToInput(settings.sailingCancelled),
    unitsSummaryPerSailing: transformUserSubscriptionToInput(settings.unitsSummaryPerSailing),
    waitingChangedToConfirmed: transformUserSubscriptionToInput(settings.waitingChangedToConfirmed),
  };
};

const transformUserSubscriptionToInput = (
  subscription: UserSubscription,
): UserSubscriptionInput => {
  return {
    emailEnabled: subscription.emailEnabled,
    smsEnabled: subscription.smsEnabled,
    freightPortalEnabled: subscription.freightPortalEnabled,
  };
};

const trackUserSubscriptionsRouteChange = (items: string[], action: string) => {
  items.forEach(item => {
    trackEvent(
      {
        category: 'Subscriptions',
        action: action,
        label: item,
      },
      { route_code: item },
    );
  });
};

export const saveUserSubscriptions = createAsyncThunk<
  UserSubscriptionsMutation_updateSubscriptionSettings,
  void,
  { state: StoreState }
>('save-user-subscriptions', async (_, { getState }) => {
  const formState = getUserSubscriptionsFormState(getState());
  const initialFormState = getUserSubscriptionsInitialFormState(getState());

  const removedRoutes = difference(initialFormState.routeCodes, formState.routeCodes);
  const addedRoutes = difference(formState.routeCodes, initialFormState.routeCodes);

  trackChangedUserSubscriptions(initialFormState.settings, formState.settings);
  trackUserSubscriptionsRouteChange(addedRoutes, 'Add Subscription Route');
  trackUserSubscriptionsRouteChange(removedRoutes, 'Remove Subscription Route');

  return updateUserSubscriptions(transformStateToInput(getUserSubscriptionsFormState(getState())));
});
