import styled from '@emotion/styled';
import { Box, Heading, Row } from '@stenajs-webui/core';
import { DRAGGABLE_CANCEL_CLASSNAME } from '@stenajs-webui/modal';
import { cssColor } from '@stenajs-webui/theme';
import { FC, ReactNode } from 'react';
import { PRINT_HIDE_CLASSNAME, PRINT_ROW_CLASSNAME } from '../globalStyles';
import { breakpoints } from '../themes/defaultTheme';
import logo from './images/logo_blue_text.svg';
import cx from 'classnames';

const MARGIN_HACK_CLASSNAME = 'margin-hack';

const Wrapper = styled(Box)`
  @media print {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .${MARGIN_HACK_CLASSNAME} {
    margin-right: calc(-6 * var(--swui-metrics-spacing));
    @media screen and (min-width: ${breakpoints.md}) {
      margin-right: 0;
    }
  }
`;

const ShowInPrintLogo = styled.img`
  display: none;

  @media print {
    display: block;
    width: 100px;
  }
`;

export interface BookingModalTitleBaseProps {
  className?: string;
  bookingActions: ReactNode;
  bookingNo: number;
  bookingStatus: ReactNode;
  paymentStatus?: ReactNode;
}

export const BookingModalTitleBase: FC<BookingModalTitleBaseProps> = ({
  className,
  bookingActions,
  bookingStatus,
  bookingNo,
  paymentStatus,
}) => {
  return (
    <Row flex={1} className={className} indent={[0, 1]}>
      <Wrapper
        flexDirection={['column', null, 'row']}
        alignItems={[null, null, 'center']}
        justifyContent={['center', null, 'space-between']}
        flex={1}
        gap={[2, 2, 0]}
      >
        <Row gap={2} spacing={[1, 1, null, 0]} flexDirection={['column', null, 'row']}>
          <Row>
            <Heading
              variant={'h3'}
              color={cssColor('--lhds-color-ui-900')}
              className={DRAGGABLE_CANCEL_CLASSNAME}
            >
              {bookingNo}
            </Heading>
          </Row>
          <Row
            gap={2}
            indent={[0, null, 2]}
            className={cx(PRINT_HIDE_CLASSNAME, MARGIN_HACK_CLASSNAME)}
            justifyContent={['center', null, 'flex-start']}
            spacing={[0, 1, null, 0]}
          >
            {bookingStatus}
            {paymentStatus}
          </Row>
        </Row>
        <Box className={PRINT_ROW_CLASSNAME} flex={1} justifyContent={'flex-end'}>
          <Box
            gap={2}
            flexDirection={['column', null, 'row']}
            className={cx(PRINT_HIDE_CLASSNAME, MARGIN_HACK_CLASSNAME)}
            justifyContent={['stretch', null, 'flex-end']}
          >
            {bookingActions}
          </Box>
          <ShowInPrintLogo src={logo} />
        </Box>
      </Wrapper>
    </Row>
  );
};
