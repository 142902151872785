import { first, map } from 'lodash';
import { matchRoutes } from 'react-router-dom';
import { AppRoutes, appRoutes } from '../routes/AppRoutes';

export interface MaskedPageView {
  page_location: string;
  page_title?: string;
}

const maskedRoutes: Record<keyof AppRoutes, string | null> = {
  bookingCancel: 'Cancel booking',
  bookingClaims: 'Claim booking',
  bookingDetailsEdit: 'Edit booking',
  bookingDetailsView: 'View booking',
  bookingSwitch: 'Switch booking',
  bookings: null,
  callback: null,
  claimsPortal: null,
  create: null,
  customsDocumentation: 'ENS',
  financialDocuments: null,
  hazardousGoods: 'Hazardous goods',
  help: null,
  intermodal: null,
  intermodalDetails: 'Intermodal Details',
  intermodalCreate: null,
  noShowLateHandling: null,
  payment: 'Pay bookings',
  settings: null,
  settingsDateFormat: null,
  settingsFavourites: null,
  settingsFilters: null,
  settingsCookies: null,
  settingsPassword: null,
  statistics: null,
  timetable: null,
  userSubscriptions: null,
};

export const getMaskedPageView = (path: string): MaskedPageView => {
  let r = matchRoutes(
    map(appRoutes, (p, key) => ({ path: p, id: key })),
    path,
  );

  const match = first(r);

  if (!match) {
    return {
      page_location: path,
    };
  }

  const appRouteKey = match.route.id as keyof AppRoutes;

  const appPath = appRoutes[appRouteKey];
  const maskedTitle = maskedRoutes[appRouteKey];

  return {
    page_location: appPath,
    page_title: maskedTitle ?? undefined,
  };
};
