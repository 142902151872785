import { FC } from 'react';
import { useCalendarPropsForDateTextInput } from '../../../../common/hooks/useCalendarPropsForDateTextInput';
import { DateInputField, DateInputFieldProps } from './fields/DateInputField';

export interface DepartureDateInputFieldProps extends DateInputFieldProps {
  fromDate: string | null;
  routeCode: string | null;
  toDate: string | null;
}

export const DepartureDateInputField: FC<DepartureDateInputFieldProps> = ({
  fromDate,
  routeCode,
  toDate,
  ...props
}) => {
  const calendarProps = useCalendarPropsForDateTextInput(fromDate, routeCode, toDate);

  return <DateInputField calendarProps={calendarProps} {...props} />;
};
