import { PermissionGateProps } from './PermissionGate';
import { useHasPermission } from '../../statistics/useHasPermission';
import { Modal } from '../../../use-cases/manage/details/components/modal/Modal';
import { commonProps } from '../../../use-cases/manage/details/components/BookingDetailsDecider';
import cx from 'classnames';
import { PRINT_HIDE_CLASSNAME } from '../../../globalStyles';
import { Txt } from '@stenajs-webui/core';
import {
  BOOKING_ERROR_FORBIDDEN,
  BookingErrorModalContents,
} from '../../../use-cases/manage/details/components/BookingErrorModal';
import { useRouteModal } from '../../modal/useRouteModal';

export const PermissionGateModal = ({ permission, children }: PermissionGateProps) => {
  const getHasPermission = useHasPermission();
  const { onClose } = useRouteModal();

  if (!getHasPermission(permission)) {
    return (
      <Modal
        {...commonProps}
        closeButtonClassName={cx('t_close_permission_gate_modal', PRINT_HIDE_CLASSNAME)}
        onRequestClose={onClose}
        header={<Txt size={'large'}>You do not have access to this feature</Txt>}
      >
        <BookingErrorModalContents icon={BOOKING_ERROR_FORBIDDEN.icon} />
      </Modal>
    );
  }
  return children;
};
