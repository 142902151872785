import { FC } from 'react';
import { resolveVehicleSVG } from '../../use-cases/manage/details/utils/vehicleType';
import { Colors } from '../colors';

type VehicleIconSize = 'small' | 'large';

interface VehicleTypeIconProps {
  type: string;
  size?: VehicleIconSize;
}

const VehicleTypeIcon: FC<VehicleTypeIconProps> = ({ type, size = 'small' }) => {
  const TypeIcon = resolveVehicleSVG(type);
  const { width, height } = resolveVehicleTypeSize(size);

  if (TypeIcon) {
    return (
      <TypeIcon
        color={Colors.UI2}
        style={{
          flex: 'none',
          width,
          height,
        }}
      />
    );
  }

  return null;
};

const resolveVehicleTypeSize = (size: VehicleIconSize): { width: string; height: string } => {
  switch (size) {
    case 'small':
      return { width: '2rem', height: '2em' };
    case 'large':
      return { width: '3rem', height: '3em' };
  }
};

export default VehicleTypeIcon;
