import { Omit } from '@stenajs-webui/core';
import { FC } from 'react';
import { useUpdateField } from '../../../../../common/update-information/hooks/useUpdateField';
import { DateInputFieldKeys } from '../../../../../services/update-form/updateFormReducer';
import { TrackerCategory } from '../../../../../common/tracking/trackerService';
import {
  CommonDateInputField,
  CommonDateInputFieldProps,
} from '../../../../../common-ui/form-fields/CommonDateInputField';
import { getFieldProps } from '../../../../../common-ui/form-fields/getFieldProps';

export interface DateInputFieldProps
  extends Omit<CommonDateInputFieldProps, 'onValueChange' | 'value' | 'trackerAction'> {
  name: DateInputFieldKeys;
  trackerCategory: TrackerCategory;
}

export const DateInputField: FC<DateInputFieldProps> = ({ name, ...props }) => {
  const fieldProps = useUpdateField<DateInputFieldKeys>(name);

  return (
    <CommonDateInputField
      {...props}
      {...getFieldProps(props, fieldProps)}
      changed={fieldProps.initialValue !== fieldProps.editedValue}
    />
  );
};
