import { useNumericTextInputChange } from '../../../../common/hooks/useNumericTextInputChange';
import {
  CommonTextInputField,
  CommonTextInputFieldProps,
} from '../../../../common-ui/form-fields/CommonTextInputField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import { CreateIntermodalFormModelStringKeys } from '../../../../services/intermodal/createIntermodalReducer';
import { useCreateIntermodalField } from '../../hooks/useCreateIntermodalField';

interface CreateIntermodalNumericTextInputFieldProps<
  Key extends CreateIntermodalFormModelStringKeys,
> extends Omit<CommonTextInputFieldProps, 'onChange' | 'trackerAction'> {
  name: Key;
  onlyIntegers?: boolean;
}

export function CreateIntermodalNumericTextInputField<
  Key extends CreateIntermodalFormModelStringKeys,
>({ name, onlyIntegers, ...props }: CreateIntermodalNumericTextInputFieldProps<Key>) {
  const { onChange, ...fieldProps } = useCreateIntermodalField(name);
  const onTextInputChange = useNumericTextInputChange(onChange, onlyIntegers);

  return (
    <CommonTextInputField
      {...props}
      {...getFieldProps(props, {
        ...fieldProps,
        onChange: onTextInputChange,
      })}
    />
  );
}
