import {
  BookingsSortColumn,
  BookingsSortColumns,
  ColumnId,
  SortDirection,
} from '../../../../gql/graphql';

const createBookingSortColumns = (columns: BookingsSortColumn[]): BookingsSortColumns => ({
  columns,
});

const createColumn = (column: ColumnId, direction: SortDirection): BookingsSortColumn => ({
  column,
  direction: direction,
});

const createGeneralSortOrder = (direction: SortDirection): BookingsSortColumn[] => [
  createColumn(ColumnId.DepartureDate, SortDirection.Ascending),
  createColumn(ColumnId.DepartureTime, SortDirection.Ascending),
  createColumn(ColumnId.RouteCode, direction),
  createColumn(ColumnId.Status, direction),
  createColumn(ColumnId.VehicleRegNo, direction),
  createColumn(ColumnId.VehicleTypeCode, direction),
  createColumn(ColumnId.BookingNo, SortDirection.Descending),
];

export const createSortParams = (sortColumn: BookingsSortColumn): BookingsSortColumns => {
  switch (sortColumn.column) {
    case ColumnId.DepartureDate:
      return createBookingSortColumns([
        createColumn(ColumnId.DepartureDate, sortColumn.direction),
        createColumn(ColumnId.DepartureTime, SortDirection.Ascending),
        createColumn(ColumnId.RouteCode, sortColumn.direction),
        createColumn(ColumnId.Status, sortColumn.direction),
        createColumn(ColumnId.VehicleTypeCode, sortColumn.direction),
        createColumn(ColumnId.BookingNo, SortDirection.Descending),
      ]);
    case ColumnId.DepartureTime:
      return createBookingSortColumns([
        createColumn(ColumnId.DepartureDate, sortColumn.direction),
        createColumn(ColumnId.DepartureTime, sortColumn.direction),
        createColumn(ColumnId.RouteCode, sortColumn.direction),
        createColumn(ColumnId.Status, sortColumn.direction),
        createColumn(ColumnId.VehicleTypeCode, sortColumn.direction),
      ]);
    case ColumnId.CargoWeight:
    case ColumnId.HazardousGoods:
    case ColumnId.LivestockTypeCode:
    case ColumnId.LoadingPreferenceCode:
    case ColumnId.Temperature:
      return createBookingSortColumns([
        createColumn(sortColumn.column, sortColumn.direction),
        ...createGeneralSortOrder(sortColumn.direction),
      ]);
    case ColumnId.RouteCode:
      return createBookingSortColumns([
        createColumn(ColumnId.RouteCode, sortColumn.direction),
        createColumn(ColumnId.DepartureDate, SortDirection.Ascending),
        createColumn(ColumnId.DepartureTime, SortDirection.Ascending),
        createColumn(ColumnId.Status, sortColumn.direction),
        createColumn(ColumnId.VehicleRegNo, sortColumn.direction),
        createColumn(ColumnId.VehicleTypeCode, sortColumn.direction),
        createColumn(ColumnId.BookingNo, SortDirection.Descending),
      ]);
    case ColumnId.Status:
      return createBookingSortColumns([
        createColumn(ColumnId.Status, sortColumn.direction),
        createColumn(ColumnId.QuayStatus, sortColumn.direction),
        createColumn(ColumnId.DepartureDate, SortDirection.Ascending),
        createColumn(ColumnId.DepartureTime, SortDirection.Ascending),
        createColumn(ColumnId.RouteCode, sortColumn.direction),
        createColumn(ColumnId.VehicleTypeCode, sortColumn.direction),
        createColumn(ColumnId.BookingNo, SortDirection.Descending),
      ]);
    case ColumnId.TrailerRegNo:
      return createBookingSortColumns([
        createColumn(ColumnId.TrailerRegNo, sortColumn.direction),
        createColumn(ColumnId.DepartureDate, SortDirection.Ascending),
        createColumn(ColumnId.DepartureTime, SortDirection.Ascending),
        createColumn(ColumnId.RouteCode, sortColumn.direction),
        createColumn(ColumnId.Status, sortColumn.direction),
        createColumn(ColumnId.BookingNo, SortDirection.Descending),
      ]);
    case ColumnId.VehicleRegNo:
      return createBookingSortColumns([
        createColumn(ColumnId.VehicleRegNo, sortColumn.direction),
        createColumn(ColumnId.DepartureDate, SortDirection.Ascending),
        createColumn(ColumnId.DepartureTime, SortDirection.Ascending),
        createColumn(ColumnId.RouteCode, sortColumn.direction),
        createColumn(ColumnId.Status, sortColumn.direction),
        createColumn(ColumnId.BookingNo, SortDirection.Descending),
      ]);
    case ColumnId.VehicleTypeCode:
      return createBookingSortColumns([
        createColumn(ColumnId.VehicleTypeCode, sortColumn.direction),
        createColumn(ColumnId.DepartureDate, SortDirection.Ascending),
        createColumn(ColumnId.DepartureTime, SortDirection.Ascending),
        createColumn(ColumnId.RouteCode, sortColumn.direction),
        createColumn(ColumnId.Status, sortColumn.direction),
        createColumn(ColumnId.BookingNo, SortDirection.Descending),
      ]);
    default:
      return createBookingSortColumns([
        createColumn(sortColumn.column, sortColumn.direction),
        createColumn(ColumnId.BookingNo, SortDirection.Descending),
      ]);
  }
};
