import { graphql } from '../../../gql';

export const updateIntermodalBookingMutation = graphql(/*GraphQL*/ `
  mutation UpdateIntermodalBookingMutation($booking: UpdateIntermodalBookingInput!) {
    updateIntermodalBooking(booking: $booking) {
      booking {
        ...DetailedIntermodalBooking
      }
      errors {
        description
      }
      messages {
        description
      }
      warnings {
        description
      }
      success
      bookingOutOfDate
    }
  }
`);
