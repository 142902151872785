import { graphql } from '../../../gql';

export const cancelIntermodalBookingMutation = graphql(/*GraphQL*/ `
  mutation CancelIntermodalBookingMutation($bookingNo: Int!) {
    cancelIntermodalBooking(bookingNo: $bookingNo) {
      booking {
        ...DetailedIntermodalBooking
      }
      errors {
        description
      }
      messages {
        description
      }
      warnings {
        description
      }
      success
    }
  }
`);
