import { all } from 'redux-saga/effects';
import {
  watchFilterDateSet,
  watchRequestSetFilterOpen,
} from '../services/booking-filter/filterSaga';
import { watchPageSettingsSaga } from '../services/page-settings/pageSettingsSaga';
import { watchCreateForm } from '../services/create/createFormSaga';
import { watchFrontendOutOfDate } from '../services/frontend-out-of-date/frontendOutOfDateSaga';
import { watchGrid } from '../services/grid/gridSaga';
import { watchRequestShowToast } from '../services/notifications/notificationsSaga';
import { watchSwitchResultSaga } from '../services/switch/switchSaga';
import { watchTourSaga } from '../services/tour/tourSagas';
import { watchUpdateForm } from '../services/update-form/updateFormSaga';
import { watchUrgentMessageSaga } from '../services/urgent-message/urgentMessageSaga';
import { watchIntermodalGridStatusSaga } from '../services/intermodal/sagas/watchIntermodalGridStatusSaga';
import { watchIntermodalCreateFormSaga } from '../services/intermodal/sagas/watchIntermodalCreateFormSaga';

export function* rootSaga() {
  yield all([
    watchCreateForm(),
    watchFilterDateSet(),
    watchFrontendOutOfDate(),
    watchGrid(),
    watchIntermodalGridStatusSaga(),
    watchIntermodalCreateFormSaga(),
    watchPageSettingsSaga(),
    watchRequestSetFilterOpen(),
    watchRequestShowToast(),
    watchSwitchResultSaga(),
    watchTourSaga(),
    watchUpdateForm(),
    watchUrgentMessageSaga(),
  ]);
}
