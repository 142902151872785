export enum GoodsApprovalStatus {
  NotRequested,
  Approved,
  Requested,
  Rejected,
}

export enum GoodsApprovalType {
  Hazardous,
  LoadingPreference,
  CraneableStatus,
}

export interface GoodsApproval {
  status: GoodsApprovalStatus;
  type: GoodsApprovalType;
}
