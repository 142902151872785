export function waitForElementToExist(
  selector: string | HTMLElement,
  timeout = 1000,
): Promise<HTMLElement> {
  return new Promise((resolve, reject) => {
    const element =
      typeof selector === 'string' ? document.querySelector<HTMLElement>(selector) : selector;

    if (element) {
      return resolve(element);
    }

    const timer = setTimeout(() => {
      console.error('Could not find element', selector);
      observer.disconnect();
      reject();
    }, timeout);

    const observer = new MutationObserver((_mutations, obs) => {
      const element =
        typeof selector === 'string' ? document.querySelector<HTMLElement>(selector) : selector;
      if (element) {
        obs.disconnect();
        clearTimeout(timer);
        return resolve(element);
      }
    });

    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
}

export async function getNodeFromTarget(
  target: string | string[],
  timeout?: number,
): Promise<HTMLElement | undefined> {
  const targets = typeof target !== 'string' ? target : [target];
  let node: HTMLElement | undefined;
  for (const t of targets) {
    try {
      node = await waitForElementToExist(t, timeout);
      break;
    } catch {}
  }

  return node;
}
