import styled from '@emotion/styled';
import { Box, Row, Txt } from '@stenajs-webui/core';
import { tableBorder, TableCell, TableRow } from '@stenajs-webui/grid';
import { cssColor } from '@stenajs-webui/theme';
import { flatMap, uniq } from 'lodash';
import { FC } from 'react';
import { CalculatePrice_calculatePrice_articlesResults } from '../../common/pricing/graphql/gql/CalculatePrice';
import { GQLPrice } from '../../use-cases/payment/hooks/usePaymentStatus';
import { BookingPaymentArticlesTable } from '../../use-cases/payment/components/table/payment-articles/BookingPaymentArticlesTable';
import { CELL_WIDTH_PRICE } from '../../use-cases/payment/config/common';
import { formatGQLPrice } from '../../use-cases/payment/utils/paymentUtil';
import { PopoverBody } from '../popover/PopoverBody';
import { PopoverTitle } from '../popover/PopoverTitle';

export interface CalculatePriceResultProps {
  onClose: () => void;
  amountInRouteCurrency: GQLPrice;
  articlesResults: CalculatePrice_calculatePrice_articlesResults[];
}

const FootnoteWrapper = styled.div`
  width: 30px;
  flex-shrink: 0;
`;

const MessageBox = styled(Box)`
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

export const CalculatePriceResult: FC<CalculatePriceResultProps> = ({
  onClose,
  amountInRouteCurrency,
  articlesResults,
}) => {
  const warnings = uniq(flatMap(articlesResults, r => r.warnings.map(w => w.description)));

  return (
    <Box display="inline-block" background={cssColor('--lhds-color-ui-50')}>
      <Box position={'sticky'} top={0} background={cssColor('--lhds-color-ui-50')}>
        <PopoverTitle text={'Included in booking'} onClose={onClose} />
      </Box>
      <PopoverBody>
        {articlesResults.map((result, index) =>
          result.articlesResult ? (
            <BookingPaymentArticlesTable
              key={index}
              articlesResult={result.articlesResult}
              warningIds={result.warnings.map(w => warnings.indexOf(w.description))}
            />
          ) : null,
        )}
        {articlesResults.length > 1 && (
          <TableRow borderBottom={tableBorder}>
            <TableCell flex={1}>
              <Row alignItems={'center'}>
                <Txt variant={'bold'}>Grand total</Txt>
              </Row>
            </TableCell>
            <TableCell width={CELL_WIDTH_PRICE} justifyContent={'flex-end'}>
              <Txt variant={'bold'}>{formatGQLPrice(amountInRouteCurrency)}</Txt>
            </TableCell>
          </TableRow>
        )}
      </PopoverBody>
      {warnings.length !== 0 && (
        <Box maxWidth={670} spacing={2} indent={2}>
          {warnings.map((m, i) => (
            <MessageBox flexDirection={'row'} key={i}>
              <FootnoteWrapper>
                <Txt size={'small'}>({i + 1})</Txt>
              </FootnoteWrapper>
              <Txt size={'small'}>{m}</Txt>
            </MessageBox>
          ))}
        </Box>
      )}
    </Box>
  );
};
