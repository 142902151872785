import { UpdateSettingsInput } from '../../../gql/graphql';
import { SettingsFormState } from '../../../services/settings/settingsReducer';

export const transformFormStateToSettingsInput = (
  settings: SettingsFormState,
): UpdateSettingsInput => ({
  dateFormat: settings.dateFormat,
  accountEmail: settings.accountEmail,
  accountFirstName: settings.accountFirstName,
  accountLastName: settings.accountLastName,
  accountLoginEmail: settings.accountLoginEmail,
  accountPhone: settings.accountPhone,
  accountSecondEmail: settings.accountSecondEmail,
  accountSendBookingConfirmationAsEmail: settings.accountSendBookingConfirmationAsEmail,
  bookCustNo: settings.bookCustomer?.custNo,
  bookDate: settings.bookDate,
  bookDefaultRouteCode: settings.bookDefaultRoute?.code,
  bookDefaultVehicleTypeCode: settings.bookDefaultVehicleType?.code,
  favouriteRouteCodes: settings.favouriteRoutes?.map(route => route.code),
  favouriteVehicleTypeCodes: settings.favouriteVehicleTypes?.map(vehicleType => vehicleType.code),
  filterDateInterval: settings.filterDateInterval,
  filterFilterStates: settings.filterFilterStates,
  filterRouteCodes: settings.filterRoutes?.map(route => route.code),
  dashboardNotCheckedInTime: settings.dashboardNotCheckedInTime,
  dashboardShowNotCheckedIn: settings.dashboardShowNotCheckedIn,
  dashboardShowPendingApproval: settings.dashboardShowPendingApproval,
  dashboardShowDashboardOnScroll: settings.dashboardShowDashboardOnScroll,
  dashboardShowDateOverview: settings.dashboardShowDateOverview,
  dashboardShowUnaccompaniedUnits: settings.dashboardShowUnaccompaniedUnits,
});
