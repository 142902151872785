import { Box, Row, Text } from '@stenajs-webui/core';
import { FlatButton, PrimaryButton } from '@stenajs-webui/elements';
import { FC, ReactNode } from 'react';
import { PopoverBody } from './popover/PopoverBody';
import { PopoverTitle } from './popover/PopoverTitle';

export interface CancelPanelBaseProps {
  children: ReactNode;
  className?: string;
  onClose: () => void;
  submitting: boolean;
  onConfirm: () => void;
  onAbort: () => void;
}

export const CancelPanelBase: FC<CancelPanelBaseProps> = ({
  children,
  className,
  onClose,
  onConfirm,
  submitting,
  onAbort,
}) => (
  <Box minWidth={368}>
    <PopoverTitle className={className} text={'Cancel booking'} onClose={onClose} />
    <PopoverBody gap={2}>
      <Text>Do you want to cancel this booking?</Text>
      {children}
      <Row justifyContent={'flex-end'} alignItems={'center'} gap>
        <FlatButton
          className={'t_booking_details_confirm_cancel_booking'}
          label={'Cancel booking'}
          loadingLabel={'Cancelling...'}
          loading={submitting}
          onClick={onConfirm}
          variant={'danger'}
        />
        <PrimaryButton disabled={submitting} label="Keep" onClick={onAbort} />
      </Row>
    </PopoverBody>
  </Box>
);
