import { Options } from 'react-select';
import { DateInterval } from '../../../gql/graphql';
import { FilterDateDate } from '../../../services/booking-filter/filterReducer';
import { DropdownOption } from '../../../use-cases/manage/grid/utils/optionTransformers';
import { exhaustSwitchCaseElseThrow } from '../ExhaustSwitchCase';
import { getRelativeFilterDate } from './dateFilter';

export const getLabel = (dateInterval: DateInterval): string => {
  switch (dateInterval) {
    case DateInterval.Today:
      return 'Today';
    case DateInterval.TodayAndTomorrow:
      return 'Today and tomorrow';
    case DateInterval.TodayAndOneMonthAhead:
      return 'Today and one month ahead';
    case DateInterval.TodayAndOneMonthBack:
      return 'Today and one month back';
    case DateInterval.TodayAndOneWeekAhead:
      return 'Today and one week ahead';
    case DateInterval.TodayAndOneWeekBack:
      return 'Today and one week back';
    case DateInterval.Tomorrow:
      return 'Tomorrow';
    case DateInterval.Yesterday:
      return 'Yesterday';
    case DateInterval.YesterdayAndOneWeekAhead:
      return 'Yesterday and one week ahead';
    case DateInterval.YesterdayAndToday:
      return 'Yesterday and today';
    default:
      return exhaustSwitchCaseElseThrow(dateInterval);
  }
};

export const transformDateIntervalOption = (
  dateInterval: DateInterval,
): DropdownOption<DateInterval> => ({
  data: dateInterval,
  label: getLabel(dateInterval),
  value: dateInterval,
});

export const options: Options<DropdownOption<DateInterval>> = [
  transformDateIntervalOption(DateInterval.Today),
  transformDateIntervalOption(DateInterval.TodayAndTomorrow),
  transformDateIntervalOption(DateInterval.TodayAndOneMonthAhead),
  transformDateIntervalOption(DateInterval.TodayAndOneMonthBack),
  transformDateIntervalOption(DateInterval.TodayAndOneWeekAhead),
  transformDateIntervalOption(DateInterval.TodayAndOneWeekBack),
  transformDateIntervalOption(DateInterval.Tomorrow),
  transformDateIntervalOption(DateInterval.Yesterday),
  transformDateIntervalOption(DateInterval.YesterdayAndOneWeekAhead),
  transformDateIntervalOption(DateInterval.YesterdayAndToday),
];

export const getDateFilterSettingForFilter = (
  dateInterval: DateInterval,
): { from: FilterDateDate; to: FilterDateDate } => {
  switch (dateInterval) {
    case DateInterval.Today:
      return {
        from: getRelativeFilterDate('TODAY'),
        to: getRelativeFilterDate('TODAY'),
      };
    case DateInterval.Tomorrow:
      return {
        from: getRelativeFilterDate('TOMORROW'),
        to: getRelativeFilterDate('TOMORROW'),
      };
    case DateInterval.Yesterday:
      return {
        from: getRelativeFilterDate('YESTERDAY'),
        to: getRelativeFilterDate('YESTERDAY'),
      };
    case DateInterval.TodayAndOneWeekBack:
      return {
        from: getRelativeFilterDate('ONE_WEEK_BACK'),
        to: getRelativeFilterDate('TODAY'),
      };
    case DateInterval.TodayAndTomorrow:
      return {
        from: getRelativeFilterDate('TODAY'),
        to: getRelativeFilterDate('TOMORROW'),
      };
    case DateInterval.TodayAndOneMonthBack:
      return {
        from: getRelativeFilterDate('ONE_MONTH_BACK'),
        to: getRelativeFilterDate('TODAY'),
      };
    case DateInterval.TodayAndOneWeekAhead:
      return {
        from: getRelativeFilterDate('TODAY'),
        to: getRelativeFilterDate('ONE_WEEK_AHEAD'),
      };
    case DateInterval.TodayAndOneMonthAhead:
      return {
        from: getRelativeFilterDate('TODAY'),
        to: getRelativeFilterDate('ONE_MONTH_AHEAD'),
      };
    case DateInterval.YesterdayAndToday:
      return {
        from: getRelativeFilterDate('YESTERDAY'),
        to: getRelativeFilterDate('TODAY'),
      };
    case DateInterval.YesterdayAndOneWeekAhead:
      return {
        from: getRelativeFilterDate('YESTERDAY'),
        to: getRelativeFilterDate('ONE_WEEK_AHEAD'),
      };
    default:
      return exhaustSwitchCaseElseThrow(dateInterval);
  }
};
