import { useLocation, useNavigate } from 'react-router-dom';
import { Location, ModalLocation } from '../routes/AppRoutes';
import { getBackgroundLocation } from '../home/components/UseBackgroundLocation';

export const useShowModal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const pushModal = <T>(routeGetter: (args: T, background: Location) => ModalLocation, args: T) => {
    const destination = routeGetter(args, getBackgroundLocation(location));

    if (location.pathname !== destination.path.pathname) {
      navigate(destination.path, { state: destination.state });
    }
  };

  const replaceModal = <T>(
    routeGetter: (args: T, background: Location) => ModalLocation,
    args: T,
  ) => {
    const destination = routeGetter(args, getBackgroundLocation(location));

    navigate(destination.path, { state: destination.state, replace: true });
  };

  return { pushModal, replaceModal };
};
