import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateIntermodalBookingInput } from '../../../gql/graphql';
import { FetchResult } from '@apollo/client';
import {
  DetailedIntermodalBooking,
  UpdateIntermodalBookingResult,
} from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { StoreState } from '../../../store/storeState';
import { transformIntermodalBookingToUpdateFormState } from '../../../use-cases/intermodal/utils/detailsUtils';
import {
  getUpdateIntermodalBookingEditedFormState,
  UpdateIntermodalBookingFormModel,
  UpdateIntermodalBookingValidationErrors,
} from '../updateIntermodalReducer';
import { Message } from '../../../common/update-information/utils/messagesResult';

import { validateUpdateIntermodalFields } from '../validation/validators';

type UpdateIntermodalBookingThunkRejectValue =
  | {
      type: 'validationErrors';
      errors: UpdateIntermodalBookingValidationErrors;
    }
  | { type: 'outOfDate' }
  | { type: 'error'; errors: Message[] };

export const updateIntermodalBookingThunk = createAsyncThunk<
  {
    booking: DetailedIntermodalBooking;
    formState: UpdateIntermodalBookingFormModel;
    warnings: Message[];
    messages: Message[];
  },
  {
    updateBooking: (
      updateInput: UpdateIntermodalBookingInput,
    ) => Promise<FetchResult<UpdateIntermodalBookingResult>>;
    onOutdatedBooking: () => void;
  },
  { state: StoreState; rejectValue: UpdateIntermodalBookingThunkRejectValue }
>(
  'update-intermodal-booking',
  async ({ updateBooking, onOutdatedBooking }, { getState, rejectWithValue, fulfillWithValue }) => {
    const validationResult = validateUpdateIntermodalFields(
      getUpdateIntermodalBookingEditedFormState(getState()),
    );

    if (!validationResult.success) {
      return rejectWithValue({
        type: 'validationErrors',
        errors: validationResult.error,
      });
    }

    const updateInput = validationResult.value;

    const result = await updateBooking(updateInput);
    const updateResult = result.data?.updateIntermodalBooking;

    if (updateResult?.success && updateResult.booking) {
      return fulfillWithValue({
        booking: updateResult.booking,
        formState: transformIntermodalBookingToUpdateFormState(updateResult.booking),
        warnings: updateResult.warnings,
        messages: updateResult.messages,
      });
    } else if (updateResult?.bookingOutOfDate) {
      onOutdatedBooking();
      return rejectWithValue({
        type: 'outOfDate',
      });
    } else {
      return rejectWithValue({
        type: 'error',
        errors: updateResult?.errors ?? [],
      });
    }
  },
);
