import {
  CreateFormIdVariant,
  CreateFormUpdateCommonValuesAction,
  CreateResult,
  InitializeCreateFormAction,
  RequestCleanupCreateFormAction,
} from './createFormActions';
import { CreateValidationErrors } from './createReducer';

export type CreateMetaActions =
  | SubmitStartAction
  | SubmitFinishedAction
  | SetCreateResultAction
  | InitializeCreateFormAction
  | RequestCleanupCreateFormAction
  | CreateFormUpdateCommonValuesAction
  | EnableExtendedModeAction;

export interface SubmitStartAction {
  payload: {
    formId: CreateFormIdVariant;
  };
  type: 'CREATE_FORM:SUBMIT_START';
}

export const startSubmit = (formId: CreateFormIdVariant): SubmitStartAction => ({
  payload: {
    formId,
  },
  type: 'CREATE_FORM:SUBMIT_START',
});

export interface SubmitFinishedAction {
  payload: {
    formId: CreateFormIdVariant;
  };
  type: 'CREATE_FORM:SUBMIT_FINISHED';
}

export const finishSubmit = (formId: CreateFormIdVariant): SubmitFinishedAction => ({
  payload: {
    formId,
  },
  type: 'CREATE_FORM:SUBMIT_FINISHED',
});

export interface SetCreateResultAction {
  payload: {
    formId: CreateFormIdVariant;
    result: CreateResult | null;
    validationErrors: CreateValidationErrors;
  };
  type: 'CREATE_FORM:SET_CREATE_RESULT';
}

export const setCreateResult = (
  formId: CreateFormIdVariant,
  result: CreateResult,
  validationErrors: CreateValidationErrors,
): SetCreateResultAction => ({
  payload: {
    formId,
    result,
    validationErrors,
  },
  type: 'CREATE_FORM:SET_CREATE_RESULT',
});

export interface EnableExtendedModeAction {
  payload: { formId: CreateFormIdVariant };
  type: 'CREATE_FORM:ENABLE_EXTENDED_MODE';
}

export const enableExtendedMode = (formId: CreateFormIdVariant): EnableExtendedModeAction => ({
  payload: { formId },
  type: 'CREATE_FORM:ENABLE_EXTENDED_MODE',
});
