import {
  Route,
  Route_exportSettings,
  Route_exportSettings_accompaniedList,
  Route_importSettings,
} from '../../../common/graphql/fragments/gql/Route';
import { orderBy } from 'lodash';
import {
  getMaybeDateFromDateAndTime,
  isItemValidWithinDate,
} from '../../../common/utils/dates/dateUtils';
import { MinimalBooking } from '../../manage/details/utils/minimalBooking';

export const getUnaccompaniedAccompaniedSettings = (
  list: Route_exportSettings_accompaniedList[],
  departureDate: Date,
  customsClassificationCode: string | undefined,
  useCustomsClassification: boolean | undefined,
) => {
  return (
    orderBy(
      list.filter(l => {
        if (useCustomsClassification) {
          return (
            !l.customsClassificationCode ||
            l.customsClassificationCode === customsClassificationCode
          );
        }

        return !l.customsClassificationCode;
      }),
      [l => l.validFrom, l => l.customsClassificationCode],
      ['desc', 'asc'],
    ).find(isItemValidWithinDate(departureDate)) ?? undefined
  );
};

const getImportExportSettings = <T extends Route_exportSettings | Route_importSettings>(
  importExportSettings: T | undefined | null,
  unacc: boolean,
  departureDate: Date | undefined,
  customsClassificationCode: string | undefined,
  useCustomsClassification: boolean | undefined,
): Route_exportSettings_accompaniedList | undefined => {
  if (!importExportSettings || !departureDate) {
    return undefined;
  }
  const list = unacc
    ? importExportSettings.unaccompaniedList
    : importExportSettings.accompaniedList;
  return getUnaccompaniedAccompaniedSettings(
    list,
    departureDate,
    customsClassificationCode,
    useCustomsClassification,
  );
};

export const getImportExportSettingsByNumberOfDrivers = <
  T extends Route_exportSettings | Route_importSettings,
>(
  importExportSettings: T | undefined | null,
  noOfDrivers: number | undefined = 0,
  departureDate: Date | undefined,
  customsClassificationCode: string | undefined,
  useCustomsClassification: boolean | undefined,
) =>
  getImportExportSettings(
    importExportSettings,
    noOfDrivers === 0,
    departureDate,
    customsClassificationCode,
    useCustomsClassification,
  );

export const getImportExportSettingsFromMinimalBooking = <
  T extends Route_exportSettings | Route_importSettings,
>(
  minimalBooking: MinimalBooking | undefined,
  importExportSettings: T | null | undefined,
) =>
  getImportExportSettingsByNumberOfDrivers(
    importExportSettings,
    minimalBooking?.noOfDrivers,
    getMaybeDateFromDateAndTime(minimalBooking?.departureDate, minimalBooking?.departureTime),
    minimalBooking?.customsClassificationCode,
    minimalBooking?.route?.useCustomsClassification,
  );

interface RouteWithImportExportSettings extends Pick<Route, 'importSettings' | 'exportSettings'> {}

export const getImportExportSettingsFromRoute = (
  route: RouteWithImportExportSettings | null | undefined,
  reference: 'importReference' | 'exportReference',
): Route_exportSettings | Route_importSettings | null => {
  if (!route) {
    return null;
  }

  return reference === 'importReference' ? route.importSettings : route.exportSettings;
};
