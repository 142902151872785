import { Txt } from '@stenajs-webui/core';
import { FC, ReactNode } from 'react';
import { ValueTableExpandableContent } from '../../../../common-ui/ValueTableExpandableContent';
import { ValueTableItem } from '@stenajs-webui/elements';

interface DetailsRowProps {
  className?: string;
  name: string;
  value?: string;
  suffix?: ReactNode;
  collapse: boolean;
}

export const DetailsRow: FC<DetailsRowProps> = ({ className, name, value, suffix, collapse }) => (
  <ValueTableItem
    label={name}
    value={
      value ? (
        collapse ? (
          <ValueTableExpandableContent>
            <Txt className={className}>
              {value}
              {suffix}
            </Txt>
          </ValueTableExpandableContent>
        ) : (
          <Txt className={className}>
            {value}
            {suffix}
          </Txt>
        )
      ) : undefined
    }
  />
);
