import { Column, Txt } from '@stenajs-webui/core';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { useCancelBooking } from '../../../../common/graphql/useCancelBooking';
import { useLocalDateFormat } from '../../../../common/hooks/useLocalDateFormat';
import { formatDate } from '../../../../common/utils/dates/dateUtils';
import { TrackerCategory, trackEvent } from '../../../../common/tracking/trackerService';
import { FC } from 'react';
import { CancelPanelBase } from '../../../../common-ui/CancelPanelBase';

export interface BookingCancelPanelProps {
  booking: BasicBooking;
  onClose: () => void;
  onFail?: () => void;
  onSuccess?: () => void;
  trackerCategory: TrackerCategory;
  className?: string;
}

export const BookingCancelPanel: FC<BookingCancelPanelProps> = ({
  booking,
  onClose,
  onFail,
  onSuccess,
  trackerCategory,
  className,
}: BookingCancelPanelProps) => {
  const { submit, submitting } = useCancelBooking({ booking, onFail, onSuccess });

  const dateFormat = useLocalDateFormat();

  const onConfirm = () => {
    trackEvent({ category: trackerCategory, action: 'Confirm cancel booking' });
    submit();
  };

  const onAbort = () => {
    trackEvent({ category: trackerCategory, action: 'Abort cancel booking' });
    onClose();
  };

  return (
    <CancelPanelBase
      className={className}
      onClose={onClose}
      submitting={submitting}
      onConfirm={onConfirm}
      onAbort={onAbort}
    >
      <Column>
        <Txt variant={'bold'}>{booking.bookingNo}</Txt>
        <Txt variant={'bold'}>
          {booking.sailing.route.longDescription} ({booking.sailing.route.code})
        </Txt>
        <Txt variant={'bold'}>
          {formatDate(booking.sailing.departureDate, dateFormat)} at {booking.sailing.departureTime}
        </Txt>
      </Column>
    </CancelPanelBase>
  );
};
