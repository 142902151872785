import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBackgroundLocation } from '../home/components/UseBackgroundLocation';
import { routeCreators } from '../routes/AppRoutes';
import { isOnManagePage } from '../routes/routeUtils';

export const useRedirectToManagePage = () => {
  const navigate = useNavigate();
  const location = useBackgroundLocation();
  return useCallback(() => {
    if (!isOnManagePage(location)) {
      navigate(routeCreators.bookings());
    }
  }, [navigate, location]);
};
