import { Box } from '@stenajs-webui/core';
import { Spinner } from '@stenajs-webui/elements';
import { FC, Suspense } from 'react';
import { GatePhotosFragment } from '../../../../../common/graphql/fragments/gql/DetailedBooking';
import { useLocalDateFormat } from '../../../../../common/hooks/useLocalDateFormat';
import { PortPicturesLocation } from './PortPicturesLocation';

interface Props {
  inboundHasCapability: boolean;
  outboundHasCapability: boolean;
  inbound: GatePhotosFragment | null;
  outbound: GatePhotosFragment | null;
  arrivalLocationName: string;
  departureLocationName: string;
}

export const PortPicturesView: FC<Props> = ({
  inboundHasCapability,
  outboundHasCapability,
  outbound,
  inbound,
  departureLocationName,
  arrivalLocationName,
}) => {
  const dateFormat = useLocalDateFormat();

  return (
    <Suspense
      fallback={
        <Box flex={1} justifyContent={'center'} alignItems={'center'} width={800} maxWidth={'100%'}>
          <Spinner />
        </Box>
      }
    >
      <Box width={800} maxWidth={'100%'}>
        <PortPicturesLocation
          hasCapability={inboundHasCapability}
          gatePhotoResult={inbound}
          fallbackLocationName={departureLocationName}
          dateFormat={dateFormat}
          locationSuffix={'Entry'}
        />
        <PortPicturesLocation
          hasCapability={outboundHasCapability}
          gatePhotoResult={outbound}
          fallbackLocationName={arrivalLocationName}
          locationSuffix={'Exit'}
          dateFormat={dateFormat}
        />
      </Box>
    </Suspense>
  );
};
