import styled from '@emotion/styled';
import { TextProps, Txt } from '@stenajs-webui/core';
import { FC, MouseEventHandler } from 'react';
import { stopPropagation } from '../common/eventHelpers';

export interface LinkProps extends TextProps {
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const ButtonWrapper = styled('button')`
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  background: none;
  cursor: pointer;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;

  .txt {
    color: var(--swui-text-action-color);
    text-decoration: underline;
  }

  &:hover,
  &:focus {
    .txt {
      color: var(--swui-text-action-color-focus);
    }
  }
`;

export const LinkButton: FC<LinkProps> = ({
  children,
  onClick,
  className,
  id,
  label,
  ...textProps
}) => {
  return (
    <ButtonWrapper
      className={className}
      id={id}
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
      onClick={onClick}
    >
      <Txt {...textProps} className={'txt'}>
        {label}
      </Txt>
    </ButtonWrapper>
  );
};
