import styled from '@emotion/styled';
import { Row, Txt } from '@stenajs-webui/core';
import { RadioButton } from '@stenajs-webui/forms';
import cx from 'classnames';
import { FC } from 'react';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { renderSwitchColumn, SwitchModalColumn } from './columns';

interface Props {
  selectedBookingNo?: number;
  bookings: BasicBooking[];
  onChange?: (value: BasicBooking | null) => void;
  columns: SwitchModalColumn[];
}

const Table = styled('table')`
  border-collapse: collapse;

  tr {
    height: 32px;
  }

  thead th,
  tbody td {
    min-width: 48px;
    border-bottom: thin solid var(--silver-ui);
    padding: var(--swui-metrics-spacing) calc(2 * var(--swui-metrics-indent));
  }

  tbody tr:focus-within {
    background: var(--lhds-color-blue-100);
  }

  tbody tr.selected {
    background: var(--lhds-color-blue-200);
  }
  tbody tr.clickable {
    cursor: pointer;
  }
`;

export const SwitchModalTable: FC<Props> = ({ columns, selectedBookingNo, bookings, onChange }) => (
  <Table>
    <thead>
      <tr>
        {onChange ? <th className={'icon'} /> : <th />}
        {columns.map(column => (
          <th key={column.id}>
            <Row style={column.headerStyle}>
              {typeof column.label === 'string' ? (
                <Txt
                  size={'small'}
                  variant={'bold'}
                  style={{ textAlign: 'left', whiteSpace: 'nowrap' }}
                >
                  {column.label}
                </Txt>
              ) : (
                column.label
              )}
            </Row>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {bookings.map(booking => (
        <tr
          key={booking.bookingNo}
          onClick={onChange ? () => onChange(booking) : undefined}
          className={cx({
            selected: selectedBookingNo === booking.bookingNo,
            clickable: Boolean(onChange),
          })}
        >
          {onChange ? (
            <td className={'icon'}>
              <RadioButton
                onValueChange={() => onChange(booking)}
                checked={selectedBookingNo === booking.bookingNo}
                size={'small'}
              />
            </td>
          ) : (
            <td />
          )}
          {columns.map(column => (
            <td key={column.id}>{renderSwitchColumn(booking, column)}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </Table>
);
