import { Tag } from '@stenajs-webui/elements';
import { FC } from 'react';
import {
  getPaymentIndicatorStatusText,
  getPaymentStatusDisplayProps,
} from '../../common/utils/paymentUtil';
import { PaymentStatus } from '../../gql/graphql';
import { DRAGGABLE_CANCEL_CLASSNAME } from '@stenajs-webui/modal';
import cx from 'classnames';

export const PaymentStatusLabel: FC<{ status: PaymentStatus }> = ({ status }) => {
  const label = getPaymentIndicatorStatusText(status);
  const { icon, tagVariant } = getPaymentStatusDisplayProps(status);

  if (!label) {
    return null;
  }

  return (
    <Tag
      icon={icon}
      variant={tagVariant}
      label={label}
      className={cx(DRAGGABLE_CANCEL_CLASSNAME, 't_payment_status_label')}
    />
  );
};
