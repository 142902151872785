import { FC } from 'react';
import {
  BaseModal,
  BaseModalProps,
} from '../../use-cases/manage/details/components/modal/BaseModal';

export const getModalRoot = (id: 'modal-root' | 'routeless-modal-root'): HTMLElement => {
  const container = document.getElementById(id);
  if (!container) {
    // tslint:disable-next-line
    console.error(`Target element "#${id}" is not in dom`);
    return document.body;
  }
  return container;
};

export const freightPortalBaseModalProps: Partial<BaseModalProps> = {
  appElement: document.getElementById('root')!,
  parentSelector: () => getModalRoot('modal-root'),
  width: 'auto',
  style: { content: { minWidth: 300, minHeight: 300, position: 'static' } },
};

export const FreightPortalBaseModal: FC<BaseModalProps> = props => {
  return <BaseModal {...freightPortalBaseModalProps} {...props} />;
};

export const FreightPortalLegacyBaseModal: FC<BaseModalProps> = props => {
  return (
    <BaseModal
      appElement={document.getElementById('root')!}
      parentSelector={() => getModalRoot('routeless-modal-root')}
      width={'auto'}
      style={{ content: { minWidth: 300, minHeight: 300, position: 'static' } }}
      {...props}
    />
  );
};
