import { FC } from 'react';
import { Box, Heading } from '@stenajs-webui/core';

export interface BookingDetailsSectionHeaderProps {
  title: string;
}
export const BookingDetailsSectionHeader: FC<BookingDetailsSectionHeaderProps> = ({ title }) => (
  <Box indent={3}>
    <Heading variant={'h3'}>{title}</Heading>
  </Box>
);
