import { call, put } from 'redux-saga/effects';
import { updatePageSettings2 } from '../../../common/api';
import { bufferTime } from '../../../common/saga/bufferTime';
import { commonBeforeUnload } from '../../common/commonActions';
import {
  rehydratePageSettings,
  requestSavePageSettings,
  RequestSavePageSettingsAction,
} from '../pageSettingsActions';
import { mergeUpdatePageSettingsInputs } from './utils/mergeUpdatePageSettingsInputs';
import { transformToPageSettingsRehydratePayload } from './utils/transformToPageSettingsRehydratePayload';

export function* submitPageSettings(action: RequestSavePageSettingsAction) {
  try {
    const result: Awaited<ReturnType<typeof updatePageSettings2>> = yield call(
      updatePageSettings2,
      action.payload,
    );

    if (result.settings) {
      yield put(rehydratePageSettings(transformToPageSettingsRehydratePayload(result.settings)));
    }
  } catch (e) {
    console.error(e, 'Failed to save page settings');
  }
}

export function* watchSubmitPageSettings() {
  yield bufferTime(
    10000,
    'PAGE_SETTINGS:REQUEST_SAVE',
    submitPageSettings,
    (a, b) => requestSavePageSettings(mergeUpdatePageSettingsInputs(a.payload, b.payload)),
    ['AUTH:USER_LOGGED_OUT', commonBeforeUnload],
  );
}
