import {
  AuthError,
  getAuthError,
  isAuth0SessionInformationFetched,
  isAuthenticated,
} from '../../services/authentication/authReducer';
import { StoreState } from '../../store/storeState';
import { useShallowEqualSelector } from './useShallowEqualSelector';

interface StateProps {
  isAuthenticated: boolean;
  sessionFetched: boolean;
  error: AuthError | null;
}
const mapState = (state: StoreState): StateProps => {
  return {
    isAuthenticated: isAuthenticated(state),
    sessionFetched: isAuth0SessionInformationFetched(state),
    error: getAuthError(state),
  };
};

export const useAuthState = () => {
  const { isAuthenticated, sessionFetched, error } = useShallowEqualSelector(mapState);

  return { isAuthenticated, sessionFetched, error };
};
