import { Box, Column, Heading } from '@stenajs-webui/core';
import { Banner, CardBody } from '@stenajs-webui/elements';
import { FC, useCallback, useState } from 'react';
import { resolveInformationMessageBannerVariant } from '../../../../common-ui/information-message/informationMessageUtils';
import { SailingInfoHorizontal } from '../../../../common-ui/sailing-info/SailingInfoHorizontal';
import { fetchSwitchCandidates } from '../../../../common/api';
import { assertIsDefined } from '../../../../common/asserts/assert';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { trackEvent } from '../../../../common/tracking/trackerService';
import { suspend } from '../../../../vendor/suspend-react';
import { useSwitch } from '../hooks/useSwitch';
import { switchModalCandidateColumns } from './columns';
import { SwitchInformationBanner, SwitchLoadingPreferenceBanner } from './SwitchInformationBanner';
import { SwitchModalFooter } from './SwitchModalFooter';
import { SwitchModalHeader } from './SwitchModalHeader';
import { SwitchModalTable } from './SwitchModalTable';

interface Props {
  bookingNo: number;
  onRequestClose: () => void;
}

export const BOOKING_WAS_NOT_FOUND_MESSAGE: string = 'Booking was not found';

export const SwitchModalContent: FC<Props> = ({ bookingNo, onRequestClose }) => {
  const { booking, switchCandidates } = suspend(
    () => fetchSwitchCandidates(bookingNo),
    ['switch-candidates', bookingNo],
  );

  const { switchBookings, statusInformationList, loading, setStatusInformationList } = useSwitch();

  const [bookingToSwitch, onChangeBookingToSwitch] = useState<BasicBooking | null>(
    switchCandidates.length === 1 ? switchCandidates[0] : null,
  );

  const onChangeCandidate = (booking: BasicBooking | null) => {
    setStatusInformationList([]);
    onChangeBookingToSwitch(booking);
  };

  assertIsDefined(booking, BOOKING_WAS_NOT_FOUND_MESSAGE);

  const onSubmit = useCallback(async () => {
    try {
      const switchBookingsResult = await switchBookings(booking, bookingToSwitch);
      if (switchBookingsResult?.success) {
        onRequestClose();
      }
    } catch (e) {
      trackEvent({ category: 'Switch', action: 'Error' });
    }
  }, [booking, bookingToSwitch, onRequestClose, switchBookings]);

  return (
    <>
      <SwitchModalHeader bookingNo={bookingNo} onRequestClose={onRequestClose} />
      <CardBody alignItems={'flex-start'} gap={3} maxWidth={'100%'}>
        <SailingInfoHorizontal
          hasArrived={booking.hasArrived}
          releasedAt={booking.releasedAt}
          sailing={booking.sailing}
          bookingStatus={booking.bookingStatus.status}
        />
        <Heading variant={'h5'} as={'h2'}>
          First booking
        </Heading>
        <Box width={'100%'} overflowX={'auto'}>
          <SwitchModalTable columns={switchModalCandidateColumns} bookings={[booking]} />
        </Box>
        {switchCandidates.length === 0 && (
          <SwitchInformationBanner
            text={
              'Could not find two bookings possible to switch. You can currently only switch a Waitlist booking with a similar Confirmed booking on the same sailing.'
            }
          />
        )}

        {switchCandidates.length > 0 && (
          <>
            <Heading variant={'h5'} as={'h2'}>
              Select second booking
            </Heading>
            <Box width={'100%'} overflowX={'auto'}>
              <SwitchModalTable
                columns={switchModalCandidateColumns}
                selectedBookingNo={bookingToSwitch?.bookingNo}
                bookings={switchCandidates}
                onChange={onChangeCandidate}
              />
            </Box>
          </>
        )}
      </CardBody>
      <SwitchModalFooter loading={loading} onClose={onRequestClose} onSubmit={onSubmit}>
        {statusInformationList.length > 0 ? (
          <Column gap>
            {statusInformationList.map((statusInformation, index) => (
              <Banner
                key={index}
                variant={resolveInformationMessageBannerVariant(statusInformation.type)}
                text={statusInformation.message}
              />
            ))}
          </Column>
        ) : (
          <SwitchLoadingPreferenceBanner booking={booking} bookingToSwitch={bookingToSwitch} />
        )}
      </SwitchModalFooter>
    </>
  );
};
