import { FC, ReactNode } from 'react';
import * as ReactDOM from 'react-dom';

interface PortalProps {
  target?: HTMLElement | string;
  children?: ReactNode;
}

export const Portal: FC<PortalProps> = ({ children, target = document.body }) => {
  const container = typeof target === 'string' ? document.querySelector(target) : target;

  if (!container) {
    // tslint:disable-next-line
    console.error(`Target element "${target}" is not in dom`);
    return <>{children}</>;
  }

  return ReactDOM.createPortal(children, container);
};
