import { Box, Row } from '@stenajs-webui/core';
import { FlatButton, stenaDots } from '@stenajs-webui/elements';
import { cssColor } from '@stenajs-webui/theme';
import { Popover } from '@stenajs-webui/tooltip';
import { FC, ReactNode } from 'react';
import { useOverflowingBox } from '../common/hooks/useOverflowingBox';
import { PRINT_HIDE_CLASSNAME } from '../globalStyles';

interface Props {
  children: ReactNode;
}

export const ValueTableExpandableContent: FC<Props> = ({ children }) => {
  const { ref, isOverflowing: showPopover } = useOverflowingBox();

  return (
    <Row width={'100%'} alignItems={'center'} display={'inline-flex'} position={'relative'}>
      <Row ref={ref} flex={1} overflow={'hidden'} style={{ whiteSpace: 'nowrap' }}>
        {children}
      </Row>
      {showPopover && (
        <div
          className={PRINT_HIDE_CLASSNAME}
          style={{
            background:
              'linear-gradient(to right, rgba(244,244,244,0) 0%, rgba(244,244,244,1) 50%)',
            position: 'absolute',
            paddingLeft: 24,
            right: 0,
            top: -4,
          }}
        >
          <Popover
            content={
              <Box width={'100dvw'} maxWidth={'29rem'}>
                {children}
              </Box>
            }
            placement={'right'}
            trigger={'click'}
          >
            <FlatButton
              size={'small'}
              leftIcon={stenaDots}
              color={cssColor('--lhds-color-blue-500')}
            />
          </Popover>
        </div>
      )}
    </Row>
  );
};
