import { BookingsSortColumn, ColumnId, SortDirection } from '../../gql/graphql';
import { StoreState } from '../../store/storeState';
import { getDefaultSortDirection } from '../../use-cases/manage/grid/utils/getDefaultSortDirection';
import { SortingActions } from './sortingActions';

export const INITIAL_VALUE: BookingsSortColumn = {
  column: ColumnId.DepartureDate,
  direction: getDefaultSortDirection(ColumnId.DepartureDate),
};

const toggleDirection = (direction: SortDirection) =>
  direction === SortDirection.Descending ? SortDirection.Ascending : SortDirection.Descending;

const getNextSortDirection = (
  { column, direction }: BookingsSortColumn,
  columnId: ColumnId,
): SortDirection =>
  columnId === column ? toggleDirection(direction) : getDefaultSortDirection(columnId);

export const sortingReducer = (
  state: BookingsSortColumn = INITIAL_VALUE,
  action: SortingActions,
): BookingsSortColumn => {
  switch (action.type) {
    case 'SORTING:SORT_BOOKINGS':
      return {
        direction: action.payload.direction,
        column: action.payload.property,
      };
    case 'SORTING:TOGGLE_SORT_BOOKINGS': {
      const { property } = action.payload;
      return {
        direction: getNextSortDirection(state, property),
        column: property,
      };
    }
    default:
      return state;
  }
};

export const getSorting = (state: StoreState): BookingsSortColumn => state.sorting;
export const getDirection = (state: StoreState): SortDirection => getSorting(state).direction;
export const getColumn = (state: StoreState): ColumnId => getSorting(state).column;
