import { ActionMenuItem, stenaSwitch } from '@stenajs-webui/elements';
import { FC, Suspense } from 'react';
import { fetchSwitchCandidates } from '../../../../../common/api';
import { ErrorBoundary } from '../../../../../common/error-handling/ErrorBoundary';
import { useShowModal } from '../../../../../common/hooks/useShowModal';
import { modalRouteCreators } from '../../../../../common/routes/AppRoutes';
import { trackEvent } from '../../../../../common/tracking/trackerService';
import { suspend } from '../../../../../vendor/suspend-react';
import { CommonBookingActionMenuItemProps } from './types';

interface SwitchBookingActionMenuItemProps extends CommonBookingActionMenuItemProps {
  modalAction: 'push' | 'replace';
}

export const SwitchBookingActionMenuItem: FC<SwitchBookingActionMenuItemProps> = ({
  booking: { bookingNo },
  onClick,
  trackerCategory,
  modalAction,
  trackerAction,
}) => {
  const { pushModal, replaceModal } = useShowModal();

  const onShowSwitchClick = () => {
    onClick?.();
    trackEvent({ category: trackerCategory, action: trackerAction });
    if (modalAction === 'push') {
      pushModal(modalRouteCreators.bookingSwitch, { bookingNo });
    } else {
      replaceModal(modalRouteCreators.bookingSwitch, { bookingNo });
    }
  };

  return (
    <ErrorBoundary fallback={() => null}>
      <Suspense
        fallback={
          <ActionMenuItem
            onClick={onShowSwitchClick}
            leftIcon={stenaSwitch}
            label={'Switch'}
            disabled
            loading
          />
        }
      >
        <SwitchBookingActionMenuItemLoader
          bookingNo={bookingNo}
          onShowSwitchClick={onShowSwitchClick}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

const SwitchBookingActionMenuItemLoader = ({
  bookingNo,
  onShowSwitchClick,
}: {
  bookingNo: number;
  onShowSwitchClick: () => void;
}) => {
  const { switchCandidates } = suspend(
    () => fetchSwitchCandidates(bookingNo),
    ['switch-candidates', bookingNo],
  );

  const switchCandidatesExists = switchCandidates.length > 0;

  return (
    <ActionMenuItem
      onClick={switchCandidatesExists ? onShowSwitchClick : undefined}
      leftIcon={stenaSwitch}
      label={'Switch'}
      disabled={!switchCandidatesExists}
      title={switchCandidatesExists ? undefined : 'No matching bookings to switch with'}
    />
  );
};
