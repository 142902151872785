import { Customer } from '../graphql/fragments/gql/Customer';

export const resolveCustomerDropdownOptionLabel = ({ custIndex }: Customer) => custIndex;

export const resolveCustomerLabel = (custNo: number, custIndex: string) => {
  return custNo.toString() === custIndex ? custIndex : `${custIndex} (${custNo})`;
};

export const customerReferencePlaceHolder = (
  customer: Customer | null | undefined,
): string | undefined => {
  if (!customer || !customer.customerReferenceRequired) {
    return undefined;
  }

  return customer.defaultCustomerReference
    ? customer.defaultCustomerReference
    : 'Reference required';
};
