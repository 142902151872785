import { Placement } from '../../../../common-ui/popover/FreightPortalPopover';
import { CalculatePrice } from '../../../../common-ui/pricing/CalculatePrice';
import { trackEvent } from '../../../../common/tracking/trackerService';
import { useCalculatePrice } from '../../../../common/pricing/useCalculatePrice';
import { BookingFormState } from '../../../../services/update-form/updateFormReducer';
import { transformToInput } from '../utils/transformFormData';
import { isPriceOutdated } from './isPriceOutdated';
import { FC } from 'react';
import { transformFormStateToCalculatePriceInput } from './transformFormStateToCalculatePriceInput';

export interface CalculatePriceProps {
  formState?: BookingFormState;
  placement?: Placement;
}

export const CalculatePriceForDetails: FC<CalculatePriceProps> = ({ formState, placement }) => {
  const { result, errors, fetchPrice, loading, priceOutdated } = useCalculatePrice(
    formState,
    isPriceOutdated,
  );

  const onClick = () => {
    trackEvent({ category: 'Details', action: 'CalculatePrice' });

    if (formState) {
      fetchPrice(
        {
          bookings: [transformFormStateToCalculatePriceInput(transformToInput(formState))],
        },
        formState,
      );
    }
  };

  return (
    <CalculatePrice
      errors={errors}
      priceOutdated={priceOutdated}
      result={result}
      loading={loading}
      onClick={onClick}
      placement={placement}
      trackerCategory={'Details'}
      disabled={!formState?.sailing}
    />
  );
};
