import { ActionMenuItem, stenaMobile } from '@stenajs-webui/elements';
import { FC } from 'react';
import { TrackerCategory, trackEvent } from '../../../../../common/tracking/trackerService';

interface SendBookingActionMenuItemProps {
  onClick?: () => void;
  trackerCategory: TrackerCategory;
  trackerAction: string;
  disabled?: boolean | undefined;
}

export const SendBookingActionMenuItem: FC<SendBookingActionMenuItemProps> = ({
  onClick,
  disabled,
  trackerCategory,
  trackerAction,
}) => {
  const onClickActionMenuItem = () => {
    onClick?.();
    trackEvent({ category: trackerCategory, action: trackerAction });
  };
  return (
    <ActionMenuItem
      label={'Send to Driver app'}
      leftIcon={stenaMobile}
      onClick={onClickActionMenuItem}
      disabled={disabled}
    />
  );
};
