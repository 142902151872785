import { BookingStatus } from '../../gql/graphql';
import { useHasPermission } from '../statistics/useHasPermission';
import { Permission } from '../../services/authentication/utils/authUtils';

export const bookingStatusIsValidForCalculatePrice = (bookingStatus?: BookingStatus | null) =>
  bookingStatus === BookingStatus.Confirmed ||
  bookingStatus === BookingStatus.BlockBooking ||
  bookingStatus === BookingStatus.Waitlist;

export const useShowCalculatePrice = (
  isCashCustomer: boolean | undefined,
  status?: BookingStatus | null,
) => {
  const getHasPermission = useHasPermission();

  const userHasAccess = isCashCustomer || getHasPermission(Permission.PriceCalculation);

  if (status) {
    return userHasAccess && bookingStatusIsValidForCalculatePrice(status);
  }

  return userHasAccess;
};
