import { Row, Space, Spacing, Txt } from '@stenajs-webui/core';
import { TextInputButtonSize } from '@stenajs-webui/elements';
import { FC, useCallback, useState } from 'react';
import { InformationMessageType } from '../../common-ui/information-message/informationMessageUtils';
import { LinkButton } from '../../common-ui/LinkButton';
import { RowInformationCellPopup } from './RowInformationCellPopup';

export interface WaitListPossiblePopupProps {
  size?: TextInputButtonSize;
  onSetBookingStatusToWaitlist?: () => void;
  onIgnoreChanges?: () => void;
}

export const WaitListPossibleIconWithPopup: FC<WaitListPossiblePopupProps> = ({
  size,
  onIgnoreChanges,
  onSetBookingStatusToWaitlist,
}) => {
  const [open, setOpen] = useState(true);

  const onIgnoreChangesClick = useCallback(() => {
    setOpen(false);
    if (onIgnoreChanges) {
      onIgnoreChanges();
    }
  }, [onIgnoreChanges, setOpen]);

  const onAddToWaitlistClick = useCallback(() => {
    setOpen(false);
    if (onSetBookingStatusToWaitlist) {
      onSetBookingStatusToWaitlist();
    }
  }, [setOpen, onSetBookingStatusToWaitlist]);

  return (
    <RowInformationCellPopup
      type={InformationMessageType.SoftWarning}
      size={size}
      initialOpen={open}
      title={'Max capacity reached on sailing'}
    >
      <Row>
        <Txt>
          Unfortunately the selected sailing is unable to accommodate any more bookings with the
          selected options. Do you want to place the booking on the waiting list?
        </Txt>
      </Row>

      <Spacing num={2}>
        <Row>
          <LinkButton
            variant={'bold'}
            onClick={onAddToWaitlistClick}
            label={'Yes, place on waiting list'}
          />
        </Row>
        <Space num={2} />
        <Row>
          <LinkButton
            variant={'bold'}
            onClick={onIgnoreChangesClick}
            label={'No, ignore changes and go back'}
          />
        </Row>
      </Spacing>
    </RowInformationCellPopup>
  );
};
