import { NavBarSearchField } from '@stenajs-webui/panels';
import { FC, useLayoutEffect, useRef } from 'react';
import { MAXIMUM_SEARCH_LENGTH } from '../../../../common/graphql/useSearchBookingsResult';

export const WRAPPER_CLASS_NAME = 'booking-search-wrapper';

interface BookingSearchInputProps {
  hasFocus: boolean;
  onClickClear: () => void;
  onClickSearch: () => void;
  onValueChange: (value: string) => void;
  onFocus: () => void;
  value: string;
  onEsc: () => void;
}

export const BookingSearchInput: FC<BookingSearchInputProps> = ({
  hasFocus,
  onClickClear,
  onClickSearch,
  onEsc,
  onFocus,
  onValueChange,
  value,
}: BookingSearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (hasFocus) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [hasFocus]);

  return (
    <NavBarSearchField
      inputRef={inputRef}
      placeholder={'Search bookings'}
      showClearButton={hasFocus}
      onClickClearButton={() => {
        onClickClear();
        inputRef.current?.focus();
      }}
      onValueChange={onValueChange}
      onFocus={onFocus}
      value={value}
      onEnter={onClickSearch}
      onEsc={onEsc}
      wrapperClassName={WRAPPER_CLASS_NAME}
      maxLength={MAXIMUM_SEARCH_LENGTH}
    />
  );
};
