import { FC } from 'react';
import { useUpdateField } from '../../../../../common/update-information/hooks/useUpdateField';
import { NumericFieldKeys } from '../../../../../services/update-form/updateFormReducer';
import {
  CommonNumericTextInputField,
  CommonNumericTextInputProps,
} from '../../../../../common-ui/form-fields/CommonNumericTextInput';
import { getFieldProps } from '../../../../../common-ui/form-fields/getFieldProps';

export interface NumericFieldProps
  extends Omit<CommonNumericTextInputProps, 'value' | 'onValueChange' | 'trackerAction'> {
  name: NumericFieldKeys;
}

export const NumericField: FC<NumericFieldProps> = ({ name, ...props }) => {
  const fieldProps = useUpdateField<NumericFieldKeys>(name);
  return (
    <CommonNumericTextInputField
      {...props}
      {...getFieldProps(props, fieldProps)}
      width={'100%'}
      changed={fieldProps.editedValue !== fieldProps.initialValue}
    />
  );
};
