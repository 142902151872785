import {
  CommonDropdownField,
  CommonDropdownFieldProps,
} from '../../../../common-ui/form-fields/CommonDropdownField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import {
  CreateIntermodalBookingFormModel,
  CreateIntermodalBookingFormModelDropdownKeys,
} from '../../../../services/intermodal/createIntermodalReducer';
import { useCreateIntermodalField } from '../../hooks/useCreateIntermodalField';

interface CreateIntermodalDropDownFieldProps<
  Name extends CreateIntermodalBookingFormModelDropdownKeys,
> extends Omit<
    CommonDropdownFieldProps<CreateIntermodalBookingFormModel[Name]>,
    'onValueChange' | 'value' | 'trackerAction'
  > {
  name: Name;
}

export function CreateIntermodalDropdownField<
  Name extends CreateIntermodalBookingFormModelDropdownKeys,
>({ name, ...props }: CreateIntermodalDropDownFieldProps<Name>) {
  const fieldProps = useCreateIntermodalField(name);
  return <CommonDropdownField {...props} {...getFieldProps(props, { ...fieldProps })} />;
}
