import { AccountQuery_viewer_account_byEmail } from '../graphql/gql/AccountQuery';
import {
  getShowDashboardOnScroll,
  getShowDateOverview,
  getShowNotCheckedIn,
  getShowPendingApproval,
  getShowUnaccompaniedUnits,
} from './defaultSettings';

export const mergeSettings = (
  setting: AccountQuery_viewer_account_byEmail,
  defaultSetting: AccountQuery_viewer_account_byEmail,
): AccountQuery_viewer_account_byEmail => {
  return {
    customers: setting.customers ?? defaultSetting.customers,
    accountSettings: {
      bookDate: setting.accountSettings.bookDate ?? defaultSetting.accountSettings.bookDate,
      bookCustomer:
        setting.accountSettings.bookCustomer ?? defaultSetting.accountSettings.bookCustomer,
      bookDefaultRoute:
        setting.accountSettings.bookDefaultRoute ?? defaultSetting.accountSettings.bookDefaultRoute,
      bookDefaultVehicleType:
        setting.accountSettings.bookDefaultVehicleType ??
        defaultSetting.accountSettings.bookDefaultVehicleType,
      favouriteRoutes:
        setting.accountSettings.favouriteRoutes ?? defaultSetting.accountSettings.favouriteRoutes,
      favouriteVehicleTypes:
        setting.accountSettings.favouriteVehicleTypes ??
        defaultSetting.accountSettings.favouriteVehicleTypes,
      dateFormat: setting.accountSettings.dateFormat ?? defaultSetting.accountSettings.dateFormat,
      accountEmail:
        setting.accountSettings.accountEmail ?? defaultSetting.accountSettings.accountEmail,
      accountFirstName:
        setting.accountSettings.accountFirstName ?? defaultSetting.accountSettings.accountFirstName,
      id: setting.accountSettings.id ?? defaultSetting.accountSettings.id,
      accountLastName:
        setting.accountSettings.accountLastName ?? defaultSetting.accountSettings.accountLastName,
      accountLoginEmail:
        setting.accountSettings.accountLoginEmail ??
        defaultSetting.accountSettings.accountLoginEmail,
      accountPhone:
        setting.accountSettings.accountPhone ?? defaultSetting.accountSettings.accountPhone,
      accountSecondEmail:
        setting.accountSettings.accountSecondEmail ??
        defaultSetting.accountSettings.accountSecondEmail,
      accountSendBookingConfirmationAsEmail:
        setting.accountSettings.accountSendBookingConfirmationAsEmail ??
        defaultSetting.accountSettings.accountSendBookingConfirmationAsEmail,
      filterDateInterval:
        setting.accountSettings.filterDateInterval ??
        defaultSetting.accountSettings.filterDateInterval,
      filterFilterStates:
        setting.accountSettings.filterFilterStates ??
        defaultSetting.accountSettings.filterFilterStates,
      filterRoutes:
        setting.accountSettings.filterRoutes ?? defaultSetting.accountSettings.filterRoutes,
      dashboardNotCheckedInTime:
        setting.accountSettings.dashboardNotCheckedInTime ??
        defaultSetting.accountSettings.dashboardNotCheckedInTime,
      dashboardShowNotCheckedIn:
        setting.accountSettings.dashboardShowNotCheckedIn ??
        getShowNotCheckedIn(defaultSetting.accountSettings.dashboardShowNotCheckedIn),
      dashboardShowPendingApproval:
        setting.accountSettings.dashboardShowPendingApproval ??
        getShowPendingApproval(defaultSetting.accountSettings.dashboardShowPendingApproval),
      dashboardShowDashboardOnScroll:
        setting.accountSettings.dashboardShowDashboardOnScroll ??
        getShowDashboardOnScroll(defaultSetting.accountSettings.dashboardShowDashboardOnScroll),
      dashboardShowDateOverview:
        setting.accountSettings.dashboardShowDateOverview ??
        getShowDateOverview(defaultSetting.accountSettings.dashboardShowDateOverview),
      dashboardShowUnaccompaniedUnits:
        setting.accountSettings.dashboardShowUnaccompaniedUnits ??
        getShowUnaccompaniedUnits(defaultSetting.accountSettings.dashboardShowUnaccompaniedUnits),
    },
    privacySettings: {
      ...defaultSetting.privacySettings,
      ...setting.privacySettings,
    },
    pageSettings: setting.pageSettings,
  };
};
