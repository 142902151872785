import { createAsyncThunk } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';
import { first } from 'lodash';
import {
  fetchAllRoutesCached,
  fetchSailings,
  fetchVehicleTypeOptionsCached,
} from '../../../common/api';
import { assertIsDefined } from '../../../common/asserts/assert';
import { Route } from '../../../common/graphql/fragments/gql/Route';
import { SettingsFragment } from '../../../common/graphql/fragments/gql/SettingsFragment';
import { VehicleType } from '../../../common/graphql/fragments/gql/VehicleType';
import { formatServerDate } from '../../../common/utils/dates/dateUtils';
import { DateSetting } from '../../../gql/graphql';
import { StoreState } from '../../../store/storeState';
import { getInitialCreateCandidate } from '../../../use-cases/create/utils/bookingCandidateUtils';
import { getAccount } from '../../settings/settingsReducer';
import {
  CreateFormIdVariant,
  initializeCreateForm as initializeCreateFormAction,
} from '../createFormActions';
import { getSingleBookableSailing } from '../../../common/utils/sailingUtils';

async function getRoute(accountSettings: SettingsFragment): Promise<Route> {
  if (accountSettings.bookDefaultRoute) {
    return accountSettings.bookDefaultRoute;
  } else if (accountSettings.favouriteRoutes.length > 0) {
    return accountSettings.favouriteRoutes[0];
  } else {
    const routes = await fetchAllRoutesCached();
    return routes[0];
  }
}

const getVehicleTypeFromSettings = (accountSettings: SettingsFragment): VehicleType | null => {
  if (accountSettings.bookDefaultVehicleType) {
    return accountSettings.bookDefaultVehicleType;
  } else if (accountSettings.favouriteVehicleTypes.length > 0) {
    return accountSettings.favouriteVehicleTypes[0];
  } else {
    return null;
  }
};

async function getVehicleType(
  accountSettings: SettingsFragment,
  customerNo: number,
  routeCode: string,
): Promise<VehicleType> {
  const vehicleType = getVehicleTypeFromSettings(accountSettings);
  if (vehicleType) {
    return vehicleType;
  }

  const vehicleTypes = await fetchVehicleTypeOptionsCached(customerNo, routeCode);
  return vehicleTypes[0];
}

export const initializeCreateFormThunk = createAsyncThunk<
  void,
  CreateFormIdVariant,
  { state: StoreState }
>('initializeCreateForm', async (formId, { dispatch, getState }) => {
  const today = new Date();
  const tomorrow = addDays(today, 1);

  const account = getAccount(getState());

  assertIsDefined(account, 'Account is not initialized');

  const { accountSettings, customers } = account;

  const departureDate = formatServerDate(
    accountSettings.bookDate === DateSetting.Today ? today : tomorrow,
  );

  const customer = accountSettings.bookCustomer ?? first(customers);

  assertIsDefined(customer, 'Account has no customers');

  const route = await getRoute(accountSettings);
  const vehicleType = await getVehicleType(accountSettings, customer.custNo, route.code);
  const sailings = await fetchSailings(route.code, departureDate);

  const candidate = getInitialCreateCandidate(
    vehicleType,
    vehicleType.defaultHeight,
    vehicleType.defaultLength,
    vehicleType.defaultWidth,
    vehicleType.defaultTradeWeight,
  );

  dispatch(
    initializeCreateFormAction(formId, {
      bookings: [candidate],
      initialBookings: [candidate],
      customer,
      departureDate,
      route,
      sailing: getSingleBookableSailing(sailings),
    }),
  );
});
