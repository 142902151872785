export function initializePlannerInstallPromptAndroidAfterFirstReactRender() {
  let deferredPrompt: any;
  window.addEventListener(
    'beforeinstallprompt',
    e => {
      e.preventDefault();

      // Stash the event so it can be triggered later.
      deferredPrompt = e;

      window.addEventListener(
        'click',
        () => {
          deferredPrompt.prompt();

          deferredPrompt.userChoice.then((choiceResult: any) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }

            deferredPrompt = null;
          });
        },
        { once: true },
      );
    },
    { once: true },
  );
}
