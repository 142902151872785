import { BannerVariant, TextInputButtonVariant } from '@stenajs-webui/elements';

export interface InformationMessageInfo {
  variant: TextInputButtonVariant;
}

export enum InformationMessageType {
  Confirmed,
  HardWarning,
  SoftWarning,
  RegularInfo,
}

export interface InformationMessage {
  message: string;
  type: InformationMessageType;
}

export const getInformationMessageInfo = (type: InformationMessageType): InformationMessageInfo => {
  switch (type) {
    case InformationMessageType.Confirmed:
      return {
        variant: 'success',
      };
    case InformationMessageType.HardWarning:
      return {
        variant: 'error',
      };
    case InformationMessageType.SoftWarning:
      return {
        variant: 'warning',
      };
    case InformationMessageType.RegularInfo:
      return {
        variant: 'normal',
      };
    default:
      return {
        variant: 'success',
      };
  }
};

export const resolveInformationMessageBannerVariant = (
  type: InformationMessageType | null,
): BannerVariant => {
  switch (type) {
    case InformationMessageType.Confirmed:
      return 'success';
    case InformationMessageType.HardWarning:
      return 'error';
    case InformationMessageType.SoftWarning:
      return 'warning';
    case InformationMessageType.RegularInfo:
      return 'info';
    default:
      return 'standard';
  }
};
