import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { _PersistConfig } from 'redux-persist/lib/types';
import { healthCheckReducer } from '../services/apollo/healthCheckReducer';
import { authReducer } from '../services/authentication/authReducer';
import { filterReducer } from '../services/booking-filter/filterReducer';
import { columnsFilterReducer } from '../services/columns-filter/columnsFilterReducer';
import { cookieConsentReducer } from '../services/cookie-consent/slice';
import { createReducer } from '../services/create/createReducer';
import { customsLinkLoaderReducer } from '../services/customs-link/customsLinkReducer';
import { dashboardReducer } from '../services/dashboard/dashboardReducer';
import { frontendOutOfDateReducer } from '../services/frontend-out-of-date/frontendOutOfDateReducer';
import { gridReducer } from '../services/grid/gridReducer';
import { modalReducer } from '../services/modal/modalReducer';
import { multiCreateReducer } from '../services/multi-create/multiCreateReducer';
import {
  notificationsReducer,
  NotificationsReducerState,
} from '../services/notifications/notificationsReducer';
import { searchReducer } from '../services/search/searchReducer';
import { settingsReducer } from '../services/settings/settingsReducer';
import { sidebarReducer, SidebarReducerState } from '../services/sidebar/sidebarReducer';
import { sortingReducer } from '../services/sorting/sortingReducer';
import { switchReducer } from '../services/switch/switchReducer';
import { tourReducer } from '../services/tour/tourReducer';
import { updateFormReducer } from '../services/update-form/updateFormReducer';
import { subscriptionSettingsReducer } from '../services/user-subscriptions/userSubscriptionsReducer';
import { StoreState } from './storeState';
import { updateIntermodalBookingReducer } from '../services/intermodal/updateIntermodalReducer';
import { createIntermodalBookingReducer } from '../services/intermodal/createIntermodalReducer';
import { intermodalGridStatusReducer } from '../services/intermodal/IntermodalGridStatusReducer';

const localPersistConfig: Pick<_PersistConfig<any>, 'storage' | 'stateReconciler'> = {
  storage,
  stateReconciler: false,
};

const notificationsPersistConfig: _PersistConfig<NotificationsReducerState> = {
  ...localPersistConfig,
  key: 'notifications-persisted-state',
};

const sidebarPersistConfig: _PersistConfig<SidebarReducerState> = {
  ...localPersistConfig,
  whitelist: ['railEnabled'],
  key: 'sidebar-persisted-state',
};

export const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  columnsFilter: columnsFilterReducer,
  cookieConsent: cookieConsentReducer,
  customsLinkLoader: customsLinkLoaderReducer,
  create: createReducer,
  dashboard: dashboardReducer,
  filter: filterReducer,
  sidebar: persistReducer(sidebarPersistConfig, sidebarReducer) as typeof sidebarReducer,
  frontendOutOfDate: frontendOutOfDateReducer,
  grid: gridReducer,
  health: healthCheckReducer,
  intermodalCreate: createIntermodalBookingReducer,
  intermodalUiStatus: intermodalGridStatusReducer,
  intermodalUpdate: updateIntermodalBookingReducer,
  modal: modalReducer,
  multiCreate: multiCreateReducer,
  notifications: persistReducer(
    notificationsPersistConfig,
    notificationsReducer,
  ) as typeof notificationsReducer,
  searchQuery: searchReducer,
  settings: settingsReducer,
  sorting: sortingReducer,
  switch: switchReducer,
  tour: tourReducer,
  updateForm: updateFormReducer,
  userSubscriptions: subscriptionSettingsReducer,
});
