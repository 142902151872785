import { ActionMenuItem, ActionMenuSeparator, stenaPrint } from '@stenajs-webui/elements';
import { ActionMenuSecondaryButton } from '@stenajs-webui/panels';
import { FC, lazy, Suspense } from 'react';
import {
  BookingModalTitleBase,
  BookingModalTitleBaseProps,
} from '../../../common-ui/BookingModalTitleBase';
import { IntermodalBookingStatusTag } from '../../../common-ui/BookingStatusTag';
import { CopyActionMenuItem } from '../../../common-ui/CopyActionMenuItem';
import { isIntermodalBookingClaimable } from '../../../common/claims/claimsUtils';
import { stringifyIntermodalBooking } from '../../../common/formatters/bookingStringFormatter';
import { useLocalDateFormat } from '../../../common/hooks/useLocalDateFormat';
import { TrackerCategory, trackEvent } from '../../../common/tracking/trackerService';
import { DetailedIntermodalBookingFragment } from '../../../gql/graphql';
import {
  ACTION_MENU_PLACEMENT,
  ACTION_MENU_WIDTH,
} from '../../manage/details/components/BookingActionMenuButton';
import { ActionMenuClaimsLink } from '../../manage/grid/components/options-table-cell/ActionMenuClaimsLink';

const IntermodalPrintButton = lazy(() => import('../../../common/pdf/IntermodalPrintButton'));

interface IntermodalDetailsHeaderProps extends Pick<BookingModalTitleBaseProps, 'className'> {
  booking: DetailedIntermodalBookingFragment;
}

const trackerCategory: TrackerCategory = 'IntermodalUpdate';

export const UpdateIntermodalHeader: FC<IntermodalDetailsHeaderProps> = ({
  className,
  booking,
}) => {
  const dateFormat = useLocalDateFormat();
  const showClaimButton = isIntermodalBookingClaimable(booking.status.status);

  const getIntermodalBookingString = () => {
    trackEvent({ category: trackerCategory, action: 'Copy to clipboard' });
    return stringifyIntermodalBooking(booking, dateFormat);
  };

  return (
    <BookingModalTitleBase
      className={className}
      bookingNo={booking.bookingNo}
      bookingStatus={<IntermodalBookingStatusTag status={booking.status} />}
      bookingActions={
        <ActionMenuSecondaryButton
          placement={ACTION_MENU_PLACEMENT}
          label={'Booking actions'}
          menuWidth={ACTION_MENU_WIDTH}
          renderItems={close => (
            <>
              <Suspense
                fallback={<ActionMenuItem label={'Print booking'} leftIcon={stenaPrint} loading />}
              >
                <IntermodalPrintButton booking={booking} dateFormat={dateFormat} />
              </Suspense>
              <CopyActionMenuItem getValue={getIntermodalBookingString} />
              {showClaimButton && (
                <>
                  <ActionMenuSeparator />
                  <ActionMenuClaimsLink
                    bookingNo={booking.bookingNo}
                    trackerCategory={trackerCategory}
                    onClick={close}
                  />
                </>
              )}
            </>
          )}
        />
      }
    />
  );
};
