import { TravelledVehicle } from '../../common/graphql/fragments/gql/TravelledVehicle';
import { VehicleType } from '../../common/graphql/fragments/gql/VehicleType';

export enum TravelledVehicleType {
  Vehicle,
  Trailer,
}

export interface RegistrationNumberAlertFormState {
  appliedArrivalNotepad: boolean;
  appliedHeight: boolean;
  appliedLength: boolean;
  appliedLoadingListMessage: boolean;
  appliedCargoWeight: boolean;
  appliedHazardousGoods: boolean;
  result: TravelledVehicle | null;
  travelledVehicleType: TravelledVehicleType | null;
  vehicleType: VehicleType | null;
  appliedTravelledVehicle: boolean;
}
