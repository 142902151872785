import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { graphql } from '../../gql';
import { TrackerCategory, trackEvent } from '../tracking/trackerService';

export const shareBookingMutation = graphql(/*GraphQL*/ `
  mutation ShareBooking($bookingNo: Int!, $vehicleRegNo: String!, $mobilePhoneNo: String!) {
    shareBooking(
      bookingNo: $bookingNo
      vehicleRegNo: $vehicleRegNo
      mobilePhoneNo: $mobilePhoneNo
    ) {
      bookingNo
      vehicleRegNo
      mobilePhoneNo
      success
    }
  }
`);

export const useShareBooking = (
  bookingNo: number,
  vehicleRegNo: string,
  trackerCategory: TrackerCategory,
) => {
  const [submitting, setSubmitting] = useState(false);
  const [shareResult, setResult] = useState<boolean | undefined>(undefined);

  const [shareBooking] = useMutation(shareBookingMutation);

  const resetShareResult = useCallback(() => {
    setResult(undefined);
  }, [setResult]);

  const submit = useCallback(
    (mobilePhoneNo: string) => {
      setSubmitting(true);
      trackEvent({ category: trackerCategory, action: 'Share booking (send)' });

      shareBooking({
        variables: { bookingNo, vehicleRegNo, mobilePhoneNo },
      })
        .then(result => {
          setSubmitting(false);
          if (result) {
            if (result.errors) {
              trackEvent({
                category: trackerCategory,
                action: `Share booking failed`,
              });
              setResult(false);
            } else if (result.data) {
              setResult(true);
            }
          }
        })
        .catch(() => {
          setSubmitting(false);
          setResult(false);
        });
    },
    [bookingNo, vehicleRegNo, shareBooking, setResult, trackerCategory],
  );

  return {
    submit,
    submitting,
    shareResult,
    resetShareResult,
  };
};
