import { useEffect } from 'react';
import { useLatest } from '../../../../common/hooks/useLatest';

export const useOnBlurToIframe = (callback: Function) => {
  const ref = useLatest(callback);

  useEffect(() => {
    const listener = () => {
      // setTimeout is needed because Firefox doesn't set
      // `document.activeElement` until after the blur event

      setTimeout(() => {
        if (document.activeElement?.tagName === 'IFRAME') {
          ref.current();
        }
      }, 0);
    };
    window.addEventListener('blur', listener, { passive: true });

    return () => {
      window.removeEventListener('blur', listener);
    };
  }, [ref]);
};
