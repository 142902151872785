import { BookingFormState, BookingFormStateKeys } from '../updateFormReducer';

export const isEqualFormStates = (
  initial: BookingFormState | undefined,
  edited: BookingFormState | undefined,
): boolean => {
  if (!initial || !edited) {
    return false;
  }

  if (Object.keys(initial).length !== Object.keys(edited).length) {
    return false;
  }

  for (const key of Object.keys(initial) as BookingFormStateKeys) {
    if ((!initial[key] || !edited[key]) && initial[key] !== edited[key]) {
      return false;
    }

    if (key === 'sailing') {
      if (
        initial[key] !== undefined &&
        edited[key] !== undefined &&
        initial[key]!.id !== edited[key]!.id
      ) {
        return false;
      }
    } else if (key === 'vehicleType' || key === 'route') {
      if (
        initial[key] !== undefined &&
        edited[key] !== undefined &&
        initial[key]!.code !== edited[key]!.code
      ) {
        return false;
      }
    } else if (key === 'customer') {
      if (
        initial[key] !== undefined &&
        edited[key] !== undefined &&
        initial[key]!.custNo !== edited[key]!.custNo
      ) {
        return false;
      }
    } else if (
      key === 'livestockType' ||
      key === 'loadingPreference' ||
      key === 'customsClassification'
    ) {
      if (
        initial[key] !== undefined &&
        initial[key] !== null &&
        edited[key] !== undefined &&
        edited[key] !== null &&
        initial[key]!.value !== edited[key]!.value
      ) {
        return false;
      }
    } else {
      if (initial[key] !== edited[key]) {
        return false;
      }
    }
  }
  return true;
};
