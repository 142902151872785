import { Column, Heading, Row, Space, Txt } from '@stenajs-webui/core';
import {
  Icon,
  Link,
  PrimaryButton,
  stenaExclamationCircle,
  stenaRefresh,
} from '@stenajs-webui/elements';
import { FC, ReactNode } from 'react';
import { ErrorBoundary } from './ErrorBoundary';
import { ErrorPage } from './ErrorPage';
import { CONTACT_URL } from '../footer/Footer';

interface Props {
  children: ReactNode;
}

export const ErrorPageBoundary: FC<Props> = ({ children }) => (
  <ErrorBoundary
    fallback={() => (
      <ErrorPage
        content={
          <Column alignItems={'flex-start'}>
            <Row alignItems={'center'}>
              <Icon icon={stenaExclamationCircle} />
              <Space />
              <Heading variant={'h2'}>An error occurred</Heading>
            </Row>
            <Txt>
              Unfortunately we encountered an unexpected problem. Please try to reload the page.
            </Txt>
            <Space num={2} />
            <PrimaryButton
              label={'Reload page'}
              onClick={() => window.location.reload()}
              leftIcon={stenaRefresh}
            />
            <Space num={6} />
            <Txt>
              Please <Link href={CONTACT_URL}>contact your local booking office</Link> if the
              problem persists and you need booking assistance.
            </Txt>
          </Column>
        }
        title={'An error occurred'}
      />
    )}
  >
    {children}
  </ErrorBoundary>
);
