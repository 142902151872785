import { Box, Row, SeparatorLine } from '@stenajs-webui/core';
import { Tab, TabMenu } from '@stenajs-webui/elements';
import { FC, ReactNode } from 'react';
import { DetailedBooking } from '../../../../../common/graphql/fragments/gql/DetailedBooking';
import { PortPicturesView } from './PortPicturesView';
import { BookingDetailsTabs } from './types';

interface Props {
  booking: DetailedBooking;
  gatePhotos: NonNullable<DetailedBooking['gatePhotos']>;
  children: ReactNode;
  tab: BookingDetailsTabs;
  setTab: (v: BookingDetailsTabs) => void;
}

export const BookingDetailsTabView: FC<Props> = ({
  gatePhotos,
  children,
  tab,
  setTab,
  booking,
}) => {
  return (
    <Box minHeight={650}>
      <Row indent={1}>
        <TabMenu>
          <Tab label={'Details'} selected={tab === 'details'} onClick={() => setTab('details')} />
          <Tab
            label={'Gate photos'}
            selected={tab === 'gate-photos'}
            onClick={() => {
              setTab('gate-photos');
            }}
          />
        </TabMenu>
      </Row>
      <SeparatorLine />

      {tab === 'gate-photos' ? (
        <PortPicturesView
          inboundHasCapability={gatePhotos.inboundHasCapability}
          outboundHasCapability={gatePhotos.outboundHasCapability}
          inbound={gatePhotos.inbound}
          outbound={gatePhotos.outbound}
          arrivalLocationName={booking.sailing.route.arrivalLocation.name}
          departureLocationName={booking.sailing.route.departureLocation.name}
        />
      ) : (
        children
      )}
    </Box>
  );
};
