import { Box } from '@stenajs-webui/core';
import { Banner } from '@stenajs-webui/elements';
import { FC } from 'react';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { GoodsApprovalStatus } from '../../../../common/utils/GoodsApproval';
import { resolveLoadingPreferenceApproved } from '../../../../common/utils/loadingPreferenceUtil';

export interface SwitchInformationBannerProps {
  text: string;
}

const resolveRemovedConfirmedApprovalText = (
  bookingToSwitch: BasicBooking,
): string => `The booking ${bookingToSwitch.bookingNo} includes a confirmed loading reference that will be set to
                  ”Requested” after the switch.`;

const getBookingWithApprovedLoadingPreference = (
  booking: BasicBooking | null,
): BasicBooking | null => {
  if (!booking) {
    return null;
  }

  const goodsApprovalStatus = resolveLoadingPreferenceApproved(
    booking.loadingPreference,
    booking.loadingPreferenceStatusCode,
  );

  return goodsApprovalStatus === GoodsApprovalStatus.Approved ? booking : null;
};

export const SwitchInformationBanner: FC<SwitchInformationBannerProps> = ({ text }) => {
  return (
    <Box maxWidth={800}>
      <Banner variant={'info'} text={text} />
    </Box>
  );
};

export interface SwitchLoadingPreferenceBannerProps {
  booking: BasicBooking;
  bookingToSwitch: BasicBooking | null;
}

export const SwitchLoadingPreferenceBanner: FC<SwitchLoadingPreferenceBannerProps> = ({
  bookingToSwitch,
  booking,
}) => {
  const firstBookingWithApprovedLoadingPreference =
    getBookingWithApprovedLoadingPreference(booking) ??
    getBookingWithApprovedLoadingPreference(bookingToSwitch);

  if (firstBookingWithApprovedLoadingPreference) {
    const text = resolveRemovedConfirmedApprovalText(firstBookingWithApprovedLoadingPreference);

    return <SwitchInformationBanner text={text} />;
  }

  return null;
};
