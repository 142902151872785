import styled from '@emotion/styled';
import { FC } from 'react';
import { zIndices } from '../common/zIndices';
import { Loading } from './Loading';

export interface PageLoadingProps {}

const PageLoadingOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: ${zIndices.loadingPageWrapper};
  isolation: isolate;
`;

const PageLoadingWrapper = styled.div`
  display: grid;
  place-items: center;
  position: absolute;
  inset: 0;
`;

export const PageLoading: FC<PageLoadingProps> = () => (
  <PageLoadingOverlay>
    <PageLoadingWrapper>
      <Loading />
    </PageLoadingWrapper>
  </PageLoadingOverlay>
);
