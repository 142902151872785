import { flatMap, uniq } from 'lodash';
import { call, fork, put, select } from 'redux-saga/effects';
import { createBookings, CreateBookingsResult } from '../../../common/api';
import { Sailing } from '../../../common/graphql/fragments/gql/Sailing';
import { TrackerCategory } from '../../../common/tracking/trackerService';
import { transformBookingCandidates } from '../../../use-cases/create/utils/bookingCandidateUtils';
import { CreateBookings_createBookings_results } from '../../../use-cases/multiple-create/graphql/gql/CreateBookings';
import { updateBookingsInGridSaga } from '../../grid/gridSaga';
import { hideCreatePanel } from '../../multi-create/multiCreateActions';
import {
  addBookingResultNotification,
  BookingResult,
  requestShowBookingResultToast,
} from '../../notifications/notificationsActions';
import { createCreatedAt } from '../../notifications/notificationsUtils';
import {
  CreateFormIdVariant,
  CreateRequestSubmitAction,
  setBookingsToKeepInCreateForm,
} from '../createFormActions';
import { CreateFormState } from '../createFormReducer';
import { finishSubmit, setCreateResult, startSubmit } from '../createMetaActions';
import { CreateValidationErrors, getCreateFormState } from '../createReducer';
import { initializeCreateFormThunk } from '../thunks/initializeCreateFormThunk';
import {
  getBookingsToKeepInForm,
  transformCreateBookingsResult,
} from '../utils/transformCreateBookingResults';
import { trackBookingCreateResult, trackCreateEvent } from './trackCreateResult';

const messageRequestFailed = 'The create request failed';

export function* showBookingResultNotification(bookingResult: BookingResult) {
  yield put(requestShowBookingResultToast(bookingResult));
  yield put(addBookingResultNotification(bookingResult, createCreatedAt()));
}

function validate(state: CreateFormState): CreateValidationErrors | null {
  const validationErrors: CreateValidationErrors = {};

  if (!state?.sailing) {
    validationErrors.sailing = 'Select a departure time';
  }

  if (!state?.departureDate) {
    validationErrors.departureDate = 'Select a departure date';
  }

  if (Object.keys(validationErrors).length === 0) {
    return null;
  }

  return validationErrors;
}

function* validateSubmitInput(
  formId: CreateFormIdVariant,
  formState: CreateFormState,
  trackerCategory: TrackerCategory,
) {
  const validationErrors = validate(formState);

  if (validationErrors != null) {
    trackCreateEvent(
      trackerCategory,
      'Booking failed (validation error)',
      formState.bookings.length,
    );

    yield showBookingResultNotification({
      type: 'create-fail',
      count: formState.bookings.length,
      sailing: formState.sailing,
      warnings: [],
      errors: ['There was a problem. Please review your information and try again.'],
    });

    yield put(setCreateResult(formId, {}, validationErrors));
    return false;
  }

  return true;
}

export function* handleRequestSubmit(action: CreateRequestSubmitAction) {
  const formState: CreateFormState = yield select(getCreateFormState(action.payload.formId));

  if (!formState) {
    return;
  }

  const trackerCategory: TrackerCategory =
    action.payload.formId === 'simple' ? 'SimpleCreate' : 'MultiCreate';

  const isValid = yield* validateSubmitInput(action.payload.formId, formState, trackerCategory);

  if (!isValid) {
    return;
  }

  yield put(startSubmit(action.payload.formId));
  try {
    const bookings = transformBookingCandidates(formState);
    const { errors, results }: CreateBookingsResult = yield call(createBookings, bookings);

    const sailing = formState.sailing;

    if (results) {
      trackBookingCreateResult(trackerCategory, results);

      yield call(showBookingResultToasts, results, sailing);
      yield put(setCreateResult(action.payload.formId, { result: results }, {}));

      const bookingsToKeepInForm = getBookingsToKeepInForm(results, formState);

      const allAreSuccess = bookingsToKeepInForm.length === 0;
      const anyAreSuccess = bookingsToKeepInForm.length !== bookings.length;

      if (anyAreSuccess) {
        yield fork(updateBookingsInGridSaga);
      }

      if (allAreSuccess) {
        yield put(hideCreatePanel());
        yield put(initializeCreateFormThunk(action.payload.formId));
      } else {
        yield put(setBookingsToKeepInCreateForm(action.payload.formId, bookingsToKeepInForm));
      }
    } else {
      trackCreateEvent(
        trackerCategory,
        'Booking failed (request failed)',
        formState.bookings.length,
      );
      yield showBookingResultNotification({
        type: 'create-fail',
        count: bookings.length,
        sailing,
        warnings: [],
        errors: ['No booking was added. Unfortunately an error occurred during saving.'],
      });

      if (errors) {
        yield put(setCreateResult(action.payload.formId, { genericError: errors[0] }, {}));
      } else {
        yield put(
          setCreateResult(action.payload.formId, { genericError: messageRequestFailed }, {}),
        );
      }
    }
  } catch (e) {
    trackCreateEvent(trackerCategory, 'Booking failed (request failed)', formState.bookings.length);

    yield showBookingResultNotification({
      type: 'create-fail',
      count: formState.bookings.length,
      sailing: formState.sailing,
      warnings: [],
      errors: [messageRequestFailed],
    });
    yield put(setCreateResult(action.payload.formId, { genericError: messageRequestFailed }, {}));
  } finally {
    yield put(finishSubmit(action.payload.formId));
  }
}

export function* showBookingResultToasts(
  results: CreateBookings_createBookings_results[],
  sailing: Sailing | null,
) {
  const { created, failed, waitlisted } = transformCreateBookingsResult(results);

  if (failed.length > 0) {
    yield call(showBookingResultNotification, {
      type: 'create-fail',
      count: failed.length,
      errors: uniq(flatMap(failed, b => b.errors)),
      warnings: uniq(flatMap(failed, b => b.warnings)),
      sailing,
    });
  }

  if (created.length > 0) {
    yield call(showBookingResultNotification, {
      type: 'confirmed',
      bookings: created.map(b => b.booking),
      messages: uniq(flatMap(created, b => b.messages)),
      warnings: uniq(flatMap(created, b => b.warnings)),
      sailing,
    });
  }

  if (waitlisted.length > 0) {
    yield call(showBookingResultNotification, {
      type: 'waitlist',
      bookings: waitlisted.map(b => b.booking),
      messages: uniq(flatMap(waitlisted, b => b.messages)),
      warnings: uniq(flatMap(waitlisted, b => b.warnings)),
      sailing,
    });
  }
}
