import { UrgentMessage } from '../../common/graphql/fragments/gql/UrgentMessage';
import { TrackerCategory } from '../../common/tracking/trackerService';

export type ModalActions =
  | ClearModalsAction
  | HideModalAction
  | ShowIntroductionAction
  | ShowCo2EmissionsAction
  | ShowCustomsLinkGdprAction
  | ShowUrgentMessageAction
  | ShowHotKeyOverviewAction;

interface ModalArgs {
  type: string;
  label: string;
  modalOptions?: {
    shouldCloseOnOverlayClick?: boolean;
  };
  args: Record<string, any>;
}

export interface UrgentMessageModalArgs extends ModalArgs {
  type: 'urgentMessage';
  label: string;
  args: {
    urgentMessage: UrgentMessage;
    trackerCategory: TrackerCategory;
    trackerAction: string;
  };
}

export interface CustomsLinkGdprModalArgs extends ModalArgs {
  type: 'customslink-gdpr';
  args: {
    bookingNo: number;
    trackerCategory: TrackerCategory;
  };
}

export interface IntroductionVideoModalArgs extends ModalArgs {
  type: 'introduction';
  label: string;
}

export interface Co2EmissionsModalArgs extends ModalArgs {
  type: 'co2emissions';
  label: string;
}

export interface HotKeyOverviewArgs extends ModalArgs {
  type: 'hotkeysOverview';
  label: string;
}

export type ModalDefinition =
  | UrgentMessageModalArgs
  | IntroductionVideoModalArgs
  | Co2EmissionsModalArgs
  | CustomsLinkGdprModalArgs
  | HotKeyOverviewArgs;

export interface ShowUrgentMessageAction {
  payload: UrgentMessageModalArgs;
  type: 'MODAL:PUSH_MODAL:URGENT_MESSAGE';
}

export const showUrgentMessage = (
  urgentMessage: UrgentMessage,
  trackerCategory: TrackerCategory,
  trackerAction: string,
): ShowUrgentMessageAction => ({
  payload: {
    args: {
      urgentMessage,
      trackerCategory,
      trackerAction,
    },
    label: '',
    type: 'urgentMessage',
  },
  type: 'MODAL:PUSH_MODAL:URGENT_MESSAGE',
});

export interface ShowIntroductionAction {
  payload: IntroductionVideoModalArgs;
  type: 'MODAL:PUSH_MODAL:INTRODUCTION';
}

export const showIntroduction = (): ShowIntroductionAction => ({
  payload: {
    args: {},
    label: '',
    modalOptions: {
      shouldCloseOnOverlayClick: false,
    },
    type: 'introduction',
  },
  type: 'MODAL:PUSH_MODAL:INTRODUCTION',
});

export interface ShowCo2EmissionsAction {
  payload: Co2EmissionsModalArgs;
  type: 'MODAL:PUSH_MODAL:CO2_EMISSIONS';
}

export const showCo2Emissions = (): ShowCo2EmissionsAction => ({
  payload: {
    args: {},
    label: '',
    modalOptions: {
      shouldCloseOnOverlayClick: false,
    },
    type: 'co2emissions',
  },
  type: 'MODAL:PUSH_MODAL:CO2_EMISSIONS',
});

export interface ShowCustomsLinkGdprAction {
  payload: CustomsLinkGdprModalArgs;
  type: 'MODAL:PUSH_MODAL:CUSTOMS_LINK_GDPR';
}

export const showCustomsLinkGdpr = (
  bookingNo: number,
  trackerCategory: TrackerCategory,
): ShowCustomsLinkGdprAction => ({
  payload: {
    label: '',
    args: { bookingNo, trackerCategory },
    type: 'customslink-gdpr',
  },
  type: 'MODAL:PUSH_MODAL:CUSTOMS_LINK_GDPR',
});

interface HideModalAction {
  payload: {};
  type: 'MODAL:POP_MODAL';
}

export const hideModal = (): HideModalAction => ({
  payload: {},
  type: 'MODAL:POP_MODAL',
});

interface ClearModalsAction {
  payload: {};
  type: 'MODAL:CLEAR_MODALS';
}

export const clearModals = (): ClearModalsAction => ({
  payload: {},
  type: 'MODAL:CLEAR_MODALS',
});

export interface ShowHotKeyOverviewAction {
  payload: HotKeyOverviewArgs;
  type: 'MODAL:PUSH_MODAL:HOTKEYS_OVERVIEW';
}

export const showHotkeysOverview = (): ShowHotKeyOverviewAction => ({
  payload: {
    args: {},
    label: 'Hotkeys Overview',
    type: 'hotkeysOverview',
  },
  type: 'MODAL:PUSH_MODAL:HOTKEYS_OVERVIEW',
});
