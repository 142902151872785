import { FilterDateDate, FilterState, FilterType, ListFilter } from './filterReducer';

export type FilterAction =
  | FilterAddIdAction
  | FilterAddIdsAction
  | FilterClearAction
  | FilterInitialFilterAppliedAction
  | FilterRemoveDateAction
  | FilterRemoveIdAction
  | FilterRemoveIdsAction
  | SetFilterSelectorViewOpenInStoreAction
  | FilterSelectorToggleViewStatusAction
  | FilterSelectorViewStatusAction
  | FilterSetAction
  | FilterSetDateAction
  | FilterSetSearchQueryFilterAction
  | FilterSetTimeAction;

export interface FilterClearAction {
  payload: {};
  type: 'FILTER_CLEAR';
}

export const clearFilter = (): FilterClearAction => ({
  payload: {},
  type: 'FILTER_CLEAR',
});

export interface FilterInitialFilterAppliedAction {
  payload: {};
  type: 'FILTER_INITIAL_FILTER_APPLIED';
}

export const initialFilterApplied = (): FilterInitialFilterAppliedAction => ({
  payload: {},
  type: 'FILTER_INITIAL_FILTER_APPLIED',
});

interface FilterSetAction {
  payload: {
    filter: FilterState;
  };
  type: 'FILTER_SET';
}

export const setFilter = (filter: FilterState): FilterSetAction => ({
  payload: {
    filter,
  },
  type: 'FILTER_SET',
});

interface FilterAddIdAction {
  payload: {
    id: string;
    filter: ListFilter;
  };
  type: 'FILTER_ADD_ID';
}

export const addId = (id: string, filter: ListFilter): FilterAddIdAction => ({
  payload: {
    filter,
    id,
  },
  type: 'FILTER_ADD_ID',
});

interface FilterAddIdsAction {
  payload: {
    ids: string[];
    filter: ListFilter;
  };
  type: 'FILTER_ADD_IDS';
}

export const addIds = (ids: string[], filter: ListFilter): FilterAddIdsAction => ({
  payload: {
    filter,
    ids,
  },
  type: 'FILTER_ADD_IDS',
});

interface FilterRemoveIdAction {
  payload: {
    id: string;
    filter: ListFilter;
  };
  type: 'FILTER_REMOVE_ID';
}

export const removeId = (id: string, filter: ListFilter): FilterRemoveIdAction => ({
  payload: {
    filter,
    id,
  },
  type: 'FILTER_REMOVE_ID',
});

interface FilterRemoveIdsAction {
  payload: {
    ids: string[];
    filter: ListFilter;
  };
  type: 'FILTER_REMOVE_IDS';
}

export const removeIds = (ids: string[], filter: ListFilter): FilterRemoveIdsAction => ({
  payload: {
    filter,
    ids,
  },
  type: 'FILTER_REMOVE_IDS',
});

interface FilterSetSearchQueryFilterAction {
  payload: {
    query: string;
  };
  type: 'FILTER_SET_SEARCH_QUERY';
}

export const setSearchQueryFilter = (query: string): FilterSetSearchQueryFilterAction => ({
  payload: {
    query,
  },
  type: 'FILTER_SET_SEARCH_QUERY',
});

interface FilterSetTimeAction {
  payload: {
    end?: string | null;
    start?: string | null;
  };
  type: 'FILTER_SET_TIME';
}

export const setTime = (end?: string | null, start?: string | null): FilterSetTimeAction => ({
  payload: {
    end,
    start,
  },
  type: 'FILTER_SET_TIME',
});

export interface FilterSetDateAction {
  payload: {
    from?: FilterDateDate;
    to?: FilterDateDate;
  };
  type: 'FILTER_SET_DATE';
}

export const setDate = (
  from: FilterDateDate | undefined,
  to: FilterDateDate | undefined,
): FilterSetDateAction => ({ payload: { from, to }, type: 'FILTER_SET_DATE' });

interface FilterRemoveDateAction {
  type: 'FILTER_REMOVE_DATE';
}

export const removeDate = (): FilterRemoveDateAction => ({
  type: 'FILTER_REMOVE_DATE',
});

interface FilterSelectorViewStatusAction {
  payload: {
    selectorViewStatus: FilterType;
  };
  type: 'FILTER_SET_SELECTOR_VIEW_STATUS';
}

export const setFilterSelectorViewStatus = (
  selectorViewStatus: FilterType,
): FilterSelectorViewStatusAction => ({
  payload: {
    selectorViewStatus,
  },
  type: 'FILTER_SET_SELECTOR_VIEW_STATUS',
});

interface FilterSelectorToggleViewStatusAction {
  payload: {
    selectorViewStatus: FilterType;
  };
  type: 'FILTER_TOGGLE_SELECTOR_VIEW_STATUS';
}

export const toggleFilterSelectorViewStatus = (
  selectorViewStatus: FilterType,
): FilterSelectorToggleViewStatusAction => ({
  payload: {
    selectorViewStatus,
  },
  type: 'FILTER_TOGGLE_SELECTOR_VIEW_STATUS',
});

export interface FilterSelectorViewOpenAction {
  payload: {
    selectorViewOpen: boolean;
  };
  type: 'FILTER:REQUEST_SET_SELECTOR_VIEW_OPEN';
}

export const setFilterSelectorViewOpenStatus = (
  selectorViewOpen: boolean,
): FilterSelectorViewOpenAction => ({
  payload: {
    selectorViewOpen,
  },
  type: 'FILTER:REQUEST_SET_SELECTOR_VIEW_OPEN',
});

interface SetFilterSelectorViewOpenInStoreAction {
  payload: {
    selectorViewOpen: boolean;
  };
  type: 'FILTER_SET_SELECTOR_VIEW_OPEN';
}

export const setFilterSelectorViewOpenStatusInStore = (
  selectorViewOpen: boolean,
): SetFilterSelectorViewOpenInStoreAction => ({
  payload: {
    selectorViewOpen,
  },
  type: 'FILTER_SET_SELECTOR_VIEW_OPEN',
});
