import { graphql } from '../../gql';

export const PageSettingsFragment = graphql(/*GraphQL*/ `
  fragment PageSetting on PageSettings {
    id
    tour {
      featuresSeen
      introductionTourSeen
    }
    grid {
      bookingsPerLoad
      displayDensity
    }
    column {
      enabled {
        enabled
        columnId
      }
      order
      width {
        width
        columnId
      }
    }
    sidebar {
      pinned
    }
  }
`);

export const updatePageSettingsMutation = graphql(/*GraphQL*/ `
  mutation UpdatePageSettingsMutation($settings: UpdatePageSettings2Input!) {
    updatePageSettings2(settings: $settings) {
      success
      settings {
        id
        ...PageSetting
      }
    }
  }
`);
