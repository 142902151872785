import styled from '@emotion/styled';
import { Text } from '@stenajs-webui/core';
import { FC } from 'react';
import { Colors } from '../../../../common/colors';
import { useLocalDateFormat } from '../../../../common/hooks/useLocalDateFormat';
import { formatDate } from '../../../../common/utils/dates/dateUtils';
import { theme } from '../../../../themes/theme';
import { cssColor } from '@stenajs-webui/theme';

export type Ids = 'by' | 'status' | 'time';

export interface Column<T> {
  id: T;
  label: string;
  format: (value: any) => string;
}

export interface HistoryRow {
  by: string;
  status: string;
  time: Date;
}

export interface HistoryTableProps {
  columns: Array<Column<Ids>>;
  rows: HistoryRow[];
}

export const BodyCell = styled('td')`
  color: ${Colors.UI2};
  padding: ${theme.metrics.spacing}px ${theme.metrics.indent}px;
`;

export const HeaderCell = styled('td')`
  color: ${Colors.UI3};
  padding: ${theme.metrics.spacing}px ${theme.metrics.indent}px;
`;

export const HistoryTable: FC<HistoryTableProps> = ({ columns, rows }) => {
  const dateFormat = useLocalDateFormat();

  return (
    <table>
      <thead>
        <tr>
          {columns.map(column => {
            return (
              <HeaderCell key={column.id}>
                <Text variant={'bold'} color={cssColor('--lhds-color-ui-900')}>
                  {column.label}
                </Text>
              </HeaderCell>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((historyItem, index) => {
          return (
            <tr key={index}>
              {columns.map(column => {
                return (
                  <BodyCell key={formatDate(historyItem.time, dateFormat) + column.id + index}>
                    <Text>{column.format(historyItem[column.id])}</Text>
                  </BodyCell>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
