import { BannerVariant } from '@stenajs-webui/elements';
import { uniq } from 'lodash';

export interface Message {
  description: string;
}

interface Result {
  errors: Message[];
  warnings: Message[];
  messages: Message[];
}

type MessageResultSeverity = 'error' | 'warning' | 'message';

export interface MessagesResult {
  severity: MessageResultSeverity;
  messages: string[];
}

export const getMessagesResult = ({
  errors,
  warnings,
  messages,
}: Result): MessagesResult | null => {
  if (errors.length > 0) {
    return {
      severity: 'error',
      messages: uniq(errors.map(m => m.description)),
    };
  } else if (warnings.length > 0) {
    return {
      severity: 'warning',
      messages: uniq(warnings.map(m => m.description)),
    };
  } else if (messages.length > 0) {
    return {
      severity: 'message',
      messages: uniq(messages.map(m => m.description)),
    };
  } else {
    return null;
  }
};

export const getBannerVariantForMessageResultSeverity = (
  severity: MessageResultSeverity,
): BannerVariant => {
  switch (severity) {
    case 'error':
      return 'error';
    case 'warning':
      return 'warning';
    case 'message':
      return 'info';
  }
};
