import { FC } from 'react';
import { TrackerCategory } from '../../../../common/tracking/trackerService';
import {
  CommonDateInputField,
  CommonDateInputFieldProps,
} from '../../../../common-ui/form-fields/CommonDateInputField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import { CreateIntermodalBookingFormModelDateKeys } from '../../../../services/intermodal/createIntermodalReducer';
import { useCreateIntermodalField } from '../../hooks/useCreateIntermodalField';

interface CreateIntermodalDateInputFieldProps
  extends Omit<CommonDateInputFieldProps, 'onValueChange' | 'trackerAction' | 'value'> {
  trackerCategory: TrackerCategory;
  name: CreateIntermodalBookingFormModelDateKeys;
}

export const CreateIntermodalDateInputField: FC<CreateIntermodalDateInputFieldProps> = ({
  name,
  ...props
}) => {
  const fieldProps = useCreateIntermodalField(name);

  return <CommonDateInputField {...props} {...getFieldProps(props, fieldProps)} />;
};
