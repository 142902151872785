export enum Texts {
  HazardousGoodsRequested = 'You must send the hazardous goods document(s) to your local booking office for approval prior to unit arriving at the port. Hazardous goods arriving at the ports without prior approval will be subject to an additional charge.',
  HazardousGoodsRequestedShort = 'Please send hazardous goods document(s) to your local Stena Line office prior to departure.',
  HazardousGoodsApproved = 'Hazardous goods have been approved. Documentation has been added to the booking.',
  LoadingPreferenceRequested = 'Your loading preference request will be handled by Stena Line.',
  LoadingPreferenceApproved = 'Your loading preference request has been approved',
  LoadingPreferenceRejected = 'Unfortunately your requested loading preference is not available on the selected departure',
  LoadingPreferenceNotApplicable = 'Unfortunately your requested loading preference is not available on the selected departure',
  CraneableRequested = 'Your craneable request will be handled by Stena Line.',
  CraneableApproved = 'Your craneable request has been approved',
  CraneableRejected = 'Unfortunately your craneable request is not available on the selected departure',
  CraneableNotApplicable = 'Unfortunately your craneable request is not available on the selected departure',
  AnimalsInformation = 'Special handling of this vehicle is required. Please advise details of the animal(s) to your local booking office as soon as possible.',
  Plugin = 'If two plugins are needed, use the format: +4 / -10 °C. Please note that your plugin request refers to the ship voyage only.',
  IntermodalInProgress = 'This booking request is waiting to be confirmed by Stena Line staff. Bookings are handled during office hours (Monday - Friday 07-18).',
  IntermodalUpdated = 'The updates on this booking are waiting to be confirmed by Stena Line staff. Bookings are handled during office hours (Monday - Friday 07-18).',
  IntermodalCreate = 'This booking request will be handled by Stena Line staff during office hours (Monday - Friday 07-18).',
}
