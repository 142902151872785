import { StoreState } from '../../store/storeState';
import { SidebarActions } from './sidebarActions';
import { RehydratePageSettingsAction } from '../page-settings/pageSettingsActions';

export interface SidebarReducerState {
  sidebarOpen: boolean;
  railEnabled: boolean;
}

const INITIAL_VALUE: SidebarReducerState = {
  railEnabled: false,
  sidebarOpen: false,
};

export const sidebarReducer = (
  state: SidebarReducerState = INITIAL_VALUE,
  action: SidebarActions | RehydratePageSettingsAction,
): SidebarReducerState => {
  switch (action.type) {
    case 'PAGE_SETTINGS:REHYDRATE':
      return {
        ...state,
        railEnabled: action.payload.railEnabled,
      };
    case 'SIDEBAR:OPEN':
      return {
        ...state,
        sidebarOpen: true,
      };
    case 'SIDEBAR:CLOSE':
      return {
        ...state,
        sidebarOpen: false,
      };
    case 'SIDEBAR:TOGGLE':
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };
    case 'SIDEBAR:ENABLE_RAIL':
      return {
        ...state,
        railEnabled: true,
      };
    case 'SIDEBAR:DISABLE_RAIL':
      return {
        ...state,
        railEnabled: false,
      };
    default:
      return state;
  }
};

export const getSidebarIsOpen = (state: StoreState) => state.sidebar.sidebarOpen;
export const getRailIsEnabled = (state: StoreState) => state.sidebar.railEnabled;
