import { Customer } from '../../../../common/graphql/fragments/gql/Customer';
import { Route } from '../../../../common/graphql/fragments/gql/Route';
import { Sailing } from '../../../../common/graphql/fragments/gql/Sailing';
import { VehicleType } from '../../../../common/graphql/fragments/gql/VehicleType';
import { resolveCustomerDropdownOptionLabel } from '../../../../common/utils/customerUtils';
import { capitalize } from 'lodash';

export interface DropdownOption<TData> {
  data: TData;
  label: string;
  value: string;
}

export const transformNumberOfDrivers = (c: number): DropdownOption<number> => ({
  data: c,
  label: String(c),
  value: String(c),
});

export const transformSailing = (s: Sailing): DropdownOption<Sailing> => ({
  data: s,
  label: s.departureTime,
  value: s.id,
});

export const transformRoute = (route: Route): DropdownOption<Route> => ({
  data: route,
  label: route.code,
  value: route.code,
});

export const transformRouteLongDescription = (route: Route): DropdownOption<Route> => ({
  data: route,
  label: `${route.longDescription} (${route.code})`,
  value: route.code,
});

export const formatVehicleLabel = (label: string): string => capitalize(label);

export const getVehicleTypeOptionValue = (vehicle: VehicleType): string => vehicle.code;

export const transformVehicleType = (vehicle: VehicleType): DropdownOption<VehicleType> => {
  return {
    data: vehicle,
    label: formatVehicleLabel(vehicle.description),
    value: getVehicleTypeOptionValue(vehicle),
  };
};

export const transformCustomer = (value: Customer): DropdownOption<Customer> => ({
  data: value,
  label: resolveCustomerDropdownOptionLabel(value),
  value: String(value.custNo),
});

export const transformYesNoOption = (value: boolean): DropdownOption<boolean> => ({
  data: value,
  label: value ? 'Yes' : 'No',
  value: String(value),
});

export interface Animal {
  label: string;
  value: string;
}

export const transformAnimalOption = (animal: Animal): DropdownOption<Animal> => ({
  data: animal,
  label: animal.label,
  value: animal.value,
});

export interface LoadingPreference {
  label: string;
  value: string;
}

export const transformLoadingPreferenceOption = (
  loadingPreference: LoadingPreference,
): DropdownOption<LoadingPreference> => ({
  data: loadingPreference,
  label: loadingPreference.label,
  value: loadingPreference.value,
});

export interface CustomsClassification {
  label: string;
  value: string;
}

export const transformCustomsClassificationOption = (
  customsClassification: CustomsClassification,
): DropdownOption<CustomsClassification> => ({
  data: customsClassification,
  label: customsClassification.label,
  value: customsClassification.value,
});

export interface SenderReceiverCountry {
  label: string;
  value: string;
}

export const transformSenderReceiverCountryOption = (
  country: SenderReceiverCountry,
): DropdownOption<SenderReceiverCountry> => ({
  data: country,
  label: country.label,
  value: country.value,
});
