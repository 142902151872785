import { Column, Row } from '@stenajs-webui/core';
import { Label } from '@stenajs-webui/elements';
import { ReactNode } from 'react';
import {
  DetailsFieldCell,
  DetailsFieldCellProps,
} from '../../manage/details/components/DetailsFieldCell';
import { useEditable } from '../../../common/modal/EditableContext';

export interface IntermodalDetailsFieldCellProps
  extends Omit<DetailsFieldCellProps, 'superficialLabel' | 'field'> {
  label: string;
  className?: string;
  customInfoLabel?: ReactNode;
  children?: ReactNode;
}

export const IntermodalDetailsFieldCell = ({
  children,
  customInfoLabel,
  label,
  ...props
}: IntermodalDetailsFieldCellProps) => {
  const editable = useEditable();
  if (customInfoLabel) {
    return (
      <Column gap={1}>
        <Row justifyContent={'space-between'}>
          <Label text={label} />
          {customInfoLabel}
        </Row>
        {children}
      </Column>
    );
  }

  return (
    <DetailsFieldCell {...props} superficialLabel={!editable} label={label} field={children} />
  );
};
