import { Txt } from '@stenajs-webui/core';
import { FC } from 'react';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import {
  getBookingSearchTableRowCellTextStyle,
  Searchable,
  SearchResultColumn,
} from '../utils/searchResultColumns';
import { HighlightedText } from './HighlightedText';

interface BookingSearchTableRowCellProps {
  booking: BasicBooking;
  column: SearchResultColumn;
  searchQuery: string;
  emptyPlaceholder: string;
}

export const BookingSearchTableRowCell: FC<BookingSearchTableRowCellProps> = ({
  booking,
  column,
  searchQuery,
  emptyPlaceholder,
}) => {
  const columnValue = column.getValue(booking).toString();
  const text = columnValue === '' ? emptyPlaceholder : columnValue;

  const style = getBookingSearchTableRowCellTextStyle(column);

  switch (column.searchable) {
    case Searchable.Yes:
      return <HighlightedText text={text} highlight={searchQuery} style={style} />;
    case Searchable.ExactMatch:
      return searchQuery === columnValue.toString() ? (
        <HighlightedText text={text} highlight={searchQuery} style={style} />
      ) : (
        <Txt style={style}>{text}</Txt>
      );
    case Searchable.No:
      return <Txt style={style}>{text}</Txt>;
    default:
      return <Txt style={style}>{text}</Txt>;
  }
};
