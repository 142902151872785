import { graphql } from '../../gql';

export const bookingHistoryQuery = graphql(/*GraphQL*/ `
  query BookingHistoryQuery($bookingNo: Int!) {
    viewer {
      bookings {
        byBookingNo(bookingNo: $bookingNo) {
          id
          history {
            versionNumber
            status
            dateModified
            modifiedBy
          }
        }
      }
    }
  }
`);
