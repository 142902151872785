import { isEqual, sortBy } from 'lodash';
import { SettingsFragment } from '../../../common/graphql/fragments/gql/SettingsFragment';
import { getDateFilterSettingForFilter } from '../../../common/utils/dates/dateIntervalUtils';
import { FilterState, INITIAL_VALUE } from '../filterReducer';

export const transformDefaultFiltersFromSettings = (
  accountSettings: SettingsFragment,
): FilterState => ({
  ...INITIAL_VALUE.filter,
  date: accountSettings.filterDateInterval
    ? getDateFilterSettingForFilter(accountSettings.filterDateInterval)
    : INITIAL_VALUE.filter.date,
  filterStates: accountSettings.filterFilterStates,
  routeCodeIds: accountSettings.filterRoutes.map(route => route.code),
});

const isArrayEqual = (a1: any[], a2: any[]) => isEqual(sortBy(a1), sortBy(a2));

export const isDateFilterEqual = (f1: FilterState, f2: FilterState) => isEqual(f1.date, f2.date);
export const isTimeFilterEqual = (f1: FilterState, f2: FilterState) => isEqual(f1.time, f2.time);

export const isRouteFilterEqual = (f1: FilterState, f2: FilterState) =>
  isArrayEqual(f1.routeCodeIds, f2.routeCodeIds);

export const isCargoFilterEqual = (f1: FilterState, f2: FilterState) =>
  isArrayEqual(f1.cargoIds, f2.cargoIds);

export const isAccountFilterEqual = (f1: FilterState, f2: FilterState) =>
  isArrayEqual(f1.customerIds, f2.customerIds);

export const isVehicleFilterEqual = (f1: FilterState, f2: FilterState) =>
  isArrayEqual(f1.vehicleIds, f2.vehicleIds);

export const isFilterStateEqual = (f1: FilterState, f2: FilterState): boolean =>
  isArrayEqual(f1.filterStates, f2.filterStates);
