import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import {
  setFrontendOutOfDateAction,
  stopPollingFrontendOutOfDateAction,
} from './frontendOutOfDateActions';
import { getFrontendOutOfDatePollingEnabled } from './frontendOutOfDateReducer';
import { fetchFrontendIsOutOfDate } from './frontendOutOfDateService';

export const TEN_MINUTES_IN_MS: number = 10 * 60 * 1000;

function* checkIsFrontendOutOfDate() {
  let pollingEnabled = true;
  const firstModified: string | null = yield call(fetchFrontendIsOutOfDate);

  while (pollingEnabled) {
    yield delay(TEN_MINUTES_IN_MS);

    const lastModified: string | null = yield call(fetchFrontendIsOutOfDate);

    if (firstModified !== lastModified) {
      yield put(setFrontendOutOfDateAction(true));
      yield put(stopPollingFrontendOutOfDateAction());
    }

    pollingEnabled = yield select(getFrontendOutOfDatePollingEnabled);
  }
}

export function* watchFrontendOutOfDate() {
  yield takeEvery('FRONTEND_OUT_OF_DATE:START_POLLING', checkIsFrontendOutOfDate);
}
