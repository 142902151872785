import { CSSProperties, FC, useEffect } from 'react';
import { ClientRectObject, FloatingOverlay } from '@floating-ui/react-dom-interactions';
import { Spotlight } from './Spotlight';

interface OverlayProps {
  overlayStyle?: CSSProperties;
  zIndex?: number;
  rect: ClientRectObject | undefined;
  onClick?: () => void;
}

export const Overlay: FC<OverlayProps> = ({ overlayStyle, rect, zIndex, onClick }) => {
  useEffect(() => {
    // Stops action button dropdowns to disappear when pressing mouse down
    function stopMouseDownEvents(e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();
    }

    window.addEventListener('mousedown', stopMouseDownEvents, { capture: true });

    return () => {
      window.removeEventListener('mousedown', stopMouseDownEvents, { capture: true });
    };
  }, []);

  return (
    <FloatingOverlay
      onClick={onClick}
      style={{
        mixBlendMode: 'hard-light',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        ...overlayStyle,
        zIndex,
      }}
    >
      <Spotlight rect={rect} />
    </FloatingOverlay>
  );
};
