import { Tag, TagProps, TagSize } from '@stenajs-webui/elements';
import { FC } from 'react';
import {
  BasicBooking_bookingQuayStatus,
  BasicBooking_bookingStatus,
} from '../common/graphql/fragments/gql/BasicBooking';
import { IntermodalBooking_status } from '../common/graphql/fragments/gql/IntermodalBooking';
import {
  CommonBookingTagStatus,
  mapBookingStatus,
  mapFilterStatus,
  mapIntermodalStatus,
  resolveFullStatusText,
  resolveTagAppearanceFromCommonBookingStatus,
} from '../common/utils/bookingStatus';
import { BookingFilterState } from '../gql/graphql';
import { useGridTagSize } from '../services/grid/hooks/useGridHeight';

export type BookingTagStatus = Pick<BasicBooking_bookingStatus, 'status' | 'label'>;
export type BookingTagQuayStatus = Pick<BasicBooking_bookingQuayStatus, 'status' | 'label'> | null;

interface BookingStatusTagProps {
  status: BookingTagStatus;
  quayStatus: BookingTagQuayStatus;
  size?: TagSize;
}

interface CommonBookingStatusTagProps extends Pick<TagProps, 'size' | 'label'> {
  status: CommonBookingTagStatus;
}

interface FilterStatusTagProps extends Pick<TagProps, 'size' | 'label'> {
  status: BookingFilterState;
}

export const CommonBookingStatusTag: FC<CommonBookingStatusTagProps> = ({ status, ...props }) => {
  const { icon, backgroundColor } = resolveTagAppearanceFromCommonBookingStatus(status);

  return <Tag style={{ backgroundColor }} icon={icon} {...props} />;
};

export const FilterStatusTag: FC<FilterStatusTagProps> = ({ status, ...props }) => {
  const { icon, backgroundColor } = resolveTagAppearanceFromCommonBookingStatus(
    mapFilterStatus(status),
  );

  return <Tag style={{ backgroundColor }} icon={icon} {...props} />;
};

export const BookingStatusTag: FC<BookingStatusTagProps> = ({ status, quayStatus, size }) => {
  return (
    <CommonBookingStatusTag
      status={mapBookingStatus(status.status, quayStatus?.status)}
      size={size}
      label={resolveFullStatusText(status, quayStatus)}
    />
  );
};

export const GridBookingStatusTag: FC<Omit<BookingStatusTagProps, 'size'>> = props => {
  return <BookingStatusTag {...props} size={useGridTagSize()} />;
};

interface IntermodalBookingStatusTagProps {
  status: IntermodalBooking_status;
  size?: TagSize;
}

export const IntermodalBookingStatusTag: FC<IntermodalBookingStatusTagProps> = ({
  status,
  size,
}) => {
  return (
    <CommonBookingStatusTag
      status={mapIntermodalStatus(status.status)}
      size={size}
      label={status.label}
    />
  );
};

export const GridIntermodalBookingStatusTag: FC<
  Omit<IntermodalBookingStatusTagProps, 'size'>
> = props => {
  const size = useGridTagSize();

  return <IntermodalBookingStatusTag {...props} size={size} />;
};
