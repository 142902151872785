import { graphql } from '../../gql';

export const createBookingClaimTokenMutation = graphql(/* GraphQL */ `
  mutation CreateBookingClaimToken($bookingNo: Int!) {
    createBookingClaimToken(bookingNo: $bookingNo) {
      success
      token
      service
      params
    }
  }
`);
