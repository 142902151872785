import { VehicleType } from '../graphql/fragments/gql/VehicleType';
import { exhaustSwitchCaseElseThrow } from '@stenajs-webui/core';

export function getVehicleTypeDimension(
  vehicleType: VehicleType,
  dimensionName: 'height' | 'length',
) {
  switch (dimensionName) {
    case 'height':
      return vehicleType.defaultHeight;
    case 'length':
      return vehicleType.defaultLength;
    default:
      return exhaustSwitchCaseElseThrow(dimensionName);
  }
}
