import { first } from 'lodash';
import { useCallback } from 'react';
import { StoreState } from '../../../store/storeState';
import { useShallowEqualSelector } from '../../../common/hooks/useShallowEqualSelector';
import { useDispatch } from 'react-redux';
import {
  TrackerAction,
  transformCreateIntermodalFieldToTrackerAction,
} from '../../simple-create/hooks/UseTrackingTextInput';
import {
  CreateIntermodalBookingFormModel,
  getCreateIntermodalBookingFormState,
  getCreateIntermodalBookingIsSubmitting,
  getCreateIntermodalBookingValidationErrors,
  setCreateFormValue,
} from '../../../services/intermodal/createIntermodalReducer';

export interface UseCreateIntermodalFieldProps<Key extends keyof CreateIntermodalBookingFormModel> {
  editedValue: CreateIntermodalBookingFormModel[Key] | undefined;
  onChange: (value: CreateIntermodalBookingFormModel[Key]) => void;
  validationError?: string;
  journeyValidationError?: string;
  disabled: boolean;
  trackerAction: TrackerAction;
}

export const useCreateIntermodalField = <Key extends keyof CreateIntermodalBookingFormModel>(
  name: Key,
): UseCreateIntermodalFieldProps<Key> => {
  const mapState = useCallback(
    (state: StoreState) => {
      const formState = getCreateIntermodalBookingFormState(state);
      const validationErrors = getCreateIntermodalBookingValidationErrors(state, name);
      const disabled = getCreateIntermodalBookingIsSubmitting(state);
      return {
        value: formState[name],
        validationError: first(validationErrors),
        disabled,
      };
    },
    [name],
  );

  const { value, validationError, disabled } = useShallowEqualSelector(mapState);

  const trackerAction = transformCreateIntermodalFieldToTrackerAction(name);

  const dispatch = useDispatch();
  const onChange = useCallback<(value: CreateIntermodalBookingFormModel[Key]) => void>(
    newValue => {
      if (value !== newValue) {
        dispatch(setCreateFormValue({ [name]: newValue }));
      }
    },
    [dispatch, name, value],
  );

  return {
    editedValue: value,
    onChange,
    validationError,
    disabled,
    trackerAction,
  };
};
