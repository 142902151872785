import { graphql } from '../../../../gql';

export const updateBookingMutation = graphql(/* GraphQL */ `
  mutation UpdateBooking($booking: UpdateBookingInput!, $detailed: Boolean!) {
    updateBooking(booking: $booking) {
      booking {
        ...BasicBooking @skip(if: $detailed)
        ...DetailedBooking @include(if: $detailed)
      }
      bookingOutOfDate
      errors {
        description
        code
      }
      messages {
        description
        code
      }
      success
      waitlistPossible
      warnings {
        description
        code
      }
    }
  }
`);
