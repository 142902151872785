import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { transformToUpdateBookingFormState } from '../../../../common/update-information/utils/transformToUpdateBookingFormState';
import { graphql } from '../../../../gql';
import { initializeUpdateForm } from '../../../../services/update-form/updateFormActions';

const detailedBookingQuery = graphql(/* GraphQL */ `
  query DetailedBookingQuery($bookingNo: Int!) {
    viewer {
      bookings {
        booking: byBookingNo(bookingNo: $bookingNo) {
          ...DetailedBooking
        }
      }
    }
  }
`);

export function useBookingLoader(bookingNo: number) {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(detailedBookingQuery, {
    fetchPolicy: 'network-only',
    variables: { bookingNo },
    onCompleted: data => {
      const booking = data.viewer.bookings.booking;

      if (booking) {
        const formState = transformToUpdateBookingFormState(booking);
        dispatch(
          initializeUpdateForm(
            { bookingNo: booking.bookingNo, formId: 'Details' },
            formState,
            booking,
          ),
        );
      }
    },
  });

  return { loading, error: error?.graphQLErrors?.[0], booking: data?.viewer.bookings.booking };
}
