import { addDays, areIntervalsOverlapping, isWithinInterval, parseISO, subDays } from 'date-fns';
import { uniqWith } from 'lodash';
import { FilterDate } from '../../../gql/graphql';
import { BookingsFilterQueryResult } from '../../../services/grid/gridReducer';
import { UpdateFormReducerState } from '../../../services/update-form/updateFormReducer';
import { BasicBooking } from '../../graphql/fragments/gql/BasicBooking';
import { UrgentMessage } from '../../graphql/fragments/gql/UrgentMessage';
import { formatServerDate, getDateFromDateAndTime } from '../../utils/dates/dateUtils';
import { isDefined } from '../../utils/isDefined';

export const getUrgentMessageInitialDate = (
  noDaysBack: number = 2,
  noDaysForward: number = 100,
  date: Date = new Date(),
): FilterDate => ({
  from: formatServerDate(subDays(date, noDaysBack)),
  to: formatServerDate(addDays(date, noDaysForward)),
});

export const urgentMessageCheckIfIsActive = (
  message: UrgentMessage,
  date: Date,
  routeCode: string,
): boolean =>
  message.routes.some(r => r.code === routeCode) &&
  isWithinInterval(date, {
    start: parseISO(message.validFrom),
    end: parseISO(message.validTo),
  });

export const urgentMessageCheckIfOverlappingInterval = (
  message: UrgentMessage,
  dateFrom: Date,
  dateTo: Date,
  routeCode: string,
): boolean =>
  message.routes.some(r => r.code === routeCode) &&
  areIntervalsOverlapping(
    { start: dateFrom, end: dateTo },
    {
      start: parseISO(message.validFrom),
      end: parseISO(message.validTo),
    },
  );

export const getBookingsFromGrid = (
  gridResult: BookingsFilterQueryResult | null,
  updateForm: UpdateFormReducerState,
): BasicBooking[] => {
  return (
    gridResult?.result?.bookingNos
      ?.map(bookingNo => updateForm[bookingNo]?.Grid?.booking ?? undefined)
      .filter(isDefined) ?? []
  );
};

export interface DateAndRouteCode {
  date: Date;
  routeCode: string;
}

export const getUniqueRoutesAndDepartureDates = (bookings: BasicBooking[]): DateAndRouteCode[] => {
  return uniqWith(
    bookings,
    (b1, b2) =>
      b1.sailing.departureDate === b2.sailing.departureDate &&
      b1.sailing.departureTime === b2.sailing.departureTime &&
      b1.sailing.route.code === b2.sailing.route.code,
  ).map(booking => {
    const date = getDateFromDateAndTime(
      booking.sailing.departureDate,
      booking.sailing.departureTime,
    );
    return {
      routeCode: booking.sailing.route.code,
      date,
    };
  });
};

export const getMessagesToShow = (
  messages: UrgentMessage[],
  dateAndRouteCodes: DateAndRouteCode[],
): UrgentMessage[] => {
  return messages.filter(message =>
    dateAndRouteCodes.some(({ routeCode, date }) =>
      urgentMessageCheckIfIsActive(message, date, routeCode),
    ),
  );
};

export const resolveUrgentMessagesForGrid = (
  gridResult: BookingsFilterQueryResult | null,
  updateForm: UpdateFormReducerState,
  urgentMessages: UrgentMessage[],
): UrgentMessage[] => {
  const bookingsFromGrid = getBookingsFromGrid(gridResult, updateForm);
  const uniqueRoutesAndDepartureDates = getUniqueRoutesAndDepartureDates(bookingsFromGrid);
  return getMessagesToShow(urgentMessages, uniqueRoutesAndDepartureDates);
};
