import { delay, put } from 'redux-saga/effects';
import { updateIntermodalBookingThunk } from '../thunks/updateIntermodalBookingThunk';
import { isAnyOf } from '@reduxjs/toolkit';
import { createIntermodalBookingThunk } from '../thunks/createIntermodalBookingThunk';
import { clear } from '../IntermodalGridStatusReducer';
import { takeLatestUniqBy } from '../../../common/saga/takeLatestUniqBy';

type CleanIntermodalGridStatusAction =
  | ReturnType<typeof createIntermodalBookingThunk.fulfilled>
  | ReturnType<typeof updateIntermodalBookingThunk.fulfilled>;

export function* watchIntermodalGridStatusSaga() {
  yield takeLatestUniqBy(
    (action: CleanIntermodalGridStatusAction) => String(action.payload.booking.bookingNo),
    isAnyOf(createIntermodalBookingThunk.fulfilled, updateIntermodalBookingThunk.fulfilled),
    cleanIntermodalGridStatusSaga,
  );
}

function* cleanIntermodalGridStatusSaga(action: CleanIntermodalGridStatusAction) {
  yield delay(60_000);
  yield put(clear(action.payload.booking.bookingNo));
}
