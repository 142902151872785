import { ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  RegistrationNumberAlertFormState,
  TravelledVehicleType,
} from '../../../services/update-form/registrationNumberAlert';
import {
  FormInstanceId,
  getRegistrationNumberAlert,
} from '../../../services/update-form/updateFormReducer';
import { StoreState } from '../../../store/storeState';
import { useBookingFormInstanceId } from '../../update-information/BookingFormInstanceIdProvider';
import { useAppDispatch } from '../../../store/appDispatch';
import { TrackerCategory, trackEvent } from '../../tracking/trackerService';
import {
  revertValues,
  updateRegistrationNumberAlert,
} from '../../../services/update-form/updateFormActions';
import { renderRegistrationNumberAlertIcon } from '../../../common-ui/information-message/renderRegistrationNumberAlertIcon';
import { Placement } from '../../../common-ui/popover/FreightPortalPopover';
import { TextInputButtonSize } from '@stenajs-webui/elements';
import {
  UseUpdateFormState,
  useUpdateFormState,
} from '../../update-information/hooks/useUpdateFormState';
import { setVehicleTypeFromTravelledVehicle } from '../../../services/update-form/travelledVehicleActions';
import { getValuesForUpdateForm } from '../../update-information/utils/arrivalNotepadUtils';
import { getRegistrationNumberAlertProps } from '../utils/registrationNumberAlertUtil';
import { Dispatch } from 'redux';

export interface UseRegistrationNumberAlert {
  icon: ReactNode | null;
}

function getUpdateRegistrationNumberAlert(
  trackerCategory: TrackerCategory,
  trackerLabel: 'TrailerReg' | 'VehicleReg',
  dispatch: Dispatch,
  formInstanceId: FormInstanceId,
  registrationNumberAlert: RegistrationNumberAlertFormState | undefined,
  travelledVehicleType: TravelledVehicleType,
  formState: UseUpdateFormState,
  iconOptions: { placement?: Placement; buttonSize?: TextInputButtonSize } | undefined,
) {
  const onSkip = () => {
    trackEvent({
      category: trackerCategory,
      action: 'TravelledVehicleTrailer - Keep VehicleType',
      label: trackerLabel,
    });
    dispatch(
      updateRegistrationNumberAlert(formInstanceId, {
        appliedTravelledVehicle: true,
      }),
    );
  };

  const onDisableArrivalNotepad = () => {
    if (registrationNumberAlert) {
      dispatch(revertValues(formInstanceId, getValuesForUpdateForm(registrationNumberAlert)));
      trackEvent({
        category: trackerCategory,
        action: 'ArrivalNotepad - Revert changes',
        label: trackerLabel,
      });
      dispatch(
        updateRegistrationNumberAlert(formInstanceId, {
          appliedArrivalNotepad: false,
        }),
      );
    }
  };

  const onApply = () => {
    trackEvent({
      category: trackerCategory,
      action: 'TravelledVehicleTrailer - Update VehicleType',
      label: trackerLabel,
    });
    dispatch(setVehicleTypeFromTravelledVehicle(formInstanceId));
    dispatch(
      updateRegistrationNumberAlert(formInstanceId, {
        appliedTravelledVehicle: true,
      }),
    );
  };

  const onIconClick = (type: string) => {
    trackEvent({
      category: trackerCategory,
      action: `${type} alert icon clicked`,
      label: 'conflict',
    });
  };

  const { hasAppliedTravelledVehicle, hasTravelledVehicle, hasArrivalNotepad } =
    getRegistrationNumberAlertProps(
      registrationNumberAlert,
      travelledVehicleType,
      formState.booking?.vehicleType,
    );

  return {
    icon: renderRegistrationNumberAlertIcon({
      travelledVehicleType,
      onIconClick,
      onSkip,
      onApply,
      onDisableArrivalNotepad,
      registrationNumberAlert,
      locationName: formState.booking?.sailing?.route.departureLocation.name ?? null,
      hasTravelledVehicle,
      hasAppliedTravelledVehicle,
      hasArrivalNotepad,
      ...iconOptions,
    }),
  };
}

export const useUpdateRegistrationNumberAlert = (
  trackerCategory: TrackerCategory,
  trackerLabel: 'TrailerReg' | 'VehicleReg',
  travelledVehicleType: TravelledVehicleType,
  iconOptions?: { placement?: Placement; buttonSize?: TextInputButtonSize } | undefined,
): UseRegistrationNumberAlert => {
  const dispatch = useAppDispatch();
  const formInstanceId = useBookingFormInstanceId();
  const formState = useUpdateFormState(formInstanceId);
  const registrationNumberAlert = useSelector(
    useCallback(
      (state: StoreState) => getRegistrationNumberAlert(state, formInstanceId),
      [formInstanceId],
    ),
  );

  return getUpdateRegistrationNumberAlert(
    trackerCategory,
    trackerLabel,
    dispatch,
    formInstanceId,
    registrationNumberAlert,
    travelledVehicleType,
    formState,
    iconOptions,
  );
};
