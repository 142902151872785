import { CreateResult } from './createFormActions';
import { CreateMetaActions } from './createMetaActions';
import { CreateValidationErrors } from './createReducer';
import { CreateFormState } from './createFormReducer';

export interface CreateMetaState {
  initialized: boolean;
  result: CreateResult | null;
  submitting: boolean;
  extendedMode: boolean;
  validationErrors: CreateValidationErrors;
}

export const INITIAL_VALUE: CreateMetaState = {
  initialized: false,
  result: null,
  submitting: false,
  extendedMode: false,
  validationErrors: {},
};

export const createMetaReducer = (
  state: CreateMetaState = INITIAL_VALUE,
  action: CreateMetaActions,
): CreateMetaState => {
  switch (action.type) {
    case 'CREATE_FORM:INITIALIZE_CREATE_FORM':
      return {
        ...INITIAL_VALUE,
        initialized: true,
      };
    case 'CREATE_FORM:REQUEST_CLEANUP_FORM':
      return INITIAL_VALUE;
    case 'CREATE_FORM:SUBMIT_START':
      return {
        ...state,
        submitting: true,
      };
    case 'CREATE_FORM:SET_CREATE_RESULT':
      return {
        ...state,
        result: action.payload.result,
        validationErrors: action.payload.validationErrors,
      };
    case 'CREATE_FORM:UPDATE_COMMON_VALUES':
      const validationErrors: CreateValidationErrors = { ...state.validationErrors };

      for (const value in action.payload.values) {
        delete validationErrors[value as keyof CreateFormState];
      }

      return {
        ...state,
        validationErrors,
      };
    case 'CREATE_FORM:SUBMIT_FINISHED':
      return {
        ...state,
        submitting: false,
      };
    case 'CREATE_FORM:ENABLE_EXTENDED_MODE':
      return {
        ...state,
        extendedMode: true,
      };

    default:
      return state;
  }
};
