import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { clearActiveRow, setActiveRow } from '../../../../services/grid/gridActions';

export const useActiveRow = ({ bookingNo }: { bookingNo: number }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveRow(bookingNo));
    return () => {
      dispatch(clearActiveRow());
    };
  }, [dispatch, bookingNo]);
};
