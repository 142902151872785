type ArrayFn<T, U> = (v: T, i: number, list: T[]) => U;

export const filterMap = <T, U>(array: T[], mapper: ArrayFn<T, U | undefined | null>): U[] => {
  return array.reduce<U[]>((p, c, i, a) => {
    let value = mapper(c, i, a);
    if (value != null) {
      p.push(value);
    }
    return p;
  }, []);
};
