import { fromPairs } from 'lodash';
import { AccountQuery_viewer_account_byEmail_pageSettings } from '../../../../use-cases/settings/graphql/gql/AccountQuery';
import { BookingsPerLoad } from '../../../grid/gridReducer';
import { PageSettingsRehydratePayload } from '../../pageSettingsActions';
import { transformToCompactMode } from './transformDisplayDensity';

export const transformToPageSettingsRehydratePayload = ({
  grid,
  column,
  sidebar,
}: AccountQuery_viewer_account_byEmail_pageSettings): PageSettingsRehydratePayload => ({
  bookingsPerLoad: grid.bookingsPerLoad as BookingsPerLoad,
  compactMode: transformToCompactMode(grid.displayDensity),
  columnsEnabled: fromPairs(column.enabled.map(({ enabled, columnId }) => [columnId, enabled])),
  columnsWidth: fromPairs(column.width.map(({ width, columnId }) => [columnId, width])),
  columnsOrder: column.order,
  railEnabled: sidebar.pinned,
});
