import { ActionMenuLink, stenaMail } from '@stenajs-webui/elements';
import { FC } from 'react';
import { useLocalDateFormat } from '../../../../../common/hooks/useLocalDateFormat';
import { trackEvent } from '../../../../../common/tracking/trackerService';
import { CommonBookingActionMenuItemProps } from './types';
import { getMailUrl } from './util/options-table-cell-utils';

export const MailBookingActionMenuItem: FC<CommonBookingActionMenuItemProps> = ({
  disabled,
  booking,
  trackerAction,
  onClick,
  trackerCategory,
}) => {
  const dateFormat = useLocalDateFormat();

  const onClickActionMenuItem = () => {
    onClick?.();
    trackEvent({ category: trackerCategory, action: trackerAction });
  };

  return (
    <ActionMenuLink
      label={'Send as mail'}
      href={getMailUrl(booking, dateFormat)}
      leftIcon={stenaMail}
      onClick={onClickActionMenuItem}
      disabled={disabled}
    />
  );
};
