import { ColumnId, SortDirection } from '../../gql/graphql';

export type SortingActions = SortBookingsAction | ToggleSortBookingsAction;

export interface SortBookingsAction {
  payload: {
    direction: SortDirection;
    property: ColumnId;
  };
  type: 'SORTING:SORT_BOOKINGS';
}

export const sortBookings = (property: ColumnId, direction: SortDirection): SortBookingsAction => ({
  payload: {
    direction,
    property,
  },
  type: 'SORTING:SORT_BOOKINGS',
});

export interface ToggleSortBookingsAction {
  payload: {
    property: ColumnId;
  };
  type: 'SORTING:TOGGLE_SORT_BOOKINGS';
}

export const toggleSortBookings = (property: ColumnId): ToggleSortBookingsAction => ({
  payload: {
    property,
  },
  type: 'SORTING:TOGGLE_SORT_BOOKINGS',
});
