import { DropdownOption, transformYesNoOption } from '../../../grid/utils/optionTransformers';
import { createRangeOptions } from '../../utils/createRangeOptions';
import { padStart, range } from 'lodash';
import { transformNoOfPluginsOption } from '../../../../intermodal/utils/detailsUtils';

export const personsRange = createRangeOptions(0, 9);

export const pluginsRange: Array<DropdownOption<number>> = range(0, 3).map(
  transformNoOfPluginsOption,
);

export const yesNoOptions: Array<DropdownOption<boolean>> = [
  transformYesNoOption(false),
  transformYesNoOption(true),
];

export const timeOptions: Array<DropdownOption<string>> = range(24).flatMap(h => {
  return [
    createHourOption(padStart(String(h), 2, '0') + ':00'),
    createHourOption(padStart(String(h), 2, '0') + ':30'),
  ];
});

function createHourOption(time: string): DropdownOption<string> {
  return { data: time, value: time, label: time };
}
