import { delay, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  FilterSelectorViewOpenAction,
  setFilterSelectorViewOpenStatusInStore,
} from './filterActions';
import { getFilter } from './filterReducer';
import { getFilterDate } from '../../common/utils/dates/dateFilter';
import { trackEvent } from '../../common/tracking/trackerService';
import {
  formatDateDifference,
  getCalenderDateDifference,
} from '../../common/utils/dates/dateUtils';

export function* requestSetFilterOpenSaga(action: FilterSelectorViewOpenAction) {
  yield delay(100);
  yield put(setFilterSelectorViewOpenStatusInStore(action.payload.selectorViewOpen));
}

export function* watchRequestSetFilterOpen() {
  yield takeLatest('FILTER:REQUEST_SET_SELECTOR_VIEW_OPEN', requestSetFilterOpenSaga);
}

export function* watchFilterDateSet() {
  yield takeLatest('FILTER_SET_DATE', trackFilterSaga);
}

export function* trackFilterSaga() {
  yield take('GRID:SET_QUERY_RESULT');
  const filter: ReturnType<typeof getFilter> = yield select(getFilter);

  const startDate = filter.date.from ? getFilterDate(filter.date.from) : undefined;
  const endDate = filter.date.to ? getFilterDate(filter.date.to) : undefined;

  trackEvent({
    category: 'Filter',
    action: 'Set date',
    label: formatDateDifference(getCalenderDateDifference({ startDate, endDate }, new Date())),
  });
}
