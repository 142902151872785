import {
  array,
  boolean,
  coerce,
  custom,
  fallback,
  flatten,
  Issues,
  minLength,
  nullish,
  number,
  object,
  optional,
  record,
  string,
} from 'valibot';

const positive = (error: string) => custom<number>(v => v > 0, error);

export const numberStringPositive = (error: string) => coerce(number([positive(error)]), Number);

export const numberStringPositiveWithFallback = (error: string, fallbackValue: number) =>
  fallback(numberStringPositive(error), fallbackValue);

export const IntermodalGoodsFormModelSchema = object({
  goodsType: object({ id: string(), description: string() }, 'Goods type is required'),
  weight: numberStringPositive('Goods weight is required'),
  id: string(),
  created: optional(boolean()),
});

const commonFields = {
  route: object({ id: string() }, 'Route is required'),
  customer: object({ custNo: number() }, 'Account is required'),
  departureDate: string([minLength(1, 'Date is required')]),
  departureTime: string([minLength(1, 'Time is required')]),
  vehicleType: object({ code: string() }, 'Vehicle type is required'),
  noOfPlugins: number(),
  length: numberStringPositive('Length is required'),
  width: numberStringPositive('Width is required'),
  height: numberStringPositive('Height is required'),
  emptyWeight: numberStringPositive('Empty weight is required'),
};

const updateAndCreateFields = {
  ...commonFields,
  vehicleReg: string(),
  iluCode: string(),
  craneable: boolean(),
  hazardousGoods: boolean(),
  noOfPlugins: number(),
  temperature: string(),
  customerReference: string(),
  loadingNote: string(),
};

const IntermodalLegFormModelSchema = object({
  legNumber: number(),
  id: string(),
});

export const CreateIntermodalFormModelSchema = object({
  ...updateAndCreateFields,
  legs: nullish(array(IntermodalLegFormModelSchema)),
  searchByDeparture: boolean(),
  bookingGoods: record(IntermodalGoodsFormModelSchema),
});

export const UpdateIntermodalFormModelSchema = object({
  ...updateAndCreateFields,
  bookingNo: number(),
  bookingGoods: record(IntermodalGoodsFormModelSchema),
});

export const IntermodalJourneyFormModelSchema = object({
  ...commonFields,
  searchByDeparture: boolean(),
  bookingGoods: record(
    object({ weight: numberStringPositiveWithFallback('Goods weight is required', 0) }),
  ),
});

export const collectIssues = (issues: Issues): Record<string, string[] | undefined> =>
  flatten(issues).nested;
