import { ReactElement } from 'react';
import { components, OptionProps, SingleValueProps } from 'react-select';
import { DropdownOption } from '../../use-cases/manage/grid/utils/optionTransformers';
import { VehicleTypeRowWithIcon } from './VehicleTypeRowWithIcon';

export const VehicleTypeDropdownOption = <TValue extends DropdownOption<unknown>>({
  children,
  ...optionProps
}: OptionProps<TValue, false>): ReactElement<OptionProps<TValue, false>> => (
  <components.Option {...optionProps}>
    <VehicleTypeRowWithIcon vehicleTypeCode={optionProps.data.value} children={children} />
  </components.Option>
);

export const VehicleTypeDropdownSingleValue = <TValue extends DropdownOption<unknown>>({
  children,
  ...optionProps
}: SingleValueProps<TValue, false, any>): ReactElement<SingleValueProps<TValue>> => (
  <components.SingleValue {...optionProps}>
    <VehicleTypeRowWithIcon vehicleTypeCode={optionProps.data.value} children={children} />
  </components.SingleValue>
);
