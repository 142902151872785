import { put, select } from 'redux-saga/effects';
import { UpdatePageSettings2Input } from '../../../gql/graphql';
import { requestSavePageSettings } from '../pageSettingsActions';
import { getRailIsEnabled } from '../../sidebar/sidebarReducer';

export function* updateSidebarPageSettings() {
  const pinned: ReturnType<typeof getRailIsEnabled> = yield select(getRailIsEnabled);

  const input: UpdatePageSettings2Input = {
    sidebar: {
      pinned,
    },
  };

  yield put(requestSavePageSettings(input));
}
