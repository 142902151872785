import { Column, Row, Txt } from '@stenajs-webui/core';
import { Banner, Cardy, Label, PrimaryButton, stenaSendPlane } from '@stenajs-webui/elements';
import { TextInput } from '@stenajs-webui/forms';
import { cssColor } from '@stenajs-webui/theme';
import { FC, useCallback, useRef, useState } from 'react';
import { components, OnChangeValue } from 'react-select';
import { SingleValueProps } from 'react-select/dist/declarations/src/components/SingleValue';
import { DropdownShare } from '../../../../common-ui/form-fields/Dropdown';
import { PopoverBody } from '../../../../common-ui/popover/PopoverBody';
import { PopoverTitle } from '../../../../common-ui/popover/PopoverTitle';
import { preventDefault } from '../../../../common/eventHelpers';
import { useShareBooking } from '../../../../common/graphql/useShareBooking';
import { usePersistedStorageState } from '../../../../common/header-banners/usePersistedStorageState';
import { TrackerCategory } from '../../../../common/tracking/trackerService';
import {
  Country,
  COUNTRY_LIST,
  COUNTRY_UNITED_KINGDOM,
  formatAbbreviatedCountryString,
  formatFullCountryString,
} from '../../../../common/utils/countryUtil';
import { DropdownOption } from '../../grid/utils/optionTransformers';
import {
  cleanPhoneNumberString,
  evaluatePhoneNumberString,
  formatPhoneNumber,
} from '../util/shareUtil';
import { CopyBookingLinkButton } from './CopyBookingLinkButton';

export interface ShareBookingPanelProps {
  bookingNo: number;
  vehicleRegNo: string;
  onClose: () => void;
  trackerCategory: TrackerCategory;
  showCopyBookingLink?: boolean;
}

const transformCountryToDropdownOption = (o: Country): DropdownOption<Country> => ({
  data: o,
  label: formatFullCountryString(o),
  value: o.locale,
});

const optionList: DropdownOption<Country>[] = COUNTRY_LIST.map(c =>
  transformCountryToDropdownOption(c),
);

export const ShareBookingPanel: FC<ShareBookingPanelProps> = ({
  bookingNo,
  onClose,
  vehicleRegNo,
  trackerCategory,
  showCopyBookingLink = true,
}) => {
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [phoneCountry, setPhoneCountry] = usePersistedStorageState<DropdownOption<Country>>(
    'share-country-code',
    transformCountryToDropdownOption(COUNTRY_UNITED_KINGDOM),
    localStorage,
  );
  const [validationError, setValidationError] = useState(false);
  const { submit, submitting, shareResult, resetShareResult } = useShareBooking(
    bookingNo,
    vehicleRegNo,
    trackerCategory,
  );

  const handleSetPhoneCountry = useCallback(
    (v: OnChangeValue<DropdownOption<Country>, false>) => {
      if (v) {
        setPhoneCountry(v);
        resetShareResult();
        inputRef.current?.focus();
      }
    },
    [setPhoneCountry, resetShareResult],
  );

  const handleSetMobilePhoneNo = useCallback(
    (mobileNumber: string) => {
      if (evaluatePhoneNumberString(mobileNumber)) {
        setValidationError(false);
        resetShareResult();
        setMobilePhoneNumber(mobileNumber);
      } else {
        setValidationError(true);
      }
    },
    [setMobilePhoneNumber, setValidationError, resetShareResult],
  );

  const handleMobilePhoneNoBlur = useCallback(() => {
    if (evaluatePhoneNumberString(mobilePhoneNumber)) {
      setValidationError(false);
    } else {
      setValidationError(true);
    }
  }, [setValidationError, mobilePhoneNumber]);

  const handleClickSend = useCallback(() => {
    const country = phoneCountry.data;

    const cleanedMobilePhoneNumber = cleanPhoneNumberString(mobilePhoneNumber);
    if (cleanedMobilePhoneNumber !== '') {
      const formattedPhoneNumber = formatPhoneNumber(country.code, cleanedMobilePhoneNumber);
      submit(formattedPhoneNumber);
    }
  }, [mobilePhoneNumber, phoneCountry, submit]);

  return (
    <Column maxWidth={450} minWidth={'100%'}>
      <PopoverTitle text={'Share to Freight Driver App'} onClose={onClose} />
      <form onSubmit={preventDefault}>
        <PopoverBody gap={2}>
          <Txt>
            Share booking <Txt variant="bold">{bookingNo}</Txt> to Driver app.
          </Txt>
          {showCopyBookingLink && (
            <Row>
              <CopyBookingLinkButton
                trackerCategory={trackerCategory}
                bookingNo={bookingNo}
                vehicleRegNo={vehicleRegNo}
              />
            </Row>
          )}
          <Cardy>
            <Column indent={4} gap={2}>
              <Txt size={'large'}>Send as SMS</Txt>
              {validationError && (
                <Txt size={'small'} color={cssColor('--lhds-color-red-500')}>
                  Only numbers and dash are allowed
                </Txt>
              )}
              {shareResult === false && (
                <Banner
                  variant={'error'}
                  text={'An error occurred, could not share the booking.'}
                />
              )}
              {shareResult && (
                <Banner
                  variant={'success'}
                  text={`Message sent to: ${formatPhoneNumber(
                    phoneCountry.data.code,
                    cleanPhoneNumberString(mobilePhoneNumber),
                  )}`}
                />
              )}
              <Row gap={2} flexDirection={['column', 'row']}>
                <Column>
                  <Label text={'Country code'}>
                    <DropdownShare
                      onChange={handleSetPhoneCountry}
                      options={optionList}
                      components={{ SingleValue }}
                      value={phoneCountry}
                    />
                  </Label>
                </Column>
                <Column>
                  <Label text={'Number'}>
                    <TextInput
                      name={'sharePhoneNumber'}
                      inputRef={inputRef}
                      disableContentPadding
                      variant={validationError ? 'error' : undefined}
                      value={mobilePhoneNumber}
                      onValueChange={handleSetMobilePhoneNo}
                      onBlur={handleMobilePhoneNoBlur}
                    />
                  </Label>
                </Column>
              </Row>
              <Row justifyContent={'flex-end'}>
                <PrimaryButton
                  type={'submit'}
                  disabled={mobilePhoneNumber.length === 0 || shareResult}
                  leftIcon={stenaSendPlane}
                  label="Send"
                  loadingLabel={'Sending...'}
                  loading={submitting}
                  onClick={handleClickSend}
                />
              </Row>
            </Column>
          </Cardy>
        </PopoverBody>
      </form>
    </Column>
  );
};

const SingleValue = (props: SingleValueProps<DropdownOption<Country>>) => {
  return (
    <components.SingleValue {...props} children={formatAbbreviatedCountryString(props.data.data)} />
  );
};
