import { FetchResult } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateIntermodalBookingResult,
  DetailedIntermodalBooking,
} from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { Message } from '../../../common/update-information/utils/messagesResult';
import { CreateIntermodalBookingInput } from '../../../gql/graphql';
import { StoreState } from '../../../store/storeState';
import {
  CreateIntermodalBookingValidationErrors,
  getCreateIntermodalBookingFormState,
} from '../createIntermodalReducer';

import { validateCreateIntermodalFields } from '../validation/validators';

type CreateIntermodalBookingThunkRejectValue =
  | {
      type: 'validationErrors';
      errors: CreateIntermodalBookingValidationErrors;
    }
  | { type: 'error'; errors: Message[] };

export const createIntermodalBookingThunk = createAsyncThunk<
  { warnings: Message[]; messages: Message[]; booking: DetailedIntermodalBooking },
  {
    createBooking: (
      createInput: CreateIntermodalBookingInput,
    ) => Promise<FetchResult<CreateIntermodalBookingResult>>;
  },
  { state: StoreState; rejectValue: CreateIntermodalBookingThunkRejectValue }
>(
  'create-intermodal-booking',
  async ({ createBooking }, { getState, rejectWithValue, fulfillWithValue }) => {
    const validationResult = validateCreateIntermodalFields(
      getCreateIntermodalBookingFormState(getState()),
    );

    if (!validationResult.success) {
      return rejectWithValue({
        type: 'validationErrors',
        errors: validationResult.error,
      });
    }

    if (validationResult.value.legs.length === 0) {
      return rejectWithValue({
        type: 'validationErrors',
        errors: { legs: ['No connections found'] },
      });
    }

    const createInput = validationResult.value;

    const result = await createBooking(createInput);
    const createResult = result.data?.createIntermodalBooking;

    if (createResult?.success && createResult.booking) {
      return fulfillWithValue({
        warnings: createResult.warnings,
        messages: createResult.messages,
        booking: createResult.booking,
      });
    } else {
      return rejectWithValue({
        type: 'error',
        errors: createResult?.errors ?? [],
      });
    }
  },
);
