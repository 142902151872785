import { HealthCheckAction } from './healthCheckActions';
import { StoreState } from '../../store/storeState';

export interface HealthCheckState {
  fail: boolean;
}

const INITIAL_VALUE: HealthCheckState = {
  fail: false,
};

export const healthCheckReducer = (
  state: HealthCheckState = INITIAL_VALUE,
  action: HealthCheckAction,
): HealthCheckState => {
  switch (action.type) {
    case 'HEALTH_CHECK:FAIL':
      return {
        ...state,
        fail: true,
      };
    default:
      return state;
  }
};

const getHealth = (state: StoreState): HealthCheckState => state.health;
export const getHealthCheckFailed = (state: StoreState) => getHealth(state).fail;
