import { differenceInDays, parseISO } from 'date-fns';
import {
  BookingResultNotification,
  BookingResultNotificationRecord,
} from '../notificationsReducer';

const removeOutdatedBooking = (date: Date, result: BookingResultNotification) =>
  differenceInDays(date, parseISO(result.createdAt)) < 7;

export const removeOutdatedBookingResults = (
  bookingResults: BookingResultNotificationRecord,
  date = new Date(),
): BookingResultNotificationRecord => {
  return Object.entries(bookingResults).reduce((record, [key, result]) => {
    if (result && removeOutdatedBooking(date, result)) {
      return {
        ...record,
        [key]: result,
      };
    }
    return record;
  }, {});
};
