import { Row, Txt } from '@stenajs-webui/core';
import { Switch } from '@stenajs-webui/forms';
import { FC } from 'react';

interface ManageCookiesProps {
  consent: boolean;
  onToggleConsent: (val: boolean) => void;
}

export const ManageCookies: FC<ManageCookiesProps> = ({ consent, onToggleConsent }) => {
  return (
    <>
      <Row alignItems={'center'} justifyContent={'space-between'}>
        <label htmlFor={'cookies_always_active'}>
          <Txt variant={'bold'}>Always active</Txt>
        </label>
        <Switch id={'cookies_always_active'} value={true} disabled />
      </Row>
      <Txt>
        Some cookies are necessary for our site to function as expected. These cannot be turned off.
      </Txt>
      <Row alignItems={'center'} justifyContent={'space-between'}>
        <label htmlFor={'cookies_analytics'}>
          <Txt variant={'bold'}>Analytics and Statistics</Txt>
        </label>
        <Switch id={'cookies_analytics'} value={consent} onValueChange={onToggleConsent} />
      </Row>
      <Txt>
        These cookies help us better understand how we can improve our site to make it easier and
        faster for you as a visitor.
      </Txt>
    </>
  );
};
