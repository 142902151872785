import { Box } from '@stenajs-webui/core';
import { CardBody, FlatButton, PrimaryButton } from '@stenajs-webui/elements';
import { FC, ReactNode } from 'react';
import { StickyModalFooter } from '../../details/components/modal/Modal';

interface Props {
  loading: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: ReactNode;
}

export const SwitchModalFooter: FC<Props> = ({ loading, onClose, onSubmit, children }) => {
  return (
    <StickyModalFooter>
      <CardBody row={[false, true]} gap={2}>
        <Box flex={1}>{children}</Box>
        <Box row={[false, true]} alignSelf={[null, 'flex-end']} gap>
          <FlatButton onClick={onClose} label={'Close'} />
          <PrimaryButton
            label="Switch"
            loading={loading}
            loadingLabel={'Switching...'}
            onClick={onSubmit}
          />
        </Box>
      </CardBody>
    </StickyModalFooter>
  );
};
