import { ConsentMode } from '../tracking/ga4';

export const encodeUserId = (userId: string): string => {
  return userId.replace('\\', '___');
};

export const decodeUserId = (userId: string): string => {
  return userId.replace('___', '\\');
};

export const resolveCustomsDocumentationParams = (
  userId: string,
  tracking: ConsentMode | null,
): string => `&userId=${encodeURIComponent(userId)}&tracking=${tracking ? tracking : 'denied'}`;

export const resolveCustomsDocumentationEnsURL = (
  ensUrl: string,
  userId: string,
  tracking: ConsentMode | null,
): string => {
  return `${ensUrl}${resolveCustomsDocumentationParams(userId, tracking)}`;
};
