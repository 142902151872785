/** Represents the minumum date value that we use  */
export const minDate = new Date(1900, 1, 1, 0, 0, 0, 0);

/** Represents the maximum date value that we use  */
export const maxDate = new Date(3000, 1, 1, 0, 0, 0, 0);

/** Represents the minimum time value that we use  */
export const minTime = '00:00';

/** Represents the maximum time value that we use  */
export const maxTime = '23:59';
