import { PrimaryButton, SecondaryButton, stenaBusinessCard } from '@stenajs-webui/elements';
import { FC } from 'react';
import { getPayBookingPath } from '../../common/routes/AppRoutes';
import { TrackerCategory, trackEvent } from '../../common/tracking/trackerService';
import { FlatButtonLink, SecondaryButtonLink, PrimaryButtonLink } from '../Link';

interface PayButtonProps extends SeePaymentProps {
  secondary?: boolean;
  disabled?: boolean;
}

export const PayButton: FC<PayButtonProps> = ({
  bookingNo,
  disabled = false,
  secondary = false,
  trackerCategory,
}) => {
  const ButtonComponent = secondary ? SecondaryButton : PrimaryButton;
  const ButtonLinkComponent = secondary ? SecondaryButtonLink : PrimaryButtonLink;

  if (disabled) {
    return <ButtonComponent disabled leftIcon={stenaBusinessCard} label={'Pay'} />;
  }

  return (
    <ButtonLinkComponent
      className={'t_payment_link'}
      label={'Pay'}
      leftIcon={stenaBusinessCard}
      to={getPayBookingPath([bookingNo])}
      onClick={() => {
        trackEvent({ category: trackerCategory, action: 'Pay' });
      }}
    />
  );
};

interface SeePaymentProps {
  bookingNo: number;
  trackerCategory: TrackerCategory;
}

export const SeePaymentLink: FC<SeePaymentProps> = ({ bookingNo, trackerCategory }) => (
  <FlatButtonLink
    label={'See payment'}
    className={'t_see_payment_link'}
    style={{ textDecoration: 'underline' }}
    to={getPayBookingPath([bookingNo])}
    onClick={() => {
      trackEvent({ category: trackerCategory, action: 'See payment' });
    }}
  />
);
