import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { bookingStatusIsValidForCalculatePrice } from '../../../../common/pricing/useShowCalculatePrice';
import { PaymentStatus } from '../../../../gql/graphql';

export const bookingIsPayable = (booking: BasicBooking) => {
  return booking.payable;
};

export const bookingHasPayments = (booking: BasicBooking) =>
  booking.paymentStatus === PaymentStatus.Paid ||
  booking.paymentStatus === PaymentStatus.PartiallyPaid ||
  booking.paymentStatus === PaymentStatus.Refunded ||
  booking.payments.length > 0;

export const shouldShowHazGoodsPaymentInformation = (booking: BasicBooking) => {
  return (
    booking.customer.isCashCustomer &&
    booking.hazardousGoods &&
    !booking.hazardousGoodsApproved &&
    bookingStatusIsValidForCalculatePrice(booking.bookingStatus.status)
  );
};
