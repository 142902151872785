import { isEqual } from 'lodash';
import { BookingFormState } from '../../../../services/update-form/updateFormReducer';

export const isPriceOutdated = (initial?: BookingFormState, edited?: BookingFormState): boolean => {
  if (!initial || !edited) {
    return true;
  }
  if (isEqual(initial, edited)) {
    return false;
  }

  return true;
};
