import { Box, DivProps, Txt, useBoolean } from '@stenajs-webui/core';
import { TextInputButtonSize } from '@stenajs-webui/elements';
import { FC, ReactNode, useRef } from 'react';
import { InformationMessageIcon } from '../../../../common-ui/information-message/InformationMessageIcon';
import { FreightPortalPopover } from '../../../../common-ui/popover/FreightPortalPopover';
import { PopoverBody } from '../../../../common-ui/popover/PopoverBody';
import { PopoverTitle } from '../../../../common-ui/popover/PopoverTitle';
import { stopPropagation } from '../../../../common/eventHelpers';
import { ApprovalConfig } from '../utils/approvalInfo';

export interface ApproveAlertProps extends Pick<DivProps, 'tabIndex'> {
  config: ApprovalConfig;
  buttonSize?: TextInputButtonSize;
  children?: ReactNode;
}

export const ApproveAlert: FC<ApproveAlertProps> = ({ config, tabIndex, children, buttonSize }) => {
  const [isPopupOpen, openPopup, closePopup] = useBoolean(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { descriptionText, headerText, type } = config;

  if (type === null) {
    return null;
  }

  return (
    <>
      <InformationMessageIcon
        buttonRef={buttonRef}
        type={type}
        onClick={openPopup}
        tabIndex={tabIndex}
        size={buttonSize}
      />
      <FreightPortalPopover
        reference={buttonRef}
        lazy
        onRequestClose={closePopup}
        visible={isPopupOpen}
        placement={'left-start'}
        content={
          <Box minWidth={240} maxWidth={320} onDoubleClick={stopPropagation}>
            <PopoverTitle text={headerText} onClose={closePopup} />
            <PopoverBody gap>
              <Txt>{descriptionText}</Txt>
              {children}
            </PopoverBody>
          </Box>
        }
      />
    </>
  );
};
