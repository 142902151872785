import {
  addDayStateHighlights,
  CalendarState,
  DateTextInputCalendarProps,
} from '@stenajs-webui/calendar';
import { useMemo } from 'react';
import { useAllAvailableDepartureDates } from '../graphql/useAvailableDepartureDates';

export const useCalendarPropsForDateTextInput = (
  fromDate: string | null,
  routeCode: string | null,
  toDate: string | null,
): DateTextInputCalendarProps<any> => {
  const [dates] = useAllAvailableDepartureDates(fromDate, routeCode, toDate);

  const statePerMonth = useMemo(() => {
    return dates.reduce<CalendarState | undefined>((previousValue, currentValue) => {
      return addDayStateHighlights(previousValue, currentValue, ['enabled']);
    }, undefined);
  }, [dates]);
  return {
    highlightToday: true,
    defaultHighlights: ['disabled'],
    statePerMonth,
  };
};
