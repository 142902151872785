import {
  BookingGoods,
  DetailedIntermodalBookingFragment,
  HazardousGoodsStatus,
  PartnerBooking,
} from '../../gql/graphql';
import { bookingDetailsRows, RowType } from '../../use-cases/manage/details/utils/detailsRows';
import { BasicBooking } from '../graphql/fragments/gql/BasicBooking';
import { resolveCustomerLabel } from '../utils/customerUtils';
import { formatDate } from '../utils/dates/dateUtils';
import { resolveHazardousGoodsStatusText } from '../utils/hazardousGoodsUtil';
import { resolveCraneableStatusText } from '../utils/loadingPreferenceUtil';

export const formatVertical = (columns: Array<[string, string]>): string => {
  const columnWidth = columns.reduce(
    (widest, [label, _]) => (label.length > widest ? label.length : widest),
    columns[0][0].length,
  );
  return columns.map(([label, value]) => `${label.padEnd(columnWidth)}\t${value}`).join('\n');
};

export const formatHorizontal = (columns: Array<[string, string]>): string => {
  const labels = columns.map(([label]) => label);
  const values = columns.map(([, value]) => value);

  return labels.join('\t') + '\n' + values.join('\t');
};

export const stringifyBooking = (
  booking: BasicBooking,
  dateFormat: string,
  vertical: boolean = true,
): string => {
  const rows = bookingDetailsRows(booking, dateFormat).map(
    ({ name, value }) => [name, value] as [string, string],
  );

  if (vertical) {
    return formatVertical(rows);
  } else {
    return formatHorizontal(rows);
  }
};

export const stringifyIntermodalBooking = (
  booking: DetailedIntermodalBookingFragment,
  dateFormat: string,
): string => {
  const rows: RowType[] = [];

  rows.push({
    name: 'Booking number',
    value: String(booking.bookingNo),
  });

  rows.push({
    name: 'Status',
    value: booking.status.label,
  });

  rows.push({
    name: 'Account',
    value: resolveCustomerLabel(booking.customer.custNo, booking.customer.custIndex),
  });

  if (booking.journey) {
    rows.push({
      name: 'Intermodal route',
      value: booking.journey.route.longDescription,
    });

    rows.push({
      name: 'Departure date',
      value: formatDate(booking.journey.departureDate, dateFormat),
    });

    rows.push({
      name: 'Departure time',
      value: booking.journey.departureTime,
    });

    rows.push({
      name: 'Arrival date',
      value: formatDate(booking.journey.arrivalDate, dateFormat),
    });

    rows.push({
      name: 'Arrival time',
      value: booking.journey.arrivalTime,
    });
  }

  rows.push({
    name: 'Vehicle registration',
    value: booking.vehicleReg ?? '',
  });

  rows.push({
    name: 'ILU code',
    value: booking.iluCode ?? '',
  });

  rows.push({
    name: 'Vehicle type',
    value: booking.vehicleType?.description ?? '',
  });

  rows.push({
    name: 'Craneable',
    value: resolveCraneableStatusText(booking.craneableStatus),
  });

  rows.push({
    name: 'Length',
    value: String(booking.length),
  });

  rows.push({
    name: 'Width',
    value: String(booking.width),
  });

  rows.push({
    name: 'Height',
    value: String(booking.height),
  });

  if (booking.hazardousGoods !== HazardousGoodsStatus.NotRequested) {
    rows.push({
      name: 'Hazardous goods',
      value: resolveHazardousGoodsStatusText(booking.hazardousGoods),
    });
  }

  rows.push({
    name: 'Number of plugins',
    value: String(booking.noOfPlugins),
  });

  if (booking.pluginTemperature) {
    rows.push({
      name: 'Temperature',
      value: `${booking.pluginTemperature} °C`,
    });
  }

  rows.push({
    name: 'Goods list',
    value:
      booking.bookingGoods && booking.bookingGoods.length > 0
        ? stringfyIntermodalGoodsList(booking.bookingGoods)
        : 'Empty',
  });

  if (booking.customerReference) {
    rows.push({
      name: 'Customer reference',
      value: booking.customerReference,
    });
  }

  rows.push({
    name: 'Train bookings',
    value:
      booking.partnerBookings && booking.partnerBookings.length > 0
        ? stringfyTrainBookings(booking.partnerBookings)
        : 'None',
  });

  if (booking.dropOffReference) {
    rows.push({
      name: 'Drop off reference',
      value: booking.dropOffReference,
    });
  }

  if (booking.pickUpReference) {
    rows.push({
      name: 'Pick up reference',
      value: booking.pickUpReference,
    });
  }

  if (booking.loadingNote) {
    rows.push({
      name: 'Loading note',
      value: booking.loadingNote,
    });
  }

  return formatVertical(rows.map(({ name, value }) => [name, value] as [string, string]));
};

export const formatBookingMailContent = (booking: BasicBooking, dateFormat: string) => {
  const rows = bookingDetailsRows(booking, dateFormat);

  const heading = 'Stena Line booking:';

  return rows.reduce((prev, { name, value }, i) => {
    if (
      name === 'Departure time (local)' ||
      name === 'Trailer registration' ||
      (name === 'Vehicle registration' && rows[i + 1].name !== 'Trailer registration')
    ) {
      return `${prev}\r\n${name}:   ${value}\r\n`;
    }

    return `${prev}\r\n${name}:   ${value}`;
  }, `${heading}\r\n`);
};

export const stringfyIntermodalGoodsList = (goods: BookingGoods[]): string => {
  const goodsListStringArr = goods.map(
    goodsItem => `${goodsItem.goodsType.description} (${goodsItem.weight} kg)`,
  );

  return goodsListStringArr.join('/');
};

export const stringfyTrainBookings = (trainBookings: PartnerBooking[]): string => {
  const trainBookingsStringArr = trainBookings.map(
    train => `${train.number} (${train.status ? 'Confirmed' : 'Unconfirmed'})`,
  );
  return trainBookingsStringArr.join('/');
};
