import { Space } from '@stenajs-webui/core';
import {
  FlatButton,
  ResultListBanner,
  stenaRefresh,
  useResultListBannerState,
} from '@stenajs-webui/elements';
import { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import { trackEvent } from '../../../../common/tracking/trackerService';
import { useBookingFormInstanceId } from '../../../../common/update-information/BookingFormInstanceIdProvider';
import { transformToUpdateBookingFormState } from '../../../../common/update-information/utils/transformToUpdateBookingFormState';
import { ImportExportStatusCode } from '../../../../gql/graphql';
import { initializeUpdateForm } from '../../../../services/update-form/updateFormActions';
import { useRefreshImportExportStatus } from '../../hooks/UseRefreshImportExportStatus';

interface Props {
  bookingNo: number;
  statusCode: ImportExportStatusCode;
}

export const ImportExportRefreshStatusButton: FC<Props> = ({ bookingNo, statusCode }) => {
  const { bannerState, setBannerState, clearBannerResult } = useResultListBannerState(undefined);
  const { refreshImportExportStatus, loading } = useRefreshImportExportStatus();

  const { formId } = useBookingFormInstanceId();
  const dispatch = useDispatch();

  const onClick = async () => {
    trackEvent({ category: formId, action: 'Get status update', label: statusCode });
    clearBannerResult();
    try {
      const r = await refreshImportExportStatus(bookingNo);
      const es = r.data?.refreshBookingImportExportStatus.errors;
      if ((es && es.length > 0) || (r.errors && r.errors.length > 0)) {
        setBannerState({ headerText: 'Failed to refresh status' });
        trackEvent({ category: formId, action: 'Failed to get status update' });
      } else {
        trackEvent({ category: formId, action: 'Get status update success' });
        const booking = r.data?.refreshBookingImportExportStatus.booking;
        if (booking) {
          const bookingFormState = transformToUpdateBookingFormState(booking);
          batch(() => {
            dispatch(
              initializeUpdateForm({ formId: 'Details', bookingNo }, bookingFormState, booking),
            );
            dispatch(
              initializeUpdateForm({ formId: 'Grid', bookingNo }, bookingFormState, booking),
            );
          });
        }
      }
    } catch (e) {
      setBannerState({ headerText: 'Failed to refresh status' });
      trackEvent({ category: formId, action: 'Failed to get status update' });
    }
  };

  return (
    <>
      <FlatButton
        loading={loading}
        leftIcon={stenaRefresh}
        label={'Get status update'}
        loadingLabel={'Get status update'}
        disabled={loading}
        onClick={onClick}
        className={'t_import_export_popup_button'}
      />
      {bannerState && (
        <>
          <Space />
          <ResultListBanner bannerState={bannerState} variant={'error'} />
        </>
      )}
    </>
  );
};
