import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  ButtonVariant,
  stenaBusinessCard,
  stenaFinancePaid,
  stenaFinancePayPartially,
  stenaFinanceUnpaid,
  TagVariant,
} from '@stenajs-webui/elements';
import { PaymentStatus } from '../../gql/graphql';
import { CSSProperties } from 'react';
import { cssColor } from '@stenajs-webui/theme';

export const getPaymentStatusDisplayProps = (
  status: PaymentStatus,
): {
  icon: IconDefinition;
  buttonVariant?: ButtonVariant;
  buttonStyle?: CSSProperties;
  tagVariant: TagVariant;
} => {
  switch (status) {
    case PaymentStatus.Paid:
      return {
        icon: stenaFinancePaid,
        buttonVariant: 'success',
        tagVariant: 'success',
      };
    case PaymentStatus.PartiallyPaid:
      return {
        icon: stenaFinancePayPartially,
        buttonVariant: 'danger',
        tagVariant: 'error',
      };
    case PaymentStatus.Unpaid:
      return {
        icon: stenaFinanceUnpaid,
        buttonVariant: 'danger',
        tagVariant: 'error',
      };
    case PaymentStatus.Refunded:
      return {
        icon: stenaBusinessCard,
        buttonStyle: { color: cssColor('--silver') },
        tagVariant: 'passive',
      };
    default:
      return {
        icon: stenaFinanceUnpaid,
        buttonVariant: 'normal',
        tagVariant: 'passive',
      };
  }
};

export const getPaymentIndicatorStatusAbbreviation = (status: PaymentStatus): string => {
  switch (status) {
    case PaymentStatus.Paid:
      return 'P';
    case PaymentStatus.PartiallyPaid:
      return 'PP';
    case PaymentStatus.Unpaid:
      return 'U';
    case PaymentStatus.Refunded:
      return 'R';
    default:
      return '';
  }
};

export const getPaymentIndicatorStatusText = (status: PaymentStatus): string => {
  switch (status) {
    case PaymentStatus.Paid:
      return 'Paid';
    case PaymentStatus.PartiallyPaid:
      return 'Partially paid';
    case PaymentStatus.Unpaid:
      return 'Unpaid';
    case PaymentStatus.Refunded:
      return 'Refunded';
    default:
      return '';
  }
};

export const getPaymentIndicatorClassName = (status: PaymentStatus): string => {
  switch (status) {
    case PaymentStatus.Paid:
      return 'paid';
    case PaymentStatus.PartiallyPaid:
      return 'partially-paid';
    case PaymentStatus.Unpaid:
      return 'unpaid';
    case PaymentStatus.Refunded:
      return 'refunded';
    default:
      return '';
  }
};
