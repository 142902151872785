import { useMemo } from 'react';
import { useAccount } from '../../services/settings/hooks/useAccount';
import { getVehicleTypeOptionValue } from '../../use-cases/manage/grid/utils/optionTransformers';
import { addFavouritesToOptions } from '../utils/DropdownUtils';
import { useVehicleTypesForCustomerAndRouteAsOptions } from './useVehicleTypesForCustomerAndRouteAsOptions';

export const useVehicleTypesForCustomerAndRouteAsOptionsWithFavoriteOptions = (
  customerNo: number | null,
  routeCode: string | null,
  skip: boolean = false,
) => {
  const { accountSettings } = useAccount();
  const [options] = useVehicleTypesForCustomerAndRouteAsOptions(customerNo, routeCode, skip);
  return useMemo(() => {
    if (accountSettings.favouriteVehicleTypes && accountSettings.favouriteVehicleTypes.length > 0) {
      return addFavouritesToOptions(
        options,
        accountSettings.favouriteVehicleTypes.map(getVehicleTypeOptionValue),
      );
    }
    return options;
  }, [options, accountSettings.favouriteVehicleTypes]);
};
