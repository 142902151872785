import { useTimeoutState } from '@stenajs-webui/core';
import { ActionMenuItem, stenaCopy } from '@stenajs-webui/elements';
import { FC } from 'react';

interface CommonActionMenuItemProps {
  disabled?: boolean;
  getValue: () => string;
}
export const CopyActionMenuItem: FC<CommonActionMenuItemProps> = ({ disabled, getValue }) => {
  const [showCopyClicked, setShowCopyClicked] = useTimeoutState(false, 2000);

  const onClick = () => {
    setShowCopyClicked(true);
    navigator.clipboard.writeText(getValue());
  };

  return (
    <ActionMenuItem
      label={showCopyClicked ? 'Copied' : 'Copy to clipboard'}
      leftIcon={stenaCopy}
      variant={showCopyClicked ? 'success' : undefined}
      success={showCopyClicked}
      disableCloseOnClick
      onClick={onClick}
      disabled={disabled}
    />
  );
};
