import { first } from 'lodash';
import {
  getUpdateEditedBookingGood,
  getUpdateInitialBookingGood,
  getUpdateIntermodalBookingGoodValidationErrors,
  getUpdateIntermodalBookingIsSubmitting,
  modifyUpdateIntermodalBookingGood,
  UpdateIntermodalBookingGoodFormModel,
} from '../../../services/intermodal/updateIntermodalReducer';
import { useEditable } from '../../../common/modal/EditableContext';
import { useCallback } from 'react';
import { StoreState } from '../../../store/storeState';
import { useShallowEqualSelector } from '../../../common/hooks/useShallowEqualSelector';
import { useDispatch } from 'react-redux';
import {
  TrackerAction,
  transformUpdateIntermodalGoodsFieldToTrackerAction,
} from '../../simple-create/hooks/UseTrackingTextInput';

interface useIntermodalGoodsFieldProps<TFormState, Key extends keyof TFormState> {
  editedValue: TFormState[Key] | undefined;
  initialValue: TFormState[Key] | undefined;
  onChange: (value: TFormState[Key]) => void;
  revertChange: () => void;
  validationError?: string;
  editable: boolean;
  disabled: boolean;
  trackerAction: TrackerAction;
}

export const useUpdateIntermodalGoodsField = <
  Key extends keyof UpdateIntermodalBookingGoodFormModel,
>(
  goodId: string,
  name: Key,
): useIntermodalGoodsFieldProps<UpdateIntermodalBookingGoodFormModel, Key> => {
  const editable = useEditable();

  const mapState = useCallback(
    (state: StoreState) => {
      const initialFormState = getUpdateInitialBookingGood(state, goodId);
      const editedFormState = getUpdateEditedBookingGood(state, goodId);
      const validationErrors = getUpdateIntermodalBookingGoodValidationErrors(state, goodId, name);
      const disabled = getUpdateIntermodalBookingIsSubmitting(state);
      return {
        editedValue: editedFormState?.[name],
        initialValue: initialFormState?.[name],
        validationError: first(validationErrors),
        disabled,
      };
    },
    [goodId, name],
  );

  const { editedValue, initialValue, disabled, validationError } =
    useShallowEqualSelector(mapState);

  const trackerAction = transformUpdateIntermodalGoodsFieldToTrackerAction(name);

  const dispatch = useDispatch();
  const onChange = useCallback<(value: UpdateIntermodalBookingGoodFormModel[Key]) => void>(
    newValue => {
      if (editedValue !== newValue) {
        dispatch(
          modifyUpdateIntermodalBookingGood({ updatedFields: { [name]: newValue }, id: goodId }),
        );
      }
    },
    [editedValue, dispatch, name, goodId],
  );

  const revertChange = useCallback(
    () => () => {
      if (initialValue) {
        onChange(initialValue);
      }
    },
    [initialValue, onChange],
  );

  return {
    editedValue,
    initialValue,
    onChange,
    revertChange,
    editable,
    disabled,
    validationError,
    trackerAction,
  };
};
