import { Row, Txt } from '@stenajs-webui/core';
import { Dot } from '../Dot';
import { getColorForSailingCategory } from './util';

interface SailingCategoryDotWithTextProps {
  type: { code: string; label: string };
}

export function SailingCategoryDotWithText({
  type: { code, label },
}: SailingCategoryDotWithTextProps) {
  return (
    <Row alignItems={'center'} gap>
      <Dot color={getColorForSailingCategory(code)} size={8} />
      <Txt whiteSpace={'nowrap'} variant={'overline'} size={'small'}>
        {label}
      </Txt>
    </Row>
  );
}
