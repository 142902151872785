import { graphql } from '../../../gql';

export const createBookingsMutation = graphql(/* GraphQL */ `
  mutation CreateBookings($bookings: [CreateBookingInput!]!) {
    createBookings(bookings: $bookings) {
      results {
        booking {
          ...BasicBooking
        }
        errors {
          description
          field
        }
        messages {
          description
        }
        success
        warnings {
          description
          field
        }
      }
    }
  }
`);
