import { TextInputProps } from '@stenajs-webui/forms';
import { FC } from 'react';
import {
  TextInputWithCheckbox,
  TextWithCheckboxValue,
} from '../../../../../common-ui/form-fields/TextInputWithCheckbox';
import { useUpdateField } from '../../../../../common/update-information/hooks/useUpdateField';
import {
  transformUpdateFieldToTrackerAction,
  useTrackingTextInput,
} from '../../../../simple-create/hooks/UseTrackingTextInput';

export interface TextInputWithCheckboxFieldProps extends TextInputProps {
  checkBoxName: 'noOfPlugins';
  name: 'pluginTemperature';
}

export const TextInputWithCheckboxField: FC<TextInputWithCheckboxFieldProps> = ({
  checkBoxName,
  name,
  ...props
}) => {
  const {
    onChange: checkboxOnChange,
    editedValue: checkboxValue,
    initialValue: checkboxInitialValue,
  } = useUpdateField<'noOfPlugins'>(checkBoxName);
  const {
    onChange: textInputOnChange,
    editedValue: textInputValue,
    initialValue,
  } = useUpdateField<'pluginTemperature'>(name);

  const onChange = (value: TextWithCheckboxValue) => {
    checkboxOnChange(value.checkboxValue ? checkboxInitialValue || 1 : 0);

    if (value.textValue !== textInputValue) {
      textInputOnChange(value.textValue);
    } else if (!value.checkboxValue && textInputValue !== '') {
      textInputOnChange('');
    }
  };

  const { onBlur, onFocus } = useTrackingTextInput(
    transformUpdateFieldToTrackerAction(name),
    'Details',
  );

  return (
    <TextInputWithCheckbox
      {...props}
      onChange={onChange}
      value={{
        textValue: textInputValue || '',
        checkboxValue: checkboxValue ? checkboxValue > 0 : false,
      }}
      width={'100%'}
      style={{
        fontStyle: textInputValue !== initialValue ? 'italic' : 'normal',
        fontWeight: textInputValue !== initialValue ? 'bold' : 'normal',
      }}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};
