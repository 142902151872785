import { Customer } from '../../common/graphql/fragments/gql/Customer';
import { Route } from '../../common/graphql/fragments/gql/Route';
import { Sailing } from '../../common/graphql/fragments/gql/Sailing';
import { VehicleType } from '../../common/graphql/fragments/gql/VehicleType';
import { CustomerCustomsStatus } from '../../gql/graphql';
import { StoreState } from '../../store/storeState';
import {
  Animal,
  CustomsClassification,
  LoadingPreference,
  SenderReceiverCountry,
} from '../../use-cases/manage/grid/utils/optionTransformers';
import { RegistrationNumberAlertFormState } from './registrationNumberAlert';
import {
  BookingValidationErrors,
  updateBookingReducer,
  UpdateFormState,
  UpdateResult,
} from './updateBookingReducer';
import { initializeUpdateForm, UpdateFormAction } from './updateFormActions';
import { isBookingEditable } from './utils/bookingUtils';
import { isEqualFormStates } from './utils/isEqualFormStates';

export type UpdateFormIdVariant = 'Grid' | 'Details';
export const formIdVariants: UpdateFormIdVariant[] = ['Details', 'Grid'];

export interface FormInstanceId {
  bookingNo: number;
  formId: UpdateFormIdVariant;
}

export type UpdateFormReducerState = Record<
  number,
  Partial<Record<UpdateFormIdVariant, UpdateFormState | undefined>> | undefined
>;

export type DateInputFieldKeys = keyof Pick<BookingFormState, 'departureDate'>;
export type NumericFieldKeys = keyof Pick<
  BookingFormState,
  'length' | 'height' | 'width' | 'cargoWeight' | 'tradeWeight'
>;

export type StringFieldKeys = keyof Pick<
  BookingFormState,
  | 'bookingStatusCode'
  | 'customerReference'
  | 'departureDate'
  | 'loadingListMessage'
  | 'pluginTemperature'
  | 'timestamp'
  | 'trailerRegNo'
  | 'vehicleRegNo'
  | 'importReference'
  | 'exportReference'
>;

export type BookingFormStateKeys = Array<keyof BookingFormState>;

export interface BookingFormState {
  bookingNo?: number;
  bookingStatusCode?: string;
  cargoWeight?: number;
  customer?: Customer;
  customerCustomsStatus?: CustomerCustomsStatus;
  customerReference?: string;
  customsClassification?: CustomsClassification | null;
  departureDate?: string;
  hazardousGoods?: boolean;
  height?: number;
  length?: number;
  livestockType?: Animal | null;
  livestock?: boolean;
  loadingListMessage?: string;
  loadingPreference?: LoadingPreference | null;
  noOfAdults?: number;
  noOfChildren?: number;
  noOfDrivers?: number;
  noOfInfants?: number;
  noOfPlugins?: number;
  exportReference?: string;
  importReference?: string;
  pluginTemperature?: string;
  receiverCountry?: SenderReceiverCountry | null;
  route?: Route;
  sailing?: Sailing;
  saveAsWaitList?: boolean;
  senderCountry?: SenderReceiverCountry | null;
  showDaysOnQuay?: boolean;
  timestamp?: string;
  tradeWeight?: number;
  trailerRegNo?: string;
  vehicleRegNo?: string;
  vehicleType?: VehicleType;
  width?: number;
}

const INITIAL_STATE: UpdateFormReducerState = {};

export const updateFormReducer = (
  state = INITIAL_STATE,
  action: UpdateFormAction,
): UpdateFormReducerState => {
  if (action.type === 'UPDATE_FORM:MULTI_INITIALIZE_UPDATE_FORM') {
    return action.payload.bookings.reduce((acc, { booking, formState }) => {
      const updateFormElement = acc[booking.bookingNo];

      return {
        ...acc,
        [booking.bookingNo]: {
          ...updateFormElement,
          [action.payload.formId]: updateBookingReducer(
            updateFormElement ? updateFormElement[action.payload.formId] : undefined,
            initializeUpdateForm(
              { formId: action.payload.formId, bookingNo: booking.bookingNo },
              formState,
              booking,
            ),
          ),
        },
      };
    }, state);
  }

  const { bookingNo, formId } = action?.payload?.id || {
    bookingNo: undefined,
    formId: undefined,
  };

  if (action.type.startsWith('UPDATE_FORM:') && bookingNo && formId) {
    const updateFormElement = state[bookingNo];

    return {
      ...state,
      [bookingNo]: {
        ...updateFormElement,
        [formId]: updateBookingReducer(updateFormElement?.[formId] ?? undefined, action),
      },
    };
  } else {
    return state;
  }
};

export const getUpdateFormListState = (state: StoreState): UpdateFormReducerState =>
  state.updateForm;

export const getUpdateFormState = (
  state: StoreState,
  { bookingNo, formId }: FormInstanceId,
): UpdateFormState | undefined => {
  const list = getUpdateFormListState(state);
  return list[bookingNo]?.[formId];
};

export const getEditedFormState = (
  state: StoreState,
  formInstanceId: FormInstanceId,
): BookingFormState | undefined => {
  const updateFormState = getUpdateFormState(state, formInstanceId);
  return updateFormState ? updateFormState.editedFormState : undefined;
};

export const getInitialFormState = (
  state: StoreState,
  formInstanceId: FormInstanceId,
): BookingFormState | undefined => {
  const updateFormState = getUpdateFormState(state, formInstanceId);
  return updateFormState?.initialFormState ?? undefined;
};

export const getFormIsEdited = (state: StoreState, formInstanceId: FormInstanceId): boolean => {
  const updateFormState = getUpdateFormState(state, formInstanceId);
  return updateFormState
    ? !isEqualFormStates(updateFormState.initialFormState, updateFormState.editedFormState)
    : false;
};

export const getFormIsUpdating = (state: StoreState, formInstanceId: FormInstanceId): boolean => {
  const updateFormState = getUpdateFormState(state, formInstanceId);
  return updateFormState ? updateFormState.meta.updateCount > 0 : false;
};
export const getFormUpdateResult = (
  state: StoreState,
  formInstanceId: FormInstanceId,
): UpdateResult | null => {
  const updateFormState = getUpdateFormState(state, formInstanceId);
  return updateFormState ? updateFormState.meta.updateResult : null;
};

export const getFormIsSubmitting = (state: StoreState, formInstanceId: FormInstanceId): boolean => {
  const updateFormState = getUpdateFormState(state, formInstanceId);
  return updateFormState ? updateFormState.meta.submitting : false;
};

export const getBookingIsDisabled = (
  state: StoreState,
  formInstanceId: FormInstanceId,
): boolean => {
  const updateFormState = getUpdateFormState(state, formInstanceId);
  const submitting = getFormIsSubmitting(state, formInstanceId);
  const booking = updateFormState?.booking;

  if (!booking) {
    return true;
  }

  return submitting || !isBookingEditable(booking);
};

export const getRegistrationNumberAlert = (
  state: StoreState,
  formInstanceId: FormInstanceId,
): RegistrationNumberAlertFormState | undefined => {
  const updateFormState = getUpdateFormState(state, formInstanceId);
  return updateFormState ? updateFormState.registrationNumberAlert : undefined;
};

export const getValidationErrors = (
  state: StoreState,
  formInstanceId: FormInstanceId,
): BookingValidationErrors | undefined => {
  const updateFormState = getUpdateFormState(state, formInstanceId);
  return updateFormState ? updateFormState.meta.validationErrors : undefined;
};
