import { TrackerCategory, trackEvent } from '../../../common/tracking/trackerService';
import { pluralizeBooking } from '../../../common/utils/pluralization';
import { CreateBookings_createBookings_results } from '../../../use-cases/multiple-create/graphql/gql/CreateBookings';

export const trackCreateEvent = (trackerCategory: TrackerCategory, action: string, count: number) =>
  trackEvent({
    category: trackerCategory,
    action: action,
    label: `${count} ${pluralizeBooking(count)}`,
    value: count,
  });

export const trackBookingCreateResult = (
  trackerCategory: TrackerCategory,
  results: CreateBookings_createBookings_results[],
) => {
  const countTotal = results.length;
  const countConfirmed = results.filter(
    r => r.booking != null && r.booking.bookingStatusCode !== 'W',
  ).length;
  const countWaitlist = results.filter(
    r => r.booking != null && r.booking.bookingStatusCode === 'W',
  ).length;

  const countFailed = countTotal - (countConfirmed + countWaitlist);

  if (countFailed > 0) {
    trackCreateEvent(trackerCategory, 'Booking failed (validation error)', countFailed);
  }

  if (countConfirmed > 0) {
    trackCreateEvent(trackerCategory, 'Booking successful (confirmed)', countConfirmed);
  }

  if (countWaitlist > 0) {
    trackCreateEvent(trackerCategory, 'Booking successful (waitlist)', countWaitlist);
  }
};
