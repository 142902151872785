import { Route_exportSettings_accompaniedList } from '../graphql/fragments/gql/Route';
import { ImportExportType } from '../../gql/graphql';

export interface ImportExportParams {
  editedValue?: string;
  initialValue?: string;
}

export const getImportExportStatus = ({ editedValue, initialValue }: ImportExportParams) => {
  const hideStatusIcon = editedValue !== initialValue;
  return { hideStatusIcon };
};

export interface ShowImportExportContentProps {
  setting: Route_exportSettings_accompaniedList;
  type: Exclude<ImportExportType, ImportExportType.Empty>;
}

export function getShowImportExportContentProps(
  setting: Route_exportSettings_accompaniedList | undefined,
): ShowImportExportContentProps | null {
  if (setting && setting.type !== ImportExportType.Empty) {
    return {
      setting,
      type: setting.type,
    };
  }

  return null;
}
