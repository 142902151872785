import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';

const instrumentationKey = (window.FREIGHT_PORTAL_ENV || {}).APPLICATION_INSIGHT_KEY;

let appInsights: ApplicationInsights | null = null;

export const initAppInsight = () => {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
    },
    queue: [
      () => {
        appInsights?.addTelemetryInitializer(envelope => {
          if (envelope.tags) {
            envelope.tags['ai.cloud.role'] = 'FreightPortal';
          }
        });
      },
    ],
  });

  appInsights.loadAppInsights();
};

export const trackException = (exception: IExceptionTelemetry) => {
  appInsights?.trackException(exception);
};
