import { StoreState } from '../../store/storeState';
import { getDashboardEnabled } from '../dashboard/dashboardReducer';
import { getActiveModal } from '../modal/modalReducer';
import { TourActions } from './tourActions';

export interface TourReducerState {
  unseenFeatureIds: string[];
  isNewFeatureTourRunning: boolean;
  isWelcomeTourRunning: boolean;
  hasWelcomeTourBeenSeen: boolean;
}

const INITIAL_VALUE: TourReducerState = {
  unseenFeatureIds: [],
  isNewFeatureTourRunning: false,
  isWelcomeTourRunning: false,
  hasWelcomeTourBeenSeen: false,
};

export const tourReducer = (
  state: TourReducerState = INITIAL_VALUE,
  action: TourActions,
): TourReducerState => {
  switch (action.type) {
    case 'WELCOME_TOUR:START':
      return {
        ...state,
        isWelcomeTourRunning: true,
      };
    case 'WELCOME_TOUR:REQUEST_STOP':
      return {
        ...state,
        isWelcomeTourRunning: false,
        hasWelcomeTourBeenSeen: true,
      };
    case 'NEW_FEATURE_TOUR:START':
      return {
        ...state,
        isNewFeatureTourRunning: true,
        unseenFeatureIds: action.payload.ids,
      };
    case 'NEW_FEATURE_TOUR:STOP':
      return {
        ...state,
        isNewFeatureTourRunning: false,
      };
    default:
      return state;
  }
};

export const getTour = (state: StoreState): TourReducerState => state.tour;

export const getIsWelcomeTourRunning = (state: StoreState): boolean =>
  getTour(state).isWelcomeTourRunning;

export const getIsNewFeatureTourRunning = (state: StoreState): boolean =>
  !getIsWelcomeTourRunning(state) && getTour(state).isNewFeatureTourRunning;

export const getUnseenFeatureIds = (state: StoreState): string[] => getTour(state).unseenFeatureIds;

const modalIsShowing = (state: StoreState): boolean => {
  const activeModal = getActiveModal(state);

  return (
    activeModal != null &&
    (activeModal.type === 'introduction' || activeModal.type === 'hotkeysOverview')
  );
};

export const getIsAnyTourRunning = (state: StoreState): boolean => {
  return getIsWelcomeTourRunning(state) || getIsNewFeatureTourRunning(state);
};

export const getIsAnyTourRunningOrModalShowing = (state: StoreState): boolean => {
  return getIsAnyTourRunning(state) || modalIsShowing(state) || !getDashboardEnabled(state);
};
