import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestSubmit } from '../../../services/update-form/updateFormActions';
import {
  FormInstanceId,
  getFormIsSubmitting,
} from '../../../services/update-form/updateFormReducer';
import { StoreState } from '../../../store/storeState';

export const useUpdateSubmit = ({ bookingNo, formId }: FormInstanceId) => {
  const dispatch = useDispatch();

  const mapState = useCallback(
    (state: StoreState) => getFormIsSubmitting(state, { bookingNo, formId }),
    [bookingNo, formId],
  );

  const submitting = useSelector(mapState);

  const submit = useCallback(() => {
    dispatch(requestSubmit({ bookingNo, formId }));
  }, [bookingNo, dispatch, formId]);
  return {
    submit,
    submitting,
  };
};
