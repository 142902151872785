import { call } from 'redux-saga/effects';
import { TravelledVehicleType } from '../../../services/update-form/registrationNumberAlert';
import {
  fetchArrivalNotepad as apiFetchArrivalNotepad,
  fetchTravelledVehicle as apiFetchTravelledVehicle,
  fetchVehicleType as apiFetchVehicleType,
} from '../../api';
import { ArrivalNotepad } from '../../graphql/fragments/gql/ArrivalNotepad';
import { TravelledVehicle } from '../../graphql/fragments/gql/TravelledVehicle';
import { VehicleType } from '../../graphql/fragments/gql/VehicleType';
import { trackEvent } from '../../tracking/trackerService';
import { getAppliedDimension } from '../utils/registrationNumberAlertUtil';
import {
  getHeight,
  isFTAndTRMatch,
  shouldApplyTravelledVehicleAutomatically,
} from '../utils/travelledVehicleUtils';

export interface RegistrationNumberAlertStateChange {
  cargoWeight?: number;
  hazardousGoods?: boolean;
  height?: number;
  length?: number;
  loadingListMessage?: string;
  registrationNumberAlert: {
    appliedArrivalNotepad: boolean;
    appliedCargoWeight: boolean;
    appliedHazardousGoods: boolean;
    appliedHeight: boolean;
    appliedLength: boolean;
    appliedLoadingListMessage: boolean;
    appliedTravelledVehicle: boolean;
    result: TravelledVehicle | null;
    travelledVehicleType: TravelledVehicleType;
    vehicleType: VehicleType | null;
  };
  vehicleType?: VehicleType | null;
}

function* fetchArrivalNotepad(
  customerNumber: number,
  routeCode: string,
  vehicleRegNo: string,
  previousCargoWeight: number | undefined,
  previousHazardousGoods: boolean | undefined,
  previousHeight: number | undefined,
  previousLength: number | undefined,
  previousLoadingListMessage: string | undefined,
): Generator<any, RegistrationNumberAlertStateChange | null, any> {
  const arrivalNotepadResult: ArrivalNotepad | null = yield call(
    apiFetchArrivalNotepad,
    customerNumber,
    vehicleRegNo,
    routeCode,
  );

  if (!arrivalNotepadResult) {
    return null;
  }

  trackEvent({ category: 'General', action: 'ArrivalNotepad match' });

  const vehicleType = yield call(
    apiFetchVehicleType,
    customerNumber,
    routeCode,
    arrivalNotepadResult.vehicleTypeCode,
  );

  if (!vehicleType) {
    // tslint:disable-next-line
    console.log('No vehicle type found for ', arrivalNotepadResult.vehicleTypeCode);
    return null;
  }

  return {
    cargoWeight: arrivalNotepadResult.cargoWeight || previousCargoWeight,
    hazardousGoods: arrivalNotepadResult.hazardousGoods,
    height: arrivalNotepadResult.height || previousHeight,
    length: arrivalNotepadResult.length || previousLength,
    loadingListMessage: arrivalNotepadResult.note || previousLoadingListMessage,
    registrationNumberAlert: {
      appliedArrivalNotepad: true,
      appliedCargoWeight:
        arrivalNotepadResult.cargoWeight !== previousCargoWeight && previousCargoWeight != null,
      appliedHazardousGoods: arrivalNotepadResult.hazardousGoods !== previousHazardousGoods,
      appliedHeight: getAppliedDimension(arrivalNotepadResult.height, previousHeight),
      appliedLength: getAppliedDimension(arrivalNotepadResult.length, previousLength),
      appliedLoadingListMessage:
        arrivalNotepadResult.note !== previousLoadingListMessage && !!arrivalNotepadResult.note,
      appliedTravelledVehicle: false,
      result: null,
      travelledVehicleType: TravelledVehicleType.Vehicle,
      vehicleType: null,
    },
    vehicleType,
  };
}

function* fetchTravelledVehicle(
  customerNumber: number,
  routeCode: string,
  vehicleRegNo: string,
  travelledVehicleType: TravelledVehicleType,
  previousVehicleType: VehicleType | null,
  previousHeight: number | undefined,
  previousLength: number | undefined,
): Generator<any, RegistrationNumberAlertStateChange | null, any> {
  const travelledVehicleResult: TravelledVehicle | null = yield call(
    apiFetchTravelledVehicle,
    customerNumber,
    vehicleRegNo,
  );

  if (!travelledVehicleResult) {
    return null;
  }

  trackEvent({ category: 'General', action: 'TravelledVehicle match' });

  const height = getHeight(
    travelledVehicleType,
    Boolean(previousVehicleType?.useTrailerReg),
    previousHeight,
    travelledVehicleResult.height,
  );

  if (travelledVehicleType === TravelledVehicleType.Trailer) {
    return {
      height,
      registrationNumberAlert: {
        appliedArrivalNotepad: false,
        appliedCargoWeight: false,
        appliedHazardousGoods: false,
        appliedHeight: previousHeight !== height,
        appliedLength: false,
        appliedLoadingListMessage: false,
        appliedTravelledVehicle: false,
        result: travelledVehicleResult,
        travelledVehicleType,
        vehicleType: null,
      },
      vehicleType: null,
    };
  }

  if (!shouldApplyTravelledVehicleAutomatically(previousVehicleType, travelledVehicleResult)) {
    // VehicleType mismatch,
    const vehicleType = yield call(
      apiFetchVehicleType,
      customerNumber,
      routeCode,
      travelledVehicleResult.vehicleTypeCode,
    );

    return {
      registrationNumberAlert: {
        appliedArrivalNotepad: false,
        appliedCargoWeight: false,
        appliedHazardousGoods: false,
        appliedHeight: false,
        appliedLength: false,
        appliedLoadingListMessage: false,
        appliedTravelledVehicle: false,
        result: travelledVehicleResult,
        travelledVehicleType,
        vehicleType,
      },
    };
  } else {
    // Only dimension affected, apply dimensions if they differs from 0 and previous value.
    const appliedLength = getAppliedDimension(travelledVehicleResult.length, previousLength);

    let vehicleType = null;
    if (isFTAndTRMatch(previousVehicleType, travelledVehicleResult)) {
      vehicleType = yield call(
        apiFetchVehicleType,
        customerNumber,
        routeCode,
        travelledVehicleResult.vehicleTypeCode,
      );
    }

    return {
      height,
      length: appliedLength ? travelledVehicleResult.length : previousLength,
      registrationNumberAlert: {
        appliedArrivalNotepad: false,
        appliedCargoWeight: false,
        appliedHazardousGoods: false,
        appliedHeight: previousHeight !== height,
        appliedLength,
        appliedLoadingListMessage: false,
        appliedTravelledVehicle: false,
        result: travelledVehicleResult,
        travelledVehicleType,
        vehicleType,
      },
      vehicleType,
    };
  }
}

export function* handleVehicleRegNoRequest(
  customerNumber: number,
  routeCode: string,
  vehicleRegNo: string,
  travelledVehicleType: TravelledVehicleType,
  previousCargoWeight: number | undefined,
  previousHazardousGoods: boolean | undefined,
  previousVehicleType: VehicleType | null,
  previousHeight: number | undefined,
  previousLength: number | undefined,
  previousLoadingListMessage: string | undefined,
): Generator<any, RegistrationNumberAlertStateChange | null, any> {
  if (vehicleRegNo.length < 2) {
    return null;
  }

  const arrivalNotepadChange: RegistrationNumberAlertStateChange | null = yield call(
    fetchArrivalNotepad,
    customerNumber,
    routeCode,
    vehicleRegNo,
    previousCargoWeight,
    previousHazardousGoods,
    previousHeight,
    previousLength,
    previousLoadingListMessage,
  );

  if (arrivalNotepadChange) {
    return arrivalNotepadChange;
  }

  return yield call(
    fetchTravelledVehicle,
    customerNumber,
    routeCode,
    vehicleRegNo,
    travelledVehicleType,
    previousVehicleType,
    previousHeight,
    previousLength,
  );
}
