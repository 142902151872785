import { FC, useEffect } from 'react';
import { useAuth } from '../../../use-cases/auth/AuthContext';

export const Login: FC = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.login();
  }, [auth]);

  return null;
};
