import styled from '@emotion/styled';
import { animated, useTransition } from '@react-spring/web';
import { Column } from '@stenajs-webui/core';
import { CSSProperties, FC, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { zIndices } from '../../../common/zIndices';
import { getToastIds } from '../../../services/notifications/notificationsReducer';
import { getIsAnyTourRunningOrModalShowing } from '../../../services/tour/tourReducer';
import { Toast } from './Toast';

const ToasterWrapper = styled(Column)<{ hidden: boolean }>`
  display: block;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  position: fixed;
  z-index: ${zIndices.toasterWrapper};
  top: 0;
  right: 0;
  bottom: 0;
  max-height: 100dvh;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: none;
  transition: opacity 100ms ease-in-out;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    pointer-events: ${({ hidden }) => (hidden ? 'none' : 'all')};
  }
`;

const MemoizedToast = memo(Toast);

interface ToasterProps {
  disabled: boolean;
}

export const Toaster: FC<ToasterProps> = memo<ToasterProps>(({ disabled }) => {
  const [refMap] = useState(() => new Map<string, HTMLElement>());
  const toastIds = useSelector(getToastIds);
  const hidden = useSelector(getIsAnyTourRunningOrModalShowing);

  const transitions = useTransition(toastIds, {
    from: {
      transform: 'translateX(100%)',
      opacity: 0.5,
      height: 0,
    },
    enter: (item: string) => async (next: (props: CSSProperties) => Promise<void>) => {
      await next({
        transform: 'translateX(0)',
        opacity: 1,
        height: (refMap.get(item)?.offsetHeight ?? 10) + 8,
      });
    },
    leave: { opacity: 0, height: 0, transform: 'translateX(150%)' },
  });

  return (
    <ToasterWrapper className={'t_toast'} width={340} indent spacing hidden={hidden}>
      {!disabled &&
        transitions((props, item) => (
          <animated.div key={item} style={props}>
            <div ref={ref => ref && refMap.set(item, ref)}>
              <MemoizedToast id={item} />
            </div>
          </animated.div>
        ))}
    </ToasterWrapper>
  );
});
