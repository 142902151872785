import { useTimeoutState } from '../../../common/hooks/useTimeoutState';
import { Row, Txt } from '@stenajs-webui/core';
import { FlatButton, stenaCopy } from '@stenajs-webui/elements';
import { FC } from 'react';

interface ValueTxtProps {
  value: string | null | undefined;
  disableCopyButton?: boolean;
}

export const ValueTxt: FC<ValueTxtProps> = ({ value, disableCopyButton = false }) => {
  if (!value) {
    return <Txt>-</Txt>;
  }

  const txtElement = <Txt wordBreak={'break-word'}>{value}</Txt>;

  if (disableCopyButton) {
    return txtElement;
  }

  return (
    <Row justifyContent={'space-between'} alignItems={'center'}>
      {txtElement}
      <CopyButton getValue={() => value} />
    </Row>
  );
};

interface CopyButtonProps {
  getValue: () => string;
}

export const CopyButton: FC<CopyButtonProps> = ({ getValue }) => {
  const [copyClicked, setCopyClicked] = useTimeoutState(false, 3000);
  return (
    <FlatButton
      leftIcon={stenaCopy}
      success={copyClicked}
      variant={copyClicked ? 'success' : 'normal'}
      onClick={() => {
        navigator.clipboard.writeText(getValue());
        setCopyClicked(true);
      }}
    />
  );
};
