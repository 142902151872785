import { entries, groupBy, sortBy } from 'lodash';
import { GroupedOptionsType } from '../../common-ui/form-fields/Dropdown';
import { DropdownOption } from '../../use-cases/manage/grid/utils/optionTransformers';
import { Route } from '../graphql/fragments/gql/Route';

export const getRoutesGroupedBySeaAreas = (allRoutes: Route[]): Array<[string, Route[]]> =>
  entries(
    groupBy(sortBy(allRoutes, ['routePairCode', 'seaAreaDescription']), 'seaAreaDescription'),
  );

export const getGroupedOptionsBySeaArea = (
  allRoutes: Route[],
): GroupedOptionsType<DropdownOption<Route>> =>
  getRoutesGroupedBySeaAreas(allRoutes).map(([seaAreaKey, routes]) => ({
    label: seaAreaKey,
    value: '',
    options: routes.map(
      (route: Route): DropdownOption<Route> => ({
        data: route,
        label: route.longDescription,
        value: getRouteValue(route),
      }),
    ),
  }));

export const getRouteValue = (route: Route): string => route.code;
