import { FeatureFlag } from '../../services/flags/enum';
import { getFeatureIsEnabled } from '../../services/flags/featureFlags';
import { initializeGA4 } from './ga4';
import { getMaskedPageView, MaskedPageView } from './maskedPageView';

export const initializeAnalytics = (userId: string | undefined) => {
  if (getFeatureIsEnabled(FeatureFlag.GA4)) {
    initializeGA4(userId);
  }
};

export type TrackerCategory =
  | 'AccountMenu'
  | 'Chips'
  | 'Claims'
  | 'Dashboard'
  | 'Details'
  | 'Filter'
  | 'FinancialDocuments'
  | 'Footer'
  | 'GatePhotos'
  | 'General'
  | 'Grid'
  | 'HazardousGoods'
  | 'Help'
  | 'IntermodalUpdate'
  | 'IntermodalCreate'
  | 'IntermodalOverview'
  | 'MultiCreate'
  | 'Navigation'
  | 'NewFeatureTour'
  | 'NoShowLateHandling'
  | 'NotificationCenter'
  | 'Payment'
  | 'Search'
  | 'Settings'
  | 'ShortKeys'
  | 'SimpleCreate'
  | 'Statistics'
  | 'Subscriptions'
  | 'Switch'
  | 'Timetable'
  | 'Toast'
  | 'WelcomeTour';

export interface EventArgs {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: string;
}

export interface TrackerEventArgs extends EventArgs {
  category: TrackerCategory;
}

export const trackEvent = (args: TrackerEventArgs, eventParams?: object) => {
  if (import.meta.env.DEV) {
    console.debug('trackEvent', args);
  }

  if (getFeatureIsEnabled(FeatureFlag.GA4)) {
    const maskedPageView = getMaskedPageView(window.location.pathname);

    gtag('event', args.action, {
      ...eventParams,
      event_category: args.category,
      event_label: args.label,
      value: args.value,
      page_referrer: null,
      ...maskedPageView,
    });
  }
};

export const trackPageViewWithCustomTitle = (maskedPageView: MaskedPageView) => {
  if (getFeatureIsEnabled(FeatureFlag.GA4)) {
    console.debug('page_view', maskedPageView.page_location);
    gtag('event', 'page_view', {
      page_referrer: null,
      ...maskedPageView,
    });
  }
};

export const trackPageView = (path: string) => {
  const maskedPageView = getMaskedPageView(path);
  trackPageViewWithCustomTitle(maskedPageView);
};

export const trackModalView = (name: string) => {
  trackPageView('/modal/' + name.trim());
};
