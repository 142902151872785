import {
  ActionMenuItem,
  ActionMenuItemContent,
  ActionMenuLink,
  stenaCog,
  stenaSignOut,
  stenaUserCircle,
} from '@stenajs-webui/elements';
import { NavBarUserButton } from '@stenajs-webui/panels';
import { FC } from 'react';
import { Link } from '../../../common-ui/Link';
import { useUserDisplayName } from '../../../services/authentication/hooks/useUserName';
import { useUserLoginEmail } from '../../../services/settings/hooks/useAccount';
import { getPortalRoot } from '../../../use-cases/manage/grid/utils/getPortalRoot';
import { routeCreators } from '../../routes/AppRoutes';
import { trackEvent } from '../../tracking/trackerService';

export interface AccountLoggedInProps {
  onLogOut: () => void;
}

const portalRoot = getPortalRoot();

export const AccountLoggedIn: FC<AccountLoggedInProps> = ({ onLogOut }) => {
  const userDisplayName = useUserDisplayName();
  const userLoginEmail = useUserLoginEmail();
  return (
    <NavBarUserButton
      icon={stenaUserCircle}
      className={'t_user_button'}
      portalTarget={portalRoot}
      username={userDisplayName}
      renderItems={() => (
        <>
          <ActionMenuItemContent label={userLoginEmail ?? ''} />
          <Link
            render={props => (
              <ActionMenuLink
                {...props}
                leftIcon={stenaCog}
                label={'Settings'}
                className={'tour_settings_link'}
              />
            )}
            onClick={() => {
              trackEvent({
                category: 'AccountMenu',
                action: 'Navigate to Settings',
              });
            }}
            to={routeCreators.settings()}
          />
          <ActionMenuItem
            onClick={onLogOut}
            className={'t_logout_link'}
            leftIcon={stenaSignOut}
            label={'Log out'}
          />
        </>
      )}
    />
  );
};
