import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useShallowEqualSelector } from '../../../common/hooks/useShallowEqualSelector';
import { useShowConfirmationWhenLeaving } from '../../../common/hooks/useShowConfirmationWhenLeaving';
import { usePageTitle } from '../../../common/routes/PageTitle';
import {
  getSettingsEditedFormState,
  getSettingsFormIsInitialized,
  getSettingsFormStatus,
  getSettingsInitialFormState,
  resetSettingsForm,
  SettingsFormState,
  SettingsPart,
} from '../../../services/settings/settingsReducer';
import { updateSettingsThunk } from '../../../services/settings/thunk';
import { useAppDispatch } from '../../../store/appDispatch';
import { StoreState } from '../../../store/storeState';
import { getFieldsFromSettingsPart } from '../utils/getFieldsFromSettingsPart';
import { isEqualFormStates } from '../utils/isEqualFormStates';
import { FormStatus } from '../utils/resolveSettingsFormStatus';

export interface UseSettingsFormState {
  formIsEdited: boolean;
  onSubmit: () => void;
  formStatus: FormStatus;
  showSuccess: boolean;
}

export const getChangedFormFields = (
  initialFormState: SettingsFormState | undefined,
  editedFormState: SettingsFormState | undefined,
): string[] => {
  if (!initialFormState || !editedFormState) {
    return [];
  }
  return (Object.keys(initialFormState) as Array<keyof SettingsFormState>).reduce<string[]>(
    (changedValues, key) => {
      if (!isEqual(initialFormState[key], editedFormState[key])) {
        return [...changedValues, key];
      }
      return changedValues;
    },
    [],
  );
};

export const useSettingsInitialized = () => useSelector(getSettingsFormIsInitialized);

const mapState = (state: StoreState, part: SettingsPart) => {
  const editedFormState = getSettingsEditedFormState(state);
  const initialFormState = getSettingsInitialFormState(state);
  const status = getSettingsFormStatus(state, part);

  return {
    isEdited: !isEqualFormStates(
      getFieldsFromSettingsPart(initialFormState, part),
      getFieldsFromSettingsPart(editedFormState, part),
    ),
    status,
  };
};

const transformSettingsPartToPageTitle = (part: SettingsPart): string => {
  switch (part) {
    case 'account':
      return 'Account';
    case 'filter':
      return 'Filters';
    case 'favourites':
      return 'Favourites';
    case 'dateFormat':
      return 'Date format';
  }
};

export const useSettingsFormState = (part: SettingsPart): UseSettingsFormState => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  usePageTitle(transformSettingsPartToPageTitle(part));

  const dispatch = useAppDispatch();

  const { isEdited, status } = useShallowEqualSelector(
    useCallback((state: StoreState) => mapState(state, part), [part]),
  );

  const [showStatus, setShowStatus] = useState(false);

  const submit = async () => {
    clearTimeout(timeoutRef.current);
    setShowStatus(true);

    await dispatch(updateSettingsThunk({ part })).unwrap();
    timeoutRef.current = setTimeout(() => {
      setShowStatus(false);
    }, 3000);
  };

  useShowConfirmationWhenLeaving(isEdited);

  useEffect(
    () => () => {
      dispatch(resetSettingsForm());
    },
    [dispatch],
  );

  return {
    formIsEdited: isEdited,
    onSubmit: submit,
    formStatus: status,
    showSuccess: showStatus && status === 'success',
  };
};
