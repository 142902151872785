import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useBookingDetails } from '../graphql/useBookingDetails';
import { PageTitle } from '../routes/PageTitle';
import { ModalLoader } from './ModalLoader';
import { FreightPortalBaseModal } from './FreightPortalBaseModal';
import { BookingCancelPanel } from '../../use-cases/manage/cancel/components/BookingCancelPanel';
import { DRAGGABLE_HANDLE_CLASSNAME } from '@stenajs-webui/modal';
import { Permission } from '../../services/authentication/utils/authUtils';
import { useRouteModal } from './useRouteModal';
import { PermissionGateModal } from '../home/components/PermissionGateModal';

export const BookingCancelModal: FC = () => {
  const { onClose } = useRouteModal();
  const { bookingNo: bookingNoString } = useParams<'bookingNo'>();
  const bookingNo = Number(bookingNoString);
  const { booking, loading } = useBookingDetails(bookingNo);

  return (
    <PermissionGateModal permission={Permission.EditBookings}>
      <FreightPortalBaseModal
        aria-label={'Cancel booking'}
        isOpen
        onRequestClose={onClose}
        shouldCloseOnOverlayClick
        draggable
      >
        <PageTitle title={`Cancel ${bookingNo}`} />
        {loading || !booking ? (
          <ModalLoader />
        ) : (
          <BookingCancelPanel
            className={DRAGGABLE_HANDLE_CLASSNAME}
            booking={booking}
            onClose={onClose}
            onFail={onClose}
            trackerCategory={'Grid'}
          />
        )}
      </FreightPortalBaseModal>
    </PermissionGateModal>
  );
};
