import { sumBy } from 'lodash';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { formatDate } from '../../../../common/utils/dates/dateUtils';
import { ColumnId } from '../../../../gql/graphql';
import { theme } from '../../../../themes/theme';
import { cssColor } from '@stenajs-webui/theme';
import { TextProps } from '@stenajs-webui/core';

export interface SearchResultColumn {
  getValue: (item: BasicBooking) => string | number;
  columnId: ColumnId;
  remWidth: number;
  searchable: Searchable;
}

export enum Searchable {
  Yes,
  No,
  ExactMatch,
}
export const searchResultColumnRemWidth = {
  [ColumnId.BookingNo]: 8,
  [ColumnId.RouteCode]: 5.5,
  [ColumnId.DepartureDate]: 9,
  [ColumnId.DepartureTime]: 5,
  [ColumnId.VehicleRegNo]: 17.5,
  [ColumnId.TrailerRegNo]: 17.5,
  [ColumnId.CustomerReference]: 17.5,
};

export const getSearchResultColumns = (dateFormat: string): SearchResultColumn[] => {
  return [
    {
      getValue: item => item.bookingNo,
      columnId: ColumnId.BookingNo,
      remWidth: searchResultColumnRemWidth[ColumnId.BookingNo],
      searchable: Searchable.ExactMatch,
    },
    {
      getValue: item => item.sailing.route.code,
      columnId: ColumnId.RouteCode,
      remWidth: searchResultColumnRemWidth[ColumnId.RouteCode],
      searchable: Searchable.No,
    },
    {
      getValue: item => formatDate(item.sailing.departureDate, dateFormat),
      columnId: ColumnId.DepartureDate,
      remWidth: searchResultColumnRemWidth[ColumnId.DepartureDate],
      searchable: Searchable.No,
    },
    {
      getValue: item => item.sailing.departureTime,
      columnId: ColumnId.DepartureTime,
      remWidth: searchResultColumnRemWidth[ColumnId.DepartureTime],
      searchable: Searchable.No,
    },
    {
      getValue: item => item.vehicleRegNo,
      columnId: ColumnId.VehicleRegNo,
      remWidth: searchResultColumnRemWidth[ColumnId.VehicleRegNo],
      searchable: Searchable.Yes,
    },
    {
      getValue: item => item.trailerRegNo,
      columnId: ColumnId.TrailerRegNo,
      remWidth: searchResultColumnRemWidth[ColumnId.TrailerRegNo],
      searchable: Searchable.Yes,
    },
    {
      getValue: item => item.customerReference,
      columnId: ColumnId.CustomerReference,
      remWidth: searchResultColumnRemWidth[ColumnId.CustomerReference],
      searchable: Searchable.Yes,
    },
  ];
};

export const getSearchColumnsWidth = (searchColumns: SearchResultColumn[]): number =>
  sumBy(searchColumns, column => column.remWidth) + theme.metrics.indent * 2;

export const getBookingSearchTableRowCellTextStyle = (
  column: SearchResultColumn,
): TextProps['style'] | undefined => {
  if (column.columnId === ColumnId.BookingNo) {
    return { color: cssColor('--core-blue'), textDecoration: 'underline' };
  }

  return undefined;
};
