import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { CardHeader, CardHeaderProps, CloseButton, Icon } from '@stenajs-webui/elements';
import { cssColor } from '@stenajs-webui/theme';
import { FC } from 'react';

export interface PopoverTitleProps
  extends Pick<CardHeaderProps, 'contentLeft' | 'contentAfterHeading' | 'className'> {
  onClose?: () => void;
  text?: string;
  icon?: IconDefinition;
  iconColor?: string;
  closeButtonClassName?: string;
}

export const PopoverTitle: FC<PopoverTitleProps> = ({
  onClose,
  icon,
  iconColor = cssColor('--lhds-color-ui-700'),
  contentLeft,
  closeButtonClassName,
  ...props
}) => (
  <CardHeader
    {...props}
    contentLeft={
      contentLeft ? (
        contentLeft
      ) : Icon ? (
        <Icon icon={icon} color={iconColor} size={16} />
      ) : undefined
    }
    contentRight={onClose && <CloseButton className={closeButtonClassName} onClick={onClose} />}
  />
);
