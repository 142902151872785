import { graphql } from '../../../gql';

export const calculatePriceQuery = graphql(/* GraphQL */ `
  query CalculatePriceQuery($bookings: [CalculatePriceInput!]!) {
    viewer {
      payment {
        calculatePriceForBookings(input: $bookings) {
          ...CalculatePriceResult
        }
      }
    }
  }
`);
