import { ActionMatchingPattern } from '@redux-saga/types';
import { ActionPattern, delay, fork, race, take } from 'redux-saga/effects';

export const bufferTime = <
  P extends ActionPattern,
  A extends ActionMatchingPattern<P>,
  PFlush extends ActionPattern,
>(
  ms: number,
  pattern: P,
  worker: (action: A) => any,
  mergeFn: (a1: A, a2: A) => A,
  flushPattern?: PFlush,
) =>
  fork(function* () {
    while (true) {
      let action: A = yield take(pattern);

      while (true) {
        const { next, timeout, flush } = yield race({
          timeout: delay(ms),
          next: take(pattern),
          flush: take(flushPattern),
        });

        if (flush || timeout) {
          yield fork(worker, action);
          break;
        }

        action = mergeFn(action, next);
      }
    }
  });
