import { exhaustSwitchCase } from '@stenajs-webui/core';
import { pick } from 'lodash';
import { SettingsFormState, SettingsPart } from '../../../services/settings/settingsReducer';

export const getFieldsFromSettingsPart = (
  formState: SettingsFormState | undefined,
  part: SettingsPart,
): Partial<SettingsFormState> => {
  switch (part) {
    case 'account':
      return pick(
        formState,
        'accountEmail',
        'accountFirstName',
        'accountLastName',
        'accountLoginEmail',
        'accountPhone',
        'accountSecondEmail',
        'accountSendBookingConfirmationAsEmail',
      );
    case 'dateFormat':
      return pick(formState, 'dateFormat');
    case 'filter':
      return pick(
        formState,
        'filterDateInterval',
        'filterFilterStates',
        'filterRoutes',
        'dashboardNotCheckedInTime',
        'dashboardShowNotCheckedIn',
        'dashboardShowPendingApproval',
        'dashboardShowDashboardOnScroll',
        'dashboardShowDateOverview',
        'dashboardShowUnaccompaniedUnits',
      );
    case 'favourites':
      return pick(
        formState,
        'bookCustomer',
        'bookDate',
        'bookDefaultRoute',
        'bookDefaultVehicleType',
        'favouriteRoutes',
        'favouriteVehicleTypes',
      );
    default:
      return exhaustSwitchCase(part, {});
  }
};
