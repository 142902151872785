import { Component, ErrorInfo, ReactNode } from 'react';
import { trackException } from '../tracking/appInsight';

const changedArray = (a: Array<unknown> = [], b: Array<unknown> = []) =>
  a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]));

interface Props {
  resetKeys?: unknown[];
  children?: ReactNode;
  onError?: (error: Error, componentStack: string) => void;
  fallback: (error: Error) => ReactNode;
}

interface State {
  error: Error | null;
}

const initialState: State = { error: null };

export class ErrorBoundary extends Component<Props, State> {
  state: State = initialState;

  reset() {
    this.setState(initialState);
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.setState({ error });
    trackException({
      error,
      properties: {
        ...info,
      },
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { error } = this.state;
    const { resetKeys } = this.props;

    if (
      error !== null &&
      prevState.error !== null &&
      changedArray(prevProps.resetKeys, resetKeys)
    ) {
      this.reset();
    }
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (error) {
      return fallback(error);
    }

    return children;
  }
}
