import { RegistrationNumberAlertFormState } from '../../../services/update-form/registrationNumberAlert';
import { BookingFormStateKeys } from '../../../services/update-form/updateFormReducer';
import { getKeysToChangeForArrivalNotepad } from '../../registrationNumberAlert/utils/arrivalNotepadUtil';

export const getValuesForUpdateForm = (
  registrationNumberAlert: RegistrationNumberAlertFormState,
): BookingFormStateKeys => {
  const keys = getKeysToChangeForArrivalNotepad(registrationNumberAlert);
  return keys.map(key => key);
};
