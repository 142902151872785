import {
  addDays,
  addMonths,
  addWeeks,
  parseISO,
  startOfToday,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { FilterDateDate } from '../../../services/booking-filter/filterReducer';
import { assert } from '../../asserts/assert';
import { formatServerDate, isValidServerDate } from './dateUtils';

export const getAbsoluteFilterDate = (date: Date): FilterDateDate => ({
  type: 'absolute',
  dateString: formatServerDate(date),
});

export const getRelativeFilterDate = (value: FilterDateString): FilterDateDate => ({
  type: 'relative',
  value,
});

export type FilterDateString =
  | 'TODAY'
  | 'TOMORROW'
  | 'YESTERDAY'
  | 'ONE_WEEK_BACK'
  | 'ONE_MONTH_BACK'
  | 'ONE_WEEK_AHEAD'
  | 'ONE_MONTH_AHEAD';

export const parseFilterDate = (value: FilterDateString | string): FilterDateDate => {
  switch (value) {
    case 'TODAY':
    case 'TOMORROW':
    case 'YESTERDAY':
    case 'ONE_WEEK_BACK':
    case 'ONE_MONTH_BACK':
    case 'ONE_WEEK_AHEAD':
    case 'ONE_MONTH_AHEAD':
      return { type: 'relative', value: value };
    default: {
      assert(isValidServerDate(value), 'Invalid filter date');
      return { type: 'absolute', dateString: value };
    }
  }
};

export const resolveFilterDateString = (
  value: FilterDateString,
  today: Date = startOfToday(),
): Date => {
  switch (value) {
    case 'TODAY':
      return today;
    case 'TOMORROW':
      return addDays(today, 1);
    case 'YESTERDAY':
      return subDays(today, 1);
    case 'ONE_WEEK_BACK':
      return subWeeks(today, 1);
    case 'ONE_MONTH_BACK':
      return subMonths(today, 1);
    case 'ONE_WEEK_AHEAD':
      return addWeeks(today, 1);
    case 'ONE_MONTH_AHEAD':
      return addMonths(today, 1);
    default:
      return value;
  }
};

export const getFilterDate = (date: FilterDateDate, today: Date = startOfToday()): Date => {
  if (date.type === 'absolute') {
    return parseISO(date.dateString);
  } else {
    return resolveFilterDateString(date.value, today);
  }
};

export const isValidFilterDate = (date: FilterDateDate): boolean => {
  if (date.type === 'relative') {
    return true;
  } else {
    return isValidServerDate(date.dateString);
  }
};

export const isFilterDateEqual = (from: FilterDateDate, to: FilterDateDate): boolean => {
  if (from.type === 'absolute' && to.type === 'absolute') {
    return from.dateString === to.dateString;
  } else if (from.type === 'relative' && to.type === 'relative') {
    return from.value === to.value;
  }
  return false;
};
