import { BookingsSortColumn } from '../gql/graphql';
import { FilterState } from '../services/booking-filter/filterReducer';
import { browserHistory } from '../store/utils/history';
import {
  urlReplaceSearchString,
  urlGetSearchString,
  getFilterFromLegacyUrlHash,
  urlDecodeObject,
} from '../store/utils/urlModifier';
import { URLHashData } from './booking-filters/types';
import { parseManagePageUrl, stringifyManagePageUrl } from './booking-filters/urlFilterState';
import { isOnManagePage } from './routes/routeUtils';

export const loadFilter = (): Partial<URLHashData> => {
  if (!isOnManagePage(browserHistory.location)) {
    return { filter: undefined, sorting: undefined };
  }

  const hashString = getFilterFromLegacyUrlHash(browserHistory);

  const oldFilter = urlDecodeObject(hashString);

  if (oldFilter) {
    return oldFilter;
  }

  const searchString = urlGetSearchString(browserHistory);

  return parseManagePageUrl(searchString);
};

export const saveURLFilterData = (filter: FilterState, sorting: BookingsSortColumn) => {
  urlReplaceSearchString(browserHistory, stringifyManagePageUrl(filter, sorting));
};
