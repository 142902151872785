import { sum } from 'lodash';
import {
  IdsRecord,
  UrgentMessageNotification,
} from '../../../services/notifications/notificationsReducer';
import { NotificationsGroupedByDate } from './getSortedNotifications';

export const getUnreadCount = (
  notificationsGroupedByDates: NotificationsGroupedByDate<UrgentMessageNotification>[],
  readIds: IdsRecord,
) => {
  return sum(notificationsGroupedByDates.map(not => filterUnreads(not.notifications, readIds)));
};

const filterUnreads = (nots: UrgentMessageNotification[], readIds: IdsRecord): number =>
  nots.filter(n => !isRead(n, readIds)).length;

export const isRead = (notification: UrgentMessageNotification, readIds: IdsRecord): boolean =>
  !!readIds[notification.message.id];
