import styled from '@emotion/styled';
import { FC } from 'react';
import { breakpoints } from '../../../../../themes/defaultTheme';
import { blackTableBorder, grayTableBorder } from '../../../config/common';
import { PaymentViewArticlesResult } from '../../../graphql/gql/PaymentViewBooking';
import { BasePaymentGrid } from '../BasePaymentGrid';
import { PaymentTableArticleRows } from './PaymentTableArticleRows';
import { PaymentTableHeader } from './PaymentTableHeader';
import { PaymentTableTotalArticleRows } from './PaymentTableTotalArticleRows';

interface Props {
  articlesResult: PaymentViewArticlesResult;
  warningIds: number[];
}

const Grid = styled(BasePaymentGrid)`
  grid-template-columns: [ex_vat] 1fr [vat_amount] minmax(auto, 1fr) [vat_rate] minmax(auto, 1fr) [inc_vat] minmax(
      auto,
      1fr
    );

  .total {
    border-top: 0;
  }

  .total.cell-heading {
    border-top: ${blackTableBorder};
    border-bottom: 0;
  }

  .article,
  .vat_amount,
  .vat_rate,
  .inc_vat,
  .ex_vat {
    grid-column-end: span 1;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .article {
    display: none;
    grid-column-start: article;
    align-items: flex-start;
  }

  .vat_amount {
    grid-column-start: vat_amount;
  }

  .vat_rate {
    grid-column-start: vat_rate;
  }

  .inc_vat {
    grid-column-start: inc_vat;
  }

  .ex_vat {
    grid-column-start: ex_vat;
    align-items: flex-start;
  }

  .cell.no-heading {
    border-top: ${grayTableBorder};
    padding: 12px 8px;
  }

  .cell-heading {
    border-top: ${grayTableBorder};
    grid-column: 1 / -1;
    padding: 12px 8px 4px;
  }

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns:
      [article] 1fr [ex_vat] minmax(auto, 1fr) [vat_amount] minmax(auto, 1fr) [vat_rate] minmax(
        auto,
        1fr
      )
      [inc_vat] minmax(auto, 1fr);

    .article,
    .vat_amount,
    .vat_rate,
    .inc_vat,
    .ex_vat {
      justify-content: start;
    }

    .article {
      display: flex;
    }

    .ex_vat {
      align-items: flex-end;
    }

    .cell {
      border-top: ${grayTableBorder};
    }

    .total {
      border-top: ${blackTableBorder};
    }

    .cell-heading {
      display: none;
    }
  }
`;

export const BookingPaymentArticlesTable: FC<Props> = ({ articlesResult, warningIds }) => (
  <>
    <Grid>
      <PaymentTableHeader />

      {articlesResult.articles.map(article => (
        <PaymentTableArticleRows article={article} key={article.articleCode} />
      ))}

      <PaymentTableTotalArticleRows articlesResult={articlesResult} warningIds={warningIds} />
    </Grid>
  </>
);
