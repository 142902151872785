import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { revertChangesUpdateForm } from '../../../services/update-form/updateFormActions';
import { FormInstanceId } from '../../../services/update-form/updateFormReducer';

export const useUpdateFormRevertChanges = ({ bookingNo, formId }: FormInstanceId) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(revertChangesUpdateForm({ bookingNo, formId }));
  }, [dispatch, bookingNo, formId]);
};
