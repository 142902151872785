import { Column } from '@stenajs-webui/core';
import { PropsWithChildren } from 'react';
import { globalContentWrapperIndent } from '../globalStyles';

interface BookingDetailsModalWrapperProps extends PropsWithChildren {
  maxWidth?: number;
}

export const BookingDetailsModalWrapper = ({
  children,
  maxWidth = 1408,
}: BookingDetailsModalWrapperProps) => {
  return (
    <Column
      indent={globalContentWrapperIndent}
      spacing={3}
      minHeight={560}
      width={['100%', 448, 736, null, maxWidth]}
      maxWidth={'100%'}
      gap={2}
    >
      {children}
    </Column>
  );
};
