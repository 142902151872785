import { AnyAction, isAnyOf } from '@reduxjs/toolkit';
import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchSuggestedJourney } from '../../../common/api';
import { IntermodalJourneyResult } from '../../../common/graphql/fragments/gql/IntermodalBooking';
import {
  finishFetchingJourney,
  getCreateIntermodalBookingFormState,
  modifyCreateIntermodalBookingGood,
  setCreateFormValue,
  setJourneyResultFailure,
  setJourneyResultSuccess,
  startFetchingJourney,
} from '../createIntermodalReducer';

import { validateJourneyIntermodalFields } from '../validation/validators';
import { createIntermodalBookingThunk } from '../thunks/createIntermodalBookingThunk';
import { showBookingResultNotification } from '../../create/sagas/handleSubmitSagas';

type GetSuggestedJourneyTriggerAction = ReturnType<
  typeof setCreateFormValue | typeof modifyCreateIntermodalBookingGood
>;

export function* watchIntermodalCreateFormSaga() {
  yield all([
    takeLatest(
      (action: AnyAction) =>
        isAnyOf(setCreateFormValue, modifyCreateIntermodalBookingGood)(action) &&
        isValueRequiredForJourney(action),
      getSuggestedJourneySaga,
    ),
    takeEvery(createIntermodalBookingThunk.fulfilled.match, showIntermodalCreateNotifications),
  ]);
}

function* showIntermodalCreateNotifications(
  action: ReturnType<typeof createIntermodalBookingThunk.fulfilled>,
) {
  yield showBookingResultNotification({
    type: 'intermodal-created',
    messages: action.payload.messages.map(m => m.description),
    booking: action.payload.booking,
    warnings: action.payload.warnings.map(w => w.description),
  });
}

function* getSuggestedJourneySaga() {
  const formState: ReturnType<typeof getCreateIntermodalBookingFormState> = yield select(
    getCreateIntermodalBookingFormState,
  );
  const validationResult = validateJourneyIntermodalFields(formState);

  if (!validationResult.success) {
    yield put(setJourneyResultFailure(validationResult.error.validationErrors));
    return;
  }

  try {
    yield put(startFetchingJourney());
    yield delay(2000);

    const journeyResult: IntermodalJourneyResult | null = yield call(
      fetchSuggestedJourney,
      validationResult.value,
    );

    yield put(
      setJourneyResultSuccess({
        legs: journeyResult?.journey?.legs ?? null,
        result: {
          errors: journeyResult?.errors ?? [],
          warnings: journeyResult?.warnings ?? [],
          messages: journeyResult?.messages ?? [],
          success: journeyResult?.success ?? false,
        },
      }),
    );
  } finally {
    yield put(finishFetchingJourney());
  }
}

function isValueRequiredForJourney(action: GetSuggestedJourneyTriggerAction): boolean {
  if (setCreateFormValue.match(action)) {
    return (
      'departureTime' in action.payload ||
      'departureDate' in action.payload ||
      'route' in action.payload ||
      'length' in action.payload ||
      'width' in action.payload ||
      'searchByDeparture' in action.payload ||
      'height' in action.payload ||
      'vehicleType' in action.payload ||
      'noOfPlugins' in action.payload ||
      'emptyWeight' in action.payload
    );
  }
  return 'weight' in action.payload.updatedFields || 'goodsType' in action.payload.updatedFields;
}
