import { useQuery } from '@apollo/client';
import { GroupedOptionsType } from '../../common-ui/form-fields/Dropdown';
import { DropdownOption } from '../../use-cases/manage/grid/utils/optionTransformers';
import { getGroupedVehicleTypeOptionsByNoOfDrivers } from '../utils/vehicleTypesUtils';
import { allVehicleTypesForCustomerAndRouteQuery } from './allVehicleTypesForCustomerAndRouteQuery';
import { VehicleType } from './fragments/gql/VehicleType';
import { QueryResult } from './types/QueryResult';

export const useVehicleTypesForCustomerAndRouteAsOptions = (
  customerNo: number | null,
  routeCode: string | null,
  skip: boolean = false,
): QueryResult<GroupedOptionsType<DropdownOption<VehicleType>>> => {
  const { data, loading, error } = useQuery(allVehicleTypesForCustomerAndRouteQuery, {
    variables: {
      customerNo: customerNo!,
      routeCode: routeCode!,
    },
    skip: !customerNo || !routeCode || skip,
  });
  const vehicleTypes = getGroupedVehicleTypeOptionsByNoOfDrivers(
    data?.viewer?.vehicleType?.allForCustomerNoAndRouteCode?.vehicleTypes ?? [],
  );
  return [vehicleTypes, loading, error];
};
