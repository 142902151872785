import { graphql } from '../../gql';

export const searchBookingsQuery = graphql(/*GraphQL*/ `
  query SearchBookingsQuery($query: String!) {
    viewer {
      search {
        byQuery(query: $query) {
          activeTransports {
            count
            items {
              ...BasicBooking
            }
          }
          transportHistory {
            count
            items {
              ...BasicBooking
            }
          }
        }
      }
    }
  }
`);
