import { useQuery } from '@apollo/client';
import { intermodalBookingQuery } from '../graphql/intermodalQuery';
import { IntermodalDetailsQueryQuery } from '../../../gql/graphql';

export const useIntermodalBookingsDetails = (
  bookingNo: number,
  onCompleted: (data: IntermodalDetailsQueryQuery) => void,
) => {
  const { data, error, loading, refetch } = useQuery(intermodalBookingQuery, {
    fetchPolicy: 'network-only',
    variables: { bookingNo },
    onCompleted,
  });
  return {
    booking: data?.viewer.intermodalBookings.booking,
    error: error?.graphQLErrors?.[0],
    loading,
    refetch,
  };
};
