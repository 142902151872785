import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GroupedOptionsType } from '../../common-ui/form-fields/Dropdown';
import { DropdownOption } from '../../use-cases/manage/grid/utils/optionTransformers';
import { getGroupedOptionsBySeaArea } from '../utils/getGroupedOptionsBySeaArea';
import { allRoutesQuery } from './allRoutesQuery';
import { Route } from './fragments/gql/Route';
import { AllRoutesQuery } from './gql/AllRoutesQuery';
import { QueryResult } from './types/QueryResult';

export const useAllRoutesAsOptions = (
  skip: boolean = false,
): QueryResult<GroupedOptionsType<DropdownOption<Route>>> => {
  const { data, loading, error } = useQuery<AllRoutesQuery>(allRoutesQuery, {
    skip,
  });

  return useMemo(() => {
    return [getGroupedOptionsBySeaArea(data?.viewer?.routes?.allRoutes ?? []), loading, error];
  }, [data, error, loading]);
};
