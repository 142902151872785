import { maxDate, maxTime, minDate, minTime } from '../../../../common/dates';
import { getFilterDate } from '../../../../common/utils/dates/dateFilter';
import { formatServerDate } from '../../../../common/utils/dates/dateUtils';
import { formatSearchQueryForServerString } from '../../../../common/utils/stringUtil';
import { BookingsFilter, FilterDate as GQLFilterDate, FilterTime } from '../../../../gql/graphql';
import { FilterDate, FilterState } from '../../../../services/booking-filter/filterReducer';

const transformFilterDate = (date: FilterDate): GQLFilterDate => ({
  from: date.from ? formatServerDate(getFilterDate(date.from)) : formatServerDate(minDate),
  to: date.to ? formatServerDate(getFilterDate(date.to)) : formatServerDate(maxDate),
});

const transformFilterTime = (time: FilterTime): FilterTime => ({
  end: time.end ? time.end : maxTime,
  start: time.start ? time.start : minTime,
});

export const transformFilterState = (state: FilterState): BookingsFilter => ({
  cargo: state.cargoIds,
  customerCustomsStatus: state.customerCustomsStatusIds,
  customerIds: state.customerIds,
  date: transformFilterDate(state.date),
  filterStates: state.filterStates,
  query: formatSearchQueryForServerString(state.query),
  routeCodes: state.routeCodeIds,
  time: transformFilterTime(state.time),
  vehicleIds: state.vehicleIds,
});
