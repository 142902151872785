import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  stenaAnimals,
  stenaHazardous,
  stenaPlugin,
  stenaSailingOnQuay,
  stenaSailingTrain,
  stenaTag,
  stenaTags,
  stenaUserMultiple,
} from '@stenajs-webui/elements';
import { exhaustSwitchCaseElseNull } from '../../../../common/utils/ExhaustSwitchCase';
import { SpecialProperty } from '../../../../gql/graphql';

export interface SpecialBookingIconMeta {
  icon: IconDefinition;
  description: string;
}

export const ANIMALS_ICON = stenaAnimals;
export const ARRIVAL_NOTEPAD_ICON = stenaSailingOnQuay;
export const DRIVER_ICON = stenaUserMultiple;
export const HAZARDOUS_GOODS_ICON = stenaHazardous;
export const LOADING_PREFERENCE_ICON = stenaTag;
export const PLUG_IN_ICON = stenaPlugin;
export const SEVERAL_ICON = stenaTags;
export const INTERMODAL_ICON = stenaSailingTrain;

export const resolveSpecialPropertyIcon = (specialProperty: SpecialProperty) => {
  switch (specialProperty) {
    case SpecialProperty.Animals:
      return ANIMALS_ICON;
    case SpecialProperty.ArrivalNotepad:
      return ARRIVAL_NOTEPAD_ICON;
    case SpecialProperty.Driver:
      return DRIVER_ICON;
    case SpecialProperty.HazardousGoods:
      return HAZARDOUS_GOODS_ICON;
    case SpecialProperty.LoadingPreference:
      return LOADING_PREFERENCE_ICON;
    case SpecialProperty.PlugIn:
      return PLUG_IN_ICON;
    case SpecialProperty.Several:
      return SEVERAL_ICON;
    case SpecialProperty.Intermodal:
      return INTERMODAL_ICON;
    default:
      return exhaustSwitchCaseElseNull(specialProperty);
  }
};

export const resolveSpecialPropertyDescription = (specialProperty: SpecialProperty) => {
  switch (specialProperty) {
    case SpecialProperty.Animals:
      return 'Animals';
    case SpecialProperty.ArrivalNotepad:
      return 'Unit on quay';
    case SpecialProperty.Driver:
      return 'Additional drivers';
    case SpecialProperty.HazardousGoods:
      return 'Hazardous goods';
    case SpecialProperty.LoadingPreference:
      return 'Loading preference';
    case SpecialProperty.PlugIn:
      return 'Temperature plugins';
    case SpecialProperty.Several:
      return 'Several';
    case SpecialProperty.Intermodal:
      return 'Intermodal';
    default:
      return exhaustSwitchCaseElseNull(specialProperty);
  }
};

export const resolveBookingSpecialPropertyIconIconAndDescription = (
  bookingSpecialProperties: SpecialProperty[],
): SpecialBookingIconMeta | null => {
  if (bookingSpecialProperties.length === 0) {
    return null;
  }

  const icon =
    bookingSpecialProperties.length === 1
      ? resolveSpecialPropertyIcon(bookingSpecialProperties[0])
      : resolveSpecialPropertyIcon(SpecialProperty.Several);
  const description = bookingSpecialProperties.map(resolveSpecialPropertyDescription).join(', ');

  if (!icon || !description) {
    return null;
  }

  return {
    icon,
    description,
  };
};
