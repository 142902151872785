import hotkeys from 'hotkeys-js';
import { useEffect } from 'react';
import { Hotkeys } from '../utils/hotkeys';
import { useLatest } from '../../hooks/useLatest';

export function useHotkeys(keys: Hotkeys, callback: (event: KeyboardEvent) => boolean | void) {
  const callbackRef = useLatest(callback);

  useEffect(() => {
    // By default, hotkeys are filtered when inside an input/textarea. Here, we always trigger the hotkey.
    if (keys === Hotkeys.BookingSearch) {
      hotkeys.filter = () => true;
    }

    const callback = (event: KeyboardEvent) => {
      if (callbackRef.current) {
        callbackRef.current(event);
      }
    };

    hotkeys(keys, callback);

    return () => hotkeys.unbind(keys, callback);
  }, [callbackRef, keys]);
}
