import { graphql } from '../../../gql';

export const goodsTypesQuery = graphql(/* GraphQL */ `
  query GoodsTypesQuery {
    viewer {
      goodsTypes {
        all {
          id
          description
        }
      }
    }
  }
`);
