import {
  stenaBusinessClaim,
  stenaBusinessInvoice,
  stenaBusinessNslh,
  stenaCalendarManage,
  stenaClock,
  stenaCog,
  stenaHelp,
  stenaSailingShip,
  stenaSailingTrain,
  stenaStatisticsBar,
} from '@stenajs-webui/elements';
import { SidebarMenuHeading, SidebarMenuLink, SidebarMenuLinkProps } from '@stenajs-webui/panels';
import cx from 'classnames';
import { FC } from 'react';
import { Location, NavLink } from 'react-router-dom';
import { Permission } from '../../../services/authentication/utils/authUtils';
import { FeatureFlag } from '../../../services/flags/enum';
import { getFeatureIsEnabled } from '../../../services/flags/featureFlags';
import { routeCreators } from '../../routes/AppRoutes';
import { useHasPermission } from '../../statistics/useHasPermission';
import { trackEvent } from '../../tracking/trackerService';

interface LinkRendererProps {
  location: Location;
  onRequestClose: () => void;
  rail?: boolean;
}

export const SidebarTopItems: FC<LinkRendererProps> = props => {
  const getHasPermission = useHasPermission();

  return (
    <>
      <SidebarMenuHeading label={'Freight Portal'} />
      <FreightPortalSidebarMenuLink
        {...props}
        label={'Manage'}
        leftIcon={stenaCalendarManage}
        className={'t_manage_link'}
        to={routeCreators.bookings()}
      />
      {getHasPermission(Permission.EditBookings) && (
        <FreightPortalSidebarMenuLink
          {...props}
          label={'Book'}
          leftIcon={stenaSailingShip}
          className={'t_book_link'}
          to={routeCreators.create()}
        />
      )}
      {getHasPermission(Permission.ViewIntermodalBookings) &&
        getFeatureIsEnabled(FeatureFlag.IntermodalBook) && (
          <FreightPortalSidebarMenuLink
            {...props}
            label={'Intermodal'}
            leftIcon={stenaSailingTrain}
            className={'t_intermodal_link'}
            to={routeCreators.intermodal()}
          />
        )}
      {getHasPermission(Permission.Statistics) && getFeatureIsEnabled(FeatureFlag.Statistics) && (
        <FreightPortalSidebarMenuLink
          {...props}
          label={'Statistics'}
          leftIcon={stenaStatisticsBar}
          className={'t_statistics_link'}
          to={routeCreators.statistics()}
        />
      )}
      <SidebarMenuHeading label={'Administration'} />
      {getHasPermission(Permission.Invoices) &&
        getFeatureIsEnabled(FeatureFlag.FinancialDocuments) && (
          <FreightPortalSidebarMenuLink
            {...props}
            label={'Financial documents'}
            leftIcon={stenaBusinessInvoice}
            className={'t_financialDocuments_link'}
            to={routeCreators.financialDocuments()}
          />
        )}
      {getFeatureIsEnabled(FeatureFlag.NoShowLateHandling) && (
        <FreightPortalSidebarMenuLink
          {...props}
          label={'No show & Late handling'}
          leftIcon={stenaBusinessNslh}
          className={'t_noShowLateHandling_link'}
          to={routeCreators.noShowLateHandling()}
        />
      )}
      <FreightPortalSidebarMenuLink
        {...props}
        label={'Claims'}
        leftIcon={stenaBusinessClaim}
        className={'t_claims_link'}
        to={routeCreators.claimsPortal()}
      />
    </>
  );
};

interface FreightPortalSidebarMenuLinkProps
  extends LinkRendererProps,
    Pick<SidebarMenuLinkProps, 'leftIcon' | 'label'> {
  title?: string;
  className: string;
  to: string;
}

const FreightPortalSidebarMenuLink: FC<FreightPortalSidebarMenuLinkProps> = ({
  to,
  label,
  title = label,
  className,
  leftIcon,
  onRequestClose,
  location,
}) => (
  <SidebarMenuLink
    label={label}
    title={title}
    leftIcon={leftIcon}
    className={className}
    renderLink={({ title, className, style, ...linkProps }, activeClassName) => (
      <NavLink
        {...linkProps}
        style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', ...style }}
        className={({ isActive }) => cx(className, isActive ? activeClassName : null)}
        to={to}
        onClick={() => {
          onRequestClose();
          trackEvent({
            category: 'Navigation',
            action: 'Navigate to ' + title,
          });
        }}
        state={location.state}
        title={title}
      />
    )}
  />
);

export const SidebarBottomItems: FC<LinkRendererProps> = props => (
  <>
    <FreightPortalSidebarMenuLink
      {...props}
      label={'Timetable'}
      leftIcon={stenaClock}
      className={'t_timetable_link'}
      to={routeCreators.timetable()}
    />
    <FreightPortalSidebarMenuLink
      {...props}
      to={routeCreators.help()}
      leftIcon={stenaHelp}
      label={'Help'}
      className={cx('t_help_link', { tour_help: !props.rail })}
    />
    <FreightPortalSidebarMenuLink
      {...props}
      label={'Settings'}
      leftIcon={stenaCog}
      className={cx('t_settings_link')}
      to={routeCreators.settings()}
    />
  </>
);
