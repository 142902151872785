import {
  CommonTextInputField,
  CommonTextInputFieldProps,
} from '../../../../common-ui/form-fields/CommonTextInputField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import { UpdateIntermodalFormModelStringKeys } from '../../../../services/intermodal/updateIntermodalReducer';
import { useUpdateIntermodalField } from '../../hooks/useUpdateIntermodalField';
import { useMaskInput } from '../../../../common/hooks/useMaskInput';

interface UpdateIntermodalTextInputFieldProps<Key extends UpdateIntermodalFormModelStringKeys>
  extends Omit<CommonTextInputFieldProps, 'onValueChange' | 'trackerAction'> {
  name: Key;
  maskInput?: (value: string) => string;
}

export function UpdateIntermodalTextInputField<Key extends UpdateIntermodalFormModelStringKeys>({
  name,
  maskInput,
  ...props
}: UpdateIntermodalTextInputFieldProps<Key>) {
  const { onChange: onFieldChange, ...fieldProps } = useUpdateIntermodalField(name);
  const onChange = useMaskInput(onFieldChange, maskInput);

  return (
    <CommonTextInputField
      {...props}
      {...getFieldProps(props, { ...fieldProps, onChange })}
      changed={fieldProps.initialValue !== fieldProps.editedValue}
    />
  );
}
