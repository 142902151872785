import { Column, Row } from '@stenajs-webui/core';
import {
  IntermodalJourneyLeg,
  IntermodalJourneyLegs,
} from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { useLocalDateFormat } from '../../../common/hooks/useLocalDateFormat';
import { FC, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlatButton, RouteLeg, stenaAngleDown, stenaAngleUp } from '@stenajs-webui/elements';
import { getDepartureTimeDifferenceString, getOrdinal } from '../utils/detailsUtils';
import {
  getIntermodalUiShowAllLegs,
  toggleShowAllLegs,
} from '../../../services/intermodal/IntermodalGridStatusReducer';
import { TimeGap } from './TimeGap';
import { first, last } from 'lodash';
import { getLocationName, getRouteLegArrival, getRouteLegDeparture } from './utils';

import { PortDetails } from '../../../common-ui/PortDetails';
import { ShadowlessCard } from '../../../common-ui/ShadowlessCard';

export interface IntermodalJourneyInfoProps {
  legs: IntermodalJourneyLegs;
}

export const IntermodalJourneyInfo: FC<IntermodalJourneyInfoProps> = ({ legs }) => {
  const firstLeg = first(legs);
  const lastLeg = last(legs);
  const dateFormat = useLocalDateFormat();
  const showAllLegs = useSelector(getIntermodalUiShowAllLegs);
  const dispatch = useDispatch();

  if (!firstLeg || !lastLeg) {
    return null;
  }

  return (
    <Column gap>
      <ShadowlessCard className={'t_intermodal_journey_card'} indent={3} spacing={3}>
        <JourneyOverview dateFormat={dateFormat} firstLeg={firstLeg} lastLeg={lastLeg} />
      </ShadowlessCard>
      <Row justifyContent={'center'}>
        <FlatButton
          onClick={() => dispatch(toggleShowAllLegs())}
          label={showAllLegs ? 'Hide connections' : 'Show connections'}
          leftIcon={showAllLegs ? stenaAngleUp : stenaAngleDown}
        />
      </Row>
      {showAllLegs &&
        legs.map((leg, index) => (
          <Fragment key={index}>
            <ShadowlessCard indent={3} spacing={3}>
              <RouteLeg
                size={'compact'}
                label={getOrdinal(index + 1) + ' ROUTE'}
                variant={leg.isTrain ? 'rail' : 'ship'}
                departure={getRouteLegDeparture(leg, dateFormat)}
                arrival={getRouteLegArrival(leg, dateFormat)}
              />
            </ShadowlessCard>
            {legs.length > index + 1 && (
              <TimeGap
                time={getDepartureTimeDifferenceString(
                  leg.arrivalDate + ' ' + leg.arrivalTime,
                  legs[index + 1].departureDate + ' ' + legs[index + 1].departureTime,
                )}
              />
            )}
          </Fragment>
        ))}
    </Column>
  );
};

export const CompactIntermodalJourneyInfo: FC<IntermodalJourneyInfoProps> = ({ legs }) => {
  const lastLeg = last(legs);
  const firstLeg = first(legs);

  if (!firstLeg || !lastLeg) {
    return null;
  }

  const departureLocation = getLocationName(firstLeg.route.departureLocation);
  const arrivalLocation = getLocationName(lastLeg.route.arrivalLocation);

  return (
    <PortDetails
      arrivalDate={lastLeg.arrivalDate}
      arrivalTime={lastLeg.arrivalTime}
      arrivalLocation={arrivalLocation}
      departureDate={firstLeg.departureDate}
      departureTime={firstLeg.departureTime}
      departureLocation={departureLocation}
    />
  );
};

interface JourneyOverviewProps {
  firstLeg: IntermodalJourneyLeg;
  lastLeg: IntermodalJourneyLeg;
  dateFormat: string;
}

const JourneyOverview: FC<JourneyOverviewProps> = ({ firstLeg, lastLeg, dateFormat }) => (
  <RouteLeg
    variant={'rail'}
    departure={getRouteLegDeparture(firstLeg, dateFormat)}
    arrival={getRouteLegArrival(lastLeg, dateFormat)}
  />
);
