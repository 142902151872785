import { cssColor } from '@stenajs-webui/theme';
import { FC } from 'react';
import { Box, Heading, Row, Txt } from '@stenajs-webui/core';
import { getDateDisplayName } from '../common/utils/dates/dateUtils';
import { breakpoints } from '../themes/defaultTheme';
import { PortCircle } from './sailing-info/PortCircle';
import styled from '@emotion/styled';
import { BookingStatus } from '../gql/graphql';
import { CssPropColor } from '@stenajs-webui/theme/dist/util/CssColor';
import { journeyLineColor } from './util/portDetailsUtil';

const PortBox = styled(Box)`
  align-items: center;
  flex-direction: column;
  flex: 1;
  gap: calc(2 * var(--swui-metrics-space));

  @media print, screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;
    gap: calc(4 * var(--swui-metrics-space));
  }
`;

const PrintFriendlyLine = styled.div<{ color: CssPropColor }>`
  border-top: ${({ color }) => ` 3px solid ${cssColor(color)}`};
  flex-grow: 1;
`;
const LocationAndTimeBox = styled(Box)`
  flex-direction: row;
  align-items: baseline;

  @media print, screen and (min-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

interface LocationAndTimeProps {
  location: string;
  date: string;
  time: string;
}

const LocationAndTime: FC<LocationAndTimeProps> = ({ location, date, time }) => {
  return (
    <LocationAndTimeBox gap>
      <Txt whiteSpace={'nowrap'}>{location}</Txt>
      <Heading variant={'h3'} whiteSpace={'nowrap'}>
        {getDateDisplayName(date, 'd MMM')} {time}
      </Heading>
    </LocationAndTimeBox>
  );
};

const JourneyLine: FC<{ color: CssPropColor }> = ({ color }) => {
  return (
    <Row flex={1} alignItems={'center'} alignSelf={'stretch'}>
      <PortCircle color={color} />
      <PrintFriendlyLine color={color} />
      <PortCircle color={color} />
    </Row>
  );
};

interface PortDetailsProps {
  departureLocation: string;
  departureDate: string;
  departureTime: string;
  arrivalLocation: string;
  arrivalDate: string;
  arrivalTime: string;
  bookingStatus?: BookingStatus | null;
}

export const PortDetails: FC<PortDetailsProps> = ({
  arrivalLocation,
  departureLocation,
  arrivalTime,
  departureDate,
  departureTime,
  arrivalDate,
  bookingStatus,
}) => {
  const color = journeyLineColor(bookingStatus);
  return (
    <PortBox>
      <LocationAndTime location={departureLocation} date={departureDate} time={departureTime} />
      <JourneyLine color={color} />
      <LocationAndTime location={arrivalLocation} date={arrivalDate} time={arrivalTime} />
    </PortBox>
  );
};
