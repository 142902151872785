import { Row, Txt } from '@stenajs-webui/core';
import { FC } from 'react';
import { stopPropagation } from '../../../../common/eventHelpers';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { GoodsApproval, GoodsApprovalType } from '../../../../common/utils/GoodsApproval';
import { resolveLoadingPreferenceApproved } from '../../../../common/utils/loadingPreferenceUtil';
import { ApproveAlert } from '../../details/components/ApproveAlert';
import { ApprovalConfig, getApprovalInfo } from '../../details/utils/approvalInfo';

const getLoadingPreferenceApprovalInfo = (booking: BasicBooking): ApprovalConfig | null => {
  const loadingPreferenceApproval: GoodsApproval = {
    status: resolveLoadingPreferenceApproved(
      booking.loadingPreference,
      booking.loadingPreferenceStatusCode,
    ),
    type: GoodsApprovalType.LoadingPreference,
  };
  return getApprovalInfo(loadingPreferenceApproval);
};

export const LoadingPreferenceCell: FC<{
  booking: BasicBooking;
}> = ({ booking }) => {
  const approvalInfo = getLoadingPreferenceApprovalInfo(booking);

  return (
    <Row gap>
      <Txt whiteSpace={'nowrap'}>{booking.loadingPreference?.label}</Txt>
      {approvalInfo && (
        <Row onClick={stopPropagation}>
          <ApproveAlert config={approvalInfo} />
        </Row>
      )}
    </Row>
  );
};
