import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../common/tracking/trackerService';
import {
  BookingResultArgs,
  pauseToastRemoval,
  removeToast,
  resumeToastRemoval,
} from '../../../services/notifications/notificationsActions';
import { BaseToast } from './BaseToast';
import { BookingToastBody } from './BookingToastBody';
import { getBookingResultParams } from './getBookingResultParams';

export interface BookingResultToastProps {
  id: string;
  args: BookingResultArgs;
}

export const BookingResultToast: FC<BookingResultToastProps> = ({ id, args: { result } }) => {
  const dispatch = useDispatch();

  const { title, icon, iconBackgroundColor, ...params } = getBookingResultParams(result);

  useEffect(() => {
    trackEvent({ category: 'Toast', action: 'Appear (booking result)' });
  }, []);

  return (
    <BaseToast
      className={`t_booking_toast_${result.type}`}
      icon={icon}
      title={title}
      onClose={() => {
        dispatch(removeToast(id));
      }}
      onMouseEnter={() => {
        dispatch(pauseToastRemoval(id));
      }}
      onMouseLeave={() => {
        dispatch(resumeToastRemoval(id));
      }}
      iconBackgroundColor={iconBackgroundColor}
    >
      <BookingToastBody {...params} trackerCategory={'Toast'} />
    </BaseToast>
  );
};
