import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../store/storeState';
import {
  CreateIntermodalBookingFormModel,
  getCreateIntermodalBookingFormState,
} from '../createIntermodalReducer';

export const useCreateIntermodalFieldValue = <TKey extends keyof CreateIntermodalBookingFormModel>(
  name: TKey,
): CreateIntermodalBookingFormModel[TKey] => {
  return useSelector(
    useCallback((state: StoreState) => getCreateIntermodalBookingFormState(state)[name], [name]),
  );
};
