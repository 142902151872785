import { BookingStatus, IntermodalBookingStatus } from '../../gql/graphql';

export const isBookingClaimable = (status: BookingStatus | null): boolean => {
  return status === BookingStatus.Shipped || status === BookingStatus.Cancelled;
};

export const isIntermodalBookingClaimable = (status: IntermodalBookingStatus | null): boolean => {
  switch (status) {
    case IntermodalBookingStatus.GateExit:
    case IntermodalBookingStatus.ReadyForPickup:
    case IntermodalBookingStatus.Arrived:
    case IntermodalBookingStatus.OnRoute:
    case IntermodalBookingStatus.Cancelled:
    case IntermodalBookingStatus.EtaPassed:
      return true;
    case IntermodalBookingStatus.Confirmed:
    case IntermodalBookingStatus.Waitlist:
    case IntermodalBookingStatus.InProgress:
    case IntermodalBookingStatus.Updated:
    default:
      return false;
  }
};
