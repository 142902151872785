import { takeUniqBy } from 'redux-saga-uniq';
import { all, takeEvery } from 'redux-saga/effects';
import { takeLatestUniqBy } from '../../common/saga/takeLatestUniqBy';
import { cleanUpdateResult } from './sagas/cleanUpdateResult';
import { handleWaitForSubmit } from './sagas/handleSubmit';
import { handleUpdateFormValues } from './sagas/handleUpdateFormValues';
import { watchTravelledVehicle } from './sagas/watchTravelledVehicle';
import { SubmitFinishedAction, UpdateFormRequestSubmitAction } from './updateFormActions';

export function* watchUpdateForm() {
  yield all([
    takeEvery('UPDATE_FORM:UPDATE_VALUES', handleUpdateFormValues),
    takeUniqBy(
      (action: UpdateFormRequestSubmitAction) =>
        action.payload.id.bookingNo + action.payload.id.formId,
      'UPDATE_FORM:REQUEST_SUBMIT',
      handleWaitForSubmit,
    ),
    takeLatestUniqBy(
      (action: SubmitFinishedAction) => action.payload.id.bookingNo + action.payload.id.formId,
      'UPDATE_FORM:SUBMIT_FINISHED',
      cleanUpdateResult,
    ),
    watchTravelledVehicle(),
  ]);
}
