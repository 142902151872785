import { ColumnId, UpdatePageSettings2Input } from '../../gql/graphql';
import { BookingsPerLoad, CompactMode } from '../grid/gridReducer';

export interface PageSettingsRehydratePayload {
  compactMode: CompactMode;
  bookingsPerLoad: BookingsPerLoad;
  columnsEnabled: Partial<Record<ColumnId, boolean>>;
  columnsWidth: Partial<Record<ColumnId, number>>;
  columnsOrder: ColumnId[];
  railEnabled: boolean;
}

export interface RehydratePageSettingsAction {
  type: 'PAGE_SETTINGS:REHYDRATE';
  payload: PageSettingsRehydratePayload;
}

export const rehydratePageSettings = (
  payload: PageSettingsRehydratePayload,
): RehydratePageSettingsAction => ({
  payload,
  type: 'PAGE_SETTINGS:REHYDRATE',
});

export interface RequestSavePageSettingsAction {
  type: 'PAGE_SETTINGS:REQUEST_SAVE';
  payload: UpdatePageSettings2Input;
}

export const requestSavePageSettings = (
  payload: UpdatePageSettings2Input,
): RequestSavePageSettingsAction => ({
  payload,
  type: 'PAGE_SETTINGS:REQUEST_SAVE',
});
