import { graphql } from '../../gql';

export const sailingsByFilterQuery = graphql(/*GraphQL*/ `
  query SailingsQuery($filter: SailingsFilter!) {
    viewer {
      sailings {
        byFilter(filter: $filter) {
          items {
            ...Sailing
          }
        }
      }
    }
  }
`);
