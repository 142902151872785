import { REHYDRATE } from 'redux-persist';
import { UrgentMessage } from '../../common/graphql/fragments/gql/UrgentMessage';
import { RehydrateActions } from '../../store/rehydrateActionTypes';
import { StoreState } from '../../store/storeState';
import { BookingResult, NotificationsActions, ToastArgs } from './notificationsActions';
import { removeOutdatedBookingResults } from './utils/removeOutdatedBookingResults';

export type CreatedAt = string;

export interface UrgentMessageNotification {
  message: UrgentMessage;
  createdAt: CreatedAt;
}

export interface BookingResultNotification {
  bookingResult: BookingResult;
  id: number;
  createdAt: CreatedAt;
}

export type UrgentMessageNotificationRecord = Record<string, UrgentMessageNotification | undefined>;
export type BookingResultNotificationRecord = Record<number, BookingResultNotification | undefined>;

export type IdsRecord = Record<string, boolean | undefined>;

export interface NotificationsReducerState {
  toast: Record<string, ToastArgs | undefined>;
  toastIds: string[];
  urgentMessage: UrgentMessageNotificationRecord;
  bookingResults: BookingResultNotificationRecord;
  readUrgentMessageIds: IdsRecord;
}

const INITIAL_VALUE: NotificationsReducerState = {
  toast: {},
  toastIds: [],
  urgentMessage: {},
  bookingResults: {},
  readUrgentMessageIds: {},
};

export const notificationsReducer = (
  state: NotificationsReducerState = INITIAL_VALUE,
  action: NotificationsActions | RehydrateActions,
): NotificationsReducerState => {
  switch (action.type) {
    case REHYDRATE: {
      if (action.key === 'notifications-persisted-state' && action.payload) {
        return {
          ...state,
          bookingResults: removeOutdatedBookingResults(action.payload.bookingResults),
          readUrgentMessageIds: action.payload.readUrgentMessageIds,
        };
      }
      return state;
    }
    case 'NOTIFICATIONS:ADD_TOAST': {
      return {
        ...state,
        toast: {
          ...state.toast,
          [action.payload.id]: action.payload.args,
        },
        toastIds: [action.payload.id, ...state.toastIds],
      };
    }
    case 'NOTIFICATIONS:REMOVE_TOAST': {
      const { [action.payload.id]: item, ...rest } = state.toast;

      return {
        ...state,
        toast: rest,
        toastIds: state.toastIds.filter(id => id !== action.payload.id),
      };
    }
    case 'NOTIFICATION:ADD_BOOKING_RESULT_NOTIFICATION': {
      const bookingResult = state.bookingResults[action.payload.id];
      if (bookingResult) {
        return state;
      }
      return {
        ...state,
        bookingResults: {
          ...state.bookingResults,
          [action.payload.id]: {
            bookingResult: action.payload.bookingResult,
            id: action.payload.id,
            createdAt: action.payload.createdAt,
          },
        },
      };
    }
    case 'NOTIFICATION:REMOVE_BOOKING_RESULT_NOTIFICATION': {
      const { [action.payload.id]: item, ...rest } = state.bookingResults;
      return {
        ...state,
        bookingResults: rest,
      };
    }
    case 'NOTIFICATION:ADD_URGENT_MESSAGE_NOTIFICATION': {
      const urgentMessage = state.urgentMessage[action.payload.message.id];
      const isRead = state.readUrgentMessageIds[action.payload.message.id];

      if (urgentMessage && isRead) {
        return state;
      }

      return {
        ...state,
        urgentMessage: {
          ...state.urgentMessage,
          [action.payload.message.id]: {
            message: action.payload.message,
            createdAt: action.payload.createdAt,
          },
        },
      };
    }
    case 'NOTIFICATIONS:MARK_URGENT_MESSAGE_AS_UNREAD': {
      return {
        ...state,
        readUrgentMessageIds: {
          ...state.readUrgentMessageIds,
          [action.payload.id]: false,
        },
      };
    }
    case 'NOTIFICATIONS:MARK_URGENT_MESSAGE_AS_READ': {
      return {
        ...state,
        readUrgentMessageIds: {
          ...state.readUrgentMessageIds,
          [action.payload.id]: true,
        },
      };
    }
    default:
      return state;
  }
};

export const getNotifications = (state: StoreState): NotificationsReducerState =>
  state.notifications;

export const getToastById = (state: StoreState, toastId: string): ToastArgs | undefined =>
  getNotifications(state).toast[toastId];

export const getToastIds = (state: StoreState): string[] => getNotifications(state).toastIds;

export const getUrgentMessageNotification = (state: StoreState): UrgentMessageNotificationRecord =>
  getNotifications(state).urgentMessage;
export const getBookingResultNotification = (state: StoreState): BookingResultNotificationRecord =>
  getNotifications(state).bookingResults;

export const getUrgentMessageNotificationById = (
  state: StoreState,
  notificationId: string,
): UrgentMessageNotification | undefined => getNotifications(state).urgentMessage[notificationId];

export const getUrgentMessageReadIds = (state: StoreState): IdsRecord =>
  getNotifications(state).readUrgentMessageIds;

export const getIsUrgentMessageRead = (state: StoreState, notificationId: string) =>
  Boolean(getUrgentMessageReadIds(state)[notificationId]);
