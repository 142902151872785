import { Box, SeparatorLine } from '@stenajs-webui/core';
import { Notification } from '@stenajs-webui/panels';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { removeBookingResultNotification } from '../../../services/notifications/notificationsActions';
import { BookingResultNotification as BookingResultNotificationType } from '../../../services/notifications/notificationsReducer';
import { BookingToastBody } from '../toasts/BookingToastBody';
import { getBookingResultParams } from '../toasts/getBookingResultParams';
import { formatToastRelativeTimestamp } from '../utils/formatToastRelativeTimestamp';

export interface BookingResultNotificationProps {
  bookingResultNotification: BookingResultNotificationType;
  onClose: () => void;
}

export const BookingResultNotification: FC<BookingResultNotificationProps> = ({
  bookingResultNotification,
  onClose,
}) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(removeBookingResultNotification(bookingResultNotification.id));
  };

  const { title, icon, iconBackgroundColor, ...params } = getBookingResultParams(
    bookingResultNotification.bookingResult,
  );

  return (
    <Box>
      <Notification
        icon={icon}
        text={title}
        onClose={onClick}
        timestamp={formatToastRelativeTimestamp(bookingResultNotification.createdAt)}
        iconBackgroundColor={iconBackgroundColor}
      >
        <BookingToastBody
          {...params}
          onAfterClick={onClose}
          trackerCategory={'NotificationCenter'}
        />
      </Notification>
      <SeparatorLine />
    </Box>
  );
};
