import { FC } from 'react';
import { DebouncedTextInput } from '../../../../../common-ui/form-fields/DebouncedTextInput';
import { useUpdateField } from '../../../../../common/update-information/hooks/useUpdateField';
import { useUpdateRegistrationNumberAlert } from '../../../../../common/registrationNumberAlert/hooks/useUpdateRegistrationNumberAlert';
import { formatRegistrationNumberString } from '../../../../../common/utils/stringUtil';
import { TravelledVehicleType } from '../../../../../services/update-form/registrationNumberAlert';
import {
  transformUpdateFieldToTrackerAction,
  useTrackingTextInput,
} from '../../../../simple-create/hooks/UseTrackingTextInput';
import { DetailsFieldCell } from '../DetailsFieldCell';

interface UpdateTrailerRegNoProps {
  disabled: boolean;
}

export const UpdateTrailerRegNo: FC<UpdateTrailerRegNoProps> = ({ disabled }) => {
  const fieldName = 'trailerRegNo';
  const { editedValue, onChange, initialValue } = useUpdateField<'trailerRegNo'>(fieldName);
  const { onBlur, onFocus } = useTrackingTextInput(
    transformUpdateFieldToTrackerAction(fieldName),
    'Details',
  );
  const { icon } = useUpdateRegistrationNumberAlert(
    'Details',
    'TrailerReg',
    TravelledVehicleType.Trailer,
  );

  return (
    <DetailsFieldCell
      label="Trailer registration"
      field={
        <DebouncedTextInput
          className={'t_trailer_reg_details_edit'}
          name={fieldName}
          maskInput={formatRegistrationNumberString}
          maxLength={17}
          disabled={disabled}
          onChange={onChange}
          editedValue={editedValue || ''}
          initialValue={initialValue || ''}
          onFocus={onFocus}
          onBlur={onBlur}
          buttonRight={icon}
        />
      }
    />
  );
};
