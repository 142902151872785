import cx from 'classnames';
import { FC, useLayoutEffect, useRef } from 'react';
import { REACT_MODAL_OVERLAY_SELECTOR } from './BaseModal';
import styles from './Modal.module.css';

export const ScrollShadow: FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const content = document.querySelector<HTMLDivElement>(REACT_MODAL_OVERLAY_SELECTOR);
    const shadowBottom = ref.current;

    if (content && shadowBottom) {
      const setOpacity = () => {
        const scrollDistance = content.scrollHeight - content.offsetHeight;
        shadowBottom.style.opacity = String(
          scrollDistance > 0 ? 1 - content.scrollTop / scrollDistance : 0,
        );
      };

      window.requestAnimationFrame(setOpacity);

      content.addEventListener('scroll', setOpacity, { passive: true });
      window.addEventListener('resize', setOpacity, { passive: true });

      return () => {
        content.removeEventListener('scroll', setOpacity);
        window.removeEventListener('resize', setOpacity);
      };
    }
  }, [ref]);

  return <div className={cx(styles.shadowBottom)} ref={ref} />;
};
