import { LibErrorCodes, SpecErrorCodes } from 'auth0-js';
import { AuthError } from '../../services/authentication/authReducer';

export type ErrorType = LibErrorCodes | SpecErrorCodes | string;

export function authErrorTitle(error: AuthError) {
  switch (error.type) {
    case 'invalid_token':
      return 'Invalid token';
    case 'request_error':
      return 'Request error';
    case 'timeout':
      return 'Timeout';
    default:
      return 'Error';
  }
}
