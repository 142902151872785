import { VehicleType } from '../../common/graphql/fragments/gql/VehicleType';
import { TravelledVehicle } from '../../common/graphql/fragments/gql/TravelledVehicle';
import { Box, Column, Row, Txt } from '@stenajs-webui/core';
import VehicleTypeIcon from '../../common/vehicle-types/VehicleTypeIcon';

interface TravelledVehicleResultProps {
  vehicleType: VehicleType;
  travelledVehicleResult: TravelledVehicle;
}

export const TravelledVehicleResult = ({
  travelledVehicleResult: { vehicleRegNo, vehicleTypeCode, length, height },
  vehicleType: { description },
}: TravelledVehicleResultProps) => (
  <Box gap={2} spacing>
    <Txt>{`${vehicleRegNo} has been shipped before. Do you want to use this information?`}</Txt>
    <Row gap={2}>
      <Column>
        <VehicleTypeIcon type={vehicleTypeCode} size={'large'} />
      </Column>
      <Column gap>
        <TravelledVehicleField label={'Vehicle type'} text={description} />
        <Row gap={2}>
          {length > 0 && <TravelledVehicleField label={'Length'} text={length + 'm'} />}
          {height > 0 && <TravelledVehicleField label={'Height'} text={height + 'm'} />}
        </Row>
      </Column>
    </Row>
  </Box>
);

const TravelledVehicleField = ({ label, text }: { label: string; text: string }) => (
  <Column>
    <Txt size={'small'}>{label}</Txt>
    <Txt>{text}</Txt>
  </Column>
);
