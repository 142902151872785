import { Column, HeaderText, Row, SmallText, Space, StandardText } from '@stenajs-webui/core';
import {
  Icon,
  Link,
  PrimaryButton,
  stenaClock,
  stenaLockClosed,
  stenaRefresh,
  stenaSignIn,
} from '@stenajs-webui/elements';
import { FC } from 'react';
import { CONTACT_URL } from '../../common/footer/Footer';
import { AuthError } from '../../services/authentication/authReducer';
import { useAuth } from './AuthContext';

export interface AuthErrorBodyProps {
  error: AuthError;
}

export const AuthErrorBody: FC<AuthErrorBodyProps> = ({ error }) => {
  const auth = useAuth();

  if (
    error.type === 'invalid_token' &&
    error.description.includes('current time') &&
    error.description.includes('is before issued')
  ) {
    return (
      <Column alignItems={'flex-start'}>
        <Row alignItems={'center'}>
          <Icon icon={stenaClock} />
          <Space />
          <HeaderText>We're sorry about the timing</HeaderText>
        </Row>
        <Space />
        <StandardText>
          Since we only support a few minutes of time difference between systems we are not able to
          log you in to Freight Portal. If you update the time on your computer it should work just
          fine.
        </StandardText>
        <Space num={2} />
        <Row spacing={0.5}>
          <SmallText variant={'bold'}>Update the time on a Windows computer</SmallText>
        </Row>
        <Row spacing={0.5}>
          <StandardText>1. Open Control Panel.</StandardText>
        </Row>
        <Row spacing={0.5}>
          <StandardText>2. Click on Clock, Language, and Region.</StandardText>
        </Row>
        <Row spacing={0.5}>
          <StandardText>3. Click on Date and Time.</StandardText>
        </Row>
        <Row spacing={0.5}>
          <StandardText>4. Click on the Internet Time tab.</StandardText>
        </Row>
        <Row spacing={0.5}>
          <StandardText>5. Click the Change settings button.</StandardText>
        </Row>
        <Row spacing={0.5}>
          <StandardText>
            6. Check that the Synchronize with an internet time server option is selected.
          </StandardText>
        </Row>
        <Row spacing={0.5}>
          <StandardText>7. Use the drop-down menu to select a different server.</StandardText>
        </Row>
      </Column>
    );
  }

  if (error.type === 'timeout') {
    return (
      <Column alignItems={'flex-start'}>
        <Row alignItems={'center'}>
          <Icon icon={stenaClock} />
          <Space />
          <HeaderText>Time's up</HeaderText>
        </Row>
        <Space />
        <StandardText>
          The request took too long and we could not log you in. Refresh the page and try again.
        </StandardText>
        <Space num={2} />
        <Row>
          <PrimaryButton
            label={'Reload page'}
            leftIcon={stenaRefresh}
            onClick={() => window.location.reload()}
          />
        </Row>
      </Column>
    );
  }

  return (
    <Column alignItems={'flex-start'}>
      <Row alignItems={'center'}>
        <Icon icon={stenaLockClosed} />
        <Space />
        <HeaderText>Sorry, we could not log you in</HeaderText>
      </Row>
      <Space />
      <StandardText>
        We were not able to log you in to Freight Portal. Please try again.
      </StandardText>
      <Space num={2} />
      <PrimaryButton label={'Go to login'} leftIcon={stenaSignIn} onClick={() => auth.logout()} />
      <Space num={2} />
      <StandardText>
        Please <Link href={CONTACT_URL}>contact your local booking office</Link> if you are still
        not able to log in to Freight Portal and need assistance with your bookings.
      </StandardText>
    </Column>
  );
};
