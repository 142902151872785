import { isValid, parseISO } from 'date-fns';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchUrgentMessage } from '../../common/api';
import { Route } from '../../common/graphql/fragments/gql/Route';
import { UrgentMessage } from '../../common/graphql/fragments/gql/UrgentMessage';
import {
  getMessagesToShow,
  getUrgentMessageInitialDate,
  resolveUrgentMessagesForGrid,
  urgentMessageCheckIfOverlappingInterval,
} from '../../common/urgent-message/utils/urgentMessageUtils';
import {
  CreateFormUpdateCommonValuesAction,
  InitializeCreateFormAction,
} from '../create/createFormActions';
import { getCreateFormState } from '../create/createReducer';
import { SetBookingQueryResultAction } from '../grid/gridActions';
import {
  addUrgentMessageNotification,
  requestShowUrgentMessageToast,
} from '../notifications/notificationsActions';
import { createCreatedAt } from '../notifications/notificationsUtils';
import { getUpdateFormListState } from '../update-form/updateFormReducer';
import { checkUrgentMessages, CheckUrgentMessagesAction } from './actions';
import { getDateFromDateAndTime } from '../../common/utils/dates/dateUtils';

export function* watchUrgentMessageSaga() {
  yield all([
    takeLatest<SetBookingQueryResultAction>('GRID:SET_QUERY_RESULT', urgentMessageFromGridSaga),
    takeLatest<CreateFormUpdateCommonValuesAction>(
      'CREATE_FORM:UPDATE_COMMON_VALUES',
      showUrgentMessagesOnUpdate,
    ),
    takeLatest<InitializeCreateFormAction>(
      'CREATE_FORM:INITIALIZE_CREATE_FORM',
      showUrgentMessagesOnInitialize,
    ),
    takeLatest<CheckUrgentMessagesAction>(checkUrgentMessages.type, showUrgentMessagesOnTimetable),
  ]);
}

function* postUrgentMessagesToNotificationCenter(messages: UrgentMessage[]) {
  for (let message of messages) {
    yield put(requestShowUrgentMessageToast(message));
    yield put(addUrgentMessageNotification(message, createCreatedAt(new Date())));
  }
}

function* urgentMessageFromGridSaga(action: SetBookingQueryResultAction) {
  const gridResult = action.payload.result;
  const updateForm: ReturnType<typeof getUpdateFormListState> = yield select(
    getUpdateFormListState,
  );

  const urgentMessages: UrgentMessage[] | null = yield call(
    fetchUrgentMessage,
    getUrgentMessageInitialDate(),
  );

  if (urgentMessages) {
    const messages = resolveUrgentMessagesForGrid(gridResult, updateForm, urgentMessages);
    yield call(postUrgentMessagesToNotificationCenter, messages);
  }
}

function* showUrgentMessagesOnInitialize(action: InitializeCreateFormAction) {
  if (
    action.payload.formState.departureDate &&
    action.payload.formState.route &&
    action.payload.formState.sailing?.departureTime
  ) {
    yield* postUrgentMessageFromCreateFormState(
      action.payload.formState.departureDate,
      action.payload.formState.sailing.departureTime,
      action.payload.formState.route,
    );
  }
}

function* showUrgentMessagesOnUpdate(action: CreateFormUpdateCommonValuesAction) {
  const formState: ReturnType<ReturnType<typeof getCreateFormState>> = yield select(
    getCreateFormState(action.payload.formId),
  );

  const departureDate = action.payload.values.departureDate ?? formState.departureDate;
  const route = action.payload.values.route ?? formState.route;
  const departureTime =
    action.payload.values.sailing?.departureTime ?? formState.sailing?.departureTime;

  if (departureDate && route && departureTime) {
    yield* postUrgentMessageFromCreateFormState(departureDate, departureTime, route);
  }
}

function* postUrgentMessageFromCreateFormState(
  departureDate: string,
  departureTime: string,
  route: Route,
) {
  const urgentMessages: UrgentMessage[] | null = yield call(
    fetchUrgentMessage,
    getUrgentMessageInitialDate(),
  );
  const parsedDate = getDateFromDateAndTime(departureDate, departureTime);

  if (urgentMessages && parsedDate && isValid(parsedDate) && route) {
    const messages = getMessagesToShow(urgentMessages, [
      {
        date: parsedDate,
        routeCode: route.code,
      },
    ]);
    yield call(postUrgentMessagesToNotificationCenter, messages);
  }
}

function* showUrgentMessagesOnTimetable({
  payload: { dateFromString, dateToString, routeCode },
}: CheckUrgentMessagesAction) {
  const urgentMessages: UrgentMessage[] | null = yield call(
    fetchUrgentMessage,
    getUrgentMessageInitialDate(),
  );

  const dateFrom = parseISO(dateFromString);
  const dateTo = parseISO(dateToString);

  if (urgentMessages && isValid(dateFrom) && isValid(dateTo)) {
    const messages = urgentMessages.filter(message =>
      urgentMessageCheckIfOverlappingInterval(message, dateFrom, dateTo, routeCode),
    );
    yield call(postUrgentMessagesToNotificationCenter, messages);
  }
}
