import { entries, groupBy, sortBy } from 'lodash';
import { GroupedOptionsType } from '../../common-ui/form-fields/Dropdown';
import {
  DropdownOption,
  transformVehicleType,
} from '../../use-cases/manage/grid/utils/optionTransformers';
import { VehicleType } from '../graphql/fragments/gql/VehicleType';

export const getVehicleTypesGroupedByUseNoOfDrivers = (
  vehicleTypes: VehicleType[],
): Array<[string, VehicleType[]]> =>
  entries(groupBy(vehicleTypes, vt => (vt.useNoOfDrivers ? 'Accompanied' : 'Unaccompanied')));

export const getGroupedVehicleTypeOptionsByNoOfDrivers = (
  vehicleTypes: VehicleType[],
): GroupedOptionsType<DropdownOption<VehicleType>> => {
  return sortBy(
    getVehicleTypesGroupedByUseNoOfDrivers(vehicleTypes).map(([label, vehicleTypes]) => ({
      label,
      value: '',
      options: sortBy(vehicleTypes.map(transformVehicleType), ['label']),
    })),
    ['label'],
  );
};
