import { BookingFormState } from '../../../services/update-form/updateFormReducer';
import { BasicBooking } from '../../graphql/fragments/gql/BasicBooking';
import {
  animalDropdownNoOption,
  animalDropdownYesOption,
} from '../../graphql/useAnimalTypesOptions';
import { loadingPreferenceDropdownNoOption } from '../../graphql/useLoadingPreferenceOptions';
import { includeYesOption } from '../../utils/animalsUtils';
import { formatServerDate } from '../../utils/dates/dateUtils';
import { customsClassificationDropdownEmptyOption } from '../../graphql/useCustomsClassificationsOptions';

function resolveLivestockType(booking: BasicBooking) {
  if (booking.livestock) {
    return includeYesOption(booking.livestockType, booking.livestock)
      ? animalDropdownYesOption.data
      : booking.livestockType;
  }
  return animalDropdownNoOption.data;
}

export const transformToUpdateBookingFormState = (
  booking: BasicBooking,
): Required<BookingFormState> => ({
  bookingNo: booking.bookingNo,
  bookingStatusCode: booking.bookingStatusCode,
  cargoWeight: booking.cargoWeight,
  customer: {
    ...booking.customer,
  },
  exportReference: booking.exportReference.reference,
  importReference: booking.importReference.reference,
  customerCustomsStatus: booking.customerCustomsStatus,
  customerReference: booking.customerReference,
  customsClassification: booking.customsClassification
    ? {
        ...booking.customsClassification,
      }
    : customsClassificationDropdownEmptyOption.data,
  departureDate: formatServerDate(booking.sailing.departureDate),
  hazardousGoods: booking.hazardousGoods,
  height: booking.height,
  length: booking.length,
  livestock: booking.livestock,
  livestockType: resolveLivestockType(booking),
  loadingListMessage: booking.loadingListMessage,
  loadingPreference: booking.loadingPreference
    ? { ...booking.loadingPreference }
    : loadingPreferenceDropdownNoOption.data,
  noOfAdults: booking.noOfAdults,
  noOfChildren: booking.noOfChildren,
  noOfDrivers: booking.noOfDrivers,
  noOfInfants: booking.noOfInfants,
  noOfPlugins: booking.noOfPlugins,
  pluginTemperature: booking.pluginTemperature,
  route: {
    ...booking.sailing.route,
  },
  receiverCountry: booking.receiverCountry,
  sailing: {
    ...booking.sailing,
    route: {
      ...booking.sailing.route,
    },
  },
  saveAsWaitList: false,
  senderCountry: booking.senderCountry,
  showDaysOnQuay: booking.showDaysOnQuay,
  timestamp: booking.timestamp,
  tradeWeight: booking.tradeWeight,
  trailerRegNo: booking.trailerRegNo,
  vehicleRegNo: booking.vehicleRegNo,
  vehicleType: { ...booking.vehicleType },
  width: booking.width,
});
