import {
  BookingDetailsSectionHeader,
  BookingDetailsSectionHeaderProps,
} from './BookingDetailsSectionHeader';
import { FC, ReactNode } from 'react';
import { CardBody } from '@stenajs-webui/elements';

export const sectionIndent = 2;

interface BookingDetailsSectionProps extends BookingDetailsSectionHeaderProps {
  children?: ReactNode;
  disablePadding?: boolean;
}

export const BookingSection: FC<BookingDetailsSectionProps> = ({
  title,
  disablePadding = false,
  children,
}) => (
  <>
    <BookingDetailsSectionHeader title={title} />

    {disablePadding ? children : <CardBody gap={3}>{children}</CardBody>}
  </>
);
