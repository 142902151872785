import { Row, Space, Txt } from '@stenajs-webui/core';
import { FlatButton, stenaSms } from '@stenajs-webui/elements';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InformationMessageType } from '../../../common-ui/information-message/informationMessageUtils';
import { trackEvent } from '../../../common/tracking/trackerService';
import { dotdotdot } from '../../../common/utils/stringUtil';
import { showUrgentMessage } from '../../../services/modal/modalActions';
import {
  markUrgentMessageAsRead,
  removeToast,
  UrgentMessageArgs,
} from '../../../services/notifications/notificationsActions';
import { BaseToast } from './BaseToast';
import { getToastIconProps } from './getBookingResultParams';

export interface UrgentMessageToastProps {
  id: string;
  args: UrgentMessageArgs;
}

const action = 'Open urgent message (unread)';

export const UrgentMessageToast: FC<UrgentMessageToastProps> = ({
  id,
  args: { urgentMessage },
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    trackEvent({ category: 'Toast', action: 'Appear (urgent message)' });
  }, []);

  const showMessage = () => {
    trackEvent({
      category: 'Toast',
      action,
    });

    dispatch(showUrgentMessage(urgentMessage, 'Toast', action));
    dispatch(markUrgentMessageAsRead(urgentMessage.id));
    dispatch(removeToast(id));
  };

  return (
    <BaseToast
      title={dotdotdot(urgentMessage.header, 34)}
      {...getToastIconProps(InformationMessageType.RegularInfo)}
      onClose={() => {
        dispatch(removeToast(id));
      }}
    >
      <Txt title={urgentMessage.text}>{dotdotdot(urgentMessage.text, 100)}</Txt>
      <Space num={1} />
      <Row alignSelf={'flex-end'}>
        <FlatButton label={'Full message'} leftIcon={stenaSms} onClick={showMessage} />
      </Row>
    </BaseToast>
  );
};
