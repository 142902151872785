import { ErrorType } from '../../use-cases/auth/errors';
import { AuthResult } from '../../use-cases/auth/types';
import { AuthError } from './authReducer';

export type AuthAction = UserLoggedInAction | NoSessionFoundAction | FatalErrorAction;

export interface UserLoggedInAction {
  payload: {
    authResult: AuthResult;
  };
  type: 'AUTH:USER_LOGGED_IN';
}

export const userLoggedIn = (authResult: AuthResult): UserLoggedInAction => ({
  payload: {
    authResult,
  },
  type: 'AUTH:USER_LOGGED_IN',
});

export interface UserLoggedOutAction {
  payload: {};
  type: 'AUTH:USER_LOGGED_OUT';
}

export const userLoggedOut = (): UserLoggedOutAction => ({
  payload: {},
  type: 'AUTH:USER_LOGGED_OUT',
});

export interface NoSessionFoundAction {
  payload: {};
  type: 'AUTH:NO_SESSION_FOUND';
}

export const noSessionFound = (): NoSessionFoundAction => ({
  payload: {},
  type: 'AUTH:NO_SESSION_FOUND',
});

export interface FatalErrorAction {
  payload: AuthError;
  type: 'AUTH:FATAL_ERROR';
}

export const fatalError = (type: ErrorType, description: string): FatalErrorAction => ({
  payload: {
    type,
    description,
  },
  type: 'AUTH:FATAL_ERROR',
});
