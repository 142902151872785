import { groupBy, orderBy, sortBy } from 'lodash';
import { formatServerDate } from '../../../common/utils/dates/dateUtils';
import { isDefined } from '../../../common/utils/isDefined';
import {
  BookingResultNotification,
  BookingResultNotificationRecord,
  UrgentMessageNotification,
  UrgentMessageNotificationRecord,
} from '../../../services/notifications/notificationsReducer';

export interface NotificationsGroupedByDate<TNotification> {
  date: string;
  notifications: TNotification[];
}

export function getSortedNotifications(
  notifications: UrgentMessageNotificationRecord,
): NotificationsGroupedByDate<UrgentMessageNotification>[];
export function getSortedNotifications(
  notifications: BookingResultNotificationRecord,
): NotificationsGroupedByDate<BookingResultNotification>[];
export function getSortedNotifications(
  notifications: UrgentMessageNotificationRecord | BookingResultNotificationRecord,
): NotificationsGroupedByDate<any>[] {
  const sortedNotifications = orderBy(
    Object.values(notifications).filter(isDefined),
    [(m: UrgentMessageNotification | BookingResultNotification) => m.createdAt],
    ['desc'],
  );

  const sortedEntries = sortBy(
    Object.entries(
      groupBy(sortedNotifications, (n: UrgentMessageNotification | BookingResultNotification) =>
        formatServerDate(n.createdAt),
      ),
    ),
    ([date]) => date,
  );

  return orderBy(
    sortedEntries.map(([date, notifications]) => ({
      date,
      notifications,
    })),
    [({ date }) => date],
    ['desc'],
  );
}
