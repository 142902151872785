import { useCreateIntermodalField } from '../hooks/useCreateIntermodalField';
import { Column } from '@stenajs-webui/core';
import { RadioButtonWithLabel } from '@stenajs-webui/forms';
import { trackEditEvent } from '../../simple-create/hooks/UseTrackingTextInput';

export function IntermodalSearchOptionSwitch() {
  const { editedValue, onChange, trackerAction, disabled } =
    useCreateIntermodalField('searchByDeparture');

  return (
    <Column gap>
      <RadioButtonWithLabel
        name={'departmode'}
        disabled={disabled}
        label={'Depart after'}
        value={'departure'}
        onClick={() => {
          onChange(true);
          trackEditEvent(trackerAction, 'IntermodalCreate');
        }}
        checked={editedValue}
      />
      <RadioButtonWithLabel
        name={'departmode'}
        disabled={disabled}
        label={'Arrive before'}
        value={'arrival'}
        onClick={() => {
          onChange(false);
          trackEditEvent(trackerAction, 'IntermodalCreate');
        }}
        checked={!editedValue}
      />
    </Column>
  );
}
