import { Column, Txt, useBoolean } from '@stenajs-webui/core';
import { FC } from 'react';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { trackEvent } from '../../../../common/tracking/trackerService';
import { formatDate } from '../../../../common/utils/dates/dateUtils';
import { useCancelBooking } from '../../../../common/graphql/useCancelBooking';
import { useLocalDateFormat } from '../../../../common/hooks/useLocalDateFormat';
import { CancelButtonWithPopover } from '../../../../common-ui/CancelButtonWithPopover';

interface BookingCancelButtonProps {
  booking: BasicBooking;
  onFail: () => void;
  disabled?: boolean;
}

export const BookingCancelButton: FC<BookingCancelButtonProps> = ({
  booking,
  disabled,
  onFail,
}) => {
  const [visible, , hide, toggle] = useBoolean(false);
  const { submit, submitting } = useCancelBooking({ booking, onFail, onSuccess: hide });
  const dateFormat = useLocalDateFormat();

  const onCancelOpen = () => {
    trackEvent({ category: 'Details', action: 'Cancel booking' });
    toggle();
  };

  const onConfirm = () => {
    trackEvent({ category: 'Details', action: 'Confirm cancel booking' });
    submit();
  };

  const onAbort = () => {
    trackEvent({ category: 'Details', action: 'Abort cancel booking' });
    hide();
  };

  if (!booking.cancelable) {
    return null;
  }

  return (
    <CancelButtonWithPopover
      onRequestClose={hide}
      visible={visible}
      submitting={submitting}
      onConfirm={onConfirm}
      onAbort={onAbort}
      onClick={onCancelOpen}
      disabled={disabled}
    >
      <Column>
        <Txt variant={'bold'}>{booking.bookingNo}</Txt>
        <Txt variant={'bold'}>
          {booking.sailing.route.longDescription} ({booking.sailing.route.code})
        </Txt>
        <Txt variant={'bold'}>
          {formatDate(booking.sailing.departureDate, dateFormat)} at {booking.sailing.departureTime}
        </Txt>
      </Column>
    </CancelButtonWithPopover>
  );
};
