import { graphql } from '../../gql';

export const createCustomsLinkUserTokenMutation = graphql(/* GraphQL */ `
  mutation CreateCustomsLinkUserToken($bookingNo: Int!) {
    createCustomsLinkUserToken(bookingNo: $bookingNo) {
      success
      redirectUrl
    }
  }
`);
