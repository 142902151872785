import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateSettings } from '../../common/api';
import { assertIsDefined } from '../../common/asserts/assert';
import { trackEvent } from '../../common/tracking/trackerService';
import { StoreState } from '../../store/storeState';
import { UpdateSettings_updateSettings } from '../../use-cases/settings/graphql/gql/UpdateSettings';
import { getChangedFormFields } from '../../use-cases/settings/hooks/useSettingsFormState';
import { getFieldsFromSettingsPart } from '../../use-cases/settings/utils/getFieldsFromSettingsPart';
import { transformFormStateToSettingsInput } from '../../use-cases/settings/utils/transformFormStateToSettingsInput';
import { transformToSettingsFormState } from '../../use-cases/settings/utils/transformToSettingsFormState';
import { setFilter } from '../booking-filter/filterActions';
import { transformDefaultFiltersFromSettings } from '../booking-filter/utils/filterUtils';
import {
  getSettingsEditedFormState,
  getSettingsInitialFormState,
  SettingsFormState,
  SettingsPart,
} from './settingsReducer';

interface UpdateSettingsRequest {
  part: SettingsPart;
}

export const updateSettingsThunk = createAsyncThunk<
  {
    updateResult: UpdateSettings_updateSettings;
    formState?: SettingsFormState;
  },
  UpdateSettingsRequest,
  { state: StoreState }
>('update-settings', async ({ part }, { getState, dispatch }) => {
  const state = getState();
  const editedFormState = getSettingsEditedFormState(state);
  const initialFormState = getSettingsInitialFormState(state);

  assertIsDefined(editedFormState, 'Form state is not initialized?');

  const changedFields = getChangedFormFields(initialFormState, editedFormState);

  const settingsInput = transformFormStateToSettingsInput(
    getFieldsFromSettingsPart(editedFormState, part),
  );

  try {
    const updateSettingsResult = await updateSettings(settingsInput);

    changedFields.forEach(changeField => {
      trackEvent({
        category: 'Settings',
        action: `Save/${updateSettingsResult.success ? 'Success' : 'Fail'}`,
        label: changeField,
      });
    });

    const settings = updateSettingsResult.settings;
    if (settings) {
      dispatch(setFilter(transformDefaultFiltersFromSettings(settings)));
    }

    return {
      updateResult: updateSettingsResult,
      formState: settings ? transformToSettingsFormState(settings) : undefined,
    };
  } catch (e) {
    trackEvent({
      category: 'Settings',
      action: 'Save/Fail',
      label: 'Generic Fail',
    });

    throw e;
  }
});
