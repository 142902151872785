import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { PRINT_HIDE_CLASSNAME } from '../../globalStyles';
import { Permission } from '../../services/authentication/utils/authUtils';
import { useIntermodalBookingsDetails } from '../../services/intermodal/hooks/useIntermodalBookingsDetails';
import { initializeUpdateIntermodalFormState } from '../../services/intermodal/updateIntermodalReducer';
import { useAppDispatch } from '../../store/appDispatch';
import { UpdateIntermodalFooter } from '../../use-cases/intermodal/update/UpdateIntermodalFooter';
import { UpdateIntermodalHeader } from '../../use-cases/intermodal/update/UpdateIntermodalHeader';
import { transformIntermodalBookingToUpdateFormState } from '../../use-cases/intermodal/utils/detailsUtils';
import { commonProps } from '../../use-cases/manage/details/components/BookingDetailsDecider';
import { renderBookingErrorModal } from '../../use-cases/manage/details/components/BookingErrorModal';
import { Modal } from '../../use-cases/manage/details/components/modal/Modal';
import { usePageTitle } from '../routes/PageTitle';
import { useHasPermission } from '../statistics/useHasPermission';
import { EditableProvider } from './EditableContext';
import { useRouteModal } from './useRouteModal';
import { IntermodalBookingUpdate } from '../../use-cases/intermodal/update/IntermodalBookingUpdate';
import { useGoodsTypesBackgroundQuery } from '../../services/intermodal/hooks/useGoodsTypes';
import { useIntermodalVehicleTypesBackgroundQuery } from '../../services/intermodal/hooks/useAllIntermodalVehicleTypesAsOptions';

export const IntermodalBookingUpdateModal = () => {
  const { bookingNo: bookingNoString } = useParams<'bookingNo'>();
  const { onClose } = useRouteModal();
  usePageTitle(`Intermodal booking ${bookingNoString}`);
  const dispatch = useAppDispatch();
  const getHasPermission = useHasPermission();
  const hasPermission = getHasPermission(Permission.EditIntermodalBookings);

  const goodsTypesQueryRef = useGoodsTypesBackgroundQuery();
  const vehicleTypesQueryRef = useIntermodalVehicleTypesBackgroundQuery();

  const { booking, loading, error, refetch } = useIntermodalBookingsDetails(
    Number(bookingNoString),
    data => {
      if (data.viewer.intermodalBookings.booking) {
        dispatch(
          initializeUpdateIntermodalFormState(
            transformIntermodalBookingToUpdateFormState(data.viewer.intermodalBookings.booking),
          ),
        );
      }
    },
  );

  return (
    <EditableProvider value={booking?.editable === true && hasPermission}>
      {loading || error || !booking ? (
        renderBookingErrorModal(loading, error, onClose)
      ) : (
        <Modal
          className={'t_intermodal_edit_modal'}
          {...commonProps}
          closeButtonClassName={cx('t_close_intermodal_details_edit', PRINT_HIDE_CLASSNAME)}
          onRequestClose={onClose}
          header={
            <UpdateIntermodalHeader booking={booking} className={'t_update_intermodal_header'} />
          }
          footer={<UpdateIntermodalFooter booking={booking} refetch={refetch} />}
        >
          <IntermodalBookingUpdate
            booking={booking}
            goodsTypesQueryRef={goodsTypesQueryRef}
            vehicleTypesQueryRef={vehicleTypesQueryRef}
          />
        </Modal>
      )}
    </EditableProvider>
  );
};
