import { useQuery } from '@apollo/client';
import { SearchBookingsQueryQuery } from '../../gql/graphql';
import { formatSearchQueryForServerString } from '../utils/stringUtil';
import { BasicBooking } from './fragments/gql/BasicBooking';
import { searchBookingsQuery } from './searchBookingsQuery';
import { QueryResult } from './types/QueryResult';

export interface SearchBookingsResult {
  activeTransportsCount: number;
  activeTransportsItems: BasicBooking[];
  historicTransportsCount: number;
  historicTransportsItems: BasicBooking[];
}

const EMPTY_RESULT: SearchBookingsResult = {
  activeTransportsCount: 0,
  activeTransportsItems: [],
  historicTransportsCount: 0,
  historicTransportsItems: [],
};

function transformOptions(data: SearchBookingsQueryQuery | undefined): SearchBookingsResult {
  if (data?.viewer?.search?.byQuery) {
    return {
      activeTransportsCount: data.viewer.search.byQuery.activeTransports.count,
      activeTransportsItems: data.viewer.search.byQuery.activeTransports.items,
      historicTransportsCount: data.viewer.search.byQuery.transportHistory.count,
      historicTransportsItems: data.viewer.search.byQuery.transportHistory.items,
    };
  } else {
    return EMPTY_RESULT;
  }
}

export const MINIMUM_SEARCH_LENGTH = 2;
export const MAXIMUM_SEARCH_LENGTH = 30;

export const searchQueryHasValidMinLength = (value: string) =>
  value.length >= MINIMUM_SEARCH_LENGTH;

export const useSearchBookingsResult = (searchQuery: string): QueryResult<SearchBookingsResult> => {
  const formattedQuery = formatSearchQueryForServerString(searchQuery);

  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useQuery(searchBookingsQuery, {
    fetchPolicy: 'network-only',
    skip: !searchQueryHasValidMinLength(formattedQuery),
    variables: {
      query: formattedQuery,
    },
  });

  return [transformOptions(data), loading, error];
};
