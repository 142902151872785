import { Row, Txt } from '@stenajs-webui/core';
import { FC } from 'react';
import { SearchResultColumn } from '../utils/searchResultColumns';
import { getGridColumnLabel } from '../../../../common/utils/columns/gridColumns';

export interface BookingSearchTableHeaderRowProps {
  searchColumns: SearchResultColumn[];
}

export const BookingSearchTableHeaderRow: FC<BookingSearchTableHeaderRowProps> = ({
  searchColumns,
}) => {
  return (
    <Row indent={2}>
      {searchColumns.map(column => {
        return (
          <Row
            className={column.columnId}
            alignItems={'center'}
            spacing
            key={column.columnId}
            width={column.remWidth + 'rem'}
            height={40}
          >
            <Txt size={'small'}>{getGridColumnLabel(column.columnId)}</Txt>
          </Row>
        );
      })}
    </Row>
  );
};
