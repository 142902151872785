import { ApolloError, isApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { first } from 'lodash';
import { HttpError } from './HttpError';

export interface ErrorWithStatusCode {
  message: string;
  statusCode: HttpError;
}

export const transformGraphqlError = (error: GraphQLError | Error): ErrorWithStatusCode => {
  if ('extensions' in error) {
    return {
      message: error.message,
      statusCode: error.extensions.code as HttpError,
    };
  }

  return { statusCode: HttpError.InternalServerError, message: error.message };
};

export const getGenericErrorFromApolloError = (error: ApolloError): string => {
  const graphQLError = first(error.graphQLErrors);
  if (graphQLError) {
    const errorDetails = transformGraphqlError(graphQLError);

    switch (errorDetails.statusCode) {
      case HttpError.Unauthorized:
      case HttpError.FileNotFound:
      case HttpError.Forbidden:
        return 'You do not have access to this page.';

      case HttpError.InternalServerError:
      default:
        return 'Something went wrong. Please try again later.';
    }
  } else {
    return 'Something went wrong. Please try again later.';
  }
};

export const getStringErrors = (error: Error | ApolloError): string[] => {
  if (isApolloError(error)) {
    return error?.graphQLErrors.map(e => e.message);
  }
  return [error.message];
};
