import { Options } from 'react-select';
import {
  DropdownOption,
  transformCustomer,
} from '../../../use-cases/manage/grid/utils/optionTransformers';
import { Customer } from '../../../common/graphql/fragments/gql/Customer';
import { useMemo } from 'react';
import { useAllIntermodalCustomers } from './useAllIntermodalCustomers';

export const useAllIntermodalCustomersAsOptions = (): Options<DropdownOption<Customer>> => {
  const customers = useAllIntermodalCustomers();

  return useMemo(() => customers.map(transformCustomer), [customers]);
};
