import { useCallback, useState } from 'react';

function getValueFromSessionStorage(key: string, storage: Storage) {
  if (typeof storage === 'undefined') {
    return null;
  }
  const storedValue = storage.getItem(key) || 'null';
  try {
    return JSON.parse(storedValue);
  } catch (error) {
    console.error(error);
  }

  return storedValue;
}

function saveValueToSessionStorage(key: string, value: any, storage: Storage) {
  if (typeof storage === 'undefined') {
    return null;
  }

  return storage.setItem(key, JSON.stringify(value));
}

function initialize<S>(key: string, initialState: S, storage: Storage) {
  const valueLoadedFromSessionStorage = getValueFromSessionStorage(key, storage);
  if (valueLoadedFromSessionStorage === null) {
    return initialState;
  } else {
    return valueLoadedFromSessionStorage;
  }
}

export function usePersistedStorageState<S>(
  key: string,
  initialState?: S | (() => S),
  storage = sessionStorage,
): [S, (v: S) => void, () => void] {
  const [value, __setValue] = useState(() => initialize(key, initialState, storage));

  const setValue = useCallback(
    (newValue: S) => {
      __setValue(newValue);
      saveValueToSessionStorage(key, newValue, storage);
    },
    [key, storage],
  );

  const remove = useCallback(() => {
    storage.removeItem(key);
  }, [key, storage]);

  return [value, setValue, remove];
}
