import { FC, useRef } from 'react';
import {
  Cardy,
  CardyBody,
  FlatButton,
  Icon,
  stenaMobile,
  stenaSailingVehicle,
} from '@stenajs-webui/elements';
import { Box, Row, Txt, useBoolean } from '@stenajs-webui/core';
import { CopyBookingLinkButton } from '../../share/components/CopyBookingLinkButton';
import { FreightPortalPopover } from '../../../../common-ui/popover/FreightPortalPopover';
import { ShareBookingPanel } from '../../share/components/ShareBookingPanel';

export interface ShareBookingProps {
  bookingNo: number;
  vehicleRegNo: string;
}

export const ShareBookingCard: FC<ShareBookingProps> = ({ bookingNo, vehicleRegNo }) => {
  const [isModalOpen, openModal, closeModal] = useBoolean(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Cardy color={'--lhds-color-blue-50'}>
        <CardyBody gap={2}>
          <Row gap={2}>
            <Icon icon={stenaSailingVehicle} size={24} />
            <Box width={200} flexGrow={1}>
              <Txt size={'small'}>
                Send this booking directly to the Driver app or copy the link and share via
                WhatsApp, email, etc.
              </Txt>
            </Box>
          </Row>
          <CopyBookingLinkButton
            trackerCategory={'Details'}
            bookingNo={bookingNo}
            vehicleRegNo={vehicleRegNo}
          />
          <FlatButton
            ref={buttonRef}
            leftIcon={stenaMobile}
            label={'Send to Driver app'}
            onClick={openModal}
          />
        </CardyBody>
      </Cardy>
      <FreightPortalPopover
        reference={buttonRef}
        placement={'bottom-end'}
        visible={isModalOpen}
        onRequestClose={closeModal}
        content={
          <ShareBookingPanel
            onClose={closeModal}
            bookingNo={bookingNo}
            vehicleRegNo={vehicleRegNo}
            trackerCategory={'Details'}
            showCopyBookingLink={false}
          />
        }
      />
    </>
  );
};
