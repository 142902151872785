import { Column } from '@stenajs-webui/core';
import { FC } from 'react';
import { SearchBookingsResult } from '../../../../common/graphql/useSearchBookingsResult';
import { InvalidSearchQueryReason } from '../../../../common/utils/stringUtil';
import { BookingSearchListInnerContent } from './BookingSearchListInnerContent';
import { SearchQueryInformation } from './SearchQueryInformation';
import { ApolloError } from '@apollo/client';
import { SearchResultColumn } from '../utils/searchResultColumns';

export interface BookingSearchListProps {
  onClickResult: (bookingNo: number) => void;
  searchQuery: string;
  invalidSearchQueryReason: InvalidSearchQueryReason | null;
  onClickActiveTransports: () => void;
  onClickTransportHistory: () => void;
  loading: boolean;
  data: SearchBookingsResult;
  error: ApolloError | undefined;
  searchColumns: SearchResultColumn[];
}

export const BookingSearchList: FC<BookingSearchListProps> = ({
  onClickResult,
  searchColumns,
  loading,
  data,
  onClickActiveTransports,
  onClickTransportHistory,
  error,
  invalidSearchQueryReason,
  searchQuery,
}) => (
  <Column spacing indent>
    <SearchQueryInformation invalidSearchQueryReason={invalidSearchQueryReason} />

    <BookingSearchListInnerContent
      searchColumns={searchColumns}
      loading={loading}
      error={error}
      data={data}
      searchQuery={searchQuery}
      onClickResult={onClickResult}
      onTransportClick={onClickActiveTransports}
      onHistoryClick={onClickTransportHistory}
    />
  </Column>
);
