import { useMemo } from 'react';
import { Options } from 'react-select';
import { GroupedOptionsType } from '../../common-ui/form-fields/Dropdown';
import { useAccount } from '../../services/settings/hooks/useAccount';
import { DropdownOption } from '../../use-cases/manage/grid/utils/optionTransformers';
import { addFavouritesToOptions } from '../utils/DropdownUtils';
import { Route } from './fragments/gql/Route';
import { QueryResult } from './types/QueryResult';
import { useAllRoutesAsOptions } from './useAllRoutesAsOptions';

export const useAllRoutesAsOptionsWithFavoriteOptions = (
  skip: boolean = false,
): QueryResult<GroupedOptionsType<DropdownOption<Route>> | Options<DropdownOption<Route>>> => {
  const settings = useAccount();
  const [options, loading, error] = useAllRoutesAsOptions(skip);

  return useMemo(() => {
    if (
      settings.accountSettings.favouriteRoutes &&
      settings.accountSettings.favouriteRoutes.length > 0
    ) {
      const optionsWithFavourites = addFavouritesToOptions(
        options,
        settings.accountSettings.favouriteRoutes.map(route => route.code),
      );
      return [optionsWithFavourites, loading, error];
    }
    return [options, loading, error];
  }, [error, loading, options, settings.accountSettings.favouriteRoutes]);
};
