import { graphql } from '../../../gql';

export const userSubscriptionsMutation = graphql(/* GraphQL */ `
  mutation UserSubscriptionsMutation($userSubscriptions: UserSubscriptionsInput!) {
    updateSubscriptionSettings(input: $userSubscriptions) {
      success
      settings {
        routes {
          id
        }
        ...UserSubscriptions
      }
    }
  }
`);
