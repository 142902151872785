import { BookingStatus } from '../../gql/graphql';
import { CssPropColor } from '@stenajs-webui/theme/dist/util/CssColor';

export const journeyLineColor = (bookingStatus: BookingStatus | null | undefined): CssPropColor => {
  if (!bookingStatus) {
    return '--black';
  }
  switch (bookingStatus) {
    case BookingStatus.BlockBooking:
    case BookingStatus.Waitlist:
    case BookingStatus.CheckedIn:
    case BookingStatus.CheckedInWaitlist:
    case BookingStatus.Confirmed:
    case BookingStatus.Shipped:
      return '--black';
    case BookingStatus.NoShow:
      return '--lhds-color-red-500';
    case BookingStatus.Cancelled:
      return '--lhds-color-ui-400';
  }
};
