import { FC, useEffect } from 'react';

// Note: Before this component is rendered, the title tag in index.html is used.
const PAGE_TITLE_SUFFIX = 'Freight Portal';

export const usePageTitle = (title: string) => {
  useEffect(() => {
    const oldTitle = document.title;
    document.title = [title, PAGE_TITLE_SUFFIX].join(' - ');

    return () => {
      document.title = oldTitle;
    };
  }, [title]);
};

export const PageTitle: FC<{ title: string }> = ({ title }) => {
  usePageTitle(title);
  return null;
};
