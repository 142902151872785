import { UpdatePageSettings2Input } from '../../../../gql/graphql';

export const mergeUpdatePageSettingsInputs = (
  a: UpdatePageSettings2Input,
  b: UpdatePageSettings2Input,
): UpdatePageSettings2Input => ({
  column: { ...a.column, ...b.column },
  tour: { ...a.tour, ...b.tour },
  grid: { ...a.grid, ...b.grid },
  sidebar: { ...a.sidebar, ...b.sidebar },
});
