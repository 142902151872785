import { Box, Heading, Row, SeparatorLine, Txt } from '@stenajs-webui/core';
import { FC, useRef, useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import { AsyncImage } from '../../../../../common-ui/AsyncImage';
import { GatePhotosFragment } from '../../../../../common/graphql/fragments/gql/DetailedBooking';
import { formatDateTime } from '../../../../../common/utils/dates/dateUtils';
import { trackEvent } from '../../../../../common/tracking/trackerService';

interface Props {
  hasCapability: boolean;
  gatePhotoResult: GatePhotosFragment | null;
  fallbackLocationName: string;
  locationSuffix: string;
  dateFormat: string;
}

export const PortPicturesLocation: FC<Props> = ({
  hasCapability,
  fallbackLocationName,
  gatePhotoResult,
  dateFormat,
  locationSuffix,
}) => {
  const [imageIndex, setImageIndex] = useState<number | null>(null);
  const hasOpenedRef = useRef(false);

  const onImageClick = (index: number) => {
    setImageIndex(index);
    trackEvent({ category: 'GatePhotos', action: 'Thumbnail clicked', label: `Index ${index}` });
  };

  const onImageZoom = () => {
    trackEvent({ category: 'GatePhotos', action: 'Carousel zoom' });
  };

  const onImageView = () => {
    // Skip tracking for initial image view as it is not a carousel step
    if (!hasOpenedRef.current) {
      hasOpenedRef.current = true;
      return;
    }

    trackEvent({ category: 'GatePhotos', action: 'Carousel step' });
  };

  const onImageClose = () => {
    hasOpenedRef.current = false;
    setImageIndex(null);
  };

  const slides = gatePhotoResult?.photos.map(p => ({ src: p.originalUrl })) ?? [];
  return (
    <Box
      onKeyDown={e => {
        if (imageIndex != null) {
          e.stopPropagation();
        }
      }}
    >
      <Box indent={3} spacing={3}>
        {gatePhotoResult?.date && (
          <Txt size={'small'} variant={'overline'}>
            {formatDateTime(gatePhotoResult.date, dateFormat)}
          </Txt>
        )}
        <Heading variant={'h4'} as={'h2'}>
          {gatePhotoResult?.location.name ?? fallbackLocationName} {locationSuffix}
        </Heading>
      </Box>
      <SeparatorLine />
      <Row flexWrap={'wrap'} gap={2} indent={3} spacing={3}>
        {!hasCapability ? (
          <Txt>Gate photos are not available for this port</Txt>
        ) : gatePhotoResult?.photos == null ? (
          <Txt>There are no photos available for this unit</Txt>
        ) : (
          gatePhotoResult.photos.map((p, index) => (
            <AsyncImage
              key={index}
              src={p.thumbnailUrl}
              alt={p.angle}
              style={{
                height: 100,
                maxWidth: '100%',
                objectFit: 'cover',
                objectPosition: 'left top',
                cursor: 'pointer',
              }}
              onClick={() => onImageClick(index)}
            />
          ))
        )}
      </Row>
      <Lightbox
        open={imageIndex != null}
        index={imageIndex ?? undefined}
        close={onImageClose}
        slides={slides}
        plugins={[Zoom]}
        carousel={{ finite: true }}
        controller={{ closeOnBackdropClick: true }}
        on={{
          zoom: onImageZoom,
          view: onImageView,
        }}
      />
    </Box>
  );
};
