import { TextInputButton, TextInputButtonSize } from '@stenajs-webui/elements';
import { FC, Ref } from 'react';
import { getInformationMessageInfo, InformationMessageType } from './informationMessageUtils';

export interface InformationMessageIconProps {
  type: InformationMessageType;
  onClick: () => void;
  tabIndex?: number;
  size?: TextInputButtonSize;
  buttonRef: Ref<HTMLButtonElement>;
}

export const InformationMessageIcon: FC<InformationMessageIconProps> = ({
  type,
  buttonRef,
  ...props
}) => {
  const info = getInformationMessageInfo(type);

  return <TextInputButton {...props} ref={buttonRef} variant={info.variant} />;
};
