import { assignWith } from 'lodash';
import { batch } from 'react-redux';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { initialFilterApplied, setFilter } from '../../services/booking-filter/filterActions';
import { FilterState } from '../../services/booking-filter/filterReducer';
import { transformDefaultFiltersFromSettings } from '../../services/booking-filter/utils/filterUtils';
import { rehydratePageSettings } from '../../services/page-settings/pageSettingsActions';
import { transformToPageSettingsRehydratePayload } from '../../services/page-settings/sagas/utils/transformToPageSettingsRehydratePayload';
import { transformToTourReducerState } from '../../services/page-settings/sagas/utils/transformToTourReducerState';
import { initializeSettingsForm, setAccount } from '../../services/settings/settingsReducer';
import { sortBookings } from '../../services/sorting/sortingActions';
import { requestInitializeTour } from '../../services/tour/tourActions';
import { useAppDispatch } from '../../store/appDispatch';
import { StoreState } from '../../store/storeState';
import { getDefaultSortDirection } from '../../use-cases/manage/grid/utils/getDefaultSortDirection';
import { transformToSettingsFormState } from '../../use-cases/settings/utils/transformToSettingsFormState';
import { suspend } from '../../vendor/suspend-react';
import { fetchAccount } from '../api';
import { assertIsDefined } from '../asserts/assert';
import { loadFilter } from '../urlFilterListener';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  StoreState,
  unknown,
  Action<string>
>;

export const useSettingsFetcher = () => {
  const dispatch = useAppDispatch();

  suspend(async () => {
    const account = await fetchAccount();

    assertIsDefined(account, 'Error fetching user account');

    const { accountSettings, pageSettings } = account;

    batch(() => {
      dispatch(setAccount(account));
      dispatch(initializeSettingsForm(transformToSettingsFormState(accountSettings)));
      dispatch(rehydratePageSettings(transformToPageSettingsRehydratePayload(pageSettings)));
      dispatch(requestInitializeTour(transformToTourReducerState(pageSettings.tour)));
      dispatch(
        setFilterFromUrlWithSettingsFallback(transformDefaultFiltersFromSettings(accountSettings)),
      );
    });
  }, ['account']);
};

const setFilterFromUrlWithSettingsFallback =
  (filterState: FilterState): AppThunk =>
  dispatch => {
    const { filter: filterFromURL, sorting: sortingFromURL } = loadFilter();

    batch(() => {
      if (sortingFromURL?.column) {
        dispatch(
          sortBookings(
            sortingFromURL.column,
            sortingFromURL.direction ?? getDefaultSortDirection(sortingFromURL.column),
          ),
        );
      }

      const mergedFilterState = assignWith(
        { ...filterState },
        filterFromURL,
        (objValue, srcValue) => {
          return srcValue != null ? srcValue : objValue;
        },
      );
      dispatch(setFilter(mergedFilterState));
      dispatch(initialFilterApplied());
    });
  };
