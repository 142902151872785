import { graphql } from '../../gql';

export const switchQuery = graphql(/*GraphQL*/ `
  query BookingSwitchableBookingsQuery($bookingNo: Int!) {
    viewer {
      bookings {
        byBookingNo(bookingNo: $bookingNo) {
          ...BasicBooking
        }
        forSwitch(bookingNo: $bookingNo) {
          bookings {
            ...BasicBooking
          }
          errors {
            description
          }
          messages {
            description
          }
          warnings {
            description
          }
        }
      }
    }
  }
`);
export const switchBookingMutation = graphql(/*GraphQL*/ `
  mutation SwitchBookings(
    $confirmedBookingNo: Int!
    $confirmedTimeStamp: String!
    $waitlistedBookingNo: Int!
    $waitlistedTimeStamp: String!
  ) {
    switchBookings(
      switchBookingsInput: {
        confirmedBookingNo: $confirmedBookingNo
        confirmedTimeStamp: $confirmedTimeStamp
        waitlistedBookingNo: $waitlistedBookingNo
        waitlistedTimeStamp: $waitlistedTimeStamp
      }
    ) {
      bookingOutOfDate
      confirmedBooking {
        ...BasicBooking
      }
      errors {
        description
      }
      messages {
        description
      }
      warnings {
        description
      }
      waitlistedBooking {
        ...BasicBooking
      }
      success
    }
  }
`);
