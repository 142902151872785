import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Options } from 'react-select';
import { graphql } from '../../gql';
import { MinimalBooking } from '../../use-cases/manage/details/utils/minimalBooking';
import {
  CustomsClassification,
  DropdownOption,
  transformCustomsClassificationOption,
} from '../../use-cases/manage/grid/utils/optionTransformers';
import { filterValidCustomsClassifications } from '../utils/customsClassificationUtil';
import { getMaybeDateFromDateAndTime } from '../utils/dates/dateUtils';
import { CustomsClassificationsQuery_viewer_routes_byId_ukCustomsClassifications } from './gql/CustomsClassificationsQuery';
import { QueryResult } from './types/QueryResult';

export const customsClassificationsQuery = graphql(/*GraphQL*/ `
  query CustomsClassificationsQuery($routeCode: ID!) {
    viewer {
      routes {
        byId(id: $routeCode) {
          id
          ukCustomsClassifications {
            id
            label
            value
            validFrom
            validTo
          }
        }
      }
    }
  }
`);

export const customsClassificationDropdownEmptyOption: DropdownOption<CustomsClassification> = {
  data: { label: 'Select...', value: 'null' },
  label: 'Select...',
  value: 'null',
};

const getCustomsClassificationOptions = (
  ukCustomsClassifications:
    | CustomsClassificationsQuery_viewer_routes_byId_ukCustomsClassifications[]
    | undefined,
  departureDate: Date | undefined,
) => {
  if (!ukCustomsClassifications || !departureDate) {
    return [];
  }
  return filterValidCustomsClassifications(ukCustomsClassifications, departureDate).map(
    transformCustomsClassificationOption,
  );
};

export const transformCustomsClassificationsOptions = (
  ukCustomsClassifications:
    | CustomsClassificationsQuery_viewer_routes_byId_ukCustomsClassifications[]
    | undefined,
  departureDate: Date | undefined,
  includeEmptyOption: boolean = false,
): Options<DropdownOption<CustomsClassification>> => {
  const options: DropdownOption<CustomsClassification>[] = [];
  if (includeEmptyOption) {
    options.push(customsClassificationDropdownEmptyOption);
  }
  options.push(...getCustomsClassificationOptions(ukCustomsClassifications, departureDate));
  return options;
};

const useDepartureDateTime = (booking?: MinimalBooking) => {
  const departureDate = booking?.departureDate;
  const departureTime = booking?.departureTime;

  return useMemo(
    () => getMaybeDateFromDateAndTime(departureDate, departureTime),
    [departureDate, departureTime],
  );
};

export const useCustomsClassificationsOptions = (
  booking: MinimalBooking | undefined,
  skip: boolean = false,
): QueryResult<Options<DropdownOption<CustomsClassification>>> => {
  const { data, loading, error } = useQuery(customsClassificationsQuery, {
    variables: { routeCode: booking?.route?.code! },
    fetchPolicy: 'cache-first',
    skip: skip || !booking?.route?.code,
  });

  const departureDate = useDepartureDateTime(booking);

  return useMemo(
    () => [
      transformCustomsClassificationsOptions(
        data?.viewer.routes.byId?.ukCustomsClassifications,
        departureDate,
      ),
      loading,
      error,
    ],
    [data, departureDate, error, loading],
  );
};
