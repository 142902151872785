import { FC, useMemo, useRef, useState } from 'react';
import { TrackerCategory, trackEvent } from '../../common/tracking/trackerService';
import { FloatingPortal } from '@floating-ui/react-dom-interactions';
import { LifecycleEffectsParams, Tour, TourDefinition } from './Tour';
import { Step } from './Step';
import { useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBackgroundLocation } from '../../common/home/components/UseBackgroundLocation';
import { SkipFooter } from './SkipFooter';
import { StepFooter } from './StepFooter';
import { zIndices } from '../../common/zIndices';
import { StoreState } from '../../store/storeState';

interface PortalTourProps {
  mainTour: TourDefinition;
  skipTour: TourDefinition;
  active: boolean;
  onFinished: () => void;
  trackerCategory: TrackerCategory;
}

export const getTourRoot = (): HTMLElement => {
  const container = document.getElementById('tour-root');
  if (!container) {
    console.error(`Target element "#tour-root" is not in dom`);

    return document.body;
  }
  return container;
};

export const PortalTour: FC<PortalTourProps> = ({
  mainTour,
  skipTour,
  active,
  onFinished,
  trackerCategory,
}) => {
  const store = useStore<StoreState>();
  const navigate = useNavigate();
  const location = useBackgroundLocation();

  const lifecycleEffectsRef = useRef<LifecycleEffectsParams>({
    store,
    navigate,
    location,
  });

  const [showSkipStep, setShowSkipStep] = useState(false);

  const showTour = active || showSkipStep;

  const tour = useMemo(
    () => (showSkipStep ? skipTour : mainTour),
    [mainTour, showSkipStep, skipTour],
  );

  const onTourFinished = (index: number, skipped: boolean) => {
    if (!showSkipStep) {
      if (skipped) {
        trackEvent({
          category: trackerCategory,
          action: 'Skip',
          label: `At card ${index + 1}`,
        });
        setShowSkipStep(true);
      } else {
        trackEvent({ category: trackerCategory, action: 'Finished' });
      }
      onFinished();
    } else {
      setShowSkipStep(false);
    }
  };

  return (
    <FloatingPortal root={getTourRoot()}>
      {showTour && (
        <Tour
          zIndex={zIndices.tourOverlay}
          lifecycleEffectsRef={lifecycleEffectsRef}
          steps={tour.steps}
          onFinished={onTourFinished}
          renderStep={props => (
            <Step
              {...props}
              renderFooter={footerProps =>
                showSkipStep ? <SkipFooter {...footerProps} /> : <StepFooter {...footerProps} />
              }
            />
          )}
        />
      )}
    </FloatingPortal>
  );
};
