import { searchQueryHasValidMinLength } from '../graphql/useSearchBookingsResult';

export const dotdotdot = (value: string, maxLength: number) => {
  if (value.length > maxLength) {
    return value.substr(0, maxLength) + '…';
  }
  return value;
};

export const formatRegistrationNumberString = (regNo: string): string => {
  return regNo.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
};

export const formatILUCodeString = (code: string): string => {
  return code.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
};

export const removeInvalidSearchQueryCharacters = (query: string): string => {
  return query.replace(/[^a-zA-Z0-9/!#$%&*()_=|:;+^ \\-]/g, '');
};

export type InvalidSearchQueryReason = 'length_too_short' | 'invalid_letter';

export const validateSearchQueryString = (query: string): InvalidSearchQueryReason | null => {
  if (query.length === 0) {
    return null;
  }

  if (!/^[a-zA-Z0-9/!#$%&*()_=|:;+^ \\-]*$/g.test(query)) {
    return 'invalid_letter';
  }

  if (!searchQueryHasValidMinLength(query)) {
    return 'length_too_short';
  }

  return null;
};

export const formatSearchQueryForServerString = (query: string): string => {
  return query.trim();
};
