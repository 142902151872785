import { createContext, useContext } from 'react';
import { FormInstanceId } from '../../services/update-form/updateFormReducer';

const BookingFormContext = createContext<FormInstanceId>({
  bookingNo: 0,
  formId: 'Details',
});

export const BookingFormInstanceIdProvider = BookingFormContext.Provider;

export const useBookingFormInstanceId = () => useContext(BookingFormContext);
