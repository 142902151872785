import { Box, useBoolean } from '@stenajs-webui/core';
import { ButtonSize, FlatButton, stenaInfoCircle } from '@stenajs-webui/elements';
import { FC, useCallback, useRef } from 'react';
import { CalculatePrice_calculatePrice_articlesResults } from '../../common/pricing/graphql/gql/CalculatePrice';
import { TrackerCategory, trackEvent } from '../../common/tracking/trackerService';
import { GQLPrice } from '../../use-cases/payment/hooks/usePaymentStatus';
import { formatGQLPrice } from '../../use-cases/payment/utils/paymentUtil';
import { FreightPortalPopover, Placement } from '../popover/FreightPortalPopover';
import { CalculatePriceResult } from './CalculatePriceResult';

export interface CalculatePricePopoverProps {
  articlesResults: CalculatePrice_calculatePrice_articlesResults[];
  trackerCategory: TrackerCategory;
  amountInRouteCurrency: GQLPrice;
  placement?: Placement;
  buttonSize?: ButtonSize;
}

export const CalculatePricePopover: FC<CalculatePricePopoverProps> = ({
  articlesResults,
  amountInRouteCurrency,
  trackerCategory,
  placement,
  buttonSize,
}) => {
  const [visible, open, close] = useBoolean(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onOpen = useCallback(() => {
    open();
    trackEvent({ category: trackerCategory, action: 'Show articles' });
  }, [open, trackerCategory]);

  const inconclusivePrice = articlesResults.some(r => r.warnings.length > 0);

  return (
    <>
      <FlatButton
        ref={buttonRef}
        size={buttonSize}
        leftIcon={inconclusivePrice ? stenaInfoCircle : undefined}
        title={inconclusivePrice ? 'Inconclusive price' : undefined}
        onClick={onOpen}
        label={formatGQLPrice(amountInRouteCurrency)}
      />
      <FreightPortalPopover
        reference={buttonRef}
        lazy
        onRequestClose={close}
        visible={visible}
        placement={placement}
        maxWidth={800}
        content={
          <Box maxHeight={'75vh'} overflowY={'auto'} borderRadius={'var(--swui-border-radius)'}>
            <CalculatePriceResult
              onClose={close}
              amountInRouteCurrency={amountInRouteCurrency}
              articlesResults={articlesResults}
            />
          </Box>
        }
      />
    </>
  );
};
