import { FC } from 'react';
import { useNumericTextInputChange } from '../../../../common/hooks/useNumericTextInputChange';
import { useUpdateIntermodalGoodsField } from '../../hooks/useUpdateIntermodalGoodsFields';
import {
  CommonTextInputField,
  CommonTextInputFieldProps,
} from '../../../../common-ui/form-fields/CommonTextInputField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';

export interface GoodsWeightInputProps
  extends Omit<CommonTextInputFieldProps, 'onChange' | 'trackerAction'> {
  goodsId: string;
}

export const UpdateGoodsWeightInput: FC<GoodsWeightInputProps> = ({ goodsId, ...props }) => {
  const { onChange, ...fieldProps } = useUpdateIntermodalGoodsField(goodsId, 'weight');
  const onTextInputChange = useNumericTextInputChange(onChange, true);

  return (
    <CommonTextInputField
      {...props}
      {...getFieldProps(props, { ...fieldProps, onChange: onTextInputChange })}
      changed={fieldProps.initialValue !== fieldProps.editedValue}
    />
  );
};
