import { useQuery } from '@apollo/client';
import { parseISO } from 'date-fns';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { graphql } from '../../gql';
import { getBookableSailings } from '../utils/sailingUtils';
import { QueryResult } from './types/QueryResult';

const departureDateQuery = graphql(/*GraphQL*/ `
  query AvailableDepartureDatesQuery($filter: SailingsFilter!) {
    viewer {
      sailings {
        byFilter(filter: $filter) {
          items {
            id
            departureDate
            bookable
          }
        }
      }
    }
  }
`);

export const useAllAvailableDepartureDates = (
  fromDate: string | null,
  routeCode: string | null,
  toDate: string | null,
): QueryResult<Date[]> => {
  const { data, loading, error } = useQuery(departureDateQuery, {
    skip: !fromDate || !toDate || !routeCode,
    variables: {
      filter: {
        date: {
          from: fromDate!,
          to: toDate!,
        },
        routeCode: routeCode!,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const dates = useMemo(
    () =>
      uniq(
        getBookableSailings(data?.viewer.sailings.byFilter.items ?? []).map(sailing =>
          parseISO(sailing.departureDate),
        ),
      ),
    [data],
  );

  return [dates, loading, error];
};
