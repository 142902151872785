import { Box, useBoolean } from '@stenajs-webui/core';
import { TextInputButtonSize } from '@stenajs-webui/elements';
import { FC, ReactNode, useRef } from 'react';
import { stopPropagation } from '../../common/eventHelpers';
import { InformationMessageIcon } from './InformationMessageIcon';
import { InformationMessageType } from './informationMessageUtils';
import { FreightPortalPopover, Placement } from '../popover/FreightPortalPopover';
import { PopoverBody } from '../popover/PopoverBody';
import { PopoverTitle } from '../popover/PopoverTitle';

export interface RegistrationNumberAlertInformationIconProps {
  buttonSize?: TextInputButtonSize;
  title?: string;
  iconType: InformationMessageType;
  placement?: Placement;
  onClick?: () => void;
  renderPopupContent: (requestClose: () => void) => ReactNode;
}

export enum RegistrationNumberAlertInformationIconType {
  TravelledVehicleTrailer,
  ArrivalNotepad,
}

export const RegistrationNumberAlertIcon: FC<RegistrationNumberAlertInformationIconProps> = ({
  title,
  iconType = InformationMessageType.RegularInfo,
  buttonSize = 'medium',
  placement = 'left-start',
  onClick,
  renderPopupContent,
}) => {
  const [open, , setClosed, toggle] = useBoolean(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <InformationMessageIcon
        size={buttonSize}
        type={iconType}
        onClick={() => {
          toggle();
          onClick?.();
        }}
        buttonRef={buttonRef}
      />
      <FreightPortalPopover
        reference={buttonRef}
        lazy
        onRequestClose={setClosed}
        visible={open}
        placement={placement}
        content={
          <Box
            className="t_portal_popover"
            minWidth={240}
            maxWidth={420}
            onDoubleClick={stopPropagation}
          >
            <PopoverTitle text={title} onClose={setClosed} />
            <PopoverBody>{renderPopupContent(setClosed)}</PopoverBody>
          </Box>
        }
      />
    </>
  );
};
