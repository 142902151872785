import { DropdownOption } from './optionTransformers';

export const hasValueChanged = <T>(
  initial: DropdownOption<T> | undefined,
  option: DropdownOption<T> | undefined,
) => {
  if (initial === undefined) {
    return option !== undefined;
  } else {
    return option === undefined ? true : option.value !== initial.value;
  }
};
