import { delay, put, takeLatest } from 'redux-saga/effects';
import { clearTempSwitchResult, SwitchSetTempResultAction } from './switchActions';

function* queueClearSwitchTempResult(_action: SwitchSetTempResultAction) {
  yield delay(60000);
  yield put(clearTempSwitchResult());
}

export function* watchSwitchResultSaga() {
  yield takeLatest('SWITCH:SET_TEMP_RESULT', queueClearSwitchTempResult);
}
