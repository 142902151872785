import { useQuery } from '@apollo/client';
import { ResultOf } from '@graphql-typed-document-node/core';
import { useMemo } from 'react';
import { Options } from 'react-select';
import { graphql } from '../../gql';
import {
  DropdownOption,
  LoadingPreference,
  transformLoadingPreferenceOption,
} from '../../use-cases/manage/grid/utils/optionTransformers';
import { QueryResult } from './types/QueryResult';

const loadingPreferenceQuery = graphql(/*GraphQL*/ `
  query LoadingPreferenceQuery($routeCode: String!) {
    viewer {
      loadingPreferences(routeCode: $routeCode) {
        items {
          label
          value
        }
      }
    }
  }
`);
export const loadingPreferenceDropdownNoOption: DropdownOption<LoadingPreference> = {
  data: { label: 'No', value: 'null' },
  label: 'No',
  value: 'null',
};

const transformOptions = (
  data: ResultOf<typeof loadingPreferenceQuery> | undefined,
): Options<DropdownOption<LoadingPreference>> => [
  loadingPreferenceDropdownNoOption,
  ...(data?.viewer?.loadingPreferences?.items ?? []).map(transformLoadingPreferenceOption),
];

export const useLoadingPreferenceOptions = (
  routeCode: string | null | undefined,
  skip: boolean = false,
): QueryResult<Options<DropdownOption<LoadingPreference>>> => {
  const { data, loading, error } = useQuery(loadingPreferenceQuery, {
    fetchPolicy: 'cache-first',
    variables: { routeCode: routeCode! },
    skip: !routeCode || skip,
  });

  return useMemo(() => [transformOptions(data), loading, error], [data, error, loading]);
};
