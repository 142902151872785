import { FC } from 'react';
import { PaymentTableHeaderCell } from '../PaymentTableHeaderCell';

export const PaymentTableHeader: FC = () => {
  return (
    <>
      <PaymentTableHeaderCell label={'Articles'} className={'article'} />
      <PaymentTableHeaderCell label={'Price (excl. VAT)'} className={'ex_vat'} />
      <PaymentTableHeaderCell label={'VAT amount'} className={'vat_amount'} />
      <PaymentTableHeaderCell label={'VAT rate'} className={'vat_rate'} />
      <PaymentTableHeaderCell label={'Price (incl. VAT)'} className={'inc_vat'} />
    </>
  );
};
