import { useMemo } from 'react';
import { Options } from 'react-select';
import {
  DropdownOption,
  transformCustomer,
} from '../../use-cases/manage/grid/utils/optionTransformers';
import { Customer } from './fragments/gql/Customer';
import { useAllCustomers } from './useAllCustomers';

export const useAllCustomersAsOptions = (): Options<DropdownOption<Customer>> => {
  const customers = useAllCustomers();

  return useMemo(() => customers.map(transformCustomer), [customers]);
};
