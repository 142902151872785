import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { graphql } from '../../gql';
import {
  addBookingResultNotification,
  BookingResult,
  requestShowBookingResultToast,
} from '../../services/notifications/notificationsActions';
import { initializeUpdateForm } from '../../services/update-form/updateFormActions';
import { formIdVariants, UpdateFormIdVariant } from '../../services/update-form/updateFormReducer';
import { transformToUpdateBookingFormState } from '../update-information/utils/transformToUpdateBookingFormState';
import { BasicBooking } from './fragments/gql/BasicBooking';

const createInitializeFormActionMapper = (booking: BasicBooking) => (formId: UpdateFormIdVariant) =>
  initializeUpdateForm(
    { bookingNo: booking.bookingNo, formId },
    transformToUpdateBookingFormState(booking),
    booking,
  );

export const cancelBookingMutation = graphql(/*GraphQL*/ `
  mutation CancelBookingMutation($bookingNo: Int!, $timestamp: String!) {
    cancelBooking(bookingNo: $bookingNo, timestamp: $timestamp) {
      booking {
        ...BasicBooking
      }
      bookingOutOfDate
      errors {
        description
      }
      messages {
        description
      }
      warnings {
        description
      }
      success
    }
  }
`);

interface BookingWithBookingNoAndTimestamp {
  bookingNo: number;
  timestamp: string;
}
interface UseCancelBookingParams {
  booking: BookingWithBookingNoAndTimestamp;
  onFail?: () => void;
  onSuccess?: () => void;
}

export const useCancelBooking = ({
  booking: { bookingNo, timestamp },
  onFail,
  onSuccess,
}: UseCancelBookingParams) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const [cancelBooking] = useMutation(cancelBookingMutation, {
    refetchQueries: ['DashboardQuery', 'PaymentDetailsQuery'],
    variables: { bookingNo, timestamp },
  });

  const submit = useCallback(() => {
    setSubmitting(true);
    cancelBooking()
      .then(result => {
        setSubmitting(false);

        if (result.data?.cancelBooking) {
          if (result.data.cancelBooking.success) {
            const cancelledBooking = result.data.cancelBooking.booking!;

            const bookingResult: BookingResult = {
              type: 'cancel-success',
              bookingType: 'booking',
              bookingNo: cancelledBooking.bookingNo,
              messages: result.data.cancelBooking.messages.map(msg => msg.description),
              warnings: result.data.cancelBooking.warnings.map(msg => msg.description),
            };

            batch(() => {
              formIdVariants.forEach(variant => {
                dispatch(createInitializeFormActionMapper(cancelledBooking)(variant));
              });

              dispatch(requestShowBookingResultToast(bookingResult));
              dispatch(addBookingResultNotification(bookingResult));
            });

            onSuccess?.();
          } else {
            const bookingResult: BookingResult = {
              bookingNo,
              bookingType: 'booking',
              type: 'cancel-fail',
              errors: result.data.cancelBooking.errors.map(msg => msg.description),
            };

            batch(() => {
              dispatch(requestShowBookingResultToast(bookingResult));
              dispatch(addBookingResultNotification(bookingResult));
            });

            onFail?.();
          }
        }
      })
      .catch(() => {
        setSubmitting(false);

        onFail?.();
      });
  }, [bookingNo, cancelBooking, dispatch, onFail, onSuccess]);

  return {
    submit,
    submitting,
  };
};
