import { put } from 'redux-saga/effects';
import { UpdatePageSettings2Input } from '../../../gql/graphql';
import { requestSavePageSettings } from '../pageSettingsActions';
import { StopNewFeatureTourAction } from '../../tour/tourActions';

export function* updateWelcomeTourPageSettings() {
  const input: UpdatePageSettings2Input = {
    tour: {
      introductionTourSeen: true,
    },
  };

  yield put(requestSavePageSettings(input));
}

export function* updateFeaturesSeenPageSettings(action: StopNewFeatureTourAction) {
  const input: UpdatePageSettings2Input = {
    tour: {
      featuresSeen: action.payload.seenIds,
    },
  };

  yield put(requestSavePageSettings(input));
}
