import { FC } from 'react';
import { Box } from '@stenajs-webui/core';
import { Icon, stenaExclamationTriangle, stenaLighthouseXl } from '@stenajs-webui/elements';
import { cssColor } from '@stenajs-webui/theme';

interface IntermodalJourneyHeaderIconProps {
  variant: 'error' | 'info';
}

export const IntermodalJourneyHeaderIcon: FC<IntermodalJourneyHeaderIconProps> = ({ variant }) =>
  variant === 'error' ? (
    <Box
      background={cssColor('--lhds-color-ui-50')}
      borderRadius={'50%'}
      shadow={'box'}
      indent={2}
      spacing={2}
    >
      <Icon icon={stenaExclamationTriangle} size={32} />
    </Box>
  ) : (
    <Icon icon={stenaLighthouseXl} size={104} />
  );
