import { createMockCreator } from '../../../common/_mocks/mockUtils';
import { registrationNumberAlertMock } from '../../../common/registrationNumberAlert/_mocks/registrationNumberAlertMock';
import { CreateCandidate } from '../createFormReducer';

export const createCandidateMock: CreateCandidate = {
  cargoWeight: 0,
  customerRef: 'customer ref',
  customsClassification: {
    label: 'customs classification',
    value: 'customs classification',
  },
  hasAnimals: true,
  hasCargoWeight: true,
  hasElectricalPlugin: true,
  hasHazardousGoods: true,
  height: 0,
  length: 0,
  livestockType: {
    label: 'animal',
    value: 'animal',
  },
  loadingListMessage: 'loading list message',
  loadingPreference: {
    label: 'loading preference',
    value: 'loadingPreference',
  },
  meta: {
    errors: [],
    warnings: [],
  },
  numberOfDrivers: 2,
  pluginTemperature: '3',
  receiverCountry: {
    label: 'United Kingdom',
    value: 'en',
  },
  registrationNumberAlert: registrationNumberAlertMock,
  senderCountry: {
    label: 'Holland',
    value: 'nl',
  },
  trailerReg: 'trailer reg',
  vehicleReg: 'vehicle reg',
  vehicleType: null,
  width: 0,
  exportReference: '',
  importReference: '',
};

export const createCreateCandidateMock = createMockCreator(createCandidateMock);
