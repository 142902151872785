export const exhaustSwitchCaseElseThrow = (arg: never) => {
  throw new Error(`Switch unhandled case: ${arg}`);
};

export const exhaustSwitchCaseElseNull = (arg: never) => {
  console.log(`Switch unhandled case: ${arg}`);
  return null;
};

export const exhaustSwitchCase = <T>(arg: never, fallback: T) => {
  console.log(`Switch unhandled case: ${arg}`);
  return fallback;
};
