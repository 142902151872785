import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { cancelIntermodalBookingMutation } from '../graphql/cancelIntermodalBookingMutation';
import {
  addBookingResultNotification,
  BookingResult,
  requestShowBookingResultToast,
} from '../../notifications/notificationsActions';
import { transformIntermodalBookingToUpdateFormState } from '../../../use-cases/intermodal/utils/detailsUtils';
import { setUpdateIntermodalBookingFormValue } from '../updateIntermodalReducer';

interface UseCancelBookingParams {
  bookingNo: number;
  onFail?: () => void;
  onSuccess?: () => void;
}

export const useCancelIntermodalBooking = ({
  bookingNo,
  onFail,
  onSuccess,
}: UseCancelBookingParams) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const [cancelIntermodalBooking] = useMutation(cancelIntermodalBookingMutation, {
    variables: { bookingNo },
  });

  const submit = useCallback(() => {
    setSubmitting(true);
    cancelIntermodalBooking()
      .then(result => {
        setSubmitting(false);

        if (result.data?.cancelIntermodalBooking) {
          if (result.data.cancelIntermodalBooking.success) {
            const cancelledIntermodalBooking = result.data.cancelIntermodalBooking.booking!;

            const bookingResult: BookingResult = {
              type: 'cancel-success',
              bookingType: 'intermodalBooking',
              bookingNo: cancelledIntermodalBooking.bookingNo,
              messages: result.data.cancelIntermodalBooking.messages.map(msg => msg.description),
              warnings: result.data.cancelIntermodalBooking.warnings.map(msg => msg.description),
            };

            batch(() => {
              dispatch(
                setUpdateIntermodalBookingFormValue(
                  transformIntermodalBookingToUpdateFormState(cancelledIntermodalBooking),
                ),
              );

              dispatch(requestShowBookingResultToast(bookingResult));
              dispatch(addBookingResultNotification(bookingResult));
            });

            onSuccess?.();
          } else {
            const bookingResult: BookingResult = {
              bookingNo,
              bookingType: 'intermodalBooking',
              type: 'cancel-fail',
              errors: result.data.cancelIntermodalBooking.errors.map(msg => msg.description),
            };

            batch(() => {
              dispatch(requestShowBookingResultToast(bookingResult));
              dispatch(addBookingResultNotification(bookingResult));
            });

            onFail?.();
          }
        }
      })
      .catch(() => {
        setSubmitting(false);

        onFail?.();
      });
  }, [bookingNo, cancelIntermodalBooking, dispatch, onFail, onSuccess]);

  return {
    submit,
    submitting,
  };
};
