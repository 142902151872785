import { graphql } from '../../../gql';

export const intermodalJourneyQuery = graphql(/* GraphQL */ `
  query IntermodalJourneyQuery($filter: IntermodalJourneyFilterInput!) {
    viewer {
      intermodalJourney {
        byFilter(filter: $filter) {
          ...IntermodalJourneyResultFragment
        }
      }
    }
  }
`);

export const IntermodalJourneyResultFragment = graphql(/*GraphQL*/ `
  fragment IntermodalJourneyResultFragment on IntermodalJourneyResult {
    messages {
      description
    }
    warnings {
      description
    }
    errors {
      description
    }
    journey {
      ...JourneyFragment
    }
    success
  }
`);

export const JourneyFragment = graphql(/*GraphQL*/ `
  fragment JourneyFragment on IntermodalJourney {
    arrivalDate
    arrivalTime
    departureDate
    departureTime
    estimatedArrivalDate
    estimatedArrivalTime
    realArrivalDate
    realArrivalTime
    realDepartureDate
    realDepartureTime
    publicArrivalDate
    publicArrivalTime
    publicDepartureDate
    publicDepartureTime
    route {
      id
      longDescription
    }
    legs {
      ...Leg
    }
  }
`);

export const LegFragment = graphql(/*GraphQL*/ `
  fragment Leg on IntermodalJourneyLeg {
    id
    isTrain
    legNumber
    departureDate
    departureTime
    arrivalDate
    arrivalTime
    publicArrivalDate
    publicArrivalTime
    publicDepartureDate
    publicDepartureTime
    route {
      id
      departureLocation {
        ...Location
      }
      arrivalLocation {
        ...Location
      }
    }
  }
`);
