import { BasicBooking_customsClassification } from '../graphql/fragments/gql/BasicBooking';
import { CustomsClassificationsQuery_viewer_routes_byId_ukCustomsClassifications } from '../graphql/gql/CustomsClassificationsQuery';
import { getDateFromDateAndTime, isItemValidWithinDate } from './dates/dateUtils';
import { Route } from '../graphql/fragments/gql/Route';
import { CustomsClassification } from '../../use-cases/manage/grid/utils/optionTransformers';

export const resolveCustomsClassificationText = (
  customsClassification: BasicBooking_customsClassification | null,
) => {
  return customsClassification?.label ?? 'No';
};

export const filterValidCustomsClassifications = (
  list: CustomsClassificationsQuery_viewer_routes_byId_ukCustomsClassifications[],
  departureDate: Date,
) => {
  return list.filter(isItemValidWithinDate(departureDate));
};

export function shouldClearUkCustomsClassification(
  departureDate: string,
  departureTime: string,
  route: Route,
  previousCustomsClassification: CustomsClassification | null,
): boolean {
  if (!departureDate || !departureTime) {
    return false;
  }

  const date = getDateFromDateAndTime(departureDate, departureTime);

  if (!date || !previousCustomsClassification) {
    return false;
  }

  const validCustomsClassifications = filterValidCustomsClassifications(
    route?.ukCustomsClassifications,
    date,
  );

  if (validCustomsClassifications.length <= 0) {
    return false;
  }

  const previousCustomsClassificationFoundForRoute = validCustomsClassifications.some(
    c => c.value === previousCustomsClassification.value,
  );

  return !previousCustomsClassificationFoundForRoute;
}
