import { FC } from 'react';
import { IntermodalDetailsFieldCell } from '../../common/IntermodalDetailsFieldCell';
import { PartnerBookingStatusTag } from '../PartnerBookingStatusTag';
import { ValueTxt } from '../../common/ValueTxt';
import { IntermodalBookingPartnerBookings } from '../../../../common/graphql/fragments/gql/IntermodalBooking';
import { getOrdinal } from '../../utils/detailsUtils';
import { useAllIntermodalCustomersAsOptions } from '../../../../services/intermodal/hooks/useAllIntermodalCustomersAsOptions';
import { useUpdateIntermodalFieldValue } from '../../../../services/intermodal/hooks/UseUpdateIntermodalFieldValue';
import { UpdateIntermodalTextInputField } from '../fields/UpdateIntermodalTextInputField';
import { customerReferencePlaceHolder } from '../../../../common/utils/customerUtils';
import { transformCustomer } from '../../../manage/grid/utils/optionTransformers';
import { UpdateIntermodalDropdownField } from '../fields/UpdateIntermodalDropdownField';
import { MAX_LENGTH_CUSTOMER_REFERENCE, MAX_LENGTH_LOADING_NOTE } from '../../utils/fieldLengths';

interface DetailsIntermodalDetailsSectionProps {
  partnerBookings: IntermodalBookingPartnerBookings;
  dropOffRef: string | null;
  pickUpRef: string | null;
}

export const UpdateIntermodalDetailsSection: FC<DetailsIntermodalDetailsSectionProps> = ({
  partnerBookings,
  dropOffRef,
  pickUpRef,
}) => {
  const customerOptions = useAllIntermodalCustomersAsOptions();
  const multipleCustomers = customerOptions.length > 1;
  const customer = useUpdateIntermodalFieldValue('customer');

  return (
    <>
      {multipleCustomers && (
        <IntermodalDetailsFieldCell label={'Account'}>
          <UpdateIntermodalDropdownField
            trackerCategory={'IntermodalUpdate'}
            name={'customer'}
            options={customerOptions}
            transformOption={transformCustomer}
          />
        </IntermodalDetailsFieldCell>
      )}
      <IntermodalDetailsFieldCell label={'Customer reference'}>
        <UpdateIntermodalTextInputField
          trackerCategory={'IntermodalUpdate'}
          name={'customerReference'}
          maxLength={MAX_LENGTH_CUSTOMER_REFERENCE}
          placeholder={customerReferencePlaceHolder(customer)}
          showCopyButton
        />
      </IntermodalDetailsFieldCell>
      {partnerBookings?.map((booking, index) => (
        <IntermodalDetailsFieldCell
          key={index}
          label={getOrdinal(index + 1) + ' train booking'}
          customInfoLabel={<PartnerBookingStatusTag partnerBookingStatus={booking.status} />}
        >
          <ValueTxt value={booking.number} />
        </IntermodalDetailsFieldCell>
      ))}
      <IntermodalDetailsFieldCell label={'Drop off reference'}>
        <ValueTxt value={dropOffRef} />
      </IntermodalDetailsFieldCell>
      <IntermodalDetailsFieldCell label={'Pick up reference'}>
        <ValueTxt value={pickUpRef} />
      </IntermodalDetailsFieldCell>
      <IntermodalDetailsFieldCell label={'Loading note'}>
        <UpdateIntermodalTextInputField
          maxLength={MAX_LENGTH_LOADING_NOTE}
          name={'loadingNote'}
          trackerCategory={'IntermodalUpdate'}
        />
      </IntermodalDetailsFieldCell>
    </>
  );
};
