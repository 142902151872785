import { Box, Column, Heading, Row, Space, Txt } from '@stenajs-webui/core';
import { CloseButton, SecondaryButton } from '@stenajs-webui/elements';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../common-ui/images/logo_blue_text.svg';
import { trackEvent } from '../../common/tracking/trackerService';
import { getBookingsFilterQueryResult } from '../../services/grid/gridReducer';
import { requestStopWelcomeTour, startWelcomeTour } from '../../services/tour/tourActions';

export interface IntroductionModalProps {
  onClose: () => void;
}

export const WelcomeModal: FC<IntroductionModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const bookingQueryResult = useSelector(getBookingsFilterQueryResult);
  const gridResultAvailable = bookingQueryResult?.result;

  const onStartTourClick = () => {
    trackEvent({ category: 'WelcomeTour', action: 'Start' });
    dispatch(startWelcomeTour());
    onClose();
  };

  const onCloseClick = () => {
    trackEvent({ category: 'WelcomeTour', action: 'Ignore' });
    dispatch(requestStopWelcomeTour());
    onClose();
  };

  if (!gridResultAvailable) {
    return null;
  }

  return (
    <Column className={'t_introduction_tour_modal'} width={624} maxWidth={'100%'} gap={3}>
      <Row justifyContent={'space-between'}>
        <Box indent={3} spacing={3}>
          <img style={{ width: 120 }} src={logo} alt={'Stena Line'} />
        </Box>
        <Box spacing={1} indent={1}>
          <CloseButton onClick={onCloseClick} />
        </Box>
      </Row>
      <Row justifyContent={'center'}>
        <Column indent={3} gap={1}>
          <Heading variant={'h2'}>Welcome to the new and improved Freight Portal!</Heading>
          <Txt>
            We're happy to introduce the updated Stena Line Freight Portal, enhancing your
            experience with improved usability. With this change, you can expect better readability,
            efficiency, and task focus while retaining all familiar features. A few examples:
          </Txt>
          <Heading variant={'h4'}>Enhanced Accessibility</Heading>
          <Txt>Crisper, larger text, clearer colors, and improved contrast.</Txt>
          <Heading variant={'h4'}>Better Zoom</Heading>
          <Txt>
            Customize your Freight Portal view with browser zoom options for ultimate flexibility.
          </Txt>
          <Heading variant={'h4'}>Easier notifications overview</Heading>
          <Txt>
            A more organized notifications panel with color feedback and unread message indicators.
          </Txt>
          <Space />
          <Txt>Are you new to Freight Portal or want a refresh? Take our tour.</Txt>
        </Column>
      </Row>
      <Box spacing={2} alignSelf={'center'}>
        <SecondaryButton
          autoFocus
          className={'t_start_introduction_tour'}
          disabled={!gridResultAvailable}
          label={'Start the introduction'}
          onClick={onStartTourClick}
        />
        <Space num={2} />
      </Box>
    </Column>
  );
};
