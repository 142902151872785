import styled from '@emotion/styled';
import { Box, Column, Space } from '@stenajs-webui/core';
import { Badge, stenaBell, Tab, TabMenu } from '@stenajs-webui/elements';
import { Drawer, DrawerHeader } from '@stenajs-webui/modal';
import { FC, useState } from 'react';
import { trackEvent } from '../../../common/tracking/trackerService';
import { zIndices } from '../../../common/zIndices';
import {
  BookingResultNotification,
  IdsRecord,
  UrgentMessageNotification,
} from '../../../services/notifications/notificationsReducer';
import { NotificationsGroupedByDate } from '../utils/getSortedNotifications';
import { getUnreadCount } from '../utils/getUnreadCount';
import { BookingResultNotifications } from './BookingResultNotifications';
import { Tabs } from './NotificationCenterIcon';
import { UrgentMessageNotifications } from './UrgentMessageNotifications';

const StyledScrollBox = styled(Box)`
  overflow-y: auto;

  background:
/* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size:
    100% 40px,
    100% 40px,
    100% 14px,
    100% 14px;

  /* Opera doesn’t support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
`;

export interface NotificationCenterProps {
  isOpen: boolean;
  onClose: () => void;
  readIds: IdsRecord;
  urgentMessageNotifications: NotificationsGroupedByDate<UrgentMessageNotification>[];
  bookingResultNotifications: NotificationsGroupedByDate<BookingResultNotification>[];
}

export const NotificationCenter: FC<NotificationCenterProps> = ({
  isOpen,
  onClose,
  readIds,
  urgentMessageNotifications,
  bookingResultNotifications,
}) => {
  const [activeTab, setActiveTab] = useState<Tabs>('bookingInformation');

  const setActiveTabWithTracking = (tab: Tabs) => {
    trackEvent({
      category: 'NotificationCenter',
      action: `Open tab (${tab === 'bookingInformation' ? 'bookings' : 'urgent messages'})`,
    });

    setActiveTab(tab);
  };

  const unreadCount = getUnreadCount(urgentMessageNotifications, readIds);
  return (
    <Drawer
      isOpen={isOpen}
      slideFrom={'right'}
      onRequestClose={onClose}
      zIndex={zIndices.drawer}
      width={'380px'}
    >
      <Box flex={1} maxHeight={'100%'}>
        <DrawerHeader icon={stenaBell} header={'Notifications'} onRequestClose={onClose} />
        <Column indent={2}>
          <TabMenu>
            <Tab
              label={'Bookings'}
              selected={activeTab === 'bookingInformation'}
              onClick={() => setActiveTabWithTracking('bookingInformation')}
            />
            <Tab
              label={'Urgent message'}
              selected={activeTab === 'urgentMessage'}
              onClick={() => setActiveTabWithTracking('urgentMessage')}
              right={
                unreadCount > 0 ? (
                  <>
                    <Space />
                    <Badge label={unreadCount} />
                  </>
                ) : null
              }
            />
          </TabMenu>
        </Column>
        <StyledScrollBox flex={1}>
          {activeTab === 'bookingInformation' && (
            <BookingResultNotifications
              notificationGroups={bookingResultNotifications}
              onClose={onClose}
            />
          )}
          {activeTab === 'urgentMessage' && (
            <UrgentMessageNotifications
              notificationGroups={urgentMessageNotifications}
              readIds={readIds}
            />
          )}
        </StyledScrollBox>
      </Box>
    </Drawer>
  );
};
