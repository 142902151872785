import { SpecialBookingIconMeta } from '../use-cases/manage/grid/utils/bookingSpecialProperties';
import { Icon } from '@stenajs-webui/elements';

export const IconWithDescription = ({ iconInfo }: { iconInfo: SpecialBookingIconMeta | null }) => {
  if (!iconInfo) {
    return null;
  }

  return (
    <div title={iconInfo.description}>
      <Icon icon={iconInfo.icon} size={16} />
    </div>
  );
};
