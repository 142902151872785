import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createIntermodalBookingThunk } from './thunks/createIntermodalBookingThunk';
import { updateIntermodalBookingThunk } from './thunks/updateIntermodalBookingThunk';
import { StoreState } from '../../store/storeState';
import { RowStatus } from '../../use-cases/manage/grid/utils/tableRowUtil';

enum Reason {
  Created,
  Updated,
}

export interface IntermodalUiStatusReducerState {
  results: Record<number, Reason | undefined>;
  showAllLegs: boolean;
}

export const INITIAL_VALUE: IntermodalUiStatusReducerState = {
  results: {},
  showAllLegs: false,
};

const slice = createSlice({
  name: 'intermodalUiStatus',
  initialState: INITIAL_VALUE,
  reducers: {
    clear(state, action: PayloadAction<number>) {
      delete state.results[action.payload];
    },
    toggleShowAllLegs(state) {
      state.showAllLegs = !state.showAllLegs;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(createIntermodalBookingThunk.fulfilled, (state, action) => {
        state.results[action.payload.booking.bookingNo] = Reason.Created;
      })
      .addCase(updateIntermodalBookingThunk.fulfilled, (state, action) => {
        state.results[action.payload.booking.bookingNo] = Reason.Updated;
      }),
});

export const { clear, toggleShowAllLegs } = slice.actions;
export const intermodalGridStatusReducer = slice.reducer;

export const getIntermodalUiStatus = (state: StoreState) => state.intermodalUiStatus;

export const getIntermodalGridRowStatus = (state: StoreState, bookingNo: number): RowStatus => {
  switch (getIntermodalUiStatus(state).results[bookingNo]) {
    case Reason.Updated:
      return 'recently-updated';
    case Reason.Created:
      return 'recently-created';
    default:
      return 'no-status';
  }
};

export const getIntermodalUiShowAllLegs = (state: StoreState) =>
  getIntermodalUiStatus(state).showAllLegs;
