import { Customer } from '../../common/graphql/fragments/gql/Customer';
import { Route } from '../../common/graphql/fragments/gql/Route';
import { Sailing } from '../../common/graphql/fragments/gql/Sailing';
import { VehicleType } from '../../common/graphql/fragments/gql/VehicleType';
import { ensureAllBookingCandidatesHasValues } from '../../use-cases/create/utils/bookingCandidateUtils';
import {
  Animal,
  CustomsClassification,
  LoadingPreference,
  SenderReceiverCountry,
} from '../../use-cases/manage/grid/utils/optionTransformers';
import { RegistrationNumberAlertFormState } from '../update-form/registrationNumberAlert';
import { CreateFormActions } from './createFormActions';

export interface CreateCandidateMeta {
  errors: string[];
  warnings: string[];
}

export interface CreateCandidate {
  cargoWeight?: number;
  customerRef: string;
  customsClassification: CustomsClassification | null;
  hasAnimals: boolean;
  hasCargoWeight: boolean;
  hasElectricalPlugin: boolean;
  hasHazardousGoods: boolean;
  height?: number;
  length?: number;
  livestockType: Animal;
  loadingListMessage: string;
  loadingPreference: LoadingPreference;
  meta: CreateCandidateMeta;
  numberOfDrivers?: number;
  pluginTemperature: string;
  receiverCountry?: SenderReceiverCountry;
  registrationNumberAlert: RegistrationNumberAlertFormState | null;
  senderCountry?: SenderReceiverCountry;
  tradeWeight?: number;
  trailerReg: string;
  vehicleReg: string;
  vehicleType: VehicleType | null;
  width?: number;
  importReference: string;
  exportReference: string;
}

export type NumericFieldKeys = keyof Pick<
  CreateCandidate,
  'length' | 'height' | 'width' | 'cargoWeight' | 'tradeWeight'
>;

export type StringKeys = keyof Pick<
  CreateCandidate,
  | 'customerRef'
  | 'loadingListMessage'
  | 'pluginTemperature'
  | 'trailerReg'
  | 'vehicleReg'
  | 'importReference'
  | 'exportReference'
>;

export type DropdownKeys = keyof Pick<
  CreateCandidate,
  | 'customsClassification'
  | 'hasHazardousGoods'
  | 'livestockType'
  | 'loadingPreference'
  | 'numberOfDrivers'
  | 'vehicleType'
  | 'senderCountry'
  | 'receiverCountry'
>;

export interface CreateFormState {
  bookings: CreateCandidate[];
  initialBookings: CreateCandidate[];
  customer: Customer | null;
  departureDate?: string;
  sailing: Sailing | null;
  route: Route | null;
}

export const INITIAL_VALUE: CreateFormState = {
  bookings: [],
  initialBookings: [],
  customer: null,
  route: null,
  sailing: null,
};
export const createFormReducer = (
  state: CreateFormState = INITIAL_VALUE,
  action: CreateFormActions,
): CreateFormState => {
  switch (action.type) {
    case 'CREATE_FORM:UPDATE_COMMON_VALUES': {
      return {
        ...state,
        ...action.payload.values,
      };
    }
    case 'CREATE_FORM:UPDATE_ALL_SPECIFIC_VALUES':
    case 'CREATE_FORM:SET_ALL_SPECIFIC_VALUES': {
      return {
        ...state,
        bookings: state.bookings.map(booking => ({
          ...booking,
          ...action.payload.values,
        })),
      };
    }
    case 'CREATE_FORM:SET_SPECIFIC_VALUES':
    case 'CREATE_FORM:UPDATE_SPECIFIC_VALUES': {
      const bookings = [...state.bookings];

      const booking = bookings[action.payload.id];

      bookings[action.payload.id] = {
        ...booking,
        ...action.payload.values,
      };

      return {
        ...state,
        bookings,
      };
    }
    case 'CREATE_FORM:REMOVE_BOOKING_CANDIDATE':
      return {
        ...state,
        bookings: state.bookings.filter((_, index) => index !== action.payload.id),
        initialBookings: state.initialBookings.filter((_, index) => index !== action.payload.id),
      };
    case 'CREATE_FORM:INITIALIZE_CREATE_FORM':
      return action.payload.formState;
    case 'CREATE_FORM:REQUEST_CLEANUP_FORM':
      return INITIAL_VALUE;
    case 'CREATE_FORM:SET_BOOKINGS_TO_KEEP_IN_FORM':
      return {
        ...state,
        bookings: action.payload.bookings,
      };
    case 'CREATE_FORM:SET_NUMBER_OF_BOOKINGS':
      return {
        ...state,
        bookings: ensureAllBookingCandidatesHasValues(
          state.bookings,
          action.payload.numberOfBookings,
        ),
        initialBookings: ensureAllBookingCandidatesHasValues(
          state.initialBookings,
          action.payload.numberOfBookings,
        ),
      };
    case 'CREATE_FORM:UPDATE_REGISTRATION_NUMBER_ALERT': {
      const bookings = [...state.bookings];

      const booking = bookings[action.payload.id];
      const registrationNumberAlert = booking.registrationNumberAlert;
      bookings[action.payload.id] = {
        ...booking,
        ...(registrationNumberAlert
          ? {
              registrationNumberAlert: {
                ...registrationNumberAlert,
                ...action.payload.registrationNumberAlert,
              },
            }
          : {}),
      };
      return { ...state, bookings };
    }
    default:
      return state;
  }
};
