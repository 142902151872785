import { createAsyncThunk } from '@reduxjs/toolkit';
import { first } from 'lodash';
import { assertIsDefined } from '../../../common/asserts/assert';
import { formatServerDate } from '../../../common/utils/dates/dateUtils';
import { StoreState } from '../../../store/storeState';
import { getAccount } from '../../settings/settingsReducer';
import { AccountQuery_viewer_account_byEmail } from '../../../use-cases/settings/graphql/gql/AccountQuery';
import {
  createEmptyCreateFormModel,
  initializeCreateIntermodalFormState,
} from '../createIntermodalReducer';
import { fetchAllIntermodalVehicleTypes } from '../../../common/api';
import { getVehicleDimensions } from '../utils';

function getIntermodalCustomer(account: AccountQuery_viewer_account_byEmail) {
  const bookCustomer = account.accountSettings.bookCustomer;
  return bookCustomer?.isIntermodal
    ? bookCustomer
    : first(account.customers.filter(c => c.isIntermodal));
}

export const initializeIntermodalCreateThunk = createAsyncThunk<
  void,
  { goodId: string },
  { state: StoreState }
>('initializeIntermodalCreateForm', async ({ goodId }, { dispatch, getState }) => {
  const departureDate = formatServerDate(new Date());
  const departureTime = '00:00';
  const defaultVehicleTypeCode = 'TR';

  const account = getAccount(getState());
  assertIsDefined(account, 'Account is not initialized');

  const intermodalCustomer = getIntermodalCustomer(account);
  assertIsDefined(intermodalCustomer, 'Account has no intermodal customer');

  const vehicleTypes = await fetchAllIntermodalVehicleTypes();
  const defaultVehicleType = vehicleTypes.find(vt => vt.code === defaultVehicleTypeCode);

  const fields = defaultVehicleType ? getVehicleDimensions(defaultVehicleType) : {};

  dispatch(
    initializeCreateIntermodalFormState(
      createEmptyCreateFormModel(goodId, {
        customer: intermodalCustomer,
        vehicleType: defaultVehicleType ?? null,
        ...fields,
        departureDate,
        departureTime,
      }),
    ),
  );
});
