import { FC } from 'react';
import { LinkButton } from '../../../common-ui/LinkButton';
import { useShowBookingDetails } from '../../../common/hooks/useShowBookingDetails';
import { TrackerCategory, trackEvent } from '../../../common/tracking/trackerService';

interface ShowDetailsLinkProps {
  bookingNo: number;
  trackerCategory: TrackerCategory;
  trackerAction: string;
  onAfterShowDetails?: () => void;
}

export const ShowDetailsLink: FC<ShowDetailsLinkProps> = ({
  bookingNo,
  trackerAction,
  trackerCategory,
  onAfterShowDetails,
}) => {
  const { pushBookingDetailsModal } = useShowBookingDetails();

  const showBookingDetails = (bookingNo: number) => {
    trackEvent({ category: trackerCategory, action: trackerAction });

    pushBookingDetailsModal(bookingNo, false);
    onAfterShowDetails?.();
  };

  return (
    <LinkButton
      className={`t_open_details`}
      onClick={() => showBookingDetails(bookingNo)}
      label={String(bookingNo)}
    />
  );
};
