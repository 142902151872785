import { IntermodalRoute } from '../../gql/graphql';
import { useMemo } from 'react';
import { useAllIntermodalRoutes } from './useAllIntermodalRoutes';
import { transformIntermodalRouteToDropdownOption } from '../../use-cases/intermodal/utils/detailsUtils';

export interface IntermodalRouteDropdownOption
  extends Pick<IntermodalRoute, 'longDescription' | 'id'> {}

export const useAllIntermodalRoutesAsOptions = () => {
  const { allIntermodalRoutes, error } = useAllIntermodalRoutes();

  const intermodalRouteOptions = useMemo(() => {
    return allIntermodalRoutes.map(route => transformIntermodalRouteToDropdownOption(route));
  }, [allIntermodalRoutes]);
  return { intermodalRouteOptions, error };
};
