import {
  autoPlacement,
  ClientRectObject,
  offset,
  Placement,
  shift,
  Strategy,
  useFloating,
} from '@floating-ui/react-dom-interactions';
import {
  CSSProperties,
  MutableRefObject,
  ReactNode,
  Ref,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { NavigateFunction, Location } from 'react-router-dom';
import { Store } from 'redux';
import { StoreState } from '../../store/storeState';
import { Overlay } from './Overlay';
import { getNodeFromTarget } from './util/utils';

export interface LifecycleEffectsParams {
  location: Location;
  store: Store<StoreState>;
  navigate: NavigateFunction;
}

export interface StepWithLifecycleEffects {
  onEnter?: (params: LifecycleEffectsParams) => Promise<void>;
  onExit?: (params: LifecycleEffectsParams) => Promise<void>;
  content: string;
  target: string | string[];
  title: string;
  placement?: Placement;
  offset?: number;
  disableScroll?: boolean;
}

export interface RenderFooterProps {
  index: number;
  size: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onSkip: () => void;
  nextRef: Ref<HTMLButtonElement>;
  previousRef: Ref<HTMLButtonElement>;
}

export interface TourDefinition<TStep extends StepWithLifecycleEffects = StepWithLifecycleEffects> {
  steps: TStep[];
}

export interface RenderStepProps<
  TStep extends StepWithLifecycleEffects = StepWithLifecycleEffects,
> {
  step: TStep;
  x: number;
  y: number;
  zIndex: number;
  position: Strategy;
  footerProps: RenderFooterProps;
  floating: (node: HTMLElement | null) => void;
}

interface TourProps<TStep extends StepWithLifecycleEffects = StepWithLifecycleEffects> {
  steps: TStep[];
  onFinished?: (index: number, skipped: boolean) => void;
  zIndex?: number;
  overlayStyle?: CSSProperties;
  renderStep: (props: RenderStepProps<TStep>) => ReactNode;
  lifecycleEffectsRef: MutableRefObject<LifecycleEffectsParams>;
}

export function Tour<TStep extends StepWithLifecycleEffects = StepWithLifecycleEffects>({
  steps,
  onFinished,
  zIndex = 10000,
  overlayStyle,
  renderStep,
  lifecycleEffectsRef,
}: TourProps<TStep>) {
  const [index, setIndex] = useState(0);
  const [visibleIndex, setVisibleIndex] = useState(index);
  const [rect, setRect] = useState<ClientRectObject>();
  const [step, setStep] = useState<TStep>(steps[0]);
  const lastStepDirection = useRef<'next' | 'prev'>('next');
  const nextRef = useRef<HTMLButtonElement>(null);
  const previousRef = useRef<HTMLButtonElement>(null);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    refs: { reference: targetReference },
  } = useFloating({
    placement: step.placement ?? 'right',
    strategy: 'fixed',
    middleware: [offset(step.offset ?? 20), shift({ padding: 20 }), autoPlacement()],
  });

  useEffect(() => {
    const lastDir = lastStepDirection.current;

    function returnFocusToElement() {
      const backElement = previousRef.current;
      const nextElement = nextRef.current;

      const focusElement = lastDir === 'prev' && backElement != null ? backElement : nextElement;

      focusElement?.focus?.();
    }

    async function changeTarget() {
      let nextStep = steps[index];

      if (!nextStep) {
        nextStep = steps[0];
        setIndex(0);
      }

      await nextStep.onEnter?.(lifecycleEffectsRef.current);
      const node = await getNodeFromTarget(nextStep.target);

      if (!node && index < steps.length) {
        console.warn('Step target not found, going to next step');
        setIndex(i => i + 1);
        return;
      }

      if (node) {
        setTimeout(() => {
          if (!nextStep.disableScroll) {
            node.scrollIntoView({ block: 'center' });
          }

          reference(node);
          setStep(nextStep);
          setVisibleIndex(index);
          setTimeout(() => {
            returnFocusToElement();
            setRect(node.getBoundingClientRect());
          }, 100);
        }, 0);
      }
    }

    changeTarget();
  }, [reference, previousRef, nextRef, lastStepDirection, index, steps, lifecycleEffectsRef]);

  useLayoutEffect(() => {
    function updateRectPosition() {
      reference(targetReference.current);
      setRect(targetReference.current?.getBoundingClientRect());
    }

    window.addEventListener('scroll', updateRectPosition, { passive: true });
    window.addEventListener('resize', updateRectPosition, { passive: true });

    return () => {
      window.removeEventListener('scroll', updateRectPosition);
      window.removeEventListener('resize', updateRectPosition);
    };
  }, [reference, targetReference]);

  const size = steps.length;
  const isLastStep = index === size - 1;
  const isFirstStep = index === 0;

  const onNext = async () => {
    lastStepDirection.current = 'next';
    await step.onExit?.(lifecycleEffectsRef.current);
    if (!isLastStep) {
      setIndex(i => i + 1);
    } else {
      onFinished?.(index, false);
    }
  };

  const onPrevious = async () => {
    lastStepDirection.current = 'prev';
    await step.onExit?.(lifecycleEffectsRef.current);
    if (!isFirstStep) {
      setIndex(i => i - 1);
    }
  };

  const onSkip = async () => {
    await step.onExit?.(lifecycleEffectsRef.current);
    onFinished?.(index, true);
  };

  if (!targetReference.current) {
    return null;
  }

  return (
    <>
      <Overlay overlayStyle={overlayStyle} zIndex={zIndex} rect={rect} onClick={onNext} />
      {renderStep({
        step,
        position: strategy,
        x: x ?? 0,
        y: y ?? 0,
        zIndex,
        floating,
        footerProps: {
          index: visibleIndex,
          size,
          isFirstStep,
          isLastStep,
          onNext,
          nextRef,
          onPrevious,
          previousRef,
          onSkip,
        },
      })}
    </>
  );
}
