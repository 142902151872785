import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatServerDate } from '../../common/utils/dates/dateUtils';
import { StoreState } from '../../store/storeState';
import { DASHBOARD_POLL_INTERVAL } from '../../use-cases/manage/dashboard/utils/dashboardUtils';

export interface DashboardReducerState {
  dashboardDate: string;
  enabled: boolean;
}

const INITIAL_VALUE: DashboardReducerState = {
  dashboardDate: formatServerDate(new Date()),
  enabled: true,
};

const slice = createSlice({
  name: 'dashboard',
  initialState: INITIAL_VALUE,
  reducers: {
    setDashboardDate(state, action: PayloadAction<string>) {
      state.dashboardDate = action.payload;
    },
    setDashboardEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
  },
});

export const {
  reducer: dashboardReducer,
  actions: { setDashboardDate, setDashboardEnabled },
} = slice;

const getDashboard = (state: StoreState): DashboardReducerState => state.dashboard;
export const getDashboardDate = (state: StoreState): string => getDashboard(state).dashboardDate;
export const getDashboardPollInterval = (state: StoreState) =>
  getDashboard(state).enabled ? DASHBOARD_POLL_INTERVAL : 0;
export const getDashboardEnabled = (state: StoreState) => getDashboard(state).enabled;
