import { Sailing } from '../../../../common/graphql/fragments/gql/Sailing';
import { formatDate } from '../../../../common/utils/dates/dateUtils';

export const formatArrivalDateTime = (
  originalArrivalDate: string,
  arrivalDate: string,
  arrivalTime: string,
  dateFormat: string,
): string =>
  arrivalDate && originalArrivalDate !== arrivalDate
    ? `${formatDate(arrivalDate, dateFormat)} ${arrivalTime}`
    : `${arrivalTime}`;

export const resolveETAorATAText = (hasArrived: boolean, sailing: Sailing): string => {
  if (hasArrived && sailing.realArrivalTime) {
    return `arr. ${sailing.realArrivalTime}`;
  } else if (sailing.estimatedArrivalTime) {
    return `est. ${sailing.estimatedArrivalTime}`;
  } else {
    return sailing.arrivalTime;
  }
};
