import { FC } from 'react';
import { CssPropColor } from '@stenajs-webui/theme/dist/util/CssColor';
import { cssColor } from '@stenajs-webui/theme';

const PortCircleEmpty: FC<{ color: CssPropColor }> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="10" fill="none" stroke={cssColor(color)} strokeWidth="4" />
  </svg>
);

const PortCircleFilled: FC<{ color: CssPropColor }> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <circle cx="14" cy="14" r="11" fill={color} stroke={cssColor(color)} strokeWidth="6" />
  </svg>
);

export const PortCircle: FC<{ filled?: boolean; color: CssPropColor }> = ({ filled, color }) =>
  filled ? <PortCircleFilled color={color} /> : <PortCircleEmpty color={color} />;
