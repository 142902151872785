import { getKeysToChangeForArrivalNotepad } from '../../../common/registrationNumberAlert/utils/arrivalNotepadUtil';
import { exhaustSwitchCaseElseThrow } from '../../../common/utils/ExhaustSwitchCase';
import { CreateCandidate } from '../../../services/create/createFormReducer';
import { RegistrationNumberAlertFormState } from '../../../services/update-form/registrationNumberAlert';

export const getValuesForCreateForm = (
  registrationNumberAlert: RegistrationNumberAlertFormState,
): Array<keyof CreateCandidate> => {
  const keys = getKeysToChangeForArrivalNotepad(registrationNumberAlert);
  return keys.map(key => {
    switch (key) {
      case 'vehicleType':
      case 'height':
      case 'length':
      case 'loadingListMessage':
      case 'cargoWeight':
        return key;
      case 'vehicleRegNo':
        return 'vehicleReg';
      case 'hazardousGoods':
        return 'hasHazardousGoods';
      default:
        return exhaustSwitchCaseElseThrow(key);
    }
  });
};

export const calculateNewPartialCandidate = (
  keys: Array<keyof CreateCandidate>,
  initialCandidate: CreateCandidate,
): Partial<CreateCandidate> =>
  keys.reduce<Partial<CreateCandidate>>(
    (previousValue, key) => {
      return {
        ...previousValue,
        [key]: initialCandidate[key],
      };
    },
    { registrationNumberAlert: null, vehicleReg: '' },
  );
