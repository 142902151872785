import { useSelector } from 'react-redux';
import { getCustomsLinkLoaderStateForBookingNo } from '../../../services/customs-link/customsLinkReducer';
import { loadTokenForBookingNo } from '../../../services/customs-link/thunks/loadTokenForBookingNo';
import { saveGdprOption } from '../../../services/customs-link/thunks/saveGdprOption';
import { useAppDispatch } from '../../../store/appDispatch';
import { StoreState } from '../../../store/storeState';

export const useCustomsLinkTokenLoader = (bookingNo: number) => {
  const loaderState = useSelector((state: StoreState) =>
    getCustomsLinkLoaderStateForBookingNo(state, bookingNo),
  );

  const dispatch = useAppDispatch();

  const acceptGdpr = async () => {
    try {
      return await dispatch(saveGdprOption()).unwrap();
    } catch {
      // Error is handled in thunk
    }

    return false;
  };

  const fetchTokenAndRedirect = async (bookingNo: number) => {
    try {
      const result = await dispatch(loadTokenForBookingNo(bookingNo)).unwrap();

      if (result.redirectUrl) {
        window.open(result.redirectUrl);
        return true;
      }
    } catch {
      // Error is handled in thunk
    }

    return false;
  };

  return {
    fetchTokenAndRedirect,
    acceptGdpr,
    isLoading: loaderState?.name === 'loading',
    loaderState,
  };
};
