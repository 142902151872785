import { useMemo } from 'react';
import { SettingsFragment } from '../../../common/graphql/fragments/gql/SettingsFragment';
import { FeatureFlag } from '../../../services/flags/enum';
import { getFeatureIsEnabled } from '../../../services/flags/featureFlags';
import { useAccount } from '../../../services/settings/hooks/useAccount';

export const getIsUserMigrated = (settings: SettingsFragment) =>
  settings.accountLoginEmail?.toLowerCase() === settings.accountEmail?.toLowerCase() &&
  !settings.accountSecondEmail;

export const shouldShowNewSubscriptions = (settings: SettingsFragment) =>
  getIsUserMigrated(settings) && getFeatureIsEnabled(FeatureFlag.UserSubscriptions);

export const useShouldShowNewSubscriptions = (): boolean => {
  const { accountSettings } = useAccount();

  return useMemo(() => shouldShowNewSubscriptions(accountSettings), [accountSettings]);
};
