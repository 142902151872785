import { graphql } from '../../gql';

export const allVehicleTypesForCustomerAndRouteQuery = graphql(/*GraphQL*/ `
  query AllVehicleTypesForCustomerAndRouteQuery($customerNo: Int!, $routeCode: String!) {
    viewer {
      vehicleType {
        allForCustomerNoAndRouteCode(customerNo: $customerNo, routeCode: $routeCode) {
          vehicleTypes {
            ...VehicleType
          }
        }
      }
    }
  }
`);
