import { Txt, useBoolean } from '@stenajs-webui/core';
import { ButtonSize, FlatButton } from '@stenajs-webui/elements';
import { FC, useCallback, useRef } from 'react';
import { TrackerCategory, trackEvent } from '../../common/tracking/trackerService';
import { FreightPortalPopover, Placement } from '../popover/FreightPortalPopover';
import { PopoverTitle } from '../popover/PopoverTitle';
import { PopoverBody } from '../popover/PopoverBody';

export interface CalculatePriceErrorsProps {
  errors: string[];
  trackerCategory: TrackerCategory;
  placement?: Placement;
  buttonSize?: ButtonSize;
}

export const CalculatePriceErrors: FC<CalculatePriceErrorsProps> = ({
  errors,
  trackerCategory,
  placement,
  buttonSize,
}) => {
  const [visible, open, close] = useBoolean(false);

  const onOpen = useCallback(() => {
    open();
    trackEvent({ category: trackerCategory, action: 'Show error' });
  }, [open, trackerCategory]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <FlatButton size={buttonSize} onClick={onOpen} label={'Price unavailable'} ref={buttonRef} />
      <FreightPortalPopover
        lazy
        onRequestClose={close}
        visible={visible}
        reference={buttonRef}
        placement={placement}
        content={
          <>
            <PopoverTitle text={'Price unavailable'} onClose={close} />
            <PopoverBody maxWidth={600} gap={2}>
              {errors.map(error => (
                <Txt key={error}>{error}</Txt>
              ))}
            </PopoverBody>
          </>
        }
      />
    </>
  );
};
