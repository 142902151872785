import { Txt } from '@stenajs-webui/core';
import { DetailedIntermodalBooking } from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { useLocalDateFormat } from '../../../common/hooks/useLocalDateFormat';
import { formatSailingLinkText } from './SailingLink';

export const IntermodalJourneyTxt = ({ booking }: { booking: DetailedIntermodalBooking }) => {
  const dateFormat = useLocalDateFormat();

  return <Txt>{formatSailingLinkText(booking.journey, booking.journey.route.id, dateFormat)}</Txt>;
};
