import { FC, ReactNode } from 'react';

export interface HiddenFieldGateProps {
  hidden: boolean;
  children?: ReactNode;
}

export const HiddenFieldGate: FC<HiddenFieldGateProps> = ({ hidden, children }) => {
  return hidden ? null : <>{children}</>;
};
