import { StoreState } from '../../store/storeState';
import { BookingsFilterResult } from '../../use-cases/manage/list/hooks/useGridQuery';
import { RehydratePageSettingsAction } from '../page-settings/pageSettingsActions';
import { BookingFormState, getEditedFormState } from '../update-form/updateFormReducer';
import { GridActions } from './gridActions';

export interface BookingsFilterQueryResult {
  errors: string[];
  result: BookingsFilterResult | null;
}

export type CompactMode = 'spacious' | 'normal' | 'compact';

export type BookingsPerLoad = 100 | 50 | 25;

export interface GridReducerState {
  first: number;
  offset: number;
  result: BookingsFilterQueryResult | null;
  refetching: boolean;
  compactMode: CompactMode;
  activeRow: number | null;
  bookingsPerLoad: BookingsPerLoad;
}

export const NUMBER_TO_SHOW_ON_INIT = 25;

export const INITIAL_VALUE: GridReducerState = {
  first: NUMBER_TO_SHOW_ON_INIT,
  offset: 0,
  result: null,
  refetching: false,
  compactMode: 'normal',
  activeRow: null,
  bookingsPerLoad: 50,
};

export const gridReducer = (
  state: GridReducerState = INITIAL_VALUE,
  action: GridActions | RehydratePageSettingsAction,
): GridReducerState => {
  switch (action.type) {
    case 'PAGE_SETTINGS:REHYDRATE':
      return {
        ...state,
        compactMode: action.payload.compactMode,
        bookingsPerLoad: action.payload.bookingsPerLoad,
      };
    case 'GRID:UPDATE_PAGINATION':
      return {
        ...state,
        first: action.payload.first,
        offset: action.payload.offset,
      };
    case 'GRID:SET_QUERY_RESULT':
      return {
        ...state,
        result: action.payload.result,
      };
    case 'GRID:SET_REFETCHING':
      return {
        ...state,
        refetching: action.payload.refetching,
      };
    case 'GRID:SET_COMPACT_MODE':
      return {
        ...state,
        compactMode: action.payload.compactMode,
      };
    case 'GRID:SET_ACTIVE_ROW':
      return {
        ...state,
        activeRow: action.payload.bookingNo,
      };
    case 'GRID:CLEAR_ACTIVE_ROW':
      return {
        ...state,
        activeRow: null,
      };
    case 'GRID:SET_BOOKINGS_PER_LOAD':
      return {
        ...state,
        bookingsPerLoad: action.payload.bookingsPerLoad,
      };
    default:
      return state;
  }
};

export const getGrid = (state: StoreState): GridReducerState => state.grid;
export const getFirst = (state: StoreState): number => getGrid(state).first;
export const getOffset = (state: StoreState): number => getGrid(state).offset;
export const getBookingsFilterQueryResult = (state: StoreState): BookingsFilterQueryResult | null =>
  getGrid(state).result;
export const getIsRefetching = (state: StoreState): boolean => getGrid(state).refetching;
export const getCompactMode = (state: StoreState): CompactMode => getGrid(state).compactMode;
export const getIsActiveRow = (state: StoreState, bookingNo: number): boolean =>
  getGrid(state).activeRow === bookingNo;
export const getBookingsPerLoad = (state: StoreState): number => getGrid(state).bookingsPerLoad;
const EMPTY_ARRAY: any[] = [];
export const getResultErrors = (state: StoreState): string[] =>
  getBookingsFilterQueryResult(state)?.errors ?? EMPTY_ARRAY;
export const getIsLoading = (state: StoreState): boolean =>
  getBookingsFilterQueryResult(state)?.result == null && getResultErrors(state).length === 0;

const isBrexitRoute = ({ route }: BookingFormState): boolean =>
  route ? route.departureCountryCode === 'GB' || route.arrivalCountryCode === 'GB' : false;

const isNlUkRoute = ({ route }: BookingFormState): boolean =>
  route ? route.departureCountryCode === 'NL' && route.arrivalCountryCode === 'GB' : false;

const getHasGridResult = (
  state: StoreState,
  predicate: (editedFormState: BookingFormState) => boolean,
) => {
  const bookingNos = getBookingsFilterQueryResult(state)?.result?.bookingNos;

  return (
    bookingNos?.some(bookingNo => {
      const editedFormState = getEditedFormState(state, { bookingNo, formId: 'Grid' });

      return editedFormState && predicate(editedFormState);
    }) ?? false
  );
};

export const getHasResultWithBrexitRoutes = (state: StoreState) =>
  getHasGridResult(state, isBrexitRoute);

export const getHasResultWithNlUkRoutes = (state: StoreState) =>
  getHasGridResult(state, isNlUkRoute);

export const getHasAnyResult = (state: StoreState) => getHasGridResult(state, () => true);
