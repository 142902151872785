import { UpdateSubmitInformation } from '../../../../common/update-information/utils/getUpdateSubmitInformation';
import { DetailedBooking_autoCancellation } from '../../../../common/graphql/fragments/gql/DetailedBooking';

export const AUTO_CANCELLATION_MESSAGE_CODE = 'IEXT-009';

export const resolveAutoCancellationInformation = (
  updateSubmitInformation: UpdateSubmitInformation,
  autoCancellation: DetailedBooking_autoCancellation | null,
) =>
  updateSubmitInformation.messages.some(m => m.code === AUTO_CANCELLATION_MESSAGE_CODE)
    ? null
    : autoCancellation;
