import { SubscriptionKey, UserSubscription } from './userSubscriptionsReducer';
import { startCase } from 'lodash';
import { trackEvent } from '../../common/tracking/trackerService';

type SubscriptionChannel = 'Sms' | 'Email' | 'FreightPortal';

const trackSubscription = (
  subscription: SubscriptionKey,
  channel: SubscriptionChannel,
  added: boolean,
) => {
  const subscriptionName = startCase(subscription);
  trackEvent(
    {
      category: 'Subscriptions',
      action: added ? 'Add Subscription' : 'Remove Subscription',
      label: subscriptionName + ' ' + channel,
    },
    { subscription_name: subscriptionName, subscription_type: channel },
  );
};

export const trackChangedUserSubscriptions = (
  prevState: Record<SubscriptionKey, UserSubscription>,
  newState: Record<SubscriptionKey, UserSubscription>,
) => {
  (Object.keys(newState) as SubscriptionKey[]).forEach(key => {
    const prevSubscription = prevState[key];
    const newSubscription = newState[key];
    if (newSubscription.smsEnabled !== prevSubscription.smsEnabled) {
      trackSubscription(key, 'Sms', newSubscription.smsEnabled);
    }
    if (newSubscription.emailEnabled !== prevSubscription.emailEnabled) {
      trackSubscription(key, 'Email', newSubscription.emailEnabled);
    }
    if (newSubscription.freightPortalEnabled !== prevSubscription.freightPortalEnabled) {
      trackSubscription(key, 'FreightPortal', newSubscription.freightPortalEnabled);
    }
  });
};
