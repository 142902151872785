import { Box, Txt } from '@stenajs-webui/core';
import { FC } from 'react';
import { Loading } from '../../../../common-ui/Loading';
import { cssColor } from '@stenajs-webui/theme';
import {
  CompactIntermodalJourneyInfo,
  IntermodalJourneyInfo,
} from '../../common/IntermodalJourneyInfo';
import { useJourneyResult } from '../../hooks/UseJourneyResult';

import { IntermodalJourneyHeaderIcon } from '../../../../common-ui/IntermodalJourneyHeaderIcon';
import { Card } from '@stenajs-webui/elements';

export interface ConnectionSectionProps {
  compact?: boolean;
}

export const CreateIntermodalConnectionsSection: FC<ConnectionSectionProps> = ({
  compact = false,
}) => {
  const { errors, fetchingJourney, legs } = useJourneyResult();

  if (fetchingJourney) {
    return (
      <Box flex={1} justifyContent={'center'}>
        <Loading />
      </Box>
    );
  }

  if (errors.length > 0) {
    return (
      <Card
        spacing={4}
        flex={1}
        gap={2}
        indent={2}
        width={'100%'}
        alignItems={'center'}
        style={{ background: cssColor('--lhds-color-red-100') }}
      >
        <IntermodalJourneyHeaderIcon variant={'error'} />
        <Txt variant={'bold'}>No connections found</Txt>
        {errors.map((e, index) => (
          <Txt key={index} style={{ textAlign: 'center' }}>
            {e.description}
          </Txt>
        ))}
      </Card>
    );
  }

  if (!legs) {
    return (
      <Card gap={3} flex={1} width={'100%'} alignItems={'center'} indent spacing={6}>
        <IntermodalJourneyHeaderIcon variant={'info'} />
        <Txt variant={'bold'}>No departures</Txt>
        <Txt style={{ textAlign: 'center' }}>Select departure and enter vehicle information</Txt>
      </Card>
    );
  }

  return compact ? (
    <CompactIntermodalJourneyInfo legs={legs} />
  ) : (
    <IntermodalJourneyInfo legs={legs} />
  );
};
