import { FlatButton, stenaCopy } from '@stenajs-webui/elements';
import { FC } from 'react';
import { useTimeoutState } from '../../../../common/hooks/useTimeoutState';
import { TrackerCategory, trackEvent } from '../../../../common/tracking/trackerService';

const copyBookingLink = (bookingNo: number, vehicleReg: string) => {
  return navigator.clipboard.writeText(
    `https://driver.stenalinefreight.com/open?bookingNo=${bookingNo}&vehicleReg=${vehicleReg}`,
  );
};

interface CopyBookingLinkButtonProps {
  trackerCategory: TrackerCategory;
  bookingNo: number;
  vehicleRegNo: string;
}

export const CopyBookingLinkButton: FC<CopyBookingLinkButtonProps> = ({
  trackerCategory,
  bookingNo,
  vehicleRegNo,
}) => {
  const [showCopyClicked, setShowCopyClicked] = useTimeoutState(false, 2000);

  const onClick = () => {
    trackEvent({ category: trackerCategory, action: 'Share - Copy booking link' });
    setShowCopyClicked(true);
    copyBookingLink(bookingNo, vehicleRegNo);
  };

  return (
    <FlatButton
      leftIcon={stenaCopy}
      label={'Copy booking link'}
      success={showCopyClicked}
      successLabel={'Copied'}
      variant={showCopyClicked ? 'success' : undefined}
      onClick={onClick}
    />
  );
};
