import { formatBookingMailContent } from '../../../../../../common/formatters/bookingStringFormatter';
import { BasicBooking } from '../../../../../../common/graphql/fragments/gql/BasicBooking';
import { formatDate } from '../../../../../../common/utils/dates/dateUtils';

export const getMailUrl = (booking: BasicBooking, dateFormat: string) => {
  const subject = encodeURIComponent(
    `Stena Line booking ${booking.bookingNo} ${booking.sailing.route.code} ${formatDate(
      booking.sailing.departureDate,
      dateFormat,
    )} ${booking.sailing.departureTime}`,
  );

  return `mailto:?subject=${subject}&body=${encodeURIComponent(
    formatBookingMailContent(booking, dateFormat),
  )}`;
};
