import { ActionMenuItem, stenaHistory, stenaPrint } from '@stenajs-webui/elements';
import { ActionMenuSecondaryButton } from '@stenajs-webui/panels';
import { FC, lazy, Suspense, useRef, useState } from 'react';
import { CopyActionMenuItem } from '../../../../common-ui/CopyActionMenuItem';
import { FreightPortalPopover } from '../../../../common-ui/popover/FreightPortalPopover';
import { isBookingClaimable } from '../../../../common/claims/claimsUtils';
import { stringifyBooking } from '../../../../common/formatters/bookingStringFormatter';
import { DetailedBooking } from '../../../../common/graphql/fragments/gql/DetailedBooking';
import { useLocalDateFormat } from '../../../../common/hooks/useLocalDateFormat';
import { useHasPermission } from '../../../../common/statistics/useHasPermission';
import { TrackerCategory, trackEvent } from '../../../../common/tracking/trackerService';
import { Permission } from '../../../../services/authentication/utils/authUtils';
import { ActionMenuClaimsLink } from '../../grid/components/options-table-cell/ActionMenuClaimsLink';
import { MailBookingActionMenuItem } from '../../grid/components/options-table-cell/MailBookingActionMenuItem';
import { SendBookingActionMenuItem } from '../../grid/components/options-table-cell/SendBookingActionMenuItem';
import { SwitchBookingActionMenuItem } from '../../grid/components/options-table-cell/SwitchBookingActionMenuItem';
import { ShareBookingPanel } from '../../share/components/ShareBookingPanel';
import { isBookingSwitchable } from '../../switch/utils/switchUtils';
import { HistoryPanel } from './HistoryPanel';
import { Box } from '@stenajs-webui/core';
import { shouldShowShareBooking } from '../../share/util/shareUtil';

const DetailsPrintButton = lazy(() => import('../../../../common/pdf/DetailsPrintButton'));

interface BookingActionMenuButtonProps {
  booking: DetailedBooking;
  trackerCategory: TrackerCategory;
  disabled?: boolean;
  edited?: boolean;
}

type BookingActionModalVariant = 'history' | 'share';

export const ACTION_MENU_WIDTH = '22rem';
export const ACTION_MENU_PLACEMENT = 'bottom-end';

export const BookingActionMenuButton: FC<BookingActionMenuButtonProps> = ({
  booking,
  trackerCategory,
  disabled,
  edited,
}) => {
  const [modal, setModal] = useState<BookingActionModalVariant | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const vehicleRegNumber = booking.vehicleRegNo || booking.trailerRegNo;

  const getHasPermission = useHasPermission();
  const dateFormat = useLocalDateFormat();
  const showSwitchOption =
    getHasPermission(Permission.EditBookings) && isBookingSwitchable(booking);
  const showClaimsOptions = isBookingClaimable(booking.bookingStatus.status);

  const onHistoryOpen = () => {
    trackEvent({ category: trackerCategory, action: 'History' });
    setModal('history');
  };

  const closeModal = () => setModal(null);

  const getBookingString = () => {
    trackEvent({ category: 'Details', action: 'Copy to clipboard' });
    return stringifyBooking(booking, dateFormat);
  };

  return (
    <>
      <ActionMenuSecondaryButton
        buttonRef={buttonRef}
        label={'Booking actions'}
        disabled={disabled}
        placement={ACTION_MENU_PLACEMENT}
        menuWidth={ACTION_MENU_WIDTH}
        renderItems={close => (
          <>
            <Suspense
              fallback={<ActionMenuItem leftIcon={stenaPrint} label={'Print booking'} loading />}
            >
              <DetailsPrintButton booking={booking} dateFormat={dateFormat} />
            </Suspense>
            <CopyActionMenuItem getValue={getBookingString} disabled={disabled} />
            <MailBookingActionMenuItem
              booking={booking}
              trackerCategory={trackerCategory}
              trackerAction={'Mail'}
              onClick={close}
              disabled={disabled || edited}
            />
            {shouldShowShareBooking(
              vehicleRegNumber,
              booking.bookingStatus.status,
              booking.bookingQuayStatus?.status,
            ) && (
              <Box display={['flex', null, null, null, 'none']}>
                <SendBookingActionMenuItem
                  trackerCategory={trackerCategory}
                  trackerAction={'Share booking (menu item)'}
                  onClick={() => {
                    close();
                    setModal('share');
                  }}
                  disabled={disabled}
                />
              </Box>
            )}
            {showSwitchOption && (
              <SwitchBookingActionMenuItem
                booking={booking}
                onClick={close}
                trackerCategory={trackerCategory}
                trackerAction={'Switch'}
                modalAction={'replace'}
              />
            )}
            {showClaimsOptions && (
              <ActionMenuClaimsLink
                trackerCategory={trackerCategory}
                disabled={disabled}
                bookingNo={booking.bookingNo}
                onClick={close}
              />
            )}
            <ActionMenuItem
              label={'History'}
              leftIcon={stenaHistory}
              onClick={() => {
                close();
                onHistoryOpen();
              }}
              disabled={disabled}
            />
          </>
        )}
      />
      <FreightPortalPopover
        reference={buttonRef}
        placement={'bottom-end'}
        visible={modal != null}
        onRequestClose={closeModal}
        content={
          modal === 'share' ? (
            <ShareBookingPanel
              onClose={closeModal}
              bookingNo={booking.bookingNo}
              vehicleRegNo={vehicleRegNumber}
              trackerCategory={trackerCategory}
            />
          ) : modal === 'history' ? (
            <HistoryPanel bookingNo={booking.bookingNo} onClose={closeModal} />
          ) : null
        }
      />
    </>
  );
};
