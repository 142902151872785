import { Row } from '@stenajs-webui/core';
import { FlatButton, PrimaryButton, SecondaryButton } from '@stenajs-webui/elements';
import { FC } from 'react';
import { RenderFooterProps } from './Tour';

interface FooterProps extends RenderFooterProps {}

export const StepFooter: FC<FooterProps> = ({
  index,
  size,
  isFirstStep,
  isLastStep,
  onNext,
  onPrevious,
  onSkip,
  nextRef,
  previousRef,
}) => {
  const showSkip = index < size && !isLastStep;

  return (
    <>
      <Row>
        {showSkip && (
          <FlatButton className={'t_skip_introduction_tour'} label={'Skip'} onClick={onSkip} />
        )}
      </Row>
      <Row gap>
        {!isFirstStep && (
          <SecondaryButton
            id={'t_back_introduction_tour'}
            ref={previousRef}
            label={'Back'}
            onClick={onPrevious}
          />
        )}
        <PrimaryButton
          autoFocus
          id={'t_next_introduction_tour'}
          ref={nextRef}
          onClick={onNext}
          label={isLastStep ? "Let's go!" : `Next tip (${index + 1}/${size})`}
        />
      </Row>
    </>
  );
};
