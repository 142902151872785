import {
  FlatButtonLink as WebUiFlatButtonLink,
  FlatButtonLinkProps,
  PrimaryButtonLink as WebUiPrimaryButtonLink,
  PrimaryButtonLinkProps,
  SecondaryButtonLink as WebUiSecondaryButtonLink,
  SecondaryButtonLinkProps,
} from '@stenajs-webui/elements';
import { forwardRef, MouseEvent, ReactElement } from 'react';
import { LinkProps as RRLinkProps, useHref, useLinkClickHandler } from 'react-router-dom';

interface LinkProps extends Pick<RRLinkProps, 'to' | 'onClick' | 'state'> {
  render: (props: any) => ReactElement<any, any> | null;
  target?: string;
}

// FIXME: Replace render prop API with react-router connected webui-links
//  and document how to do this in webui
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ onClick, target, to, render, state, ...props }, ref) => {
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
      target,
      state,
    });

    return render({
      ...props,
      ref,
      href,
      onClick: (event: MouseEvent<HTMLAnchorElement>) => {
        onClick?.(event);
        if (!event.defaultPrevented) {
          handleClick(event);
        }
      },
    });
  },
);

export const FlatButtonLink = (
  outerProps: Omit<LinkProps, 'render'> & Omit<FlatButtonLinkProps, 'href'>,
) => (
  <Link
    {...outerProps}
    render={({ ref, ...props }) => <WebUiFlatButtonLink {...props} ref={ref} />}
  />
);

export const SecondaryButtonLink = (
  outerProps: Omit<LinkProps, 'render'> & Omit<SecondaryButtonLinkProps, 'href'>,
) => (
  <Link
    {...outerProps}
    render={({ ref, ...props }) => <WebUiSecondaryButtonLink {...props} ref={ref} />}
  />
);

export const PrimaryButtonLink = (
  outerProps: Omit<LinkProps, 'render'> & Omit<PrimaryButtonLinkProps, 'href'>,
) => (
  <Link
    {...outerProps}
    render={({ ref, ...props }) => <WebUiPrimaryButtonLink {...props} ref={ref} />}
  />
);
