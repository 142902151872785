import { Row } from '@stenajs-webui/core';
import { ReactNode } from 'react';

export interface RegistrationNumberAlertFooterProps {
  children: ReactNode;
}
export const RegistrationNumberAlertFooter = ({ children }: RegistrationNumberAlertFooterProps) => (
  <Row justifyContent={'flex-end'} gap width={'100%'}>
    {children}
  </Row>
);
