import { ImportExportStatusCode } from '../../../gql/graphql';
import { InformationMessageType } from '../../../common-ui/information-message/informationMessageUtils';

export const resolveInformationMessageType = (
  code: ImportExportStatusCode,
): InformationMessageType => {
  switch (code) {
    case ImportExportStatusCode.Error:
      return InformationMessageType.HardWarning;
    case ImportExportStatusCode.Success:
      return InformationMessageType.Confirmed;
    case ImportExportStatusCode.Warning:
      return InformationMessageType.SoftWarning;
    case ImportExportStatusCode.Refresh:
    default:
      return InformationMessageType.RegularInfo;
  }
};
