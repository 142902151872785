import { graphql } from '../../gql';

export const arrivalNotepadQuery = graphql(/*GraphQL*/ `
  query ArrivalNotepadQuery($customerNo: Int!, $registrationNumber: String!, $routeCode: String!) {
    viewer {
      arrivalNotepad {
        arrivalnotepad(
          customerNo: $customerNo
          registrationNumber: $registrationNumber
          routeCode: $routeCode
        ) {
          arrivalNotepad {
            ...ArrivalNotepad
          }
        }
      }
    }
  }
`);
