import { FC } from 'react';
import { Sailing } from '../common/graphql/fragments/gql/Sailing';
import { BookingStatus } from '../gql/graphql';
import { PortDetails } from './PortDetails';

interface PortDetailsForSailingProps {
  sailing: Sailing;
  bookingStatus?: BookingStatus | null;
}

export const PortDetailsForSailing: FC<PortDetailsForSailingProps> = ({
  sailing,
  bookingStatus,
}) => {
  return (
    <PortDetails
      departureLocation={
        sailing.route.departureLocation.longName ?? sailing.route.departureLocation.name
      }
      arrivalLocation={sailing.route.arrivalLocation.longName ?? sailing.route.arrivalLocation.name}
      departureDate={sailing.departureDate}
      departureTime={sailing.departureTime}
      arrivalDate={sailing.arrivalDate}
      arrivalTime={sailing.arrivalTime}
      bookingStatus={bookingStatus}
    />
  );
};
