import { RouteLegProps } from '@stenajs-webui/elements';
import { isSameMinute, isValid } from 'date-fns';
import { IntermodalJourneyLeg } from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { formatDateTime, getDateFromDateAndTime } from '../../../common/utils/dates/dateUtils';

export function getLocationName(location: IntermodalJourneyLeg['route']['arrivalLocation']) {
  return location.longName ?? location.name;
}

export function getScheduledOrPublicDateTime(scheduledDate: Date, publicDate: Date) {
  if (isValid(publicDate)) {
    return isSameMinute(scheduledDate, publicDate)
      ? { dateTime: scheduledDate }
      : { dateTime: publicDate, originalDateTime: scheduledDate };
  } else {
    return { dateTime: scheduledDate };
  }
}

export function getRouteLegDeparture(
  leg: IntermodalJourneyLeg,
  dateFormat: string,
): RouteLegProps['departure'] {
  const scheduledDate = getDateFromDateAndTime(leg.departureDate, leg.departureTime);
  const publicDate = getDateFromDateAndTime(leg.publicDepartureDate, leg.publicDepartureTime);

  const props = getScheduledOrPublicDateTime(scheduledDate, publicDate);

  return {
    location: getLocationName(leg.route.departureLocation),
    dateTime: formatDateTime(props.dateTime, dateFormat),
    originalDateTime: props.originalDateTime
      ? formatDateTime(props.originalDateTime, dateFormat)
      : undefined,
  };
}

export function getRouteLegArrival(
  leg: IntermodalJourneyLeg,
  dateFormat: string,
): RouteLegProps['arrival'] {
  const scheduledDate = getDateFromDateAndTime(leg.arrivalDate, leg.arrivalTime);
  const publicDate = getDateFromDateAndTime(leg.publicArrivalDate, leg.publicArrivalTime);

  const props = getScheduledOrPublicDateTime(scheduledDate, publicDate);

  return {
    location: getLocationName(leg.route.arrivalLocation),
    dateTime: formatDateTime(props.dateTime, dateFormat),
    originalDateTime: props.originalDateTime
      ? formatDateTime(props.originalDateTime, dateFormat)
      : undefined,
  };
}
