import { FilterDateString } from '../../common/utils/dates/dateFilter';
import {
  BookingFilterState,
  CustomerCustomsStatus,
  FilterCargo,
  FilterTime,
  FilterVehicleType,
} from '../../gql/graphql';
import { StoreState } from '../../store/storeState';
import { FilterAction } from './filterActions';

export type ListFilter = keyof Pick<
  FilterState,
  | 'routeCodeIds'
  | 'customerIds'
  | 'vehicleIds'
  | 'filterStates'
  | 'cargoIds'
  | 'customerCustomsStatusIds'
>;

export interface FilterState {
  customerIds: string[];
  customerCustomsStatusIds: CustomerCustomsStatus[];
  cargoIds: FilterCargo[];
  date: FilterDate;
  query: string;
  routeCodeIds: string[];
  time: FilterTime;
  filterStates: BookingFilterState[];
  vehicleIds: FilterVehicleType[];
}

export interface FilterViewState {
  focusedSelectorView: FilterType;
  selectorViewStatus: Record<FilterType, boolean>;
  selectorViewOpen: boolean;
}

export type FilterDateDate =
  | { type: 'absolute'; dateString: string }
  | { type: 'relative'; value: FilterDateString };

export interface FilterDate {
  from?: FilterDateDate;
  to?: FilterDateDate;
}

export enum FilterType {
  Date = 1,
  Time = 2,
  Route = 3,
  Status = 4,
  VehicleType = 5,
  CargoInfo = 6,
  Customer = 7,
  CustomerCustomsStatus = 8,
}

export interface Filter {
  filter: FilterState;
  initialFilterApplied: boolean;
  view: FilterViewState;
}

export const INITIAL_VALUE: Filter = {
  filter: {
    cargoIds: [],
    customerCustomsStatusIds: [],
    customerIds: [],
    date: {},
    filterStates: [],
    query: '',
    routeCodeIds: [],
    time: {},
    vehicleIds: [],
  },
  initialFilterApplied: false,
  view: {
    focusedSelectorView: FilterType.Date,
    selectorViewOpen: false,
    selectorViewStatus: {
      [FilterType.Date]: false,
      [FilterType.Time]: false,
      [FilterType.Route]: false,
      [FilterType.Status]: false,
      [FilterType.VehicleType]: false,
      [FilterType.CargoInfo]: false,
      [FilterType.Customer]: false,
      [FilterType.CustomerCustomsStatus]: false,
    },
  },
};

export const filterReducer = (state: Filter = INITIAL_VALUE, action: FilterAction): Filter => {
  switch (action.type) {
    case 'FILTER_INITIAL_FILTER_APPLIED':
      return {
        ...state,
        initialFilterApplied: true,
      };
    case 'FILTER_CLEAR':
      return {
        ...state,
        filter: {
          ...INITIAL_VALUE.filter,
        },
      };
    case 'FILTER_SET':
      return {
        ...state,
        filter: action.payload.filter,
      };
    case 'FILTER_ADD_ID':
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.filter]: Array.from(
            new Set<string>([...state.filter[action.payload.filter], action.payload.id]),
          ),
        },
      };
    case 'FILTER_ADD_IDS':
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.filter]: Array.from(
            new Set<string>([...state.filter[action.payload.filter], ...action.payload.ids]),
          ),
        },
      };
    case 'FILTER_REMOVE_ID':
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.filter]: state.filter[action.payload.filter].filter(
            (id: string) => action.payload.id !== id,
          ),
        },
      };
    case 'FILTER_REMOVE_IDS':
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.filter]: state.filter[action.payload.filter].filter(
            (id: string) => action.payload.ids.indexOf(id) === -1,
          ),
        },
      };
    case 'FILTER_SET_TIME':
      return {
        ...state,
        filter: {
          ...state.filter,
          time: {
            ...action.payload,
          },
        },
      };
    case 'FILTER_SET_DATE':
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.payload.from || action.payload.to ? action.payload : {},
        },
      };
    case 'FILTER_REMOVE_DATE':
      return {
        ...state,
        filter: {
          ...state.filter,
          date: {},
        },
      };
    case 'FILTER_SET_SEARCH_QUERY':
      return {
        ...state,
        filter: {
          ...state.filter,
          query: action.payload.query,
        },
      };
    case 'FILTER_SET_SELECTOR_VIEW_STATUS':
      return {
        ...state,
        view: {
          ...state.view,
          focusedSelectorView: action.payload.selectorViewStatus,
          selectorViewStatus: {
            ...state.view.selectorViewStatus,
            [action.payload.selectorViewStatus]: true,
          },
        },
      };
    case 'FILTER_TOGGLE_SELECTOR_VIEW_STATUS':
      return {
        ...state,
        view: {
          ...state.view,
          selectorViewStatus: {
            ...state.view.selectorViewStatus,
            [action.payload.selectorViewStatus]:
              !state.view.selectorViewStatus[action.payload.selectorViewStatus],
          },
        },
      };
    case 'FILTER_SET_SELECTOR_VIEW_OPEN':
      return {
        ...state,
        view: {
          ...state.view,
          focusedSelectorView: action.payload.selectorViewOpen
            ? state.view.focusedSelectorView
            : INITIAL_VALUE.view.focusedSelectorView,
          selectorViewOpen: action.payload.selectorViewOpen,
        },
      };
    default:
      return state;
  }
};
export const getFilterState = (state: StoreState): Filter => state.filter;
export const isInitialFilterApplied = (state: StoreState): boolean =>
  getFilterState(state).initialFilterApplied;
export const getFilter = (state: StoreState): FilterState => getFilterState(state).filter;
export const getFilterView = (state: StoreState): FilterViewState => getFilterState(state).view;
export const getCustomerIds = (state: StoreState): string[] => getFilter(state).customerIds;
export const getVehicleIds = (state: StoreState): FilterVehicleType[] =>
  getFilter(state).vehicleIds;
export const getBookingFilterStates = (state: StoreState): BookingFilterState[] =>
  getFilter(state).filterStates;
export const getBookingFilterStatesContainsUnitsOnQuay = (state: StoreState): boolean => {
  const bookingFilterStates = getBookingFilterStates(state);
  return bookingFilterStates.some(
    state => state === BookingFilterState.OnQuay || state === BookingFilterState.Incoming,
  );
};
export const getCargo = (state: StoreState): FilterCargo[] => getFilter(state).cargoIds;
export const getRouteCodeIds = (state: StoreState): string[] => getFilter(state).routeCodeIds;
export const getTime = (state: StoreState): FilterTime => getFilter(state).time;
export const getDate = (state: StoreState): FilterDate => getFilter(state).date;
export const getQuery = (state: StoreState): string => getFilter(state).query;
export const getFilterSelectorViewOpenStatus = (state: StoreState): boolean =>
  getFilterView(state).selectorViewOpen;
export const getFilterSelectorFocusedSelectorView = (state: StoreState) =>
  getFilterView(state).focusedSelectorView;
export const getFilterSelectorViewStatusForType =
  (type: FilterType) =>
  (state: StoreState): boolean =>
    getFilterView(state).selectorViewStatus[type];
