import { useSelector } from 'react-redux';
import { getAuthTokenScope } from '../../services/authentication/authReducer';
import { getHasPermission, Permission } from '../../services/authentication/utils/authUtils';
import { useHasCashCustomer } from '../../services/settings/hooks/useHasCashCustomer';
import { useCallback } from 'react';

export const useHasPermission = (): ((permission: Permission) => boolean) => {
  const scope = useAuthScope();
  const hasCashCustomer = useHasCashCustomer();

  return useCallback(
    permission => getHasPermission(permission, scope, hasCashCustomer),
    [hasCashCustomer, scope],
  );
};

export const useAuthScope = () => useSelector(getAuthTokenScope);
