import { isValid, parse } from 'date-fns';
import { useEffect, useState } from 'react';
import { formatDate, formatServerDate } from '../utils/dates/dateUtils';
import { useLocalDateFormat } from './useLocalDateFormat';

export const useLocalDateInputState = (
  initialDate: string,
  onChange: (date: string) => void,
): [string, (date: string) => void] => {
  const dateFormat = useLocalDateFormat();

  const [inputValue, setInputValue] = useState(() =>
    initialDate ? formatDate(initialDate, dateFormat) : '',
  );

  useEffect(() => {
    if (initialDate) {
      setInputValue(formatDate(initialDate, dateFormat));
    }
  }, [dateFormat, initialDate]);

  const handleChange = (date: string): void => {
    const maybeDate = parse(date, dateFormat, new Date());
    if (date.length >= dateFormat.length && isValid(maybeDate)) {
      onChange(formatServerDate(maybeDate));
    } else if (initialDate !== '') {
      onChange('');
    }
    setInputValue(date);
  };

  return [inputValue, handleChange];
};
