import { pick } from 'lodash';
import { BasicBooking } from '../../common/graphql/fragments/gql/BasicBooking';
import { RegistrationNumberAlertFormState } from './registrationNumberAlert';
import { UpdateFormAction } from './updateFormActions';
import { BookingFormState } from './updateFormReducer';
import { UpdateBooking_updateBooking } from '../../use-cases/manage/details/graphql/gql/UpdateBooking';

export interface BookingValidationErrors extends Partial<Record<keyof BookingFormState, string>> {}

export interface UpdateResult {
  bookingOutOfDate?: boolean;
  result?: UpdateBooking_updateBooking;
  genericError?: string;
}

export interface BookingFormMeta {
  updateCount: number;
  submitting: boolean;
  updateResult: UpdateResult | null;
  validationErrors: BookingValidationErrors;
}

export interface UpdateFormState {
  booking?: BasicBooking;
  editedFormState?: BookingFormState;
  initialFormState?: BookingFormState;
  meta: BookingFormMeta;
  registrationNumberAlert?: RegistrationNumberAlertFormState;
}

const INITIAL_STATE: UpdateFormState = {
  meta: {
    submitting: false,
    updateCount: 0,
    updateResult: null,
    validationErrors: {},
  },
};

export const updateBookingReducer = (
  state: UpdateFormState = INITIAL_STATE,
  action: UpdateFormAction,
): UpdateFormState => {
  switch (action.type) {
    case 'UPDATE_FORM:INITIALIZE_UPDATE_FORM': {
      return {
        booking: action.payload.booking,
        editedFormState: action.payload.formState,
        initialFormState: action.payload.formState,
        meta: {
          submitting: false,
          updateCount: 0,
          updateResult: null,
          validationErrors: {},
        },
      };
    }
    case 'UPDATE_FORM:REVERT_CHANGES_UPDATE_FORM': {
      return {
        ...state,
        editedFormState: state.initialFormState,
        meta: {
          submitting: false,
          updateCount: 0,
          updateResult: {},
          validationErrors: {},
        },
      };
    }
    case 'UPDATE_FORM:SET_SAVE_AS_WAIT_LIST': {
      return {
        ...state,
        editedFormState: {
          ...state.editedFormState,
          saveAsWaitList: true,
        },
      };
    }
    case 'UPDATE_FORM:SET_VALUES': {
      return {
        ...state,
        editedFormState: {
          ...state.editedFormState,
          ...action.payload.values,
        },
      };
    }
    case 'UPDATE_FORM:UPDATE_STARTED': {
      return {
        ...state,
        meta: {
          ...state.meta,
          updateCount: state.meta.updateCount + 1,
        },
      };
    }
    case 'UPDATE_FORM:UPDATE_VALUES': {
      const validationErrors: BookingValidationErrors = { ...state.meta.validationErrors };

      for (const value in action.payload.values) {
        delete validationErrors[value as keyof BookingFormState];
      }

      return {
        ...state,
        editedFormState: {
          ...state.editedFormState,
          ...action.payload.values,
        },
        meta: {
          ...state.meta,
          validationErrors,
        },
      };
    }
    case 'UPDATE_FORM:REVERT_VALUES': {
      const initialFormState = state.initialFormState;
      if (initialFormState) {
        const overridingKeys = pick(initialFormState, action.payload.keys);
        return {
          ...state,
          editedFormState: {
            ...state.editedFormState,
            ...overridingKeys,
          },
        };
      }
      return state;
    }
    case 'UPDATE_FORM:UPDATE_FINISHED': {
      return {
        ...state,
        meta: {
          ...state.meta,
          updateCount: state.meta.updateCount - 1,
        },
      };
    }
    case 'UPDATE_FORM:SUBMIT_START': {
      return {
        ...state,
        meta: {
          ...state.meta,
          submitting: true,
          validationErrors: {},
        },
      };
    }
    case 'UPDATE_FORM:SUBMIT_FINISHED': {
      return {
        ...state,
        editedFormState: action.payload.formState || state.editedFormState,
        initialFormState: action.payload.formState || state.initialFormState,
        booking: action.payload.booking || state.booking,
        meta: {
          ...state.meta,
          updateResult: action.payload.updateResult,
          submitting: false,
          validationErrors: action.payload.validationErrors,
        },
      };
    }
    case 'UPDATE_FORM:SET_UPDATE_RESULT': {
      return {
        ...state,
        meta: {
          ...state.meta,
          updateResult: action.payload.result,
        },
      };
    }
    case 'UPDATE_FORM:SET_REGISTRATION_NUMBER_ALERT':
      return {
        ...state,
        registrationNumberAlert: action.payload.registrationNumberAlert,
      };
    case 'UPDATE_FORM:UPDATE_REGISTRATION_NUMBER_ALERT':
      return {
        ...state,
        ...(state.registrationNumberAlert
          ? {
              registrationNumberAlert: {
                ...state.registrationNumberAlert,
                ...action.payload.registrationNumberAlert,
              },
            }
          : {}),
      };
    default: {
      return state;
    }
  }
};
