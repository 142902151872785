import { useAllIntermodalCustomersAsOptions } from '../../../../services/intermodal/hooks/useAllIntermodalCustomersAsOptions';
import { useCreateIntermodalFieldValue } from '../../../../services/intermodal/hooks/UseCreateIntermodalFieldValue';
import { IntermodalDetailsFieldCell } from '../../common/IntermodalDetailsFieldCell';
import { CreateIntermodalTextInputField } from '../fields/CreateIntermodalTextInputField';
import { customerReferencePlaceHolder } from '../../../../common/utils/customerUtils';
import { transformCustomer } from '../../../manage/grid/utils/optionTransformers';
import { CreateIntermodalDropdownField } from '../fields/CreateIntermodalDropdownField';
import { CreateIntermodalValidationWrapper } from '../CreateIntermodalValidationWrapper';
import { MAX_LENGTH_CUSTOMER_REFERENCE, MAX_LENGTH_LOADING_NOTE } from '../../utils/fieldLengths';

export const CreateIntermodalDetailsSection = () => {
  const customerOptions = useAllIntermodalCustomersAsOptions();
  const multipleCustomers = customerOptions.length > 1;
  const customer = useCreateIntermodalFieldValue('customer');

  return (
    <>
      {multipleCustomers && (
        <CreateIntermodalValidationWrapper
          name={'customer'}
          renderField={isValid => (
            <IntermodalDetailsFieldCell label={'Account'}>
              <CreateIntermodalDropdownField
                trackerCategory={'IntermodalCreate'}
                name={'customer'}
                options={customerOptions}
                transformOption={transformCustomer}
                isValid={isValid}
              />
            </IntermodalDetailsFieldCell>
          )}
        />
      )}
      <IntermodalDetailsFieldCell label={'Customer reference'}>
        <CreateIntermodalTextInputField
          trackerCategory={'IntermodalCreate'}
          maxLength={MAX_LENGTH_CUSTOMER_REFERENCE}
          name={'customerReference'}
          placeholder={customerReferencePlaceHolder(customer)}
        />
      </IntermodalDetailsFieldCell>
      <IntermodalDetailsFieldCell label={'Loading note'}>
        <CreateIntermodalTextInputField
          maxLength={MAX_LENGTH_LOADING_NOTE}
          name={'loadingNote'}
          trackerCategory={'IntermodalCreate'}
        />
      </IntermodalDetailsFieldCell>
    </>
  );
};
