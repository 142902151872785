import { FC } from 'react';
import { LinkButton } from '../../../common-ui/LinkButton';
import { Sailing } from '../../../common/graphql/fragments/gql/Sailing';
import { useLocalDateFormat } from '../../../common/hooks/useLocalDateFormat';
import { useShowDeparture } from '../../../common/hooks/useShowDeparture';
import { TrackerCategory } from '../../../common/tracking/trackerService';
import { formatDateTime, getDateFromDateAndTime } from '../../../common/utils/dates/dateUtils';

interface SailingLinkProps {
  sailing: Sailing;
  onAfterShowDeparture?: () => void;
  trackerCategory: TrackerCategory;
}

export const formatSailingLinkText = (
  { departureDate, departureTime }: { departureDate: string; departureTime: string },
  routeCode: string,
  dateFormat: string,
): string => {
  const dateTime = getDateFromDateAndTime(departureDate, departureTime);

  return `${routeCode} ${formatDateTime(dateTime, dateFormat)}`;
};

export const SailingLink: FC<SailingLinkProps> = ({
  sailing,
  onAfterShowDeparture,
  trackerCategory,
}) => {
  const dateFormat = useLocalDateFormat();
  const showDeparture = useShowDeparture(
    sailing.departureDate,
    sailing.departureTime,
    sailing.route.code,
    trackerCategory,
    'Show departure',
  );

  const label = formatSailingLinkText(sailing, sailing.route.code, dateFormat);

  return (
    <LinkButton
      className={'t_booking_toast_show_departure_link'}
      onClick={() => {
        showDeparture();
        onAfterShowDeparture?.();
      }}
      label={label}
    />
  );
};
