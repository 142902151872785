import { StoreState } from '../../store/storeState';
import { SearchActions } from './searchActions';

export interface SearchQueryState {
  showOverlay: boolean;
}

const INITIAL_VALUE: SearchQueryState = {
  showOverlay: false,
};

export const searchReducer = (
  state: SearchQueryState = INITIAL_VALUE,
  action: SearchActions,
): SearchQueryState => {
  switch (action.type) {
    case 'SEARCH_QUERY:SHOW_OVERLAY':
      return {
        ...state,
        showOverlay: true,
      };
    case 'SEARCH_QUERY:HIDE_OVERLAY':
      return {
        ...state,
        showOverlay: false,
      };
    default:
      return state;
  }
};

export const getSearchQuery = (state: StoreState): SearchQueryState => state.searchQuery;
export const getShowOverlay = (state: StoreState): boolean => getSearchQuery(state).showOverlay;
