import { Box, Column } from '@stenajs-webui/core';
import { Spinner } from '@stenajs-webui/elements';
import cx from 'classnames';
import { FC, ReactNode, Suspense } from 'react';
import { BaseModal, BaseModalProps } from './BaseModal';
import styles from './Modal.module.css';
import { ModalHeader, ModalHeaderProps } from './ModalHeader';
import { ScrollShadow } from './ScrollShadow';

export interface ModalProps extends BaseModalProps, ModalHeaderProps {
  draggable?: boolean;
  loading?: boolean;
  footer?: ReactNode;
  disableStickyFooter?: boolean;
}

export const Modal: FC<ModalProps> = ({
  header,
  headerText,
  children,
  draggable = false,
  loading = false,
  onRequestClose,
  closeButtonClassName,
  footer,
  disableStickyFooter = false,
  ...props
}) => {
  return (
    <BaseModal {...props} onRequestClose={onRequestClose} draggable={draggable}>
      <Suspense fallback={<ModalContentsLoading />}>
        {loading ? (
          <ModalContentsLoading />
        ) : (
          <>
            <ModalHeader
              onRequestClose={onRequestClose}
              header={header}
              headerText={headerText}
              closeButtonClassName={closeButtonClassName}
            />
            {children}
            {footer && (
              <StickyModalFooter disableSticky={disableStickyFooter}>{footer}</StickyModalFooter>
            )}
          </>
        )}
      </Suspense>
    </BaseModal>
  );
};

export const StickyModalFooter: FC<{ children: ReactNode; disableSticky?: boolean }> = ({
  disableSticky,
  children,
}) => (
  <Box
    className={cx(styles.footer, {
      [styles.stickyFooter]: !disableSticky,
    })}
  >
    {!disableSticky && <ScrollShadow />}
    {children}
  </Box>
);

const ModalContentsLoading = () => (
  <Column spacing={2} alignItems={'center'}>
    <Spinner size={'small'} />
  </Column>
);
