import { useCallback } from 'react';
import { OnChangeValue as ValueType, Options as OptionsType } from 'react-select';
import { GroupedOptionsType } from '../../../common-ui/form-fields/Dropdown';
import { findOptionByValue } from '../../../common/utils/DropdownUtils';
import { isDefined } from '../../../common/utils/isDefined';
import { DropdownOption } from '../../manage/grid/utils/optionTransformers';
import { trackEditEvent, TrackerAction } from '../../simple-create/hooks/UseTrackingTextInput';
import { TrackerCategory } from '../../../common/tracking/trackerService';
import { hasValueChanged } from '../../manage/grid/utils/hasValueChanged';

type TransformOption<T> = (option: NonNullable<T>) => DropdownOption<NonNullable<T>>;

interface UseDropdownFieldProp<T> {
  options:
    | GroupedOptionsType<DropdownOption<NonNullable<T>>>
    | OptionsType<DropdownOption<NonNullable<T>>>;
  transformOption: TransformOption<T>;
  value: T | undefined;
  onChange: (value: NonNullable<T> | undefined) => void;
  trackerCategory: TrackerCategory;
  trackerAction: TrackerAction;
}

interface UseDropdownField<T, IsMulti extends boolean> {
  label?: string;
  onChange: (data: ValueType<DropdownOption<NonNullable<T>>, IsMulti>) => void;
  value: DropdownOption<NonNullable<T>> | undefined;
}

const getTransformedOption = <T>(
  v: T | null | undefined,
  transform: TransformOption<T>,
): DropdownOption<NonNullable<T>> | undefined => {
  if (isDefined(v)) {
    return transform(v);
  }
  return undefined;
};

export function hasDropdownValueChanged<TValue>(
  {
    initialValue,
    editedValue,
  }: {
    initialValue: TValue | undefined;
    editedValue: TValue | undefined;
  },
  transformOption: TransformOption<TValue>,
) {
  return hasValueChanged(
    getTransformedOption(initialValue, transformOption),
    getTransformedOption(editedValue, transformOption),
  );
}

export const useDropdownField = <TValue, IsMulti extends boolean>({
  options,
  transformOption,
  value,
  onChange,
  trackerCategory,
  trackerAction,
}: UseDropdownFieldProp<TValue>): UseDropdownField<TValue, IsMulti> => {
  const option = getTransformedOption(value, transformOption);

  const selectedOption = findOptionByValue(options, option ? option.value : undefined);

  const onNewChange = useCallback(
    (opt: ValueType<DropdownOption<NonNullable<TValue>>, IsMulti>) => {
      if (!opt || Array.isArray(opt)) {
        onChange(undefined);
      } else if (selectedOption !== opt && 'data' in opt) {
        onChange(opt.data);

        if (option?.value !== opt.value) {
          trackEditEvent(trackerAction, trackerCategory, opt.label);
        }
      }
    },
    [onChange, selectedOption, trackerCategory, trackerAction, option],
  );

  const optionToShow = value === undefined && selectedOption ? selectedOption : option;

  return {
    label: optionToShow ? optionToShow.label : undefined,
    onChange: onNewChange,
    value: selectedOption,
  };
};
