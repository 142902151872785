import { StoreState } from '../../store/storeState';
import { ModalActions, ModalDefinition } from './modalActions';

export interface ModalState {
  modals: ModalDefinition[];
}

const INITIAL_VALUE: ModalState = {
  modals: [],
};

export const modalReducer = (
  state: ModalState = INITIAL_VALUE,
  action: ModalActions,
): ModalState => {
  switch (action.type) {
    case 'MODAL:POP_MODAL': {
      const [, ...modals] = state.modals;
      return {
        modals,
      };
    }
    case 'MODAL:PUSH_MODAL:INTRODUCTION':
    case 'MODAL:PUSH_MODAL:URGENT_MESSAGE':
    case 'MODAL:PUSH_MODAL:CO2_EMISSIONS':
    case 'MODAL:PUSH_MODAL:CUSTOMS_LINK_GDPR':
      return {
        modals: [action.payload, ...state.modals],
      };
    case 'MODAL:PUSH_MODAL:HOTKEYS_OVERVIEW':
      if (state.modals.every(modal => modal.type !== 'hotkeysOverview')) {
        return { modals: [action.payload, ...state.modals] };
      }

      return state;
    case 'MODAL:CLEAR_MODALS':
      return {
        modals: [],
      };
    default:
      return state;
  }
};

export const getModalState = (state: StoreState): ModalState => state.modal;
export const getShowModal = (state: StoreState): boolean => getModalState(state).modals.length > 0;
export const getActiveModal = (state: StoreState): ModalDefinition | undefined =>
  getModalState(state).modals[0];
