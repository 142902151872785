import { all, put, select, takeEvery } from 'redux-saga/effects';
import { calculateNewPartialCandidate } from '../../../use-cases/create/utils/arrivalNotepadUtils';
import { CreateBookingFormRevertValuesAction } from '../arrivalNotepadActions';
import { updateSpecificValues } from '../createFormActions';
import { CreateCandidate } from '../createFormReducer';
import { getCreateFormStateInitialCandidate } from '../createReducer';

function* handleRestoreValues(action: CreateBookingFormRevertValuesAction) {
  const { formId, id, keys } = action.payload;

  const initialCandidate: CreateCandidate | undefined = yield select(
    getCreateFormStateInitialCandidate(formId),
    id,
  );

  if (initialCandidate) {
    yield put(
      updateSpecificValues(formId, id, {
        ...calculateNewPartialCandidate(keys, initialCandidate),
      }),
    );
  }
}

export function* watchArrivalNotepad() {
  yield all([takeEvery('CREATE_FORM:ARRIVAL_NOTEPAD:REVERT_VALUES', handleRestoreValues)]);
}
