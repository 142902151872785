import { Box, Text } from '@stenajs-webui/core';
import { Spinner, stenaHistory } from '@stenajs-webui/elements';
import { FC } from 'react';
import { PopoverTitle } from '../../../../common-ui/popover/PopoverTitle';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { useBookingHistory } from '../../../../common/graphql/useBookingHistory';
import { useLocalDateFormat } from '../../../../common/hooks/useLocalDateFormat';
import { getHistoryColumns, transformHistoryRows } from '../utils/historyUtil';
import { HistoryTable } from './HistoryTable';
import { PopoverBody } from '../../../../common-ui/popover/PopoverBody';

export interface HistoryProps {
  bookingNo: BasicBooking['bookingNo'];
  onClose: () => void;
}

export const HistoryPanel: FC<HistoryProps> = ({ bookingNo, onClose }) => {
  const [history, loading] = useBookingHistory(bookingNo);

  const dateFormat = useLocalDateFormat();

  return (
    <Box minWidth={320}>
      <PopoverTitle text={'History'} icon={stenaHistory} onClose={onClose} />
      <PopoverBody>
        {history ? (
          <HistoryTable
            columns={getHistoryColumns(dateFormat)}
            rows={transformHistoryRows(history)}
          />
        ) : loading ? (
          <Box alignItems={'center'}>
            <Spinner size={'small'} />
          </Box>
        ) : (
          <Text>No history found for this booking.</Text>
        )}
      </PopoverBody>
    </Box>
  );
};
