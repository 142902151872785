import { TextInputButtonSize } from '@stenajs-webui/elements';
import { FC, ReactNode, useState } from 'react';
import { getInformationMessageInfo } from '../../../common-ui/information-message/informationMessageUtils';
import { FreightPortalPopover } from '../../../common-ui/popover/FreightPortalPopover';
import { stopPropagation } from '../../../common/eventHelpers';
import { BasicBooking_importReference_status } from '../../../common/graphql/fragments/gql/BasicBooking';
import { ImportExportStatusCode } from '../../../gql/graphql';
import { resolveInformationMessageType } from '../util/InformationMessageFactory';
import { ImportExportStatusIcon, TextInputButtonPlaceholder } from './ImportExportStatusIcon';
import { ImportExportPopupContent } from './popup/ImportExportPopupContent';

interface ImportExportStatusContentProps {
  children?: ReactNode;
  bookingNo: number;
  onRefreshIconClick?: () => Promise<void>;
  status: BasicBooking_importReference_status | undefined | null;
  showPopupRefreshStatusButton?: boolean;
  visible: boolean;
  onRequestClose: () => void;
  onOpen: () => void;
  size?: TextInputButtonSize;
}

export const ImportExportStatusCellIconButton: FC<ImportExportStatusContentProps> = ({
  status,
  onRefreshIconClick,
  bookingNo,
  showPopupRefreshStatusButton = true,
  onOpen,
  onRequestClose,
  visible,
  size = 'medium',
  children,
}) => {
  const [loading, setLoading] = useState(false);

  if (!status) {
    return <TextInputButtonPlaceholder size={size} />;
  }

  const { statusCode } = status;

  if (statusCode === ImportExportStatusCode.Refresh && onRefreshIconClick) {
    const onClick = async () => {
      try {
        setLoading(true);
        await onRefreshIconClick();
      } finally {
        setLoading(false);
      }
    };
    return (
      <ImportExportStatusIcon
        spin={loading}
        code={statusCode}
        size={size}
        onClick={onClick}
        title={status.title}
      />
    );
  }

  const infoMessageIconProps = getInformationMessageInfo(resolveInformationMessageType(statusCode));

  return (
    <FreightPortalPopover
      lazy
      onRequestClose={onRequestClose}
      visible={visible}
      placement={'left-start'}
      content={
        <ImportExportPopupContent
          onDoubleClick={stopPropagation}
          onRequestClose={onRequestClose}
          status={status}
          infoMessageIconProps={infoMessageIconProps}
          bookingNo={bookingNo}
          showRefreshStatusButton={showPopupRefreshStatusButton}
        >
          {children}
        </ImportExportPopupContent>
      }
    >
      <ImportExportStatusIcon code={statusCode} size={size} onClick={onOpen} title={status.title} />
    </FreightPortalPopover>
  );
};
