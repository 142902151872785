import { DateInterval, DateSetting, DisplayDensity } from '../../../gql/graphql';
import { AccountQuery_viewer_account_byEmail } from '../graphql/gql/AccountQuery';

export const getShowNotCheckedIn = (showNotCheckedIn: boolean | null): boolean =>
  showNotCheckedIn !== null ? showNotCheckedIn : true;
export const getShowPendingApproval = (showPendingApproval: boolean | null): boolean =>
  showPendingApproval !== null ? showPendingApproval : true;
export const getShowDateOverview = (showNotCheckedInTime: boolean | null): boolean =>
  showNotCheckedInTime !== null ? showNotCheckedInTime : true;
export const getShowUnaccompaniedUnits = (showUnaccompaniedUnits: boolean | null): boolean =>
  showUnaccompaniedUnits !== null ? showUnaccompaniedUnits : true;
export const getShowDashboardOnScroll = (showDashboardOnScroll: boolean | null): boolean =>
  showDashboardOnScroll !== null ? showDashboardOnScroll : true;

export const defaultSettings: AccountQuery_viewer_account_byEmail = {
  customers: [],
  accountSettings: {
    id: 'default',
    bookDate: DateSetting.Today,
    bookCustomer: null,
    bookDefaultRoute: null,
    bookDefaultVehicleType: null,
    favouriteRoutes: [],
    favouriteVehicleTypes: [],
    dateFormat: null,
    accountEmail: '',
    accountFirstName: '',
    accountLastName: '',
    accountLoginEmail: '',
    accountPhone: '',
    accountSecondEmail: '',
    accountSendBookingConfirmationAsEmail: false,
    filterDateInterval: DateInterval.TodayAndTomorrow,
    filterFilterStates: [],
    filterRoutes: [],
    dashboardNotCheckedInTime: 120,
    dashboardShowNotCheckedIn: getShowNotCheckedIn(null),
    dashboardShowPendingApproval: getShowPendingApproval(null),
    dashboardShowDashboardOnScroll: getShowDashboardOnScroll(null),
    dashboardShowDateOverview: getShowDateOverview(null),
    dashboardShowUnaccompaniedUnits: getShowUnaccompaniedUnits(null),
  },
  privacySettings: { id: 'default', customsLink: false },
  pageSettings: {
    id: 'default',
    tour: {
      featuresSeen: [],
      introductionTourSeen: false,
    },
    column: {
      enabled: [],
      order: [],
      width: [],
    },
    grid: {
      displayDensity: DisplayDensity.Normal,
      bookingsPerLoad: 50,
    },
    sidebar: {
      pinned: false,
    },
  },
};
