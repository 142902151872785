import { TravelledVehicle } from '../../graphql/fragments/gql/TravelledVehicle';
import { VehicleType } from '../../graphql/fragments/gql/VehicleType';
import { TravelledVehicleType } from '../../../services/update-form/registrationNumberAlert';

export const isFTAndTRMatch = (
  previousVehicleType: VehicleType | null,
  travelledVehicleResult: TravelledVehicle,
): boolean => {
  if (!previousVehicleType) {
    return false;
  }
  return (
    (travelledVehicleResult.vehicleTypeCode === 'FT' && previousVehicleType.code === 'TR') ||
    (travelledVehicleResult.vehicleTypeCode === 'TR' && previousVehicleType.code === 'FT')
  );
};

export const shouldApplyTravelledVehicleAutomatically = (
  previousVehicleType: VehicleType | null,
  travelledVehicleResult: TravelledVehicle,
): boolean => {
  if (travelledVehicleResult.vehicleTypeCode === '') {
    return true;
  }
  return Boolean(
    previousVehicleType &&
      (travelledVehicleResult.vehicleTypeCode === previousVehicleType.code ||
        isFTAndTRMatch(previousVehicleType, travelledVehicleResult)),
  );
};

export const getHeight = (
  travelledVehicleType: TravelledVehicleType,
  useTrailerReg: boolean,
  previousHeight: number | undefined,
  newHeight: number | undefined,
) => {
  const trailerRegExists = travelledVehicleType === TravelledVehicleType.Vehicle && useTrailerReg;

  let height = newHeight || previousHeight;

  if (trailerRegExists && height && previousHeight) {
    height = Math.max(height, previousHeight);
  }

  return height;
};
