import { graphql } from '../../../gql';

export const createIntermodalBookingMutation = graphql(/*GraphQL*/ `
  mutation CreateIntermodalBookingMutation($booking: CreateIntermodalBookingInput!) {
    createIntermodalBooking(booking: $booking) {
      booking {
        ...DetailedIntermodalBooking
      }
      errors {
        description
      }
      messages {
        description
      }
      warnings {
        description
      }
      success
    }
  }
`);
