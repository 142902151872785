import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PickByValue } from '@stenajs-webui/core';
import { mapKeys, uniq } from 'lodash';
import { v4 as uuid } from 'uuid';
import { Customer } from '../../common/graphql/fragments/gql/Customer';
import {
  DetailedIntermodalBooking,
  IntermodalBookingRoute,
  IntermodalJourneyResultJourneyLeg,
  IntermodalVehicleType,
} from '../../common/graphql/fragments/gql/IntermodalBooking';
import { Message } from '../../common/update-information/utils/messagesResult';
import { GoodsType } from '../../gql/graphql';
import { StoreState } from '../../store/storeState';
import { clearValidationErrors } from '../common/utils';
import { createIntermodalBookingThunk } from './thunks/createIntermodalBookingThunk';
import { getVehicleDimensions } from './utils';

export type CreateIntermodalFormModelStringKeys = keyof PickByValue<
  CreateIntermodalBookingFormModel,
  string
>;

export type CreateIntermodalBookingValidationErrors = Record<string, string[] | undefined>;

export type CreateIntermodalBookingFormModelDateKeys = keyof Pick<
  CreateIntermodalBookingFormModel,
  'departureDate'
>;

export type CreateIntermodalBookingFormModelDropdownKeys = keyof Pick<
  CreateIntermodalBookingFormModel,
  | 'vehicleType'
  | 'craneable'
  | 'departureTime'
  | 'noOfPlugins'
  | 'customer'
  | 'route'
  | 'hazardousGoods'
>;

interface JourneyResult {
  success: boolean;
  errors: Message[];
  messages: Message[];
  warnings: Message[];
}

export interface IntermodalCreateResult {
  booking?: DetailedIntermodalBooking;
  success: boolean;
  errors: Message[];
  messages: Message[];
  warnings: Message[];
}

export type CreateIntermodalBookingGoodFormModel = {
  goodsType: GoodsType | null;
  weight: string;
  id: string;
  created?: boolean;
};

export type CreateIntermodalBookingGoodsRecord = Record<
  string,
  CreateIntermodalBookingGoodFormModel
>;

export interface CreateIntermodalBookingFormModel {
  route: IntermodalBookingRoute | null;
  customer: Customer | null;
  departureDate: string;
  vehicleReg: string;
  iluCode: string;
  vehicleType: IntermodalVehicleType | null;
  craneable: boolean;
  emptyWeight: string;
  length: string;
  width: string;
  height: string;
  hazardousGoods: boolean;
  noOfPlugins: number;
  temperature: string;
  bookingGoods: CreateIntermodalBookingGoodsRecord;
  customerReference: string;
  loadingNote: string;
  departureTime: string;
  searchByDeparture: boolean;
  legs: IntermodalJourneyResultJourneyLeg[] | null;
}

interface CreateIntermodalBookingFormMeta {
  journeyResult: JourneyResult | null;
  createResult: IntermodalCreateResult | null;
  fieldValidationErrors: CreateIntermodalBookingValidationErrors;
  bannerValidationErrors: string[];
  submitting: boolean;
  fetchingJourney: boolean;
}

export interface CreateIntermodalBookingReducerState {
  formState: CreateIntermodalBookingFormModel;
  meta: CreateIntermodalBookingFormMeta;
}

export const createEmptyCreateFormModel = (
  goodId: string,
  partial?: Partial<CreateIntermodalBookingFormModel>,
): CreateIntermodalBookingFormModel => ({
  vehicleReg: '',
  customer: null,
  iluCode: '',
  length: '',
  width: '',
  height: '',
  emptyWeight: '',
  temperature: '',
  customerReference: '',
  craneable: true,
  loadingNote: '',
  route: null,
  bookingGoods: createInitialCreateGoodsFormModel(goodId),
  hazardousGoods: false,
  noOfPlugins: 0,
  departureDate: '',
  departureTime: '',
  vehicleType: null,
  searchByDeparture: true,
  legs: null,
  ...partial,
});

export const createInitialCreateGoodsFormModel = (
  goodId: string,
): CreateIntermodalBookingGoodsRecord => {
  const good = createEmptyCreateGoodsFormModel(goodId);
  return { [good.id]: good };
};

export const createEmptyCreateGoodsFormModel = (
  id: string,
): CreateIntermodalBookingGoodFormModel => ({
  goodsType: null,
  weight: '',
  id,
  created: true,
});

export const createEmptyCreateFormMeta = (): CreateIntermodalBookingFormMeta => ({
  journeyResult: null,
  createResult: null,
  fieldValidationErrors: {},
  bannerValidationErrors: [],
  submitting: false,
  fetchingJourney: false,
});

export const INITIAL_VALUE: CreateIntermodalBookingReducerState = {
  formState: createEmptyCreateFormModel(uuid()),
  meta: createEmptyCreateFormMeta(),
};

export function getBannerValidationErrors(
  fieldValidationErrors: Record<string, string[] | undefined>,
): string[] {
  return uniq(Object.values(fieldValidationErrors).flatMap(e => e ?? []));
}

const slice = createSlice({
  name: 'intermodalBooking',
  initialState: INITIAL_VALUE,
  reducers: {
    initializeCreateIntermodalFormState(
      state,
      action: PayloadAction<CreateIntermodalBookingFormModel>,
    ) {
      state.formState = action.payload;
      state.meta = {
        journeyResult: null,
        createResult: null,
        fieldValidationErrors: {},
        bannerValidationErrors: [],
        submitting: false,
        fetchingJourney: false,
      };
    },
    setCreateFormValue(state, action: PayloadAction<Partial<CreateIntermodalBookingFormModel>>) {
      clearValidationErrors(state.meta.fieldValidationErrors, action.payload);
      Object.assign(state.formState, action.payload);

      if (action.payload.vehicleType) {
        Object.assign(state.formState, getVehicleDimensions(action.payload.vehicleType));
      }
    },
    addCreateIntermodalBookingGood(state, action: PayloadAction<{ goodId: string }>) {
      const good = createEmptyCreateGoodsFormModel(action.payload.goodId);
      state.formState.bookingGoods[good.id] = good;
    },
    modifyCreateIntermodalBookingGood(
      state,
      action: PayloadAction<{
        id: string;
        updatedFields: Partial<CreateIntermodalBookingGoodFormModel>;
      }>,
    ) {
      const { id, updatedFields } = action.payload;
      clearValidationErrors(
        state.meta.fieldValidationErrors as any,
        mapKeys(updatedFields, (_v, k) => getBookingGoodsKey(id, k)),
      );

      const bookingGood = state.formState.bookingGoods[id];
      if (bookingGood) {
        Object.assign(bookingGood, updatedFields);
      }
    },
    removeCreateIntermodalBookingGood(state, action: PayloadAction<{ id: string }>) {
      delete state.formState.bookingGoods[action.payload.id];
    },
    setJourneyResultSuccess(
      state,
      action: PayloadAction<{
        result: JourneyResult;
        legs: IntermodalJourneyResultJourneyLeg[] | null;
      }>,
    ) {
      state.meta.journeyResult = { ...action.payload.result, errors: [] };
      state.formState.legs = action.payload.legs;
      state.meta.bannerValidationErrors = action.payload.result.errors.map(e => e.description);
    },
    setJourneyResultFailure(state, action: PayloadAction<CreateIntermodalBookingValidationErrors>) {
      state.meta.fieldValidationErrors = action.payload;
      state.meta.journeyResult = null;
      state.formState.legs = null;
    },
    startFetchingJourney(state) {
      state.meta.fetchingJourney = true;
    },
    finishFetchingJourney(state) {
      state.meta.fetchingJourney = false;
    },
    clearCreateBookingGoods(state) {
      state.formState.bookingGoods = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(createIntermodalBookingThunk.fulfilled, (state, action) => {
        state.meta.createResult = {
          success: true,
          errors: [],
          warnings: action.payload.warnings,
          messages: action.payload.messages,
          booking: action.payload.booking,
        };
        state.meta.submitting = false;
      })
      .addCase(createIntermodalBookingThunk.rejected, (state, action) => {
        const rejectedValue = action.payload;
        switch (rejectedValue?.type) {
          case 'validationErrors':
            state.meta.fieldValidationErrors = rejectedValue.errors;
            state.meta.bannerValidationErrors = getBannerValidationErrors(
              state.meta.fieldValidationErrors,
            );
            state.meta.createResult = null;
            break;
          case 'error':
            state.meta.createResult = {
              errors: rejectedValue.errors,
              messages: [],
              warnings: [],
              success: false,
            };
            break;
          default:
            state.meta.createResult = {
              messages: [],
              warnings: [],
              errors: action.error.message ? [{ description: action.error.message }] : [],
              success: false,
            };
        }
        state.meta.submitting = false;
      })
      .addCase(createIntermodalBookingThunk.pending, state => {
        state.meta.submitting = true;
        state.meta.fieldValidationErrors = {};
        state.meta.bannerValidationErrors = [];
        state.meta.createResult = null;
      }),
});

export const {
  setCreateFormValue,
  addCreateIntermodalBookingGood,
  removeCreateIntermodalBookingGood,
  initializeCreateIntermodalFormState,
  modifyCreateIntermodalBookingGood,
  setJourneyResultSuccess,
  startFetchingJourney,
  finishFetchingJourney,
  setJourneyResultFailure,
  clearCreateBookingGoods,
} = slice.actions;

export const createIntermodalBookingReducer = slice.reducer;

export const getCreateIntermodalBookingState = (state: StoreState) => state.intermodalCreate;

export const getCreateIntermodalBookingValidationErrors = (
  state: StoreState,
  name: keyof CreateIntermodalBookingFormModel,
) => getCreateIntermodalBookingState(state).meta.fieldValidationErrors[name];

export const getCreateIntermodalBookingFormState = (state: StoreState) =>
  getCreateIntermodalBookingState(state).formState;

export const getCreateIntermodalBookingLegs = (state: StoreState) =>
  getCreateIntermodalBookingState(state).formState.legs;

export const getCreateIntermodalBookingIsSubmitting = (state: StoreState) =>
  state.intermodalCreate.meta.submitting;

export const getCreateIntermodalBookingIsFetchingJourney = (state: StoreState) =>
  state.intermodalCreate.meta.fetchingJourney;

export const getBookingGoodsKey = (goodId: string, key: string) => `bookingGoods.${goodId}.${key}`;

export const getCreateIntermodalBookingGoodValidationErrors = (
  state: StoreState,
  goodId: string,
  key: keyof CreateIntermodalBookingGoodFormModel,
) =>
  getCreateIntermodalBookingState(state).meta.fieldValidationErrors[
    getBookingGoodsKey(goodId, key)
  ];

export const getCreateIntermodalBookingGood = (state: StoreState, id: string) => {
  return getCreateIntermodalBookingFormState(state).bookingGoods[id];
};

export const getCreateIntermodalBookingJourneyResult = (state: StoreState) =>
  state.intermodalCreate.meta.journeyResult;

export const getCreateIntermodalBookingCreateResult = (state: StoreState) =>
  state.intermodalCreate.meta.createResult;

export const getAllIntermodalCreateValidationErrors = (state: StoreState) =>
  getCreateIntermodalBookingState(state).meta.bannerValidationErrors;
