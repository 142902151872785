import { FilterState, INITIAL_VALUE } from '../../../services/booking-filter/filterReducer';

export const createDepartureFilter = (
  departureDate: string,
  routeCode: string,
  departureTime: string,
): FilterState => ({
  ...INITIAL_VALUE.filter,
  date: {
    from: { type: 'absolute', dateString: departureDate },
    to: { type: 'absolute', dateString: departureDate },
  },
  routeCodeIds: [routeCode],
  time: {
    end: departureTime,
    start: departureTime,
  },
});
