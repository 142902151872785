import { FC } from 'react';
import { TrackerCategory } from '../../../../common/tracking/trackerService';
import {
  CommonDateInputField,
  CommonDateInputFieldProps,
} from '../../../../common-ui/form-fields/CommonDateInputField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import { UpdateIntermodalBookingFormModelDateKeys } from '../../../../services/intermodal/updateIntermodalReducer';
import { useUpdateIntermodalField } from '../../hooks/useUpdateIntermodalField';

interface UpdateIntermodalDateInputFieldProps
  extends Omit<CommonDateInputFieldProps, 'onValueChange' | 'trackerAction' | 'value'> {
  trackerCategory: TrackerCategory;
  name: UpdateIntermodalBookingFormModelDateKeys;
}

export const UpdateIntermodalDateInputField: FC<UpdateIntermodalDateInputFieldProps> = ({
  name,
  ...props
}) => {
  const fieldProps = useUpdateIntermodalField(name);

  return (
    <CommonDateInputField
      {...props}
      {...getFieldProps(props, fieldProps)}
      changed={fieldProps.initialValue !== fieldProps.editedValue}
    />
  );
};
