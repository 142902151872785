import { LinkProps, Path } from 'react-router-dom';
import { mapValues } from 'lodash';

interface RouteParamsWithBookingNo {
  bookingNo: string | number;
}

export const routeCreators = {
  bookings: () => `/bookings`,
  callback: () => `/callback`,
  claimsPortal: () => `/claims`,
  noShowLateHandling: () => `/noshow-latehandling`,
  financialDocuments: () => `/financial-documents`,
  bookingClaims: ({ bookingNo }: RouteParamsWithBookingNo) => `/claims/${bookingNo}`,
  help: () => '/help',
  create: () => '/create',
  customsDocumentation: ({
    bookingNo,
    route,
    sailingId,
    userId,
  }: RouteParamsWithBookingNo & { sailingId: string; userId: string; route: string }) =>
    `/customs-documentation/${bookingNo}/${sailingId}/${userId}/${route}`,
  hazardousGoods: ({ bookingNo }: RouteParamsWithBookingNo) =>
    `/bookings/${bookingNo}/hazardous-goods`,
  settings: () => '/settings',
  settingsPassword: () => '/settings/password',
  settingsFilters: () => '/settings/filters',
  settingsFavourites: () => '/settings/favourites',
  settingsDateFormat: () => '/settings/date-format',
  settingsCookies: () => '/settings/cookies',
  userSubscriptions: () => '/settings/notifications',
  payment: () => '/pay',
  timetable: () => '/timetable',
  statistics: () => '/statistics',
  intermodal: () => '/intermodal',
};

export interface Location extends Partial<Path> {
  pathname: string;
}

export interface ModalLocationState {
  background: Location;
}

export interface ModalLocation {
  path: Location;
  state: ModalLocationState;
}

const defaultBackgroundLocation: Location = { pathname: routeCreators.bookings() };
const intermodalBackgroundLocation: Location = { pathname: routeCreators.intermodal() };

export const modalRouteCreators = {
  bookingDetails: (
    {
      bookingNo,
      editState,
    }: RouteParamsWithBookingNo & {
      editState: string;
    },
    background: Location = defaultBackgroundLocation,
  ): ModalLocation => ({
    path: {
      pathname: `/bookings/${bookingNo}/${editState}`,
    },
    state: { background },
  }),
  bookingSwitch: (
    { bookingNo }: RouteParamsWithBookingNo,
    background: Location = defaultBackgroundLocation,
  ): ModalLocation => ({
    path: { pathname: `/bookings/${bookingNo}/switch` },
    state: { background },
  }),
  bookingCancel: (
    { bookingNo }: RouteParamsWithBookingNo,
    background: Location = defaultBackgroundLocation,
  ): ModalLocation => ({
    path: { pathname: `/bookings/${bookingNo}/cancel` },
    state: { background },
  }),
  intermodalDetails: (
    { bookingNo }: RouteParamsWithBookingNo & {},
    background: Location = intermodalBackgroundLocation,
  ): ModalLocation => ({
    path: {
      pathname: `/intermodal/${bookingNo}`,
    },
    state: { background },
  }),
  intermodalCreate: (
    _params = {},
    background: Location = { pathname: routeCreators.intermodal() },
  ): ModalLocation => ({
    path: {
      pathname: `/intermodal/create`,
    },
    state: { background },
  }),
};

export const getPayBookingPath = (bookingNos: number[]) =>
  routeCreators.payment() + '?bookingNo=' + bookingNos.join(',');

export const getModalRouteLinkProps = (modalLocation: ModalLocation): LinkProps => ({
  to: modalLocation.path,
  state: modalLocation.state,
});

export const modalRouteLocations = {
  bookingSwitch: modalRouteCreators.bookingSwitch({ bookingNo: ':bookingNo' }),
  bookingCancel: modalRouteCreators.bookingCancel({ bookingNo: ':bookingNo' }),
  intermodalCreate: modalRouteCreators.intermodalCreate(),
  intermodalDetails: modalRouteCreators.intermodalDetails({ bookingNo: ':bookingNo' }),
  bookingDetailsEdit: modalRouteCreators.bookingDetails({
    bookingNo: ':bookingNo',
    editState: 'edit',
  }),
  bookingDetailsView: modalRouteCreators.bookingDetails({
    bookingNo: ':bookingNo',
    editState: 'view',
  }),
};

export const appRoutes = {
  ...mapValues(modalRouteLocations, route => route.path.pathname),
  bookings: routeCreators.bookings() + '/*',
  callback: routeCreators.callback(),
  claimsPortal: routeCreators.claimsPortal(),
  bookingClaims: routeCreators.bookingClaims({ bookingNo: ':bookingNo' }),
  financialDocuments: routeCreators.financialDocuments() + '/*',
  help: routeCreators.help(),
  intermodal: routeCreators.intermodal() + '/*',
  intermodalCreate: modalRouteCreators.intermodalCreate().path.pathname,
  create: routeCreators.create(),
  settings: routeCreators.settings() + '/*',
  settingsPassword: routeCreators.settingsPassword(),
  settingsFilters: routeCreators.settingsFilters(),
  settingsFavourites: routeCreators.settingsFavourites(),
  settingsDateFormat: routeCreators.settingsDateFormat(),
  settingsCookies: routeCreators.settingsCookies(),
  userSubscriptions: routeCreators.userSubscriptions(),
  payment: routeCreators.payment(),
  timetable: routeCreators.timetable(),
  hazardousGoods: routeCreators.hazardousGoods({ bookingNo: ':bookingNo' }),
  customsDocumentation: routeCreators.customsDocumentation({
    bookingNo: ':bookingNo',
    sailingId: ':sailingId',
    userId: ':userId',
    route: ':route',
  }),
  statistics: routeCreators.statistics(),
  noShowLateHandling: routeCreators.noShowLateHandling(),
};

export type AppRoutes = typeof appRoutes;
export type ModalRouteLocations = typeof modalRouteLocations;
