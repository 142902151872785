import { Column, Row, Txt } from '@stenajs-webui/core';
import { FC } from 'react';
import { BasicBooking } from '../../../common/graphql/fragments/gql/BasicBooking';
import { DetailedIntermodalBooking } from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { Sailing } from '../../../common/graphql/fragments/gql/Sailing';
import { TrackerCategory } from '../../../common/tracking/trackerService';
import { IntermodalJourneyTxt } from './IntermodalJourneyTxt';
import { IntermodalLink } from './IntermodalLink';
import { SailingLink } from './SailingLink';
import { ShowDetailsLink } from './ShowDetailsLink';

interface BookingToastBodyProps {
  sailing: Sailing | null;
  bookings?: BasicBooking[];
  intermodalBooking?: DetailedIntermodalBooking;
  messages?: string[];
  warnings?: string[];
  errors?: string[];
  switchResult?: {
    confirmedBookingNo: number;
    waitlistedBookingNo: number;
  };
  trackerCategory: TrackerCategory;
  onAfterClick?: () => void;
}

const SwitchResultRow: FC<{
  status: string;
  bookingNo: number;
  trackerCategory: TrackerCategory;
  onAfterClick?: () => void;
}> = ({ status, bookingNo, trackerCategory, onAfterClick }) => {
  return (
    <Txt>
      Booking{' '}
      <ShowDetailsLink
        bookingNo={bookingNo}
        trackerCategory={trackerCategory}
        trackerAction={'Show booking details (switch result)'}
        onAfterShowDetails={onAfterClick}
      />{' '}
      is now {status}.
    </Txt>
  );
};

export const BookingToastBody: FC<BookingToastBodyProps> = ({
  sailing,
  bookings = [],
  messages = [],
  warnings = [],
  errors = [],
  switchResult,
  trackerCategory,
  onAfterClick,
  intermodalBooking,
}) => {
  const allMessages = [...messages, ...warnings, ...errors];

  return (
    <Column gap={2}>
      {sailing && (
        <Row>
          <SailingLink
            sailing={sailing}
            onAfterShowDeparture={onAfterClick}
            trackerCategory={trackerCategory}
          />
        </Row>
      )}
      {allMessages.length > 1 ? (
        <Column spacing>
          <ul style={{ margin: 0, paddingInlineStart: 'calc(2 * var(--swui-metrics-indent))' }}>
            {allMessages.map((message, index) => (
              <li key={index}>
                <Txt size={'small'}>{message}</Txt>
              </li>
            ))}
          </ul>
        </Column>
      ) : (
        allMessages.length === 1 && <Txt size={'small'}>{allMessages[0]}</Txt>
      )}
      {intermodalBooking && (
        <Column gap={2}>
          <IntermodalJourneyTxt booking={intermodalBooking} />
          <Row gap>
            <IntermodalLink
              booking={intermodalBooking}
              onAfterClick={onAfterClick}
              trackerCategory={trackerCategory}
            />
            <Txt>{intermodalBooking.vehicleReg || intermodalBooking.iluCode || ''}</Txt>
          </Row>
        </Column>
      )}
      {bookings.length > 0 && (
        <Column gap={0.5}>
          {bookings.map(booking => (
            <Row key={booking.bookingNo} gap>
              <ShowDetailsLink
                bookingNo={booking.bookingNo}
                trackerCategory={trackerCategory}
                trackerAction={'Show booking details'}
                onAfterShowDetails={onAfterClick}
              />
              <Txt>{booking.vehicleRegNo || booking.trailerRegNo || ''}</Txt>
            </Row>
          ))}
        </Column>
      )}
      {switchResult && (
        <>
          <Row>
            <SwitchResultRow
              status={'Confirmed'}
              trackerCategory={trackerCategory}
              bookingNo={switchResult.waitlistedBookingNo}
              onAfterClick={onAfterClick}
            />
          </Row>
          <Row>
            <SwitchResultRow
              status={'Waitlisted'}
              trackerCategory={trackerCategory}
              bookingNo={switchResult.confirmedBookingNo}
              onAfterClick={onAfterClick}
            />
          </Row>
        </>
      )}
    </Column>
  );
};
