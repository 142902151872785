import { FC } from 'react';
import { SpecialProperty } from '../gql/graphql';
import { resolveBookingSpecialPropertyIconIconAndDescription } from '../use-cases/manage/grid/utils/bookingSpecialProperties';
import { IconWithDescription } from './IconWithDescription';

interface IconProps {
  specialProperties: SpecialProperty[];
}

export const BookingSpecialPropertyIcon: FC<IconProps> = ({ specialProperties }) => {
  const iconInfo = resolveBookingSpecialPropertyIconIconAndDescription(specialProperties);
  return <IconWithDescription iconInfo={iconInfo} />;
};
