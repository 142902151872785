import { useMemo } from 'react';
import { useLocalDateFormat } from '../../../../common/hooks/useLocalDateFormat';
import { getSearchColumnsWidth, getSearchResultColumns } from '../utils/searchResultColumns';

export const useSearchColumns = () => {
  const dateFormat = useLocalDateFormat();

  return useMemo(() => {
    const searchColumns = getSearchResultColumns(dateFormat);
    const width = getSearchColumnsWidth(searchColumns);

    return { searchColumns, width };
  }, [dateFormat]);
};
