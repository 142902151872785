import { UpdateResult } from '../../../services/update-form/updateBookingReducer';

export enum UPDATE_SHOW {
  NONE,
  SUCCESS,
  INFORMATION,
  ERRORS,
  WAITLIST,
  BOOKING_OUT_OF_DATE,
  WARNING,
}

export interface UpdateInformationMessage {
  description: string;
  code?: string;
}

export interface UpdateSubmitInformation {
  bookingOutOfDate: boolean;
  messages: UpdateInformationMessage[];
  success: boolean;
  status: UPDATE_SHOW;
}

const NO_MESSAGES: UpdateSubmitInformation = {
  bookingOutOfDate: false,
  messages: [],
  status: UPDATE_SHOW.NONE,
  success: false,
};

const SUCCESS_MESSAGES: UpdateSubmitInformation = {
  bookingOutOfDate: false,
  messages: [],
  status: UPDATE_SHOW.SUCCESS,
  success: true,
};

export const getUpdateSubmitInformation = (result: UpdateResult): UpdateSubmitInformation => {
  if (result.genericError) {
    return {
      bookingOutOfDate: false,
      messages: [{ description: result.genericError }],
      status: UPDATE_SHOW.ERRORS,
      success: false,
    };
  }
  if (result.bookingOutOfDate) {
    return {
      bookingOutOfDate: true,
      messages: [
        {
          description:
            'Conflicting booking details were detected during update. The most recently saved changes have been loaded',
        },
      ],
      status: UPDATE_SHOW.BOOKING_OUT_OF_DATE,
      success: false,
    };
  }
  if (result.result) {
    if (result.result.waitlistPossible) {
      return {
        bookingOutOfDate: false,
        messages: [...result.result.warnings, ...result.result.messages],
        status: UPDATE_SHOW.WAITLIST,
        success: true,
      };
    }

    if (!result.result.success) {
      return {
        bookingOutOfDate: false,
        messages: result.result.errors,
        status: UPDATE_SHOW.ERRORS,
        success: false,
      };
    } else {
      if (result.result.messages.length > 0 || result.result.warnings.length > 0) {
        return {
          bookingOutOfDate: false,
          messages: [...result.result.warnings, ...result.result.messages],
          status: result.result.warnings.length > 0 ? UPDATE_SHOW.WARNING : UPDATE_SHOW.INFORMATION,
          success: true,
        };
      }
      return SUCCESS_MESSAGES;
    }
  }
  return NO_MESSAGES;
};
