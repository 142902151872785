import { first, map } from 'lodash';
import { matchRoutes } from 'react-router-dom';
import { browserHistory } from '../../store/utils/history';
import { ModalRouteLocations, modalRouteLocations } from './AppRoutes';

export const tryModalAfterAuth = (targetPathname: string) => {
  const r = matchRoutes(
    map(modalRouteLocations, (route, key) => ({ path: route.path.pathname, id: key })),
    targetPathname,
  );

  const match = first(r);

  if (match != null) {
    const appRouteKey = match.route.id as keyof ModalRouteLocations;
    const modalRoute = modalRouteLocations[appRouteKey];

    browserHistory.replace(modalRoute.state.background);
    browserHistory.push(targetPathname, modalRoute.state);
  } else {
    browserHistory.replace(targetPathname);
  }
};
