import {
  MediumIcon,
  stenaCheckCircle,
  stenaExclamationTriangle,
  stenaInfoCircle,
} from '@stenajs-webui/elements';
import { InformationMessageType } from '../../../common-ui/information-message/informationMessageUtils';
import { BasicBooking } from '../../../common/graphql/fragments/gql/BasicBooking';
import { DetailedIntermodalBooking } from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { Sailing } from '../../../common/graphql/fragments/gql/Sailing';
import { exhaustSwitchCaseElseThrow } from '../../../common/utils/ExhaustSwitchCase';
import { pluralize, pluralizeBooking } from '../../../common/utils/pluralization';
import { BookingResult, BookingType } from '../../../services/notifications/notificationsActions';
import { CssPropColor } from '@stenajs-webui/theme';

interface BookingResultParams {
  sailing: Sailing | null;
  title: string;
  icon: MediumIcon;
  bookings?: BasicBooking[];
  intermodalBooking?: DetailedIntermodalBooking;
  messages?: string[];
  warnings?: string[];
  errors?: string[];
  switchResult?: {
    confirmedBookingNo: number;
    waitlistedBookingNo: number;
  };
  iconBackgroundColor: CssPropColor;
}

export const getToastIconProps = (
  type: InformationMessageType,
): Pick<BookingResultParams, 'icon'> => {
  switch (type) {
    case InformationMessageType.Confirmed:
      return {
        icon: stenaCheckCircle,
      };
    case InformationMessageType.HardWarning:
      return {
        icon: stenaExclamationTriangle,
      };
    case InformationMessageType.SoftWarning:
      return {
        icon: stenaExclamationTriangle,
      };
    case InformationMessageType.RegularInfo:
      return {
        icon: stenaInfoCircle,
      };
    default:
      return {
        icon: stenaCheckCircle,
      };
  }
};

export const getBookingResultParams = (result: BookingResult): BookingResultParams => {
  switch (result.type) {
    case 'waitlist': {
      const count = result.bookings.length;
      return {
        ...getToastIconProps(InformationMessageType.SoftWarning),
        title: `${count} ${pluralizeBooking(count)} on waitlist`,
        messages: result.messages,
        warnings: result.warnings,
        bookings: result.bookings,
        sailing: result.sailing,
        iconBackgroundColor: '--lhds-color-orange-100',
      };
    }
    case 'confirmed': {
      const count = result.bookings.length;
      return {
        ...getToastIconProps(InformationMessageType.Confirmed),
        title: `${count} ${pluralizeBooking(count)} confirmed`,
        messages: result.messages,
        warnings: result.warnings,
        bookings: result.bookings,
        sailing: result.sailing,
        iconBackgroundColor: '--lhds-color-green-100',
      };
    }
    case 'create-fail': {
      const count = result.count;
      return {
        ...getToastIconProps(InformationMessageType.HardWarning),
        title: `${count} ${pluralizeBooking(count)} ${pluralize(count, 'was', 'were')} not added`,
        warnings: result.warnings,
        errors: result.errors,
        sailing: result.sailing,
        iconBackgroundColor: '--lhds-color-red-100',
      };
    }
    case 'cancel-fail': {
      return {
        ...getToastIconProps(InformationMessageType.HardWarning),
        title: getCancelFailTitle(result.bookingNo, result.bookingType),
        errors: result.errors,
        sailing: null,
        iconBackgroundColor: '--lhds-color-red-100',
      };
    }
    case 'cancel-success': {
      return {
        ...getToastIconProps(InformationMessageType.Confirmed),
        title: getCancelSuccessTitle(result.bookingNo, result.bookingType),
        messages: result.messages,
        warnings: result.warnings,
        sailing: null,
        iconBackgroundColor: '--lhds-color-ui-200',
      };
    }
    case 'switch-success': {
      return {
        ...getToastIconProps(InformationMessageType.Confirmed),
        title: 'Switch successful',
        messages: result.messages,
        warnings: result.warnings,
        sailing: result.sailing,
        switchResult: {
          confirmedBookingNo: result.confirmedBookingNo,
          waitlistedBookingNo: result.waitlistedBookingNo,
        },
        iconBackgroundColor: '--lhds-color-green-100',
      };
    }
    case 'switch-fail': {
      return {
        ...getToastIconProps(InformationMessageType.HardWarning),
        title: 'Switch failed',
        sailing: null,
        errors: result.errors,
        iconBackgroundColor: '--lhds-color-red-100',
      };
    }
    case 'intermodal-created': {
      return {
        ...getToastIconProps(InformationMessageType.Confirmed),
        title: `Booking request created`,
        messages: result.messages,
        warnings: result.warnings,
        sailing: null,
        intermodalBooking: result.booking,
        iconBackgroundColor: '--lhds-color-green-100',
      };
    }
    default:
      return exhaustSwitchCaseElseThrow(result);
  }
};

function getCancelSuccessTitle(bookingNo: number, type: BookingType) {
  if (type === 'intermodalBooking') {
    return `Intermodal booking ${bookingNo} cancelled`;
  }
  return `Booking ${bookingNo} cancelled`;
}

function getCancelFailTitle(bookingNo: number, type: BookingType) {
  if (type === 'intermodalBooking') {
    return `Failed to cancel intermodal booking ${bookingNo}`;
  }
  return `Failed to cancel booking ${bookingNo}`;
}
