import {
  CommonDropdownField,
  CommonDropdownFieldProps,
} from '../../../../common-ui/form-fields/CommonDropdownField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import {
  GoodsTypesQueryRefProps,
  useGoodsTypes,
} from '../../../../services/intermodal/hooks/useGoodsTypes';
import { UpdateIntermodalBookingGoodFormModel } from '../../../../services/intermodal/updateIntermodalReducer';
import { hasDropdownValueChanged } from '../../../multiple-create/hooks/useDropdownField';
import { useUpdateIntermodalGoodsField } from '../../hooks/useUpdateIntermodalGoodsFields';
import { transformGoodsTypeOption } from '../../utils/detailsUtils';

export interface GoodsTypeDropdownFieldProps
  extends Omit<
      CommonDropdownFieldProps<UpdateIntermodalBookingGoodFormModel['goodsType']>,
      'onValueChange' | 'value' | 'trackerAction' | 'options' | 'transformOption' | 'isLoading'
    >,
    GoodsTypesQueryRefProps {
  goodsId: string;
}

export const UpdateGoodsTypeDropdown = ({
  goodsId,
  goodsTypesQueryRef,
  ...props
}: GoodsTypeDropdownFieldProps) => {
  const { goodsTypes } = useGoodsTypes(goodsTypesQueryRef);
  const fieldProps = useUpdateIntermodalGoodsField(goodsId, 'goodsType');

  return (
    <CommonDropdownField
      {...props}
      {...getFieldProps(props, fieldProps)}
      transformOption={transformGoodsTypeOption}
      options={goodsTypes.map(transformGoodsTypeOption)}
      changed={hasDropdownValueChanged(fieldProps, transformGoodsTypeOption)}
    />
  );
};
