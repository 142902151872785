import { Column, Row, Space, Text } from '@stenajs-webui/core';
import { FlatButton, PrimaryButton } from '@stenajs-webui/elements';
import { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import { markUrgentMessageAsUnread } from '../../../services/notifications/notificationsActions';
import { UrgentMessage } from '../../graphql/fragments/gql/UrgentMessage';
import { TrackerCategory, trackEvent } from '../../tracking/trackerService';
import { ModalHeader } from '../../../use-cases/manage/details/components/modal/ModalHeader';

interface UrgentMessageModalProps {
  onClose: () => void;
  urgentMessage: UrgentMessage;
  trackerCategory: TrackerCategory;
  trackerAction: string;
}

export const UrgentMessageModal: FC<UrgentMessageModalProps> = ({
  onClose,
  urgentMessage: { text, header, id, routes },
  trackerAction,
  trackerCategory,
}) => {
  const dispatch = useDispatch();

  const markAsUnread = () => {
    trackEvent({
      category: trackerCategory,
      action: trackerAction,
      label: 'Remind me button',
    });

    batch(() => {
      dispatch(markUrgentMessageAsUnread(id));
      onClose();
    });
  };

  const onCloseWithTracker = (label: string) => () => {
    trackEvent({
      category: trackerCategory,
      action: trackerAction,
      label,
    });

    onClose();
  };

  return (
    <Column width={500} maxWidth={'100%'}>
      <ModalHeader onRequestClose={onCloseWithTracker('Close message')} headerText={header} />
      <Column indent={3} spacing={3}>
        {routes.length > 0 && (
          <>
            <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
              {routes.map(r => (
                <li key={r.code}>
                  <Text variant={'overline'} size={'small'}>
                    {r.longDescription}
                  </Text>
                </li>
              ))}
            </ul>
            <Space num={2} />
          </>
        )}
        <Text whiteSpace={'pre-line'} style={{ overflowWrap: 'break-word' }}>
          {text}
        </Text>
        <Space num={2} />
        <Row justifyContent={'flex-end'} alignItems={'center'}>
          <FlatButton label={'Remind me'} onClick={markAsUnread} />
          <Space />
          <PrimaryButton
            label={'Ok, got it'}
            onClick={onCloseWithTracker("Don't remind me button")}
          />
        </Row>
      </Column>
    </Column>
  );
};
