import { useSelector } from 'react-redux';
import { AccountQuery_viewer_account_byEmail } from '../../../use-cases/settings/graphql/gql/AccountQuery';
import { defaultSettings } from '../../../use-cases/settings/utils/defaultSettings';
import { getAccount } from '../settingsReducer';

export const useAccount = (): AccountQuery_viewer_account_byEmail => {
  const account = useSelector(getAccount);

  return account ?? defaultSettings;
};

export const useUserLoginEmail = () => useAccount().accountSettings.accountLoginEmail;
