import { GraphQLError } from 'graphql';
import {
  BookingsFilter,
  BookingsFilterQueryQueryVariables,
  CreateBookingInput,
  FilterDate,
  IntermodalJourneyFilterInput,
  UpdateBookingInput,
  UpdatePageSettings2Input,
  UpdatePageSettingsMutationMutation,
  UpdatePrivacySettingsInput,
  UpdateSettingsInput,
  UserSubscriptionsInput,
} from '../gql/graphql';
import { client } from '../services/apollo/apollo';
import { bookingsFilterQuery } from '../services/grid/graphql/bookingsFilterQuery';
import { BookingsFilterQuery_viewer_bookings_byFilter } from '../services/grid/graphql/gql/BookingsFilterQuery';
import { bookingQuery } from '../use-cases/manage/details/graphql/bookingQuery';
import { UpdateBooking_updateBooking } from '../use-cases/manage/details/graphql/gql/UpdateBooking';
import { updateBookingMutation } from '../use-cases/manage/details/graphql/updateBookingMutation';
import {
  Animal,
  DropdownOption,
  SenderReceiverCountry,
  transformAnimalOption,
} from '../use-cases/manage/grid/utils/optionTransformers';

import { createBookingsMutation } from '../use-cases/multiple-create/graphql/createBookingsMutation';
import { CreateBookings_createBookings_results } from '../use-cases/multiple-create/graphql/gql/CreateBookings';
import { accountQuery } from '../use-cases/settings/graphql/accountQuery';
import { AccountQuery_viewer_account_byEmail } from '../use-cases/settings/graphql/gql/AccountQuery';
import { UpdatePrivacySettings_updatePrivacySettings } from '../use-cases/settings/graphql/gql/UpdatePrivacySettings';
import { UpdateSettings_updateSettings } from '../use-cases/settings/graphql/gql/UpdateSettings';
import { updatePrivacySettingsMutation } from '../use-cases/settings/graphql/updatePrivacySettingsMutation';
import { updateSettingsMutation } from '../use-cases/settings/graphql/updateSettingsMutation';
import { defaultSettings } from '../use-cases/settings/utils/defaultSettings';
import { mergeSettings } from '../use-cases/settings/utils/settingsMerger';
import { UserSubscriptionsMutation_updateSubscriptionSettings } from '../use-cases/user-subscriptions/graphql/gql/UserSubscriptionsMutation';
import { UserSubscriptionsQuery_viewer_account_byEmail_userSubscriptions } from '../use-cases/user-subscriptions/graphql/gql/UserSubscriptionsQuery';
import { userSubscriptionsMutation } from '../use-cases/user-subscriptions/graphql/userSubscriptionsMutation';
import { userSubscriptionsQuery } from '../use-cases/user-subscriptions/graphql/userSubscriptionsQuery';
import { createBookingClaimTokenMutation } from './claims/createBookingClaimTokenMutation';
import { createClaimsPortalTokenMutation } from './claims/createClaimsPortalTokenMutation';
import { CreateBookingClaimToken_createBookingClaimToken } from './claims/gql/CreateBookingClaimToken';
import { CreateClaimsPortalToken_createClaimsPortalToken } from './claims/gql/CreateClaimsPortalToken';
import { createCustomsLinkUserTokenMutation } from './customslink/createCustomsLinkUserTokenMutation';
import { CreateCustomsLinkUserToken_createCustomsLinkUserToken } from './customslink/gql/CreateCustomsLinkUserToken';
import { allRoutesQuery } from './graphql/allRoutesQuery';
import { allVehicleTypesForCustomerAndRouteQuery } from './graphql/allVehicleTypesForCustomerAndRouteQuery';
import { arrivalNotepadQuery } from './graphql/arrivalNotepadQuery';
import { ArrivalNotepad } from './graphql/fragments/gql/ArrivalNotepad';
import { BasicBooking } from './graphql/fragments/gql/BasicBooking';
import { Route } from './graphql/fragments/gql/Route';
import { Sailing } from './graphql/fragments/gql/Sailing';
import { TravelledVehicle } from './graphql/fragments/gql/TravelledVehicle';
import { UrgentMessage } from './graphql/fragments/gql/UrgentMessage';
import { VehicleType } from './graphql/fragments/gql/VehicleType';
import { sailingsByFilterQuery } from './graphql/sailingsByFilterQuery';
import { switchQuery } from './graphql/switchBookingQuery';
import { travelledVehicleQuery } from './graphql/travelledVehicleQuery';
import { updatePageSettingsMutation } from './graphql/updatePageSettingsQuery';
import { allUrgentMessagesQuery } from './graphql/urgentMessagesQuery';
import { animalTypesQuery } from './graphql/useAnimalTypesOptions';
import { senderReceiverCountryQuery } from './graphql/useSenderReceiverCountryOptions';
import { vehicleTypeByCustomerNoAndRouteCodeQuery } from './graphql/vehicleTypeByCustomerNoAndRouteCodeQuery';
import { pingLastLoginMutation } from './ping-login/pingLogin';
import {
  IntermodalJourneyResult,
  IntermodalVehicleType,
} from './graphql/fragments/gql/IntermodalBooking';
import { allIntermodalVehicleTypesQuery } from '../services/intermodal/graphql/allIntermodalVehicleTypesQuery';
import { intermodalJourneyQuery } from '../services/intermodal/graphql/intermodalJourneyQuery';

export async function fetchUrgentMessage(dateRange: FilterDate): Promise<UrgentMessage[] | null> {
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: allUrgentMessagesQuery,
    variables: { dateRange },
  });
  return data?.viewer?.urgentMessages.byDateRange.messages ?? null;
}

export async function fetchVehicleType(
  customerNo: number,
  routeCode: string,
  vehicleTypeCode: string,
): Promise<VehicleType | null> {
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: vehicleTypeByCustomerNoAndRouteCodeQuery,
    variables: { customerNo, routeCode, vehicleTypeCode },
  });
  return data?.viewer?.vehicleType?.byCodeAndCustomerAndRoute ?? null;
}

function shouldIncludeAllRoutes(filter: BookingsFilter | null | undefined) {
  return filter?.routeCodes?.length === 0 && filter?.query === '';
}

export async function fetchBookings({
  filter,
  sort,
  offset,
  first,
}: BookingsFilterQueryQueryVariables): Promise<BookingsFilterQuery_viewer_bookings_byFilter | null> {
  const routeCodes = shouldIncludeAllRoutes(filter)
    ? (await fetchAllRoutesCached()).map(r => r.id)
    : filter?.routeCodes;

  const { data } = await client.query({
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
    query: bookingsFilterQuery,
    variables: { filter: { ...filter, routeCodes }, sort, offset, first },
  });

  return data?.viewer?.bookings?.byFilter ?? null;
}

export type Result<T, E> = { success: true; value: T } | { success: false; error: E };

export async function fetchBooking(
  bookingNo: number,
): Promise<Result<BasicBooking | null, ReadonlyArray<GraphQLError>>> {
  const { data, errors } = await client.query({
    fetchPolicy: 'network-only',
    query: bookingQuery,
    variables: { bookingNo },
  });

  if (errors) {
    return {
      success: false,
      error: errors,
    };
  }

  return {
    success: true,
    value: data?.viewer.bookings.booking ?? null,
  };
}

export interface UpdateBookingResult {
  result?: UpdateBooking_updateBooking;
  errors: string[];
}

export async function updateBooking(
  booking: UpdateBookingInput,
  detailed: boolean,
): Promise<UpdateBookingResult> {
  try {
    const { data, errors } = await client.mutate({
      mutation: updateBookingMutation,
      refetchQueries: ['DashboardQuery', 'BookingsFilterQuery', 'PaymentDetailsQuery'],
      variables: { booking, detailed },
    });

    return {
      errors: errors?.map(error => error.message) ?? [],
      result: data?.updateBooking ?? undefined,
    };
  } catch (e) {
    return {
      errors: ['Something went wrong, try again'],
    };
  }
}

export interface CreateBookingsResult {
  results?: CreateBookings_createBookings_results[];
  errors: string[];
}

export async function createBookings(
  bookings: CreateBookingInput[],
): Promise<CreateBookingsResult> {
  try {
    const { data, errors } = await client.mutate({
      mutation: createBookingsMutation,
      refetchQueries: ['DashboardQuery', 'BookingsFilterQuery'],
      variables: { bookings },
    });

    return {
      errors: errors?.map(error => error.message) ?? [],
      results: data?.createBookings.results ?? undefined,
    };
  } catch (e) {
    return {
      errors: ['Something went wrong, try again'],
    };
  }
}

export async function fetchAccount(): Promise<AccountQuery_viewer_account_byEmail | null> {
  const { data } = await client.query({
    fetchPolicy: 'network-only',
    query: accountQuery,
  });
  return data?.viewer?.account?.byEmail
    ? mergeSettings(data.viewer.account.byEmail, defaultSettings)
    : null;
}

export async function fetchVehicleTypeOptionsCached(
  customerNo: number,
  routeCode: string,
): Promise<VehicleType[]> {
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: allVehicleTypesForCustomerAndRouteQuery,
    variables: { customerNo, routeCode },
  });

  return data?.viewer?.vehicleType?.allForCustomerNoAndRouteCode?.vehicleTypes ?? [];
}

export async function fetchAllIntermodalVehicleTypes(): Promise<IntermodalVehicleType[]> {
  const { data } = await client.query({ query: allIntermodalVehicleTypesQuery });
  return data.viewer.intermodalVehicleType.all.vehicleTypes;
}

export async function fetchSuggestedJourney(
  filter: IntermodalJourneyFilterInput,
): Promise<IntermodalJourneyResult | null> {
  const { data } = await client.query({
    query: intermodalJourneyQuery,
    variables: { filter },
    fetchPolicy: 'network-only',
  });
  return data.viewer.intermodalJourney.byFilter;
}

export async function fetchAllRoutesCached(): Promise<Route[]> {
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: allRoutesQuery,
  });

  return data?.viewer?.routes?.allRoutes ?? [];
}

export async function fetchAnimalOptions(): Promise<DropdownOption<Animal>[]> {
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: animalTypesQuery,
  });

  return data?.viewer?.animalTypes?.items?.map(transformAnimalOption) ?? [];
}

export async function fetchSwitchCandidates(bookingNo: number) {
  const { data } = await client.query({
    query: switchQuery,
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
    variables: { bookingNo },
  });

  return {
    booking: data?.viewer.bookings.byBookingNo,
    switchCandidates: data?.viewer.bookings.forSwitch.bookings,
  };
}

export async function fetchSailings(routeCode: string, departureDate: string): Promise<Sailing[]> {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: sailingsByFilterQuery,
    variables: {
      filter: {
        date: {
          from: departureDate,
          to: departureDate,
        },
        routeCode,
      },
    },
  });

  return data?.viewer?.sailings?.byFilter?.items ?? [];
}

export async function fetchTravelledVehicle(
  customerNo: number,
  registrationNumber: string,
): Promise<TravelledVehicle | null> {
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: travelledVehicleQuery,
    variables: {
      customerNo,
      registrationNumber,
    },
  });

  return data?.viewer?.travelledVehicle?.travelledVehicle?.travelledVehicle ?? null;
}

export const fetchClaimsPortalToken =
  async (): Promise<CreateClaimsPortalToken_createClaimsPortalToken> => {
    const { data } = await client.mutate({
      mutation: createClaimsPortalTokenMutation,
    });

    return data?.createClaimsPortalToken ?? { success: false, token: null, service: null };
  };

export const fetchBookingClaimToken = async (
  bookingNo: number,
): Promise<CreateBookingClaimToken_createBookingClaimToken> => {
  const { data } = await client.mutate({
    mutation: createBookingClaimTokenMutation,
    variables: { bookingNo },
  });

  return (
    data?.createBookingClaimToken ?? { success: false, token: null, params: null, service: null }
  );
};

export const fetchCustomsLinkUserToken = async (
  bookingNo: number,
): Promise<CreateCustomsLinkUserToken_createCustomsLinkUserToken> => {
  const { data } = await client.mutate({
    mutation: createCustomsLinkUserTokenMutation,
    variables: { bookingNo },
  });

  return data?.createCustomsLinkUserToken ?? { success: false, redirectUrl: null };
};

export const updatePrivacySettings = async (
  settings: UpdatePrivacySettingsInput,
): Promise<UpdatePrivacySettings_updatePrivacySettings> => {
  const { data } = await client.mutate({
    mutation: updatePrivacySettingsMutation,
    variables: { settings },
  });

  return (
    data?.updatePrivacySettings ?? {
      success: false,
      settings: defaultSettings.privacySettings,
      errors: [],
    }
  );
};

export const updateSettings = async (
  settings: UpdateSettingsInput,
): Promise<UpdateSettings_updateSettings> => {
  const { data } = await client.mutate({
    mutation: updateSettingsMutation,
    variables: { settings },
  });

  return data?.updateSettings ?? { settings: null, success: false, errors: [], validation: null };
};

export const updatePageSettings2 = async (
  settings: UpdatePageSettings2Input,
): Promise<NonNullable<UpdatePageSettingsMutationMutation['updatePageSettings2']>> => {
  const { data } = await client.mutate({
    mutation: updatePageSettingsMutation,
    variables: { settings },
  });

  return data?.updatePageSettings2 ?? { success: false, settings: null };
};

export const updateUserSubscriptions = async (
  userSubscriptions: UserSubscriptionsInput,
): Promise<UserSubscriptionsMutation_updateSubscriptionSettings> => {
  const { data } = await client.mutate({
    mutation: userSubscriptionsMutation,
    variables: { userSubscriptions },
  });

  return data?.updateSubscriptionSettings ?? { settings: null, success: false };
};

export const fetchUserSubscriptions =
  async (): Promise<UserSubscriptionsQuery_viewer_account_byEmail_userSubscriptions | null> => {
    const { data } = await client.query({
      query: userSubscriptionsQuery,
      fetchPolicy: 'network-only',
    });

    return data?.viewer.account.byEmail?.userSubscriptions ?? null;
  };

export const pingLastLogin = () =>
  client.mutate({
    mutation: pingLastLoginMutation,
  });

export async function fetchArrivalNotepad(
  customerNo: number,
  registrationNumber: string,
  routeCode: string,
): Promise<ArrivalNotepad | null> {
  const { data } = await client.query({
    fetchPolicy: 'network-only',
    query: arrivalNotepadQuery,
    variables: {
      customerNo,
      registrationNumber,
      routeCode,
    },
  });

  return data?.viewer?.arrivalNotepad?.arrivalnotepad?.arrivalNotepad ?? null;
}

export async function fetchSenderReceiverCountries(): Promise<SenderReceiverCountry[]> {
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: senderReceiverCountryQuery,
  });

  return data?.viewer?.senderReceiverCountries.items;
}
