import { Column } from '@stenajs-webui/core';
import { useAllIntermodalRoutesAsOptions } from '../../../../common/graphql/useAllIntermodalRoutesAsOptions';
import { IntermodalDetailsFieldCell } from '../../common/IntermodalDetailsFieldCell';
import {
  transformDepartureTimeOption,
  transformIntermodalRouteToDropdownOption,
} from '../../utils/detailsUtils';
import { timeOptions } from '../../../manage/details/components/sections/common';
import { IntermodalSearchOptionSwitch } from '../IntermodalSearchOptionSwitch';
import { CreateIntermodalDropdownField } from '../fields/CreateIntermodalDropdownField';
import { CreateIntermodalDateInputField } from '../fields/CreateIntermodalDateInputField';
import { CreateIntermodalValidationWrapper } from '../CreateIntermodalValidationWrapper';
import { formatServerDate } from '../../../../common/utils/dates/dateUtils';

export const CreateIntermodalDepartureSection = () => {
  const { intermodalRouteOptions } = useAllIntermodalRoutesAsOptions();
  const today = new Date();
  return (
    <>
      <CreateIntermodalValidationWrapper
        name={'route'}
        renderField={isValid => (
          <IntermodalDetailsFieldCell
            label={'Intermodal route'}
            className={'t_intermodal_route_field'}
            children={
              <CreateIntermodalDropdownField
                trackerCategory={'IntermodalCreate'}
                options={intermodalRouteOptions}
                name={'route'}
                transformOption={transformIntermodalRouteToDropdownOption}
                isValid={isValid}
              />
            }
          />
        )}
      />
      <IntermodalSearchOptionSwitch />
      <Column gap={2}>
        <CreateIntermodalValidationWrapper
          name={'departureDate'}
          renderField={isValid => (
            <IntermodalDetailsFieldCell
              label={'Date'}
              className={'t_intermodal_date_field'}
              children={
                <CreateIntermodalDateInputField
                  width={'100%'}
                  trackerCategory={'IntermodalCreate'}
                  name={'departureDate'}
                  isValid={isValid}
                  minDate={formatServerDate(today)}
                />
              }
            />
          )}
        />
        <CreateIntermodalValidationWrapper
          name={'departureTime'}
          renderField={isValid => (
            <IntermodalDetailsFieldCell
              label={'Time'}
              children={
                <CreateIntermodalDropdownField
                  trackerCategory={'IntermodalCreate'}
                  name={'departureTime'}
                  options={timeOptions}
                  transformOption={transformDepartureTimeOption}
                  isValid={isValid}
                />
              }
            />
          )}
        />
      </Column>
    </>
  );
};
