import { orderBy } from 'lodash';
import { BookingHistoryQuery_viewer_bookings_byBookingNo_history } from '../../../../common/graphql/gql/BookingHistoryQuery';
import { formatDateTime } from '../../../../common/utils/dates/dateUtils';
import { Column, HistoryRow, Ids } from '../components/HistoryTable';

export const getHistoryColumns = (dateTimeFormat: string): Array<Column<Ids>> => [
  {
    format: (value: string) => value,
    id: 'status',
    label: 'Status',
  },
  {
    format: (value: Date) => formatDateTime(value, dateTimeFormat),
    id: 'time',
    label: 'Time',
  },
  {
    format: (value: string) => value,
    id: 'by',
    label: 'By',
  },
];

export const transformHistoryRows = (
  history: BookingHistoryQuery_viewer_bookings_byBookingNo_history[],
): HistoryRow[] =>
  orderBy(history, ['versionNumber'], ['desc']).map(h => ({
    by: h.modifiedBy,
    status: h.status,
    time: new Date(h.dateModified),
  }));
