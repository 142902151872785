import { Box, Row, Txt } from '@stenajs-webui/core';
import { FlatButton, stenaInfoCircle, stenaSms } from '@stenajs-webui/elements';
import { Notification } from '@stenajs-webui/panels';
import { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import { trackEvent } from '../../../common/tracking/trackerService';
import { dotdotdot } from '../../../common/utils/stringUtil';
import { showUrgentMessage } from '../../../services/modal/modalActions';
import { markUrgentMessageAsRead } from '../../../services/notifications/notificationsActions';
import { UrgentMessageNotification as UrgentMessageNotificationType } from '../../../services/notifications/notificationsReducer';
import { formatToastRelativeTimestamp } from '../utils/formatToastRelativeTimestamp';

export interface UrgentMessageNotificationProps {
  messageIsRead: boolean;
  urgentMessageNotification: UrgentMessageNotificationType;
}

export const UrgentMessageNotification: FC<UrgentMessageNotificationProps> = ({
  messageIsRead,
  urgentMessageNotification,
}) => {
  const dispatch = useDispatch();

  const onFullMessageClickWithTracking = () => {
    const action = `Open urgent message (${messageIsRead ? 'read' : 'unread'})`;
    trackEvent({
      category: 'NotificationCenter',
      action,
    });

    batch(() => {
      const { message } = urgentMessageNotification;
      dispatch(markUrgentMessageAsRead(message.id));
      dispatch(showUrgentMessage(message, 'NotificationCenter', action));
    });
  };

  return (
    <Notification
      variant={'standard'} // TODO: info
      icon={stenaInfoCircle}
      text={dotdotdot(urgentMessageNotification.message.header, 100)}
      timestamp={formatToastRelativeTimestamp(urgentMessageNotification.createdAt)}
      unread={!messageIsRead}
    >
      <Box gap={2}>
        <Txt title={urgentMessageNotification.message.text}>
          {dotdotdot(urgentMessageNotification.message.text, 250)}
        </Txt>
        <Row alignSelf={'flex-end'}>
          <FlatButton
            label={'Full message'}
            leftIcon={stenaSms}
            onClick={onFullMessageClickWithTracking}
          />
        </Row>
      </Box>
    </Notification>
  );
};
