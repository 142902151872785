import { useMutation } from '@apollo/client';
import { useAppDispatch } from '../../../store/appDispatch';
import { updateIntermodalBookingMutation } from '../graphql/updateIntermodalBookingMutation';
import { updateIntermodalBookingThunk } from '../thunks/updateIntermodalBookingThunk';

export const useUpdateIntermodalBooking = (refetch: () => void) => {
  const dispatch = useAppDispatch();

  const [updateIntermodalBooking, { loading }] = useMutation(updateIntermodalBookingMutation);

  const submit = async () => {
    await dispatch(
      updateIntermodalBookingThunk({
        updateBooking: updateInput =>
          updateIntermodalBooking({ variables: { booking: updateInput } }),
        onOutdatedBooking: refetch,
      }),
    );
  };
  return { submit, loading };
};
