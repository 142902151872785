import { Box, BoxProps, Column, Row } from '@stenajs-webui/core';
import { FC, ReactNode } from 'react';
import { GoodsApproval } from '../../../../common/utils/GoodsApproval';
import { getApprovalInfo } from '../utils/approvalInfo';
import { ApproveAlert } from './ApproveAlert';
import { Label } from '@stenajs-webui/elements';

export interface DetailsFieldCellProps
  extends Pick<BoxProps, 'flex' | 'width' | 'maxWidth' | 'minWidth'> {
  approved?: GoodsApproval;
  label: string;
  field: ReactNode;
  // TODO better name is needed
  superficialLabel?: boolean;
}

export const DetailsFieldCell: FC<DetailsFieldCellProps> = ({
  approved,
  label,
  field,
  superficialLabel = false,
  ...props
}) => {
  const approvalInfo = approved ? getApprovalInfo(approved) : null;
  if (!approvalInfo) {
    return (
      <Column {...props} gap>
        {superficialLabel ? (
          <>
            <Label text={label} />
            {field}
          </>
        ) : (
          <Label text={label}>{field}</Label>
        )}
      </Column>
    );
  }

  return (
    <Label text={label}>
      <Row alignItems={'center'} gap>
        <Box flex={1}>{field}</Box>
        <Row style={{ height: 36 }} alignItems={'center'}>
          {approvalInfo && <ApproveAlert config={approvalInfo} />}
        </Row>
      </Row>
    </Label>
  );
};
