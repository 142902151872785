import cx from 'classnames';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  InformationMessage,
  InformationMessageType,
} from '../../../../common-ui/information-message/informationMessageUtils';
import { DetailedBooking } from '../../../../common/graphql/fragments/gql/DetailedBooking';
import { useShowBookingDetails } from '../../../../common/hooks/useShowBookingDetails';
import { freightPortalBaseModalProps } from '../../../../common/modal/FreightPortalBaseModal';
import { useHasPermission } from '../../../../common/statistics/useHasPermission';
import { PRINT_HIDE_CLASSNAME } from '../../../../globalStyles';
import { Permission } from '../../../../services/authentication/utils/authUtils';
import { revertChangesUpdateForm } from '../../../../services/update-form/updateFormActions';
import { isBookingEditable } from '../../../../services/update-form/utils/bookingUtils';
import { ModalFooterEdit } from '../footer/ModalFooterEdit';
import { ModalFooterView, shouldShowModalFooterView } from '../footer/ModalFooterView';
import { AutoCancellationInfoBanner } from './AutoCancellationInfoBanner';
import { BookingDetailsEdit } from './BookingDetailsEdit';
import { BookingDetailsView } from './BookingDetailsView';
import { renderBookingErrorModal } from './BookingErrorModal';
import { BookingModalTitleEdit } from './BookingModalTitleEdit';
import { BookingModalTitleView } from './BookingModalTitleView';
import { Modal } from './modal/Modal';
import { BookingDetailsTabView } from './tabs/BookingDetailsTabView';
import { BookingDetailsTabs } from './tabs/types';
import { useBookingLoader } from './useBookingLoader';
import { trackEvent } from '../../../../common/tracking/trackerService';

export interface BookingDetailsDeciderProps {
  bookingNo: number;
  isEditing: boolean;
  onRequestClose: () => void;
}

const useBookingDetailsModalNavigation = (bookingNo: number) => {
  const { replaceBookingDetailsModal } = useShowBookingDetails();

  return useMemo(
    () => ({
      startEditing: () => {
        replaceBookingDetailsModal(bookingNo, true);
      },
      stopEditing: () => {
        replaceBookingDetailsModal(bookingNo, false);
      },
    }),
    [replaceBookingDetailsModal, bookingNo],
  );
};

export const commonProps = {
  ...freightPortalBaseModalProps,
  isOpen: true,
  shouldCloseOnOverlayClick: true,
  draggable: true,
};

export const BookingDetailsDecider: FC<BookingDetailsDeciderProps> = ({
  bookingNo,
  isEditing,
  onRequestClose,
}) => {
  const { booking, loading, error } = useBookingLoader(bookingNo);
  const { startEditing, stopEditing } = useBookingDetailsModalNavigation(bookingNo);
  const getHasPermission = useHasPermission();
  const [detailsTab, setDetailsTab] = useState<BookingDetailsTabs>('details');

  const onChangeTab = useCallback((tab: BookingDetailsTabs) => {
    setDetailsTab(tab);

    trackEvent({ category: 'Details', action: 'Change tab', label: tab });
  }, []);

  const [statusInformationList, setList] = useState<InformationMessage[]>([]);

  const onCancelFail = useCallback(() => {
    setList([
      {
        message: `Failed to cancel booking (${bookingNo})`,
        type: InformationMessageType.HardWarning,
      },
    ]);
  }, [bookingNo, setList]);

  const dispatch = useDispatch();

  const onStartEditing = () => {
    dispatch(revertChangesUpdateForm({ formId: 'Details', bookingNo }));
    setList([]);
    startEditing();
  };

  const onStopEditing = () => {
    setList([]);
    stopEditing();
  };

  if (loading || error || !booking) {
    return renderBookingErrorModal(loading, error, onRequestClose);
  }

  const props: EditViewModalProps<DetailedBooking> = {
    bookingNo,
    booking,
    onCancelFail,
    onRequestClose,
    onStartEditing,
    onStopEditing,
    statusInformationList,
    detailsTab: detailsTab,
    setDetailsTab: onChangeTab,
  };

  const showEditModal =
    getHasPermission(Permission.EditBookings) && isEditing && isBookingEditable(booking);

  return showEditModal ? renderEditModal(props) : renderViewModal(props);
};

function renderEditModal({
  booking,
  onCancelFail,
  onRequestClose,
  bookingNo,
  onStopEditing,
  statusInformationList,
}: EditViewModalProps<DetailedBooking>): JSX.Element {
  return (
    <Modal
      {...commonProps}
      className={'t_booking_details_edit'}
      onRequestClose={onRequestClose}
      aria-label={`Edit booking ${bookingNo}`}
      closeButtonClassName={cx('t_close_booking_details_edit', PRINT_HIDE_CLASSNAME)}
      header={<BookingModalTitleEdit booking={booking} />}
      footer={<ModalFooterEdit booking={booking} onCancelFail={onCancelFail} />}
    >
      <BookingDetailsEdit
        bookingNo={bookingNo}
        onStopEdit={onStopEditing}
        statusInformationList={statusInformationList}
      >
        <AutoCancellationInfoBanner info={booking.autoCancellation} />
      </BookingDetailsEdit>
    </Modal>
  );
}

interface EditViewModalProps<TBooking> {
  bookingNo: number;
  booking: TBooking;
  onCancelFail: () => void;
  onRequestClose: () => void;
  onStartEditing: () => void;
  onStopEditing: () => void;
  statusInformationList: InformationMessage[];
  detailsTab: BookingDetailsTabs;
  setDetailsTab: (v: BookingDetailsTabs) => void;
}

function renderViewModal({
  booking,
  onCancelFail,
  onRequestClose,
  onStartEditing,
  statusInformationList,
  setDetailsTab,
  detailsTab,
}: EditViewModalProps<DetailedBooking>): ReactNode {
  const showViewModalFooter = shouldShowModalFooterView(booking);

  return (
    <Modal
      {...commonProps}
      className={'t_booking_details_view'}
      onRequestClose={onRequestClose}
      aria-label={`View booking ${booking.bookingNo}`}
      closeButtonClassName={cx('t_close_booking_details_view', PRINT_HIDE_CLASSNAME)}
      header={<BookingModalTitleView booking={booking} />}
      footer={
        showViewModalFooter &&
        detailsTab !== 'gate-photos' && (
          <ModalFooterView
            booking={booking}
            onCancelFail={onCancelFail}
            onStartEdit={onStartEditing}
          />
        )
      }
    >
      {booking.gatePhotos &&
      (booking.gatePhotos.inboundHasCapability || booking.gatePhotos.outboundHasCapability) ? (
        <BookingDetailsTabView
          gatePhotos={booking.gatePhotos}
          booking={booking}
          tab={detailsTab}
          setTab={setDetailsTab}
        >
          <BookingDetailsView booking={booking} statusInformationList={statusInformationList} />
        </BookingDetailsTabView>
      ) : (
        <BookingDetailsView booking={booking} statusInformationList={statusInformationList} />
      )}
    </Modal>
  );
}
