import { Popover, PopoverProps } from '@stenajs-webui/tooltip';
import { FC, ReactNode } from 'react';
import { getPortalRoot } from '../../use-cases/manage/grid/utils/getPortalRoot';
import { hideOnEsc } from './plugins';

const portalRoot = getPortalRoot();

export type Placement = PopoverProps['placement'];

interface FreightPortalPopoverProps extends Omit<PopoverProps, 'disablePadding' | 'children'> {
  onRequestClose: () => void;
  enablePadding?: boolean;
  children?: ReactNode;
}

export const FreightPortalPopover: FC<FreightPortalPopoverProps> = ({
  onRequestClose,
  visible,
  plugins = [],
  appendTo,
  enablePadding = false,
  children,
  ...props
}) => (
  <Popover
    arrow={false}
    {...props}
    children={children as any}
    plugins={[...plugins, hideOnEsc]}
    onHide={onRequestClose}
    onClickOutside={onRequestClose}
    visible={visible}
    appendTo={appendTo ?? portalRoot}
    disablePadding={!enablePadding}
  />
);
