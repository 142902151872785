class AssertionError extends Error {
  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, AssertionError.prototype);
    Object.defineProperty(this, 'name', { value: 'AssertionError' });
  }
}

export function assertIsDefined<T>(val: T | null | undefined, message: string): asserts val is T {
  if (val == null) {
    throw new AssertionError(message);
  }
}

export function assert(condition: any, message: string): asserts condition {
  if (!condition) {
    throw new AssertionError(message);
  }
}
