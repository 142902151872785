import { Column, Row, Txt } from '@stenajs-webui/core';
import { FC, MouseEvent } from 'react';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { BookingSearchTableRow } from './BookingSearchTableRow';
import { FlatButton } from '@stenajs-webui/elements';
import { SearchResultColumn } from '../utils/searchResultColumns';

export interface BookingSearchTableProps {
  onClickResult: (bookingNo: number) => void;
  linkClassName?: string;
  items: BasicBooking[];
  label: string;
  onTotalCountClick: (e: MouseEvent<HTMLElement>) => void;
  totalCount: number;
  searchQuery: string;
  searchColumns: SearchResultColumn[];
}

export const BookingSearchTable: FC<BookingSearchTableProps> = ({
  onClickResult,
  linkClassName,
  items,
  label,
  onTotalCountClick,
  totalCount,
  searchQuery,
  searchColumns,
}) => (
  <Column>
    <Row alignItems={'center'} indent={2} justifyContent={'space-between'} spacing>
      <Txt size={'small'} variant={'overline'}>
        {label}
      </Txt>

      <FlatButton
        className={linkClassName}
        onClick={onTotalCountClick}
        size={'small'}
        label={`Show all (${totalCount})`}
      />
    </Row>

    {items.map(item => (
      <BookingSearchTableRow
        onClickResult={() => onClickResult(item.bookingNo)}
        item={item}
        key={item.bookingNo}
        searchColumns={searchColumns}
        searchQuery={searchQuery}
      />
    ))}
  </Column>
);
