import { BasicBooking } from '../../common/graphql/fragments/gql/BasicBooking';
import { RegistrationNumberAlertFormState } from './registrationNumberAlert';
import { BookingValidationErrors, UpdateResult } from './updateBookingReducer';
import {
  BookingFormState,
  BookingFormStateKeys,
  FormInstanceId,
  UpdateFormIdVariant,
} from './updateFormReducer';

export type UpdateFormAction =
  | UpdateFormUpdateFormStateAction
  | UpdateFormSetFormStateAction
  | UpdateFormRevertValuesAction
  | InitializeUpdateFormAction
  | SubmitStartAction
  | SubmitFinishedAction
  | RevertChangesUpdateFormAction
  | SetUpdateResultAction
  | UpdateFormSetSaveAsWaitListAction
  | UpdateFormUpdateStartedAction
  | MultiInitializeUpdateFormAction
  | UpdateFormUpdateFinishedAction
  | SetRegistrationNumberAlertAction
  | UpdateRegistrationNumberAlertAction;

export interface UpdateFormSetSaveAsWaitListAction {
  payload: {
    id: FormInstanceId;
  };
  type: 'UPDATE_FORM:SET_SAVE_AS_WAIT_LIST';
}

export const setSaveAsWaitList = (id: FormInstanceId): UpdateFormSetSaveAsWaitListAction => ({
  payload: {
    id,
  },
  type: 'UPDATE_FORM:SET_SAVE_AS_WAIT_LIST',
});

export interface UpdateFormSetFormStateAction {
  payload: {
    id: FormInstanceId;
    values: Partial<BookingFormState>;
  };
  type: 'UPDATE_FORM:SET_VALUES';
}

export const setUpdateValues = (
  id: FormInstanceId,
  values: Partial<BookingFormState>,
): UpdateFormSetFormStateAction => ({
  payload: {
    id,
    values,
  },
  type: 'UPDATE_FORM:SET_VALUES',
});

export interface UpdateFormUpdateFormStateAction {
  payload: {
    id: FormInstanceId;
    values: Partial<BookingFormState>;
  };
  type: 'UPDATE_FORM:UPDATE_VALUES';
}

export const updateValues = (
  id: FormInstanceId,
  values: Partial<BookingFormState>,
): UpdateFormUpdateFormStateAction => ({
  payload: {
    id,
    values,
  },
  type: 'UPDATE_FORM:UPDATE_VALUES',
});

export interface UpdateFormRevertValuesAction {
  payload: {
    id: FormInstanceId;
    keys: BookingFormStateKeys;
  };
  type: 'UPDATE_FORM:REVERT_VALUES';
}

export const revertValues = (
  id: FormInstanceId,
  keys: BookingFormStateKeys,
): UpdateFormRevertValuesAction => ({
  payload: {
    id,
    keys,
  },
  type: 'UPDATE_FORM:REVERT_VALUES',
});

export interface UpdateFormUpdateStartedAction {
  payload: {
    id: FormInstanceId;
  };
  type: 'UPDATE_FORM:UPDATE_STARTED';
}

export const updateStarted = (id: FormInstanceId): UpdateFormUpdateStartedAction => ({
  payload: {
    id,
  },
  type: 'UPDATE_FORM:UPDATE_STARTED',
});

export interface UpdateFormUpdateFinishedAction {
  payload: {
    id: FormInstanceId;
  };
  type: 'UPDATE_FORM:UPDATE_FINISHED';
}

export const updateFinished = (id: FormInstanceId): UpdateFormUpdateFinishedAction => ({
  payload: {
    id,
  },
  type: 'UPDATE_FORM:UPDATE_FINISHED',
});

export interface UpdateFormRequestSubmitAction {
  payload: {
    id: FormInstanceId;
  };
  type: 'UPDATE_FORM:REQUEST_SUBMIT';
}

export const requestSubmit = (id: FormInstanceId): UpdateFormRequestSubmitAction => ({
  payload: {
    id,
  },
  type: 'UPDATE_FORM:REQUEST_SUBMIT',
});

export interface SubmitStartAction {
  payload: {
    id: FormInstanceId;
  };
  type: 'UPDATE_FORM:SUBMIT_START';
}

export const startSubmit = (id: FormInstanceId): SubmitStartAction => ({
  payload: { id },
  type: 'UPDATE_FORM:SUBMIT_START',
});

export interface SubmitFinishedAction {
  payload: {
    validationErrors: BookingValidationErrors;
    id: FormInstanceId;
    updateResult: UpdateResult;
    booking?: BasicBooking;
    formState?: BookingFormState;
  };
  type: 'UPDATE_FORM:SUBMIT_FINISHED';
}

export const finishSubmit = (
  id: FormInstanceId,
  updateResult: UpdateResult,
  validationErrors: BookingValidationErrors,
  booking?: BasicBooking,
  formState?: BookingFormState,
): SubmitFinishedAction => ({
  payload: { booking, formState, id, updateResult, validationErrors },
  type: 'UPDATE_FORM:SUBMIT_FINISHED',
});

export interface TriggerFormUpdateSagaAction<V> {
  payload: {
    id: FormInstanceId;
    name: string;
    value: V;
  };
  type: 'UPDATE_FORM:TRIGGER_FORM_UPDATE_SAGA';
}

export const triggerFormUpdateSaga = <V>(
  id: FormInstanceId,
  name: string,
  value: V,
): TriggerFormUpdateSagaAction<V> => ({
  payload: {
    id,
    name,
    value,
  },
  type: 'UPDATE_FORM:TRIGGER_FORM_UPDATE_SAGA',
});

interface InitializeUpdateFormPayload {
  id: FormInstanceId;
  booking: BasicBooking;
  formState: BookingFormState;
}

export interface InitializeUpdateFormAction {
  type: 'UPDATE_FORM:INITIALIZE_UPDATE_FORM';
  payload: InitializeUpdateFormPayload;
}

export const initializeUpdateForm = (
  id: FormInstanceId,
  formState: BookingFormState,
  booking: BasicBooking,
): InitializeUpdateFormAction => ({
  payload: {
    booking,
    formState,
    id,
  },
  type: 'UPDATE_FORM:INITIALIZE_UPDATE_FORM',
});

export interface RevertChangesUpdateFormAction {
  type: 'UPDATE_FORM:REVERT_CHANGES_UPDATE_FORM';
  payload: {
    id: FormInstanceId;
  };
}

export const revertChangesUpdateForm = (id: FormInstanceId): RevertChangesUpdateFormAction => ({
  payload: {
    id,
  },
  type: 'UPDATE_FORM:REVERT_CHANGES_UPDATE_FORM',
});

interface BookingWithFormState {
  formState: BookingFormState;
  booking: BasicBooking;
}

export interface MultiInitializeUpdateFormAction {
  type: 'UPDATE_FORM:MULTI_INITIALIZE_UPDATE_FORM';
  payload: {
    bookings: BookingWithFormState[];
    formId: UpdateFormIdVariant;
  };
}

export const multiInitializeUpdateForm = (
  formId: UpdateFormIdVariant,
  bookings: BookingWithFormState[],
): MultiInitializeUpdateFormAction => ({
  payload: {
    bookings,
    formId,
  },
  type: 'UPDATE_FORM:MULTI_INITIALIZE_UPDATE_FORM',
});

export interface SetUpdateResultAction {
  payload: {
    id: FormInstanceId;
    result: UpdateResult | null;
  };
  type: 'UPDATE_FORM:SET_UPDATE_RESULT';
}

export const setUpdateResult = (
  id: FormInstanceId,
  result: UpdateResult | null,
): SetUpdateResultAction => ({
  payload: {
    id,
    result,
  },
  type: 'UPDATE_FORM:SET_UPDATE_RESULT',
});

export interface SetRegistrationNumberAlertAction {
  payload: {
    id: FormInstanceId;
    registrationNumberAlert: RegistrationNumberAlertFormState | undefined;
  };
  type: 'UPDATE_FORM:SET_REGISTRATION_NUMBER_ALERT';
}

export const setRegistrationNumberAlert = (
  id: FormInstanceId,
  registrationNumberAlert: RegistrationNumberAlertFormState | undefined,
): SetRegistrationNumberAlertAction => ({
  payload: {
    id,
    registrationNumberAlert,
  },
  type: 'UPDATE_FORM:SET_REGISTRATION_NUMBER_ALERT',
});

export interface UpdateRegistrationNumberAlertAction {
  payload: {
    id: FormInstanceId;
    registrationNumberAlert: Partial<RegistrationNumberAlertFormState>;
  };
  type: 'UPDATE_FORM:UPDATE_REGISTRATION_NUMBER_ALERT';
}

export const updateRegistrationNumberAlert = (
  id: FormInstanceId,
  registrationNumberAlert: Partial<RegistrationNumberAlertFormState>,
): UpdateRegistrationNumberAlertAction => ({
  payload: {
    id,
    registrationNumberAlert,
  },
  type: 'UPDATE_FORM:UPDATE_REGISTRATION_NUMBER_ALERT',
});
