import { HazardousGoodsStatus } from '../../gql/graphql';
import { Texts } from '../texts';
import { GoodsApprovalStatus } from './GoodsApproval';

export const resolveHazardousGoodsApproved = (
  editing: boolean,
  hazardousGoods: boolean,
  hazardousGoodsApproved: boolean,
): GoodsApprovalStatus => {
  if (editing || !hazardousGoods) {
    return GoodsApprovalStatus.NotRequested;
  }

  return hazardousGoodsApproved ? GoodsApprovalStatus.Approved : GoodsApprovalStatus.Requested;
};

export const resolveHazardousGoodsShortText = (
  hazardousGoods: boolean,
  hazardousGoodsApproved: boolean,
) => {
  if (hazardousGoodsApproved) {
    return 'Y';
  } else if (hazardousGoods) {
    return 'R';
  } else {
    return '';
  }
};

export const resolveHazardousGoodsText = (value: boolean): string => {
  return value ? 'Yes' : 'No';
};

export const resolveHazardousGoodsInformationText = (
  hazardousGoods: boolean | null,
): string | undefined => (hazardousGoods ? Texts.HazardousGoodsRequested : undefined);

export const resolveHazardousGoodsStatusText = (status: HazardousGoodsStatus): string => {
  switch (status) {
    case HazardousGoodsStatus.Approved:
      return 'Approved';
    case HazardousGoodsStatus.NotRequested:
      return 'No';
    case HazardousGoodsStatus.Requested:
      return 'Requested';
  }
};
