import { BannerVariant } from '@stenajs-webui/elements/dist/components/ui/banners/banner/Banner';
import { DropdownOption } from '@stenajs-webui/select';
import { differenceInMinutes, parseISO } from 'date-fns';
import { keyBy } from 'lodash';
import {
  DetailedIntermodalBooking,
  IntermodalBookingGood,
  IntermodalBookingHazardousGoods,
  IntermodalBookingRoute,
  IntermodalVehicleType,
} from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { Texts } from '../../../common/texts';
import {
  GoodsApproval,
  GoodsApprovalStatus,
  GoodsApprovalType,
} from '../../../common/utils/GoodsApproval';
import {
  CraneableStatus,
  GoodsType,
  HazardousGoodsStatus,
  IntermodalBookingStatus,
} from '../../../gql/graphql';
import { CreateIntermodalBookingFormModel } from '../../../services/intermodal/createIntermodalReducer';
import {
  UpdateIntermodalBookingFormModel,
  UpdateIntermodalBookingGoodFormModel,
} from '../../../services/intermodal/updateIntermodalReducer';
import { formatVehicleLabel } from '../../manage/grid/utils/optionTransformers';

function transformBookingGoods(good: IntermodalBookingGood): UpdateIntermodalBookingGoodFormModel {
  return {
    goodsType: good.goodsType,
    id: good.id,
    weight: String(good.weight),
  };
}

export const transformIntermodalBookingToUpdateFormState = (
  booking: DetailedIntermodalBooking,
): UpdateIntermodalBookingFormModel => {
  return {
    bookingNo: booking.bookingNo,
    route: booking.journey.route,
    customer: booking.customer,
    width: String(booking.width),
    craneable: booking.craneableStatus === CraneableStatus.NotRequested,
    customerReference: booking.customerReference ?? '',
    height: String(booking.height),
    departureDate: booking.journey.departureDate,
    departureTime: booking.journey.departureTime,
    iluCode: booking.iluCode ?? '',
    length: String(booking.length),
    loadingNote: booking.loadingNote ?? '',
    noOfPlugins: booking.noOfPlugins,
    temperature: booking.pluginTemperature ?? '',
    vehicleReg: booking.vehicleReg ?? '',
    emptyWeight: String(booking.emptyWeight),
    vehicleType: booking.vehicleType,
    hazardousGoods: getBoolFromHazardousGoods(booking.hazardousGoods),
    bookingGoods: keyBy(booking.bookingGoods.map(transformBookingGoods), b => b.id),
  };
};

export const transformIntermodalBookingToCreateFormState = (
  booking: DetailedIntermodalBooking,
): CreateIntermodalBookingFormModel => {
  return {
    route: booking.journey.route,
    customer: booking.customer,
    width: String(booking.width),
    craneable: booking.craneableStatus === CraneableStatus.NotRequested,
    customerReference: booking.customerReference ?? '',
    height: String(booking.height),
    departureDate: booking.journey.departureDate,
    departureTime: booking.journey.departureTime,
    iluCode: booking.iluCode ?? '',
    length: String(booking.length),
    loadingNote: booking.loadingNote ?? '',
    noOfPlugins: booking.noOfPlugins,
    temperature: booking.pluginTemperature ?? '',
    vehicleReg: booking.vehicleReg ?? '',
    emptyWeight: String(booking.emptyWeight),
    vehicleType: booking.vehicleType,
    hazardousGoods: getBoolFromHazardousGoods(booking.hazardousGoods),
    bookingGoods: keyBy(booking.bookingGoods.map(transformBookingGoods), b => b.id),
    legs: booking.journey.legs,
    searchByDeparture: false,
  };
};

export const transformNoOfPluginsOption = (noOfPlugins: number): DropdownOption<number> => ({
  data: noOfPlugins,
  value: String(noOfPlugins),
  label: noOfPlugins === 0 ? 'No' : String(noOfPlugins),
});

export function transformIntermodalRouteToDropdownOption(
  route: IntermodalBookingRoute,
): DropdownOption<IntermodalBookingRoute> {
  return {
    value: route.id,
    data: route,
    label: route.longDescription,
  };
}

export function transformDepartureTimeOption(departureTime: string): DropdownOption<string> {
  return { data: departureTime, value: departureTime, label: departureTime };
}

export const transformGoodsTypeOption = (goodsType: GoodsType): DropdownOption<GoodsType> => {
  return {
    label: goodsType.description,
    value: goodsType.id,
    data: goodsType,
  };
};

export const getOrdinal = (number: number) => {
  const englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });
  const suffixes = {
    zero: '',
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
    many: 'th',
  };
  const category = englishOrdinalRules.select(number);
  const suffix = suffixes[category];
  return number + suffix;
};

export const getDepartureTimeDifferenceString = (
  firstSailingArrival: string,
  secondSailingDeparture: string,
) => {
  const timeDifferenceInMinutes = differenceInMinutes(
    parseISO(secondSailingDeparture),
    parseISO(firstSailingArrival),
  );
  const hours = Math.floor(timeDifferenceInMinutes / 60);
  const minutes = timeDifferenceInMinutes % 60;
  return `${hours}H ${minutes}MIN`;
};

export function parseBoolToHazardous(value: boolean): IntermodalBookingHazardousGoods | undefined {
  switch (value) {
    case true:
      return HazardousGoodsStatus.Requested;
    case false:
      return undefined;
  }
}

export function getBoolFromHazardousGoods(value: IntermodalBookingHazardousGoods | null) {
  switch (value) {
    case HazardousGoodsStatus.Approved:
    case HazardousGoodsStatus.Requested:
      return true;
    default:
      return false;
  }
}

export function resolveIntermodalStatusMessage(
  status: IntermodalBookingStatus | null,
): { message: string; variant: BannerVariant } | undefined {
  switch (status) {
    case IntermodalBookingStatus.InProgress:
      return {
        message: Texts.IntermodalInProgress,
        variant: 'info',
      };
    case IntermodalBookingStatus.Updated:
      return {
        message: Texts.IntermodalUpdated,
        variant: 'info',
      };
    default:
      return undefined;
  }
}

export const getIntermodalVehicleTypeOptions = (
  vehicleTypes: IntermodalVehicleType[],
): DropdownOption<IntermodalVehicleType>[] => {
  return vehicleTypes.map(transformIntermodalVehicleType);
};

export const transformIntermodalVehicleType = (
  vehicle: IntermodalVehicleType,
): DropdownOption<IntermodalVehicleType> => {
  return {
    data: vehicle,
    label: formatVehicleLabel(vehicle.description),
    value: vehicle.code,
  };
};

export function getIntermodalHazardousGoodsApprovalInfo(
  hazardousGoods: HazardousGoodsStatus,
): GoodsApproval {
  return {
    status: resolveHazardousGoodsApproval(hazardousGoods),
    type: GoodsApprovalType.Hazardous,
  };
}

function resolveHazardousGoodsApproval(status: HazardousGoodsStatus): GoodsApprovalStatus {
  switch (status) {
    case HazardousGoodsStatus.Approved:
      return GoodsApprovalStatus.Approved;
    case HazardousGoodsStatus.Requested:
      return GoodsApprovalStatus.Requested;
    case HazardousGoodsStatus.NotRequested:
    default:
      return GoodsApprovalStatus.NotRequested;
  }
}

export function getCraneableStatusApprovalInfo(status: CraneableStatus): GoodsApproval {
  return {
    status: resolveCraneableStatusApproval(status),
    type: GoodsApprovalType.CraneableStatus,
  };
}

function resolveCraneableStatusApproval(status: CraneableStatus): GoodsApprovalStatus {
  switch (status) {
    case CraneableStatus.Approved:
      return GoodsApprovalStatus.Approved;
    case CraneableStatus.Requested:
      return GoodsApprovalStatus.Requested;
    case CraneableStatus.NotAvailable:
      return GoodsApprovalStatus.Rejected;
    case CraneableStatus.NotRequested:
    default:
      return GoodsApprovalStatus.NotRequested;
  }
}
