import { FC, Suspense, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorBoundary } from '../../../../common/error-handling/ErrorBoundary';
import { FreightPortalBaseModal } from '../../../../common/modal/FreightPortalBaseModal';
import { ModalLoader } from '../../../../common/modal/ModalLoader';
import { PageTitle } from '../../../../common/routes/PageTitle';
import { trackModalView } from '../../../../common/tracking/trackerService';
import { clear } from '../../../../vendor/suspend-react';
import { useActiveRow } from '../../grid/hooks/useActiveRow';
import { SwitchModalContent } from './SwitchModalContent';
import { SwitchModalError } from './SwitchModalError';
import { useHasPermission } from '../../../../common/statistics/useHasPermission';
import {
  BOOKING_ERROR_FORBIDDEN,
  BookingErrorModalContents,
} from '../../details/components/BookingErrorModal';
import { SwitchModalHeader } from './SwitchModalHeader';
import { Permission } from '../../../../services/authentication/utils/authUtils';

export const SwitchModal: FC = () => {
  const { bookingNo: bookingNoString } = useParams<'bookingNo'>();
  const bookingNo = Number(bookingNoString);

  const getHasPermission = useHasPermission();

  const navigate = useNavigate();

  const onRequestClose = () => {
    navigate(-1);
    clear(['switch-candidates', bookingNo]);
  };

  useActiveRow({ bookingNo });

  useEffect(() => {
    trackModalView('Switch');
  }, []);

  return (
    <FreightPortalBaseModal
      aria-label={'Switch booking'}
      isOpen
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
      draggable
    >
      <PageTitle title={`Switch ${bookingNo}`} />
      {getHasPermission(Permission.EditBookings) ? (
        <Suspense fallback={<ModalLoader />}>
          <ErrorBoundary
            fallback={error => (
              <SwitchModalError
                error={error}
                bookingNo={bookingNo}
                onRequestClose={onRequestClose}
              />
            )}
          >
            <SwitchModalContent bookingNo={bookingNo} onRequestClose={onRequestClose} />
          </ErrorBoundary>
        </Suspense>
      ) : (
        <>
          <SwitchModalHeader
            bookingNo={bookingNo}
            onRequestClose={onRequestClose}
            errorTitle={BOOKING_ERROR_FORBIDDEN.message}
          />
          <BookingErrorModalContents icon={BOOKING_ERROR_FORBIDDEN.icon} />
        </>
      )}
    </FreightPortalBaseModal>
  );
};
