import { all, call, fork, put, select } from 'redux-saga/effects';
import {
  fetchAnimalOptions,
  fetchSailings,
  fetchSenderReceiverCountries,
  fetchVehicleTypeOptionsCached,
} from '../../../common/api';
import { VehicleType } from '../../../common/graphql/fragments/gql/VehicleType';
import { transformCountryOptions } from '../../../common/graphql/useSenderReceiverCountryOptions';
import {
  handleVehicleRegNoRequest,
  RegistrationNumberAlertStateChange,
} from '../../../common/registrationNumberAlert/sagas/registrationNumberAlertsSaga';
import { hasSenderReceiverCountries } from '../../../common/update/utils/hasSenderReceiverCountries';
import { getNewBookableSailing } from '../../../common/utils/sailingUtils';
import { TravelledVehicleType } from '../../update-form/registrationNumberAlert';
import {
  BookingCandidateId,
  CreateFormIdVariant,
  CreateFormSetAllSpecificValuesAction,
  CreateFormUpdateCommonValuesAction,
  CreateFormUpdateSpecificValuesAction,
  setAllSpecificValues,
  setSpecificValues,
  updateAllSpecificValues,
  updateCommonValues,
  updateSpecificValues,
} from '../createFormActions';
import { CreateCandidate, CreateFormState } from '../createFormReducer';
import { enableExtendedMode } from '../createMetaActions';
import { getCreateFormState, getCreateFormStateCandidate } from '../createReducer';
import { getNumberOfDrivers } from '../utils/transformCreateBookingResults';
import { ResolveGenerator } from '../../../types/ResolveGenerator';
import { Route } from '../../../common/graphql/fragments/gql/Route';
import { shouldClearUkCustomsClassification } from '../../../common/utils/customsClassificationUtil';
import { customsClassificationDropdownEmptyOption } from '../../../common/graphql/useCustomsClassificationsOptions';
import { Sailing } from '../../../common/graphql/fragments/gql/Sailing';

function* setUkCustomsClassification(
  formId: CreateFormIdVariant,
  departureDate: string,
  departureTime: string,
  route: Route,
  candidates: CreateCandidate[],
) {
  yield all(
    candidates
      .filter(c =>
        shouldClearUkCustomsClassification(
          departureDate,
          departureTime,
          route,
          c.customsClassification,
        ),
      )
      .map((_c, index) =>
        put(
          setSpecificValues(formId, index, {
            customsClassification: customsClassificationDropdownEmptyOption.data,
          }),
        ),
      ),
  );
}

function* fetchAndUpdateVehicleType(
  formId: CreateFormIdVariant,
  customerNumber: number,
  routeCode: string,
  candidates: CreateCandidate[],
) {
  const vehicleTypes: Awaited<ReturnType<typeof fetchVehicleTypeOptionsCached>> = yield call(
    fetchVehicleTypeOptionsCached,
    customerNumber,
    routeCode,
  );

  const actions = candidates.map((candidate, index) => {
    const vehicleType = candidate.vehicleType;
    if (vehicleType) {
      const vehicleTypeOnRoute = vehicleTypes.find(v => v.code === vehicleType.code);
      return updateSpecificValues(formId, index, {
        vehicleType: vehicleTypeOnRoute || vehicleTypes[0],
      });
    } else {
      return undefined;
    }
  });

  for (const action of actions) {
    if (action) {
      yield put(action);
    }
  }
}

function* fetchAndUpdateSailing(
  formId: CreateFormIdVariant,
  routeCode: string,
  departureDate: string,
  withDepartureTime: { departureTime: string } | undefined,
) {
  if (routeCode && departureDate) {
    const sailings: Awaited<ReturnType<typeof fetchSailings>> = yield call(
      fetchSailings,
      routeCode,
      departureDate,
    );

    const sailing = getNewBookableSailing(sailings, withDepartureTime);

    yield put(updateCommonValues(formId, { sailing }));
  }
}

function* updateValuesFromVehicleType(
  formId: CreateFormIdVariant,
  id: BookingCandidateId | null,
  vehicleType: VehicleType,
) {
  const { defaultWidth, defaultHeight, defaultLength, defaultTradeWeight } = vehicleType;

  const defaultNoOfDrivers = getNumberOfDrivers(vehicleType);

  const values: Partial<CreateCandidate> = {
    height: defaultHeight,
    length: defaultLength,
    numberOfDrivers: defaultNoOfDrivers,
    tradeWeight: defaultTradeWeight,
    width: defaultWidth,
  };

  if (id === null) {
    yield put(setAllSpecificValues(formId, values));
  } else {
    yield put(setSpecificValues(formId, id, values));
  }
}

export function* handleUpdateCommonValues(action: CreateFormUpdateCommonValuesAction) {
  const { formId, values } = action.payload;

  const state: CreateFormState = yield select(getCreateFormState(formId));

  yield put(updateAllSpecificValues(formId, { registrationNumberAlert: null }));

  // If customer or route has been changed
  if (values.route || values.customer) {
    const customer = values.customer ?? state.customer;
    const route = values.route ?? state.route;

    if (customer && route) {
      yield* fetchAndUpdateVehicleType(formId, customer.custNo, route.code, state.bookings);

      if (customer.isCashCustomer) {
        const countries: Awaited<ReturnType<typeof fetchSenderReceiverCountries>> = yield call(
          fetchSenderReceiverCountries,
        );
        const countryOptions = transformCountryOptions(countries);
        const { arrivalCountryCode, departureCountryCode } = route;
        const senderCountry = countryOptions.find(option => option.value === departureCountryCode);
        const receiverCountry = countryOptions.find(option => option.value === arrivalCountryCode);
        yield all(
          state.bookings
            .filter(hasSenderReceiverCountries)
            .map((_c, index) =>
              put(setSpecificValues(formId, index, { senderCountry, receiverCountry })),
            ),
        );
      } else {
        yield put(
          setAllSpecificValues(formId, {
            senderCountry: undefined,
            receiverCountry: undefined,
          }),
        );
      }
    }
  }

  // If route has been changed
  if (values.route && state.customer && state.departureDate) {
    yield fetchAndUpdateVehicleType(
      action.payload.formId,
      state.customer.custNo,
      values.route.code,
      state.bookings,
    );

    yield fetchAndUpdateSailing(
      action.payload.formId,
      values.route.code,
      state.departureDate,
      state.sailing ?? undefined,
    );

    yield put(
      setAllSpecificValues(formId, {
        importReference: '',
        exportReference: '',
      }),
    );

    if (!values.route.useCargoWeight || !values.route.useSenderReceiverCountry) {
      yield put(
        setAllSpecificValues(formId, {
          senderCountry: undefined,
          receiverCountry: undefined,
        }),
      );
    }
  }

  // If departure date has been changed
  if ('departureDate' in values && state.route) {
    let sailing: Sailing | null | undefined = null;

    if (values.departureDate) {
      const sailings: Awaited<ReturnType<typeof fetchSailings>> = yield call(
        fetchSailings,
        state.route.code,
        values.departureDate,
      );

      sailing = getNewBookableSailing(sailings, state.sailing);

      if (sailing) {
        yield fork(
          setUkCustomsClassification,
          formId,
          values.departureDate,
          sailing.departureTime,
          state.route,
          state.bookings,
        );
      }
    }

    yield put(updateCommonValues(formId, { sailing }));
  }
}

const transformRegistrationNumberAlertToSpecific = (
  alert: RegistrationNumberAlertStateChange,
  candidate: CreateCandidate,
): Partial<CreateCandidate> => ({
  hasHazardousGoods:
    alert.hazardousGoods !== undefined ? alert.hazardousGoods : candidate.hasHazardousGoods,
  height: alert.height || candidate.height,
  length: alert.length || candidate.length,
  loadingListMessage: alert.loadingListMessage || candidate.loadingListMessage,
  registrationNumberAlert: {
    appliedArrivalNotepad: alert.registrationNumberAlert.appliedArrivalNotepad,
    appliedCargoWeight: alert.registrationNumberAlert.appliedCargoWeight,
    appliedHazardousGoods: alert.registrationNumberAlert.appliedHazardousGoods,
    appliedHeight: alert.registrationNumberAlert.appliedHeight,
    appliedLength: alert.registrationNumberAlert.appliedLength,
    appliedLoadingListMessage: alert.registrationNumberAlert.appliedLoadingListMessage,
    appliedTravelledVehicle: alert.registrationNumberAlert.appliedTravelledVehicle,
    result: alert.registrationNumberAlert.result,
    travelledVehicleType: alert.registrationNumberAlert.travelledVehicleType,
    vehicleType: alert.registrationNumberAlert.vehicleType,
  },
  vehicleType: alert.vehicleType || candidate.vehicleType,
});

function* updateValuesFromVehicleReg(
  formId: CreateFormIdVariant,
  id: BookingCandidateId,
  state: CreateFormState,
  candidate: CreateCandidate,
  vehicleRegNo: string,
  travelledVehicleType: TravelledVehicleType,
) {
  if (!state.customer || !state.route) {
    return;
  }

  const cargoWeight = 0;

  const registrationNumberAlertResult: ResolveGenerator<
    ReturnType<typeof handleVehicleRegNoRequest>
  > = yield call(
    handleVehicleRegNoRequest,
    state.customer.custNo,
    state.route.code,
    vehicleRegNo,
    travelledVehicleType,
    cargoWeight,
    candidate.hasHazardousGoods,
    candidate.vehicleType,
    candidate.height,
    candidate.length,
    state.bookings[id].loadingListMessage,
  );

  if (registrationNumberAlertResult) {
    yield put(
      setSpecificValues(
        formId,
        id,
        transformRegistrationNumberAlertToSpecific(registrationNumberAlertResult, candidate),
      ),
    );

    if (
      registrationNumberAlertResult.registrationNumberAlert.appliedArrivalNotepad ||
      registrationNumberAlertResult.registrationNumberAlert.appliedHeight ||
      registrationNumberAlertResult.registrationNumberAlert.appliedLength
    ) {
      yield put(enableExtendedMode(formId));
    }
  } else if (
    candidate.registrationNumberAlert &&
    candidate.registrationNumberAlert.travelledVehicleType === travelledVehicleType
  ) {
    yield put(setSpecificValues(formId, id, { registrationNumberAlert: null }));
  }
}

export function* handleUpdateSpecificValues(action: CreateFormUpdateSpecificValuesAction) {
  const { formId, values, id } = action.payload;

  const state: CreateFormState = yield select(getCreateFormState(formId));
  const candidate: CreateCandidate | undefined = yield select(
    getCreateFormStateCandidate(formId),
    id,
  );

  if (!candidate) {
    return;
  }

  if (values.vehicleType) {
    yield updateValuesFromVehicleType(formId, id, values.vehicleType);
  }

  if (values.vehicleReg !== undefined) {
    yield updateValuesFromVehicleReg(
      formId,
      id,
      state,
      candidate,
      values.vehicleReg,
      TravelledVehicleType.Vehicle,
    );
  }

  if (values.trailerReg !== undefined) {
    yield updateValuesFromVehicleReg(
      formId,
      id,
      state,
      candidate,
      values.trailerReg,
      TravelledVehicleType.Trailer,
    );
  }

  if (values.hasAnimals) {
    const animalOptions: Awaited<ReturnType<typeof fetchAnimalOptions>> = yield call(
      fetchAnimalOptions,
    );
    yield put(setSpecificValues(formId, id, { livestockType: animalOptions[0].data }));
  }

  if ('livestockType' in values) {
    yield put(setSpecificValues(formId, id, { hasAnimals: !!values.livestockType }));
  }

  if ('cargoWeight' in values) {
    yield put(
      setSpecificValues(formId, id, {
        hasCargoWeight: values.cargoWeight ? values.cargoWeight > 0 : false,
      }),
    );

    if (state.customer && state.customer.isCashCustomer) {
      if (values.cargoWeight && values.cargoWeight > 0 && state.route) {
        const countries: Awaited<ReturnType<typeof fetchSenderReceiverCountries>> = yield call(
          fetchSenderReceiverCountries,
        );
        const countryOptions = transformCountryOptions(countries);
        const arrivalCountryCode = state.route.arrivalCountryCode;
        const departureCountryCode = state.route.departureCountryCode;
        yield put(
          setSpecificValues(formId, id, {
            senderCountry: countryOptions.find(option => option.value === departureCountryCode),
            receiverCountry: countryOptions.find(option => option.value === arrivalCountryCode),
          }),
        );
      } else {
        yield put(
          setSpecificValues(formId, id, {
            senderCountry: undefined,
            receiverCountry: undefined,
          }),
        );
      }
    }
  }
}

export function* handleUpdateAllSpecificValues(action: CreateFormSetAllSpecificValuesAction) {
  const { formId, values } = action.payload;

  if (values.vehicleType) {
    yield updateValuesFromVehicleType(formId, null, values.vehicleType);
  }
}
