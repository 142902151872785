import {
  CommonDropdownField,
  CommonDropdownFieldProps,
} from '../../../../common-ui/form-fields/CommonDropdownField';
import { hasDropdownValueChanged } from '../../../multiple-create/hooks/useDropdownField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import {
  UpdateIntermodalBookingFormModel,
  UpdateIntermodalBookingFormModelDropdownKeys,
} from '../../../../services/intermodal/updateIntermodalReducer';
import { useUpdateIntermodalField } from '../../hooks/useUpdateIntermodalField';

interface UpdateIntermodalDropDownFieldProps<
  Name extends UpdateIntermodalBookingFormModelDropdownKeys,
> extends Omit<
    CommonDropdownFieldProps<UpdateIntermodalBookingFormModel[Name]>,
    'onValueChange' | 'value' | 'trackerAction'
  > {
  name: Name;
}

export function UpdateIntermodalDropdownField<
  Name extends UpdateIntermodalBookingFormModelDropdownKeys,
>({ name, ...props }: UpdateIntermodalDropDownFieldProps<Name>) {
  const fieldProps = useUpdateIntermodalField(name);
  return (
    <CommonDropdownField
      {...props}
      {...getFieldProps(props, fieldProps)}
      changed={hasDropdownValueChanged(fieldProps, props.transformOption)}
    />
  );
}
