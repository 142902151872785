import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PickByValue } from '@stenajs-webui/core';
import { isEqual, mapKeys } from 'lodash';
import { Customer } from '../../common/graphql/fragments/gql/Customer';
import {
  IntermodalBookingRoute,
  IntermodalVehicleType,
} from '../../common/graphql/fragments/gql/IntermodalBooking';
import { Message } from '../../common/update-information/utils/messagesResult';
import { GoodsType } from '../../gql/graphql';
import { StoreState } from '../../store/storeState';
import { clearValidationErrors } from '../common/utils';
import { getBannerValidationErrors, getBookingGoodsKey } from './createIntermodalReducer';
import { updateIntermodalBookingThunk } from './thunks/updateIntermodalBookingThunk';
import { getVehicleDimensions } from './utils';

export type UpdateIntermodalFormModelStringKeys = keyof PickByValue<
  UpdateIntermodalBookingFormModel,
  string
>;

export type UpdateIntermodalBookingValidationErrors = Record<string, string[] | undefined>;

export type UpdateIntermodalBookingFormModelDateKeys = keyof Pick<
  UpdateIntermodalBookingFormModel,
  'departureDate'
>;

export type UpdateIntermodalBookingFormModelDropdownKeys = keyof Pick<
  UpdateIntermodalBookingFormModel,
  | 'vehicleType'
  | 'craneable'
  | 'departureTime'
  | 'noOfPlugins'
  | 'customer'
  | 'route'
  | 'hazardousGoods'
>;

type UpdateIntermodalBookingGoodsRecord = Record<string, UpdateIntermodalBookingGoodFormModel>;

export interface UpdateIntermodalBookingFormModel {
  bookingNo: number;
  route: IntermodalBookingRoute | null;
  customer: Customer | null;
  departureDate: string;
  vehicleReg: string;
  iluCode: string;
  vehicleType: IntermodalVehicleType | null;
  craneable: boolean;
  emptyWeight: string;
  length: string;
  width: string;
  height: string;
  hazardousGoods: boolean;
  noOfPlugins: number;
  temperature: string;
  bookingGoods: UpdateIntermodalBookingGoodsRecord;
  customerReference: string;
  loadingNote: string;
  departureTime: string;
}

export type UpdateIntermodalBookingGoodFormModel = {
  goodsType: GoodsType | null;
  weight: string;
  id: string;
  created?: boolean;
};

interface IntermodalUpdateResult {
  success: boolean;
  bookingOutOfDate: boolean;
  errors: Message[];
  messages: Message[];
  warnings: Message[];
}

interface UpdateIntermodalBookingFormMeta {
  updateResult: IntermodalUpdateResult | null;
  fieldValidationErrors: UpdateIntermodalBookingValidationErrors;
  bannerValidationErrors: string[];
  submitting: boolean;
}

export interface UpdateIntermodalBookingReducerState {
  initialFormState: UpdateIntermodalBookingFormModel;
  editedFormState: UpdateIntermodalBookingFormModel;
  meta: UpdateIntermodalBookingFormMeta;
}

export const createEmptyUpdateFormModel = (): UpdateIntermodalBookingFormModel => ({
  vehicleReg: '',
  bookingNo: 0,
  customer: null,
  iluCode: '',
  length: '',
  width: '',
  height: '',
  emptyWeight: '',
  temperature: '',
  customerReference: '',
  craneable: true,
  loadingNote: '',
  route: null,
  bookingGoods: {},
  hazardousGoods: false,
  noOfPlugins: 0,
  departureDate: '',
  departureTime: '',
  vehicleType: null,
});

export const createEmptyUpdateGoodsFormModel = (
  id: string,
): UpdateIntermodalBookingGoodFormModel => ({
  goodsType: null,
  weight: '',
  id,
  created: true,
});

export const createEmptyUpdateFormMeta = (): UpdateIntermodalBookingFormMeta => ({
  fieldValidationErrors: {},
  bannerValidationErrors: [],
  updateResult: null,
  submitting: false,
});

export const INITIAL_VALUE: UpdateIntermodalBookingReducerState = {
  initialFormState: createEmptyUpdateFormModel(),
  editedFormState: createEmptyUpdateFormModel(),
  meta: createEmptyUpdateFormMeta(),
};

const slice = createSlice({
  name: 'intermodalBooking',
  initialState: INITIAL_VALUE,
  reducers: {
    initializeUpdateIntermodalFormState(
      state,
      action: PayloadAction<UpdateIntermodalBookingFormModel>,
    ) {
      state.initialFormState = action.payload;
      state.editedFormState = action.payload;
      state.meta = {
        fieldValidationErrors: {},
        bannerValidationErrors: [],
        updateResult: null,
        submitting: false,
      };
    },
    setUpdateIntermodalBookingFormValue(
      state,
      action: PayloadAction<Partial<UpdateIntermodalBookingFormModel>>,
    ) {
      clearValidationErrors(state.meta.fieldValidationErrors, action.payload);
      Object.assign(state.editedFormState, action.payload);

      if (action.payload.vehicleType) {
        Object.assign(state.editedFormState, getVehicleDimensions(action.payload.vehicleType));
      }
    },
    addUpdateIntermodalBookingGood(state, action: PayloadAction<{ goodId: string }>) {
      const good = createEmptyUpdateGoodsFormModel(action.payload.goodId);
      state.editedFormState.bookingGoods[good.id] = good;
    },
    modifyUpdateIntermodalBookingGood(
      state,
      action: PayloadAction<{
        id: string;
        updatedFields: Partial<UpdateIntermodalBookingGoodFormModel>;
      }>,
    ) {
      const { id, updatedFields } = action.payload;
      clearValidationErrors(
        state.meta.fieldValidationErrors as any,
        mapKeys(updatedFields, (_v, k) => getBookingGoodsKey(id, k)),
      );

      const bookingGood = state.editedFormState.bookingGoods[id];
      if (bookingGood) {
        Object.assign(bookingGood, updatedFields);
      }
    },
    removeUpdateIntermodalBookingGood(state, action: PayloadAction<{ id: string }>) {
      delete state.editedFormState.bookingGoods[action.payload.id];
    },
    clearUpdateBookingGoods(state) {
      state.editedFormState.bookingGoods = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(updateIntermodalBookingThunk.fulfilled, (state, action) => {
        state.meta.updateResult = {
          bookingOutOfDate: false,
          success: true,
          errors: [],
          warnings: action.payload.warnings,
          messages: action.payload.messages,
        };
        state.meta.submitting = false;
        state.initialFormState = action.payload.formState;
        state.editedFormState = action.payload.formState;
      })
      .addCase(updateIntermodalBookingThunk.rejected, (state, action) => {
        const rejectedValue = action.payload;
        switch (rejectedValue?.type) {
          case 'outOfDate':
            state.meta.updateResult = {
              bookingOutOfDate: true,
              errors: [],
              messages: [],
              warnings: [],
              success: false,
            };
            break;
          case 'validationErrors':
            state.meta.fieldValidationErrors = rejectedValue.errors;
            state.meta.bannerValidationErrors = getBannerValidationErrors(
              state.meta.fieldValidationErrors,
            );
            state.meta.updateResult = null;
            break;
          case 'error':
            state.meta.updateResult = {
              bookingOutOfDate: false,
              errors: rejectedValue.errors,
              messages: [],
              warnings: [],
              success: false,
            };
            break;
          default:
            state.meta.updateResult = {
              bookingOutOfDate: false,
              messages: [],
              warnings: [],
              errors: action.error.message ? [{ description: action.error.message }] : [],
              success: false,
            };
        }
        state.meta.submitting = false;
      })
      .addCase(updateIntermodalBookingThunk.pending, state => {
        state.meta.submitting = true;
        state.meta.fieldValidationErrors = {};
        state.meta.bannerValidationErrors = [];
        state.meta.updateResult = null;
      }),
});

export const {
  setUpdateIntermodalBookingFormValue,
  addUpdateIntermodalBookingGood,
  removeUpdateIntermodalBookingGood,
  initializeUpdateIntermodalFormState,
  modifyUpdateIntermodalBookingGood,
  clearUpdateBookingGoods,
} = slice.actions;

export const updateIntermodalBookingReducer = slice.reducer;
export const getUpdateIntermodalBookingState = (state: StoreState) => state.intermodalUpdate;
export const getUpdateIntermodalBookingValidationErrors = (
  state: StoreState,
  key: keyof UpdateIntermodalBookingFormModel,
) => getUpdateIntermodalBookingState(state).meta.fieldValidationErrors[key];

export const getAllIntermodalUpdateValidationErrors = (state: StoreState) => {
  return getUpdateIntermodalBookingState(state).meta.bannerValidationErrors;
};
export const getUpdateIntermodalBookingUpdateResult = (state: StoreState) =>
  state.intermodalUpdate.meta.updateResult;
export const getUpdateIntermodalBookingInitialFormState = (state: StoreState) =>
  getUpdateIntermodalBookingState(state).initialFormState;
export const getUpdateIntermodalBookingEditedFormState = (state: StoreState) =>
  getUpdateIntermodalBookingState(state).editedFormState;
export const getUpdateIntermodalBookingIsSubmitting = (state: StoreState) =>
  state.intermodalUpdate.meta.submitting;
export const getUpdateIntermodalBookingGoodValidationErrors = (
  state: StoreState,
  goodId: string,
  key: keyof UpdateIntermodalBookingGoodFormModel,
) =>
  getUpdateIntermodalBookingState(state).meta.fieldValidationErrors[
    getBookingGoodsKey(goodId, key)
  ];

export const getUpdateIntermodalFormIsEdited = (state: StoreState) => {
  const initialState = getUpdateIntermodalBookingInitialFormState(state);
  const editedState = getUpdateIntermodalBookingEditedFormState(state);

  return !isEqual(initialState, editedState);
};

export const getUpdateEditedBookingGood = (state: StoreState, id: string) => {
  return getUpdateIntermodalBookingEditedFormState(state).bookingGoods[id];
};

export const getUpdateInitialBookingGood = (state: StoreState, id: string) => {
  return getUpdateIntermodalBookingInitialFormState(state).bookingGoods[id];
};
