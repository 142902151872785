import { FC, ReactNode } from 'react';
import { Box } from '@stenajs-webui/core';
import { PRINT_HIDE_CLASSNAME } from '../globalStyles';
import { ModalFooter } from '../use-cases/manage/details/components/modal/ModalFooter';

interface ModalFooterBaseProps {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
}

export const ModalFooterBase: FC<ModalFooterBaseProps> = ({ leftContent, rightContent }) => (
  <Box className={PRINT_HIDE_CLASSNAME}>
    <ModalFooter>
      <Box
        flexDirection={['column', null, 'row']}
        alignItems={[null, null, 'flex-end']}
        justifyContent={'space-between'}
        gap
      >
        {leftContent ? leftContent : <Box />}
        <Box gap flexDirection={['column', null, 'row']}>
          {rightContent}
        </Box>
      </Box>
    </ModalFooter>
  </Box>
);
