import { TextInput, TextInputProps } from '@stenajs-webui/forms';
import { TrackerCategory } from '../../common/tracking/trackerService';
import {
  TrackerAction,
  useTrackingTextInput,
} from '../../use-cases/simple-create/hooks/UseTrackingTextInput';
import { FC } from 'react';
import { CopyButton } from '../../use-cases/intermodal/common/ValueTxt';

export interface CommonTextInputFieldProps
  extends Pick<
    TextInputProps,
    | 'onChange'
    | 'disabled'
    | 'value'
    | 'style'
    | 'maxLength'
    | 'placeholder'
    | 'buttonRight'
    | 'buttonLeft'
  > {
  trackerCategory: TrackerCategory;
  editable?: boolean;
  trackerAction: TrackerAction;
  isValid?: boolean;
  changed?: boolean;
  showCopyButton?: boolean;
}

export const CommonTextInputField: FC<CommonTextInputFieldProps> = ({
  isValid = true,
  trackerCategory,
  trackerAction,
  editable,
  onChange,
  disabled,
  changed,
  value,
  style,
  showCopyButton = false,
  ...props
}) => {
  const { onBlur, onFocus } = useTrackingTextInput(trackerAction, trackerCategory);

  return (
    <TextInput
      variant={!isValid ? 'error' : undefined}
      onBlur={onBlur}
      disabled={disabled || !editable}
      onFocus={onFocus}
      value={value}
      buttonRight={showCopyButton && value ? <CopyButton getValue={() => value} /> : undefined}
      style={{
        ...style,
        fontStyle: changed ? 'italic' : 'normal',
        fontWeight: changed ? 'bold' : 'normal',
      }}
      {...props}
    />
  );
};
