import { useLazyQuery } from '@apollo/client';
import { flatMap, uniq } from 'lodash';
import { useCallback, useState } from 'react';
import { CalculatePriceQueryQueryVariables } from '../../gql/graphql';
import { calculatePriceQuery } from './graphql/calculatePriceQuery';

const NO_RATE_AGREEMENT_FOUND_FOR_THIS_ROUTE = 'No rate agreement found for this route.';

export const useCalculatePrice = <T>(formState: T, isPriceOutDate: (f1: T, f2: T) => boolean) => {
  const [tempFormState, setTempFormState] = useState<T>(formState);

  const [calculatePrice, { data, loading }] = useLazyQuery(calculatePriceQuery, {
    fetchPolicy: 'network-only',
  });

  const [showResult, setShowResult] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const fetchPrice = useCallback(
    async (variables: CalculatePriceQueryQueryVariables, formState: T) => {
      try {
        const result = await calculatePrice({ variables });
        setErrors([]);
        setShowResult(false);
        if (result.error || !result.data) {
          setErrors([NO_RATE_AGREEMENT_FOUND_FOR_THIS_ROUTE]);
        } else {
          const topLevelErrors = result.data.viewer.payment.calculatePriceForBookings.errors;
          const articleErrors = flatMap(
            result.data.viewer.payment.calculatePriceForBookings.articlesResults,
            r => r.errors,
          );
          const allErrors = [...topLevelErrors, ...articleErrors];

          if (allErrors.length > 0) {
            setErrors(uniq(allErrors.map(e => e.description)));
          } else {
            setShowResult(true);
          }
        }
      } catch {
        setErrors([NO_RATE_AGREEMENT_FOUND_FOR_THIS_ROUTE]);
      } finally {
        setTempFormState(formState);
      }
    },
    [calculatePrice],
  );

  return {
    fetchPrice,
    loading,
    errors,
    result: showResult ? data?.viewer.payment.calculatePriceForBookings! : null,
    priceOutdated: isPriceOutDate(tempFormState, formState),
  };
};
