import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConsentMode, getConsentMode } from '../../common/tracking/ga4';
import { StoreState } from '../../store/storeState';

export interface CookieConsentReducerState {
  consent: ConsentMode | null;
}

export const getCookieConsentInitialState = (): CookieConsentReducerState => ({
  consent: getConsentMode(),
});

const INITIAL_STATE: CookieConsentReducerState = {
  consent: null,
};

const slice = createSlice({
  name: 'cookie-consent',
  initialState: INITIAL_STATE,
  reducers: {
    updateConsent(state, { payload }: PayloadAction<ConsentMode>) {
      state.consent = payload;
    },
    clearConsent(state) {
      state.consent = null;
    },
  },
});

export const {
  reducer: cookieConsentReducer,
  actions: { updateConsent, clearConsent },
} = slice;

export const getCookieConsent = (state: StoreState) => state.cookieConsent.consent;
