import { INITIAL_VALUE } from '../../services/booking-filter/filterReducer';
import { INITIAL_VALUE as INITIAL_SORT_VALUE } from '../../services/sorting/sortingReducer';
import { getDefaultSortDirection } from '../../use-cases/manage/grid/utils/getDefaultSortDirection';
import { parseFilterDate } from '../utils/dates/dateFilter';
import { UrlFilterAndSortState, URLHashData } from './types';

export const mergePartialFilterWithInitialValue = (
  filterAndSortState: Partial<UrlFilterAndSortState>,
): URLHashData => {
  const {
    cargo,
    customers,
    dateFrom,
    dateTo,
    direction,
    query,
    routes,
    sort: column = INITIAL_SORT_VALUE.column,
    states,
    timeEnd,
    timeStart,
    vehicles,
  } = filterAndSortState;

  return {
    filter: {
      cargoIds: cargo ?? INITIAL_VALUE.filter.cargoIds,
      date:
        dateFrom || dateTo
          ? {
              from: dateFrom != null ? parseFilterDate(dateFrom) : undefined,
              to: dateTo != null ? parseFilterDate(dateTo) : undefined,
            }
          : INITIAL_VALUE.filter.date,
      filterStates: states ?? INITIAL_VALUE.filter.filterStates,
      query: query ?? INITIAL_VALUE.filter.query,
      time:
        timeStart || timeEnd
          ? {
              start: timeStart,
              end: timeEnd,
            }
          : INITIAL_VALUE.filter.time,
      vehicleIds: vehicles ?? INITIAL_VALUE.filter.vehicleIds,
      routeCodeIds: routes ?? INITIAL_VALUE.filter.routeCodeIds,
      customerIds: customers ?? INITIAL_VALUE.filter.customerIds,
      customerCustomsStatusIds: INITIAL_VALUE.filter.customerCustomsStatusIds,
    },
    sorting: {
      column,
      direction: direction ?? getDefaultSortDirection(column),
    },
  };
};
