import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateValues } from '../../../services/update-form/updateFormActions';
import {
  BookingFormState,
  getEditedFormState,
  getFormIsUpdating,
  getInitialFormState,
  getValidationErrors,
} from '../../../services/update-form/updateFormReducer';
import { StoreState } from '../../../store/storeState';
import { useShallowEqualSelector } from '../../hooks/useShallowEqualSelector';
import { useBookingFormInstanceId } from '../BookingFormInstanceIdProvider';
import {
  TrackerAction,
  transformUpdateFieldToTrackerAction,
} from '../../../use-cases/simple-create/hooks/UseTrackingTextInput';

interface UseUpdateFieldProps<Key extends keyof BookingFormState> {
  editedValue: BookingFormState[Key];
  initialValue: BookingFormState[Key];
  name: string;
  onChange: (value: BookingFormState[Key]) => void;
  revertChange: () => void;
  validationError?: string;
  trackerAction: TrackerAction;
}

export const useUpdateField = <Key extends keyof BookingFormState>(
  name: Key,
): UseUpdateFieldProps<Key> => {
  const formInstanceId = useBookingFormInstanceId();

  const mapState = useCallback(
    (state: StoreState) => {
      const editedFormState = getEditedFormState(state, formInstanceId);
      const initialFormState = getInitialFormState(state, formInstanceId);
      const validationErrors = getValidationErrors(state, formInstanceId);
      return {
        editedValue: editedFormState?.[name],
        initialValue: initialFormState?.[name],
        updating: getFormIsUpdating(state, formInstanceId),
        validationError: validationErrors?.[name],
      };
    },
    [formInstanceId, name],
  );

  const { editedValue, initialValue, updating, validationError } =
    useShallowEqualSelector(mapState);

  const trackerAction = transformUpdateFieldToTrackerAction(name);

  const dispatch = useDispatch();
  const onChange = useCallback<(value: BookingFormState[Key]) => void>(
    newValue => {
      if (updating || editedValue !== newValue) {
        dispatch(updateValues(formInstanceId, { [name]: newValue }));
      }
    },
    [updating, editedValue, dispatch, formInstanceId, name],
  );

  const revertChange = useCallback(
    () => () => {
      onChange(initialValue);
    },
    [initialValue, onChange],
  );

  return {
    editedValue,
    initialValue,
    name,
    onChange,
    revertChange,
    validationError,
    trackerAction,
  };
};
