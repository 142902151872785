export const sha256 = async (message: string) => {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};

export const maybeSha256 = async (message: string) => {
  if (window.isSecureContext) {
    return sha256(message);
  } else {
    return undefined;
  }
};
