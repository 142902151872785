import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Row, SeparatorLine } from '@stenajs-webui/core';
import { FlatButton, stenaArrowWideLeft } from '@stenajs-webui/elements';
import { cssColor } from '@stenajs-webui/theme';
import { Popover } from '@stenajs-webui/tooltip';
import { FC } from 'react';
import { sticky } from 'tippy.js';
import { BoxWithPrintHide } from '../../../../common-ui/BoxWithPrintHide';
import { Portal } from '../../../../common-ui/Portal';
import { zIndices } from '../../../../common/zIndices';
import { ColumnId } from '../../../../gql/graphql';
import { breakpoints } from '../../../../themes/defaultTheme';
import { getPortalRoot } from '../../grid/utils/getPortalRoot';
import { useBookingSearch } from '../hooks/useBookingSearch';
import { BookingSearchInput, WRAPPER_CLASS_NAME } from './BookingSearchInput';
import { BookingSearchList } from './BookingSearchList';

const appearAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const portalRoot = getPortalRoot();

const MobilePopup = styled(Box)`
  position: fixed;
  inset: 0;
  overflow: hidden;
  .${WRAPPER_CLASS_NAME}:focus-within {
    box-shadow: none !important;
  }
`;

const ResultWrapper = styled(Box)`
  opacity: 0;
  animation: 0.2s ${appearAnimation} 0.2s forwards;

  .${ColumnId.RouteCode}, .${ColumnId.DepartureDate}, .${ColumnId.DepartureTime} {
    display: none;
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    .${ColumnId.RouteCode}, .${ColumnId.DepartureDate}, .${ColumnId.DepartureTime} {
      display: flex;
    }
  }
`;

export const BookingSearch: FC<{ isWide: boolean }> = ({ isWide }) => {
  const {
    searchInputActive,
    invalidSearchQueryReason,
    width,
    inputValue,
    onClickSearch,
    hideSearchResult,
    onClickResult,
    onClear,
    showSearchResult,
    onInputChange,
    searchColumns,
    loading,
    data,
    onClickActiveTransports,
    onClickTransportHistory,
    error,
    searchQuery,
  } = useBookingSearch();

  const textInput = (
    <BookingSearchInput
      hasFocus={searchInputActive}
      onClickClear={onClear}
      onClickSearch={onClickSearch}
      onValueChange={onInputChange}
      onFocus={showSearchResult}
      value={inputValue}
      onEsc={hideSearchResult}
    />
  );

  const bookingSearchResults = (
    <ResultWrapper className={'t_search_overlay'} justifyContent={'center'}>
      <BookingSearchList
        searchQuery={searchQuery}
        onClickResult={onClickResult}
        invalidSearchQueryReason={invalidSearchQueryReason}
        searchColumns={searchColumns}
        data={data}
        error={error}
        loading={loading}
        onClickActiveTransports={onClickActiveTransports}
        onClickTransportHistory={onClickTransportHistory}
      />
    </ResultWrapper>
  );

  if (!isWide) {
    return (
      <Portal target={portalRoot}>
        {searchInputActive && (
          <MobilePopup zIndex={zIndices.topMenuOverlay} className={'t_search'}>
            <Box background={cssColor('--lhds-color-ui-50')} flex={1} maxHeight={'100%'}>
              <Row indent={2} spacing gap>
                <FlatButton onClick={hideSearchResult} leftIcon={stenaArrowWideLeft} />
                {textInput}
              </Row>
              <SeparatorLine />
              <Box overflowY={'auto'}>{bookingSearchResults}</Box>
            </Box>
          </MobilePopup>
        )}
      </Portal>
    );
  }

  return (
    <BoxWithPrintHide
      zIndex={zIndices.topMenuOverlay}
      className={'t_search'}
      justifyContent={'center'}
      flex={1}
    >
      <Popover
        sticky
        plugins={[sticky]}
        maxWidth={width + 'rem'}
        disablePadding
        visible={searchInputActive}
        appendTo={portalRoot}
        placement={'bottom'}
        offset={[0, 4]}
        onClickOutside={hideSearchResult}
        arrow={false}
        content={<Box minWidth={400}>{bookingSearchResults}</Box>}
      >
        {textInput}
      </Popover>
    </BoxWithPrintHide>
  );
};
