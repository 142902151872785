import { Row, Text } from '@stenajs-webui/core';
import { cssColor } from '@stenajs-webui/theme';
import { FC } from 'react';
import { InvalidSearchQueryReason } from '../../../../common/utils/stringUtil';
import { getSearchQueryInformation } from '../utils/searchQueryUtil';

interface SearchQueryInformationProps {
  invalidSearchQueryReason: InvalidSearchQueryReason | null;
}

export const SearchQueryInformation: FC<SearchQueryInformationProps> = ({
  invalidSearchQueryReason,
}) => {
  if (!invalidSearchQueryReason) {
    return null;
  }

  return (
    <Row indent spacing>
      <Text color={cssColor('--lhds-color-orange-700')} size={'small'}>
        {getSearchQueryInformation(invalidSearchQueryReason)}
      </Text>
    </Row>
  );
};
