import {
  CommonTextInputField,
  CommonTextInputFieldProps,
} from '../../../../common-ui/form-fields/CommonTextInputField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import { useCreateIntermodalField } from '../../hooks/useCreateIntermodalField';
import { CreateIntermodalFormModelStringKeys } from '../../../../services/intermodal/createIntermodalReducer';
import { useMaskInput } from '../../../../common/hooks/useMaskInput';

interface CreateIntermodalTextInputFieldProps<Key extends CreateIntermodalFormModelStringKeys>
  extends Omit<CommonTextInputFieldProps, 'onValueChange' | 'trackerAction'> {
  name: Key;
  maskInput?: (value: string) => string;
}

export function CreateIntermodalTextInputField<Key extends CreateIntermodalFormModelStringKeys>({
  name,
  maskInput,
  ...props
}: CreateIntermodalTextInputFieldProps<Key>) {
  const { onChange: onFieldChange, ...fieldProps } = useCreateIntermodalField(name);
  const onChange = useMaskInput(onFieldChange, maskInput);

  return <CommonTextInputField {...props} {...getFieldProps(props, { ...fieldProps, onChange })} />;
}
