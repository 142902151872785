import { useCallback, useContext, useMemo } from 'react';
import { useShallowEqualSelector } from '../../../common/hooks/useShallowEqualSelector';
import { useAppDispatch } from '../../../store/appDispatch';
import { StoreState } from '../../../store/storeState';
import { getCreateFormFieldsToShow } from '../../../use-cases/create/utils/createFormFieldsUtil';
import { BookingFields } from '../../../use-cases/manage/details/utils/detailsUtils';
import { CreateFormIdVariant, CreateResult, requestSubmit } from '../createFormActions';
import { CreateFormContext } from '../CreateFormProvider';
import { CreateFormState } from '../createFormReducer';
import {
  CreateValidationErrors,
  getCreateFormIsSubmitting,
  getCreateFormState,
  getCreateMeta,
  getCreateResult,
} from '../createReducer';

interface UseCreateFormState {
  fieldsToShow: BookingFields;
  formState: CreateFormState;
  initialized: boolean;
  result: CreateResult | null;
  submit: () => void;
  submitting: boolean;
  validationErrors: CreateValidationErrors;
}

const selectFormState = (formId: CreateFormIdVariant) => (state: StoreState) => ({
  formState: getCreateFormState(formId)(state),
  initialized: getCreateMeta(formId)(state).initialized,
  result: getCreateResult(formId)(state),
  submitting: getCreateFormIsSubmitting(formId)(state),
  validationErrors: getCreateMeta(formId)(state).validationErrors,
});

export const useCreateFormState = (): UseCreateFormState => {
  const formId = useContext(CreateFormContext);

  const { formState, initialized, result, submitting, validationErrors } = useShallowEqualSelector(
    selectFormState(formId),
  );

  const dispatch = useAppDispatch();

  const submit = useCallback(() => {
    dispatch(requestSubmit(formId));
  }, [dispatch, formId]);

  const fieldsToShow = useMemo(() => getCreateFormFieldsToShow(formState), [formState]);

  return {
    fieldsToShow,
    formState,
    initialized,
    result,
    submit,
    submitting,
    validationErrors,
  };
};
