import { Box, Row, Space, Txt } from '@stenajs-webui/core';
import { ResultListBanner, Spinner } from '@stenajs-webui/elements';
import {
  UPDATE_SHOW,
  UpdateSubmitInformation,
} from '../../../../common/update-information/utils/getUpdateSubmitInformation';
import { WaitListPossibleIconWithPopup } from '../../../../common/update-information/WaitListPossibleIconWithPopup';
import { cssColor } from '@stenajs-webui/theme';
import { FC } from 'react';

export interface BookingUpdateStatusMessageProps {
  onSetBookingStatusToWaitlist?: () => void;
  onIgnoreChanges?: () => void;
  submitting: boolean;
  updateSubmitInformation: UpdateSubmitInformation;
}

export const BookingUpdateStatusMessage: FC<BookingUpdateStatusMessageProps> = ({
  onSetBookingStatusToWaitlist,
  onIgnoreChanges,
  submitting,
  updateSubmitInformation,
}) => {
  if (submitting) {
    return (
      <Box
        background={cssColor('--lhds-color-blue-50')}
        borderColor={cssColor('--lhds-color-blue-500')}
        borderStyle={'solid'}
        borderWidth={'thin 0'}
        indent={3}
        minHeight={'50px'}
        spacing={1}
        alignItems={'center'}
      >
        <Spinner size={'small'} />
      </Box>
    );
  }

  switch (updateSubmitInformation.status) {
    case UPDATE_SHOW.SUCCESS:
      return (
        <ResultListBanner
          variant={'success'}
          bannerState={{
            headerText: 'Your changes were saved',
          }}
        />
      );
    case UPDATE_SHOW.INFORMATION:
      return (
        <ResultListBanner
          variant={'info'}
          bannerState={{
            headerText: 'Your changes were saved',
            items: updateSubmitInformation.messages.map(msg => ({ text: msg.description })),
          }}
        />
      );
    case UPDATE_SHOW.WARNING:
      return (
        <ResultListBanner
          variant={'warning'}
          bannerState={{
            headerText: 'Your changes were saved',
            items: updateSubmitInformation.messages.map(msg => ({ text: msg.description })),
          }}
        />
      );
    case UPDATE_SHOW.ERRORS:
      return (
        <ResultListBanner
          variant={'error'}
          bannerState={{
            headerText: 'Error',
            items: updateSubmitInformation.messages.map(msg => ({ text: msg.description })),
          }}
        />
      );
    case UPDATE_SHOW.BOOKING_OUT_OF_DATE:
      return (
        <ResultListBanner
          variant={'info'}
          bannerState={{
            headerText: 'The booking is out of date',
            items: updateSubmitInformation.messages.map(msg => ({ text: msg.description })),
          }}
        />
      );
    case UPDATE_SHOW.WAITLIST:
      return (
        <Row
          indent={2}
          spacing={2}
          background={cssColor('--lhds-color-orange-50')}
          alignItems={'center'}
        >
          <WaitListPossibleIconWithPopup
            onSetBookingStatusToWaitlist={onSetBookingStatusToWaitlist}
            onIgnoreChanges={onIgnoreChanges}
          />
          <Space num={2} />
          <Txt variant={'bold'}>
            Max capacity reached on sailing - only waitlist available for selected options
          </Txt>
        </Row>
      );
    case UPDATE_SHOW.NONE:
    default:
      return null;
  }
};
