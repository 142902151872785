import { TagSize, TextInputButtonSize } from '@stenajs-webui/elements';
import { CheckboxSize } from '@stenajs-webui/forms';
import { useSelector } from 'react-redux';
import { CompactMode, getCompactMode } from '../gridReducer';

export const COMPACT_TABLE_ROW_HEIGHT = 24;
export const NORMAL_TABLE_ROW_HEIGHT = 32;
export const SPACIOUS_TABLE_ROW_HEIGHT = 40;

export const useCompactMode = (): CompactMode => useSelector(getCompactMode);

export const getGridHeight = (compactMode: CompactMode) => {
  switch (compactMode) {
    case 'compact':
      return COMPACT_TABLE_ROW_HEIGHT;
    case 'normal':
      return NORMAL_TABLE_ROW_HEIGHT;
    case 'spacious':
      return SPACIOUS_TABLE_ROW_HEIGHT;
    default:
      return NORMAL_TABLE_ROW_HEIGHT;
  }
};

export const useGridHeight = () => {
  const compactMode = useCompactMode();
  return getGridHeight(compactMode);
};

export const useGridTextInputButtonSize = (): TextInputButtonSize => {
  const compactMode = useCompactMode();
  return compactMode === 'compact' ? 'small' : 'medium';
};

export const getGridCheckboxSize = (compactMode: CompactMode): CheckboxSize => {
  if (compactMode === 'compact') {
    return 'small';
  }
  return 'standard';
};

export const useGridCheckboxSize = (): CheckboxSize => {
  const compactMode = useCompactMode();
  return getGridCheckboxSize(compactMode);
};

export const getGridTagSize = (compactMode: CompactMode): TagSize => {
  if (compactMode === 'compact') {
    return 'small';
  }

  return 'medium';
};

export const useGridTagSize = (): TagSize => {
  const compactMode = useCompactMode();
  return getGridTagSize(compactMode);
};
