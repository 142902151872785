import { Box, Row, Txt, useBoolean } from '@stenajs-webui/core';
import {
  Card,
  CardBody,
  CardHeader,
  FlatButton,
  Link,
  PrimaryButton,
  SecondaryButton,
  stenaCookies,
} from '@stenajs-webui/elements';
import { WindowFooter } from '@stenajs-webui/modal';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { globalCardWrapperIndent } from '../../globalStyles';
import { getCookieConsent, updateConsent } from '../../services/cookie-consent/slice';
import { useAppDispatch } from '../../store/appDispatch';
import { COOKIE_INFORMATION_URL } from '../footer/Footer';
import { ConsentMode, updateConsentMode } from '../tracking/ga4';
import { zIndices } from '../zIndices';

import { ManageCookies } from './ManageCookies';

export const CookieConsentPopup = () => {
  const consent = useSelector(getCookieConsent);
  const dispatch = useAppDispatch();
  const [isSettingsOpen, openSettings, closeSettings] = useBoolean(false);
  const [allowAnalytics, setAllowAnalytics] = useState((consent ?? 'granted') === 'granted');

  if (consent != null) {
    return null;
  }

  const resetPopup = () => {
    closeSettings();
    setAllowAnalytics(true);
  };

  const onClickConsentMode = (value: ConsentMode) => {
    updateConsentMode(value);
    dispatch(updateConsent(value));
    resetPopup();
  };

  return (
    <Box
      background={'var(--swui-overlay-bg-color)'}
      style={{ inset: 0 }}
      zIndex={zIndices.popoverOverlay}
      position={'fixed'}
      className={'cookie cookie-consent'}
    >
      <Box
        indent={globalCardWrapperIndent}
        spacing={globalCardWrapperIndent}
        right={0}
        bottom={0}
        maxWidth={'100%'}
        width={600}
        position={'absolute'}
      >
        {!isSettingsOpen ? (
          <Card>
            <CardHeader leftIcon={stenaCookies} text={'Our use of cookies'} />
            <CardBody gap={2}>
              <Txt>
                We use necessary cookies to make our site work. We would also like to use cookies
                for analytics that help us improve our site. These will only be set if you accept.
              </Txt>
              <Link href={COOKIE_INFORMATION_URL} target={'_blank'}>
                Read more in our Cookie information
              </Link>
            </CardBody>
            <WindowFooter>
              <Box gap flexDirection={['column', 'row']} justifyContent={'space-between'}>
                <FlatButton label={'Manage cookies'} onClick={openSettings} />
                <Box gap flexDirection={['column', 'row']}>
                  <SecondaryButton
                    label={'Only necessary'}
                    onClick={() => {
                      onClickConsentMode('denied');
                    }}
                  />
                  <PrimaryButton
                    className={'t_accept_all'}
                    label={'Accept all cookies'}
                    onClick={() => {
                      onClickConsentMode('granted');
                    }}
                  />
                </Box>
              </Box>
            </WindowFooter>
          </Card>
        ) : (
          <Card>
            <CardHeader leftIcon={stenaCookies} text={'Manage cookies'} />
            <CardBody>
              <ManageCookies consent={allowAnalytics} onToggleConsent={setAllowAnalytics} />
            </CardBody>
            <WindowFooter>
              <Row gap justifyContent={'space-between'}>
                <FlatButton label={'Close'} onClick={closeSettings} />
                <PrimaryButton
                  label={'Save settings'}
                  onClick={() => {
                    onClickConsentMode(allowAnalytics ? 'granted' : 'denied');
                  }}
                />
              </Row>
            </WindowFooter>
          </Card>
        )}
      </Box>
    </Box>
  );
};
