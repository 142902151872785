import { Column, Txt } from '@stenajs-webui/core';
import { Banner, CardBody, Link, PrimaryButton, stenaCheck } from '@stenajs-webui/elements';
import { FC } from 'react';
import { TrackerCategory, trackEvent } from '../../../../common/tracking/trackerService';
import { useCustomsLinkTokenLoader } from '../../hooks/useCustomsLinkTokenLoader';
import { ModalHeader } from '../../../manage/details/components/modal/ModalHeader';

interface Props {
  bookingNo: number;
  trackerCategory: TrackerCategory;
  onClose: () => void;
}

export const CustomsLinkGdprModal: FC<Props> = ({ onClose, bookingNo, trackerCategory }) => {
  const { fetchTokenAndRedirect, acceptGdpr, loaderState } = useCustomsLinkTokenLoader(bookingNo);

  const onClickCustomsLink = async () => {
    if (await acceptGdpr()) {
      trackEvent({
        category: trackerCategory,
        action: 'Accepted CustomsLink GDPR',
      });

      if (await fetchTokenAndRedirect(bookingNo)) {
        onClose();
      }
    }
  };

  return (
    <Column width={450}>
      <ModalHeader onRequestClose={onClose} headerText={'Easy customs handling at CustomsLink'} />
      <CardBody gap={2}>
        <Txt>
          Sign up at Stena Line's partner CustomsLink to speed up and keep track of your customs
          process.
        </Txt>
        <Txt>
          Get your Goods movement reference (GMR) or Pre-boarding notification (PBN) and Portbase
          Notification Export Documentation (NED) in minutes.
        </Txt>
        <Txt size={'small'}>
          When you sign up, Stena Line will send your email and account name to CustomsLink. For
          more information, see the{' '}
          <Link
            href="https://www.customs-link.com/sites/default/files/2022-06/CustomsLink_Terms_and_Conditions.pdf"
            target={'_blank'}
            size={'small'}
          >
            Terms and conditions
          </Link>{' '}
          and{' '}
          <Link href="https://www.customs-link.com/privacy-policy" target={'_blank'} size={'small'}>
            Privacy Policy
          </Link>
          .
        </Txt>
        <Column gap={2} alignItems={[null, 'flex-start']}>
          <PrimaryButton
            label={'Sign up and go to CustomsLink'}
            leftIcon={stenaCheck}
            onClick={onClickCustomsLink}
            loading={loaderState?.name === 'loading'}
            loadingLabel={'Contacting CustomsLink'}
          />
          {loaderState?.name === 'error' && <Banner variant={'error'} text={loaderState.error} />}
        </Column>
      </CardBody>
    </Column>
  );
};
