export const maskTemperatureInput = (value: string): string =>
  value.replace(/[^a-zA-Z0-9-+/\\.,]/g, '');

export const resolveTemperatureText = (noOfPlugins: number, pluginTemperature: string): string => {
  if (pluginTemperature) {
    return `${pluginTemperature} °C`;
  }
  if (noOfPlugins > 0) {
    return 'Yes';
  } else {
    return '';
  }
};

export const shouldShowPluginInformationText = (
  noOfPlugins: number,
  pluginTemperature: string,
): boolean => {
  return noOfPlugins > 0 && pluginTemperature === '';
};
