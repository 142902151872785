import styled from '@emotion/styled';
import { Column, Row } from '@stenajs-webui/core';
import { FC } from 'react';
import logo from '../../common-ui/images/logo_blue_text.svg';
import { theme } from '../../themes/theme';
import { Colors } from '../colors';
import { Footer } from '../footer/Footer';
import { routeCreators } from '../routes/AppRoutes';
import { ErrorCard, ErrorCardProps } from './ErrorCard';

interface ErrorPageProps extends ErrorCardProps {}

const StyledHeader = styled('div')`
  background-color: ${Colors.UI8};
  border-bottom: 4px solid ${Colors.Freight2};
`;

const StyledLogo = styled('div')`
  margin-left: ${theme.metrics.indent * 2}px;
  margin-top: ${theme.metrics.spacing}px;
  width: 150px;
`;

export const ErrorPage: FC<ErrorPageProps> = ({ content, title }) => (
  <Column minHeight={'100dvh'} minWidth={'100dvw'}>
    <StyledHeader>
      <Row indent={2}>
        <StyledLogo>
          <a href={routeCreators.bookings()}>
            <img src={logo} alt={'Stena Line'} style={{ width: 150 }} />
          </a>
        </StyledLogo>
      </Row>
    </StyledHeader>
    <Column alignItems="center" indent={2} spacing={2} flexGrow={1}>
      <ErrorCard title={title} content={content} />
    </Column>
    <Footer />
  </Column>
);
