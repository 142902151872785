import { SettingsUpdateResult } from '../../../services/settings/settingsReducer';

export type FormStatus = 'idle' | 'submitting' | 'success' | 'error';

export const resolveSettingsFormStatus = (
  settingsResult: SettingsUpdateResult | null | undefined,
  submitting: boolean,
): FormStatus => {
  if (submitting) {
    return 'submitting';
  }

  if (!settingsResult) {
    return 'idle';
  }

  if (settingsResult.result?.success) {
    return 'success';
  }

  return 'error';
};
