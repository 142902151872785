import { useSuspenseQuery } from '@apollo/client';
import { allIntermodalRoutesQuery } from '../../services/intermodal/graphql/allIntermodalRoutesQuery';

export const useAllIntermodalRoutes = () => {
  const { data, error } = useSuspenseQuery(allIntermodalRoutesQuery);

  return {
    allIntermodalRoutes: data?.viewer?.intermodalRoutes.allRoutes ?? [],
    error,
  };
};
