export type HealthCheckAction = FailAction;

export interface FailAction {
  payload: {};
  type: 'HEALTH_CHECK:FAIL';
}

export const healthCheckFail = (): FailAction => ({
  payload: {},
  type: 'HEALTH_CHECK:FAIL',
});
