import {
  RegistrationNumberAlertFormState,
  TravelledVehicleType,
} from '../../../services/update-form/registrationNumberAlert';
import { VehicleType } from '../../graphql/fragments/gql/VehicleType';
import { TravelledVehicle } from '../../graphql/fragments/gql/TravelledVehicle';

export const getAppliedDimension = (
  dimension: number,
  previousDimension: number | undefined,
): boolean => dimension !== 0 && dimension !== previousDimension;

export const registrationNumberAlertEqualsValue = (
  registrationNumberAlert: RegistrationNumberAlertFormState | undefined,
  field: FieldName,
  editedValue: number | undefined,
) => {
  if (
    (field === 'length' || field === 'height') &&
    registrationNumberAlert?.result &&
    editedValue
  ) {
    return registrationNumberAlert.result[field] === editedValue;
  } else {
    return false;
  }
};

export function getRegistrationNumberAlertFormStateKey(
  field: FieldName,
): keyof Partial<
  Pick<
    RegistrationNumberAlertFormState,
    'appliedLength' | 'appliedHeight' | 'appliedCargoWeight' | 'appliedLoadingListMessage'
  >
> {
  switch (field) {
    case 'length':
      return 'appliedLength';
    case 'height':
      return 'appliedHeight';
    case 'cargoWeight':
      return 'appliedCargoWeight';
    case 'loadingNote':
      return 'appliedLoadingListMessage';
  }
}

export type FieldName = 'length' | 'height' | 'cargoWeight' | 'loadingNote';
export type ReadableFieldName = 'length' | 'height' | 'cargo weight' | 'loading note';

export function getReadableFieldName(field: FieldName): ReadableFieldName {
  switch (field) {
    case 'length':
      return 'length';
    case 'height':
      return 'height';
    case 'cargoWeight':
      return 'cargo weight';
    case 'loadingNote':
      return 'loading note';
  }
}

export function getRegistrationNumberAlertAppliedProps(
  field: FieldName,
  registrationNumberAlert: RegistrationNumberAlertFormState | null | undefined,
): { showArrivalNotepad: boolean; showTravelledVehicle: boolean; arrivalNotepadApplied: boolean } {
  const arrivalNotepadApplied = Boolean(registrationNumberAlert?.appliedArrivalNotepad);

  if (field === 'length') {
    const showArrivalNotepad =
      arrivalNotepadApplied && Boolean(registrationNumberAlert?.appliedLength);
    const showTravelledVehicle = Boolean(registrationNumberAlert?.appliedLength);

    return {
      showArrivalNotepad,
      showTravelledVehicle,
      arrivalNotepadApplied,
    };
  }

  if (field === 'height') {
    const showArrivalNotepad =
      arrivalNotepadApplied && Boolean(registrationNumberAlert?.appliedHeight);
    const showTravelledVehicle = Boolean(registrationNumberAlert?.appliedHeight);

    return {
      showArrivalNotepad,
      showTravelledVehicle,
      arrivalNotepadApplied,
    };
  }

  if (field === 'cargoWeight') {
    const showArrivalNotepad =
      arrivalNotepadApplied && Boolean(registrationNumberAlert?.appliedCargoWeight);
    const showTravelledVehicle = Boolean(registrationNumberAlert?.appliedCargoWeight);

    return {
      showArrivalNotepad,
      showTravelledVehicle,
      arrivalNotepadApplied,
    };
  }

  if (field === 'loadingNote') {
    const showArrivalNotepad =
      arrivalNotepadApplied && Boolean(registrationNumberAlert?.appliedLoadingListMessage);
    const showTravelledVehicle = Boolean(registrationNumberAlert?.appliedLoadingListMessage);

    return {
      showArrivalNotepad,
      showTravelledVehicle,
      arrivalNotepadApplied,
    };
  }

  return {
    showArrivalNotepad: false,
    showTravelledVehicle: false,
    arrivalNotepadApplied,
  };
}

export const hasArrivalNotepad = (
  registrationNumberAlert: RegistrationNumberAlertFormState | undefined | null,
  travelledVehicleType: TravelledVehicleType,
): boolean =>
  Boolean(
    registrationNumberAlert?.appliedArrivalNotepad &&
      registrationNumberAlert.travelledVehicleType === travelledVehicleType,
  );

interface TravelledVehicleRegistrationNumberAlertFormState
  extends RegistrationNumberAlertFormState {
  result: TravelledVehicle;
  vehicleType: VehicleType;
}

export const hasTravelledVehicle = (
  registrationNumberAlert: RegistrationNumberAlertFormState | undefined | null,
  travelledVehicleType: TravelledVehicleType,
  vehicleType: VehicleType | null | undefined,
): registrationNumberAlert is TravelledVehicleRegistrationNumberAlertFormState =>
  Boolean(
    registrationNumberAlert &&
      registrationNumberAlert.travelledVehicleType === travelledVehicleType &&
      registrationNumberAlert.vehicleType &&
      vehicleType &&
      vehicleType.code !== registrationNumberAlert.vehicleType.code &&
      registrationNumberAlert.vehicleType &&
      registrationNumberAlert.result,
  );
export const getRegistrationNumberAlertProps = (
  registrationNumberAlert: RegistrationNumberAlertFormState | undefined | null,
  travelledVehicleType: TravelledVehicleType,
  vehicleType: VehicleType | null | undefined,
): {
  hasAppliedTravelledVehicle: boolean;
  hasArrivalNotepad: boolean;
  hasTravelledVehicle: boolean;
} => {
  return {
    hasAppliedTravelledVehicle: Boolean(registrationNumberAlert?.appliedTravelledVehicle),
    hasTravelledVehicle: hasTravelledVehicle(
      registrationNumberAlert,
      travelledVehicleType,
      vehicleType,
    ),
    hasArrivalNotepad: hasArrivalNotepad(registrationNumberAlert, travelledVehicleType),
  };
};
