import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Options } from 'react-select';
import { graphql } from '../../gql';
import { AnimalTypesQueryQuery } from '../../gql/graphql';
import {
  Animal,
  DropdownOption,
  transformAnimalOption,
} from '../../use-cases/manage/grid/utils/optionTransformers';
import { QueryResult } from './types/QueryResult';

export const animalTypesQuery = graphql(/*GraphQL*/ `
  query AnimalTypesQuery {
    viewer {
      animalTypes {
        items {
          label
          value
        }
      }
    }
  }
`);

export const animalDropdownNoOption: DropdownOption<Animal> = {
  data: { label: 'No', value: 'null' },
  label: 'No',
  value: 'null',
};

export const animalDropdownYesOption: DropdownOption<Animal> = {
  data: { label: 'Yes', value: 'yes' },
  label: 'Yes',
  value: 'yes',
};

function transformOptions(
  data: AnimalTypesQueryQuery | undefined,
  includeNoOption: boolean,
  includeYesOption: boolean,
): Options<DropdownOption<Animal>> {
  if (data?.viewer?.animalTypes?.items) {
    const options: DropdownOption<Animal>[] = [];
    if (includeYesOption) {
      options.push(animalDropdownYesOption);
    }
    if (includeNoOption) {
      options.push(animalDropdownNoOption);
    }
    options.push(...data.viewer.animalTypes.items.map(transformAnimalOption));
    return options;
  } else {
    return [];
  }
}

export const useAnimalTypesOptions = (
  includeNoOption: boolean,
  includeYesOption: boolean,
  skip: boolean = false,
): QueryResult<Options<DropdownOption<Animal>>> => {
  const { data, loading, error } = useQuery(animalTypesQuery, {
    fetchPolicy: 'cache-first',
    skip,
  });

  return useMemo(
    () => [transformOptions(data, includeNoOption, includeYesOption), loading, error],
    [data, error, includeNoOption, includeYesOption, loading],
  );
};
