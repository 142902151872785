import { FC } from 'react';
import { useUpdateIntermodalGoodsField } from '../hooks/useUpdateIntermodalGoodsFields';
import {
  ValidationWrapper,
  ValidationWrapperProps,
} from '../../../common-ui/form-fields/ValidationWrapper';
import {
  UpdateIntermodalBookingFormModel,
  UpdateIntermodalBookingGoodFormModel,
} from '../../../services/intermodal/updateIntermodalReducer';
import { useUpdateIntermodalField } from '../hooks/useUpdateIntermodalField';

interface UpdateIntermodalValidationWrapperProps
  extends Omit<ValidationWrapperProps, 'validationError'> {
  name: keyof UpdateIntermodalBookingFormModel;
}

export const UpdateIntermodalValidationWrapper: FC<UpdateIntermodalValidationWrapperProps> = ({
  name,
  ...props
}) => {
  const { validationError } = useUpdateIntermodalField(name);
  return <ValidationWrapper validationError={validationError} {...props} />;
};

interface UpdateIntermodalGoodsValidationWrapperProps
  extends Omit<ValidationWrapperProps, 'validationError'> {
  name: keyof UpdateIntermodalBookingGoodFormModel;
  goodId: string;
}

export const UpdateIntermodalGoodsValidationWrapper: FC<
  UpdateIntermodalGoodsValidationWrapperProps
> = ({ name, goodId, ...props }) => {
  const { validationError } = useUpdateIntermodalGoodsField(goodId, name);
  return <ValidationWrapper validationError={validationError} {...props} />;
};
