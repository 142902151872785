import { graphql } from '../../gql';

export const allRoutesQuery = graphql(/*GraphQL*/ `
  query AllRoutesQuery {
    viewer {
      routes {
        allRoutes {
          ...Route
        }
      }
    }
  }
`);
