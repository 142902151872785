import { all, put, select, takeEvery } from 'redux-saga/effects';
import { getAppliedDimension } from '../../../common/registrationNumberAlert/utils/registrationNumberAlertUtil';
import { getNumberOfDrivers } from '../../create/utils/transformCreateBookingResults';
import {
  UpdateBookingFormRestoreDimensionAction,
  UpdateBookingFormSetVehicleTypeAction,
} from '../travelledVehicleActions';
import { setRegistrationNumberAlert, updateValues } from '../updateFormActions';
import { getEditedFormState, getRegistrationNumberAlert } from '../updateFormReducer';
import { getVehicleTypeDimension } from '../../../common/vehicle-types/vehicleType';

export function* handleSetVehicleType(action: UpdateBookingFormSetVehicleTypeAction) {
  const formInstanceId = action.payload.id;

  const previousRegistrationNumberAlert: ReturnType<typeof getRegistrationNumberAlert> =
    yield select(getRegistrationNumberAlert, formInstanceId);

  if (previousRegistrationNumberAlert?.result && previousRegistrationNumberAlert.vehicleType) {
    const { result, vehicleType } = previousRegistrationNumberAlert;

    // The user sets the matching type to TravelledVehicle, apply dimensions in result.
    const appliedHeight = getAppliedDimension(result.height, vehicleType?.defaultHeight);
    const appliedLength = getAppliedDimension(result.length, vehicleType?.defaultLength);

    const defaultNoOfDrivers = getNumberOfDrivers(vehicleType);

    const registrationNumberAlert = {
      ...previousRegistrationNumberAlert,
      appliedHeight,
      appliedLength,
    };

    yield put(setRegistrationNumberAlert(formInstanceId, registrationNumberAlert));

    yield put(
      updateValues(formInstanceId, {
        height: appliedHeight ? result.height : vehicleType?.defaultHeight,
        length: appliedLength ? result.length : vehicleType?.defaultLength,
        noOfDrivers: defaultNoOfDrivers,
        tradeWeight: vehicleType?.defaultTradeWeight,
        vehicleType,
        width: vehicleType?.defaultWidth,
      }),
    );
  }
}

function* handleRestoreDimension(action: UpdateBookingFormRestoreDimensionAction) {
  const { dimensionName, id: formInstanceId } = action.payload;

  const state: ReturnType<typeof getEditedFormState> = yield select(
    getEditedFormState,
    formInstanceId,
  );

  const registrationNumberAlert: ReturnType<typeof getRegistrationNumberAlert> = yield select(
    getRegistrationNumberAlert,
    formInstanceId,
  );

  if (state?.vehicleType && registrationNumberAlert) {
    yield put(
      setRegistrationNumberAlert(formInstanceId, {
        ...registrationNumberAlert,
        appliedHeight: dimensionName === 'height' ? false : registrationNumberAlert.appliedHeight,
        appliedLength: dimensionName === 'length' ? false : registrationNumberAlert.appliedLength,
      }),
    );
    yield put(
      updateValues(formInstanceId, {
        [dimensionName]: getVehicleTypeDimension(state.vehicleType, dimensionName),
      }),
    );
  }
}

export function* watchTravelledVehicle() {
  yield all([
    takeEvery('UPDATE_FORM:TRAVELLED_VEHICLE:SET_VEHICLE_TYPE', handleSetVehicleType),
    takeEvery('UPDATE_FORM:TRAVELLED_VEHICLE:RESTORE_DIMENSION', handleRestoreDimension),
  ]);
}
