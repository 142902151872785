import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { routeCreators } from '../routes/AppRoutes';
import { trackPageView } from './trackerService';

export const useTrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path && path !== '/' && path !== routeCreators.callback()) {
      trackPageView(path);
    }
  }, [location.pathname]);
};
