import { Box, Column, Row } from '@stenajs-webui/core';
import { FlatButton, Icon, stenaArrowRightAngle, stenaMinusCircle } from '@stenajs-webui/elements';
import { Select } from '@stenajs-webui/select';
import { FC, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import {
  CreateIntermodalBookingGoodFormModel,
  removeCreateIntermodalBookingGood,
} from '../../../services/intermodal/createIntermodalReducer';
import { GoodsTypesQueryRefProps } from '../../../services/intermodal/hooks/useGoodsTypes';
import { IntermodalDetailsFieldCell } from '../common/IntermodalDetailsFieldCell';
import { CreateIntermodalGoodsValidationWrapper } from './CreateIntermodalValidationWrapper';
import { CreateGoodsTypeDropdown } from './fields/CreateGoodsTypeDropdown';
import { CreateGoodsWeightInput } from './fields/CreateGoodsWeightInput';
import { MAX_LENGTH_GOODS_WEIGHT } from '../utils/fieldLengths';

interface Props extends GoodsTypesQueryRefProps {
  value: CreateIntermodalBookingGoodFormModel;
  editable: boolean;
}

export const CreateBookingGoodsItem: FC<Props> = ({ value, editable, goodsTypesQueryRef }) => {
  const dispatch = useDispatch();
  return (
    <Column gap={2}>
      <CreateIntermodalGoodsValidationWrapper
        name={'goodsType'}
        goodId={value.id}
        renderField={isValid => (
          <IntermodalDetailsFieldCell
            label="Goods description"
            children={
              <Suspense fallback={<Select isLoading />}>
                <CreateGoodsTypeDropdown
                  isValid={isValid}
                  goodsId={value.id}
                  trackerCategory={'IntermodalCreate'}
                  goodsTypesQueryRef={goodsTypesQueryRef}
                />
              </Suspense>
            }
          />
        )}
      />
      <CreateIntermodalGoodsValidationWrapper
        name={'weight'}
        goodId={value.id}
        renderField={isValid => (
          <Row alignItems={'end'} gap>
            <Box style={{ paddingBottom: 6 }}>
              <Icon className={'bottom'} size={20} icon={stenaArrowRightAngle} />
            </Box>
            <IntermodalDetailsFieldCell label={'Weight (kg)'} flex={1}>
              <CreateGoodsWeightInput
                goodsId={value.id}
                maxLength={MAX_LENGTH_GOODS_WEIGHT}
                isValid={isValid}
                trackerCategory={'IntermodalCreate'}
              />
            </IntermodalDetailsFieldCell>
            {editable ? (
              <FlatButton
                variant={'danger'}
                leftIcon={stenaMinusCircle}
                onClick={() => {
                  dispatch(removeCreateIntermodalBookingGood({ id: value.id }));
                }}
              />
            ) : null}
          </Row>
        )}
      />
    </Column>
  );
};
