import { graphql } from '../../../gql';

export const bookingsFilterQuery = graphql(/*GraphQL*/ `
  query BookingsFilterQuery(
    $filter: BookingsFilter
    $sort: BookingsSortColumns
    $first: Int!
    $offset: Int!
  ) {
    viewer {
      bookings {
        byFilter(filter: $filter, sort: $sort, first: $first, offset: $offset) {
          count
          bookings: items {
            ...BasicBooking
          }
          searchChips {
            count
            id
          }
        }
      }
    }
  }
`);
