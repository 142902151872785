import { TrackerCategory, trackEvent } from '../../tracking/trackerService';
import { Placement } from '../../../common-ui/popover/FreightPortalPopover';
import { TextInputButtonSize } from '@stenajs-webui/elements';
import { useAppDispatch } from '../../../store/appDispatch';
import { useBookingFormInstanceId } from '../../update-information/BookingFormInstanceIdProvider';
import { useSelector } from 'react-redux';
import { ReactNode, useCallback } from 'react';
import { StoreState } from '../../../store/storeState';
import {
  FormInstanceId,
  getRegistrationNumberAlert,
} from '../../../services/update-form/updateFormReducer';
import {
  FieldName,
  getReadableFieldName,
  getRegistrationNumberAlertAppliedProps,
  getRegistrationNumberAlertFormStateKey,
  registrationNumberAlertEqualsValue,
} from '../utils/registrationNumberAlertUtil';
import { updateRegistrationNumberAlert } from '../../../services/update-form/updateFormActions';
import { renderRegistrationNumberAlertAppliedIcon } from '../../../common-ui/information-message/renderRegistrationNumberAlertAppliedIcon';
import { restoreDimensionFromTravelledVehicle } from '../../../services/update-form/travelledVehicleActions';
import { Dispatch } from 'redux';
import { RegistrationNumberAlertFormState } from '../../../services/update-form/registrationNumberAlert';

interface UseRegistrationNumberAlertApplied {
  icon: ReactNode | null;
  arrivalNotepadApplied: boolean;
}

function getUpdateRegistrationNumberAlertApplied(
  trackerCategory: TrackerCategory,
  field: FieldName,
  dispatch: Dispatch,
  formInstanceId: FormInstanceId,
  registrationNumberAlert: RegistrationNumberAlertFormState | undefined,
  editedValue: number | undefined,
  iconOptions: { placement?: Placement; buttonSize?: TextInputButtonSize } | undefined,
) {
  const onHideMessage = () => {
    trackEvent({
      category: trackerCategory,
      action: `TravelledVehicle field - Hide ${field} alert`,
      label: field,
    });
    dispatch(
      updateRegistrationNumberAlert(formInstanceId, {
        [getRegistrationNumberAlertFormStateKey(field)]: false,
      }),
    );
  };
  const onRestore = () => {
    if (field === 'length' || field === 'height') {
      trackEvent({
        category: trackerCategory,
        action: `TravelledVehicle field - Restore dimensions`,
        label: getReadableFieldName(field),
      });
      dispatch(restoreDimensionFromTravelledVehicle(formInstanceId, field));
    }
  };
  const onIconClick = (type: string) => {
    trackEvent({
      category: trackerCategory,
      action: `${type} alert icon clicked`,
      label: getReadableFieldName(field),
    });
  };

  const { showTravelledVehicle, showArrivalNotepad, arrivalNotepadApplied } =
    getRegistrationNumberAlertAppliedProps(field, registrationNumberAlert);

  return {
    icon: renderRegistrationNumberAlertAppliedIcon({
      showTravelledVehicle:
        showTravelledVehicle &&
        registrationNumberAlertEqualsValue(registrationNumberAlert, field, editedValue),
      showArrivalNotepad,
      onIconClick,
      onHideMessage,
      onRestore,
      fieldName: getReadableFieldName(field),
      ...iconOptions,
    }),
    arrivalNotepadApplied,
  };
}

export const useUpdateRegistrationNumberAlertApplied = (
  trackerCategory: TrackerCategory,
  field: FieldName,
  editedValue: number | undefined,
  iconOptions?: { placement?: Placement; buttonSize?: TextInputButtonSize } | undefined,
): UseRegistrationNumberAlertApplied => {
  const dispatch = useAppDispatch();
  const formInstanceId = useBookingFormInstanceId();
  const registrationNumberAlert = useSelector(
    useCallback(
      (state: StoreState) => getRegistrationNumberAlert(state, formInstanceId),
      [formInstanceId],
    ),
  );

  return getUpdateRegistrationNumberAlertApplied(
    trackerCategory,
    field,
    dispatch,
    formInstanceId,
    registrationNumberAlert,
    editedValue,
    iconOptions,
  );
};
