import { Box, Column, Heading, Row, Space, Txt } from '@stenajs-webui/core';
import { Icon, stenaEmptyRead } from '@stenajs-webui/elements';
import { cssColor } from '@stenajs-webui/theme';
import { ReactNode } from 'react';

export const EmptyNotificationsContent = ({ label }: { label: string }) => (
  <Box gap={2} indent={8} spacing={8} alignItems={'center'}>
    <Icon size={20} icon={stenaEmptyRead} color={cssColor('--lhds-color-ui-500')} />
    <Txt>{label}</Txt>
  </Box>
);

export const NotificationGroup = ({
  heading,
  children,
}: {
  heading: string;
  children: ReactNode;
}) => (
  <Column>
    <Space num={2} />
    <Row indent={3} spacing>
      <Heading variant={'h5'}>{heading}</Heading>
    </Row>
    {children}
  </Column>
);
