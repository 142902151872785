import { ComponentPropsWithoutRef, FC, useEffect } from 'react';
import { clear, peek, suspend } from '../vendor/suspend-react';

interface Props extends ComponentPropsWithoutRef<'img'> {
  src: string;
}

export const AsyncImage: FC<Props> = ({ src, alt, ...props }) => {
  suspend(async () => {
    return new Promise<boolean>(resolve => {
      const img = document.createElement('img');
      img.src = src;

      img.onload = () => {
        resolve(true);
      };
      img.onerror = () => {
        resolve(false);
      };
    });
  }, ['async-image', src]);

  useEffect(() => {
    return () => {
      clear(['async-image', src]);
    };
  }, [src]);

  if (!peek(['async-image', src])) {
    return null;
  }

  return <img {...props} alt={alt} src={src} />;
};
