import { modalRouteCreators } from '../routes/AppRoutes';
import { useShowModal } from './useShowModal';

export const useShowBookingDetails = () => {
  const { replaceModal, pushModal } = useShowModal();

  const pushBookingDetailsModal = (bookingNo: number, editing: boolean = false) => {
    const editState = editing ? 'edit' : 'view';

    pushModal(modalRouteCreators.bookingDetails, { bookingNo, editState });
  };

  const replaceBookingDetailsModal = (bookingNo: number, editing: boolean = false) => {
    const editState = editing ? 'edit' : 'view';

    replaceModal(modalRouteCreators.bookingDetails, { bookingNo, editState });
  };

  return { pushBookingDetailsModal, replaceBookingDetailsModal };
};
