import { useBoolean } from '@stenajs-webui/core';
import { NavBarNotificationButton } from '@stenajs-webui/panels';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Portal } from '../../../common-ui/Portal';
import { trackEvent } from '../../../common/tracking/trackerService';
import {
  getBookingResultNotification,
  getUrgentMessageNotification,
  getUrgentMessageReadIds,
} from '../../../services/notifications/notificationsReducer';
import { getPortalRoot } from '../../manage/grid/utils/getPortalRoot';
import { Toaster } from '../toasts/Toaster';
import { getSortedNotifications } from '../utils/getSortedNotifications';
import { getUnreadCount } from '../utils/getUnreadCount';
import { NotificationCenter } from './NotificationCenter';

interface NotificationIconProps {
  unreadCount: number;
  onClick: () => void;
  className?: string;
}

const NotificationIcon: FC<NotificationIconProps> = ({ unreadCount, onClick, className }) => (
  <NavBarNotificationButton
    className={className}
    count={unreadCount}
    unread={unreadCount > 0}
    onClick={onClick}
  />
);

export type Tabs = 'bookingInformation' | 'urgentMessage';

export interface NotificationCenterIconProps {}

export const NotificationCenterIcon: FC<NotificationCenterIconProps> = () => {
  const urgentMessageNotifications = useSelector(getUrgentMessageNotification);
  const bookingResultNotifications = useSelector(getBookingResultNotification);

  const sortedUrgentMessageNotifications = getSortedNotifications(urgentMessageNotifications);
  const sortedBookingResultNotifications = getSortedNotifications(bookingResultNotifications);
  const readIds = useSelector(getUrgentMessageReadIds);

  const unreadCount = getUnreadCount(sortedUrgentMessageNotifications, readIds);
  const [isOpen, setOpen, setClosed] = useBoolean(false);

  const setOpenWithTracking = () => {
    trackEvent({
      category: 'NotificationCenter',
      action: 'Open notification center',
    });

    setOpen();
  };

  const setClosedWithTracking = () => {
    trackEvent({
      category: 'NotificationCenter',
      action: 'Close notification center',
    });

    setClosed();
  };

  return (
    <>
      <NotificationIcon
        unreadCount={unreadCount}
        className={'tour_notification_center'}
        onClick={setOpenWithTracking}
      />
      <Portal target={getPortalRoot()}>
        <Toaster disabled={isOpen} />
      </Portal>
      <NotificationCenter
        isOpen={isOpen}
        onClose={setClosedWithTracking}
        urgentMessageNotifications={sortedUrgentMessageNotifications}
        bookingResultNotifications={sortedBookingResultNotifications}
        readIds={readIds}
      />
    </>
  );
};
