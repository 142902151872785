import { Row } from '@stenajs-webui/core';
import { PrimaryButton } from '@stenajs-webui/elements';
import { FC } from 'react';
import { RenderFooterProps } from './Tour';

interface FooterProps extends RenderFooterProps {}

export const SkipFooter: FC<FooterProps> = ({ onNext, nextRef }) => {
  return (
    <Row justifyContent={'end'} flex={1}>
      <PrimaryButton
        id={'t_introduction_tour_skip_ok'}
        onClick={onNext}
        ref={nextRef}
        label={'OK'}
      />
    </Row>
  );
};
