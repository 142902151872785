import { RegistrationNumberAlertFormState } from '../../../services/update-form/registrationNumberAlert';

export type ArrivalNotepadPropsKeys = Array<ArrivalNotepadProps>;

export type ArrivalNotepadProps =
  | 'vehicleType'
  | 'height'
  | 'length'
  | 'loadingListMessage'
  | 'cargoWeight'
  | 'hazardousGoods'
  | 'vehicleRegNo';

export const getKeysToChangeForArrivalNotepad = (
  registrationNumberAlert: RegistrationNumberAlertFormState,
): ArrivalNotepadPropsKeys => {
  const keys: ArrivalNotepadPropsKeys = [];
  if (registrationNumberAlert.appliedArrivalNotepad) {
    keys.push('vehicleRegNo');
    keys.push('vehicleType');
    if (registrationNumberAlert.appliedLength) {
      keys.push('length');
    }
    if (registrationNumberAlert.appliedHeight) {
      keys.push('height');
    }
    if (registrationNumberAlert.appliedLoadingListMessage) {
      keys.push('loadingListMessage');
    }
    if (registrationNumberAlert.appliedCargoWeight) {
      keys.push('cargoWeight');
    }
    if (registrationNumberAlert.appliedHazardousGoods) {
      keys.push('hazardousGoods');
    }
  }

  return keys;
};
