import { Link } from '@stenajs-webui/elements';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { TrackerCategory, trackEvent } from '../../common/tracking/trackerService';
import { resolveCustomsDocumentationEnsURL } from '../../common/utils/customsEnsUtils';
import { getUserNameWithPrefix } from '../../services/authentication/authReducer';
import { getCookieConsent } from '../../services/cookie-consent/slice';

interface EnsLinkProps {
  ensUrl: string;
  disabled?: boolean;
  confirmed: boolean;
  optional?: boolean;
  trackerCategory: TrackerCategory;
}

export const EnsLink = forwardRef<HTMLAnchorElement, EnsLinkProps>(function EnsLink(
  { ensUrl, disabled, confirmed, optional, trackerCategory },
  ref,
) {
  const userId = useSelector(getUserNameWithPrefix);
  const consent = useSelector(getCookieConsent);

  const onClick = () => {
    trackEvent({ category: trackerCategory, action: 'Pressed ENS link' });
  };

  return (
    <Link
      target={'_blank'}
      href={resolveCustomsDocumentationEnsURL(ensUrl, userId, consent)}
      style={{ whiteSpace: 'nowrap' }}
      disabled={disabled}
      onClick={onClick}
    >
      <span ref={ref}>
        {confirmed ? 'ENS Confirmed' : optional ? 'Declare ENS (optional)' : 'Declare ENS'}
      </span>
    </Link>
  );
});
