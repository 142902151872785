import { useMemo } from 'react';
import { useAccount } from '../../services/settings/hooks/useAccount';
import { resolveDateFormat } from '../utils/dates/dateUtils';

export const useLocalDateFormat = (): string => {
  const { accountSettings } = useAccount();

  return useMemo(
    () => accountSettings.dateFormat || resolveDateFormat(),
    [accountSettings.dateFormat],
  );
};
