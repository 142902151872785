import { ApolloError, isApolloError } from '@apollo/client';
import { FC } from 'react';
import {
  BOOKING_ERROR_NOT_FOUND,
  BOOKING_ERROR_UNKNOWN,
  BookingErrorModalContents,
  getErrorModalContentFromGQLError,
} from '../../details/components/BookingErrorModal';
import { BOOKING_WAS_NOT_FOUND_MESSAGE } from './SwitchModalContent';
import { SwitchModalHeader } from './SwitchModalHeader';

interface Props {
  error: Error;
  bookingNo: number;
  onRequestClose: () => void;
}

function getSwitchError(error: Error | ApolloError) {
  if (isApolloError(error)) {
    return getErrorModalContentFromGQLError(error.graphQLErrors[0]);
  }

  if (error.message === BOOKING_WAS_NOT_FOUND_MESSAGE) {
    return BOOKING_ERROR_NOT_FOUND;
  } else {
    return BOOKING_ERROR_UNKNOWN;
  }
}

export const SwitchModalError: FC<Props> = ({ error, onRequestClose, bookingNo }) => {
  const { icon, message } = getSwitchError(error);

  return (
    <>
      <SwitchModalHeader
        errorTitle={message}
        onRequestClose={onRequestClose}
        bookingNo={bookingNo}
      />
      <BookingErrorModalContents icon={icon} />
    </>
  );
};
