import { FC } from 'react';
import { transformCustomsClassificationOption } from '../../../grid/utils/optionTransformers';
import { useUpdateFormStateMinimalBooking } from '../../utils/minimalBooking';
import { DropdownField } from './DropdownField';
import { transformCustomsClassificationsOptions } from '../../../../../common/graphql/useCustomsClassificationsOptions';
import { getMaybeDateFromDateAndTime } from '../../../../../common/utils/dates/dateUtils';

export interface CustomsClassificationFieldProps {
  disabled: boolean;
}

export const CustomsClassificationField: FC<CustomsClassificationFieldProps> = ({ disabled }) => {
  const minimalBooking = useUpdateFormStateMinimalBooking();

  if (!minimalBooking) {
    return null;
  }

  const customsClassifications = minimalBooking?.route?.ukCustomsClassifications;
  const includeEmptyOption = !Boolean(minimalBooking.customsClassificationCode);

  return (
    <DropdownField
      trackerCategory={'Details'}
      disabled={disabled}
      transformOption={transformCustomsClassificationOption}
      name={'customsClassification'}
      options={transformCustomsClassificationsOptions(
        customsClassifications,
        getMaybeDateFromDateAndTime(minimalBooking.departureDate, minimalBooking.departureTime),
        includeEmptyOption,
      )}
    />
  );
};
