import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchQueryHasValidMinLength,
  useSearchBookingsResult,
} from '../../../../common/graphql/useSearchBookingsResult';
import { useRedirectToManagePage } from '../../../../common/hooks/useRedirectToManagePage';
import { useHotkeys } from '../../../../common/hotkeys/hooks/useHotkeys';
import { Hotkeys } from '../../../../common/hotkeys/utils/hotkeys';
import { trackEvent } from '../../../../common/tracking/trackerService';
import {
  selectableFilterStates,
  selectablePlannedFilterStates,
} from '../../../../common/utils/statusFilter';
import {
  InvalidSearchQueryReason,
  removeInvalidSearchQueryCharacters,
  validateSearchQueryString,
} from '../../../../common/utils/stringUtil';
import { setFilter } from '../../../../services/booking-filter/filterActions';
import { getQuery, INITIAL_VALUE } from '../../../../services/booking-filter/filterReducer';
import { hideSearchOverlay, showSearchOverlay } from '../../../../services/search/searchActions';
import { getShowOverlay } from '../../../../services/search/searchReducer';
import { useOnBlurToIframe } from './useOnBlurToIframe';
import { useSearchColumns } from './useSearchColumns';
import { useShowBookingDetails } from '../../../../common/hooks/useShowBookingDetails';
import { useDebounce } from '@stenajs-webui/core';
import { BookingFilterState } from '../../../../gql/graphql';

const filterInvalidSearchQueryReason = (
  didSubmit: boolean,
  invalidSearchQueryReason: InvalidSearchQueryReason | null,
): InvalidSearchQueryReason | null =>
  didSubmit || invalidSearchQueryReason !== 'length_too_short' ? invalidSearchQueryReason : null;

const DEBOUNCE_DELAY = 300;
const DELAY_BEFORE_SHOWING_LOADING_SPINNER = 300;

export function useBookingSearch() {
  const dispatch = useDispatch();
  const [didSubmit, setDidSubmit] = useState(false);
  const redirectToManagePage = useRedirectToManagePage();
  const { pushBookingDetailsModal } = useShowBookingDetails();
  const searchInputActive = useSelector(getShowOverlay);
  const { width, searchColumns } = useSearchColumns();
  const filterQuery = useSelector(getQuery);

  const [inputValue, setInputValue] = useState(filterQuery);
  const searchQuery = useDebounce(inputValue.trim(), DEBOUNCE_DELAY);
  const [data, loading, error] = useSearchBookingsResult(searchQuery);
  const debouncedLoading = useDebounce(loading, DELAY_BEFORE_SHOWING_LOADING_SPINNER);

  const [invalidSearchQueryReason, setInvalidSearchQueryReason] =
    useState<InvalidSearchQueryReason | null>(null);

  useEffect(() => {
    if (error) {
      trackEvent({
        category: 'Search',
        action: 'Error: ' + error.message,
      });
    }
  }, [error]);

  useEffect(() => {
    setInputValue(filterQuery.trim());
  }, [filterQuery]);

  const onClickSearch = () => {
    setDidSubmit(true);
    const reason = validateSearchQueryString(inputValue);

    setInvalidSearchQueryReason(reason);

    if (searchQueryHasValidMinLength(inputValue)) {
      dispatch(
        setFilter({
          ...INITIAL_VALUE.filter,
          filterStates: selectableFilterStates,
          query: inputValue,
        }),
      );
      trackEvent({
        category: 'Search',
        action: 'View all',
        value: inputValue.length,
        label: `No. search letters ${inputValue.length}`,
      });

      redirectToManagePage();
      hideSearchResult();
    }
  };

  const showSearchResult = () => dispatch(showSearchOverlay());
  const hideSearchResult = () => dispatch(hideSearchOverlay());

  const onClickResult = (bookingNo: number) => {
    hideSearchResult();
    pushBookingDetailsModal(bookingNo);
    trackEvent({
      category: 'Search',
      action: 'Booking clicked',
      value: searchQuery.length,
      label: `No. search letters ${searchQuery.length}`,
    });
  };

  useOnBlurToIframe(hideSearchResult);

  const onClear = () => {
    setDidSubmit(false);
    trackEvent({
      category: 'Search',
      action: 'Clear',
      value: inputValue.length,
      label: `No. search letters ${inputValue.length}`,
    });
    setInputValue('');
  };

  const onInputChange = (value: string) => {
    setDidSubmit(false);
    const reason = validateSearchQueryString(value);

    setInvalidSearchQueryReason(reason);

    const formattedValue = removeInvalidSearchQueryCharacters(value);

    setInputValue(formattedValue);
  };

  const onTotalCountClick = (label: string) => {
    hideSearchResult();
    redirectToManagePage();
    trackEvent({
      category: 'Search',
      action: `${label} Total count`,
      value: searchQuery.length,
      label: `No. search letters ${searchQuery.length}`,
    });
  };

  const onClickActiveTransports = () => {
    dispatch(
      setFilter({
        ...INITIAL_VALUE.filter,
        filterStates: [...selectablePlannedFilterStates],
        query: searchQuery,
      }),
    );
    onTotalCountClick('PLANNED TRANSPORTS');
  };

  const onClickTransportHistory = () => {
    dispatch(
      setFilter({
        ...INITIAL_VALUE.filter,
        filterStates: [
          BookingFilterState.Shipped,
          BookingFilterState.Cancelled,
          BookingFilterState.NoShow,
        ],
        query: searchQuery,
      }),
    );
    onTotalCountClick('HISTORY');
  };

  useHotkeys(Hotkeys.BookingSearch, e => {
    e.preventDefault();

    trackEvent({
      category: 'ShortKeys',
      action: Hotkeys.BookingSearch,
      label: 'Search booking',
    });

    if (searchInputActive) {
      hideSearchResult();
    } else {
      showSearchResult();
    }
  });

  return {
    searchInputActive,
    invalidSearchQueryReason: filterInvalidSearchQueryReason(didSubmit, invalidSearchQueryReason),
    width,
    inputValue,
    searchQuery,
    onClickSearch,
    hideSearchResult,
    showSearchResult,
    onClear,
    onClickResult,
    onInputChange,
    searchColumns,
    onClickActiveTransports,
    onClickTransportHistory,
    data,
    error,
    loading: debouncedLoading,
  };
}
