import styled from '@emotion/styled';
import { ReactElement } from 'react';
import { useUpdateField } from '../../../../../common/update-information/hooks/useUpdateField';
import { BookingFormState } from '../../../../../services/update-form/updateFormReducer';
import {
  CommonDropdownField,
  CommonDropdownFieldProps,
} from '../../../../../common-ui/form-fields/CommonDropdownField';
import { hasDropdownValueChanged } from '../../../../multiple-create/hooks/useDropdownField';
import { getFieldProps } from '../../../../../common-ui/form-fields/getFieldProps';

export interface DropdownFieldProps<Key extends keyof BookingFormState>
  extends Omit<
    CommonDropdownFieldProps<BookingFormState[Key]>,
    'onValueChange' | 'value' | 'trackerAction'
  > {
  name: Key;
  width?: number;
}

const Wrapper = styled('div')<{ width?: number }>`
  ${({ width }) => (!!width ? `width: ${width}px` : undefined)};
`;

export const DropdownField = <Key extends keyof BookingFormState>({
  name,
  width,
  ...props
}: DropdownFieldProps<Key>): ReactElement<DropdownFieldProps<Key>> => {
  const fieldProps = useUpdateField(name);
  return (
    <Wrapper width={width}>
      <CommonDropdownField
        {...props}
        {...getFieldProps(props, fieldProps)}
        changed={hasDropdownValueChanged(fieldProps, props.transformOption)}
        menuPortalTarget={undefined}
      />
    </Wrapper>
  );
};
