export const introductionTourSession = 'introductionTourSession';

export const saveIntroductionTourSession = () => {
  try {
    window.sessionStorage.setItem(introductionTourSession, JSON.stringify(true));
  } catch (_) {}
};

export const getIntroductionTourSession = (): boolean => {
  try {
    const value = window.sessionStorage.getItem(introductionTourSession);
    return value === null ? false : JSON.parse(value);
  } catch (_) {
    return false;
  }
};
