import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Column, Row, Txt } from '@stenajs-webui/core';
import { Link } from '@stenajs-webui/elements';
import { FC, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { BoxWithPrintHide } from '../../common-ui/BoxWithPrintHide';
import { trackEvent } from '../tracking/trackerService';
import { routeCreators } from '../routes/AppRoutes';
import { zIndices } from '../zIndices';
import { globalContentWrapperIndent } from '../../globalStyles';
import { breakpoints } from '../../themes/defaultTheme';
import logo from '../../common-ui/images/logo_blue_text.svg';

interface StyledFooterProps {
  printHide: boolean;
  sticky: boolean;
}

export const stickyMainContentStyles = css`
  position: sticky;
  left: var(--nav-menu-width);
  width: calc(100dvw - var(--nav-menu-width));
`;

const StyledFooter = styled.footer<StyledFooterProps>`
  ${props => (props.sticky ? stickyMainContentStyles : '')}
  margin-left: var(--nav-menu-width);

  background-color: var(--bg-color);
  color: #9b9b9b;

  padding: calc(var(--swui-metrics-spacing) * 2) 0;

  @media screen and (min-width: ${breakpoints.md}) {
    padding: 0;
  }

  @media print {
    width: 100%;
    position: static;
    z-index: ${zIndices.footer};
    ${props => (props.printHide ? 'display: none;' : '')}

    padding: 0;
  }
`;

const PrintFriendlyRow = styled(Row)`
  @media print {
    flex-direction: row;
  }
`;

const PrintFriendlyColumn = styled(Column)`
  @media print {
    align-items: flex-start;
  }
`;

const PrintOnly = styled.div`
  display: none;

  @media print {
    display: block;
  }
`;

export const CONTACT_URL = 'https://www.stenalinefreight.com/contact';
export const PRIVACY_STATEMENT_URL = 'https://www.stenalinefreight.com/privacy-policy';
export const COOKIE_INFORMATION_URL = 'https://www.stenalinefreight.com/cookie-policy';

export const Footer: FC = () => {
  const location = useLocation();
  const printHide = location.pathname === routeCreators.timetable();
  const sticky = location.pathname.startsWith(routeCreators.bookings());

  const pathInfoForPrint =
    window.location.protocol + '//' + window.location.host + window.location.pathname;

  const trackCookieInformationEvent = useCallback(() => {
    trackEvent({ category: 'Footer', action: 'Cookie information link' });
  }, []);

  const trackContactEvent = useCallback(() => {
    trackEvent({ category: 'Footer', action: 'Contact link' });
  }, []);

  return (
    <StyledFooter sticky={sticky} printHide={printHide}>
      <PrintFriendlyRow
        flexDirection={['column', null, 'row']}
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={2}
        indent={globalContentWrapperIndent}
      >
        <PrintFriendlyColumn alignItems={'center'} gap={2} flex={1}>
          <Box spacing>
            <img src={logo} alt={'Stena Line'} style={{ width: 152 }} />
          </Box>
          <BoxWithPrintHide alignItems={'center'} flexDirection={['column', null, 'row']} gap={2}>
            <Txt size={'small'} variant={'bold'}>
              Freight Portal &copy; Stena Line
            </Txt>
            <Link href={PRIVACY_STATEMENT_URL} target={'_blank'}>
              Privacy statement
            </Link>
            <Link
              href={COOKIE_INFORMATION_URL}
              target={'_blank'}
              onClick={trackCookieInformationEvent}
            >
              Cookies
            </Link>
            <Link href={CONTACT_URL} target={'_blank'} onClick={trackContactEvent}>
              Contact us
            </Link>
            <Link href="mailto:freightportal@stenaline.com">Send feedback</Link>
          </BoxWithPrintHide>
        </PrintFriendlyColumn>
        <PrintOnly>
          <Txt>{pathInfoForPrint}</Txt>
        </PrintOnly>
      </PrintFriendlyRow>
    </StyledFooter>
  );
};
