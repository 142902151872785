import { graphql } from '../../gql';

export const vehicleTypeByCustomerNoAndRouteCodeQuery = graphql(/* GraphQL */ `
  query VehicleTypesQuery($customerNo: Int!, $routeCode: String!, $vehicleTypeCode: String!) {
    viewer {
      vehicleType {
        byCodeAndCustomerAndRoute(
          customerNo: $customerNo
          routeCode: $routeCode
          vehicleTypeCode: $vehicleTypeCode
        ) {
          ...VehicleType
        }
      }
    }
  }
`);
