import { Box } from '@stenajs-webui/core';
import { PrimaryButton } from '@stenajs-webui/elements';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PayButton, SeePaymentLink } from '../../../../common-ui/payment/PayButton';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { useShowCalculatePrice } from '../../../../common/pricing/useShowCalculatePrice';
import { useUpdateSubmit } from '../../../../common/update-information/hooks/useUpdateSubmit';
import {
  getEditedFormState,
  getFormIsEdited,
} from '../../../../services/update-form/updateFormReducer';
import { isBookingEditable } from '../../../../services/update-form/utils/bookingUtils';
import { StoreState } from '../../../../store/storeState';
import { CalculatePriceForDetails } from '../pricing/CalculatePriceForDetails';
import { bookingHasPayments, bookingIsPayable } from '../utils/showPaymentUtil';
import { BookingCancelButton } from './BookingCancelButton';
import { TrackerCategory } from '../../../../common/tracking/trackerService';
import { ModalFooterBase } from '../../../../common-ui/ModalFooterBase';

export interface ModalFooterEditProps {
  booking: BasicBooking;
  onCancelFail: () => void;
}

const trackerCategory: TrackerCategory = 'Details';

export const ModalFooterEdit: FC<ModalFooterEditProps> = ({ booking, onCancelFail }) => {
  const mapState = useCallback(
    (state: StoreState) => {
      return getFormIsEdited(state, {
        bookingNo: booking.bookingNo,
        formId: 'Details',
      });
    },
    [booking.bookingNo],
  );

  const { submitting, submit } = useUpdateSubmit({
    bookingNo: booking.bookingNo,
    formId: 'Details',
  });

  const edited = useSelector(mapState);

  const editedFormState = useSelector(
    useCallback(
      (state: StoreState) => {
        return getEditedFormState(state, {
          bookingNo: booking.bookingNo,
          formId: 'Details',
        });
      },
      [booking.bookingNo],
    ),
  );

  const showCalculatePrice = useShowCalculatePrice(
    editedFormState?.customer?.isCashCustomer,
    booking.bookingStatus.status,
  );
  const showSeePaymentLink = bookingHasPayments(booking);
  const showPayButton = bookingIsPayable(booking);

  return (
    <ModalFooterBase
      leftContent={
        booking.cancelable && (
          <BookingCancelButton booking={booking} onFail={onCancelFail} disabled={submitting} />
        )
      }
      rightContent={
        <Box flexDirection={['column', null, 'row']} gap>
          {showSeePaymentLink && (
            <SeePaymentLink bookingNo={booking.bookingNo} trackerCategory={trackerCategory} />
          )}
          {isBookingEditable(booking) && (
            <>
              {showCalculatePrice && (
                <CalculatePriceForDetails formState={editedFormState} placement={'top'} />
              )}
              {showPayButton && (
                <PayButton
                  bookingNo={booking.bookingNo}
                  trackerCategory={trackerCategory}
                  disabled={edited}
                />
              )}
              <PrimaryButton
                disabled={!edited}
                loading={submitting}
                className={'t_save_button'}
                label={'Save'}
                loadingLabel={'Saving...'}
                onClick={submit}
              />
            </>
          )}
        </Box>
      }
    />
  );
};
