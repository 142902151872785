export function clearValidationErrors<TModel extends {}>(
  validationErrors: { [P in keyof TModel]?: string } | undefined,
  updatedFields: Partial<TModel>,
) {
  if (validationErrors) {
    for (const key in updatedFields) {
      delete validationErrors[key];
    }
  }
}
