import { Heading } from '@stenajs-webui/core';
import { Banner, FlatButton, stenaExternalLink } from '@stenajs-webui/elements';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { assertIsDefined } from '../../../../common/asserts/assert';
import { ErrorBoundary } from '../../../../common/error-handling/ErrorBoundary';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { TrackerCategory, trackEvent } from '../../../../common/tracking/trackerService';
import { initializeCustomsLinkLoader } from '../../../../services/customs-link/customsLinkReducer';
import { showCustomsLinkGdpr } from '../../../../services/modal/modalActions';
import { useAccount } from '../../../../services/settings/hooks/useAccount';
import { useCustomsLinkTokenLoader } from '../../hooks/useCustomsLinkTokenLoader';

interface CustomsLinkPopupContentProps {
  booking: BasicBooking;
  label: string | null;
  trackerCategory: TrackerCategory;
  onRequestClose: () => void;
}

const CustomsLinkPopupContentInner: FC<CustomsLinkPopupContentProps> = ({
  booking,
  label,
  trackerCategory,
  onRequestClose,
}) => {
  assertIsDefined(label, 'CustomsLinkPopupContent: Label is not set');

  const { bookingNo, vehicleRegNo } = booking;

  const vehicleRegNoIsMissing = vehicleRegNo === '';

  const dispatch = useDispatch();
  const { fetchTokenAndRedirect, isLoading, loaderState } = useCustomsLinkTokenLoader(bookingNo);

  useEffect(() => {
    dispatch(initializeCustomsLinkLoader(bookingNo));
  }, [dispatch, bookingNo]);
  const { privacySettings } = useAccount();

  const onCreateGmrClick = async () => {
    const hasAccepted = privacySettings.customsLink;

    trackEvent({
      category: trackerCategory,
      action: `Pressed Create ${label}`,
      label: 'Has accepted GDPR: ' + (hasAccepted ? 'Yes' : 'No'),
    });

    if (!hasAccepted) {
      onRequestClose();
      dispatch(showCustomsLinkGdpr(bookingNo, trackerCategory));
      return;
    }

    await fetchTokenAndRedirect(bookingNo);
  };

  return (
    <>
      <Heading variant={'h5'} as={'h3'}>
        CustomsLink services
      </Heading>
      {vehicleRegNoIsMissing ? (
        <Banner variant={'info'} text={`Add vehicle registration number to create ${label}`} />
      ) : (
        <FlatButton
          label={`Create ${label}`}
          leftIcon={stenaExternalLink}
          onClick={onCreateGmrClick}
          loading={isLoading}
          loadingLabel={'Contacting CustomsLink'}
        />
      )}
      {loaderState?.name === 'error' && <Banner variant={'error'} text={loaderState.error} />}
    </>
  );
};

export const CustomsLinkPopupContent: FC<CustomsLinkPopupContentProps> = props => (
  <ErrorBoundary
    fallback={() => (
      <Banner variant={'standard'} text={'Unfortunately we encountered an unexpected problem'} />
    )}
  >
    <CustomsLinkPopupContentInner {...props} />
  </ErrorBoundary>
);
