import { IntermodalDetailsFieldCell } from '../../common/IntermodalDetailsFieldCell';
import { UpdateIntermodalDropdownField } from '../fields/UpdateIntermodalDropdownField';
import {
  transformDepartureTimeOption,
  transformIntermodalRouteToDropdownOption,
} from '../../utils/detailsUtils';
import { Column } from '@stenajs-webui/core';
import { UpdateIntermodalDateInputField } from '../fields/UpdateIntermodalDateInputField';
import { timeOptions } from '../../../manage/details/components/sections/common';
import { formatServerDate } from '../../../../common/utils/dates/dateUtils';
import { IntermodalRoute } from '../../../../gql/graphql';

interface UpdateIntermodalDepartureSectionProps {
  alternativeRoutes: IntermodalRoute[];
}

export const UpdateIntermodalDepartureSection = ({
  alternativeRoutes,
}: UpdateIntermodalDepartureSectionProps) => {
  const today = new Date();
  return (
    <>
      <IntermodalDetailsFieldCell
        label={'Intermodal route'}
        children={
          <UpdateIntermodalDropdownField
            trackerCategory={'IntermodalUpdate'}
            editable={alternativeRoutes.length > 1}
            options={alternativeRoutes.map(transformIntermodalRouteToDropdownOption)}
            name={'route'}
            transformOption={transformIntermodalRouteToDropdownOption}
          />
        }
      />
      <Column gap={2}>
        <IntermodalDetailsFieldCell
          label={'Departure date'}
          className={'t_intermodal_departure_date'}
          children={
            <UpdateIntermodalDateInputField
              width={'100%'}
              editable={false}
              trackerCategory={'IntermodalUpdate'}
              name={'departureDate'}
              minDate={formatServerDate(today)}
            />
          }
        />
        <IntermodalDetailsFieldCell
          label={'Dep. time'}
          children={
            <UpdateIntermodalDropdownField
              trackerCategory={'IntermodalUpdate'}
              editable={false}
              name={'departureTime'}
              options={timeOptions}
              transformOption={transformDepartureTimeOption}
            />
          }
        />
      </Column>
    </>
  );
};
