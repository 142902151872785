import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Options } from 'react-select';
import { SailingsQueryQuery } from '../../gql/graphql';
import { DropdownOption } from '../../use-cases/manage/grid/utils/optionTransformers';
import { getBookableSailings } from '../utils/sailingUtils';
import { Sailing } from './fragments/gql/Sailing';
import { sailingsByFilterQuery } from './sailingsByFilterQuery';
import { QueryResult } from './types/QueryResult';

const transformOptions = (data: SailingsQueryQuery | undefined): Options<DropdownOption<Sailing>> =>
  getBookableSailings(data?.viewer?.sailings?.byFilter?.items ?? []).map(sailing => ({
    data: sailing,
    label: sailing.departureTime,
    value: sailing.id,
  }));

export const useDepartureTimeOptions = (
  departureDate: string | null | undefined,
  routeCode: string | null | undefined,
  skip: boolean = false,
): QueryResult<Options<DropdownOption<Sailing>>> => {
  const { data, loading, error } = useQuery(sailingsByFilterQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        date: {
          from: departureDate!,
          to: departureDate!,
        },
        routeCode: routeCode!,
      },
    },
    skip: !departureDate || !routeCode || skip,
  });

  return useMemo(() => [transformOptions(data), loading, error], [data, loading, error]);
};
