import { Link as WebUILink } from '@stenajs-webui/elements';
import { Link } from '../../../common-ui/Link';
import { DetailedIntermodalBooking } from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { useBackgroundLocation } from '../../../common/home/components/UseBackgroundLocation';
import { getModalRouteLinkProps, modalRouteCreators } from '../../../common/routes/AppRoutes';
import { TrackerCategory, trackEvent } from '../../../common/tracking/trackerService';

export const IntermodalLink = ({
  booking,
  onAfterClick,
  trackerCategory,
}: {
  booking: DetailedIntermodalBooking;
  onAfterClick?: () => void;
  trackerCategory: TrackerCategory;
}) => {
  const location = useBackgroundLocation();

  const onClick = () => {
    onAfterClick?.();
    trackEvent({ category: trackerCategory, action: 'Show intermodal details' });
  };

  return (
    <Link
      onClick={onClick}
      render={props => <WebUILink {...props}>{booking.bookingNo}</WebUILink>}
      {...getModalRouteLinkProps(
        modalRouteCreators.intermodalDetails({ bookingNo: booking.bookingNo }, location),
      )}
    />
  );
};
