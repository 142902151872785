import { FC } from 'react';
import { CommonDropdownField } from '../../../../common-ui/form-fields/CommonDropdownField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import { useGoodsTypes } from '../../../../services/intermodal/hooks/useGoodsTypes';
import { transformGoodsTypeOption } from '../../utils/detailsUtils';
import { useCreateIntermodalGoodsField } from '../../hooks/useCreateIntermodalGoodsFields';
import { GoodsTypeDropdownFieldProps } from '../../update/fields/UpdateGoodsTypeDropdown';

export const CreateGoodsTypeDropdown: FC<GoodsTypeDropdownFieldProps> = ({
  goodsId,
  goodsTypesQueryRef,
  ...props
}) => {
  const { goodsTypes } = useGoodsTypes(goodsTypesQueryRef);
  const fieldProps = useCreateIntermodalGoodsField(goodsId, 'goodsType');

  return (
    <CommonDropdownField
      {...props}
      {...getFieldProps(props, fieldProps)}
      transformOption={transformGoodsTypeOption}
      options={goodsTypes.map(transformGoodsTypeOption)}
    />
  );
};
