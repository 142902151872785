import { FC } from 'react';
import { HiddenFieldGate } from '../../../../../common-ui/HiddenFieldGate';
import { ColumnId } from '../../../../../gql/graphql';
import { BookingFormState } from '../../../../../services/update-form/updateFormReducer';
import { transformNumberOfDrivers } from '../../../grid/utils/optionTransformers';
import { BookingFields, isHidden } from '../../utils/detailsUtils';
import { DimensionsFieldGrid } from '../BookingDetailsEdit';
import { DetailsFieldCell } from '../DetailsFieldCell';
import { DropdownField } from '../fields/DropdownField';
import { NumericField } from '../fields/NumericField';
import { UpdateTrailerRegNo } from '../fields/UpdateTrailerRegNo';
import { UpdateVehicleRegNo } from '../fields/UpdateVehicleRegNo';
import { VehicleTypeField } from '../VehicleTypeField';
import { BookingSection } from './BookingSection';
import { personsRange } from './common';
import { Row } from '@stenajs-webui/core';
import { useUpdateRegistrationNumberAlertApplied } from '../../../../../common/registrationNumberAlert/hooks/useUpdateRegistrationNumberAlertApplied';
import { useUpdateField } from '../../../../../common/update-information/hooks/useUpdateField';

interface VehicleTrailerSectionProps {
  fieldsToShow: BookingFields;
  fieldsToEnable: BookingFields;
  formState: BookingFormState;
  disabled: boolean;
}

export const VehicleDetailsSection: FC<VehicleTrailerSectionProps> = ({
  disabled,
  fieldsToShow,
  fieldsToEnable,
  formState,
}) => {
  const { editedValue: editedLength } = useUpdateField<'length'>('length');
  const { editedValue: editedHeight } = useUpdateField<'height'>('height');
  const { arrivalNotepadApplied, icon: heightIcon } = useUpdateRegistrationNumberAlertApplied(
    'Details',
    'height',
    editedHeight,
  );
  const { icon: lengthIcon } = useUpdateRegistrationNumberAlertApplied(
    'Details',
    'length',
    editedLength,
  );

  const isDisabled = (field: ColumnId) => disabled || isHidden(fieldsToEnable, field);

  return (
    <BookingSection title={'Vehicle'}>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.VehicleRegNo)}>
        <UpdateVehicleRegNo disabled={isDisabled(ColumnId.VehicleRegNo)} />
      </HiddenFieldGate>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.TrailerRegNo)}>
        <UpdateTrailerRegNo disabled={isDisabled(ColumnId.TrailerRegNo)} />
      </HiddenFieldGate>
      <Row gap={2}>
        <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.VehicleTypeCode)}>
          <DetailsFieldCell
            flex={'3 1 100px'}
            label="Vehicle type"
            field={
              <VehicleTypeField
                disabled={arrivalNotepadApplied || isDisabled(ColumnId.VehicleTypeCode)}
                customerNo={formState.customer?.custNo ?? null}
                routeCode={formState.route?.code ?? null}
              />
            }
          />
        </HiddenFieldGate>
        <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.NoOfDrivers)}>
          <DetailsFieldCell
            flex={'1 1 100px'}
            label="Drivers"
            field={
              <DropdownField
                trackerCategory={'Details'}
                name={'noOfDrivers'}
                transformOption={transformNumberOfDrivers}
                options={personsRange}
                disabled={isDisabled(ColumnId.NoOfDrivers)}
              />
            }
          />
        </HiddenFieldGate>
      </Row>
      <DimensionsFieldGrid>
        <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.Length)}>
          <DetailsFieldCell
            label={'Length (m)'}
            field={
              <NumericField
                className={'t_length_details_edit'}
                trackerCategory={'Details'}
                name={'length'}
                disabled={arrivalNotepadApplied || isDisabled(ColumnId.Length)}
                buttonRight={lengthIcon}
              />
            }
          />
        </HiddenFieldGate>
        <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.Width)}>
          <DetailsFieldCell
            label="Width (m)"
            field={
              <NumericField
                className={'t_width_details_edit'}
                name={'width'}
                disabled={isDisabled(ColumnId.Width)}
                trackerCategory={'Details'}
              />
            }
          />
        </HiddenFieldGate>
        <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.Height)}>
          <DetailsFieldCell
            label="Height (m)"
            field={
              <NumericField
                className={'t_height_details_edit'}
                trackerCategory={'Details'}
                name={'height'}
                disabled={arrivalNotepadApplied || isDisabled(ColumnId.Height)}
                buttonRight={heightIcon}
              />
            }
          />
        </HiddenFieldGate>
      </DimensionsFieldGrid>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.TradeWeight)}>
        <DetailsFieldCell
          label="Trade weight (kg)"
          field={
            <NumericField
              trackerCategory={'Details'}
              name={'tradeWeight'}
              disabled={isDisabled(ColumnId.TradeWeight)}
              maxLength={6}
              onlyIntegers
            />
          }
        />
      </HiddenFieldGate>
    </BookingSection>
  );
};
