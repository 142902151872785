import { graphql } from '../../gql';

export const allUrgentMessagesQuery = graphql(/*GraphQL*/ `
  query UrgentMessagesQuery($dateRange: FilterDate!) {
    viewer {
      urgentMessages {
        byDateRange(dateRange: $dateRange) {
          messages {
            ...UrgentMessage
          }
        }
      }
    }
  }
`);
