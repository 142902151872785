import { Box, Column, Row } from '@stenajs-webui/core';
import { FlatButton, Icon, stenaArrowRightAngle, stenaMinusCircle } from '@stenajs-webui/elements';
import { Select } from '@stenajs-webui/select';
import { FC, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { GoodsTypesQueryRefProps } from '../../../services/intermodal/hooks/useGoodsTypes';
import {
  removeUpdateIntermodalBookingGood,
  UpdateIntermodalBookingGoodFormModel,
} from '../../../services/intermodal/updateIntermodalReducer';
import { IntermodalDetailsFieldCell } from '../common/IntermodalDetailsFieldCell';
import { UpdateGoodsTypeDropdown } from './fields/UpdateGoodsTypeDropdown';
import { UpdateGoodsWeightInput } from './fields/UpdateGoodsWeightInput';
import { UpdateIntermodalGoodsValidationWrapper } from './UpdateIntermodalValidationWrapper';
import { MAX_LENGTH_GOODS_WEIGHT } from '../utils/fieldLengths';

interface Props extends GoodsTypesQueryRefProps {
  value: UpdateIntermodalBookingGoodFormModel;
  editable: boolean;
}

export const UpdateBookingGoodsItem: FC<Props> = ({ value, editable, goodsTypesQueryRef }) => {
  const dispatch = useDispatch();
  return (
    <Column gap={2}>
      <UpdateIntermodalGoodsValidationWrapper
        name={'goodsType'}
        goodId={value.id}
        renderField={isValid => (
          <IntermodalDetailsFieldCell label="Goods description">
            <Suspense fallback={<Select isLoading />}>
              <UpdateGoodsTypeDropdown
                isValid={isValid}
                goodsId={value.id}
                trackerCategory={'IntermodalUpdate'}
                goodsTypesQueryRef={goodsTypesQueryRef}
              />
            </Suspense>
          </IntermodalDetailsFieldCell>
        )}
      />
      <UpdateIntermodalGoodsValidationWrapper
        name={'weight'}
        goodId={value.id}
        renderField={isValid => (
          <Row alignItems={'end'} gap>
            <Box style={{ paddingBottom: 6 }}>
              <Icon className={'bottom'} size={20} icon={stenaArrowRightAngle} />
            </Box>
            <IntermodalDetailsFieldCell label={'Weight (kg)'} flex={1}>
              <UpdateGoodsWeightInput
                goodsId={value.id}
                maxLength={MAX_LENGTH_GOODS_WEIGHT}
                isValid={isValid}
                trackerCategory={'IntermodalUpdate'}
              />
            </IntermodalDetailsFieldCell>
            {editable ? (
              <FlatButton
                variant={'danger'}
                leftIcon={stenaMinusCircle}
                onClick={() => {
                  dispatch(removeUpdateIntermodalBookingGood({ id: value.id }));
                }}
              />
            ) : null}
          </Row>
        )}
      />
    </Column>
  );
};
