import { TempResult } from './switchReducer';

export type SwitchActions = SwitchClearTempResultAction | SwitchSetTempResultAction;

export interface SwitchClearTempResultAction {
  payload: {};
  type: 'SWITCH:CLEAR_TEMP_RESULT';
}

export const clearTempSwitchResult = (): SwitchClearTempResultAction => ({
  payload: {},
  type: 'SWITCH:CLEAR_TEMP_RESULT',
});

export interface SwitchSetTempResultAction {
  payload: {
    tempResult: TempResult | null;
  };
  type: 'SWITCH:SET_TEMP_RESULT';
}

export const setTempSwitchResult = (tempResult: TempResult | null): SwitchSetTempResultAction => ({
  payload: {
    tempResult,
  },
  type: 'SWITCH:SET_TEMP_RESULT',
});
