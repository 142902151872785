import { useMutation } from '@apollo/client';
import { useAppDispatch } from '../../../store/appDispatch';
import { createIntermodalBookingMutation } from '../graphql/createIntermodalBookingMutation';
import { createIntermodalBookingThunk } from '../thunks/createIntermodalBookingThunk';

export const useCreateIntermodalBooking = (onSuccess: () => void) => {
  const dispatch = useAppDispatch();

  const [createIntermodalBooking, { loading }] = useMutation(createIntermodalBookingMutation);

  const submit = async () => {
    try {
      await dispatch(
        createIntermodalBookingThunk({
          createBooking: createInput =>
            createIntermodalBooking({
              variables: { booking: createInput },
              refetchQueries: ['IntermodalBookingsFilterQuery'],
            }),
        }),
      ).unwrap();
      onSuccess();
    } catch {
      // Error handled in thunk
    }
  };
  return { submit, loading };
};
