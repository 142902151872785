import { first } from 'lodash';
import qs from 'query-string';
import {
  BookingFilterState,
  BookingsSortColumn,
  ColumnId,
  FilterCargo,
  FilterVehicleType,
  SortDirection,
} from '../../gql/graphql';
import { FilterDateDate, FilterState } from '../../services/booking-filter/filterReducer';
import { assertIsDefined } from '../asserts/assert';
import { parseEnum } from '../utils/enums';
import { filterMap } from '../utils/filterMap';
import { ParsedUrlFilterAndSortState, UrlFilterAndSortState, URLHashData } from './types';
import { mergePartialFilterWithInitialValue } from './urlFilterMerger';

const stringifyDate = (date: FilterDateDate): string => {
  if (date.type === 'relative') {
    return date.value;
  } else {
    return date.dateString;
  }
};

export const transformToUrlState = (
  filterState: FilterState,
  sorting: BookingsSortColumn,
): UrlFilterAndSortState => ({
  customers: filterState.customerIds,
  cargo: filterState.cargoIds,
  dateFrom: filterState.date.from != null ? stringifyDate(filterState.date.from) : undefined,
  dateTo: filterState.date.to != null ? stringifyDate(filterState.date.to) : undefined,
  query: filterState.query,
  routes: filterState.routeCodeIds,
  timeStart: filterState.time.start ?? undefined,
  timeEnd: filterState.time.end ?? undefined,
  states: filterState.filterStates,
  vehicles: filterState.vehicleIds,
  sort: sorting.column,
  direction: sorting.direction,
});

export const stringifyManagePageUrl = (
  filterState: FilterState,
  sorting: BookingsSortColumn,
): string => {
  const filterAndSortState = transformToUrlState(filterState, sorting);

  return qs.stringify(filterAndSortState, {
    arrayFormat: 'comma',
    skipEmptyString: true,
    skipNull: true,
    encode: true,
  });
};

export const parseManagePageUrl = (searchString: string): Partial<URLHashData> => {
  const parsedFilter: Partial<ParsedUrlFilterAndSortState> = qs.parse(searchString, {
    arrayFormat: 'comma',
    decode: true,
  });

  const result = tryParseFilter(parsedFilter);

  if (!result.success) {
    return {};
  }

  return mergePartialFilterWithInitialValue(result.value);
};

const transformElementToArray = <T>(array: T | T[]): T[] =>
  Array.isArray(array) ? array : [array];
const transformArrayToElement = <T>(array: T | T[]): T => {
  if (Array.isArray(array)) {
    const val = first(array);
    assertIsDefined(val, 'tried to get first element from empty array');
    return val;
  } else {
    return array;
  }
};

type ParsedFilter<T> = { success: false } | { success: true; value: T };

const tryParseFilter = (
  parsed: Partial<ParsedUrlFilterAndSortState>,
): ParsedFilter<Partial<UrlFilterAndSortState>> => {
  const params = pickValidParams(parsed);

  if (Object.values(params).every(n => n == null)) {
    return { success: false };
  }

  const {
    dateFrom,
    dateTo,
    direction,
    query,
    routes,
    sort,
    states,
    timeEnd,
    timeStart,
    vehicles,
    cargo,
    customers,
  } = params;

  return {
    success: true,
    value: {
      cargo: filterMap(transformElementToArray(cargo), id =>
        id != null ? parseEnum(FilterCargo, id) : null,
      ),
      dateFrom: dateFrom != null ? transformArrayToElement(dateFrom) : undefined,
      dateTo: dateTo != null ? transformArrayToElement(dateTo) : undefined,
      states: filterMap(transformElementToArray(states), id =>
        id != null ? parseEnum(BookingFilterState, id) : null,
      ),
      query: transformArrayToElement(query),
      timeStart: transformArrayToElement(timeStart),
      timeEnd: transformArrayToElement(timeEnd),
      vehicles: filterMap(transformElementToArray(vehicles), id =>
        id != null ? parseEnum(FilterVehicleType, id) : null,
      ),
      routes: routes != null ? transformElementToArray(routes) : undefined,
      customers: customers != null ? transformElementToArray(customers) : undefined,
      sort: sort != null ? parseEnum(ColumnId, transformArrayToElement(sort)) : undefined,
      direction:
        direction != null
          ? parseEnum(SortDirection, transformArrayToElement(direction))
          : undefined,
    },
  };
};

function pickValidParams({
  cargo,
  customers,
  dateFrom,
  dateTo,
  direction,
  query,
  routes,
  sort,
  states,
  timeEnd,
  timeStart,
  vehicles,
}: Partial<ParsedUrlFilterAndSortState>) {
  return {
    dateFrom,
    dateTo,
    direction,
    query,
    timeStart,
    timeEnd,
    vehicles,
    routes,
    cargo,
    customers,
    states,
    sort,
  };
}
