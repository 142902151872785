import { map, sum } from 'lodash';
import { Output } from 'valibot';
import {
  BookingGoodsInput,
  CreateIntermodalBookingInput,
  IntermodalJourneyFilterInput,
  UpdateIntermodalBookingInput,
} from '../../../gql/graphql';
import {
  CreateIntermodalFormModelSchema,
  IntermodalGoodsFormModelSchema,
  IntermodalJourneyFormModelSchema,
  UpdateIntermodalFormModelSchema,
} from './schemas';

const transformGoodToInput = (
  good: Output<typeof IntermodalGoodsFormModelSchema>,
): BookingGoodsInput => ({
  goodsTypeCode: good.goodsType.id,
  weight: good.weight,
  id: good.id,
  description: good.goodsType.description,
});

export const transformUpdateFormModelToInput = (
  formState: Output<typeof UpdateIntermodalFormModelSchema>,
): UpdateIntermodalBookingInput => ({
  bookingNo: formState.bookingNo,
  custNo: formState.customer.custNo,
  vehicleReg: formState.vehicleReg,
  iluCode: formState.iluCode,
  vehicleTypeCode: formState.vehicleType.code,
  hazardousGoods: formState.hazardousGoods,
  noOfPlugins: formState.noOfPlugins,
  pluginTemperature: formState.temperature,
  craneable: formState.craneable,
  length: formState.length,
  width: formState.width,
  height: formState.height,
  emptyWeight: formState.emptyWeight,
  bookingGoods: map(formState.bookingGoods, transformGoodToInput),
  customerReference: formState.customerReference,
  loadingNote: formState.loadingNote,
  routeCode: formState.route.id,
});

export const transformCreateFormModelToInput = (
  formState: Output<typeof CreateIntermodalFormModelSchema>,
): CreateIntermodalBookingInput => ({
  routeCode: formState.route.id,
  custNo: formState.customer.custNo,
  vehicleReg: formState.vehicleReg,
  iluCode: formState.iluCode,
  vehicleTypeCode: formState.vehicleType.code,
  hazardousGoods: formState.hazardousGoods,
  noOfPlugins: formState.noOfPlugins,
  pluginTemperature: formState.temperature,
  craneable: formState.craneable,
  length: formState.length,
  width: formState.width,
  height: formState.height,
  emptyWeight: formState.emptyWeight,
  bookingGoods: map(formState.bookingGoods, transformGoodToInput),
  customerReference: formState.customerReference,
  loadingNote: formState.loadingNote,
  legs: formState.legs?.map(leg => ({ legNumber: leg.legNumber, sailingId: leg.id })) ?? [],
});

export const transformIntermodalCreateFormStateToFilter = (
  state: Output<typeof IntermodalJourneyFormModelSchema>,
): IntermodalJourneyFilterInput => ({
  customerNo: String(state.customer.custNo),
  height: state.height,
  width: state.width,
  length: state.length,
  totalWeight: state.emptyWeight + sum(map(state.bookingGoods, g => g.weight)),
  routeCode: state.route.id,
  plugin: state.noOfPlugins,
  searchByDeparture: state.searchByDeparture,
  vehicleTypeCode: state.vehicleType.code,
  searchTime: state.departureTime,
  searchDate: state.departureDate,
});
