import { BasicBooking } from '../../../common/graphql/fragments/gql/BasicBooking';
import { VehicleType } from '../../../common/graphql/fragments/gql/VehicleType';
import { CreateBookings_createBookings_results } from '../../../use-cases/multiple-create/graphql/gql/CreateBookings';
import { CreateCandidate, CreateFormState } from '../createFormReducer';

export interface CreatedBooking {
  booking: BasicBooking;
  messages: string[];
  warnings: string[];
}

export interface FailedBooking {
  errors: string[];
  warnings: string[];
}

export interface WaitlistedBooking {
  booking: BasicBooking;
  messages: string[];
  warnings: string[];
}

export interface BookingResultGroupedByStatus {
  created: CreatedBooking[];
  failed: FailedBooking[];
  waitlisted: WaitlistedBooking[];
}

export const transformCreateBookingsResult = (
  results: CreateBookings_createBookings_results[],
): BookingResultGroupedByStatus => {
  const created: CreatedBooking[] = [];
  const failed: FailedBooking[] = [];
  const waitlisted: WaitlistedBooking[] = [];

  results.forEach(res => {
    if (res.booking) {
      if (res.booking.bookingStatusCode === 'W') {
        waitlisted.push({
          booking: res.booking,
          messages: res.messages.map(message => message.description),
          warnings: res.warnings.map(message => message.description),
        });
      } else {
        created.push({
          booking: res.booking,
          messages: res.messages.map(message => message.description),
          warnings: res.warnings.map(message => message.description),
        });
      }
    } else {
      failed.push({
        errors: res.errors.map(error => error.description),
        warnings: res.warnings.map(warning => warning.description),
      });
    }
  });

  return { created, failed, waitlisted };
};

export const getNumberOfDrivers = (vehicleType: VehicleType | null | undefined): number => {
  if (!vehicleType) {
    return 1;
  }

  return vehicleType.useNoOfDrivers ? 1 : 0;
};

export const getBookingsToKeepInForm = (
  results: CreateBookings_createBookings_results[],
  formState: CreateFormState,
): CreateCandidate[] => {
  const bookingsToKeepInForm: CreateCandidate[] = [];

  results.forEach((result, index) => {
    if (!result.success) {
      bookingsToKeepInForm.push({
        ...formState.bookings[index],
        meta: {
          errors: result.errors.map(error => error.description),
          warnings: result.warnings.map(warning => warning.description),
        },
      });
    }
  });
  return bookingsToKeepInForm;
};
