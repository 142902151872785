import { FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { exhaustSwitchCaseElseThrow } from '../../../common/utils/ExhaustSwitchCase';
import { ToastArgs } from '../../../services/notifications/notificationsActions';
import { getToastById } from '../../../services/notifications/notificationsReducer';
import { StoreState } from '../../../store/storeState';
import { BookingResultToast } from './BookingResultToast';
import { UrgentMessageToast } from './UrgentMessageToast';

export interface ToastProps {
  id: string;
}

export const Toast: FC<ToastProps> = ({ id }) => {
  const ref = useRef<ToastArgs | null>(null);
  const toastArgs = useSelector((state: StoreState) => getToastById(state, id));

  if (toastArgs) {
    ref.current = toastArgs;
  }

  if (!ref.current) {
    return null;
  }

  switch (ref.current.type) {
    case 'urgentMessage': {
      return <UrgentMessageToast id={id} args={ref.current} />;
    }
    case 'bookingResult': {
      return <BookingResultToast id={id} args={ref.current} />;
    }
    default:
      return exhaustSwitchCaseElseThrow(ref.current);
  }
};
