import { StoreState } from '../../store/storeState';
import {
  BookingCandidateId,
  CreateFormActions,
  CreateFormIdVariant,
  CreateResult,
} from './createFormActions';
import {
  CreateCandidate,
  createFormReducer,
  CreateFormState,
  INITIAL_VALUE as formInitialValue,
} from './createFormReducer';
import { CreateMetaActions } from './createMetaActions';
import {
  createMetaReducer,
  CreateMetaState,
  INITIAL_VALUE as metaInitialValue,
} from './createMetaReducer';

export interface CreateValidationErrors extends Partial<Record<keyof CreateFormState, string>> {}

export interface IndividualCreateReducerState {
  formState: CreateFormState;
  meta: CreateMetaState;
}

export type CreateReducerState = Record<CreateFormIdVariant, IndividualCreateReducerState>;

const INITIAL_VALUE: IndividualCreateReducerState = {
  formState: formInitialValue,
  meta: metaInitialValue,
};

const INITIAL_STATE: CreateReducerState = {
  multi: INITIAL_VALUE,
  simple: INITIAL_VALUE,
};

export const createReducer = (
  state = INITIAL_STATE,
  action: CreateFormActions | CreateMetaActions,
) => {
  switch (action.type) {
    case 'CREATE_FORM:REQUEST_CLEANUP_FORM':
    case 'CREATE_FORM:INITIALIZE_CREATE_FORM':
      return {
        ...state,
        [action.payload.formId]: {
          formState: createFormReducer(state[action.payload.formId].formState, action),
          meta: createMetaReducer(state[action.payload.formId].meta, action),
        },
      };
    case 'CREATE_FORM:UPDATE_ALL_SPECIFIC_VALUES':
    case 'CREATE_FORM:SET_ALL_SPECIFIC_VALUES':
    case 'CREATE_FORM:SET_SPECIFIC_VALUES':
    case 'CREATE_FORM:REMOVE_BOOKING_CANDIDATE':
    case 'CREATE_FORM:SET_NUMBER_OF_BOOKINGS':
    case 'CREATE_FORM:UPDATE_SPECIFIC_VALUES':
    case 'CREATE_FORM:SET_BOOKINGS_TO_KEEP_IN_FORM':
    case 'CREATE_FORM:UPDATE_REGISTRATION_NUMBER_ALERT':
      return {
        ...state,
        [action.payload.formId]: {
          ...state[action.payload.formId],
          formState: createFormReducer(state[action.payload.formId].formState, action),
        },
      };
    case 'CREATE_FORM:UPDATE_COMMON_VALUES':
      return {
        ...state,
        [action.payload.formId]: {
          ...state[action.payload.formId],
          formState: createFormReducer(state[action.payload.formId].formState, action),
          meta: createMetaReducer(state[action.payload.formId].meta, action),
        },
      };
    case 'CREATE_FORM:SUBMIT_START':
    case 'CREATE_FORM:SET_CREATE_RESULT':
    case 'CREATE_FORM:SUBMIT_FINISHED':
    case 'CREATE_FORM:ENABLE_EXTENDED_MODE':
      return {
        ...state,
        [action.payload.formId]: {
          ...state[action.payload.formId],
          meta: createMetaReducer(state[action.payload.formId].meta, action),
        },
      };
    default:
      return state;
  }
};

export const getCreate = (state: StoreState): CreateReducerState => state.create;
export const getCreateMeta =
  (formId: CreateFormIdVariant) =>
  (state: StoreState): CreateMetaState =>
    getCreate(state)[formId].meta;
export const getCreateFormState =
  (formId: CreateFormIdVariant) =>
  (state: StoreState): CreateFormState =>
    getCreate(state)[formId].formState;

export const getCreateFormStateCandidate =
  (formId: CreateFormIdVariant) =>
  (state: StoreState, id: BookingCandidateId): CreateCandidate | undefined =>
    getCreateFormState(formId)(state).bookings[id];

export const getCreateFormStateInitialCandidate =
  (formId: CreateFormIdVariant) =>
  (state: StoreState, id: BookingCandidateId): CreateCandidate | undefined =>
    getCreateFormState(formId)(state).initialBookings[id];

export const getCreateResult =
  (formId: CreateFormIdVariant) =>
  (state: StoreState): CreateResult | null =>
    getCreateMeta(formId)(state).result;
export const getCreateFormIsSubmitting =
  (formId: CreateFormIdVariant) =>
  (state: StoreState): boolean =>
    getCreateMeta(formId)(state).submitting;

export const isExtendedModeEnabled = (state: StoreState) =>
  getCreateMeta('multi')(state).extendedMode;
