import { Column, Txt, useBoolean } from '@stenajs-webui/core';
import { FC } from 'react';
import { DetailedIntermodalBooking } from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { trackEvent } from '../../../common/tracking/trackerService';
import { useLocalDateFormat } from '../../../common/hooks/useLocalDateFormat';
import { useCancelIntermodalBooking } from '../../../services/intermodal/hooks/useCancelIntermodalBooking';
import { formatDate } from '../../../common/utils/dates/dateUtils';
import { CancelButtonWithPopover } from '../../../common-ui/CancelButtonWithPopover';

interface IntermodalCancelButtonProps {
  booking: DetailedIntermodalBooking;
  disabled?: boolean;
}

export const IntermodalCancelButton: FC<IntermodalCancelButtonProps> = ({ booking, disabled }) => {
  const [visible, , hide, toggle] = useBoolean(false);
  const { submitting, submit } = useCancelIntermodalBooking({
    bookingNo: booking.bookingNo,
    onSuccess: hide,
  });

  const dateFormat = useLocalDateFormat();

  const onConfirm = () => {
    trackEvent({ category: 'IntermodalUpdate', action: 'Confirm cancel booking' });
    submit();
  };

  const onAbort = () => {
    trackEvent({ category: 'IntermodalUpdate', action: 'Abort cancel booking' });
    hide();
  };

  const onCancelOpen = () => {
    trackEvent({ category: 'IntermodalUpdate', action: 'Cancel booking' });
    toggle();
  };

  return (
    <CancelButtonWithPopover
      onRequestClose={hide}
      visible={visible}
      submitting={submitting}
      onConfirm={onConfirm}
      onAbort={onAbort}
      onClick={onCancelOpen}
      disabled={disabled}
    >
      <Column>
        <Txt variant={'bold'}>{booking.bookingNo}</Txt>
        <Txt variant={'bold'}>
          {booking.journey.route.longDescription} ({booking.journey.route.id})
        </Txt>
        <Txt variant={'bold'}>
          {formatDate(booking.journey.departureDate, dateFormat)} at {booking.journey.departureTime}
        </Txt>
      </Column>
    </CancelButtonWithPopover>
  );
};
