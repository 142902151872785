import { graphql } from '../../../../gql';

export const bookingQuery = graphql(/* GraphQL */ `
  query BookingDetailsQuery($bookingNo: Int!) {
    viewer {
      bookings {
        booking: byBookingNo(bookingNo: $bookingNo) {
          ...BasicBooking
        }
      }
    }
  }
`);
