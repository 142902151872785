import { TrackerCategory } from '../../common/tracking/trackerService';
import {
  TrackerAction,
  useTrackingTextInput,
} from '../../use-cases/simple-create/hooks/UseTrackingTextInput';
import { FC } from 'react';
import { NumericTextInput, NumericTextInputProps } from './NumericTextInput';
import { CopyButton } from '../../use-cases/intermodal/common/ValueTxt';

export interface CommonNumericTextInputProps extends NumericTextInputProps {
  trackerCategory: TrackerCategory;
  editable?: boolean;
  trackerAction: TrackerAction;
  isValid?: boolean;
  changed?: boolean;
  showCopyButton?: boolean;
}

export const CommonNumericTextInputField: FC<CommonNumericTextInputProps> = ({
  name,
  isValid = true,
  trackerCategory,
  trackerAction,
  editable,
  changed,
  value,
  disabled,
  showCopyButton = false,
  ...props
}) => {
  const { onBlur, onFocus } = useTrackingTextInput(trackerAction, trackerCategory);

  return (
    <NumericTextInput
      variant={!isValid ? 'error' : undefined}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled || !editable}
      buttonRight={
        showCopyButton && value ? <CopyButton getValue={() => String(value)} /> : undefined
      }
      value={value}
      style={{
        fontStyle: changed ? 'italic' : 'normal',
        fontWeight: changed ? 'bold' : 'normal',
      }}
      {...props}
    />
  );
};
