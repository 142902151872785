import styled from '@emotion/styled';
import { Box } from '@stenajs-webui/core';
import { ClientRectObject } from '@floating-ui/react-dom-interactions';
import { CSSProperties, FC, memo } from 'react';

interface SpotlightProps {
  rect: ClientRectObject | undefined;
}

export const Spotlight: FC<SpotlightProps> = memo(({ rect }) => {
  if (!rect) {
    return null;
  }

  return (
    <SpotlightElement background={'gray'} position={'fixed'} style={getSpotlightStyle(rect, 0)} />
  );
});

const SpotlightElement = styled(Box)`
  transition: all 0.2s;
`;

function getSpotlightStyle(rect: ClientRectObject, padding = 4): CSSProperties {
  const width = Math.round(rect.width + padding * 2);
  const height = Math.round(rect.height + padding * 2);
  const left = Math.round(rect.left - padding);
  const top = Math.round(rect.top - padding);

  return {
    top: `${top}px`,
    left: `${left}px`,
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: '4px',
  };
}
