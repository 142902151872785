import styled from '@emotion/styled';
import { Box, Space, Txt } from '@stenajs-webui/core';
import { Link, PrimaryButton } from '@stenajs-webui/elements';
import { FC } from 'react';
import { cssColor } from '@stenajs-webui/theme';
import { ModalHeader } from '../../manage/details/components/modal/ModalHeader';

const List = styled.ul`
  margin: 0;
`;

export interface Co2EmissionsModalProps {
  onClose: () => void;
}

export const Co2EmissionsModal: FC<Co2EmissionsModalProps> = ({ onClose }) => {
  return (
    <Box width={624} maxWidth={'100%'}>
      <ModalHeader headerText={'How do we calculate emissions?'} onRequestClose={onClose} />
      <Box indent={3} spacing={2} gap={2}>
        <Box>
          <Txt>
            Stena Line measures the carbon emissions footprint of transported goods onboard our
            ships in the unit of gram CO<sub>2</sub>/ton-km. This common unit for emissions related
            to transport work allows you as a customer to benchmark emissions levels with other
            transportation modes.
          </Txt>
        </Box>
        <Box>
          <Txt>
            The CO<sub>2</sub> emissions caused by your shipment is based on three factors:
          </Txt>
          <Space />
          <List>
            <li>
              <Txt>The weight of carried freight, including vehicle tare (metric tons)</Txt>
            </li>
            <li>
              <Txt>One-way distance of the route (km)</Txt>
            </li>
            <li>
              <Txt>
                CO<sub>2</sub> emission intensity of the route (gCO<sub>2</sub>/ton-km)
              </Txt>
            </li>
          </List>
        </Box>
        <Box>
          <Txt>
            The CO<sub>2</sub> emission intensity varies with different factors such as type of
            fuel, type of ship, weather, ship speed, allocation between freight and passengers
            onboard, and the total weight of freight units. That is why there is a difference
            between the CO
            <sub>2</sub> emissions on different routes, which also may vary over time throughout the
            year, due to changes in our network and operations.
          </Txt>
        </Box>
        <Box>
          <Txt>
            The CO<sub>2</sub> intensity is calculated as the total emissions allocated to freight
            divided by the total amount of freight transport work done for each route. For the
            allocation of CO<sub>2</sub> emissions, Stena Line uses the methodology described in the
            ISO 14083 standard.
          </Txt>
        </Box>
        <Box>
          <Txt>
            Emission intensities are updated quarterly representing the preceding 12-month period.
          </Txt>
        </Box>
        <Box>
          <Txt>
            Reported values exclude any booked emission reductions related to Stena Line Insetting
            offer, thus avoiding double counting of sold Scope 3 emission reductions.
          </Txt>
        </Box>
        <Box display={'block'}>
          <Txt variant={'bold'}>Updating frequency:</Txt>
          <Txt>
            {' '}
            The emission factor per route is updated quarterly after the end of each quarter
            (Q1-Q4), which represents the preceding 12-month period. This ensures that you have
            access to the latest available estimates on CO<sub>2</sub> emissions from our ships.
          </Txt>
        </Box>
        <Box>
          <Txt>
            If you have any questions, please contact{' '}
            <Link href={'mailto:sustainability@stenaline.com'}>sustainability@stenaline.com</Link>
          </Txt>
        </Box>
        <Box>
          <Txt size={'small'} color={cssColor('--lhds-color-ui-600')}>
            Information last updated: 19th of August 2024
          </Txt>
        </Box>
        <Box alignItems={[null, 'flex-end']}>
          <PrimaryButton label={'Close'} onClick={onClose} />
        </Box>
      </Box>
    </Box>
  );
};
