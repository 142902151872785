import { Box } from '@stenajs-webui/core';
import { MediumIcon } from '@stenajs-webui/elements';
import { Toast } from '@stenajs-webui/panels';
import { CSSProperties, FC, ReactNode } from 'react';
import { CssPropColor } from '@stenajs-webui/theme';

export interface BaseToastProps {
  className?: string;
  icon: MediumIcon;
  title: string;
  onClose: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  style?: CSSProperties;
  children?: ReactNode;
  iconBackgroundColor?: CssPropColor;
}

export const BaseToast: FC<BaseToastProps> = ({
  className,
  icon,
  title,
  children,
  onClose,
  onMouseEnter,
  onMouseLeave,
  iconBackgroundColor,
}) => {
  return (
    <Box className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Toast
        icon={icon}
        text={title}
        onClose={onClose}
        width={'100%'}
        iconBackgroundColor={iconBackgroundColor}
      >
        {children}
      </Toast>
    </Box>
  );
};
