export type FrontendOutOfDateActions =
  | StartPollingFrontendOutOfDateAction
  | StopPollingFrontendOutOfDateAction
  | SetFrontendOutOfDateAction;

export interface StartPollingFrontendOutOfDateAction {
  type: 'FRONTEND_OUT_OF_DATE:START_POLLING';
}
export interface StopPollingFrontendOutOfDateAction {
  type: 'FRONTEND_OUT_OF_DATE:STOP_POLLING';
}
export interface SetFrontendOutOfDateAction {
  type: 'FRONTEND_OUT_OF_DATE:SET';
  payload: {
    isOutOfDate: boolean;
  };
}

export const startPollingFrontendOutOfDateAction = (): StartPollingFrontendOutOfDateAction => ({
  type: 'FRONTEND_OUT_OF_DATE:START_POLLING',
});

export const stopPollingFrontendOutOfDateAction = (): StopPollingFrontendOutOfDateAction => ({
  type: 'FRONTEND_OUT_OF_DATE:STOP_POLLING',
});

export const setFrontendOutOfDateAction = (isOutOfDate: boolean): SetFrontendOutOfDateAction => ({
  type: 'FRONTEND_OUT_OF_DATE:SET',
  payload: {
    isOutOfDate,
  },
});
