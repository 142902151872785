import { delay, put, takeLatest } from 'redux-saga/effects';
import { clearTempCreateResult } from '../../multi-create/multiCreateActions';
import { SetCreateResultAction } from '../createMetaActions';

function* queueClearCreateTempResult(_action: SetCreateResultAction) {
  yield delay(60000);
  yield put(clearTempCreateResult());
}

export function* watchCreateResultSaga() {
  yield takeLatest('CREATE_FORM:SET_CREATE_RESULT', queueClearCreateTempResult);
}
