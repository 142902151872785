export const zIndices = {
  cellStickyWrapperCol: 1,
  footer: 1,
  header: 1,
  stickyGridHeader: 2,
  gridOverlay: 10,
  drawer: 12,
  topMenuOverlay: 12,
  dropdown: 13,
  modal: 13,
  toasterWrapper: 20,
  loadingPageWrapper: 99,
  popoverOverlay: 10000,
  tourOverlay: 10100,
};
