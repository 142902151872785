import { Row, Space } from '@stenajs-webui/core';
import { InputSpinner } from '@stenajs-webui/elements';
import { FC, lazy, ReactNode, Suspense } from 'react';

const VehicleTypeIcon = lazy(() => import('./VehicleTypeIcon'));

export interface VehicleTypeRowWithIconProps {
  vehicleTypeCode: string;
  children?: ReactNode;
}

export const VehicleTypeRowWithIcon: FC<VehicleTypeRowWithIconProps> = ({
  children,
  vehicleTypeCode,
}) => {
  return (
    <Row alignItems="center" justifyContent="space-between">
      <Suspense fallback={<InputSpinner />}>
        <VehicleTypeIcon type={vehicleTypeCode} />
      </Suspense>
      <Space />
      <Row className={'t_vehicle_type_option'} flexGrow={1}>
        {children}
      </Row>
    </Row>
  );
};
