import { delay, put } from 'redux-saga/effects';
import {
  getUpdateSubmitInformation,
  UPDATE_SHOW,
} from '../../../common/update-information/utils/getUpdateSubmitInformation';
import { setUpdateResult, SubmitFinishedAction } from '../updateFormActions';

export function* cleanUpdateResult(action: SubmitFinishedAction) {
  if (getUpdateSubmitInformation(action.payload.updateResult).status === UPDATE_SHOW.SUCCESS) {
    yield delay(10 * 1000);
  } else {
    yield delay(60 * 1000);
  }

  yield put(setUpdateResult(action.payload.id, null));
}
