import { ReactNode, useRef } from 'react';
import { FreightPortalPopover } from './popover/FreightPortalPopover';
import { CancelPanelBase } from './CancelPanelBase';
import { SecondaryButton, stenaTimesCircle } from '@stenajs-webui/elements';

type CancelButtonWithPopoverProps = {
  onRequestClose: () => void;
  visible: boolean;
  submitting: boolean;
  onConfirm: () => void;
  onAbort: () => void;
  children: ReactNode;
  onClick: () => void;
  disabled: boolean | undefined;
};

export const CancelButtonWithPopover = ({
  children,
  disabled,
  onAbort,
  onClick,
  onConfirm,
  onRequestClose,
  submitting,
  visible,
}: CancelButtonWithPopoverProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <SecondaryButton
        ref={buttonRef}
        variant={'danger'}
        label={'Cancel booking'}
        className={'t_booking_details_cancel_link'}
        onClick={onClick}
        leftIcon={stenaTimesCircle}
        disabled={disabled}
      />
      <FreightPortalPopover
        reference={buttonRef}
        lazy
        onRequestClose={onRequestClose}
        visible={visible}
        content={
          <CancelPanelBase
            onClose={onRequestClose}
            submitting={submitting}
            onConfirm={onConfirm}
            onAbort={onAbort}
          >
            {children}
          </CancelPanelBase>
        }
      />
    </>
  );
};
