import { RequestCleanupCreateFormAction } from '../create/createFormActions';
import { SetCreateResultAction } from '../create/createMetaActions';

export type MultiCreateActions =
  | ShowCreatePanelAction
  | HideCreatePanelAction
  | CreateClearTempResultAction
  | SetCreateResultAction
  | RequestCleanupCreateFormAction;

export interface CreateClearTempResultAction {
  payload: {};
  type: 'MULTIPLE_CREATE:CLEAR_TEMP_RESULT';
}

export const clearTempCreateResult = (): CreateClearTempResultAction => ({
  payload: {},
  type: 'MULTIPLE_CREATE:CLEAR_TEMP_RESULT',
});

export interface ShowCreatePanelAction {
  payload: {};
  type: 'MULTIPLE_CREATE:SHOW';
}

export const showCreatePanel = (): ShowCreatePanelAction => ({
  payload: {},
  type: 'MULTIPLE_CREATE:SHOW',
});

export interface HideCreatePanelAction {
  payload: {};
  type: 'MULTIPLE_CREATE:HIDE';
}

export const hideCreatePanel = (): HideCreatePanelAction => ({
  payload: {},
  type: 'MULTIPLE_CREATE:HIDE',
});
