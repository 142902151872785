import { useDebounce } from '@stenajs-webui/core';
import { TextInput, TextInputProps } from '@stenajs-webui/forms';
import { FC, FocusEventHandler, useCallback, useEffect, useState } from 'react';

export interface DebouncedTextInputProps
  extends Omit<TextInputProps, 'onChange' | 'width' | 'value' | 'onValueChange' | 'style'> {
  editedValue: string;
  delay?: number;
  initialValue: string;
  maskInput?: (value: string) => string;
  onChange: (value: string) => void;
}

export const DebouncedTextInput: FC<DebouncedTextInputProps> = ({
  editedValue,
  delay = 1000,
  initialValue,
  maskInput = inputValue => inputValue,
  onChange,
  onBlur,
  ...props
}) => {
  const [value, setValue] = useState(editedValue);

  const onInputChange = useCallback(
    (inputValue: string) => {
      setValue(maskInput(inputValue));
    },
    [maskInput, setValue],
  );

  const onInternalBlur: FocusEventHandler<HTMLInputElement> = event => {
    onChange(value);
    onBlur?.(event);
  };

  useEffect(() => {
    setValue(editedValue);
  }, [editedValue]);

  const debouncedValue = useDebounce(value, delay);

  useEffect(
    () => {
      if (initialValue !== value) {
        onChange(debouncedValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedValue],
  );

  return (
    <TextInput
      {...props}
      onBlur={onInternalBlur}
      onValueChange={onInputChange}
      value={value}
      width={'100%'}
      style={{
        fontStyle: value !== initialValue ? 'italic' : 'normal',
        fontWeight: value !== initialValue ? 'bold' : 'normal',
      }}
    />
  );
};
