import { Txt } from '@stenajs-webui/core';
import { Icon } from '@stenajs-webui/elements';
import { CSSProperties, ReactNode } from 'react';
import { BookingSpecialPropertyIcon } from '../../../../common-ui/BookingSpecialPropertiesIcon';
import { BookingStatusTag } from '../../../../common-ui/BookingStatusTag';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { SEVERAL_ICON } from '../../grid/utils/bookingSpecialProperties';
import { LoadingPreferenceCell } from './LoadingPreferenceCell';

type BookingSwitchModalKey = keyof BasicBooking;

export interface SwitchModalColumn {
  label: string | ReactNode;
  id: BookingSwitchModalKey;
  headerStyle?: CSSProperties;
}

export const switchModalCandidateColumns: SwitchModalColumn[] = [
  {
    label: <Icon icon={SEVERAL_ICON} />,
    id: 'specialProperties',
    headerStyle: {
      justifyContent: 'center',
    },
  },
  { label: 'Status', id: 'status', headerStyle: { width: 135 } },
  { label: 'Booking', id: 'bookingNo' },
  { label: 'Vehicle reg', id: 'vehicleRegNo' },
  { label: 'Trailer reg', id: 'trailerRegNo' },
  { label: 'Vehicle type', id: 'vehicleType' },
  { label: 'Dimensions', id: 'width' },
  { label: 'Loading pref.', id: 'loadingPreference' },
  { label: 'Customer ref.', id: 'customerReference' },
];

export const renderSwitchColumn = (booking: BasicBooking, column: SwitchModalColumn): ReactNode => {
  const { length, width, height } = booking;
  switch (column.id) {
    case 'specialProperties':
      return <BookingSpecialPropertyIcon specialProperties={booking.specialProperties} />;
    case 'status':
      return (
        <BookingStatusTag
          status={booking.bookingStatus}
          quayStatus={booking.bookingQuayStatus}
          size={'small'}
        />
      );
    case 'bookingNo':
    case 'vehicleRegNo':
    case 'trailerRegNo':
    case 'customerReference':
      return <Txt whiteSpace={'nowrap'}>{booking[column.id]}</Txt>;
    case 'customer':
      return <Txt whiteSpace={'nowrap'}>{booking.customer.custIndex}</Txt>;
    case 'vehicleType':
      return <Txt whiteSpace={'nowrap'}>{booking.vehicleType.description}</Txt>;
    case 'width':
      return <Txt whiteSpace={'nowrap'}>{`${length} x ${width} x ${height}`}</Txt>;
    case 'loadingPreference':
      return <LoadingPreferenceCell booking={booking} />;
    default:
      return null;
  }
};
