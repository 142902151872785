import { Sailing } from '../../../common/graphql/fragments/gql/Sailing';
import { formatArrivalDateTime } from '../../../use-cases/manage/details/utils/arrivalInformation';

export const getExtendedArrivalInformation = (
  hasArrived: boolean,
  sailing: Sailing | null,
  dateFormat: string,
) => {
  if (!sailing) {
    return null;
  }

  if (hasArrived && sailing.realArrivalTime) {
    return `Arrived ${formatArrivalDateTime(
      sailing.arrivalDate,
      sailing.realArrivalDate,
      sailing.realArrivalTime,
      dateFormat,
    )}`;
  } else if (sailing.estimatedArrivalTime) {
    return `Estimated ${formatArrivalDateTime(
      sailing.arrivalDate,
      sailing.estimatedArrivalDate,
      sailing.estimatedArrivalTime,
      dateFormat,
    )}`;
  }
  return null;
};

export const getShortArrivalInformation = (
  hasArrived: boolean,
  sailing: Sailing,
  dateFormat: string,
) => {
  if (hasArrived && sailing.realArrivalTime) {
    return `ATA ${formatArrivalDateTime(
      sailing.arrivalDate,
      sailing.realArrivalDate,
      sailing.realArrivalTime,
      dateFormat,
    )}`;
  } else if (sailing.estimatedArrivalTime) {
    return `ETA ${formatArrivalDateTime(
      sailing.arrivalDate,
      sailing.estimatedArrivalDate,
      sailing.estimatedArrivalTime,
      dateFormat,
    )}`;
  }
  return null;
};
