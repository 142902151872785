import { graphql } from '../../../gql';

export const updateSettingsMutation = graphql(/*GraphQL*/ `
  mutation UpdateSettings($settings: UpdateSettingsInput!) {
    updateSettings(settings: $settings) {
      settings {
        ...Setting
      }
      validation {
        ...ValidationFields
      }
      errors {
        description
      }
      success
    }
  }
`);

export const validationFieldsFragment = graphql(/* GraphQL */ `
  fragment ValidationFields on UpdateSettingsValidationErrors {
    accountEmail {
      ...SettingsValidationError
    }
    accountFirstName {
      ...SettingsValidationError
    }
    accountLastName {
      ...SettingsValidationError
    }
    accountLoginEmail {
      ...SettingsValidationError
    }
    accountPhone {
      ...SettingsValidationError
    }
    accountSecondEmail {
      ...SettingsValidationError
    }
    accountSendBookingConfirmationAsEmail {
      ...SettingsValidationError
    }
    bookCustNo {
      ...SettingsValidationError
    }
    bookDate {
      ...SettingsValidationError
    }
    bookDefaultRouteCode {
      ...SettingsValidationError
    }
    bookDefaultVehicleTypeCode {
      ...SettingsValidationError
    }
    dashboardNotCheckedInTime {
      ...SettingsValidationError
    }
    dashboardShowAttention {
      ...SettingsValidationError
    }
    dashboardShowNotCheckedIn {
      ...SettingsValidationError
    }
    dashboardShowPendingApproval {
      ...SettingsValidationError
    }
    dashboardShowDashboardOnScroll {
      ...SettingsValidationError
    }
    dashboardShowDateOverview {
      ...SettingsValidationError
    }
    dashboardShowUnaccompaniedUnits {
      ...SettingsValidationError
    }
    dateFormat {
      ...SettingsValidationError
    }
    favouriteRouteCodes {
      ...SettingsValidationError
    }
    favouriteVehicleTypeCodes {
      ...SettingsValidationError
    }
    filterDateInterval {
      ...SettingsValidationError
    }
    filterFilterStates {
      ...SettingsValidationError
    }
    filterRouteCodes {
      ...SettingsValidationError
    }
  }
`);

export const settingsValidationErrorFragment = graphql(/* GraphQL */ `
  fragment SettingsValidationError on FieldValidationError {
    code
    description
  }
`);
