export const dateRegionFormats: Record<string, string> = {
  'ar-dz': 'dd/MM/yyyy',
  'ar-kw': 'dd/MM/yyyy',
  'ar-ly': 'd/\u200FM/\u200Fyyyy',
  'ar-ma': 'dd/MM/yyyy',
  'ar-sa': 'dd/MM/yyyy',
  'de-at': 'dd.MM.yyyy',
  'de-ch': 'dd.MM.yyyy',
  'en-au': 'dd/MM/yyyy',
  'en-ca': 'yyyy-MM-dd',
  'en-gb': 'dd/MM/yyyy',
  'en-ie': 'dd/MM/yyyy',
  'en-il': 'dd/MM/yyyy',
  'en-nz': 'dd/MM/yyyy',
  'en-SG': 'dd/MM/yyyy',
  'es-do': 'dd/MM/yyyy',
  'es-us': 'MM/dd/yyyy',
  'fr-ca': 'yyyy-MM-dd',
  'fr-ch': 'dd.MM.yyyy',
  'gom-latn': 'dd-MM-yyyy',
  'hy-am': 'dd.MM.yyyy',
  'it-ch': 'dd.MM.yyyy',
  'ms-my': 'dd/MM/yyyy',
  'nl-be': 'dd/MM/yyyy',
  'pa-in': 'dd/MM/yyyy',
  'pt-br': 'dd/MM/yyyy',
  'tl-ph': 'MM/d/yyyy',
  'tzm-latn': 'dd/MM/yyyy',
  'uz-latn': 'dd/MM/yyyy',
  'x-pseudo': 'dd/MM/yyyy',
  'zh-cn': 'yyyy/MM/dd',
  'zh-hk': 'yyyy/MM/dd',
  'zh-tw': 'yyyy/MM/dd',
  af: 'dd/MM/yyyy',
  ar: 'd/\u200FM/\u200Fyyyy',
  az: 'dd.MM.yyyy',
  be: 'dd.MM.yyyy',
  bg: 'd.MM.yyyy',
  bm: 'dd/MM/yyyy',
  bn: 'dd/MM/yyyy',
  bo: 'dd/MM/yyyy',
  br: 'dd/MM/yyyy',
  bs: 'dd.MM.yyyy',
  ca: 'dd/MM/yyyy',
  cs: 'dd.MM.yyyy',
  cv: 'dd-MM-yyyy',
  da: 'dd.MM.yyyy',
  de: 'dd.MM.yyyy',
  dv: 'd/m/yyyy',
  el: 'dd/MM/yyyy',
  eo: 'yyyy-MM-dd',
  es: 'dd/MM/yyyy',
  et: 'dd.MM.yyyy',
  eu: 'yyyy-MM-dd',
  fa: 'dd/MM/yyyy',
  fi: 'dd.MM.yyyy',
  fo: 'dd/MM/yyyy',
  fr: 'dd/MM/yyyy',
  fy: 'dd-MM-yyyy',
  gd: 'dd/MM/yyyy',
  gl: 'dd/MM/yyyy',
  he: 'dd/MM/yyyy',
  hi: 'dd/MM/yyyy',
  hr: 'dd.MM.yyyy',
  hu: 'yyyy.MM.dd.',
  id: 'dd/MM/yyyy',
  is: 'dd.MM.yyyy',
  it: 'dd/MM/yyyy',
  ja: 'yyyy/MM/dd',
  jv: 'dd/MM/yyyy',
  ka: 'dd/MM/yyyy',
  kk: 'dd.MM.yyyy',
  kn: 'dd/MM/yyyy',
  ko: 'yyyy.MM.dd.',
  ku: 'dd/MM/yyyy',
  ky: 'dd.MM.yyyy',
  lo: 'dd/MM/yyyy',
  lt: 'yyyy-MM-dd',
  lv: 'dd.MM.yyyy.',
  mk: 'd.MM.yyyy',
  ml: 'dd/MM/yyyy',
  mn: 'yyyy-MM-dd',
  mr: 'dd/MM/yyyy',
  ms: 'dd/MM/yyyy',
  mt: 'dd/MM/yyyy',
  nb: 'dd.MM.yyyy',
  ne: 'dd/MM/yyyy',
  nl: 'dd-MM-yyyy',
  nn: 'dd.MM.yyyy',
  pl: 'dd.MM.yyyy',
  pt: 'dd/MM/yyyy',
  ro: 'dd.MM.yyyy',
  ru: 'dd.MM.yyyy',
  sd: 'dd/MM/yyyy',
  se: 'dd.MM.yyyy',
  si: 'yyyy/MM/dd',
  sk: 'dd.MM.yyyy',
  sl: 'dd.MM.yyyy',
  sq: 'dd/MM/yyyy',
  ss: 'dd/MM/yyyy',
  sv: 'yyyy-MM-dd',
  sw: 'dd.MM.yyyy',
  ta: 'dd/MM/yyyy',
  te: 'dd/MM/yyyy',
  tet: 'dd/MM/yyyy',
  tg: 'dd/MM/yyyy',
  th: 'dd/MM/yyyy',
  tlh: 'dd.MM.yyyy',
  tr: 'dd.MM.yyyy',
  tzl: 'dd.MM.yyyy',
  tzm: 'dd/MM/yyyy',
  uk: 'dd.MM.yyyy',
  ur: 'dd/MM/yyyy',
  uz: 'dd/MM/yyyy',
  vi: 'dd/MM/yyyy',
  yo: 'dd/MM/yyyy',
};

export const formatsToUse = [
  'yyyy-MM-dd',
  'yyyy/MM/dd',
  'yyyy.MM.dd',
  'dd-MM-yyyy',
  'dd/MM/yyyy',
  'dd.MM.yyyy',
  'd.MM.yyyy',
];
