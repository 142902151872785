import { graphql } from '../../gql';

export const travelledVehicleQuery = graphql(/*GraphQL*/ `
  query TravelledVehicleQuery($customerNo: Int!, $registrationNumber: String!) {
    viewer {
      travelledVehicle {
        travelledVehicle(customerNo: $customerNo, registrationNumber: $registrationNumber) {
          travelledVehicle {
            ...TravelledVehicle
          }
        }
      }
    }
  }
`);
