import { matchPath } from 'react-router-dom';
import { getOverrideMainPage } from '../../services/authentication/utils/authUtils';
import { appRoutes, routeCreators } from './AppRoutes';

export const isOnManagePage = (location: { pathname: string }): boolean =>
  location.pathname === '/' ||
  matchPath(
    {
      path: appRoutes.bookings,
      end: true,
    },
    location.pathname,
  ) != null;

export const isOnPaymentPage = (location: { pathname: string }): boolean =>
  matchPath(
    {
      path: appRoutes.payment,
      end: true,
    },
    location.pathname,
  ) != null;

export const isOnSimpleCreatePage = (location: { pathname: string }): boolean =>
  matchPath(
    {
      path: appRoutes.create,
      end: true,
    },
    location.pathname,
  ) != null;

export const isOnSettingsPage = (location: { pathname: string }): boolean =>
  matchPath(
    {
      path: appRoutes.settings,
      end: true,
    },
    location.pathname,
  ) != null;

export const getReturnToPath = (
  scope: string | undefined,
  returnToParam: string | null,
): string => {
  if (returnToParam) {
    return returnToParam;
  }

  return getMainPage(scope ?? '');
};

export const getMainPage = (scope: string) => {
  const override = getOverrideMainPage(scope);

  return override ?? routeCreators.bookings();
};
