import * as React from 'react';
import { Tag, TagProps } from '@stenajs-webui/elements';

const getPartnerBookingStatusTagProps = (
  partnerBookingStatus: boolean | undefined,
): Partial<TagProps> => {
  if (partnerBookingStatus) {
    return { label: 'Confirmed', variant: 'success' };
  } else {
    return { label: 'Unconfirmed', variant: 'warning' };
  }
};

interface Props {
  partnerBookingStatus: boolean | undefined;
}

export const PartnerBookingStatusTag: React.FC<Props> = ({ partnerBookingStatus }) => {
  const tagProps = getPartnerBookingStatusTagProps(partnerBookingStatus);

  return <Tag {...tagProps} size={'small'} />;
};
