import { FormInstanceId } from './updateFormReducer';

export interface UpdateBookingFormSetVehicleTypeAction {
  type: 'UPDATE_FORM:TRAVELLED_VEHICLE:SET_VEHICLE_TYPE';
  payload: {
    id: FormInstanceId;
  };
}

export const setVehicleTypeFromTravelledVehicle = (
  id: FormInstanceId,
): UpdateBookingFormSetVehicleTypeAction => ({
  payload: {
    id,
  },
  type: 'UPDATE_FORM:TRAVELLED_VEHICLE:SET_VEHICLE_TYPE',
});

export interface UpdateBookingFormRestoreDimensionAction {
  type: 'UPDATE_FORM:TRAVELLED_VEHICLE:RESTORE_DIMENSION';
  payload: {
    id: FormInstanceId;
    dimensionName: 'height' | 'length';
  };
}

export const restoreDimensionFromTravelledVehicle = (
  id: FormInstanceId,
  dimensionName: 'height' | 'length',
): UpdateBookingFormRestoreDimensionAction => ({
  payload: {
    dimensionName,
    id,
  },
  type: 'UPDATE_FORM:TRAVELLED_VEHICLE:RESTORE_DIMENSION',
});
