import { Box, Row, Txt } from '@stenajs-webui/core';
import { CloseButton } from '@stenajs-webui/elements';
import { FC, ReactNode } from 'react';
import { DRAGGABLE_CANCEL_CLASSNAME, DRAGGABLE_HANDLE_CLASSNAME } from './BaseModal';

export interface ModalHeaderProps {
  onRequestClose?: () => void;
  header?: ReactNode;
  headerText?: string;
  closeButtonClassName?: string;
}

export const ModalHeader: FC<ModalHeaderProps> = ({
  onRequestClose,
  header,
  headerText,
  closeButtonClassName,
}) => {
  return (
    <Row spacing={2} indent={1} className={DRAGGABLE_HANDLE_CLASSNAME}>
      <Row flex={1} indent alignItems={'center'}>
        {headerText && (
          <Txt className={DRAGGABLE_CANCEL_CLASSNAME} size={'large'}>
            {headerText}
          </Txt>
        )}
        {header}
      </Row>
      <Box alignSelf={'flex-start'} indent>
        <CloseButton className={closeButtonClassName} onClick={onRequestClose} />
      </Box>
    </Row>
  );
};
