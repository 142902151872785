import { ApolloError } from '@apollo/client';
import { SearchBookingsResult } from '../../../../common/graphql/useSearchBookingsResult';
import { Box, Row, SeparatorLine, Space, Txt } from '@stenajs-webui/core';
import { Loading } from '../../../../common-ui/Loading';
import { cssColor } from '@stenajs-webui/theme';
import { BookingSearchTable } from './BookingSearchTable';
import { FC } from 'react';
import { BookingSearchTableHeaderRow } from './BookingSearchTableHeaderRow';
import { SearchResultColumn } from '../utils/searchResultColumns';

interface BookingSearchListInnerContentProps {
  loading: boolean;
  error?: ApolloError;
  data: SearchBookingsResult;
  searchQuery: string;
  onClickResult: (bookingNo: number) => void;
  onTransportClick: () => void;
  onHistoryClick: () => void;
  searchColumns: SearchResultColumn[];
}

export const BookingSearchListInnerContent: FC<BookingSearchListInnerContentProps> = ({
  onClickResult,
  data,
  loading,
  searchQuery,
  error,
  onHistoryClick,
  onTransportClick,
  searchColumns,
}) => {
  if (loading) {
    return (
      <Row spacing={2} indent={2} justifyContent={'center'}>
        <Loading />
      </Row>
    );
  }

  if (error) {
    return (
      <Row spacing={2} indent={2} className={'t_search_error'}>
        <Txt color={cssColor('--lhds-color-red-500')}>Something went wrong, please try again.</Txt>
      </Row>
    );
  }

  if (searchQuery.length === 0) {
    return (
      <Row spacing={2} indent={2} className={'t_search_input_empty'}>
        <Txt>
          Type to search for booking numbers, vehicle and trailer registration numbers, and customer
          references
        </Txt>
      </Row>
    );
  }

  return (
    <>
      {data.historicTransportsCount === 0 && data.activeTransportsCount === 0 ? (
        <Row spacing={2} indent={2} className={'t_search_empty'}>
          <Txt>Your search query did not give any results. Try searching for something else</Txt>
        </Row>
      ) : (
        <>
          <Box display={['none', 'flex']}>
            <BookingSearchTableHeaderRow searchColumns={searchColumns} />
            <SeparatorLine color={cssColor('--lhds-color-ui-300')} />
          </Box>
          {data.activeTransportsCount > 0 && (
            <BookingSearchTable
              searchColumns={searchColumns}
              onClickResult={onClickResult}
              linkClassName={'t_search_planned_transports'}
              items={data.activeTransportsItems}
              label={'Planned Transports'}
              onTotalCountClick={onTransportClick}
              totalCount={data.activeTransportsCount}
              searchQuery={searchQuery}
            />
          )}
          {data.historicTransportsCount > 0 && (
            <>
              {data.activeTransportsCount > 0 && (
                <>
                  <Space />
                  <SeparatorLine color={cssColor('--lhds-color-ui-300')} />
                  <Space />
                </>
              )}
              <BookingSearchTable
                searchColumns={searchColumns}
                onClickResult={onClickResult}
                items={data.historicTransportsItems}
                label={'History'}
                linkClassName={'t_search_history_transports'}
                onTotalCountClick={onHistoryClick}
                totalCount={data.historicTransportsCount}
                searchQuery={searchQuery}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
