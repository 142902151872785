import { Column } from '@stenajs-webui/core';
import { Banner, ResultListBanner, stenaHazardous, stenaPlugin } from '@stenajs-webui/elements';
import { useSelector } from 'react-redux';
import { DetailedIntermodalBooking } from '../../../../common/graphql/fragments/gql/IntermodalBooking';
import {
  getBannerVariantForMessageResultSeverity,
  getMessagesResult,
} from '../../../../common/update-information/utils/messagesResult';
import {
  getAllIntermodalUpdateValidationErrors,
  getUpdateIntermodalBookingEditedFormState,
  getUpdateIntermodalBookingUpdateResult,
} from '../../../../services/intermodal/updateIntermodalReducer';
import { resolveIntermodalStatusMessage } from '../../utils/detailsUtils';
import { Texts } from '../../../../common/texts';
import { HazardousGoodsStatus, IntermodalBookingStatus } from '../../../../gql/graphql';

interface Props {
  booking: DetailedIntermodalBooking;
}

function ValidationErrorsBanners() {
  const allValidationErrors = useSelector(getAllIntermodalUpdateValidationErrors);

  if (allValidationErrors.length === 0) {
    return null;
  }

  return (
    <ResultListBanner
      variant={'error'}
      bannerState={{
        headerText: 'Booking was not updated',
        items: allValidationErrors.map(description => ({ text: description })),
      }}
    />
  );
}

const StatusBanner = ({ booking }: Props) => {
  const statusInformation = resolveIntermodalStatusMessage(booking.status.status);

  if (!statusInformation) {
    return null;
  }

  return <Banner variant={statusInformation.variant} text={statusInformation.message} />;
};

const BookingInfoBanners = ({ booking }: Props) => {
  const edited = useSelector(getUpdateIntermodalBookingEditedFormState);

  if (booking.status.status === IntermodalBookingStatus.Cancelled) {
    return null;
  }

  return (
    <>
      {(edited.hazardousGoods || booking.hazardousGoods === HazardousGoodsStatus.Requested) && (
        <Banner variant={'warning'} text={Texts.HazardousGoodsRequested} icon={stenaHazardous} />
      )}
      {(edited.noOfPlugins > 0 || booking.noOfPlugins > 0) && (
        <Banner variant={'info'} text={Texts.Plugin} icon={stenaPlugin} />
      )}
    </>
  );
};

function MessageResultBanners() {
  const updateResult = useSelector(getUpdateIntermodalBookingUpdateResult);

  if (!updateResult) {
    return null;
  }

  const messagesResult = getMessagesResult(updateResult);

  if (messagesResult) {
    const bannerVariant = getBannerVariantForMessageResultSeverity(messagesResult.severity);

    return (
      <ResultListBanner
        variant={bannerVariant}
        bannerState={{
          headerText:
            messagesResult.severity === 'error'
              ? 'Booking was not updated'
              : 'Your changes were saved',
          items: messagesResult.messages.map(description => ({ text: description })),
        }}
      />
    );
  } else if (updateResult.bookingOutOfDate) {
    return (
      <ResultListBanner
        variant={'info'}
        bannerState={{
          headerText: 'Booking is out of date',
          items: [
            {
              text: 'Conflicting booking details were detected during update. The most recently saved changes have been loaded',
            },
          ],
        }}
      />
    );
  } else if (updateResult.success) {
    return <Banner variant={'success'} text={'Your changes were saved'} />;
  }
}

export const UpdateIntermodalInformationBanners = ({ booking }: Props) => {
  return (
    <Column gap={1} className={'t_intermodal_banners'}>
      <StatusBanner booking={booking} />
      <BookingInfoBanners booking={booking} />
      <ValidationErrorsBanners />
      <MessageResultBanners />
    </Column>
  );
};
