import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { graphql } from '../../../gql';

const mutation = graphql(/* GraphQL */ `
  mutation ImportExportRefreshStatusButtonMutation($bookingNo: Int!) {
    refreshBookingImportExportStatus(bookingNo: $bookingNo) {
      booking {
        ...BasicBooking
      }
      errors {
        description
        field
      }
      messages {
        description
      }
      warnings {
        description
        field
      }
      success
    }
  }
`);

export const useRefreshImportExportStatus = () => {
  const [mutate, { loading, error }] = useMutation(mutation);

  const refreshImportExportStatus = useCallback(
    async (bookingNo: number) => {
      return await mutate({ variables: { bookingNo } });
    },
    [mutate],
  );

  return {
    refreshImportExportStatus,
    loading,
    error,
  };
};
