import { all, takeEvery } from 'redux-saga/effects';
import {
  updateColumnsEnabledPageSettings,
  updateColumnsOrderPageSettings,
  updateColumnsWidthPageSettings,
  updateColumnsWithDefaultSettings,
} from './sagas/columns';
import { watchSubmitPageSettings } from './sagas/common';
import { updateBookingsPerLoadPageSettings, updateCompactModePageSettings } from './sagas/grid';
import { updateFeaturesSeenPageSettings, updateWelcomeTourPageSettings } from './sagas/tour';
import { updateSidebarPageSettings } from './sagas/sidebar';

export function* watchPageSettingsSaga() {
  yield all([
    takeEvery(
      ['COLUMN_FILTER:ADD_COLUMN', 'COLUMN_FILTER:REMOVE_COLUMN'],
      updateColumnsEnabledPageSettings,
    ),
    takeEvery('COLUMN_FILTER:SET_COLUMN_ORDER', updateColumnsOrderPageSettings),
    takeEvery('COLUMN_FILTER:CHANGE_WIDTH', updateColumnsWidthPageSettings),
    takeEvery('COLUMN_FILTER:CLEAR_COLUMNS', updateColumnsWithDefaultSettings),
    takeEvery('GRID:SET_COMPACT_MODE', updateCompactModePageSettings),
    takeEvery('GRID:SET_BOOKINGS_PER_LOAD', updateBookingsPerLoadPageSettings),
    takeEvery('WELCOME_TOUR:REQUEST_STOP', updateWelcomeTourPageSettings),
    takeEvery('NEW_FEATURE_TOUR:STOP', updateFeaturesSeenPageSettings),
    takeEvery(['SIDEBAR:ENABLE_RAIL', 'SIDEBAR:DISABLE_RAIL'], updateSidebarPageSettings),
    watchSubmitPageSettings(),
  ]);
}
