import { Column, Heading } from '@stenajs-webui/core';
import { FC } from 'react';

interface Props {
  label: string;
  className?: string;
}

export const PaymentTableHeaderCell: FC<Props> = ({ label, className }) => (
  <Column className={'heading ' + className}>
    <Heading variant={'h6'}>{label}</Heading>
  </Column>
);
