import { routeCreators } from '../../../common/routes/AppRoutes';
import { AuthResult } from '../../../use-cases/auth/types';
import { AuthTokenScope } from '../authReducer';

export const resolveUserDisplayName = (authResult: AuthResult | null) => {
  return authResult ? authResult.idTokenPayload.sub.replace('auth0|EXTRANET\\', '') : '';
};

export const resolveUserName = (authResult: AuthResult | undefined | null): string => {
  return authResult ? authResult.idTokenPayload.sub.replace('auth0|', '') : '';
};

export const getHasAuthScope = (scopeString: string, scope: AuthTokenScope): boolean => {
  return scopeString.includes(scope);
};

export const getOverrideMainPage = (scope: string): string | undefined => {
  if (getHasAuthScope(scope, AuthTokenScope.WriteBooking)) {
    return undefined;
  } else if (
    getHasAuthScope(scope, AuthTokenScope.ReadIntermodal) ||
    getHasAuthScope(scope, AuthTokenScope.WriteIntermodal)
  ) {
    return routeCreators.intermodal();
  } else if (getHasAuthScope(scope, AuthTokenScope.ReadInvoices)) {
    return routeCreators.financialDocuments();
  } else {
    return undefined;
  }
};

export enum Permission {
  ViewBookings,
  EditBookings,
  Statistics,
  Invoices,
  ViewIntermodalBookings,
  EditIntermodalBookings,
  Payment,
  PriceCalculation,
}

export const getHasPermission = (
  permission: Permission,
  scopeString: string,
  hasCashCustomer: boolean,
) => {
  const authTokenScope = getScopeFromPermission(permission);

  if (authTokenScope) {
    return getHasAuthScope(scopeString, authTokenScope);
  }

  if (permission === Permission.Payment) {
    return hasCashCustomer;
  }

  return false;
};

function getScopeFromPermission(permission: Permission): AuthTokenScope | undefined {
  switch (permission) {
    case Permission.ViewBookings:
      return AuthTokenScope.ReadBooking;
    case Permission.EditBookings:
      return AuthTokenScope.WriteBooking;
    case Permission.PriceCalculation:
      return AuthTokenScope.ReadPricing;
    case Permission.Statistics:
      return AuthTokenScope.ReadStatistics;
    case Permission.Invoices:
      return AuthTokenScope.ReadInvoices;
    case Permission.EditIntermodalBookings:
      return AuthTokenScope.WriteIntermodal;
    case Permission.ViewIntermodalBookings:
      return AuthTokenScope.ReadIntermodal;
    default:
      return undefined;
  }
}
