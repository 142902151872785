import { FC } from 'react';
import {
  ValidationWrapper,
  ValidationWrapperProps,
} from '../../../common-ui/form-fields/ValidationWrapper';
import {
  CreateIntermodalBookingFormModel,
  CreateIntermodalBookingGoodFormModel,
} from '../../../services/intermodal/createIntermodalReducer';
import { useCreateIntermodalGoodsField } from '../hooks/useCreateIntermodalGoodsFields';
import { useCreateIntermodalField } from '../hooks/useCreateIntermodalField';

interface CreateIntermodalValidationWrapperProps
  extends Omit<ValidationWrapperProps, 'validationError'> {
  name: keyof CreateIntermodalBookingFormModel;
}

export const CreateIntermodalValidationWrapper: FC<CreateIntermodalValidationWrapperProps> = ({
  name,
  ...props
}) => {
  const { validationError } = useCreateIntermodalField(name);
  return <ValidationWrapper validationError={validationError} {...props} />;
};

interface CreateIntermodalGoodsValidationWrapperProps
  extends Omit<ValidationWrapperProps, 'validationError'> {
  name: keyof CreateIntermodalBookingGoodFormModel;
  goodId: string;
}

export const CreateIntermodalGoodsValidationWrapper: FC<
  CreateIntermodalGoodsValidationWrapperProps
> = ({ name, goodId, ...props }) => {
  const { validationError } = useCreateIntermodalGoodsField(goodId, name);
  return <ValidationWrapper validationError={validationError} {...props} />;
};
