import { Omit } from '@stenajs-webui/core';
import { CreateBookings_createBookings_results } from '../../use-cases/multiple-create/graphql/gql/CreateBookings';
import { CreateBookingFormRevertValuesAction } from './arrivalNotepadActions';
import { CreateCandidate, CreateFormState } from './createFormReducer';
import { RegistrationNumberAlertFormState } from '../update-form/registrationNumberAlert';

export type CreateFormActions =
  | CreateFormUpdateCommonValuesAction
  | CreateFormSetSpecificValuesAction
  | CreateFormUpdateSpecificValuesAction
  | CreateFormSetAllSpecificValuesAction
  | CreateFormUpdateAllSpecificValuesAction
  | CreateFormRemoveBookingCandidateAction
  | InitializeCreateFormAction
  | RequestCleanupCreateFormAction
  | CreateRequestSubmitAction
  | SetBookingsToKeepInCreateFormAction
  | SetNumberOfBookingsCreateFormAction
  | CreateBookingFormRevertValuesAction
  | CreateRegistrationNumberAlertAction;

export interface CreateResult {
  result?: CreateBookings_createBookings_results[];
  genericError?: string;
}

export type CreateFormIdVariant = 'simple' | 'multi';

export type BookingCandidateId = number;

export interface CreateFormUpdateCommonValuesAction {
  payload: {
    formId: CreateFormIdVariant;
    values: Partial<Omit<CreateFormState, 'bookings'>>;
  };
  type: 'CREATE_FORM:UPDATE_COMMON_VALUES';
}

export const updateCommonValues = (
  formId: CreateFormIdVariant,
  values: Partial<Omit<CreateFormState, 'bookings'>>,
): CreateFormUpdateCommonValuesAction => ({
  payload: {
    formId,
    values,
  },
  type: 'CREATE_FORM:UPDATE_COMMON_VALUES',
});

export interface CreateFormUpdateSpecificValuesAction {
  payload: {
    formId: CreateFormIdVariant;
    id: BookingCandidateId;
    values: Partial<CreateCandidate>;
  };
  type: 'CREATE_FORM:UPDATE_SPECIFIC_VALUES';
}

export const updateSpecificValues = (
  formId: CreateFormIdVariant,
  id: BookingCandidateId,
  values: Partial<CreateCandidate>,
): CreateFormUpdateSpecificValuesAction => ({
  payload: {
    formId,
    id,
    values,
  },
  type: 'CREATE_FORM:UPDATE_SPECIFIC_VALUES',
});

export interface CreateFormSetSpecificValuesAction {
  payload: {
    formId: CreateFormIdVariant;
    id: BookingCandidateId;
    values: Partial<CreateCandidate>;
  };
  type: 'CREATE_FORM:SET_SPECIFIC_VALUES';
}

export const setSpecificValues = (
  formId: CreateFormIdVariant,
  id: BookingCandidateId,
  values: Partial<CreateCandidate>,
): CreateFormSetSpecificValuesAction => ({
  payload: {
    formId,
    id,
    values,
  },
  type: 'CREATE_FORM:SET_SPECIFIC_VALUES',
});

export interface CreateFormSetAllSpecificValuesAction {
  payload: {
    formId: CreateFormIdVariant;
    values: Partial<CreateCandidate>;
  };
  type: 'CREATE_FORM:SET_ALL_SPECIFIC_VALUES';
}

export const setAllSpecificValues = (
  formId: CreateFormIdVariant,
  values: Partial<CreateCandidate>,
): CreateFormSetAllSpecificValuesAction => ({
  payload: {
    formId,
    values,
  },
  type: 'CREATE_FORM:SET_ALL_SPECIFIC_VALUES',
});

export interface CreateFormUpdateAllSpecificValuesAction {
  payload: {
    formId: CreateFormIdVariant;
    values: Partial<CreateCandidate>;
  };
  type: 'CREATE_FORM:UPDATE_ALL_SPECIFIC_VALUES';
}

export const updateAllSpecificValues = (
  formId: CreateFormIdVariant,
  values: Partial<CreateCandidate>,
): CreateFormUpdateAllSpecificValuesAction => ({
  payload: {
    formId,
    values,
  },
  type: 'CREATE_FORM:UPDATE_ALL_SPECIFIC_VALUES',
});

interface SetBookingsToKeepInCreateFormAction {
  payload: {
    formId: CreateFormIdVariant;
    bookings: CreateCandidate[];
  };
  type: 'CREATE_FORM:SET_BOOKINGS_TO_KEEP_IN_FORM';
}

export const setBookingsToKeepInCreateForm = (
  formId: CreateFormIdVariant,
  bookings: CreateCandidate[],
): SetBookingsToKeepInCreateFormAction => ({
  type: 'CREATE_FORM:SET_BOOKINGS_TO_KEEP_IN_FORM',
  payload: {
    formId,
    bookings,
  },
});

export interface CreateFormRemoveBookingCandidateAction {
  payload: {
    formId: CreateFormIdVariant;
    id: BookingCandidateId;
  };
  type: 'CREATE_FORM:REMOVE_BOOKING_CANDIDATE';
}

export const removeBookingCandidate = (
  formId: CreateFormIdVariant,
  id: BookingCandidateId,
): CreateFormRemoveBookingCandidateAction => ({
  payload: {
    formId,
    id,
  },
  type: 'CREATE_FORM:REMOVE_BOOKING_CANDIDATE',
});

export interface CreateRequestSubmitAction {
  payload: {
    formId: CreateFormIdVariant;
  };
  type: 'CREATE_FORM:REQUEST_SUBMIT';
}

export const requestSubmit = (formId: CreateFormIdVariant): CreateRequestSubmitAction => ({
  payload: {
    formId,
  },
  type: 'CREATE_FORM:REQUEST_SUBMIT',
});

export interface InitializeCreateFormAction {
  type: 'CREATE_FORM:INITIALIZE_CREATE_FORM';
  payload: {
    formId: CreateFormIdVariant;
    formState: CreateFormState;
  };
}

export const initializeCreateForm = (
  formId: CreateFormIdVariant,
  formState: CreateFormState,
): InitializeCreateFormAction => ({
  payload: {
    formId,
    formState,
  },
  type: 'CREATE_FORM:INITIALIZE_CREATE_FORM',
});

export interface RequestCleanupCreateFormAction {
  type: 'CREATE_FORM:REQUEST_CLEANUP_FORM';
  payload: {
    formId: CreateFormIdVariant;
  };
}

export const requestCleanupCreateForm = (
  formId: CreateFormIdVariant,
): RequestCleanupCreateFormAction => ({
  payload: {
    formId,
  },
  type: 'CREATE_FORM:REQUEST_CLEANUP_FORM',
});

export interface SetNumberOfBookingsCreateFormAction {
  type: 'CREATE_FORM:SET_NUMBER_OF_BOOKINGS';
  payload: { formId: CreateFormIdVariant; numberOfBookings: number };
}

export const setNumberOfBookingsCreateForm = (
  formId: CreateFormIdVariant,
  numberOfBookings: number,
): SetNumberOfBookingsCreateFormAction => ({
  payload: { formId, numberOfBookings },
  type: 'CREATE_FORM:SET_NUMBER_OF_BOOKINGS',
});

export interface CreateRegistrationNumberAlertAction {
  payload: {
    formId: CreateFormIdVariant;
    id: BookingCandidateId;
    registrationNumberAlert: Partial<RegistrationNumberAlertFormState>;
  };
  type: 'CREATE_FORM:UPDATE_REGISTRATION_NUMBER_ALERT';
}

export const updateRegistrationNumberAlertCreateForm = (
  formId: CreateFormIdVariant,
  id: BookingCandidateId,
  registrationNumberAlert: Partial<RegistrationNumberAlertFormState>,
): CreateRegistrationNumberAlertAction => ({
  payload: {
    formId,
    id,
    registrationNumberAlert,
  },
  type: 'CREATE_FORM:UPDATE_REGISTRATION_NUMBER_ALERT',
});
