import { Column, Txt } from '@stenajs-webui/core';
import { FC } from 'react';
import { BookingArticle } from '../../../graphql/gql/PaymentViewBooking';
import { formatGQLPrice } from '../../../utils/paymentUtil';

interface Props {
  article: BookingArticle;
}

export const PaymentTableArticleRows: FC<Props> = ({ article }) => (
  <>
    <Column className={'cell-heading'}>
      <Txt variant={'caption'}>{article.articleName}</Txt>
    </Column>
    <Column className={'article cell'}>
      <Txt>{article.articleName}</Txt>
    </Column>
    <Column className={'ex_vat cell'} gap>
      <Txt>{formatGQLPrice(article.grossPrice)}</Txt>
    </Column>
    <Column className={'vat_amount cell'}>
      <Txt>{formatGQLPrice(article.vatAmount)}</Txt>
    </Column>
    <Column className={'vat_rate cell'}>
      <Txt>{article.vatRate + ' %'}</Txt>
    </Column>
    <Column className={'inc_vat cell'}>
      <Txt>{formatGQLPrice(article.netPrice)}</Txt>
    </Column>
  </>
);
