import { Txt } from '@stenajs-webui/core';
import { Label } from '@stenajs-webui/elements';
import { addMonths } from 'date-fns';
import { FC } from 'react';
import { HiddenFieldGate } from '../../../../../common-ui/HiddenFieldGate';
import { getShortArrivalInformation } from '../../../../../common-ui/sailing-info/utils/getExtendedArrivalInformation';
import { useAllRoutesAsOptionsWithFavoriteOptions } from '../../../../../common/graphql/useAllRoutesAsOptionsWithFavoriteOptions';
import { useDepartureTimeOptions } from '../../../../../common/graphql/useDepartureTimeOptions';
import { useLocalDateFormat } from '../../../../../common/hooks/useLocalDateFormat';
import { formatServerDate } from '../../../../../common/utils/dates/dateUtils';
import { ColumnId } from '../../../../../gql/graphql';
import { BookingFormState } from '../../../../../services/update-form/updateFormReducer';
import { DepartureTimeDropdownOption } from '../../../../multiple-create/components/fields/departureTimeDropdownComponents';
import { transformRoute, transformSailing } from '../../../grid/utils/optionTransformers';
import { BookingFields, isHidden } from '../../utils/detailsUtils';
import { DepartureDateInputField } from '../DepartureDateInputField';
import { DetailsFieldCell } from '../DetailsFieldCell';
import { DropdownField } from '../fields/DropdownField';
import { UpdateValidationWrapper } from '../UpdateValidationWrapper';
import { BookingSection } from './BookingSection';

interface RouteDateSailingSectionProps {
  fieldsToShow: BookingFields;
  fieldsToEnable: BookingFields;
  disabled: boolean;
  formState: BookingFormState;
  hasArrived: boolean;
}

export const SailingDetailsSection: FC<RouteDateSailingSectionProps> = ({
  hasArrived,
  disabled,
  fieldsToShow,
  fieldsToEnable,
  formState,
}) => {
  const [allRoutesAsOptionsWithFavoriteOptions] = useAllRoutesAsOptionsWithFavoriteOptions(false);
  const [departureTimeOptions] = useDepartureTimeOptions(
    formState?.departureDate,
    formState?.route?.code,
  );

  const isDisabled = (field: ColumnId) => disabled || isHidden(fieldsToEnable, field);

  const dateFormat = useLocalDateFormat();

  // TODO: remove arrival information from update modal since arrived bookings cannot be edited?
  const arrivalInformation = formState.sailing
    ? getShortArrivalInformation(hasArrived, formState.sailing, dateFormat)
    : undefined;

  return (
    <BookingSection title={'Route'}>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.RouteCode)}>
        <DetailsFieldCell
          label="Route"
          field={
            <DropdownField
              trackerCategory={'Details'}
              name={'route'}
              transformOption={transformRoute}
              disabled={isDisabled(ColumnId.RouteCode)}
              options={allRoutesAsOptionsWithFavoriteOptions}
            />
          }
        />
      </HiddenFieldGate>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.DepartureDate)}>
        <DetailsFieldCell
          label="Date"
          field={
            <UpdateValidationWrapper
              name={'departureDate'}
              renderField={isValid => (
                <DepartureDateInputField
                  name={'departureDate'}
                  fromDate={formatServerDate(new Date())}
                  routeCode={formState.route ? formState.route.code : null}
                  toDate={formatServerDate(addMonths(new Date(), 6))}
                  disabled={isDisabled(ColumnId.DepartureDate)}
                  width={'100%'}
                  trackerCategory={'Details'}
                  variant={!isValid ? 'error' : undefined}
                />
              )}
            />
          }
        />
      </HiddenFieldGate>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.DepartureTime)}>
        <DetailsFieldCell
          label="Departure time"
          field={
            <UpdateValidationWrapper
              name={'sailing'}
              renderField={isValid => (
                <DropdownField
                  trackerCategory={'Details'}
                  name={'sailing'}
                  transformOption={transformSailing}
                  disabled={isDisabled(ColumnId.DepartureTime)}
                  options={departureTimeOptions}
                  components={{
                    Option: DepartureTimeDropdownOption,
                  }}
                  variant={!isValid ? 'error' : undefined}
                />
              )}
            />
          }
        />
        {arrivalInformation && (
          <Label text={'Arrival time'}>
            <Txt>{arrivalInformation}</Txt>
          </Label>
        )}
      </HiddenFieldGate>
    </BookingSection>
  );
};
