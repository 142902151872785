import { useQuery } from '@apollo/client';
import { bookingHistoryQuery } from './bookingHistoryQuery';
import { BookingHistoryQuery_viewer_bookings_byBookingNo_history } from './gql/BookingHistoryQuery';
import { QueryResult } from './types/QueryResult';

export const useBookingHistory = (
  bookingNo: number,
): QueryResult<BookingHistoryQuery_viewer_bookings_byBookingNo_history[] | null> => {
  const { data, loading, error } = useQuery(bookingHistoryQuery, {
    fetchPolicy: 'network-only',
    skip: !bookingNo,
    variables: {
      bookingNo,
    },
  });

  return [data?.viewer?.bookings?.byBookingNo?.history ?? null, loading, error];
};
