import { all, takeEvery } from 'redux-saga/effects';
import { watchCreateResultSaga } from './sagas/clearTempResultSaga';
import { handleRequestSubmit } from './sagas/handleSubmitSagas';
import {
  handleUpdateAllSpecificValues,
  handleUpdateCommonValues,
  handleUpdateSpecificValues,
} from './sagas/updateValuesSagas';
import { watchArrivalNotepad } from './sagas/watchArrivalNotepad';
import { watchTravelledVehicle } from './sagas/watchTravelledVehicle';

export function* watchCreateForm() {
  yield all([
    takeEvery('CREATE_FORM:UPDATE_COMMON_VALUES', handleUpdateCommonValues),
    takeEvery('CREATE_FORM:UPDATE_SPECIFIC_VALUES', handleUpdateSpecificValues),
    takeEvery('CREATE_FORM:UPDATE_ALL_SPECIFIC_VALUES', handleUpdateAllSpecificValues),
    takeEvery('CREATE_FORM:REQUEST_SUBMIT', handleRequestSubmit),
    watchArrivalNotepad(),
    watchCreateResultSaga(),
    watchTravelledVehicle(),
  ]);
}
