import styled from '@emotion/styled';
import { Box } from '@stenajs-webui/core';
import { breakpoints } from '../../../../themes/defaultTheme';
import { blackTableBorder, grayTableBorder } from '../../config/common';

export const BasePaymentGrid = styled(Box)`
  display: grid;

  .cell-heading {
    border-top: ${grayTableBorder};
    grid-column: 1 / -1;

    padding: calc(1.5 * var(--swui-metrics-spacing)) var(--swui-metrics-indent)
      calc(0.5 * var(--swui-metrics-spacing));
  }

  .heading {
    border: 0;
    padding: calc(1.5 * var(--swui-metrics-spacing)) var(--swui-metrics-indent);
  }

  .total {
    border-top: ${blackTableBorder};
    border-bottom: ${blackTableBorder};
    padding: calc(1.5 * var(--swui-metrics-spacing)) var(--swui-metrics-indent);
  }

  .cell {
    padding: calc(1.5 * var(--swui-metrics-spacing)) var(--swui-metrics-indent);
  }

  .cell.no-heading {
    border-top: ${grayTableBorder};
    padding: calc(1.5 * var(--swui-metrics-spacing)) var(--swui-metrics-indent);
  }

  @media (min-width: ${breakpoints.md}) {
    .cell {
      border-top: ${grayTableBorder};
      padding: calc(1.5 * var(--swui-metrics-spacing)) var(--swui-metrics-indent);
    }

    .cell-heading {
      display: none;
    }
  }
`;
