import { Column, Row } from '@stenajs-webui/core';
import { FlatButton, stenaPlusCircle } from '@stenajs-webui/elements';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GoodsTypesQueryRefProps } from '../../../../services/intermodal/hooks/useGoodsTypes';
import { pluginsRange, yesNoOptions } from '../../../manage/details/components/sections/common';
import { transformYesNoOption } from '../../../manage/grid/utils/optionTransformers';
import { transformNoOfPluginsOption } from '../../utils/detailsUtils';
import { CreateIntermodalDropdownField } from '../fields/CreateIntermodalDropdownField';
import { CreateIntermodalTextInputField } from '../fields/CreateIntermodalTextInputField';
import { IntermodalDetailsFieldCell } from '../../common/IntermodalDetailsFieldCell';
import { map } from 'lodash';
import {
  addCreateIntermodalBookingGood,
  clearCreateBookingGoods,
  getCreateIntermodalBookingIsSubmitting,
} from '../../../../services/intermodal/createIntermodalReducer';
import { useCreateIntermodalFieldValue } from '../../../../services/intermodal/hooks/UseCreateIntermodalFieldValue';
import { CreateBookingGoodsItem } from '../CreateBookingGoodsItem';
import { v4 as uuid } from 'uuid';
import { RadioButtonWithLabel } from '@stenajs-webui/forms';

export const CreateIntermodalCargoSection: FC<GoodsTypesQueryRefProps> = ({
  goodsTypesQueryRef,
}) => {
  const bookingGoods = useCreateIntermodalFieldValue('bookingGoods');
  const dispatch = useDispatch();
  const disabled = useSelector(getCreateIntermodalBookingIsSubmitting);
  const loaded = Object.keys(bookingGoods).length > 0;

  const clearGoods = () => dispatch(clearCreateBookingGoods());
  const addGood = () => dispatch(addCreateIntermodalBookingGood({ goodId: uuid() }));

  return (
    <>
      <IntermodalDetailsFieldCell
        label={'Hazardous goods'}
        children={
          <CreateIntermodalDropdownField
            trackerCategory={'IntermodalCreate'}
            transformOption={transformYesNoOption}
            name={'hazardousGoods'}
            options={yesNoOptions}
          />
        }
      />
      <Row gap={2}>
        <IntermodalDetailsFieldCell
          label={'Plugin'}
          children={
            <CreateIntermodalDropdownField
              trackerCategory={'IntermodalCreate'}
              transformOption={transformNoOfPluginsOption}
              options={pluginsRange}
              name={'noOfPlugins'}
            />
          }
          flex={1}
        />
        <IntermodalDetailsFieldCell
          label={'Temp (°C)'}
          children={
            <CreateIntermodalTextInputField
              trackerCategory={'IntermodalCreate'}
              name={'temperature'}
            />
          }
          flex={1}
        />
      </Row>
      <Column gap>
        <RadioButtonWithLabel
          name={'loaded'}
          label={'Empty'}
          disabled={disabled}
          checked={!loaded}
          onClick={loaded ? clearGoods : undefined}
        />
        <RadioButtonWithLabel
          name={'loaded'}
          label={'Loaded'}
          disabled={disabled}
          onClick={!loaded ? addGood : undefined}
          checked={loaded}
        />
      </Column>
      {loaded && (
        <>
          <Column gap={3}>
            {map(bookingGoods, good => (
              <CreateBookingGoodsItem
                key={good.id}
                value={good}
                editable={!disabled}
                goodsTypesQueryRef={goodsTypesQueryRef}
              />
            ))}
          </Column>
          <FlatButton
            leftIcon={stenaPlusCircle}
            disabled={disabled}
            label="Add goods"
            onClick={addGood}
          />
        </>
      )}
    </>
  );
};
