import { FC } from 'react';
import { DebouncedTextInput } from '../../../../../common-ui/form-fields/DebouncedTextInput';
import { useUpdateField } from '../../../../../common/update-information/hooks/useUpdateField';
import { useUpdateRegistrationNumberAlert } from '../../../../../common/registrationNumberAlert/hooks/useUpdateRegistrationNumberAlert';
import { formatRegistrationNumberString } from '../../../../../common/utils/stringUtil';
import { TravelledVehicleType } from '../../../../../services/update-form/registrationNumberAlert';
import {
  transformUpdateFieldToTrackerAction,
  useTrackingTextInput,
} from '../../../../simple-create/hooks/UseTrackingTextInput';
import { DetailsFieldCell } from '../DetailsFieldCell';

interface UpdateVehicleRegNoProps {
  disabled: boolean;
}

export const UpdateVehicleRegNo: FC<UpdateVehicleRegNoProps> = ({ disabled }) => {
  const fieldName = 'vehicleRegNo';
  const { icon } = useUpdateRegistrationNumberAlert(
    'Details',
    'VehicleReg',
    TravelledVehicleType.Vehicle,
  );

  const { editedValue, onChange, initialValue } = useUpdateField<'vehicleRegNo'>('vehicleRegNo');
  const { onBlur, onFocus } = useTrackingTextInput(
    transformUpdateFieldToTrackerAction(fieldName),
    'Details',
  );

  const onInputChange = (value: string) => {
    onChange(value);
  };

  return (
    <DetailsFieldCell
      label="Vehicle registration"
      field={
        <DebouncedTextInput
          className={'t_vehicle_reg_details_edit'}
          name={fieldName}
          maskInput={formatRegistrationNumberString}
          maxLength={17}
          disabled={disabled}
          editedValue={editedValue || ''}
          initialValue={initialValue || ''}
          onChange={onInputChange}
          onBlur={onBlur}
          onFocus={onFocus}
          buttonRight={icon}
        />
      }
    />
  );
};
