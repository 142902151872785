import { Texts } from '../../../../common/texts';
import { MediumIcon, stenaAnimals, stenaHazardous } from '@stenajs-webui/elements';

export interface ResolveMiscInformation {
  icon: MediumIcon;
  text: string;
}

export const resolveMiscInformation = (
  hasLivestock: boolean,
  hasUnsavedHazardousGoods: boolean,
): ResolveMiscInformation | null => {
  if (hasLivestock) {
    return { text: Texts.AnimalsInformation, icon: stenaAnimals };
  } else if (hasUnsavedHazardousGoods) {
    return { text: Texts.HazardousGoodsRequested, icon: stenaHazardous };
  } else return null;
};
