import { Omit, Txt } from '@stenajs-webui/core';
import { Checkbox, CheckboxSize, TextInput, TextInputProps } from '@stenajs-webui/forms';
import { FC, useCallback } from 'react';
import { Colors } from '../../common/colors';
import { stopPropagation } from '../../common/eventHelpers';

export interface TextWithCheckboxValue {
  checkboxValue: boolean;
  textValue: string;
}

export interface TextInputWithCheckboxProps extends Omit<TextInputProps, 'value' | 'onChange'> {
  disabled?: boolean;
  value: TextWithCheckboxValue | undefined;
  onChange: (value: TextWithCheckboxValue) => void;
  unit?: string;
  checkboxSize?: CheckboxSize;
}

export const TextInputWithCheckbox: FC<TextInputWithCheckboxProps> = props => {
  const { disabled = false, value, onChange, onDone, unit, checkboxSize, ...restProps } = props;

  const onInputChange = useCallback(
    (textValue: string) => {
      onChange({ checkboxValue: true, textValue });
    },
    [onChange],
  );

  const onCheckboxChange = useCallback(
    (checkboxValue: boolean) => {
      const textValue = value ? value.textValue : '';
      onChange({ textValue, checkboxValue });
      if (onDone) {
        onDone(textValue);
      }
    },
    [onChange, onDone, value],
  );

  return (
    <TextInput
      {...restProps}
      disabled={disabled}
      value={value ? value.textValue : ''}
      onValueChange={onInputChange}
      width={'100%'}
      contentLeft={
        <div onClick={stopPropagation} onMouseDown={stopPropagation} onMouseUp={stopPropagation}>
          <Checkbox
            size={checkboxSize}
            disabled={disabled}
            value={value ? value.checkboxValue : undefined}
            onValueChange={onCheckboxChange}
          />
        </div>
      }
      contentRight={unit && <Txt color={Colors.UI3}>{unit}</Txt>}
      moveCursorToEndOnMount={true}
    />
  );
};
