import { useLocation, useNavigate } from 'react-router-dom';
import { ModalLocationState } from '../routes/AppRoutes';

export const useRouteModal = () => {
  const navigate = useNavigate();
  const location = useLocation<ModalLocationState | undefined>();

  const onClose = () => {
    if (location.state?.background) {
      navigate(location.state?.background, { replace: true });
    } else {
      navigate(-1);
    }
  };
  return { onClose };
};
