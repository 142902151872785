import { useCallback } from 'react';

export const useMaskInput = (
  onChange: (value: string) => void,
  maskInput?: (value: string) => string,
) => {
  return useCallback(
    (value: string) => {
      if (maskInput) {
        onChange(maskInput(value));
      } else {
        onChange(value);
      }
    },
    [maskInput, onChange],
  );
};
