import { useCallback } from 'react';

export const normalizeNumericString = (
  input: string,
  onlyIntegers: boolean,
): string | undefined => {
  if (input === '') {
    return '';
  }

  if (!/^[\d.,]+$/.exec(input)) {
    return undefined;
  }

  if (!onlyIntegers) {
    return input.replace(/,/g, '.');
  }

  let string = input.replace(/\D/g, '');
  if (string.length > 1 && string.startsWith('0')) {
    string = string.substr(1);
  }

  return string;
};

type Setter = (s: string) => void;
export const useNumericTextInputChange = (setValue: Setter, onlyIntegers = false): Setter =>
  useCallback(
    (input: string) => {
      const newValue = normalizeNumericString(input, onlyIntegers);
      if (newValue !== undefined) {
        setValue(newValue);
      }
    },
    [onlyIntegers, setValue],
  );
