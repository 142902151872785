import { Box, BoxProps, Txt } from '@stenajs-webui/core';
import { parseISO } from 'date-fns';
import { FC } from 'react';
import { InformationMessageInfo } from '../../../../common-ui/information-message/informationMessageUtils';
import { PopoverBody } from '../../../../common-ui/popover/PopoverBody';
import { PopoverTitle } from '../../../../common-ui/popover/PopoverTitle';
import { BasicBooking_importReference_status } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { useLocalDateFormat } from '../../../../common/hooks/useLocalDateFormat';
import { formatDateTime } from '../../../../common/utils/dates/dateUtils';
import { ImportExportRefreshStatusButton } from './ImportExportRefreshStatusButton';

interface ImportExportPopupContentProps extends BoxProps {
  onRequestClose: () => void;
  status: BasicBooking_importReference_status;
  bookingNo: number;
  infoMessageIconProps: InformationMessageInfo;
  showRefreshStatusButton?: boolean;
}

export const ImportExportPopupContent: FC<ImportExportPopupContentProps> = ({
  onRequestClose,
  children,
  status,
  infoMessageIconProps,
  bookingNo,
  showRefreshStatusButton = true,
  ...boxProps
}) => {
  const { statusCode, lastUpdated, title, description } = status;

  const dateFormat = useLocalDateFormat();

  return (
    <Box width={320} {...boxProps} className={'t_import_export_popup'}>
      <PopoverTitle
        text={title || statusCode}
        onClose={onRequestClose}
        closeButtonClassName={'t_import_export_popup_close_button'}
      />
      <PopoverBody gap={2} alignItems={'flex-start'}>
        {lastUpdated ? (
          <Txt size={'smaller'} variant={'overline'} color={'var(--lhds-color-ui-600)'}>
            Last update {formatDateTime(parseISO(lastUpdated), dateFormat)}
          </Txt>
        ) : undefined}
        <Txt className={'t_import_export_popup_text'}>{description}</Txt>
        {showRefreshStatusButton && (
          <ImportExportRefreshStatusButton statusCode={statusCode} bookingNo={bookingNo} />
        )}
        {children}
      </PopoverBody>
    </Box>
  );
};
