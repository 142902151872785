import { BoxProps, Column, Txt } from '@stenajs-webui/core';
import { cssColor } from '@stenajs-webui/theme';
import { FC, ReactNode } from 'react';

export interface ValidationWrapperProps {
  renderField: (isValid: boolean) => ReactNode;
  validationError: string | null | undefined;
  gap?: BoxProps['gap'];
}

export const ValidationWrapper: FC<ValidationWrapperProps> = ({
  renderField,
  validationError,
  gap = 0.5,
}) => (
  <Column gap={gap}>
    {renderField(validationError == null)}
    {validationError && (
      <Txt key={validationError} size={'small'} color={cssColor('--lhds-color-red-500')}>
        {validationError}
      </Txt>
    )}
  </Column>
);
