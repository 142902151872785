export enum FeatureFlag {
  NoShowLateHandling = 'FEATURE_NOSHOW_LATE_HANDLING_ENABLED',
  Statistics = 'FEATURE_STATISTICS_ENABLED',
  Proforma = 'FEATURE_PROFORMA_ENABLED',
  FinancialDocuments = 'FEATURE_FINANCIAL_DOCUMENTS_ENABLED',
  CustomsLink = 'FEATURE_CUSTOMSLINK_ENABLED',
  GA4 = 'FEATURE_GA4_ENABLED',
  UserSubscriptions = 'FEATURE_USER_SUBSCRIPTIONS_ENABLED',
  UserSubscriptionsFreightPortalColumn = 'FEATURE_USER_SUBSCRIPTIONS_FREIGHT_PORTAL_COLUMN_ENABLED',
  IntermodalBook = 'FEATURE_INTERMODAL_BOOK_ENABLED',
}
