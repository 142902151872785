import { FC } from 'react';
import {
  trackEditEvent,
  TrackerAction,
} from '../../use-cases/simple-create/hooks/UseTrackingTextInput';
import { useLocalDateInputState } from '../../common/hooks/useLocalDateInputState';
import { useLocalDateFormat } from '../../common/hooks/useLocalDateFormat';
import {
  DateTextInput,
  DateTextInputCalendarProps,
  DateTextInputProps,
} from '@stenajs-webui/calendar';
import { TrackerCategory } from '../../common/tracking/trackerService';

export interface CommonDateInputFieldProps extends DateTextInputProps<{}> {
  trackerCategory: TrackerCategory;
  editable?: boolean;
  trackerAction: TrackerAction;
  calendarProps?: DateTextInputCalendarProps<any>;
  changed?: boolean;
  isValid?: boolean;
}

export const CommonDateInputField: FC<CommonDateInputFieldProps> = ({
  trackerCategory,
  onValueChange,
  editable,
  disabled,
  value,
  trackerAction,
  changed,
  isValid = true,
  variant,
  ...props
}) => {
  const onInternalOnChange = (date: string) => {
    onValueChange?.(date);

    if (date) {
      trackEditEvent(trackerAction, trackerCategory);
    }
  };

  const [inputValue, setInputValue] = useLocalDateInputState(value ?? '', onInternalOnChange);

  const dateFormat = useLocalDateFormat();

  return (
    <DateTextInput
      {...props}
      onValueChange={setInputValue}
      dateFormat={dateFormat}
      placeholder={dateFormat.toUpperCase()}
      variant={isValid ? variant : 'error'}
      disabled={disabled || !editable}
      value={inputValue}
      style={{
        fontStyle: changed ? 'italic' : 'normal',
        fontWeight: changed ? 'bold' : 'normal',
      }}
    />
  );
};
