import { TextProps, Txt } from '@stenajs-webui/core';
import { escapeRegExp } from 'lodash';
import { FC, Fragment } from 'react';
import { isDefined } from '../../../../common/utils/isDefined';

interface HighlightedTextProps extends TextProps {
  text: string;
  highlight: string;
}

export const HighlightedText: FC<HighlightedTextProps> = ({ text, highlight, ...props }) => {
  if (!highlight.trim()) {
    return <Txt {...props}>{text}</Txt>;
  }

  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
  const parts = text.split(regex);

  return (
    <Txt {...props}>
      {parts.filter(isDefined).map((part, i) => {
        return <Fragment key={i}>{regex.test(part) ? <b>{part}</b> : part}</Fragment>;
      })}
    </Txt>
  );
};
