import { BookingsFilterQueryResult, BookingsPerLoad, CompactMode } from './gridReducer';

export type GridActions =
  | UpdatePaginationAction
  | RequestFetchMoreAction
  | SetBookingQueryResultAction
  | SetIsRefetchingAction
  | SetCompactModeAction
  | SetActiveRowAction
  | ClearActiveRowAction
  | SetBookingsPerLoadAction
  | RequestFetchGridAction;

export interface UpdatePaginationAction {
  payload: {
    first: number;
    offset: number;
  };
  type: 'GRID:UPDATE_PAGINATION';
}

export const updatePagination = (first: number, offset: number): UpdatePaginationAction => ({
  payload: {
    first,
    offset,
  },
  type: 'GRID:UPDATE_PAGINATION',
});

export interface RequestFetchMoreAction {
  payload: {};
  type: 'GRID:REQUEST_FETCH_MORE';
}

export const requestFetchMore = (): RequestFetchMoreAction => ({
  payload: {},
  type: 'GRID:REQUEST_FETCH_MORE',
});

export interface SetBookingQueryResultAction {
  payload: {
    result: BookingsFilterQueryResult;
  };
  type: 'GRID:SET_QUERY_RESULT';
}

export const setBookingQueryResult = (
  result: BookingsFilterQueryResult,
): SetBookingQueryResultAction => ({
  payload: {
    result,
  },
  type: 'GRID:SET_QUERY_RESULT',
});

export interface RequestFetchGridAction {
  payload: {
    first: number;
    offset: number;
    fetchingMore: boolean;
  };
  type: 'GRID:REQUEST_FETCH_GRID';
}

interface RequestFetchGridParams {
  first: number;
  offset: number;
  fetchingMore?: boolean;
}

export const requestFetchGrid = ({
  first,
  offset,
  fetchingMore = false,
}: RequestFetchGridParams): RequestFetchGridAction => ({
  payload: {
    first,
    offset,
    fetchingMore,
  },
  type: 'GRID:REQUEST_FETCH_GRID',
});

export interface RequestUpdateGridWithoutOffsetAction {
  payload: {};
  type: 'GRID:REQUEST_UPDATE_BOOKINGS_IN_GRID';
}

export const requestUpdateBookingsGrid = (): RequestUpdateGridWithoutOffsetAction => ({
  payload: {},
  type: 'GRID:REQUEST_UPDATE_BOOKINGS_IN_GRID',
});

export interface SetIsRefetchingAction {
  payload: {
    refetching: boolean;
  };
  type: 'GRID:SET_REFETCHING';
}

export const setIsRefetching = (refetching: boolean): SetIsRefetchingAction => ({
  payload: {
    refetching,
  },
  type: 'GRID:SET_REFETCHING',
});

export interface SetCompactModeAction {
  payload: {
    compactMode: CompactMode;
  };
  type: 'GRID:SET_COMPACT_MODE';
}

export const setCompactMode = (compactMode: CompactMode): SetCompactModeAction => ({
  payload: {
    compactMode,
  },
  type: 'GRID:SET_COMPACT_MODE',
});

export interface SetBookingsPerLoadAction {
  payload: {
    bookingsPerLoad: BookingsPerLoad;
  };
  type: 'GRID:SET_BOOKINGS_PER_LOAD';
}

export const setBookingsPerLoadAction = (
  bookingsPerLoad: BookingsPerLoad,
): SetBookingsPerLoadAction => ({
  payload: {
    bookingsPerLoad,
  },
  type: 'GRID:SET_BOOKINGS_PER_LOAD',
});

export interface SetActiveRowAction {
  payload: {
    bookingNo: number;
  };
  type: 'GRID:SET_ACTIVE_ROW';
}

export const setActiveRow = (bookingNo: number): SetActiveRowAction => ({
  payload: {
    bookingNo,
  },
  type: 'GRID:SET_ACTIVE_ROW',
});

export interface ClearActiveRowAction {
  type: 'GRID:CLEAR_ACTIVE_ROW';
}

export const clearActiveRow = (): ClearActiveRowAction => ({
  type: 'GRID:CLEAR_ACTIVE_ROW',
});
