import { FocusScope } from '@react-aria/focus';
import { Box, Row, Space, Txt } from '@stenajs-webui/core';
import { cssColor } from '@stenajs-webui/theme';
import { FC, ReactNode } from 'react';
import { RenderFooterProps, RenderStepProps } from './Tour';

interface StepProps extends RenderStepProps {
  renderFooter: (props: RenderFooterProps) => ReactNode;
}

export const Step: FC<StepProps> = ({
  x,
  y,
  position,
  zIndex,
  floating,
  step,
  footerProps,
  renderFooter,
}) => {
  return (
    <FocusScope contain>
      <Box
        ref={floating}
        background={cssColor('--lhds-color-ui-50')}
        position={position}
        top={y}
        left={x}
        zIndex={zIndex}
        indent={2}
        spacing={2}
        width={380}
        minHeight={200}
        maxWidth={'100%'}
        border={`1px solid ${cssColor('--lhds-color-ui-200')}`}
        borderRadius={4}
      >
        <Txt variant={'bold'} id={'t_introduction_tour_title'}>
          {step.title}
        </Txt>
        <Box spacing={2} id={'t_introduction_tour_content'}>
          <Txt>{step.content}</Txt>
        </Box>
        <Space num={3} />
        <Row alignItems={'center'} justifyContent={'space-between'} style={{ marginTop: 'auto' }}>
          {renderFooter(footerProps)}
        </Row>
      </Box>
    </FocusScope>
  );
};
