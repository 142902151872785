import { Banner } from '@stenajs-webui/elements';
import { DetailedBooking_autoCancellation } from '../../../../common/graphql/fragments/gql/DetailedBooking';
import { useLocalDateFormat } from '../../../../common/hooks/useLocalDateFormat';
import { formatDateTime } from '../../../../common/utils/dates/dateUtils';

interface Props {
  info: DetailedBooking_autoCancellation | null;
}

export const AutoCancellationInfoBanner = ({ info }: Props) => {
  const dateFormat = useLocalDateFormat();

  if (!info) {
    return null;
  }

  const formattedDate = formatDateTime(info.departurePortDateTime, dateFormat);

  return (
    <Banner
      variant={'warning'}
      text={`This booking must be completed with a registration number before ${formattedDate} (local time at departure port). Otherwise, it will be cancelled.`}
    />
  );
};
