import { DisplayDensity } from '../../../../gql/graphql';
import { CompactMode } from '../../../grid/gridReducer';

export const transformToDisplayDensity = (compactMode: CompactMode): DisplayDensity => {
  switch (compactMode) {
    case 'spacious':
      return DisplayDensity.Spacious;
    case 'compact':
      return DisplayDensity.Compact;
    case 'normal':
    default:
      return DisplayDensity.Normal;
  }
};

export const transformToCompactMode = (displayDensity: DisplayDensity): CompactMode => {
  switch (displayDensity) {
    case DisplayDensity.Spacious:
      return 'spacious';
    case DisplayDensity.Compact:
      return 'compact';
    case DisplayDensity.Normal:
    default:
      return 'normal';
  }
};
