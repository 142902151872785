/**
 * Finds serialized dates and deserializes back to Date objects
 * @param json
 */
export const jsonParseWithDates: (json: string) => any = json => {
  const regExpDate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
  const regExpDateLong = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  // tslint:disable-next-line
  return JSON.parse(json, (_key: string, value: any) =>
    typeof value === 'string' && (regExpDate.test(value) || regExpDateLong.test(value))
      ? new Date(value)
      : value,
  );
};
export const isJson: (json: string) => boolean = json => {
  try {
    const result = JSON.parse(json);

    if (Object.prototype.toString.call(result).slice(8, -1) !== 'Object') {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};
