import { FocusEventHandler, useRef } from 'react';

interface UseValueHasChangedCallbackProps {
  onBlur: FocusEventHandler<HTMLInputElement>;
  onFocus: FocusEventHandler<HTMLInputElement>;
}

export const useValueHasChangedCallback = (
  onBlurCallback: () => void,
): UseValueHasChangedCallbackProps => {
  const valueRef = useRef<unknown | null>(null);

  const onBlur: FocusEventHandler<HTMLInputElement> = event => {
    if (event.target.value !== valueRef.current) {
      onBlurCallback();
    }
  };

  const onFocus: FocusEventHandler<HTMLInputElement> = event => {
    valueRef.current = event.target.value;
  };

  return { onBlur, onFocus };
};
