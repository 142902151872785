import { CraneableStatus } from '../../gql/graphql';
import { BasicBooking_loadingPreference } from '../graphql/fragments/gql/BasicBooking';
import { GoodsApprovalStatus } from './GoodsApproval';

export const resolveLoadingPreferenceApproved = (
  loadingPreferenceType: BasicBooking_loadingPreference | null,
  loadingPreferenceStatusCode: string,
): GoodsApprovalStatus => {
  if (!loadingPreferenceType) {
    return GoodsApprovalStatus.NotRequested;
  }

  switch (loadingPreferenceStatusCode) {
    case 'C':
      return GoodsApprovalStatus.Approved;
    case 'R':
      return GoodsApprovalStatus.Requested;
    case 'NA':
      return GoodsApprovalStatus.Rejected;
    default:
      return GoodsApprovalStatus.NotRequested;
  }
};

export const resolveLoadingPreferenceText = (
  loadingPreference: BasicBooking_loadingPreference | null,
) => {
  return loadingPreference?.label ?? 'No';
};

export const resolveCraneableStatusText = (craneableStatus: CraneableStatus): string => {
  switch (craneableStatus) {
    case CraneableStatus.Approved:
      return 'No (Approved)';
    case CraneableStatus.NotAvailable:
      return 'No (Not available)';
    case CraneableStatus.Requested:
      return 'No (Requested)';
    case CraneableStatus.NotRequested:
      return 'Yes';
  }
};
