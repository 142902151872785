import { Column, SeparatorLine } from '@stenajs-webui/core';
import { FC, Fragment } from 'react';
import { useLocalDateFormat } from '../../../common/hooks/useLocalDateFormat';
import { getDateDisplayName } from '../../../common/utils/dates/dateUtils';
import {
  IdsRecord,
  UrgentMessageNotification as UrgentMessageNotificationType,
} from '../../../services/notifications/notificationsReducer';
import { NotificationsGroupedByDate } from '../utils/getSortedNotifications';
import { isRead } from '../utils/getUnreadCount';
import { EmptyNotificationsContent, NotificationGroup } from './EmptyNotificationsContent';
import { UrgentMessageNotification } from './UrgentMessageNotification';

export interface UrgentMessageNotificationsProps {
  notificationGroups: NotificationsGroupedByDate<UrgentMessageNotificationType>[];
  readIds: IdsRecord;
}

export const UrgentMessageNotifications: FC<UrgentMessageNotificationsProps> = ({
  notificationGroups,
  readIds,
}) => {
  const dateFormat = useLocalDateFormat();

  return (
    <Column>
      {notificationGroups.map(group => (
        <NotificationGroup key={group.date} heading={getDateDisplayName(group.date, dateFormat)}>
          {group.notifications.map(message => (
            <Fragment key={message.message.id}>
              <UrgentMessageNotification
                messageIsRead={isRead(message, readIds)}
                urgentMessageNotification={message}
              />
              <SeparatorLine />
            </Fragment>
          ))}
        </NotificationGroup>
      ))}
      {notificationGroups.length === 0 && <EmptyNotificationsContent label={'No messages'} />}
    </Column>
  );
};
