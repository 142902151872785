import { useCallback, useEffect } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import { browserHistory } from '../../store/utils/history';
import { useLatest } from './useLatest';

function usePrompt(shouldBlock: boolean) {
  useEffect(() => {
    if (shouldBlock) {
      let unblock = browserHistory.block(tx => {
        let proceed = window.confirm(
          'You have unsaved changes on this page. Do you want to leave this page and discard your changes?',
        );
        if (proceed) {
          unblock();
          setTimeout(tx.retry, 0);
        }
      });

      return unblock;
    }
  }, [shouldBlock]);
}

export function useShowConfirmationWhenLeaving(hasChanges: boolean) {
  const hasChangesRef = useLatest(hasChanges);

  useBeforeUnload(
    useCallback(
      event => {
        if (hasChangesRef.current) {
          event.preventDefault();
          event.returnValue =
            'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?';
        }
      },
      [hasChangesRef],
    ),
  );

  usePrompt(hasChanges);
}
