import { CalculatePriceInput, UpdateBookingInput } from '../../../../gql/graphql';

export const transformFormStateToCalculatePriceInput = (
  formState: UpdateBookingInput,
): CalculatePriceInput => ({
  bookingNo: formState.bookingNo,
  custNo: formState.custNo,
  sailingId: formState.sailingId,
  vehicleTypeCode: formState.vehicleTypeCode,
  length: formState.length,
  width: formState.width,
  cargoWeight: formState.cargoWeight,
  noOfDrivers: formState.noOfDrivers,
  noOfAdults: formState.noOfAdults,
  noOfChildren: formState.noOfChildren,
  noOfInfants: formState.noOfInfants,
  plugIn: formState.noOfPlugins,
  livestock: formState.livestockTypeCode !== '', // TODO, do we need this?
  livestockTypeCode: formState.livestockTypeCode,
  hazardousGoods: formState.hazardousGoods,
  loadingPreferenceCode: formState.loadingPreferenceCode,
  senderCountryCode: formState.senderCountryCode,
  receiverCountryCode: formState.receiverCountryCode,
  tradeWeight: formState.tradeWeight,
});
