import { SettingsFragment } from '../../../common/graphql/fragments/gql/SettingsFragment';
import { SettingsFormState } from '../../../services/settings/settingsReducer';

export const transformToSettingsFormState = (settings: SettingsFragment): SettingsFormState => ({
  dateFormat: settings.dateFormat,
  accountEmail: settings.accountEmail,
  accountFirstName: settings.accountFirstName,
  accountLastName: settings.accountLastName,
  accountLoginEmail: settings.accountLoginEmail,
  accountPhone: settings.accountPhone,
  accountSecondEmail: settings.accountSecondEmail,
  accountSendBookingConfirmationAsEmail: settings.accountSendBookingConfirmationAsEmail,
  bookCustomer: settings.bookCustomer,
  bookDate: settings.bookDate,
  bookDefaultRoute: settings.bookDefaultRoute,
  bookDefaultVehicleType: settings.bookDefaultVehicleType,
  favouriteRoutes: settings.favouriteRoutes,
  favouriteVehicleTypes: settings.favouriteVehicleTypes,
  filterDateInterval: settings.filterDateInterval,
  filterFilterStates: settings.filterFilterStates,
  filterRoutes: settings.filterRoutes,
  dashboardNotCheckedInTime: settings.dashboardNotCheckedInTime,
  dashboardShowNotCheckedIn: settings.dashboardShowNotCheckedIn,
  dashboardShowPendingApproval: settings.dashboardShowPendingApproval,
  dashboardShowDashboardOnScroll: settings.dashboardShowDashboardOnScroll,
  dashboardShowDateOverview: settings.dashboardShowDateOverview,
  dashboardShowUnaccompaniedUnits: settings.dashboardShowUnaccompaniedUnits,
});
