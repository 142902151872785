import { Column, Row, Txt } from '@stenajs-webui/core';
import { FC } from 'react';
import { PaymentViewArticlesResult } from '../../../graphql/gql/PaymentViewBooking';
import { formatGQLPrice } from '../../../utils/paymentUtil';

interface Props {
  articlesResult: PaymentViewArticlesResult;
  warningIds: number[];
}

export const PaymentTableTotalArticleRows: FC<Props> = ({ articlesResult, warningIds }) => (
  <>
    <Column className={'total cell-heading'}>
      <Row gap>
        <Txt variant={'bold'}>Total</Txt>
        {warningIds.length > 0 &&
          warningIds.map(id => (
            <Txt key={id} size={'small'}>
              ({id + 1})
            </Txt>
          ))}
      </Row>
    </Column>
    <Column className={'total article'}>
      <Row gap>
        <Txt variant={'bold'}>Total</Txt>
        {warningIds.length > 0 &&
          warningIds.map(id => (
            <Txt key={id} size={'small'}>
              ({id + 1})
            </Txt>
          ))}
      </Row>
    </Column>
    <Column className={'total ex_vat'}>
      <Txt variant={'bold'}>{formatGQLPrice(articlesResult.totalGrossPrice)}</Txt>
    </Column>
    <Column className={'total vat_amount'}>
      <Txt variant={'bold'}>{formatGQLPrice(articlesResult.totalVatAmount)}</Txt>
    </Column>
    <Column className={'total vat_rate'}></Column>
    <Column className={'total inc_vat t_articles_total_net_price'}>
      <Txt variant={'bold'}>{formatGQLPrice(articlesResult.totalNetPrice)}</Txt>
    </Column>
  </>
);
