import { ButtonSize, FlatButton, stenaCalculate } from '@stenajs-webui/elements';
import { FC } from 'react';
import { CalculatePrice_calculatePrice } from '../../common/pricing/graphql/gql/CalculatePrice';
import { TrackerCategory } from '../../common/tracking/trackerService';
import { Placement } from '../popover/FreightPortalPopover';
import { CalculatePriceErrors } from './CalculatePriceErrors';
import { CalculatePricePopover } from './CalculatePricePopover';

interface CalculatePriceProps {
  errors: string[];
  priceOutdated: boolean;
  result: CalculatePrice_calculatePrice | null;
  loading: boolean;
  onClick: () => void;
  placement?: Placement;
  trackerCategory: TrackerCategory;
  disabled?: boolean;
  buttonSize?: ButtonSize;
}

export const CalculatePrice: FC<CalculatePriceProps> = ({
  loading,
  errors,
  onClick,
  placement,
  priceOutdated,
  result,
  trackerCategory,
  disabled = false,
  buttonSize,
}) => {
  if (!priceOutdated) {
    if (errors.length > 0) {
      return (
        <CalculatePriceErrors
          errors={errors}
          trackerCategory={trackerCategory}
          placement={placement}
          buttonSize={buttonSize}
        />
      );
    }

    if (result?.articlesResults && result.amountInRouteCurrency) {
      return (
        <CalculatePricePopover
          placement={placement}
          buttonSize={buttonSize}
          amountInRouteCurrency={result.amountInRouteCurrency}
          articlesResults={result.articlesResults}
          trackerCategory={trackerCategory}
        />
      );
    }
  }

  return (
    <FlatButton
      size={buttonSize}
      leftIcon={stenaCalculate}
      label={'Calculate price'}
      loadingLabel={'Calculating...'}
      loading={loading}
      onClick={onClick}
      disabled={disabled}
    />
  );
};
