import { TextInput, TextInputProps } from '@stenajs-webui/forms';
import { FC, FocusEventHandler, useEffect, useState } from 'react';
import { useNumericTextInputChange } from '../../common/hooks/useNumericTextInputChange';

export interface NumericTextInputProps extends Omit<TextInputProps, 'onValueChange' | 'value'> {
  value: number | undefined;
  onValueChange: (x: number | undefined) => void;
  onlyIntegers?: boolean;
}

export const NumericTextInput: FC<NumericTextInputProps> = ({
  value: editedValue,
  onValueChange,
  onlyIntegers = false,
  onFocus,
  onBlur,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(
    editedValue !== undefined && editedValue !== null ? String(editedValue) : '',
  );

  useEffect(() => {
    setInputValue(editedValue !== undefined && editedValue !== null ? String(editedValue) : '');
  }, [editedValue]);

  const onTextInputChange = useNumericTextInputChange(setInputValue, onlyIntegers);

  const onInternalBlur: FocusEventHandler<HTMLInputElement> = event => {
    if (inputValue) {
      const value = parseFloat(inputValue);
      onValueChange(value);
      setInputValue(String(value));
    } else {
      onValueChange(undefined);
      setInputValue('');
    }
    onBlur?.(event);
  };

  return (
    <TextInput
      {...props}
      onValueChange={onTextInputChange}
      onBlur={onInternalBlur}
      value={inputValue}
      onFocus={onFocus}
    />
  );
};
