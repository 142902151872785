import { FC, useCallback, useRef } from 'react';
import { StoreState } from '../../../../store/storeState';
import { getFormIsEdited } from '../../../../services/update-form/updateFormReducer';
import { useUpdateSubmit } from '../../../../common/update-information/hooks/useUpdateSubmit';
import { useSelector } from 'react-redux';
import { BookingDetailsHeader } from './BookingDetailsHeader';
import { DetailedBooking } from '../../../../common/graphql/fragments/gql/DetailedBooking';
import { Box, useBoolean } from '@stenajs-webui/core';
import { FlatButton, stenaMobile } from '@stenajs-webui/elements';
import { FreightPortalPopover } from '../../../../common-ui/popover/FreightPortalPopover';
import { ShareBookingPanel } from '../../share/components/ShareBookingPanel';
import { BookingActionMenuButton } from './BookingActionMenuButton';
import { trackEvent } from '../../../../common/tracking/trackerService';
import { shouldShowShareBooking } from '../../share/util/shareUtil';

interface BookingModalTitleEditProps {
  booking: DetailedBooking;
}

export const BookingModalTitleEdit: FC<BookingModalTitleEditProps> = ({ booking }) => {
  const mapState = useCallback(
    (state: StoreState) => {
      return getFormIsEdited(state, {
        bookingNo: booking.bookingNo,
        formId: 'Details',
      });
    },
    [booking.bookingNo],
  );

  const edited = useSelector(mapState);

  const { submitting } = useUpdateSubmit({
    bookingNo: booking.bookingNo,
    formId: 'Details',
  });

  const [isModalOpen, openModal, closeModal] = useBoolean(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const vehicleRegNumber = booking.vehicleRegNo || booking.trailerRegNo;

  return (
    <BookingDetailsHeader
      className={'t_booking_details_edit_header'}
      booking={booking}
      bookingActions={
        <>
          {shouldShowShareBooking(
            vehicleRegNumber,
            booking.bookingStatus.status,
            booking.bookingQuayStatus?.status,
          ) && (
            <>
              <Box display={['none', null, null, null, 'flex']}>
                <FlatButton
                  leftIcon={stenaMobile}
                  label={'Send to Driver app'}
                  onClick={() => {
                    trackEvent({ category: 'Details', action: 'Share booking (button)' });
                    openModal();
                  }}
                  ref={buttonRef}
                />
              </Box>
              <FreightPortalPopover
                reference={buttonRef}
                placement={'bottom-end'}
                visible={isModalOpen}
                onRequestClose={closeModal}
                content={
                  <ShareBookingPanel
                    onClose={closeModal}
                    bookingNo={booking.bookingNo}
                    vehicleRegNo={booking.vehicleRegNo}
                    trackerCategory={'Details'}
                    showCopyBookingLink={true}
                  />
                }
              />
            </>
          )}
          <BookingActionMenuButton
            booking={booking}
            trackerCategory={'Details'}
            disabled={submitting}
            edited={edited}
          />
        </>
      }
    />
  );
};
