import { FC } from 'react';
import { Row } from '@stenajs-webui/core';
import { TimeTag } from '@stenajs-webui/elements';

interface TimeGapProps {
  time: string;
}

export const TimeGap: FC<TimeGapProps> = ({ time }) => {
  return (
    <Row justifyContent={'center'}>
      <TimeTag label={time} />
    </Row>
  );
};
