import { InformationMessageType } from '../../../../common-ui/information-message/informationMessageUtils';
import { Colors } from '../../../../common/colors';
import { Texts } from '../../../../common/texts';
import { exhaustSwitchCaseElseThrow } from '../../../../common/utils/ExhaustSwitchCase';
import {
  GoodsApproval,
  GoodsApprovalStatus,
  GoodsApprovalType,
} from '../../../../common/utils/GoodsApproval';

export interface ApprovalConfig {
  backgroundColor: string | null;
  descriptionText: string;
  headerText: string;
  type: InformationMessageType | null;
}

const getApprovalBackground = (status: GoodsApprovalStatus): string | null => {
  switch (status) {
    case GoodsApprovalStatus.Requested:
      return Colors.Alert4;
    case GoodsApprovalStatus.Approved:
      return Colors.Lush4;
    case GoodsApprovalStatus.Rejected:
      return Colors.Sos4;
    case GoodsApprovalStatus.NotRequested:
      return null;
    default:
      return exhaustSwitchCaseElseThrow(status);
  }
};

const getApprovalAlert = (status: GoodsApprovalStatus): InformationMessageType | null => {
  switch (status) {
    case GoodsApprovalStatus.Requested:
      return InformationMessageType.SoftWarning;
    case GoodsApprovalStatus.Approved:
      return InformationMessageType.Confirmed;
    case GoodsApprovalStatus.Rejected:
      return InformationMessageType.HardWarning;
    case GoodsApprovalStatus.NotRequested:
      return null;
    default:
      return exhaustSwitchCaseElseThrow(status);
  }
};

export const getApprovalHeader = (goodsApproval: GoodsApproval): string => {
  switch (goodsApproval.status) {
    case GoodsApprovalStatus.Requested:
      return 'Request pending';
    case GoodsApprovalStatus.Approved:
      return 'Approved';
    case GoodsApprovalStatus.Rejected:
      return 'Not available';
    case GoodsApprovalStatus.NotRequested:
      return '';
    default:
      return exhaustSwitchCaseElseThrow(goodsApproval.status);
  }
};

const getLoadingPreferenceDescription = (goodsApproval: GoodsApproval): string => {
  switch (goodsApproval.status) {
    case GoodsApprovalStatus.Requested:
      return Texts.LoadingPreferenceRequested;
    case GoodsApprovalStatus.Approved:
      return Texts.LoadingPreferenceApproved;
    case GoodsApprovalStatus.Rejected:
      return Texts.LoadingPreferenceRejected;
    case GoodsApprovalStatus.NotRequested:
      return Texts.LoadingPreferenceNotApplicable;
    default:
      return exhaustSwitchCaseElseThrow(goodsApproval.status);
  }
};

const getCraneableStatusDescription = (goodsApproval: GoodsApproval): string => {
  switch (goodsApproval.status) {
    case GoodsApprovalStatus.Requested:
      return Texts.CraneableRequested;
    case GoodsApprovalStatus.Approved:
      return Texts.CraneableApproved;
    case GoodsApprovalStatus.Rejected:
      return Texts.CraneableRejected;
    case GoodsApprovalStatus.NotRequested:
      return Texts.CraneableNotApplicable;
    default:
      return exhaustSwitchCaseElseThrow(goodsApproval.status);
  }
};

const getHazardousDescription = (goodsApproval: GoodsApproval, extendedTexts: boolean): string => {
  switch (goodsApproval.status) {
    case GoodsApprovalStatus.Requested:
      return extendedTexts ? Texts.HazardousGoodsRequested : Texts.HazardousGoodsRequestedShort;
    case GoodsApprovalStatus.Approved:
      return Texts.HazardousGoodsApproved;
    case GoodsApprovalStatus.Rejected:
    case GoodsApprovalStatus.NotRequested:
      return ''; // Hazardous does not have Rejected nor NotApplicable
    default:
      return exhaustSwitchCaseElseThrow(goodsApproval.status);
  }
};

const getApprovalDescription = (goodsApproval: GoodsApproval, extendedTexts: boolean): string => {
  switch (goodsApproval.type) {
    case GoodsApprovalType.Hazardous:
      return getHazardousDescription(goodsApproval, extendedTexts);
    case GoodsApprovalType.LoadingPreference:
      return getLoadingPreferenceDescription(goodsApproval);
    case GoodsApprovalType.CraneableStatus:
      return getCraneableStatusDescription(goodsApproval);
  }
};

export const getApprovalInfo = (
  goodsApproval: GoodsApproval,
  extendedTexts: boolean = false,
): ApprovalConfig | null => {
  if (goodsApproval.status === GoodsApprovalStatus.NotRequested) {
    return null;
  }
  return {
    backgroundColor: getApprovalBackground(goodsApproval.status),
    descriptionText: getApprovalDescription(goodsApproval, extendedTexts),
    headerText: getApprovalHeader(goodsApproval),
    type: getApprovalAlert(goodsApproval.status),
  };
};
