import { PrimaryButton, SecondaryButton, TextInputButtonSize } from '@stenajs-webui/elements';
import { InformationMessageType } from './informationMessageUtils';
import { RegistrationNumberAlertIcon } from './RegistrationNumberAlertIcon';
import { Column, Txt } from '@stenajs-webui/core';
import { RegistrationNumberAlertFooter } from './RegistrationNumberAlertFooter';

export interface RenderRegistrationNumberAlertAppliedIconProps {
  fieldName: string;
  buttonSize?: TextInputButtonSize;
  onIconClick: (type: 'ArrivalNotepad' | 'TravelledVehicle') => void;
  onHideMessage: () => void;
  onRestore: () => void;
  showTravelledVehicle: boolean;
  showArrivalNotepad: boolean;
}

export const renderRegistrationNumberAlertAppliedIcon = ({
  fieldName,
  buttonSize,
  onIconClick,
  onHideMessage,
  onRestore,
  showTravelledVehicle,
  showArrivalNotepad,
}: RenderRegistrationNumberAlertAppliedIconProps) => {
  if (!showArrivalNotepad && !showTravelledVehicle) {
    return null;
  }

  if (showArrivalNotepad) {
    return (
      <RegistrationNumberAlertIcon
        onClick={() => onIconClick('ArrivalNotepad')}
        title={`Updated ${fieldName}`}
        buttonSize={buttonSize}
        iconType={InformationMessageType.RegularInfo}
        renderPopupContent={requestClose => (
          <Column gap={2}>
            <Txt>
              {`The ${fieldName} has been automatically updated with data from the quay unit with matching registration number. It is no longer possible to change the ${fieldName} of the unit.`}
            </Txt>
            <RegistrationNumberAlertFooter>
              <PrimaryButton
                onClick={() => {
                  onHideMessage();
                  requestClose();
                }}
                label={'Ok, got it'}
              />
            </RegistrationNumberAlertFooter>
          </Column>
        )}
      />
    );
  }

  if (showTravelledVehicle) {
    return (
      <RegistrationNumberAlertIcon
        onClick={() => onIconClick('TravelledVehicle')}
        title={`Updated ${fieldName}`}
        buttonSize={buttonSize}
        iconType={InformationMessageType.RegularInfo}
        renderPopupContent={requestClose => (
          <Column gap={2}>
            <Txt>
              {`The ${fieldName} has been automatically updated based on data from the previous booking with the same registration number.`}
            </Txt>
            <RegistrationNumberAlertFooter>
              <SecondaryButton
                onClick={() => {
                  onRestore();
                  requestClose();
                }}
                label={`Restore default ${fieldName}`}
              />
              <PrimaryButton
                onClick={() => {
                  onHideMessage();
                  requestClose();
                }}
                label={'Ok, got it'}
              />
            </RegistrationNumberAlertFooter>
          </Column>
        )}
      />
    );
  }

  return null;
};
