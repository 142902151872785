export interface Country {
  countryCode: string;
  locale: string;
  label: string;
  code: string;
}

export const COUNTRY_UNITED_KINGDOM: Country = {
  code: '+44',
  countryCode: 'gb',
  locale: 'en-gb',
  label: 'United Kingdom',
};

export const COUNTRY_LIST: Country[] = [
  {
    code: '+355',
    countryCode: 'al',
    locale: 'sq-al',
    label: 'Albania',
  },
  {
    code: '+376',
    countryCode: 'ad',
    locale: 'es-ad',
    label: 'Andorra',
  },
  {
    code: '+374',
    countryCode: 'am',
    locale: 'hy-am',
    label: 'Armenia',
  },
  {
    code: '+43',
    countryCode: 'at',
    locale: 'de-at',
    label: 'Austria',
  },
  {
    code: '+994',
    countryCode: 'az',
    locale: 'az-az',
    label: 'Azerbaijan',
  },
  {
    code: '+375',
    countryCode: 'by',
    locale: 'be-by',
    label: 'Belarus',
  },
  {
    code: '+32',
    countryCode: 'be',
    locale: 'nl-be',
    label: 'Belgium',
  },
  {
    code: '+387',
    countryCode: 'ba',
    locale: 'bs-ba',
    label: 'Bosnia Herzegovina',
  },
  {
    code: '+359',
    countryCode: 'bg',
    locale: 'bg-bg',
    label: 'Bulgaria',
  },
  {
    code: '+385',
    countryCode: 'hr',
    locale: 'hr-hr',
    label: 'Croatia',
  },
  {
    code: '+357',
    countryCode: 'cy',
    locale: 'el-cy',
    label: 'Cyprus',
  },
  {
    code: '+420',
    countryCode: 'cz',
    locale: 'cs-cz',
    label: 'Czechia',
  },
  {
    code: '+45',
    countryCode: 'dk',
    locale: 'da-dk',
    label: 'Denmark',
  },
  {
    code: '+372',
    countryCode: 'ee',
    locale: 'et-ee',
    label: 'Estonia',
  },
  {
    code: '+358',
    countryCode: 'fi',
    locale: 'fi-fi',
    label: 'Finland',
  },
  {
    code: '+33',
    countryCode: 'fr',
    locale: 'fr-fr',
    label: 'France',
  },
  {
    code: '+995',
    countryCode: 'ge',
    locale: 'ka-ge',
    label: 'Georgia',
  },
  {
    code: '+49',
    countryCode: 'de',
    locale: 'de-de',
    label: 'Germany',
  },
  {
    code: '+30',
    countryCode: 'gr',
    locale: 'el-gr',
    label: 'Greece',
  },
  {
    code: '+36',
    countryCode: 'hu',
    locale: 'hu-hu',
    label: 'Hungary',
  },
  {
    code: '+354',
    countryCode: 'is',
    locale: 'is-is',
    label: 'Iceland',
  },
  {
    code: '+353',
    countryCode: 'ie',
    locale: 'en-ie',
    label: 'Ireland',
  },
  {
    code: '+39',
    countryCode: 'it',
    locale: 'it-it',
    label: 'Italy',
  },
  {
    code: '+371',
    countryCode: 'lv',
    locale: 'lv-lv',
    label: 'Latvia',
  },
  {
    code: '+423',
    countryCode: 'li',
    locale: 'de-li',
    label: 'Liechtenstein',
  },
  {
    code: '+370',
    countryCode: 'lt',
    locale: 'lt-lt',
    label: 'Lithuania',
  },
  {
    code: '+352',
    countryCode: 'lu',
    locale: 'fr-lu',
    label: 'Luxembourg',
  },
  {
    code: '+389',
    countryCode: 'mk',
    locale: 'mk-mk',
    label: 'Macedonia',
  },
  {
    code: '+356',
    countryCode: 'mt',
    locale: 'ml-mt',
    label: 'Malta',
  },
  {
    code: '+373',
    countryCode: 'md',
    locale: 'ro-md',
    label: 'Moldova',
  },
  {
    code: '+377',
    countryCode: 'mc',
    locale: 'fr-mc',
    label: 'Monaco',
  },
  {
    code: '+382',
    countryCode: 'me',
    locale: 'sr-me',
    label: 'Montenegro',
  },
  {
    code: '+31',
    countryCode: 'nl',
    locale: 'nl-nl',
    label: 'Netherlands',
  },
  {
    code: '+47',
    countryCode: 'no',
    locale: 'no-no',
    label: 'Norway',
  },
  {
    code: '+48',
    countryCode: 'pl',
    locale: 'pl-pl',
    label: 'Poland',
  },
  {
    code: '+351',
    countryCode: 'pt',
    locale: 'pt-pt',
    label: 'Portugal',
  },
  {
    code: '+40',
    countryCode: 'ro',
    locale: 'ro-ro',
    label: 'Romania',
  },
  {
    code: '+7',
    countryCode: 'ru',
    locale: 'ru-ru',
    label: 'Russia',
  },
  {
    code: '+378',
    countryCode: 'sm',
    locale: 'it-sm',
    label: 'San Marino',
  },
  {
    code: '+381',
    countryCode: 'rs',
    locale: 'sr-rs',
    label: 'Serbia',
  },
  {
    code: '+421',
    countryCode: 'sk',
    locale: 'sk-sk',
    label: 'Slovakia',
  },
  {
    code: '+386',
    countryCode: 'si',
    locale: 'sl-si',
    label: 'Slovenia',
  },
  {
    code: '+34',
    countryCode: 'es',
    locale: 'es-es',
    label: 'Spain',
  },
  {
    code: '+46',
    countryCode: 'se',
    locale: 'sv-se',
    label: 'Sweden',
  },
  {
    code: '+41',
    countryCode: 'ch',
    locale: 'de-ch',
    label: 'Switzerland',
  },
  {
    code: '+90',
    countryCode: 'tr',
    locale: 'tr-tr',
    label: 'Turkey',
  },
  {
    code: '+380',
    countryCode: 'ua',
    locale: 'uk-ua',
    label: 'Ukraine',
  },
  COUNTRY_UNITED_KINGDOM,
  {
    code: '+1',
    countryCode: 'us',
    locale: 'en-us',
    label: 'United States',
  },
];

export const formatFullCountryString = ({ label, code }: Country): string => `${label} (${code})`;

export const formatAbbreviatedCountryString = ({ countryCode, code }: Country): string =>
  `${countryCode.toUpperCase()} (${code})`;
