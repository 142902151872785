import { Action } from 'redux';
import { delay, put, race, select, take, takeEvery } from 'redux-saga/effects';
import {
  addToast,
  PauseToastRemovalAction,
  removeToast,
  RemoveToastAction,
  RequestShowToastAction,
  ResumeToastRemovalAction,
} from './notificationsActions';
import {
  getIsUrgentMessageRead,
  getToastById,
  getUrgentMessageNotificationById,
} from './notificationsReducer';
import { getIsAnyTourRunningOrModalShowing } from '../tour/tourReducer';

const actionIsRemoveToast = (action: Action): action is RemoveToastAction =>
  action.type === 'NOTIFICATIONS:REMOVE_TOAST';

const actionIsPauseToastRemoval = (action: Action): action is PauseToastRemovalAction =>
  action.type === 'NOTIFICATIONS:PAUSE_TOAST_REMOVAL';

const actionIsResumeToastRemoval = (action: Action): action is ResumeToastRemovalAction =>
  action.type === 'NOTIFICATIONS:RESUME_TOAST_REMOVAL';

export function* requestShowToastSaga({ payload }: RequestShowToastAction) {
  const toastWithSameId: ReturnType<typeof getToastById> = yield select(
    getToastById,
    payload.toastId,
  );

  const notificationWithSameId: ReturnType<typeof getUrgentMessageNotificationById> = yield select(
    getUrgentMessageNotificationById,
    payload.toastId,
  );

  const notificationIsRead: ReturnType<typeof getIsUrgentMessageRead> = yield select(
    getIsUrgentMessageRead,
    payload.toastId,
  );

  if (toastWithSameId || notificationIsRead || notificationWithSameId) {
    return;
  }

  yield put(addToast(payload.toastId, payload.toastArgs));

  const { cancelledByUser } = yield race({
    timeout: waitForRemovalSaga(payload),
    cancelledByUser: take(
      (action: Action) => actionIsRemoveToast(action) && action.payload.id === payload.toastId,
    ),
  });

  if (!cancelledByUser) {
    yield put(removeToast(payload.toastId));
  }
}

export function* watchRequestShowToast() {
  yield takeEvery('NOTIFICATIONS:REQUEST_SHOW_TOAST', requestShowToastSaga);
}

export const takePauseToastRemoval = (id: string) => (action: Action) =>
  actionIsPauseToastRemoval(action) && action.payload.id === id;

export const takeResumeToastRemoval = (id: string) => (action: Action) =>
  actionIsResumeToastRemoval(action) && action.payload.id === id;

export function* waitForRemovalSaga(payload: RequestShowToastAction['payload']) {
  let duration = payload.durationMS;

  while (true) {
    const { timeout, paused } = yield race({
      timeout: delay(duration),
      paused: take(takePauseToastRemoval(payload.toastId)),
    });

    const isAnyTourRunningOrModalShowing: ReturnType<typeof getIsAnyTourRunningOrModalShowing> =
      yield select(getIsAnyTourRunningOrModalShowing);

    if (paused) {
      yield take(takeResumeToastRemoval(payload.toastId));
      duration = payload.durationMS / 2;
    }

    if (timeout && !isAnyTourRunningOrModalShowing) {
      break;
    }
  }
}
