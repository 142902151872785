import { QueryReference, useBackgroundQuery, useReadQuery } from '@apollo/client';
import { useMemo } from 'react';
import { AllIntermodalVehicleTypesQueryQuery } from '../../../gql/graphql';
import { getIntermodalVehicleTypeOptions } from '../../../use-cases/intermodal/utils/detailsUtils';
import { allIntermodalVehicleTypesQuery } from '../graphql/allIntermodalVehicleTypesQuery';

export interface AllIntermodalVehicleTypesQueryRefProps {
  vehicleTypesQueryRef: QueryReference<AllIntermodalVehicleTypesQueryQuery>;
}

export const useIntermodalVehicleTypesBackgroundQuery =
  (): QueryReference<AllIntermodalVehicleTypesQueryQuery> => {
    const [queryRef] = useBackgroundQuery(allIntermodalVehicleTypesQuery, {
      fetchPolicy: 'cache-first',
    });

    return queryRef;
  };

export const useAllIntermodalVehicleTypesAsOptions = (
  queryRef: QueryReference<AllIntermodalVehicleTypesQueryQuery>,
) => {
  const { data, error } = useReadQuery(queryRef);

  return useMemo(() => {
    return [
      getIntermodalVehicleTypeOptions(data?.viewer?.intermodalVehicleType.all.vehicleTypes ?? []),
      error,
    ] as const;
  }, [data, error]);
};
