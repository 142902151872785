import { ApolloProvider } from '@apollo/client';
import { Global, ThemeContext } from '@emotion/react';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { ErrorPageBoundary } from './common/error-handling/ErrorPageBoundary';
import { App, AppLoading } from './common/home/components/App';
import { ServerDownBoundary } from './common/home/components/ServerDownBoundary';
import { initAppInsight } from './common/tracking/appInsight';
import { enablePrintScrollOffsetFix } from './enablePrintScrollOffsetFix';
import { globalStyles } from './globalStyles';
import { initializeAnalyticsAfterAuth } from './InitializeAnalyticsAfterAuth';
import { initializePlannerInstallPromptAndroidAfterFirstReactRender } from './initializePlannerInstallPromptAndroid';
import { client, setErrorCallback } from './services/apollo/apollo';
import { commonBeforeUnload } from './services/common/commonActions';
import { persistor, store } from './store/configureStore';
import { browserHistory } from './store/utils/history';
import { defaultTheme } from './themes/defaultTheme';
import { AuthProvider } from './use-cases/auth/AuthContext';
import { getAuth, handleAuthOnLoad } from './use-cases/auth/getAuthParams';

// import React from 'react';
// import whyDidYouRender from '@welldone-software/why-did-you-render';
// if (import.meta.env.DEV) {
//   whyDidYouRender(React, { include: [/^Lightbox$/] });
// }

initAppInsight();
enablePrintScrollOffsetFix();

window.addEventListener('beforeunload', () => {
  store.dispatch(commonBeforeUnload());
});

Modal.setAppElement('#root');

setErrorCallback(store.dispatch);

const auth = getAuth(store, persistor);

handleAuthOnLoad(auth).then(initializeAnalyticsAfterAuth).catch(console.error);

createRoot(document.getElementById('root')!).render(
  <>
    <Global styles={globalStyles} />
    <Provider store={store} noopCheck={'always'} stabilityCheck={'always'}>
      <ApolloProvider client={client}>
        <HistoryRouter history={browserHistory as any}>
          <ThemeContext.Provider value={defaultTheme}>
            <ServerDownBoundary>
              <ErrorPageBoundary>
                <AuthProvider value={auth}>
                  <Suspense fallback={<AppLoading />}>
                    <App />
                  </Suspense>
                </AuthProvider>
              </ErrorPageBoundary>
            </ServerDownBoundary>
          </ThemeContext.Provider>
        </HistoryRouter>
      </ApolloProvider>
    </Provider>
  </>,
);

initializePlannerInstallPromptAndroidAfterFirstReactRender();
