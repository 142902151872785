import { Txt } from '@stenajs-webui/core';
import { FC } from 'react';
import { HiddenFieldGate } from '../../../../../common-ui/HiddenFieldGate';
import { BasicBooking } from '../../../../../common/graphql/fragments/gql/BasicBooking';
import { useAnimalTypesOptions } from '../../../../../common/graphql/useAnimalTypesOptions';
import { useSenderReceiverCountryOptions } from '../../../../../common/graphql/useSenderReceiverCountryOptions';
import { includeYesOption } from '../../../../../common/utils/animalsUtils';
import { GoodsApproval, GoodsApprovalType } from '../../../../../common/utils/GoodsApproval';
import { resolveHazardousGoodsApproved } from '../../../../../common/utils/hazardousGoodsUtil';
import { ColumnId } from '../../../../../gql/graphql';
import {
  transformAnimalOption,
  transformSenderReceiverCountryOption,
  transformYesNoOption,
} from '../../../grid/utils/optionTransformers';
import { BookingFields, isHidden } from '../../utils/detailsUtils';
import { FieldGrid } from '../BookingDetailsEdit';
import { DetailsFieldCell } from '../DetailsFieldCell';
import { DropdownField } from '../fields/DropdownField';
import { NumericField } from '../fields/NumericField';
import { TextInputWithCheckboxField } from '../fields/TextInputWithCheckboxField';
import { BookingSection } from './BookingSection';
import { yesNoOptions } from './common';
import { useUpdateRegistrationNumberAlertApplied } from '../../../../../common/registrationNumberAlert/hooks/useUpdateRegistrationNumberAlertApplied';

interface CargoDeclarationSectionProps {
  fieldsToShow: BookingFields;
  fieldsToEnable: BookingFields;
  disabled: boolean;
  booking: BasicBooking;
  showCargoSection: boolean;
}

export const CargoDetailsSection: FC<CargoDeclarationSectionProps> = ({
  booking,
  fieldsToShow,
  fieldsToEnable,
  disabled,
  showCargoSection,
}) => {
  const hazardousGoodsApproval: GoodsApproval = {
    status: resolveHazardousGoodsApproved(
      false,
      booking.hazardousGoods,
      booking.hazardousGoodsApproved,
    ),
    type: GoodsApprovalType.Hazardous,
  };

  const [animalTypesOptions] = useAnimalTypesOptions(
    true,
    booking ? includeYesOption(booking.livestockType, booking.livestock) : false,
  );

  const isDisabled = (field: ColumnId) => disabled || isHidden(fieldsToEnable, field);

  const [countryOptions] = useSenderReceiverCountryOptions();

  const { icon, arrivalNotepadApplied } = useUpdateRegistrationNumberAlertApplied(
    'Details',
    'cargoWeight',
    undefined,
  );

  if (showCargoSection) {
    return (
      <>
        <BookingSection title={'Cargo'}>
          <Txt>There are no cargo options available.</Txt>
        </BookingSection>
      </>
    );
  }

  return (
    <BookingSection title={'Cargo'}>
      <FieldGrid>
        <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.CargoWeight)}>
          <DetailsFieldCell
            label="Weight (kg)"
            field={
              <NumericField
                trackerCategory={'Details'}
                name={'cargoWeight'}
                disabled={arrivalNotepadApplied || isDisabled(ColumnId.CargoWeight)}
                maxLength={5}
                onlyIntegers
                buttonRight={icon}
              />
            }
          />
        </HiddenFieldGate>
        <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.Temperature)}>
          <DetailsFieldCell
            label="Temp. onboard (°C)"
            field={
              <TextInputWithCheckboxField
                checkBoxName={'noOfPlugins'}
                name={'pluginTemperature'}
                disabled={isDisabled(ColumnId.Temperature)}
              />
            }
          />
        </HiddenFieldGate>
      </FieldGrid>
      <FieldGrid>
        <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.HazardousGoods)}>
          <DetailsFieldCell
            label="Hazardous goods"
            field={
              <DropdownField
                trackerCategory={'Details'}
                name={'hazardousGoods'}
                disabled={arrivalNotepadApplied || isDisabled(ColumnId.HazardousGoods)}
                transformOption={transformYesNoOption}
                options={yesNoOptions}
              />
            }
            approved={hazardousGoodsApproval}
          />
        </HiddenFieldGate>
        <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.LivestockTypeCode)}>
          <DetailsFieldCell
            label="Animals"
            field={
              <DropdownField
                trackerCategory={'Details'}
                name={'livestockType'}
                transformOption={transformAnimalOption}
                disabled={isDisabled(ColumnId.LivestockTypeCode)}
                options={animalTypesOptions}
              />
            }
          />
        </HiddenFieldGate>
      </FieldGrid>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.SenderCountryCode)}>
        <DetailsFieldCell
          label="Sender country"
          field={
            <DropdownField
              trackerCategory={'Details'}
              name={'senderCountry'}
              transformOption={transformSenderReceiverCountryOption}
              disabled={isDisabled(ColumnId.SenderCountryCode)}
              options={countryOptions}
            />
          }
        />
      </HiddenFieldGate>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.ReceiverCountryCode)}>
        <DetailsFieldCell
          label="Receiver country"
          field={
            <DropdownField
              trackerCategory={'Details'}
              name={'receiverCountry'}
              transformOption={transformSenderReceiverCountryOption}
              disabled={isDisabled(ColumnId.ReceiverCountryCode)}
              options={countryOptions}
            />
          }
        />
      </HiddenFieldGate>
    </BookingSection>
  );
};
