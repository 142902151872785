import { uniqueId } from 'lodash';
import { BasicBooking } from '../../common/graphql/fragments/gql/BasicBooking';
import { Sailing } from '../../common/graphql/fragments/gql/Sailing';
import { UrgentMessage } from '../../common/graphql/fragments/gql/UrgentMessage';
import { CreatedAt } from './notificationsReducer';
import { createCreatedAt } from './notificationsUtils';
import { DetailedIntermodalBooking } from '../../common/graphql/fragments/gql/IntermodalBooking';

export const generateToastId = () => uniqueId('toast_');
export const generateBookingResultId = () => Date.now();

export type NotificationsActions =
  | AddToastAction
  | RemoveToastAction
  | AddBookingResultNotificationAction
  | RemoveBookingResultNotificationAction
  | AddUrgentMessageNotificationAction
  | MarkUrgentMessageAsReadAction
  | PauseToastRemovalAction
  | ResumeToastRemovalAction
  | MarkUrgentMessageAsUnreadAction;

export type BaseToastArgs = {
  type: string;
};

export type ToastArgs = UrgentMessageArgs | BookingResultArgs;

export interface UrgentMessageArgs extends BaseToastArgs {
  type: 'urgentMessage';
  urgentMessage: UrgentMessage;
}

export type BookingType = 'booking' | 'intermodalBooking';

export type BookingResult =
  | {
      type: 'cancel-success';
      bookingType: BookingType;
      bookingNo: number;
      messages: string[];
      warnings: string[];
    }
  | {
      type: 'cancel-fail';
      bookingType: BookingType;
      bookingNo: number;
      errors: string[];
    }
  | {
      type: 'waitlist';
      bookings: BasicBooking[];
      sailing: Sailing | null;
      messages: string[];
      warnings: string[];
    }
  | {
      type: 'switch-success';
      sailing: Sailing;
      confirmedBookingNo: number;
      waitlistedBookingNo: number;
      messages: string[];
      warnings: string[];
    }
  | {
      type: 'switch-fail';
      confirmedBookingNo: number;
      waitlistedBookingNo: number;
      errors: string[];
    }
  | {
      type: 'create-fail';
      count: number;
      sailing: Sailing | null;
      errors: string[];
      warnings: string[];
    }
  | {
      type: 'confirmed';
      bookings: BasicBooking[];
      sailing: Sailing | null;
      messages: string[];
      warnings: string[];
    }
  | {
      type: 'intermodal-created';
      booking: DetailedIntermodalBooking;
      messages: string[];
      warnings: string[];
    };

export interface BookingResultArgs extends BaseToastArgs {
  type: 'bookingResult';
  result: BookingResult;
}

export interface RequestShowToastAction {
  payload: {
    toastId: string;
    durationMS: number;
    toastArgs: ToastArgs;
  };
  type: 'NOTIFICATIONS:REQUEST_SHOW_TOAST';
}

export const requestShowUrgentMessageToast = (
  urgentMessage: UrgentMessage,
): RequestShowToastAction => ({
  payload: {
    durationMS: 15000,
    toastArgs: {
      type: 'urgentMessage',
      urgentMessage,
    },
    toastId: urgentMessage.id,
  },
  type: 'NOTIFICATIONS:REQUEST_SHOW_TOAST',
});

export const requestShowBookingResultToast = (
  result: BookingResult,
  toastId: string = generateToastId(),
): RequestShowToastAction => ({
  payload: {
    durationMS: 10000,
    toastArgs: {
      type: 'bookingResult',
      result,
    },
    toastId,
  },
  type: 'NOTIFICATIONS:REQUEST_SHOW_TOAST',
});

export interface AddToastAction {
  payload: {
    id: string;
    args: ToastArgs;
  };
  type: 'NOTIFICATIONS:ADD_TOAST';
}

export const addToast = (id: string, args: ToastArgs): AddToastAction => ({
  payload: {
    id,
    args,
  },
  type: 'NOTIFICATIONS:ADD_TOAST',
});

export interface RemoveToastAction {
  payload: {
    id: string;
  };
  type: 'NOTIFICATIONS:REMOVE_TOAST';
}

export const removeToast = (id: string): RemoveToastAction => ({
  payload: {
    id,
  },
  type: 'NOTIFICATIONS:REMOVE_TOAST',
});

export interface PauseToastRemovalAction {
  payload: {
    id: string;
  };
  type: 'NOTIFICATIONS:PAUSE_TOAST_REMOVAL';
}

export const pauseToastRemoval = (id: string): PauseToastRemovalAction => ({
  payload: {
    id,
  },
  type: 'NOTIFICATIONS:PAUSE_TOAST_REMOVAL',
});

export interface ResumeToastRemovalAction {
  payload: {
    id: string;
  };
  type: 'NOTIFICATIONS:RESUME_TOAST_REMOVAL';
}

export const resumeToastRemoval = (id: string): ResumeToastRemovalAction => ({
  payload: {
    id,
  },
  type: 'NOTIFICATIONS:RESUME_TOAST_REMOVAL',
});

export interface AddBookingResultNotificationAction {
  payload: {
    createdAt: CreatedAt;
    bookingResult: BookingResult;
    id: number;
  };
  type: 'NOTIFICATION:ADD_BOOKING_RESULT_NOTIFICATION';
}

export const addBookingResultNotification = (
  bookingResult: BookingResult,
  createdAt: CreatedAt = createCreatedAt(),
  id: number = generateBookingResultId(),
): AddBookingResultNotificationAction => ({
  payload: {
    createdAt,
    bookingResult,
    id,
  },
  type: 'NOTIFICATION:ADD_BOOKING_RESULT_NOTIFICATION',
});

export interface RemoveBookingResultNotificationAction {
  payload: {
    id: number;
  };
  type: 'NOTIFICATION:REMOVE_BOOKING_RESULT_NOTIFICATION';
}

export const removeBookingResultNotification = (
  id: number,
): RemoveBookingResultNotificationAction => ({
  payload: {
    id,
  },
  type: 'NOTIFICATION:REMOVE_BOOKING_RESULT_NOTIFICATION',
});

export interface AddUrgentMessageNotificationAction {
  payload: {
    createdAt: CreatedAt;
    message: UrgentMessage;
  };
  type: 'NOTIFICATION:ADD_URGENT_MESSAGE_NOTIFICATION';
}

export const addUrgentMessageNotification = (
  message: UrgentMessage,
  createdAt: CreatedAt,
): AddUrgentMessageNotificationAction => ({
  payload: {
    createdAt,
    message,
  },
  type: 'NOTIFICATION:ADD_URGENT_MESSAGE_NOTIFICATION',
});

export interface MarkUrgentMessageAsUnreadAction {
  payload: {
    id: string;
  };
  type: 'NOTIFICATIONS:MARK_URGENT_MESSAGE_AS_UNREAD';
}

export const markUrgentMessageAsUnread = (id: string): MarkUrgentMessageAsUnreadAction => ({
  payload: {
    id,
  },
  type: 'NOTIFICATIONS:MARK_URGENT_MESSAGE_AS_UNREAD',
});

export interface MarkUrgentMessageAsReadAction {
  payload: {
    id: string;
  };
  type: 'NOTIFICATIONS:MARK_URGENT_MESSAGE_AS_READ';
}

export const markUrgentMessageAsRead = (id: string): MarkUrgentMessageAsReadAction => ({
  payload: {
    id,
  },
  type: 'NOTIFICATIONS:MARK_URGENT_MESSAGE_AS_READ',
});
