import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PickByValue } from '@stenajs-webui/core';
import { BookingFilterState, DateInterval, DateSetting } from '../../gql/graphql';
import { StoreState } from '../../store/storeState';
import {
  AccountQuery_viewer_account_byEmail,
  AccountQuery_viewer_account_byEmail_accountSettings_bookCustomer,
  AccountQuery_viewer_account_byEmail_accountSettings_bookDefaultRoute,
  AccountQuery_viewer_account_byEmail_accountSettings_bookDefaultVehicleType,
  AccountQuery_viewer_account_byEmail_accountSettings_favouriteRoutes,
  AccountQuery_viewer_account_byEmail_accountSettings_favouriteVehicleTypes,
  AccountQuery_viewer_account_byEmail_accountSettings_filterRoutes,
} from '../../use-cases/settings/graphql/gql/AccountQuery';
import { UpdateSettings_updateSettings } from '../../use-cases/settings/graphql/gql/UpdateSettings';
import { ValidationFields } from '../../use-cases/settings/graphql/gql/ValidationFields';
import {
  FormStatus,
  resolveSettingsFormStatus,
} from '../../use-cases/settings/utils/resolveSettingsFormStatus';

import { updateSettingsThunk } from './thunk';

export interface SettingsReducerState {
  account: AccountQuery_viewer_account_byEmail | null;
  settingsForm: SettingsForm | null;
}

export interface SettingsUpdateResult {
  result?: UpdateSettings_updateSettings;
  genericError: boolean;
}

const INITIAL_VALUE: SettingsReducerState = {
  account: null,
  settingsForm: null,
};

export interface SettingsFormMeta {
  part: SettingsPart | null;
  submitting: boolean;
  updateResult: SettingsUpdateResult | null;
}

type SettingsFormFields = Required<SettingsFormState>;

type ArraySettings = PickByValue<SettingsFormFields, any[]>;

export type ArraySettingsKeys = keyof ArraySettings;
export type NumericSettingsKeys = keyof PickByValue<SettingsFormFields, number | null>;
export type BooleanSettingsKeys = keyof PickByValue<SettingsFormFields, boolean | null>;
export type StringKeys = keyof PickByValue<SettingsFormFields, string | null>;

export type SettingsPart = 'account' | 'filter' | 'favourites' | 'dateFormat';

const INITIAL_META: SettingsForm['meta'] = {
  part: null,
  submitting: false,
  updateResult: null,
};

export interface SettingsForm {
  editedFormState: SettingsFormState;
  initialFormState: SettingsFormState;
  meta: SettingsFormMeta;
}

export interface SettingsFormState {
  accountEmail?: string | null;
  accountFirstName?: string | null;
  accountLastName?: string | null;
  accountLoginEmail?: string | null;
  accountPhone?: string | null;
  accountSecondEmail?: string | null;
  accountSendBookingConfirmationAsEmail?: boolean | null;

  filterDateInterval?: DateInterval | null;
  filterFilterStates?: BookingFilterState[];
  filterRoutes?: AccountQuery_viewer_account_byEmail_accountSettings_filterRoutes[];
  dashboardNotCheckedInTime?: number | null;
  dashboardShowNotCheckedIn?: boolean | null;
  dashboardShowPendingApproval?: boolean | null;
  dashboardShowDashboardOnScroll?: boolean | null;
  dashboardShowDateOverview?: boolean | null;
  dashboardShowUnaccompaniedUnits?: boolean | null;

  dateFormat?: string | null;

  bookCustomer?: AccountQuery_viewer_account_byEmail_accountSettings_bookCustomer | null;
  bookDate?: DateSetting | null;
  bookDefaultRoute?: AccountQuery_viewer_account_byEmail_accountSettings_bookDefaultRoute | null;
  bookDefaultVehicleType?: AccountQuery_viewer_account_byEmail_accountSettings_bookDefaultVehicleType | null;
  favouriteRoutes?: AccountQuery_viewer_account_byEmail_accountSettings_favouriteRoutes[];
  favouriteVehicleTypes?: AccountQuery_viewer_account_byEmail_accountSettings_favouriteVehicleTypes[];
}

const slice = createSlice({
  name: 'settings',
  initialState: INITIAL_VALUE,
  reducers: {
    resetSettingsForm(state) {
      if (state.settingsForm) {
        state.settingsForm.editedFormState = state.settingsForm.initialFormState;
        state.settingsForm.meta = INITIAL_META;
      }
    },
    initializeSettingsForm(state, action: PayloadAction<SettingsFormState>) {
      state.settingsForm = {
        editedFormState: action.payload,
        initialFormState: action.payload,
        meta: INITIAL_META,
      };
    },
    updateValues(state, action: PayloadAction<Partial<SettingsFormState>>) {
      if (state.settingsForm?.editedFormState) {
        Object.assign(state.settingsForm.editedFormState, action.payload);
      }
    },
    setAccount(state, action: PayloadAction<AccountQuery_viewer_account_byEmail>) {
      state.account = action.payload;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(updateSettingsThunk.pending, (state, action) => {
        if (state.settingsForm) {
          state.settingsForm.meta.part = action.meta.arg.part;
          state.settingsForm.meta.submitting = true;
        }
      })
      .addCase(updateSettingsThunk.fulfilled, (state, action) => {
        if (state.settingsForm && action.payload) {
          if (action.payload.formState) {
            state.settingsForm.editedFormState = action.payload.formState;
            state.settingsForm.initialFormState = action.payload.formState;
          }
          state.settingsForm.meta.part = action.meta.arg.part;
          state.settingsForm.meta.submitting = false;
          state.settingsForm.meta.updateResult = {
            genericError: false,
            result: action.payload.updateResult,
          };

          if (state.account && action.payload.updateResult?.settings) {
            Object.assign(state.account.accountSettings, action.payload.updateResult.settings);
          }
        }
      })
      .addCase(updateSettingsThunk.rejected, (state, action) => {
        if (state.settingsForm) {
          state.settingsForm.meta.part = action.meta.arg.part;
          state.settingsForm.meta.submitting = false;
          state.settingsForm.meta.updateResult = {
            result: undefined,
            genericError: true,
          };
        }
      }),
});

export const {
  reducer: settingsReducer,
  actions: { setAccount, resetSettingsForm, initializeSettingsForm, updateValues },
} = slice;

const getSettingsState = (state: StoreState): SettingsReducerState => state.settings;

export const getAccount = (state: StoreState): AccountQuery_viewer_account_byEmail | null =>
  getSettingsState(state).account;

export const getSettingsForm = (state: StoreState): SettingsForm | null =>
  getSettingsState(state).settingsForm;

export const getSettingsEditedFormState = (state: StoreState): SettingsFormState | undefined =>
  getSettingsForm(state)?.editedFormState;

export const getSettingsInitialFormState = (state: StoreState): SettingsFormState | undefined =>
  getSettingsForm(state)?.initialFormState;

export const getSettingsFormIsSubmitting = (state: StoreState, part: SettingsPart): boolean => {
  const meta = getSettingsForm(state)?.meta;
  return meta?.part === part && meta.submitting;
};

export const getSettingsFormStatus = (state: StoreState, part: SettingsPart): FormStatus => {
  const submitting = getSettingsFormIsSubmitting(state, part);
  const updateResult = getSettingsUpdateResult(state, part);

  return resolveSettingsFormStatus(updateResult, submitting);
};

export const getSettingsFormIsInitialized = (state: StoreState): boolean =>
  getSettingsState(state).settingsForm != null;

export const getSettingsUpdateResult = (
  state: StoreState,
  part: SettingsPart,
): SettingsUpdateResult | null => {
  const meta = getSettingsForm(state)?.meta;

  return meta?.part === part ? meta.updateResult : null;
};

export const getSettingFieldValidationState = (state: StoreState): ValidationFields | undefined =>
  getSettingsForm(state)?.meta.updateResult?.result?.validation ?? undefined;
