import { Auth0DecodedHash, Auth0Error, WebAuth } from 'auth0-js';
import { pingLastLogin } from '../../common/api';
import { setToken } from '../../services/apollo/apollo';
import { AuthInterface, AuthParams } from './types';

const hostname = window.location.hostname;
const protocol = window.location.protocol;
const port = window.location.port;

const clientID = (window.FREIGHT_PORTAL_ENV || {}).AUTH0_CLIENT_ID;

const basePath = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
const redirectUri = `${basePath}/callback`;

export const getPathnameWithSearch = () => {
  return window.location.pathname + window.location.search;
};

export class Auth implements AuthInterface {
  private params: AuthParams;

  private auth0 = new WebAuth({
    leeway: 300, // 5 minutes in seconds
    clientID,
    domain: (window.FREIGHT_PORTAL_ENV || {}).AUTH0_URL,
    redirectUri,
    responseType: 'token id_token',
    scope: 'openid email phone read:booking write:booking',
  });

  private tokenRenewalTimeout: number | undefined;

  constructor(params: AuthParams) {
    this.login = this.login.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.setSession = this.setSession.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.logout = this.logout.bind(this);
    this.scheduleRenewal = this.scheduleRenewal.bind(this);
    this.updateApolloToken = this.updateApolloToken.bind(this);
    this.params = params;
  }

  public login() {
    const pathname = getPathnameWithSearch();
    this.auth0.authorize({
      redirectUri: `${redirectUri}?returnTo=${encodeURIComponent(
        pathname === '/' ? '' : pathname,
      )}`,
    });
  }

  public handleAuthentication(): Promise<Auth0DecodedHash> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err: Auth0Error | null, authResult: Auth0DecodedHash | null) => {
        if (authResult?.accessToken && authResult.idToken) {
          this.setSession(authResult);
          resolve(authResult);
        } else if (err) {
          this.params.onFatalError(err.error, err.error_description ?? '');
          reject(err);
        }
      });
    });
  }

  public logout() {
    this.params.logOut();
    this.auth0.logout({
      clientID,
      returnTo: `${basePath}/`,
    });
  }

  private setSession(authResult: Auth0DecodedHash) {
    const expiresAt = authResult.expiresIn ? authResult.expiresIn * 1000 + new Date().getTime() : 0;

    this.updateApolloToken(authResult.accessToken || null);
    this.scheduleRenewal(expiresAt);

    this.params.logIn({
      ...authResult,
      expiresAt,
    });
  }

  public checkSession(): Promise<Auth0DecodedHash | undefined> {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult?.accessToken && authResult.idToken) {
          this.setSession(authResult);
          pingLastLogin();
          resolve(authResult);
        } else if (err) {
          if (err.error === 'login_required') {
            this.params.onRenewSessionFail();
          } else {
            this.params.onFatalError(err.error, err.error_description ?? '');
          }
          reject(err);
        }
      });
    });
  }

  private renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult?.accessToken && authResult.idToken) {
        this.setSession(authResult);
        pingLastLogin();
      } else if (err) {
        this.params.onRenewSessionFail();
        // tslint:disable-next-line
        console.log('---', err, '--- err');
        if (err.error === 'unauthorized') {
          this.logout();
        }
      }
    });
  }

  private scheduleRenewal(expiresAt: number) {
    const timeout = expiresAt - Date.now();
    window.clearTimeout(this.tokenRenewalTimeout);
    if (timeout > 0) {
      this.tokenRenewalTimeout = window.setTimeout(() => {
        this.renewSession();
      }, timeout);
    }
  }

  private updateApolloToken(token: string | null) {
    setToken(token);
  }
}
