import { BookingFilterState } from '../../gql/graphql';
import { exhaustSwitchCaseElseThrow } from './ExhaustSwitchCase';

export const selectablePlannedFilterStates: BookingFilterState[] = [
  BookingFilterState.Block,
  BookingFilterState.Confirmed,
  BookingFilterState.WaitingList,
  BookingFilterState.CheckedIn,
];

export const selectableNotPlannedFilterState: BookingFilterState[] = [
  BookingFilterState.Shipped,
  BookingFilterState.Cancelled,
  BookingFilterState.NoShow,
];

export const selectableFilterStates: BookingFilterState[] = [
  ...selectablePlannedFilterStates,
  ...selectableNotPlannedFilterState,
];

export const notPlannedFilterStates: BookingFilterState[] = [
  BookingFilterState.Available,
  BookingFilterState.Cancelled,
  BookingFilterState.CloseToCheckin,
  BookingFilterState.NoShow,
  BookingFilterState.None,
  BookingFilterState.PendingApproval,
  BookingFilterState.Shipped,
  BookingFilterState.Unloaded,
];

export const onQuayFilterStates: BookingFilterState[] = [
  BookingFilterState.Incoming,
  BookingFilterState.OnQuay,
];

export const getLabel = (filterState: BookingFilterState): string => {
  switch (filterState) {
    case BookingFilterState.None:
      return 'None';
    case BookingFilterState.Block:
      return 'Block booking';
    case BookingFilterState.WaitingList:
      return 'Waitlist';
    case BookingFilterState.Shipped:
      return 'Shipped';
    case BookingFilterState.OnQuay:
      return 'On quay';
    case BookingFilterState.Cancelled:
      return 'Cancelled';
    case BookingFilterState.Available:
      return 'Available';
    case BookingFilterState.PendingApproval:
      return 'Pending approval';
    case BookingFilterState.CloseToCheckin:
      return 'Not checked in';
    case BookingFilterState.CheckedIn:
      return 'Checked in';
    case BookingFilterState.Confirmed:
      return 'Confirmed';
    case BookingFilterState.NoShow:
      return 'No show';
    case BookingFilterState.Unloaded:
      return 'Unloaded';
    case BookingFilterState.Incoming:
      return 'Incoming';
    default:
      return exhaustSwitchCaseElseThrow(filterState);
  }
};
