import { all, put, select, takeEvery } from 'redux-saga/effects';
import { getAppliedDimension } from '../../../common/registrationNumberAlert/utils/registrationNumberAlertUtil';
import { setSpecificValues, updateSpecificValues } from '../createFormActions';
import { CreateCandidate } from '../createFormReducer';
import { getCreateFormStateCandidate } from '../createReducer';
import {
  CreateBookingFormRestoreDimensionAction,
  CreateBookingFormSetVehicleTypeAction,
} from '../travelledVehicleActions';
import { getNumberOfDrivers } from '../utils/transformCreateBookingResults';
import { getVehicleTypeDimension } from '../../../common/vehicle-types/vehicleType';

export function* handleSetVehicleType(action: CreateBookingFormSetVehicleTypeAction) {
  const { formId, id } = action.payload;

  const candidate: CreateCandidate | undefined = yield select(
    getCreateFormStateCandidate(formId),
    id,
  );

  if (candidate?.registrationNumberAlert?.result && candidate.registrationNumberAlert.vehicleType) {
    const { result, vehicleType } = candidate.registrationNumberAlert;

    // The user sets the matching type to TravelledVehicle, apply dimensions in result.
    const appliedHeight = getAppliedDimension(result.height, vehicleType?.defaultHeight);
    const appliedLength = getAppliedDimension(result.length, vehicleType?.defaultLength);

    const defaultNoOfDrivers = getNumberOfDrivers(vehicleType);

    yield put(setSpecificValues(formId, id, { vehicleType }));

    yield put(
      updateSpecificValues(formId, id, {
        height: appliedHeight ? result.height : vehicleType?.defaultHeight,
        length: appliedLength ? result.length : vehicleType?.defaultLength,
        numberOfDrivers: defaultNoOfDrivers,
        registrationNumberAlert: {
          ...candidate.registrationNumberAlert,
          appliedHeight,
          appliedLength,
        },
        tradeWeight: vehicleType?.defaultTradeWeight,
        width: vehicleType?.defaultWidth,
      }),
    );
  }
}

function* handleRestoreDimension(action: CreateBookingFormRestoreDimensionAction) {
  const { dimensionName, formId, id } = action.payload;

  const candidate: CreateCandidate | undefined = yield select(
    getCreateFormStateCandidate(formId),
    id,
  );

  if (candidate?.vehicleType && candidate.registrationNumberAlert) {
    yield put(
      updateSpecificValues(formId, id, {
        [dimensionName]: getVehicleTypeDimension(candidate.vehicleType, dimensionName),
      }),
    );

    yield put(
      updateSpecificValues(formId, action.payload.id, {
        registrationNumberAlert: {
          ...candidate.registrationNumberAlert,
          appliedHeight:
            dimensionName === 'height' ? false : candidate.registrationNumberAlert.appliedHeight,
          appliedLength:
            dimensionName === 'length' ? false : candidate.registrationNumberAlert.appliedLength,
        },
      }),
    );
  }
}

export function* watchTravelledVehicle() {
  yield all([
    takeEvery('CREATE_FORM:TRAVELLED_VEHICLE:SET_VEHICLE_TYPE', handleSetVehicleType),
    takeEvery('CREATE_FORM:TRAVELLED_VEHICLE:RESTORE_DIMENSION', handleRestoreDimension),
  ]);
}
