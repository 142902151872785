import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreState } from '../../store/storeState';
import { isEqual } from 'lodash';
import { loadUserSubscriptions } from './thunks/loadUserSubscriptions';
import { saveUserSubscriptions } from './thunks/saveUserSubscriptions';
import {
  UserSubscriptionsQuery_viewer_account_byEmail_userSubscriptions,
  UserSubscriptionsQuery_viewer_account_byEmail_userSubscriptions_bookingUpdatedInFreightPortal,
} from '../../use-cases/user-subscriptions/graphql/gql/UserSubscriptionsQuery';

export type SubscriptionKey = keyof Omit<
  UserSubscriptionsQuery_viewer_account_byEmail_userSubscriptions,
  'routes' | '__typename'
>;

export type UserSubscription =
  UserSubscriptionsQuery_viewer_account_byEmail_userSubscriptions_bookingUpdatedInFreightPortal;

export interface UserSubscriptionsReducerState {
  formState: { settings: Record<SubscriptionKey, UserSubscription>; routeCodes: string[] };
  initialFormState: { settings: Record<SubscriptionKey, UserSubscription>; routeCodes: string[] };
  error: boolean;
}

export const enableSmsBySubscriptionKey: Record<SubscriptionKey, boolean> = {
  arrivalTimeChanged: true,
  bookingUpdatedInFreightPortal: false,
  checkInDeviation: false,
  dailySailingSummary: false,
  lateCancellations: false,
  noShow: false,
  sailingCancelled: true,
  unitsSummaryPerSailing: false,
  waitingChangedToConfirmed: true,
};

export const initialSubscriptionSettings = {
  emailEnabled: false,
  smsEnabled: false,
  freightPortalEnabled: false,
};

export const initialSettings = {
  dailySailingSummary: initialSubscriptionSettings,
  checkInDeviation: initialSubscriptionSettings,
  noShow: initialSubscriptionSettings,
  waitingChangedToConfirmed: initialSubscriptionSettings,
  bookingUpdatedInFreightPortal: initialSubscriptionSettings,
  arrivalTimeChanged: initialSubscriptionSettings,
  unitsSummaryPerSailing: initialSubscriptionSettings,
  sailingCancelled: initialSubscriptionSettings,
  lateCancellations: initialSubscriptionSettings,
};

const INITIAL_STATE: UserSubscriptionsReducerState = {
  formState: { settings: initialSettings, routeCodes: [] },
  initialFormState: { settings: initialSettings, routeCodes: [] },
  error: false,
};

const slice = createSlice({
  name: 'subscription-settings-reducer',
  initialState: INITIAL_STATE,
  reducers: {
    toggleSms(state, action: PayloadAction<SubscriptionKey>) {
      state.formState.settings[action.payload].smsEnabled =
        !state.formState.settings[action.payload].smsEnabled;
    },
    toggleEmail(state, action: PayloadAction<SubscriptionKey>) {
      state.formState.settings[action.payload].emailEnabled =
        !state.formState.settings[action.payload].emailEnabled;
    },
    toggleFreightPortal(state, action: PayloadAction<SubscriptionKey>) {
      state.formState.settings[action.payload].freightPortalEnabled =
        !state.formState.settings[action.payload].freightPortalEnabled;
    },
    updateRoutes(state, action: PayloadAction<string[]>) {
      state.formState.routeCodes = action.payload;
    },
    revertChanges(state) {
      state.formState = state.initialFormState;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(loadUserSubscriptions.pending, state => {
        state.error = false;
      })
      .addCase(loadUserSubscriptions.fulfilled, (state, action) => {
        if (action.payload) {
          state.formState.settings = action.payload;
          state.formState.routeCodes = action.payload.routes.map(r => r.id);
          state.initialFormState = state.formState;
        }
      })
      .addCase(loadUserSubscriptions.rejected, state => {
        state.error = true;
      })
      .addCase(saveUserSubscriptions.pending, state => {
        state.error = false;
      })
      .addCase(saveUserSubscriptions.fulfilled, (state, action) => {
        if (action.payload.settings) {
          state.error = false;
          state.formState.settings = action.payload.settings;
          state.formState.routeCodes = action.payload.settings.routes.map(r => r.id);
          state.initialFormState = state.formState;
        }
      })
      .addCase(saveUserSubscriptions.rejected, state => {
        state.error = true;
      }),
});

export const {
  reducer: subscriptionSettingsReducer,
  actions: { toggleSms, toggleEmail, toggleFreightPortal, updateRoutes, revertChanges },
} = slice;

export const getUserSubscriptionsState = (state: StoreState) => state.userSubscriptions;

export const getUserSubscriptionsFormState = (state: StoreState) => {
  return getUserSubscriptionsState(state).formState;
};
export const getUserSubscriptionsInitialFormState = (state: StoreState) => {
  return getUserSubscriptionsState(state).initialFormState;
};

export const getUserSubscriptionsForKey = (state: StoreState, key: SubscriptionKey) => {
  return getUserSubscriptionsFormState(state).settings[key];
};

export const getUserSubscriptionsRouteCodes = (state: StoreState) => {
  return getUserSubscriptionsFormState(state).routeCodes;
};

export const getUserSubscriptionsHasError = (state: StoreState) => {
  return getUserSubscriptionsState(state).error;
};

export const getUserSubscriptionsHasChanged = (state: StoreState) => {
  return !isEqual(
    getUserSubscriptionsFormState(state),
    getUserSubscriptionsInitialFormState(state),
  );
};
