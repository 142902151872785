import { FC, ReactNode } from 'react';
import { BookingFormState } from '../../../../services/update-form/updateFormReducer';
import { useUpdateField } from '../../../../common/update-information/hooks/useUpdateField';
import { ValidationWrapper } from '../../../../common-ui/form-fields/ValidationWrapper';

interface UpdateValidationWrapperProps {
  renderField: (isValid: boolean) => ReactNode;
  name: keyof BookingFormState;
}

export const UpdateValidationWrapper: FC<UpdateValidationWrapperProps> = ({ name, ...props }) => {
  const { validationError } = useUpdateField(name);

  return <ValidationWrapper validationError={validationError} {...props} />;
};
