import { cssColor } from '@stenajs-webui/theme';

export const getColorForSailingCategory = (category: string): string => {
  switch (category) {
    case 'S':
      return '#1178df'; // Should be --lhds-color-blue-500 when it's updated
    case 'O':
      return '#60bd2f'; // Should be --lhds-color-green-300 when it's updated
    case 'P':
      return '#ffc400'; // Should be --lhds-color-orange-300 when it's updated
    default:
      return cssColor('--lhds-color-ui-200');
  }
};
