import { FocusEventHandler } from 'react';
import { TrackerCategory, trackEvent } from '../../../common/tracking/trackerService';
import { CreateCandidate, CreateFormState } from '../../../services/create/createFormReducer';
import { SettingsFormState } from '../../../services/settings/settingsReducer';
import { BookingFormState } from '../../../services/update-form/updateFormReducer';
import { useValueHasChangedCallback } from './UseValueHasChangedCallback';
import {
  UpdateIntermodalBookingFormModel,
  UpdateIntermodalBookingGoodFormModel,
} from '../../../services/intermodal/updateIntermodalReducer';
import {
  CreateIntermodalBookingFormModel,
  CreateIntermodalBookingGoodFormModel,
} from '../../../services/intermodal/createIntermodalReducer';

interface UseTrackingTextInputProps {
  onBlur: FocusEventHandler<HTMLInputElement>;
  onFocus: FocusEventHandler<HTMLInputElement>;
}

export interface TrackerAction {
  value: string;
}

export const transformCreateFieldToTrackerAction = (
  field: keyof CreateCandidate | keyof CreateFormState,
): TrackerAction => {
  return { value: field };
};

export const transformUpdateFieldToTrackerAction = (
  field: keyof BookingFormState,
): TrackerAction => {
  return { value: field };
};

export const transformUpdateIntermodalFieldToTrackerAction = (
  field: keyof UpdateIntermodalBookingFormModel,
): TrackerAction => {
  return { value: field };
};

export const transformCreateIntermodalGoodsFieldToTrackerAction = (
  field: keyof CreateIntermodalBookingGoodFormModel,
): TrackerAction => {
  return { value: field };
};

export const transformCreateIntermodalFieldToTrackerAction = (
  field: keyof CreateIntermodalBookingFormModel,
): TrackerAction => {
  return { value: field };
};

export const transformUpdateIntermodalGoodsFieldToTrackerAction = (
  field: keyof UpdateIntermodalBookingGoodFormModel,
): TrackerAction => {
  return { value: field };
};

export const transformSettingsFieldToTrackerAction = (
  field: keyof SettingsFormState,
): TrackerAction => {
  return { value: field };
};

const isSensitiveAction = (action: TrackerAction): boolean => {
  return action.value === 'customer' || action.value === 'bookCustomer';
};

export const trackEditEvent = (
  action: TrackerAction,
  category: TrackerCategory,
  label?: string,
) => {
  if (label && !isSensitiveAction(action)) {
    trackEvent({
      category,
      action: 'Edited ' + action.value,
      label,
    });
  } else {
    trackEvent({
      category,
      action: 'Edited ' + action.value,
    });
  }
};

export const useTrackingTextInput = (
  action: TrackerAction,
  category: TrackerCategory,
): UseTrackingTextInputProps => {
  const { onBlur, onFocus } = useValueHasChangedCallback(() => {
    trackEditEvent(action, category);
  });

  return { onBlur, onFocus };
};
