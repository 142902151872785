import { first } from 'lodash';
import {
  getUpdateIntermodalBookingEditedFormState,
  getUpdateIntermodalBookingInitialFormState,
  getUpdateIntermodalBookingIsSubmitting,
  getUpdateIntermodalBookingValidationErrors,
  setUpdateIntermodalBookingFormValue,
  UpdateIntermodalBookingFormModel,
} from '../../../services/intermodal/updateIntermodalReducer';
import { useCallback } from 'react';
import { StoreState } from '../../../store/storeState';
import { useShallowEqualSelector } from '../../../common/hooks/useShallowEqualSelector';
import { useDispatch } from 'react-redux';

import { useEditable } from '../../../common/modal/EditableContext';
import {
  TrackerAction,
  transformUpdateIntermodalFieldToTrackerAction,
} from '../../simple-create/hooks/UseTrackingTextInput';

export interface UseIntermodalFieldProps<Key extends keyof UpdateIntermodalBookingFormModel> {
  editedValue: UpdateIntermodalBookingFormModel[Key] | undefined;
  initialValue: UpdateIntermodalBookingFormModel[Key] | undefined;
  onChange: (value: UpdateIntermodalBookingFormModel[Key]) => void;
  revertChange: () => void;
  validationError?: string;
  editable: boolean;
  disabled: boolean;
  trackerAction: TrackerAction;
}

export const useUpdateIntermodalField = <Key extends keyof UpdateIntermodalBookingFormModel>(
  name: Key,
): UseIntermodalFieldProps<Key> => {
  const editable = useEditable();
  const mapState = useCallback(
    (state: StoreState) => {
      const editedFormState = getUpdateIntermodalBookingEditedFormState(state);
      const initialFormState = getUpdateIntermodalBookingInitialFormState(state);
      const validationErrors = getUpdateIntermodalBookingValidationErrors(state, name);
      const disabled = getUpdateIntermodalBookingIsSubmitting(state);
      return {
        editedValue: editedFormState[name],
        initialValue: initialFormState[name],
        validationError: first(validationErrors),
        disabled,
      };
    },
    [name],
  );

  const { editedValue, initialValue, validationError, disabled } =
    useShallowEqualSelector(mapState);

  const trackerAction = transformUpdateIntermodalFieldToTrackerAction(name);

  const dispatch = useDispatch();
  const onChange = useCallback<(value: UpdateIntermodalBookingFormModel[Key]) => void>(
    newValue => {
      if (editedValue !== newValue) {
        dispatch(setUpdateIntermodalBookingFormValue({ [name]: newValue }));
      }
    },
    [editedValue, dispatch, name],
  );

  const revertChange = useCallback(
    () => () => {
      onChange(initialValue);
    },
    [initialValue, onChange],
  );

  return {
    editedValue,
    initialValue,
    onChange,
    revertChange,
    editable,
    validationError,
    disabled,
    trackerAction,
  };
};
