import { Box, useBoolean } from '@stenajs-webui/core';
import { TextInputButtonSize } from '@stenajs-webui/elements';
import { FC, ReactNode, useRef } from 'react';
import { InformationMessageIcon } from '../../common-ui/information-message/InformationMessageIcon';
import { InformationMessageType } from '../../common-ui/information-message/informationMessageUtils';
import { FreightPortalPopover } from '../../common-ui/popover/FreightPortalPopover';
import { PopoverBody } from '../../common-ui/popover/PopoverBody';
import { PopoverTitle } from '../../common-ui/popover/PopoverTitle';

interface RowInformationCellPopupProps {
  type?: InformationMessageType;
  size?: TextInputButtonSize;
  initialOpen?: boolean;
  minWidth?: number;
  maxWidth?: number;
  title: string;
  children?: ReactNode;
}

export const RowInformationCellPopup: FC<RowInformationCellPopupProps> = ({
  children,
  type = InformationMessageType.RegularInfo,
  size,
  initialOpen = false,
  minWidth = 240,
  maxWidth = 315,
  title,
}) => {
  const [isOpen, open, close] = useBoolean(initialOpen);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <InformationMessageIcon size={size} type={type} onClick={open} buttonRef={buttonRef} />
      <FreightPortalPopover
        reference={buttonRef}
        lazy
        onRequestClose={close}
        visible={isOpen}
        placement={'right-start'}
        content={
          <Box minWidth={minWidth} maxWidth={maxWidth} className={'t_information_popup'}>
            <PopoverTitle text={title} onClose={close} />
            <PopoverBody>{children}</PopoverBody>
          </Box>
        }
      ></FreightPortalPopover>
    </>
  );
};
