import { safeParse } from 'valibot';
import { Result } from '../../../common/api';
import {
  CreateIntermodalBookingInput,
  IntermodalJourneyFilterInput,
  UpdateIntermodalBookingInput,
} from '../../../gql/graphql';
import {
  CreateIntermodalBookingFormModel,
  CreateIntermodalBookingValidationErrors,
} from '../createIntermodalReducer';
import {
  UpdateIntermodalBookingFormModel,
  UpdateIntermodalBookingValidationErrors,
} from '../updateIntermodalReducer';
import {
  collectIssues,
  CreateIntermodalFormModelSchema,
  IntermodalJourneyFormModelSchema,
  UpdateIntermodalFormModelSchema,
} from './schemas';
import {
  transformCreateFormModelToInput,
  transformIntermodalCreateFormStateToFilter,
  transformUpdateFormModelToInput,
} from './transform';

export function validateUpdateIntermodalFields(
  formState: UpdateIntermodalBookingFormModel,
): Result<UpdateIntermodalBookingInput, UpdateIntermodalBookingValidationErrors> {
  const result = safeParse(UpdateIntermodalFormModelSchema, formState);

  if (!result.success) {
    return {
      success: false,
      error: collectIssues(result.issues),
    };
  }

  return { success: true, value: transformUpdateFormModelToInput(result.output) };
}

export function validateCreateIntermodalFields(
  formState: CreateIntermodalBookingFormModel,
): Result<CreateIntermodalBookingInput, CreateIntermodalBookingValidationErrors> {
  const result = safeParse(CreateIntermodalFormModelSchema, formState);

  if (!result.success) {
    return {
      success: false,
      error: collectIssues(result.issues),
    };
  }

  return { success: true, value: transformCreateFormModelToInput(result.output) };
}

export function validateJourneyIntermodalFields(
  formState: CreateIntermodalBookingFormModel,
): Result<
  IntermodalJourneyFilterInput,
  {
    validationErrors: CreateIntermodalBookingValidationErrors;
  }
> {
  const result = safeParse(IntermodalJourneyFormModelSchema, formState);

  if (!result.success) {
    return {
      success: false,
      error: { validationErrors: collectIssues(result.issues) },
    };
  }

  return { success: true, value: transformIntermodalCreateFormStateToFilter(result.output) };
}
