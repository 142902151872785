import { ActionMenuLink, stenaPen } from '@stenajs-webui/elements';
import { FC } from 'react';
import { routeCreators } from '../../../../../common/routes/AppRoutes';
import { TrackerCategory, trackEvent } from '../../../../../common/tracking/trackerService';

interface ActionMenuClaimsLinkProps {
  bookingNo: number;
  disabled?: boolean;
  onClick?: () => void;
  trackerCategory: TrackerCategory;
  trackerAction?: string;
}

export const ActionMenuClaimsLink: FC<ActionMenuClaimsLinkProps> = ({
  bookingNo,
  disabled,
  onClick,
  trackerCategory,
  trackerAction = 'Make claim',
}) => {
  const onClickActionMenuItem = () => {
    onClick?.();
    trackEvent({ category: trackerCategory, action: trackerAction });
  };

  return (
    <ActionMenuLink
      target="_blank"
      href={routeCreators.bookingClaims({ bookingNo })}
      disabled={disabled}
      onClick={onClickActionMenuItem}
      leftIcon={stenaPen}
      label={'Make claim'}
    />
  );
};
