import { first } from 'lodash';
import { useCallback } from 'react';
import { StoreState } from '../../../store/storeState';
import { useShallowEqualSelector } from '../../../common/hooks/useShallowEqualSelector';
import { useDispatch } from 'react-redux';
import {
  TrackerAction,
  transformCreateIntermodalGoodsFieldToTrackerAction,
} from '../../simple-create/hooks/UseTrackingTextInput';
import {
  CreateIntermodalBookingGoodFormModel,
  getCreateIntermodalBookingGood,
  getCreateIntermodalBookingGoodValidationErrors,
  getCreateIntermodalBookingIsSubmitting,
  modifyCreateIntermodalBookingGood,
} from '../../../services/intermodal/createIntermodalReducer';

interface useIntermodalGoodsFieldProps<TFormState, Key extends keyof TFormState> {
  editedValue: TFormState[Key] | undefined;
  onChange: (value: TFormState[Key]) => void;
  validationError?: string;
  disabled: boolean;
  trackerAction: TrackerAction;
}

export const useCreateIntermodalGoodsField = <
  Key extends keyof CreateIntermodalBookingGoodFormModel,
>(
  goodId: string,
  name: Key,
): useIntermodalGoodsFieldProps<CreateIntermodalBookingGoodFormModel, Key> => {
  const mapState = useCallback(
    (state: StoreState) => {
      const formState = getCreateIntermodalBookingGood(state, goodId);
      const validationErrors = getCreateIntermodalBookingGoodValidationErrors(state, goodId, name);
      const disabled = getCreateIntermodalBookingIsSubmitting(state);
      return {
        value: formState?.[name],
        validationError: first(validationErrors),
        disabled,
      };
    },
    [goodId, name],
  );

  const { value, disabled, validationError } = useShallowEqualSelector(mapState);

  const trackerAction = transformCreateIntermodalGoodsFieldToTrackerAction(name);

  const dispatch = useDispatch();
  const onChange = useCallback<(value: CreateIntermodalBookingGoodFormModel[Key]) => void>(
    newValue => {
      if (value !== newValue) {
        dispatch(
          modifyCreateIntermodalBookingGood({ updatedFields: { [name]: newValue }, id: goodId }),
        );
      }
    },
    [value, dispatch, name, goodId],
  );

  return {
    editedValue: value,
    onChange,
    disabled,
    validationError,
    trackerAction,
  };
};
