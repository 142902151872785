import { FC, ReactNode } from 'react';
import { useQuery } from '@apollo/client';
import { Column, Heading, Row, Space, Text } from '@stenajs-webui/core';
import {
  Icon,
  Link,
  PrimaryButton,
  stenaExclamationCircle,
  stenaRefresh,
} from '@stenajs-webui/elements';
import { graphql } from '../../../gql';
import { ErrorPage } from '../../error-handling/ErrorPage';
import { useSelector } from 'react-redux';
import { getHealthCheckFailed } from '../../../services/apollo/healthCheckReducer';
import { CONTACT_URL } from '../../footer/Footer';

export const healthCheckQuery = graphql(/* GraphQL */ `
  query HealthCheckQuery {
    __typename
  }
`);

export const ServerDownBoundary: FC<{ children?: ReactNode }> = ({ children }) => {
  const isServerDown = useSelector(getHealthCheckFailed);

  useQuery(healthCheckQuery);

  if (isServerDown) {
    return (
      <ErrorPage
        content={
          <Column alignItems={'flex-start'}>
            <Row alignItems={'center'}>
              <Icon icon={stenaExclamationCircle} />
              <Space />
              <Heading variant={'h2'}>Temporarily out of service</Heading>
            </Row>
            <Space num={2} />
            <Text>
              Unfortunately we are experiencing technical problems. Check back in a few minutes or{' '}
              <Link href={CONTACT_URL}>contact your local booking office</Link> for booking
              assistance. We apologise for the inconvenience.
            </Text>
            <Space num={2} />
            <PrimaryButton
              label={'Reload page'}
              onClick={() => window.location.reload()}
              leftIcon={stenaRefresh}
            />
          </Column>
        }
        title={'Temporarily out of service'}
      />
    );
  }

  return <>{children}</>;
};
