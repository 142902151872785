import { Options as OptionsType } from 'react-select';
import { GroupedOption, GroupedOptionsType } from '../../common-ui/form-fields/Dropdown';
import { DropdownOption } from '../../use-cases/manage/grid/utils/optionTransformers';
import { filterMap } from './filterMap';

export const isGroupedOptions = <T>(
  object: GroupedOption<DropdownOption<T>> | DropdownOption<T>,
): object is GroupedOption<DropdownOption<T>> => 'options' in object;

export const findOptionByValue = <T>(
  options: GroupedOptionsType<DropdownOption<T>> | OptionsType<DropdownOption<T>>,
  value?: string,
): DropdownOption<T> | undefined => {
  if (value === null || value === undefined) {
    return undefined;
  }

  for (const option of options) {
    if (isGroupedOptions(option)) {
      for (const groupedOption of option.options) {
        if (groupedOption.value === value) {
          return groupedOption;
        }
      }
    } else {
      if (option.value === value) {
        return option;
      }
    }
  }
  return undefined;
};

export const findOptionsByValues = <T>(
  options: GroupedOptionsType<DropdownOption<T>> | OptionsType<DropdownOption<T>>,
  values: string[],
): Array<DropdownOption<T>> => filterMap(values, value => findOptionByValue(options, value));

export const addGroupToOptions = <T>(
  options: GroupedOptionsType<DropdownOption<T>> | OptionsType<DropdownOption<T>>,
  groupLabel: string,
  matchingValues?: string[],
): GroupedOptionsType<DropdownOption<T>> | OptionsType<DropdownOption<T>> => {
  const matchingOptions: Array<DropdownOption<T>> = [];
  const notSelectedOptions: Array<DropdownOption<T>> = [];
  const notSelectedGroupedOptions: Array<GroupedOption<DropdownOption<T>>> = [];
  if (!matchingValues) {
    return matchingOptions;
  }

  for (const option of options) {
    if (isGroupedOptions(option)) {
      const notSelectedGroupOptions: Array<DropdownOption<T>> = [];
      for (const groupedOption of option.options) {
        if (matchingValues.indexOf(groupedOption.value) > -1) {
          matchingOptions.push(groupedOption);
        } else {
          notSelectedGroupOptions.push(groupedOption);
        }
      }
      if (notSelectedGroupOptions.length > 0) {
        notSelectedGroupedOptions.push({
          ...option,
          options: notSelectedGroupOptions,
        });
      }
    } else {
      if (matchingValues.indexOf(option.value) > -1) {
        matchingOptions.push(option);
      } else {
        notSelectedOptions.push(option);
      }
    }
  }
  if (notSelectedOptions.length > 0) {
    if (matchingOptions.length === 0) {
      return notSelectedOptions;
    }
    return [
      {
        label: groupLabel,
        value: '',
        options: matchingOptions,
      },
      {
        label: 'Other',
        value: '',
        options: notSelectedOptions,
      },
    ];
  } else if (notSelectedGroupedOptions.length > 0) {
    if (matchingOptions.length === 0) {
      return notSelectedGroupedOptions;
    }
    return [
      {
        label: groupLabel,
        value: '',
        options: matchingOptions,
      },
      ...notSelectedGroupedOptions,
    ];
  }
  return options;
};

export const addFavouritesToOptions = <T>(
  options: GroupedOptionsType<DropdownOption<T>> | OptionsType<DropdownOption<T>>,
  values?: string[],
): GroupedOptionsType<DropdownOption<T>> | OptionsType<DropdownOption<T>> =>
  addGroupToOptions(options, 'Favourites', values);
