import { useNumericTextInputChange } from '../../../../common/hooks/useNumericTextInputChange';
import {
  CommonTextInputField,
  CommonTextInputFieldProps,
} from '../../../../common-ui/form-fields/CommonTextInputField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import { UpdateIntermodalFormModelStringKeys } from '../../../../services/intermodal/updateIntermodalReducer';
import { useUpdateIntermodalField } from '../../hooks/useUpdateIntermodalField';

interface UpdateIntermodalNumericTextInputFieldProps<
  Key extends UpdateIntermodalFormModelStringKeys,
> extends Omit<CommonTextInputFieldProps, 'onChange' | 'trackerAction'> {
  name: Key;
  onlyIntegers?: boolean;
}

export function UpdateIntermodalNumericTextInputField<
  Key extends UpdateIntermodalFormModelStringKeys,
>({ name, onlyIntegers, ...props }: UpdateIntermodalNumericTextInputFieldProps<Key>) {
  const { onChange, ...fieldProps } = useUpdateIntermodalField(name);
  const onTextInputChange = useNumericTextInputChange(onChange, onlyIntegers);

  return (
    <CommonTextInputField
      {...props}
      {...getFieldProps(props, { ...fieldProps, onChange: onTextInputChange })}
      changed={fieldProps.initialValue !== fieldProps.editedValue}
    />
  );
}
