import { Box } from '@stenajs-webui/core';
import { TextInputButton, TextInputButtonSize } from '@stenajs-webui/elements';
import { FC } from 'react';
import { ImportExportStatusCode } from '../../../gql/graphql';
import { resolveIconProps } from '../util/ImportExportStatusUtil';

interface Props {
  code: ImportExportStatusCode;
  isButton?: boolean;
  spin?: boolean;
  size: TextInputButtonSize;
  onClick: () => void;
  title?: string;
}

export const ImportExportStatusIcon: FC<Props> = ({ code, onClick, title, size, spin }) => {
  const iconProps = resolveIconProps(code);

  if (!iconProps.icon) {
    return <TextInputButtonPlaceholder size={size} />;
  }

  return <TextInputButton {...iconProps} onClick={onClick} title={title} spin={spin} size={size} />;
};

export function getIconButtonSize(size: TextInputButtonSize) {
  return size === 'medium' ? '3rem' : '1.8rem';
}

export const TextInputButtonPlaceholder = ({ size }: Pick<Props, 'size'>) => {
  const iconSize = getIconButtonSize(size);

  return <Box height={iconSize} width={iconSize} />;
};
