import { flatMap, groupBy, sortBy } from 'lodash';
import { SettingsFragment } from '../../../../common/graphql/fragments/gql/SettingsFragment';
import { nullable } from '../../../../common/utils/nullable';
import { DashboardQueryQuery } from '../../../../gql/graphql';
import { FilterState } from '../../../../services/booking-filter/filterReducer';
import { DashboardLocationItemFragment } from '../graphql/gql/DashboardLocationItemFragment';
import { DashboardRouteItemFragment } from '../graphql/gql/DashboardRouteItemFragment';
import {
  createDayFilterFactory,
  createDefaultFilter,
  createIncomingFilterFactory,
  createNotCheckedInFilterFactory,
  createOnQuayAndIncomingFilterFactory,
  createOnQuayFilterFactory,
  createPendingApprovalFilterFactory,
  FilterStateFactory,
} from './filters';

export const DASHBOARD_POLL_INTERVAL = 10 * 60 * 1000;

export interface ItemWithNumberOfTransports {
  numberOfTransports: number;
}

export const sumByRouteUnits = <T extends ItemWithNumberOfTransports>(items: T[]): number =>
  items.reduce((total, item) => total + item.numberOfTransports, 0);

export interface SectionProps {
  loading: boolean;
}

export interface DashboardItems {
  notCheckedInItems: DashboardRouteItemFragment[];
  todayItems: DashboardRouteItemFragment[];
  pendingApprovalItems: DashboardRouteItemFragment[];
  tomorrowItems: DashboardRouteItemFragment[];
  defaultItems: DashboardRouteItemFragment[];
  incomingItems: DashboardLocationItemFragment[];
  onQuayItems: DashboardLocationItemFragment[];
}

export interface DashboardFilterStateFactories {
  defaultFilter: FilterState;
  onQuayAndIncomingFilterFactory: FilterStateFactory;
  todayFilterFactory: FilterStateFactory;
  tomorrowFilterFactory: FilterStateFactory;
  onQuayFilterFactory: FilterStateFactory;
  incomingFilterFactory: FilterStateFactory;
  notCheckedInFilterFactory: FilterStateFactory;
  pendingApprovalFilterFactory: FilterStateFactory;
}

export const getDashboardFilters = (
  accountSettings: SettingsFragment,
): DashboardFilterStateFactories => {
  const defaultFilter = createDefaultFilter(accountSettings);
  const todayFilterFactory = createDayFilterFactory('TODAY');
  const tomorrowFilterFactory = createDayFilterFactory('TOMORROW');
  const onQuayFilterFactory = createOnQuayFilterFactory('TODAY');
  const incomingFilterFactory = createIncomingFilterFactory('TODAY');
  const onQuayAndIncomingFilterFactory = createOnQuayAndIncomingFilterFactory('TODAY');

  return {
    defaultFilter,
    todayFilterFactory,
    tomorrowFilterFactory,
    onQuayFilterFactory,
    incomingFilterFactory,
    onQuayAndIncomingFilterFactory,
    notCheckedInFilterFactory: createNotCheckedInFilterFactory,
    pendingApprovalFilterFactory: createPendingApprovalFilterFactory,
  };
};

export const sortDashboardRouteItems = (
  items: DashboardRouteItemFragment[],
): DashboardRouteItemFragment[] =>
  flatMap(
    groupBy(
      sortBy(items, [item => item.route.routePairCode, item => item.route.code]),
      item => item.route.seaAreaDescription,
    ),
  );

const sortDashboardLocationItems = (items: DashboardLocationItemFragment[]) =>
  sortBy<DashboardLocationItemFragment>(items, [item => item.location.name]);

export const getDashboardItemsSorted = (data?: DashboardQueryQuery): DashboardItems => {
  const defaultItems = sortDashboardRouteItems(data?.viewer.dashboard?.default.items ?? []);

  const notCheckedInItems = sortDashboardRouteItems(
    nullable(data?.viewer.dashboard?.notCheckedIn)?.items ?? [],
  );
  const pendingApprovalItems = sortDashboardRouteItems(
    nullable(data?.viewer.dashboard?.pendingApproval)?.items ?? [],
  );
  const todayItems = sortDashboardRouteItems(nullable(data?.viewer.dashboard?.today)?.items ?? []);
  const tomorrowItems = sortDashboardRouteItems(
    nullable(data?.viewer.dashboard?.tomorrow)?.items ?? [],
  );

  const onQuayItems = sortDashboardLocationItems(
    nullable(data?.viewer.dashboard?.onQuay)?.items ?? [],
  );
  const incomingItems = sortDashboardLocationItems(
    nullable(data?.viewer.dashboard?.incoming)?.items ?? [],
  );

  return {
    defaultItems,
    notCheckedInItems,
    pendingApprovalItems,
    todayItems,
    tomorrowItems,
    onQuayItems,
    incomingItems,
  };
};
