import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal, ModalDefinition } from '../../services/modal/modalActions';
import { getActiveModal, getShowModal } from '../../services/modal/modalReducer';
import { StoreState } from '../../store/storeState';
import { CustomsLinkGdprModal } from '../../use-cases/customslink/components/modal/CustomsLinkGdprModal';
import { Co2EmissionsModal } from '../../use-cases/statistics/components/Co2EmissionsModal';
import { WelcomeModal } from '../../use-cases/tour/WelcomeModal';
import { useShallowEqualSelector } from '../hooks/useShallowEqualSelector';
import { HotkeysModal } from '../hotkeys/HotkeysModal';
import { trackModalView } from '../tracking/trackerService';
import { UrgentMessageModal } from '../urgent-message/components/UrgentMessageModal';
import { exhaustSwitchCaseElseThrow } from '../utils/ExhaustSwitchCase';
import { FreightPortalLegacyBaseModal } from './FreightPortalBaseModal';

interface Props {
  modalDef?: ModalDefinition;
  onClose: () => void;
}

export const ModalSwitcher = ({ modalDef, onClose }: Props) => {
  useEffect(() => {
    if (modalDef) {
      trackModalView(modalDef.type);
    }
  }, [modalDef]);

  if (!modalDef) {
    return null;
  }

  switch (modalDef.type) {
    case 'hotkeysOverview':
      return <HotkeysModal onClose={onClose} />;
    case 'introduction':
      return <WelcomeModal onClose={onClose} />;
    case 'co2emissions':
      return <Co2EmissionsModal onClose={onClose} />;
    case 'customslink-gdpr':
      return (
        <CustomsLinkGdprModal
          bookingNo={modalDef.args.bookingNo}
          trackerCategory={modalDef.args.trackerCategory}
          onClose={onClose}
        />
      );
    case 'urgentMessage':
      return (
        <UrgentMessageModal
          onClose={onClose}
          urgentMessage={modalDef.args.urgentMessage}
          trackerCategory={modalDef.args.trackerCategory}
          trackerAction={modalDef.args.trackerAction}
        />
      );
    default:
      return exhaustSwitchCaseElseThrow(modalDef);
  }
};

const mapState = (state: StoreState) => ({
  modalArgs: getActiveModal(state),
  open: getShowModal(state),
});

export const FreightPortalModal: FC = () => {
  const { modalArgs, open } = useShallowEqualSelector(mapState);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  return (
    <FreightPortalLegacyBaseModal
      aria-label={modalArgs?.label ?? null}
      isOpen={open}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={modalArgs?.modalOptions?.shouldCloseOnOverlayClick ?? true}
      draggable
    >
      <ModalSwitcher modalDef={modalArgs} onClose={onClose} />
    </FreightPortalLegacyBaseModal>
  );
};
