import { FC } from 'react';
import { CommonTextInputField } from '../../../../common-ui/form-fields/CommonTextInputField';
import { getFieldProps } from '../../../../common-ui/form-fields/getFieldProps';
import { useNumericTextInputChange } from '../../../../common/hooks/useNumericTextInputChange';
import { useCreateIntermodalGoodsField } from '../../hooks/useCreateIntermodalGoodsFields';
import { GoodsWeightInputProps } from '../../update/fields/UpdateGoodsWeightInput';

export const CreateGoodsWeightInput: FC<GoodsWeightInputProps> = ({ goodsId, ...props }) => {
  const { onChange, ...fieldProps } = useCreateIntermodalGoodsField(goodsId, 'weight');
  const onTextInputChange = useNumericTextInputChange(onChange, true);

  return (
    <CommonTextInputField
      {...props}
      {...getFieldProps(props, { ...fieldProps, onChange: onTextInputChange })}
    />
  );
};
