import { PrimaryButton, SecondaryButton } from '@stenajs-webui/elements';
import {
  RegistrationNumberAlertFormState,
  TravelledVehicleType,
} from '../../services/update-form/registrationNumberAlert';
import { InformationMessageType } from './informationMessageUtils';
import {
  RegistrationNumberAlertIcon,
  RegistrationNumberAlertInformationIconProps,
} from './RegistrationNumberAlertIcon';
import { Column, Txt } from '@stenajs-webui/core';
import { RegistrationNumberAlertFooter } from './RegistrationNumberAlertFooter';
import { TravelledVehicleResult } from './TravelledVehicleResult';

export interface RenderRegistrationNumberAlertIconProps
  extends Pick<RegistrationNumberAlertInformationIconProps, 'buttonSize' | 'placement'> {
  locationName: string | null;
  onIconClick: (type: 'ArrivalNotepad' | 'TravelledVehicle') => void;
  onApply: () => void;
  onSkip: () => void;
  onDisableArrivalNotepad: () => void;
  registrationNumberAlert: RegistrationNumberAlertFormState | null | undefined;
  travelledVehicleType: TravelledVehicleType;
  hasAppliedTravelledVehicle: boolean;
  hasTravelledVehicle: boolean;
  hasArrivalNotepad: boolean;
}

export const renderRegistrationNumberAlertIcon = ({
  registrationNumberAlert,
  locationName,
  onApply,
  onDisableArrivalNotepad,
  onSkip,
  travelledVehicleType,
  hasAppliedTravelledVehicle,
  hasArrivalNotepad,
  hasTravelledVehicle,
  onIconClick,
  ...props
}: RenderRegistrationNumberAlertIconProps) => {
  if (!registrationNumberAlert) {
    return null;
  }

  if (hasAppliedTravelledVehicle) {
    return null;
  }

  if (hasArrivalNotepad) {
    return (
      <RegistrationNumberAlertIcon
        {...props}
        onClick={() => onIconClick('ArrivalNotepad')}
        title={'Matching unit waiting to be shipped'}
        iconType={InformationMessageType.RegularInfo}
        renderPopupContent={requestClose => (
          <Column gap={2}>
            <Txt>
              {locationName
                ? `We found a matching trailer waiting to be shipped from ${locationName}. The registered
                details for the trailer have been added to the booking. After the booking is saved it is no longer possible to update it.`
                : 'We found a matching trailer waiting to be shipped. The registered details for the trailer have been added to the booking. After the booking is saved it is no longer possible to update it.'}
            </Txt>
            <RegistrationNumberAlertFooter>
              {travelledVehicleType === TravelledVehicleType.Vehicle && (
                <SecondaryButton
                  onClick={() => {
                    onDisableArrivalNotepad();
                    requestClose();
                  }}
                  label={'Clear and exit quay records'}
                />
              )}
            </RegistrationNumberAlertFooter>
          </Column>
        )}
      />
    );
  }
  const result = registrationNumberAlert.result;
  const vehicleType = registrationNumberAlert.vehicleType;

  if (hasTravelledVehicle && result && vehicleType) {
    return (
      <RegistrationNumberAlertIcon
        {...props}
        onClick={() => onIconClick('ArrivalNotepad')}
        title={'Vehicle history found'}
        iconType={InformationMessageType.SoftWarning}
        renderPopupContent={requestClose => (
          <Column gap={2}>
            <TravelledVehicleResult travelledVehicleResult={result} vehicleType={vehicleType} />
            <RegistrationNumberAlertFooter>
              <SecondaryButton
                onClick={() => {
                  onSkip();
                  requestClose();
                }}
                label={'Skip'}
              />
              <PrimaryButton
                onClick={() => {
                  onApply();
                  requestClose();
                }}
                label={'Use information'}
              />
            </RegistrationNumberAlertFooter>
          </Column>
        )}
      />
    );
  }

  return null;
};
